import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { PageHeader, GridDefault } from "dw-components";
import { IconButton } from "@fluentui/react";
import Sidemenu from "../../Sidemenu/Sidemenu";
import OvertimeWagePanel from "./Panels/OvertimeWagePanel";
import { APIGraphQL } from "../../../API/APIGraphQL";

const OvertimeWage = (props) => {

  const [data, setData] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [id, setId] = useState();
  const [typeid, settypeId] = useState(1);
  const [loading, setLoading] = useState(false);

  const [columns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      }
    },
    {
      name: "Цагийн хөлс гэрээт",
      fieldName: "cONTRACTECNAME",
      minWidth: 200,
      maxWidth: 200
    }, {
      name: "Цагийн хөлс нэмэгдэл",
      fieldName: "aDDECNAME",
      minWidth: 200,
      maxWidth: 200,
    }, {
      name: "Илүү цаг",
      fieldName: "oVERTIMEECNAME",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEdit(item.iD)}
            />
          </div>
        );
      },
    }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getHourlyWage {
            iD
            oVERTIMEECNAME
            cONTRACTECNAME
            aDDECNAME
          }
        }
      }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getHourlyWage;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setId();
    setShowNew(true);
  };


  const onDismiss = () => {
    setId();
    setShowNew(false);
  }

  const onEdit = (pId) => {
    setId(pId);
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  return (
   <Layout style={{ minHeight: "95vh" }}>
     
     <Layout>
       <PageHeader
         title="Нийт"
         dataCount={data?.length}
         buttonTitle="Нэмэх"
         onButtonClick={onNew}
         onSearch={onSearch}
         data={data}
         columns={columns}
       />
       <div style={{ margin: 16 }}>
       <GridDefault columns={columns} data={data} loading={loading}></GridDefault>
       <OvertimeWagePanel
         id={id}
         typeid={typeid}
         isOpen={showNew}
         onDismiss={onDismiss}
         afterSave={getData}
       ></OvertimeWagePanel>
       </div>
     </Layout>
   </Layout>
 );
};

export default OvertimeWage;
