import { message } from 'antd';
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, LoadSpin, FieldCheck } from 'dw-components';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { UserContext } from '../../../Context/UserContext';
import { STRATEGYPLAN } from '../../../Const/StrategyPlan';

const StrategyPlanPanel = (props) => {

    const [initData] = useState({
        //iSACTIVE: true
    });
    const [data, setData] = useState({ ...initData });
    const { userInfo } = useContext(UserContext);
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dSPlan, setDSPlan] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const getData = async () => {
        setLoadingData(true);
        
        let tData = { ...initData };
        if (props?.id) {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                        getSPlan(id: $id) {
                        iD
                        pARENTID
                        cODE
                        nAME
                        sPLANTYPEID
                        sPLANTYPENAME
                        cREATED
                        cREATEDBY
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(props?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getSPlan;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(response => {
                setLoadingData(false);
            });
        }

        let tDSPlan = [];
        await APIGraphQL.post("", {
            query: `query{
                sharedQuery {
                    getParentSPlan {
                    key: iD
                    text: nAME
                   }
                 }
               }`
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getParentSPlan;
            if (tResp?.length > 0) {
                tDSPlan = tResp;
            }
        }).catch(response => {
            setLoadingData(false);
        });
        setDSPlan(tDSPlan);

        setData(tData);
        setLoadingData(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const onDismiss = () => {
        setData({ ...initData });
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const fieldOnChanged = (key, text, field, fieldname) => {
        if (field) {
            if (field == "sPLANTYPEID") {

            }
            if (field == "pARENTID") {
                let tSubSPlans = [];
                APIGraphQL.post("", {
                    query: `query($splanid: Int){
                        sharedQuery {
                            getSPlanAllByParentId(splanid: $splanid) {
                                key: iD
                                text: nAME
                            }
                        }
                    }`,
                    variables: {
                        "splanid": key ?? 1
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.getSPlanAllByParentId;
                    if (tResp?.length > 0) {
                        tSubSPlans = tResp;
                    }
                    setDSubSPlan(tSubSPlans);
                }).catch(response => {
                    setLoadingData(false);
                });
            }

            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }

    const onSave = async () => {
        let vRule = true;
        setCheckRule(true);

        let tData = { ...data };

        if (!tData?.pARENTID ||
            !tData?.cODE ||
            !tData?.nAME ||
            !tData?.sPLANTYPEID ||
            !tData?.sPLANTYPENAME)
            vRule = false;
        if (vRule) {

            let tSuccess = false;
            if (!props.id) {
                tData.iD = 0;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
            }

            setLoading(true);
            await APIGraphQL.post("", {
                query: `mutation ($data: StrategyPlanInputType) {
                  sharedMutation {
                    addSPlan(data: $data) {
                      iD
                    }
                  }
                }`,
                variables: {
                    "data": tData
                }
            }).then(response => {
                setLoading(false);
                if (response?.data?.data?.sharedMutation?.addSPlan?.iD) {
                    tSuccess = true;
                }

            }).catch(() => {
                setLoading(false);
            });



            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    }

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
          query: `mutation deleteSPlan($id: Int) {
            sharedMutation {
                deleteSPlan(id: $id)
            }
          }`,
          variables: {
            "id": parseInt(props.id)
          }
        }).then(response => {
          if (response?.data?.data?.sharedMutation?.deleteSPlan == 1) {
            tSuccess = true;
            if (props.afterSave) {
              props.afterSave();
            }
          }
          if (tSuccess) {
            message.success("Амжилттай.");
            onDismiss();
          }
        });
      }

    return (
        <PanelBase
            headerText={"Стратегийн төлөвлөгөөний хэмжих үзүүлэлт"}
            isOpen={props.id == 1 ? null :props.isOpen}
            onDismiss={onDismiss}
            delete={props.id > 1 ? true : false}
            onDelete={onDelete}
            PanelType="medium"
            buttons={loading
                ? <LoadSpin />
                : <ButtonDefault
                    text={"Хадгалах"}
                    onClick={onSave}
                />
            }
        >
            <div>
                <div style={{ marginTop: 16 }}>
                    <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Хэмжих үзүүлэлт" keyfield="pARENTID" defaultValue={data.pARENTID} checkreq={checkRule} data={dSPlan} />
                </div>
                <div style={{ marginTop: 16 }}> 
                <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Ангилал" keyfield="sPLANTYPEID" keyfieldname="sPLANTYPENAME" defaultValue={data.sPLANTYPEID} data={STRATEGYPLAN} checkreq={checkRule} /></div>
                <div style={{ marginTop: 16 }}>
                    <FieldText loading={loadingData} required onChanged={fieldOnChanged} title="Товчилсон нэр" keyfield="cODE" defaultValue={data.cODE} checkreq={checkRule} /> </div>
                <div style={{ marginTop: 16 }}>
                    <FieldText loading={loadingData} required onChanged={fieldOnChanged} title="Нэр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} /> </div>
            </div>
        </PanelBase>
    );
}

export default StrategyPlanPanel;