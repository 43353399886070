import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, LoadSpin, PanelBase } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";

const RepairCostPanel = (props) => {
  const [data, setData] = useState({});
  const [dECat, setDECat] = useState([]);

  const [checkRule, setCheckRule] = useState(false);
  
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);

    let tData = {};
    let tDataCat = [];
    await APIGraphQL.post("", {
      query: `query ($id: Int) {
        sharedQuery {
          getRepairCost(id: $id) {
            iD
            nAME
            eCONOMYCATEGORYID
            eCONOMYCATEGORYNAME
          }
          ecoCatByCalcType(calctypeid: 80) {
            key: iD
            text: fULLNAME
          }
        }
      }`,
      variables: {
        "id": parseInt(props?.id)
      }
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getRepairCost;
      if (tResp?.iD) {
        tData = tResp;
      }
      let tRespcat = response?.data?.data?.sharedQuery?.ecoCatByCalcType;
      if (tRespcat?.length > 0) {
        tDataCat = tRespcat;
      }
    }).catch(() => {
      setLoading(false);
    });
    setData(tData);
    setDECat(tDataCat);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.eCONOMYCATEGORYID ||
      !data?.nAME) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: RepairCostInputType) {
          sharedMutation {
            addRepairCost(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addRepairCost?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteBaseCost($id: Int) {
        sharedMutation {
          deleteRepairCost(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteRepairCost == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" checkreq={checkRule} defaultValue={data.eCONOMYCATEGORYID} data={dECat} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldText required loading={loading} onChanged={fieldOnChanged} title="Зардлын ангиллын нэр" keyfield="nAME" checkreq={checkRule} defaultValue={data.nAME} />
      </div>

    </PanelBase>
  );
};

export default RepairCostPanel;
