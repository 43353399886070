import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from "react-router-dom";
import { LoadScreen, Number, FieldNumberFormat, ButtonDefault, Warning } from 'dw-components';
import { API } from '../../../../API/API';
import { Button, Row, Col, Space, message, Modal } from 'antd';
import { TreeList } from 'devextreme-react';
import { Column, Scrolling, Selection } from 'devextreme-react/tree-list';
import { UserContext } from "../../../../Context/UserContext";
import AmountShortenButton from "../../../../Components/AmountShortenButton/AmountShortenButton";
import { ReactComponent as History } from '../../../../svg/history.svg';
import { APIGraphQL } from "../../../../API/APIGraphQL";

const AllocationNewPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const params = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shorten, setShorten] = useState(1);
    const [dataEdit, setDataEdit] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const modalWarning = (econame) => {
        Modal.warning({
            okText: "Буцах",
            title: 'Анхааруулга',
            content: (
                <div>
                  <p>Батлагдсан төсөв, хуваарилагдсан төсвийн утгууд ижил байх шаардлагатай.</p>
                  <p style={{color: "red", textIndent: 20}}>{econame}</p>
                </div>
              ),
        });
      };
    const dMonths = [
      {key:1, text: "1 сар"},
      {key:2, text: "2 сар"},
      {key:3, text: "3 сар"},
      {key:4, text: "4 сар"},
      {key:5, text: "5 сар"},
      {key:6, text: "6 сар"},
      {key:7, text: "7 сар"},
      {key:8, text: "8 сар"},
      {key:9, text: "9 сар"},
      {key:10, text: "10 сар"},
      {key:11, text: "11 сар"},
      {key:12, text: "12 сар"},
    ];

    const getData = async () => {
        setLoading(true);
        let tData = [];
        if (params?.id) {
            await API.get("/api/budget/budgetecocatallocations?id=" + params?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
        }

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        setData(tData);
        setDataEdit(tData);
        setLoading(false);
    }

    const onOptionChanged = useCallback((e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    }, []);

    const getChildSummary = (p_node, p_col) => {
        let tSummary = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tSummary += getChildSummary(p_node.children[i], p_col);
            }
        } else {
            if (!isNaN(parseFloat(p_node?.data?.[p_col]))) {
                return parseFloat(p_node?.data?.[p_col]);
            } else {
                return 0;
            }
        }
        return tSummary;
    }

    const onNodesInitialized = useCallback((e) => {
        e.component.forEachNode((node) => {
            if (node?.data?.ischild == 0) {
                let tValue = getChildSummary(node, "total");
                node.data["total"] = parseFloat(tValue);

                Object.keys(node?.data).map(key => {
                    if (!isNaN(parseFloat(key))) {
                        let tValue = getChildSummary(node, key);
                        node.data[key] = parseFloat(tValue);
                    }
                });

            }
        });
    }, []);

    useEffect(async () => {
        getData();
    }, [params?.id]);

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        dataEdit[pIndex][pField] = pValue;
        dataEdit[pIndex].edit = true;
    }

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0) {
          let isvalid = true;
          tDataEdit.filter(r=>r.ischild == 1).forEach((el)=>{
            el.amount = 0;
            dMonths.forEach((col)=>{
              el.amount += el[col.key];
            })
            if(el.budgetamount !== el.amount) {
                modalWarning(el.fullname);
                isvalid = false;
                setData(dataEdit);
                return;
            }
          })
          if(isvalid) {
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < dMonths.length; j++)
                {
                    let tRNew = {};
                    tRNew.iD = tR[dMonths[j].key.toString() + "_ID"];
                    tRNew.bUDGETID = tR.budgetid;
                    tRNew.bUDALLID = parseInt(params?.id);
                    tRNew.eCOCATID = tR.ecocatid;
                    tRNew.eCOCATPARENTID = tR.ecocatparentid;
                    tRNew.bUDGETAMOUNT = tR[dMonths[j].key.toString() + "_BUDGET"];
                    tRNew.mONTH = dMonths[j].key.toString();
                    tRNew.aMOUNT = tR[dMonths[j].key];
                    tRNew.cREATED = tR.created;
                    tRNew.cREATEDBY = tR.createdby;
                    tBSecDtl[k] = tRNew;
                    k += 1;
                }
            }
  
            setLoading(true);
            await APIGraphQL.post("", {
                query: `mutation ($data: [BudgetEcoCatAllocationInputType]) {
                  budgetMutation {
                    addbudgetecocatallocations(data: $data) {
                      iD
                    }
                  }
                }
                `,
                variables: {
                    "data": tBSecDtl
                }
            }).then(response => {
                setLoading(false);
                if (response?.data?.data?.budgetMutation?.addbudgetecocatallocations?.length > 0) {
                    message.success("Амжилттай");
                }

            }).catch(response => {
                setLoading(false);
            });

            await getData();
          }
      }
    }

    if (loading) {
        return <LoadScreen />
    }

    return (
      <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
          <div style={{ padding: 16, backgroundColor: "#ffffff", height: 56, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                <div style={{ marginRight: 8 }}>
                    <Row gutter={16} justify="space-between">
                        <Col>
                            <Row gutter={8} justify="start" align="top">
                                <Col>
                                    <h3 className="budget-title">{data[0]?.year}</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={16} justify="end">
                                <Space>
                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                        <History />
                                        <div style={{ fontSize: 14, marginLeft: 4, marginRight: 8, fontWeight: "600" }}>Түүх</div>
                                    </div>
                                      <ButtonDefault
                                          text="Хуваарилах"
                                          onClick={() => onSave()}
                                      />
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </div>
        </div>
        <div className='defaultTreeList' style={{ marginTop: 16 }}>
            <div style={{  marginRight: 8, marginLeft: 8 }}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Row>
                        <Col>
                            <AmountShortenButton setShorten={setShorten}/>
                        </Col>
                        <Col>
                            <Button
                                style={{ marginLeft: 16 }}
                                type={expanded ? null : "primary"}
                                onClick={() => {
                                    setExpanded(prevExpanded => !prevExpanded)
                                    setExpandedRowKeys([]);
                                }}
                                size="small"
                                >{expanded ? 'Бүгдийг хаах' : 'Бүгдийг нээх'}</Button>
                        </Col> 
                    </Row>
                </Row>
            </div>
            <TreeList
                id="ecocatid"
                dataSource={data}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                keyExpr="ecocatid"
                parentIdExpr="ecocatparentid"
                showRowLines
                showBorders
                rowAlternationEnabled={true}
                autoExpandAll={expanded}
                expandedRowKeys={expandedRowKeys}
                onOptionChanged={onOptionChanged}
                onNodesInitialized={onNodesInitialized}
            >
                <Scrolling columnRenderingMode="standard" mode="standard" />
                <Selection mode="single" />
                <Column
                    minWidth={500}
                    dataField="fullname"
                    caption="Эдийн засгийн ангилал"
                    fixed={true} />
                <Column
                    dataField="budgetamount"
                    caption="Батлагдсан төсөв"
                    alignment={"center"}
                    minWidth={120}
                    cellRender={item => {
                        return (
                              <Number value={item.data?.budgetamount}/>
                          );
                    }} />
                    <Column
                        dataField="amount"
                        caption="Хуваарилсан төсөв"
                        minWidth={120}
                        alignment={"center"}
                        cellRender={item => {
                          if (item?.data?.budgetamount != item?.data?.amount) {
                            return (
                              <div style={{color:"red"}}>
                                <Number value={item.data?.amount}/>
                              </div>
                              );
                            }
                            else {
                              return (
                                  <Number value={item.data?.amount}/>
                                );
                              }
                        }} />
                {dMonths?.map(r => {
                    return (
                            <Column
                                key={r.key}
                                caption={r.text} 
                                alignment={"center"}
                                dataField={r.key?.toString()}
                                width={100}
                                format={shorten == 1 ? "fixedPoint" : customFormat}
                                cellRender={item => {
                                if (item?.data?.ischild == 1) {
                                    return <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, r.key)} defaultValue={item.data?.[r.key]} />
                                } else {
                                    return (
                                          <Number value={item.data?.[r.key]}/>
                                      );
                                }
                                }}
                            />
                    );
                })}
            </TreeList>
        </div>
        </div>
    );
}

export default AllocationNewPanel;