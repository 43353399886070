import React, { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import { Space } from "antd";
import BuildingPanel from "./Panels/BuildingPanel";
import { API } from '../../../API/API';
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import { APIGraphQL } from "../../../API/APIGraphQL";
import { PageHeader, GridDefaultShimmerStick } from "dw-components";
import FileDownload from 'js-file-download';

const Buildings = () => {

  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState(0);
  const [data, setData] = useState({});
  const [oData, setOData] = useState([]);
  const [gridFilter, setGridFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [buildingColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    },
    {
      name: "Байр",
      fieldName: "buildingname",
      maxWidth: 100,
      minWidth: 150
    },
    {
      name: "Өрөөний дугаар, код",
      fieldName: "roomcode",
      maxWidth: 100,
      minWidth: 150
    },
    {
      name: "Харъяаллын үндсэн нэгж",
      fieldName: "mainschoolname",
      colSize: "mid",
      maxWidth: 200,
      minWidth: 200
    },
    {
      name: "Харъяалагдах нэгж",
      fieldName: "schoolname",
      colSize: "mid",
      maxWidth: 100,
      minWidth: 150
    },
    {
      name: "Өрөөний зориулалт",
      fieldName: "roomusagename",
      colSize: "mid",
      maxWidth: 100,
      minWidth: 150
    },
    {
      name: "Талбай хэмжээ",
      fieldName: "squaresize",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 100
    },
    {
      name: "Ашигтай талбай",
      fieldName: "usefulsquare",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 120
    },
    {
      name: "Нийтийн эзэмшлийн талбай",
      fieldName: "publicsquare",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 200,
      minWidth: 200
    },
    {
      name: "Эзлэхүүн",
      fieldName: "volume",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 100
    },
    {
      name: "Суудал/Орны тоо",
      fieldName: "seatqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 150,
      minWidth: 150
    },
    {
      name: "Цонхны тоо",
      fieldName: "windowqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 100
    },
    {
      name: "Цонхны хийцлэл",
      fieldName: "windowdesignname",
      colSize: "mid",
      maxWidth: 120,
      number: true,
      minWidth: 120
    },
    {
      name: "Суултуурын тоо",
      fieldName: "toiletseatqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 120
    },
    {
      name: "Шээлтүүрийн тоо",
      fieldName: "urinalqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 120
    },
    {
      name: "Угаалтуурын тоо",
      fieldName: "sinkqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 120
    },
    {
      name: "Шүршүүрийн тоо",
      fieldName: "showerqty",
      colSize: "mid",
      summary: true,
      number: true,
      maxWidth: 120,
      minWidth: 120
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      },
    },
  ]);

  const [filterValue, setFilterValue] = useState('class');
  const [filterData, setFilterData] = useState([{
    key: 'class',
    text: 'Хичээлийн байр'
  }, {
    key: 'dorm',
    text: 'Оюутны байр'
  }]);

  const getData = async (p_filter) => {
    setLoading(true);

    let tGridFilter = [];
    let tFitler = [];
    if (p_filter?.length > 0) {
      tFitler = p_filter;
    }

    let tData = [];
    let typeid = filterValue == 'class' ? 1 : 2;
    await API.post("/api/budget/buildingConfigList?typeid=" + typeid, tFitler).then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.length > 0) {
          tData = response?.data?.retData;
        }
      }
    }).catch(() => {
      setLoading(false);
    });

    await APIGraphQL.post("", {
      query: `{
        sharedQuery 
        {
          getBuildingListAll {
            key: iD
            text: nAME
          }
      }
    }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getBuildingListAll;
      if (tResp?.length > 0) {
        tGridFilter.push({
          key: "building.id",
          text: "Байр",
          data: tResp
        });
      }
    });

    await API.get("/api/budget/filterObjectSchools").then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.length > 0) {
          tGridFilter.push({
            key: "buildingdetail.schoolid",
            text: "Харъяалагдах нэгж",
            data: response?.data?.retData
          });
        }
      }
    }).catch(() => {
      setLoading(false);
    });

    setLoading(false);
    setData(tData);
    setOData(tData);
    setGridFilter(tGridFilter);
  }

  useEffect(() => {
    getData();
  }, [filterValue]);

  const onNew = () => {
    setObjectId("");
    setShowNew(true);
  };

  const onDismiss = () => {
    setObjectId("");
    setShowNew(false);
  };

  const onChangeFilter = (p_key, p_name) => {
    setFilterValue(p_key);
  }

  const onSearch = (p_data) => {
    setData(p_data);
  }

  const onEditClick = (id) => {
    setObjectId(id);
    setShowNew(true);
  };

  const downloadExcel = async () => {
    let tFitler = [];
    if (filteredData?.length > 0) {
      tFitler = filteredData;
    }

    let typeid = filterValue == 'class' ? 1 : 2;
    await API.post("/api/budget/buildingConfigListTemp?typeid=" + typeid + "&filetype=xlsx", tFitler, {
      'responseType': 'blob'
    }).then(response => {
      FileDownload(response.data, "Анги_танхим,_өрөө_тасалгаа.xlsx");

    }).catch(response => {
      setLoadingDownload(false);
    });
    setLoadingDownload(false);
  }

  const onGridFilterChange = async (p_gridfiltervalue) => {
    setFilteredData(p_gridfiltervalue);
    getData(p_gridfiltervalue);
  }

  return (
    <div>
      <PageHeader
        showLeftFilter
        changeFilter={onChangeFilter}
        filterValue={filterValue}
        filterData={filterData}
        dataCount={data?.length}
        buttonTitle="Анги танхим, өрөө тасалгаа"
        onButtonClick={onNew}
        columns={buildingColumns}
        onSearch={onSearch}
        data={oData}
        filteredData={data}
        loading={loading}
        addRightButton={(
          <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
            <Space size={16} >
              <div onClick={downloadExcel}>
                <ExcelIcon />
              </div>
            </Space>
          </div>
        )}
        gridFilter={gridFilter}
        onGridFilterChange={onGridFilterChange}
      />
      <GridDefaultShimmerStick columns={buildingColumns} data={data} loading={loading} summary />
      <BuildingPanel
        id={objectId}
        typeid={filterValue}
        isOpen={showNew}
        onDismiss={onDismiss}
        afterSave={getData}
      ></BuildingPanel>
    </div>
  );
}
export default Buildings;
