import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdown, FieldText, ButtonSecondary } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ChangeRatePanel from "../BudgetDtlIndicator/ChangeRatePanel/ChangeRatePanel";

const BetweenCostPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showChangeRate, setShowChangeRate] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState("budgetbetweencostaut");
    const [isEdit, setIsEdit] = useState(false);
    const [isClickSave, setIsClickSave] = useState(false);

    const seasonData = [{
        key: "budgetbetweencostaut",
        text: "Намар"
    }, {
        key: "budgetbetweencostspr",
        text: "Хавар"
    }, {
        key: "all",
        text: "Бүгд"
    }]

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);
        let vSuccess = false;

        if (tDataEdit?.length > 0 && props?.id) {
                setLoadingSave(true);
                let tNewDataEdit = [];
                for (let i = 0; i < tDataEdit.length; i++) {

                    let tR = { ...tDataEdit[i] };

                    let tRNew = {};

                    tRNew.iD = parseInt(tR.id ?? 0);
                    tRNew.bUDGETID = parseInt(props?.id);
                    tRNew.bETWEENCOSTSCHOOLID = parseInt(tR.betweencostschoolid ?? 0);
                    tRNew.sCHOOLID = parseInt(tR.schoolid);
                    tRNew.sCHOOLNAME = tR.schoolname;
                    tRNew.bACIMPSTUQTY = parseFloat(tR.bacimpstuqty ?? 0);
                    tRNew.bACIMPSTUBATCH = parseFloat(tR.bacimpstubatch ?? 0);
                    tRNew.bACIMPSTUTOTAL = parseFloat(tR.bacimpstutotal ?? 0);
                    tRNew.bACEXPSTUQTY = parseFloat(tR.bacexpstuqty ?? 0);
                    tRNew.bACEXPSTUBATCH = parseFloat(tR.bacexpstubatch ?? 0);
                    tRNew.bACEXPSTUTOTAL = parseFloat(tR.bacexpstutotal ?? 0);
                    tRNew.mASIMPSTUQTY = parseFloat(tR.masimpstuqty ?? 0);
                    tRNew.mASIMPSTUBATCH = parseFloat(tR.masimpstubatch ?? 0);
                    tRNew.mASIMPSTUTOTAL = parseFloat(tR.masimpstutotal ?? 0);
                    tRNew.mASEXPSTUQTY = parseFloat(tR.masexpstuqty ?? 0);
                    tRNew.mASEXPSTUBATCH = parseFloat(tR.masexpstubatch ?? 0);
                    tRNew.mASEXPSTUTOTAL = parseFloat(tR.masexpstutotal ?? 0);
                    tRNew.dOCIMPSTUQTY = parseFloat(tR.docimpstuqty ?? 0);
                    tRNew.dOCIMPSTUBATCH = parseFloat(tR.docimpstubatch ?? 0);
                    tRNew.dOCIMPSTUTOTAL = parseFloat(tR.docimpstutotal ?? 0);
                    tRNew.dOCEXPSTUQTY = parseFloat(tR.docexpstuqty ?? 0);
                    tRNew.dOCEXPSTUBATCH = parseFloat(tR.docexpstubatch ?? 0);
                    tRNew.dOCEXPSTUTOTAL = parseFloat(tR.docexpstutotal ?? 0);
                    tRNew.tOTIMPSTUQTY = parseFloat(tR.totimpstuqty ?? 0);
                    tRNew.tOTIMPSTUBATCH = parseFloat(tR.totimpstubatch ?? 0);
                    tRNew.tOTIMPSTUTOTAL = parseFloat(tR.totimpstutotal ?? 0);
                    tRNew.tOTEXPSTUQTY = parseFloat(tR.totexpstuqty ?? 0);
                    tRNew.tOTEXPSTUBATCH = parseFloat(tR.totexpstubatch ?? 0);
                    tRNew.tOTEXPSTUTOTAL = parseFloat(tR.totexpstutotal ?? 0);

                    if (!tRNew.iD || tRNew.iD == 0) {
                        tRNew.iD = 0;
                        tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                    }
                    tRNew.cREATEDBY = userInfo?.username;
                    tNewDataEdit.push({ ...tRNew });
                }
                // if (selectedSeason == "budgetbetweencostaut") {
                //     await APIGraphQL.post("", {
                //         query: `mutation ($data: [BudgetBetweenCostAutInputType]) {
                //         budgetMutation {
                //             addBudgetBetweenCostAut(data: $data) {
                //                 iD
                //             }
                //         }
                //     }`,
                //         variables: {
                //             "data": tNewDataEdit
                //         }
                //     }).then(response => {
                //         if (response?.data?.data?.budgetMutation?.addBudgetBetweenCostAut?.length > 0) {
                //             vSuccess = true;
                //             message.success("Амжилттай");
                //         }
                //     });
                // } else if (selectedSeason == "budgetbetweencostspr") {
                //     await APIGraphQL.post("", {
                //         query: `mutation ($data: [BudgetBetweenCostSprInputType]) {
                //             budgetMutation {
                //                 addBudgetBetweenCostSpr(data: $data) {
                //                     iD
                //                 }
                //             }
                //         }`,
                //         variables: {
                //             "data": tNewDataEdit
                //         }
                //     }).then(response => {
                //         if (response?.data?.data?.budgetMutation?.addBudgetBetweenCostSpr?.length > 0) {
                //             vSuccess = true;
                //             message.success("Амжилттай");
                //         }
                //     });
                // }
                await API.post("api/budget/calcBetweenCost?selectedSeason= " + selectedSeason + "&budgetid=" + props?.id, tNewDataEdit).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                });
                setLoading(false);
                
                if (vSuccess) {
                    props?.onDismiss();
                }
            } else {
                props.onDismiss();
            }
            await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/budgetBetweenCostTemp?budgetid=" + props?.id + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Сургууль хоорондын тооцоо" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getAttachData = async () => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + userInfo?.schoolid + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }
    const getData = async (pSeasonId) => {

        let tDSchool = [];
        let tData = [];

        setLoading(true);
        await API.get("/api/budget/getBetweenCostList?budgetid=" + props?.id + "&tablename=" + (pSeasonId ? pSeasonId : selectedSeason))
            .then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData.length > 0) {
                        tData = response?.data?.retData;
                    }
                }
            }).catch(() => {
                setLoading(false);
            });

        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getBetweenCostSchoolList{
                        sCHOOLNAME
                        sCHOOLID
                        }
                    }
                }`,
        }).then(response => {
            let tResp = response?.data?.data.sharedQuery?.getBetweenCostSchoolList;
            if (tResp?.length > 0) {
                tDSchool = tResp;
            }
        }).catch(() => {
            setLoading(false);
        });

        if (selectedSeason != "all") {
            tDSchool.map(r => {
                if (!tData?.map(ri => ri.schoolid).includes(r?.sCHOOLID)) {
                    tData.push({
                        schoolid: r?.sCHOOLID,
                        schoolname: r?.sCHOOLNAME
                    });
                }
            });
        }

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        await getAttachData();
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setIsEdit(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "bacimpstuqty" || pField == "masimpstuqty" || pField == "docimpstuqty") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totimpstuqty"] = ((tDataEdit[pIndex]["bacimpstuqty"] ?? 0) + (tDataEdit[pIndex]["masimpstuqty"] ?? 0) + (tDataEdit[pIndex]["docimpstuqty"] ?? 0));
        }

        if (pField == "bacimpstubatch" || pField == "masimpstubatch" || pField == "docimpstubatch") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totimpstubatch"] = ((tDataEdit[pIndex]["bacimpstubatch"] ?? 0) + (tDataEdit[pIndex]["masimpstubatch"] ?? 0) + (tDataEdit[pIndex]["docimpstubatch"] ?? 0));
        }

        if (pField == "bacimpstutotal" || pField == "masimpstutotal" || pField == "docimpstutotal") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totimpstutotal"] = ((tDataEdit[pIndex]["bacimpstutotal"] ?? 0) + (tDataEdit[pIndex]["masimpstutotal"] ?? 0) + (tDataEdit[pIndex]["docimpstutotal"] ?? 0));
        }
        if (pField == "bacexpstuqty" || pField == "masexpstuqty" || pField == "docexpstuqty") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totexpstuqty"] = ((tDataEdit[pIndex]["bacexpstuqty"] ?? 0) + (tDataEdit[pIndex]["masexpstuqty"] ?? 0) + (tDataEdit[pIndex]["docexpstuqty"] ?? 0));
        }

        if (pField == "bacexpstubatch" || pField == "masexpstubatch" || pField == "docexpstubatch") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totexpstubatch"] = ((tDataEdit[pIndex]["bacexpstubatch"] ?? 0) + (tDataEdit[pIndex]["masexpstubatch"] ?? 0) + (tDataEdit[pIndex]["docexpstubatch"] ?? 0));
        }

        if (pField == "bacexpstutotal" || pField == "masexpstutotal" || pField == "docexpstutotal") {
            let tR = tDataEdit[pIndex];
            tDataEdit[pIndex]["totexpstutotal"] = ((tDataEdit[pIndex]["bacexpstutotal"] ?? 0) + (tDataEdit[pIndex]["masexpstutotal"] ?? 0) + (tDataEdit[pIndex]["docexpstutotal"] ?? 0));
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);

        setIsEdit(true);
    }

    const fieldOnChangedSeason = async (pId, pValue, pField, pNameValue, pFieldName) => {
        setSelectedSeason(pId);
        await getData(pId);
    }

    const onDismiss = () => {
        setShowChangeRate(false);
        if (props.onDismiss) {
            if (isClickSave) {
                props.afterSave();
            }
            props.onDismiss();
        }
    }

    const onShowChangeRate = async () => {
        setShowChangeRate(true);
    }

    const onChangeRate = async (rateData) => {
        setLoadingSave(true);
        let vSuccess = false;
        if(rateData?.sCHOOLIDS) {
            await API.post("/api/budget/changeBudgetRateBetweenCost?selectedseason="+ selectedSeason +"&userschoolid=" + userInfo?.schoolid,
                rateData
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    message.success("Амжилттай");
                    vSuccess = true;
                }
                else if (response?.data.retType == 2) {
                    message.warning("Хувь оруулна уу!");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
        }

        if (vSuccess) {
            await props.afterSave();
        }
        setLoadingSave(false);
        onDismiss();
    }

    return (
        <PanelBase
            headerText="Сургууль хоорондын тооцоо"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 16 }}>
                        {loadingSave ? (<LoadSpin />) :
                            (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                    </div>
                    {userInfo?.schooltypeid == 1 &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onShowChangeRate} text="Хувиар өөрчлөх" />)}
                        </div>
                    }
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>

            }
        >
            <div style={{ marginTop: 13 }}>
                <FieldDropdown disable={isEdit} loading={loading} onChanged={fieldOnChangedSeason} title="Улирал" keyfield="seasonid" keyfieldname="seasonname" defaultValue={selectedSeason} data={seasonData} />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                /мян.төгрөг/
            </div>
            <div style={{ marginTop: 24 }}>
                <DataGrid
                    dataSource={data}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    showRowLines
                    showBorders
                >
                    <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                    <Column width={50} caption="№" alignment='center' allowResizing={true}
                        cellRender={(item) => {
                            return (
                                <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                            );
                        }}
                    ></Column>
                    <Column
                        dataField="schoolname"
                        caption="Сургууль, нэгжийн нэр"
                        width={250}
                        alignment='center'
                        format="fixedPoint"
                        cellRender={(item) => {
                            return (
                                <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.schoolname} />
                            );
                        }} />
                    <Column caption="Бакалавр" alignment='center'>
                        <Column caption="Экспорт" alignment='center'>
                            <Column
                                dataField="bacexpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacexpstuqty")} defaultValue={item.data?.bacexpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="bacexpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacexpstubatch")} defaultValue={item.data?.bacexpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="bacexpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacexpstutotal")} defaultValue={item.data?.bacexpstutotal} />
                                    );
                                }} />
                        </Column>
                        <Column caption="Импорт" alignment='center'>
                            <Column
                                dataField="bacimpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacimpstuqty")} defaultValue={item.data?.bacimpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="bacimpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacimpstubatch")} defaultValue={item.data?.bacimpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="bacimpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "bacimpstutotal")} defaultValue={item.data?.bacimpstutotal} />
                                    );
                                }} />
                        </Column>
                    </Column>
                    <Column caption="Магистр" alignment='center'>
                        <Column caption="Экспорт" alignment='center'>
                            <Column
                                dataField="masexpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masexpstuqty")} defaultValue={item.data?.masexpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="masexpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masexpstubatch")} defaultValue={item.data?.masexpstubatch} />

                                    );
                                }} />
                            <Column
                                dataField="masexpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masexpstutotal")} defaultValue={item.data?.masexpstutotal} />
                                    );
                                }} />
                        </Column>
                        <Column caption="Импорт" alignment='center'>
                            <Column
                                dataField="masimpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masimpstuqty")} defaultValue={item.data?.masimpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="masimpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masimpstubatch")} defaultValue={item.data?.masimpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="masimpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "masimpstutotal")} defaultValue={item.data?.masimpstutotal} />
                                    );
                                }} />
                        </Column>
                    </Column>
                    <Column caption="Доктор" alignment='center'>
                        <Column caption="Экспорт" alignment='center'>
                            <Column
                                dataField="docexpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docexpstuqty")} defaultValue={item.data?.docexpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="docexpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docexpstubatch")} defaultValue={item.data?.docexpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="docexpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docexpstutotal")} defaultValue={item.data?.docexpstutotal} />
                                    );
                                }} />
                        </Column>
                        <Column caption="Импорт" alignment='center'>
                            <Column
                                dataField="docimpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docimpstuqty")} defaultValue={item.data?.docimpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="docimpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docimpstubatch")} defaultValue={item.data?.docimpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="docimpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={selectedSeason == "all" ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "docimpstutotal")} defaultValue={item.data?.docimpstutotal} />
                                    );
                                }} />
                        </Column>
                    </Column>
                    <Column caption="Бүгд дүн" alignment='center'>
                        <Column caption="Экспорт" alignment='center'>
                            <Column
                                dataField="totexpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totexpstuqty")} defaultValue={item.data?.totexpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="totexpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totexpstubatch")} defaultValue={item.data?.totexpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="totexpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totexpstutotal")} defaultValue={item.data?.totexpstutotal} />
                                    );
                                }} />
                        </Column>
                        <Column caption="Импорт" alignment='center'>
                            <Column
                                dataField="totimpstuqty"
                                caption="Оюутны тоо"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totimpstuqty")} defaultValue={item.data?.totimpstuqty} />
                                    );
                                }} />
                            <Column
                                dataField="totimpstubatch"
                                caption="ОБц"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totimpstubatch")} defaultValue={item.data?.totimpstubatch} />
                                    );
                                }} />
                            <Column
                                dataField="totimpstutotal"
                                caption="Нийт багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat  fixedDecimalScale={true} disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "totimpstutotal")} defaultValue={item.data?.totimpstutotal} />
                                    );
                                }} />
                        </Column>
                    </Column>
                    <Summary>
                        <TotalItem
                            column="№"
                            displayFormat="Нийт:"
                        />
                        <TotalItem
                            column="bacexpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="bacexpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="bacexpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="bacimpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="bacimpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="bacimpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="masexpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="masexpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="masexpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="masimpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="masimpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="masimpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="docexpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="docexpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="docexpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="docimpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="docimpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="docimpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="totexpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="totexpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="totexpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                        <TotalItem
                            column="totimpstuqty"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="totimpstubatch"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="totimpstutotal"
                            summaryType="sum"
                            valueFormat="#,##0.00"
                            alignment="right"
                            displayFormat="{0}₮"
                        />
                    </Summary>
                </DataGrid>

            </div>
            <div style={{ marginTop: 24 }}>
                <FieldUploadRest id={props?.indId} schoolid={userInfo?.schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(userInfo?.schoolid); props?.afterAttach(); }} />
            </div>
            <ChangeRatePanel isOpen={showChangeRate} budgetid={props?.id} indid={props?.indId} 
                onDismiss={onDismiss}
                title={"Сургууль хоорондын тооцоо /" + (seasonData[seasonData?.map(r => r.key).indexOf(selectedSeason)].text) + "/"}
                showRate={true}
                onChangeRate={onChangeRate}/>
        </PanelBase >
    );
}

export default BetweenCostPanel;