import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin } from "dw-components"; 
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";

const OvertimeWagePanel = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dECat, setDECat] = useState([]);
  const [dECat2, setDECat2] = useState([]);
  const {userInfo } = useContext(UserContext);

  const getData = async () => {

    let tData = [];
    let tDECat = [];
    let tDECat2 = [];
    await APIGraphQL.post("", {
      query: `query ($id: Int) {
      sharedQuery 
      {
        getHourlyWageConfig(id: $id) {
          iD
          oVERTIMEECID
          oVERTIMEECNAME
          cONTRACTECID
          cONTRACTECNAME
          aDDECID
          aDDECNAME
        }   
        
        ecoCatByCalcType(calctypeid: 63) {
          key: iD
          text: nAME
        }
      }
    }`,
      variables: {
        "id": parseInt(props?.id)
      },
    }).then(response => {
      let tSharedQuery = response?.data?.data?.sharedQuery;
      if (tSharedQuery?.getHourlyWageConfig?.iD) {
        tData = tSharedQuery?.getHourlyWageConfig;
      }

      if (tSharedQuery?.ecoCatByCalcType?.length > 0) {
        tDECat = tSharedQuery?.ecoCatByCalcType;
      }

    }).catch(() => {
      setLoading(false);
    });

    await APIGraphQL.post("", {
      query: `{
      sharedQuery 
      {        
        ecoCatByCalcType(calctypeid: 74) {
          key: iD
          text: nAME
        }
      }
    }`
    }).then(response => {
      let tSharedQuery = response?.data?.data?.sharedQuery;
      if (tSharedQuery?.ecoCatByCalcType?.length > 0) {
        tDECat2 = tSharedQuery?.ecoCatByCalcType;
      }

    }).catch(() => {
      setLoading(false);
    });

    setData(tData);
    setDECat(tDECat);
    setDECat2(tDECat2);
  }
  useEffect(() => {
    getData();
  }, [props?.id, props?.typeid]);

  const fieldOnChanged = async (key, text, field, fieldname, typeid) => {
    if (field) {
      let tData = {...data};
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  };

  const onSave = async () => {
    let isValid = true;
    let tData = {...data};

    if (!tData?.cONTRACTECID ||
        !tData?.aDDECID ||
        !tData?.oVERTIMEECID
    ) { isValid = false };

    if (isValid) {
      let tSuccess = false;
      setLoadingSave(true);
        await APIGraphQL.post("", {
          query: `mutation ($data: HourlyWageInputType) {
            sharedMutation {
              addHourlyWage(data: $data) {
                          iD
                      }
                  }
              }`,
          variables: {
            "data": tData,
          },
        }).then((response) => {
          setLoadingSave(false);
          if (response?.data?.data?.sharedMutation?.addHourlyWage?.iD) {
            message.success("Амжилттай");
            tSuccess = true;
          }
        }).catch(() => {
          setLoadingSave(false);
        })
        setLoadingSave(false);
  
        if (tSuccess) {
          onDismiss();
        }
    }
  }

  const onDismiss = () => {
    setData({});
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const onDelete = async () => {
    let tSuccess = false;
    if(props?.typeid == 1)
    {
      await APIGraphQL.post("", {
        query: `mutation delHourlyWage($id: Int) {
        sharedMutation {
          delHourlyWage(id: $id)
        }
      }`,
        variables: {
          "id": parseInt(props.id),
        },
      }).then((response) => {
        if (response?.data?.data?.sharedMutation?.delHourlyWage == 1) {
          tSuccess = true;
          if (props.afterSave) {
            props.afterSave();
          }
        }
      });      
    }
    else {
      await APIGraphQL.post("", {
        query: `mutation delOvertime($id: Int) {
        sharedMutation {
          delOvertime(id: $id)
        }
      }`,
        variables: {
          "id": parseInt(props.id),
        },
      }).then((response) => {
        if (response?.data?.data?.sharedMutation?.delOvertime == 1) {
          tSuccess = true;
          if (props.afterSave) {
            props.afterSave();
          }
        }
      });    
    }
    if (tSuccess) {
      message.success("Амжилттай");
      onDismiss();
    }
  };

  return (
    <PanelBase
      headerText={"Цагийн хөлс, илүү цаг тохиргоо нэмэх"}
      isOpen={props.isOpen}
      PanelType="medium"
      delete={props?.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
    >
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Цагийн хөлс гэрээт"
            keyfield="cONTRACTECID"
            keyfieldname="cONTRACTECNAME"
            defaultValue={data.cONTRACTECID}
            data={dECat2}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Цагийн хөлс нэмэгдэл"
            keyfield="aDDECID"
            keyfieldname="aDDECNAME"
            defaultValue={data.aDDECID}
            data={dECat}
          />
          </div>
          <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Илүү цаг"
            keyfield="oVERTIMEECID"
            keyfieldname="oVERTIMEECNAME"
            defaultValue={data.oVERTIMEECID}
            data={dECat}
          />
          </div>
    </PanelBase>
  );
};

export default OvertimeWagePanel;
