import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, ButtonSecondary, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import { VehicleRegion } from "../../Const/VehicleRegion";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { Icon } from '@fluentui/react/lib/Icon';
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const BudgetVehicleTax = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dTNumber, setdtNumber] = useState([]);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);

        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.sCHOOLNAME = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                }

                tR.sCHOOLID = parseInt(currentschoolid);
                tR.iD = tR.id;
                tR.bUDGETID = parseInt(props?.id);
                tR.vEHICLEID = tR.vehicleid;
                tR.mARK = tR.mark;
                tR.nUMBER = tR.number;
                tR.tYPEID = tR.typeid;
                tR.tYPENAME = tR.typename;
                tR.cATEGORYID = tR.categoryid;
                tR.cATEGORYNAME = tR.categoryname;
                tR.rEGIONID = tR.regionid;
                tR.rEGIONNAME = tR.regionname;
                tR.cYLINDER = tR.cylinder;
                tR.sEAT = tR.seat;
                tR.cARRYINGCAPACITY = tR.carryingcapacity;
                tR.tAX = tR.tax;
                tR.aIRPOLLUTIONTAX = tR.airpollutiontax;
                tR.hIGHWAYTAX = tR.highwaytax;
                tR.dIAGNOSTICTAX = tR.diagnostictax;
                delete tR.id;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.schoolname;
                delete tR.vehicleid;
                delete tR.mark;
                delete tR.number;
                delete tR.typeid;
                delete tR.typename;
                delete tR.categoryid;
                delete tR.categoryname;
                delete tR.regionid;
                delete tR.regionname;
                delete tR.cylinder;
                delete tR.seat;
                delete tR.carryingcapacity;
                delete tR.tax;
                delete tR.airpollutiontax;
                delete tR.highwaytax;
                delete tR.diagnostictax;
                delete tR.schoolcode;
                delete tR.depcode;
                delete tR.year;
                delete tR.arrayIndex;
                delete tR.edit;
                delete tR.created;
                delete tR.createdby;
                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;
                tDataEdit[i] = tR;
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetVehicleTaxInputType]) {
            //         budgetMutation {
            //             addBudgetVehicleTax(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetVehicleTax?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });

            await API.post("api/budget/calcBudgetVehicleTax?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });

            //vSuccess = await calcfunc("calcBudgetVehicleTax", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
            
            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDTNumber = [];

        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        setLoading(true);

        await API.get("/api/budget/getbudgetVehicleTax?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });
        await APIGraphQL.post("", {
            query: ` {
                        sharedQuery {
                            getVehicleList {
                                key: iD
                                text: nUMBER
                            }
                        }
                    }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getVehicleList?.length > 0) {
                tDTNumber = tResp?.sharedQuery?.getVehicleList;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setdtNumber(tDTNumber);
        setData(tData);
        setDataEdit(tData);
    }

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "vehicleid") {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                        sharedQuery {
                            getVehicle(id: $id) {
                                mark: mARK
                                typeid: tYPEID
                                typename: tYPENAME
                                categoryid: cATEGORYID
                                categoryname: cATEGORYNAME
                                cylinder: cYLINDER
                                carryingcapacity: cARRYINGCAPACITY
                                seat: sEAT
                                }
                            }
                         }`,
                variables: {
                    "id": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getVehicle;
                if (tResp) {
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                    setData(tDataEdit);
                }
            }).catch(() => {
                setLoading(false);
            });
        } else if (pField == "regionid") {
            await API.post("api/budget/getVehicleTaxFiltered?vehicleid=" + (tDataEdit[pIndex]["vehicleid"] || 0) + "&regionid=" + pValue).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData.length > 0) {
                        tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...response?.data?.retData[0] };
                        setData(tDataEdit);
                    }
                }
            }).catch(() => {
                setLoading(false);
            })
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });

        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation delBudgetVehicleTax($id: Int) {
                    budgetMutation {
                      delBudgetVehicleTax(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetVehicleTax == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBudgetVehicleTax?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&alldep=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
            }
             //vSuccess = await calcfunc("calcBudgetVehicleTax", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
          }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Авто машины татвар хураамж"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={!props.disable && !disable && (<div> {loadingSave ? (<LoadSpin />) : (<ButtonDefault text="Хадгалах" onClick={onSave} />)}</div>)}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөг/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                dataField="number"
                                caption="Машины дугаар"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "vehicleid", pNameValue, "number")} defaultValue={item.data?.vehicleid} data={dTNumber} />
                                    );
                                }} />
                            <Column
                                width={150}
                                dataField="mark"
                                caption="Машины марк"
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.mark} />
                                    );
                                }}
                            />
                            <Column
                                dataField="typename"
                                caption="Төрөл"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.typename} />
                                    );
                                }} />
                            <Column
                                dataField="categoryname"
                                caption="Ангилал"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.categoryname} />
                                    );
                                }} />
                            <Column
                                dataField="cylinder"
                                caption="Цилиндр багтаамж"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.cylinder} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="carryingcapacity"
                                caption="Даац"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.carryingcapacity} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="seat"
                                caption="Суудлын тоо"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.seat} />
                                    );
                                }} />
                            <Column
                                dataField="regionname"
                                caption="Харьяа"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "regionid", pNameValue, "regionname")} defaultValue={item.data?.regionid} data={VehicleRegion} />
                                    );
                                }} />
                            <Column
                                dataField="tax"
                                caption="АТБӨЯХ-н татвар"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.tax} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="airpollutiontax"
                                caption="Агаарын бохирдол татвар"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.airpollutiontax} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="highwaytax"
                                caption="Авто зам ашигласан татвар"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.highwaytax} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="diagnostictax"
                                caption="Техник үзлэг"
                                format="fixedPoint"
                                alignment='center'
                                minWidth={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.diagnostictax} fixedDecimalScale={true} />
                                    );
                                }} />
                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BudgetVehicleTax;