
import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, FieldText, ButtonSecondary } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const BEmpSupportPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);

    const onSave = async () => {
        let tDataEdit = [...dataEdit];

        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            let nDataEdit = [];
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                nDataEdit.push({
                    iD: parseInt(tR.id ?? 0),
                    bUDGETID: parseInt(props?.id),
                    sCHOOLID: parseInt(tR?.schoolid),
                    sCHOOLNAME: tR?.schoolname,
                    sUPPORTTYPEID: parseInt(tR?.supporttypeid ?? 0),
                    sUPPORTTYPENAME: tR?.supporttypename,
                    tRAININGLEVELID: parseInt(tR?.traininglevelid ?? 0),
                    tRAININGLEVELNAME: tR?.traininglevelname,
                    lESSONTYPEID: parseInt(tR?.lessontypeid ?? 0),
                    lESSONTYPENAME: tR?.lessontypename,
                    aMOUNT: parseFloat(tR?.amount ?? 0),
                    cREATED: tR?.cREATED ?? moment().format("YYYY.MM.DD HH:mm:SS"),
                    cREATEDBY: tR?.cREATEDBY ?? userInfo?.username,
                    oUTPUT: tR.output,
                    oUTCOME: tR.outcome,
                    iMPACT: tR.impact,
                    sPTOPPRID: tR.sptopprid,
                    sPTOPPRNAME: tR.sptopprname,
                    sPGOALID: tR.spgoalid,
                    sPGOALNAME: tR.spgoalname,
                    sPSTRID: tR.spstrid,
                    sPSTRNAME: tR.spstrname,
                    sPINDID: tR.spindid,
                    sPINDNAME: tR.spindname,
                    kPIID: tR.kpiid,
                    kPINAME: tR.kpiname
                });
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetEmpSupportInputType]) {
            //             budgetMutation {
            //                 addBudgetEmpSupport(data: $data) {
            //                 iD
            //               }
            //             }
            //           }`,
            //     variables: {
            //         "data": nDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetEmpSupport?.length > 0) {
                    
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            API.post("api/budget/calcBEmpSupp?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid + "&indid=" + props?.indId, nDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        // setLoadingDownload(true);
        // let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        // let alldep = isalldep;
        // if (alldep == 0) {
        //     alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        // }
        // if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
        //     pSchoolId = 0;
        // await API.get("/api/budget/budgetResearchFeeTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
        //     'responseType': 'blob'
        // }).then(response => {
        //     FileDownload(response.data, "Бичиг хэрэг" + "." + pData?.filetype);

        // }).catch(response => {
        //     setLoadingDownload(false);
        // });
        // setLoadingDownload(false);
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];



        setLoading(true);
        await API.get("/api/budget/budgetEmpSupport?budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data?.length > 0) {
                    tData = response?.data?.retData?.data;
                } else if (response?.data?.retData?.length > 0) {
                    tData = response?.data?.retData;
                }
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
    }

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }



    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);
        getSPlan();

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);


    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "sptopprid") {
            tDataEdit[pIndex]["spgoalid"] = null;
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spgoalid") {
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spstrid") {
            tDataEdit[pIndex]["spindid"] = null;
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <PanelBase
            headerText={props?.indName}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"

            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Тооцоолох" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            <div>
                <div>
                    <Row justify="space-between">
                        <Row style={{ marginTop: 30 }}>
                            <Col justify="end">
                                /мян.төгрөг/
                            </Col>
                        </Row>
                    </Row>
                </div>
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column width={50} caption="№" alignment='center' allowResizing={true}
                            cellRender={(item) => {
                                return (
                                    <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                );
                            }}
                        ></Column>
                        <Column
                            dataField="schoolname"
                            caption="Сургууль"
                            width={350}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.schoolname} />
                                );
                            }} />
                        <Column
                            dataField="traininglevelname"
                            caption="Сургалтын түвшин"
                            width={150}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.traininglevelname} />
                                );
                            }} />
                        <Column
                            dataField="lessontypename"
                            caption="Хичээлийн хэлбэр"
                            width={450}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.lessontypename} />
                                );
                            }} />

                        <Column
                            dataField="amount"
                            caption="Дүн"
                            alignment='center'
                            width={200}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "amount")} defaultValue={item.data?.amount} />
                                );
                            }} />
                          <Column
                                dataField="output"
                                caption="Хүрэх үр дүн /outputs/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "output")} defaultValue={item.data?.output} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.output} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="outcome"
                                caption="Хүрэх үр дагавар /outcome/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "outcome")} defaultValue={item.data?.outcome} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.outcome} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="impact"
                                caption="Үр нөлөө /Impact/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "impact")} defaultValue={item.data?.impact} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.impact} </div>
                                        );
                                    }
                                }} />

                            <Column
                                dataField="sptopprname"
                                caption="Тэргүүлэх чиглэл"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "sptopprid", pNameValue, "sptopprname")}
                                                defaultValue={item.data?.sptopprid}
                                                data={splan.filter(x => x.typeid == 2)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.sptopprname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spgoalname"
                                caption="Зорилт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spgoalid", pNameValue, "spgoalname")}
                                                defaultValue={item.data?.spgoalid}
                                                data={splan.filter(x => x.typeid == 3 && x.parentid == item.data?.sptopprid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spgoalname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spstrname"
                                caption="Стратеги"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spstrid", pNameValue, "spstrname")}
                                                defaultValue={item.data?.spstrid}
                                                data={splan.filter(x => x.typeid == 4 && x.parentid == item.data?.spgoalid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spstrname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spindname"
                                caption="Хэмжих үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spindid", pNameValue, "spindname")}
                                                defaultValue={item.data?.spindid}
                                                data={splan.filter(x => x.typeid == 5 && x.parentid == item.data?.spstrid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spindname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="kpiname"
                                caption="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "kpiid", pNameValue, "kpiname")} defaultValue={item.data?.kpiid} data={kpis} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.kpiname} </div>
                                        );
                                    }
                                }} /> 
                        <Summary>
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="amount"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}₮"
                            />
                        </Summary>
                    </DataGrid>
                </div>
                <div style={{ marginTop: 24 }}>
                    <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                </div>
            </div>

        </PanelBase >
    );
}

export default BEmpSupportPanel;