import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, message, Space, Steps, Modal } from "antd";
import { Dialog, DialogFooter, DialogType, Pivot, PivotItem } from "@fluentui/react";
import { ButtonDefault, ButtonSecondary, FieldDropdownMulti, LoadScreen, LoadSpin, Warning } from "dw-components";
import { APIGraphQL } from "../../../API/APIGraphQL";
import BudgetDtl from "./BudgetDtl/BudgetDtl";
import { API } from "../../../API/API";
import { UserContext } from "../../../Context/UserContext";
import Suggestion from "./Suggestion/Suggestion";
import BudgetDtlIndicator from "../../BudgetDtlIndicator/BudgetDtlIndicator";
import IndicatorChart from '../../../svg/stepIcons/IndicatorChart';
import IndicatorIncrease from '../../../svg/stepIcons/IndicatorIncrease';
import IndicatorDecrease from '../../../svg/stepIcons/IndicatorDecrease';
import IndicatorSum from '../../../svg/stepIcons/IndicatorSum';
import IndicatorSuggestion from '../../../svg/stepIcons/IndicatorSuggestion';
import { ReactComponent as History } from '../../../svg/history.svg';
import BudgetDtlIndicatorLog from "../../BudgetDtlIndicator/BudgetDtlIndicatorLog";
import ReportSchool from "../../Budget/Reports/ReportSchool";

const BudgetIndicators = () => {

    const { userInfo } = useContext(UserContext);

    const params = useParams();
    const [data, setData] = useState({});
    const [budgetDtl, setBudgetDtl] = useState([]);
    const [budgetDtlSchool, setBudgetDtlSchool] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [edit, setEdit] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [showAskSent, setShowAskSent] = useState(false);

    const [showReturn, setShowReturn] = useState(false);
    const [depStatus, setDepStatus] = useState([]);
    const [retSDep, setRetSDep] = useState([]);
    const [showHistory, setShowHistory] = useState(false);


    const { Step } = Steps;
    const [current, setCurrent] = useState(parseInt(params?.type) || 0);

    const getData = async () => {
        let tData = {};
        let tInfoData = {};

        if (params?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    budgetQuery {
                      budgetOpen(id: $id) {
                        iD
                        yEAR
                        sTARTDATE
                        eNDDATE
                        oWNERNAME
                        sTATUSID
                        sTATUSNAME
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(params?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.budgetQuery?.budgetOpen;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(() => {
                setLoading(false);
            });

            await API.get("/api/budget/budgetTotal?budgetid=" + params?.id + "&datatype=" + userInfo?.schooltypeid + "&datatypeid=" + userInfo?.schoolid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData?.length > 0) {
                        tInfoData = response?.data?.retData[0];
                    }
                }
            }).catch(() => {
                setLoading(false);
            });
            setLoading(false);
        }

        setData(tData);
        setInfoData(tInfoData);
    }

    useEffect(() => {
        getData();
        if (params?.type) {
            setCurrent(parseInt(params?.type) || 0);
        }
    }, [params?.id, params?.type]);

    const onClickStep = (value) => {
        setCurrent(value);
    }

    const onSave = async () => {
        if (edit) {

            setLoadingSave(true);

            if (budgetDtl?.length > 0) {
                let tBudgetDtl = budgetDtl.filter(r => r.ischild == 1 && r.edit);

                if (tBudgetDtl?.length > 0) {

                    await API.post("/api/budget/updBudgetDep?type_=2", tBudgetDtl).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            message.success("Амжилттай.");
                            setEdit(false);
                        }
                    }).catch(() => {
                        setLoadingSave(false);
                    });
                }
            }

            if (budgetDtlSchool?.length > 0) {
                let tBudgetDtlSchool = budgetDtlSchool.filter(r => (r.ischild == 1 || r.calctypeid == 2 || r.calctypeid == 51) && r.edit);
                if (tBudgetDtlSchool?.length > 0) {

                    await API.post("/api/budget/updBudgetSchool", tBudgetDtlSchool).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            message.success("Амжилттай.");
                            setEdit(false);
                        }
                    }).catch(() => {
                        setLoadingSave(false);
                    });
                }
            }

            await getData();
            setLoadingSave(false);
        }
    }

    const onEdit = (pData) => {
        setEdit(true);
        setBudgetDtl(pData);
    }

    const onEditSchool = (pData) => {
        setEdit(true);
        setBudgetDtlSchool(pData);
    }

    const sentConfirm = () => {
        setShowAskSent(true);
    }

    const onSent = async () => {
        setLoadingSave(true);
        await API.post("/api/budget/budgetToSent?budgetid=" + params?.id + "&senttype=2&sourceid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200) {
                if (response?.data?.retType == 0) {
                    message.success("Амжилттай.");
                }
                else if(response?.data?.retType == 1) {
                    message.warning("Цалин зөрүүтэй тул илгээх боломжгүй байна!");
                }
                else {
                    message.warning(response?.data?.retMsg);
                }
            }
        }).catch(() => {
            setLoadingSave(false);
        });
        setLoadingSave(false);

        await getData();
    }

    const onProcessed = async () => {
        setLoadingSave(true);
        await API.post("/api/budget/budgetToProcessed?budgetid=" + params?.id + "&schoolid=" + userInfo?.schoolid).then(response => {
            setShowAskSent(false);
            if (response?.status == 200 && response?.data?.retType == 0) {
                message.success("Амжилттай.");
            }
        }).catch(() => {
            setLoadingSave(false);
        });
        setLoadingSave(false);

        await getData();
    }

    const onReturnAsk = async () => {
        setLoadingSave(true);
        let tDepStatus = [];
        await API.get("/api/budget/getReturnDep?budgetid=" + params?.id + "&schoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.length > 0) {
                    tDepStatus = response?.data?.retData;
                }
            }
        }).catch(() => {
            setLoadingSave(false);
        });
        setLoadingSave(false);
        setDepStatus(tDepStatus);
        setShowReturn(true);
    }

    const onReturn = async () => {
        if (retSDep?.length > 0) {
            setShowReturn(false);
            setLoadingSave(true);

            let vSuccess = false;
            await API.post("/api/budget/retDepStatus", retSDep).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай.");
                    vSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            setLoadingSave(false);
            if (vSuccess) {
                setRetSDep([]);
                await getData();
            }
        }
    }

    const onShowHistory = async () => {
        setShowHistory(true);
    }

    let vStatus = null;

    if (infoData?.statusid == 1) {
        vStatus = (
            <ButtonSecondary
                text="Буцаах"
                onClick={onReturnAsk}
            />
        );
    } else if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2")) && infoData?.statusid == 2) {
        vStatus = (
            <Space>
                <ButtonSecondary
                    text="Буцаах"
                    onClick={onReturnAsk}
                />
                <ButtonDefault
                    text="Боловсруулсан"
                    onClick={sentConfirm}
                />
            </Space>
        );
    } else if (infoData?.statusid == 3 && userInfo?.roleids?.includes("3") && (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 5 || userInfo?.schooltypeid == 6) || infoData?.statusid == 2 && userInfo?.roleids?.includes("3") && userInfo?.schooltypeid == 3) {
        vStatus = (
            <ButtonDefault
                text="Илгээх"
                onClick={onSent}
            />
        );
    }

    let vDisable = false;
    if (userInfo?.schooltypeid == 3)
        vDisable = true;
    else if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        if (infoData?.statusid > 3)
            vDisable = true;
    } else {
        vDisable = true;
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    let itemList;
    let incomeList;
    let issueList;

    switch (current) {
        case 0:
            itemList = (<BudgetDtlIndicator disable={vDisable} id={params?.id} statusId={infoData?.statusid} />)
            break;
        case 1:
            incomeList = (<BudgetDtl disable={vDisable} id={params?.id} type="1" onEdit={onEditSchool} />)
            break;
        case 2:
            issueList = (<BudgetDtl disable={vDisable} id={params?.id} type="2" onEdit={onEditSchool} />)
            break;
        case 3:
            itemList = (<BudgetDtl disable={vDisable} id={params?.id} type="0" onEdit={onEditSchool} />)
            break;
        case 4:
            itemList = (<Suggestion disable={vDisable} id={params?.id} edit={edit} onEdit={onEdit} total={parseFloat(infoData?.incomeprice) + parseFloat(infoData?.issueprice)} />)
            break;
        case 5:
            itemList = (<ReportSchool id={params?.id} />)
            break;
        default:
            break;
    }

    return (
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "95vh" }}>
            <div style={{ padding: 16, backgroundColor: "#ffffff", height: params?.type ? 56 : 125, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                <div style={{ marginRight: 8 }}>
                    <Row gutter={16} justify="space-between">
                        <Col>
                            <Row gutter={8} justify="start" align="top">
                                <Col>
                                    <h3 className="budget-title">{data.yEAR}</h3>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 20, marginTop: 1 }}>
                                        <div style={{ color: '#212121' }}>{infoData?.statusname}</div>
                                    </div>
                                </Col>
                                <Col >
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>(Нээсэн: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.sTARTDATE},</div>
                                    </div>
                                </Col>
                                <Col >
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>Хаах: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.eNDDATE})</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={16} justify="end">
                                <Space>
                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                        <History />
                                        <div style={{ fontSize: 14, marginLeft: 4, marginRight: 8, fontWeight: "600" }} onClick={onShowHistory}>Түүх</div>
                                    </div>
                                    {loadingSave ? (
                                        <LoadSpin />
                                    ) : (edit ? (
                                        <ButtonDefault
                                            text="Хадгалах"
                                            onClick={onSave}
                                        />
                                    ) : vStatus
                                    )}
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: 8, marginRight: 64 }}>
                    {!params?.type && (
                        <Steps current={current} onChange={onClickStep}>
                            <Step title="---" description="Үзүүлэлт" icon={<IndicatorChart active={current == 0 ? true : false} />} />
                            <Step title="---" description="Орлого" icon={<IndicatorIncrease active={current == 1 ? true : false} />} />
                            <Step title="---" description="Зарлага" icon={<IndicatorDecrease active={current == 2 ? true : false} />} />
                            <Step title="---" description="Нийт" icon={<IndicatorSum active={current == 3 ? true : false} />} />
                            <Step title="---" description="Санал" icon={<IndicatorSuggestion active={current == 4 ? true : false} />} />
                        </Steps>
                    )}
                </div>
            </div>
            <div style={{ marginLeft: 16 }}>
                {itemList}
                {incomeList}
                {issueList}
                <BudgetDtlIndicatorLog
                    id={params?.id}
                    isOpen={showHistory}
                    onDismiss={() => {
                        setShowHistory(false);
                    }} />
                <Dialog
                    hidden={!showAskSent}
                    onDismiss={() => {
                        setShowAskSent(false);
                    }}
                    minWidth={400}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Та боловсруулсан төлөвт шилжүүлэхдээ итгэлтэй байна уу?",
                        closeButtonAriaLabel: 'Болих',
                        subText: "Төлөв шилжүүлснээр засварлах боломжгүй болно!."
                    }}
                >
                    <DialogFooter>
                        <Space>
                            <ButtonSecondary text="Үгүй" onClick={() => { setShowAskSent(false); }} />
                            <ButtonDefault text="Тийм" onClick={onProcessed} />
                        </Space>
                    </DialogFooter>
                </Dialog>
                <Modal
                    visible={showReturn}
                    onCancel={() => { setShowReturn(false); }}
                    onOk={onReturn}
                    cancelText="Болих"
                    okText="Буцаах"
                    title="Төсөв буцаах">
                    {depStatus?.length > 0 ? (
                        <FieldDropdownMulti onChanged={(key, text, field) => { setRetSDep(key); }} title="Тэнхим сонгох" keyfield="depstatus" defaultValue={null} data={depStatus} />
                    ) : (
                        <Warning value="Буцаах төсөв байхгүй байна." />
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default BudgetIndicators;
