import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { PageHeader, GridDefault, ColEdit } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { APIGraphQL } from "../../../API/APIGraphQL";
import VehicleTaxPanel from "./Panel/VehicleTaxPanel";

const VehicleTaxConfig = (props) => {

  const [data, setData] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);

  const [columns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      }
    },
    {
      name: "Эдийн засгийн ангилал код",
      fieldName: "cODE",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item) => {
        return (
          <ColEdit onClick={() => onEdit(item.iD)}>
            {item?.cODE}
          </ColEdit>
        );
      }
    }, {
      name: "Эдийн засгийн ангилал нэр",
      fieldName: "nAME",
      minWidth: 200,
      maxWidth: 200
    }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `query ($calctypeid: Int) {
        sharedQuery {
          ecoCatByCalcType(calctypeid: $calctypeid) {
            iD
            cODE
            nAME
            fULLNAME
          }
        }
      }`,
      variables: {
        "calctypeid": 67
      }
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.ecoCatByCalcType;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
  }

  useEffect(() => {
    getData();
  }, []);


  const onDismiss = () => {
    setId();
    setShowNew(false);
  }

  const onEdit = (pId) => {
    setId(pId);
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          title="Нийт"
          dataCount={data?.length}
          onButtonClick={() => { }}
          onSearch={onSearch}
          data={data}
          columns={columns}
        />
        <div style={{ margin: 16 }}>
          <GridDefault columns={columns} data={data} loading={loading}></GridDefault>
          <VehicleTaxPanel
            id={id}
            isOpen={showNew}
            onDismiss={onDismiss}
          />
        </div>
      </Layout>
    </Layout>
  );
};

export default VehicleTaxConfig;
