export const MenuRole = [
    {
        key: 1,
        text: "Дашбоард"
    },
    {
        key: 2,
        text: "Төсөв"
    },
    {
        key: 3,
        text: "Хөрөнгө оруулалт"
    },
    {
        key: 4,
        text: "Баримт бичиг"
    },
    {
        key: 5,
        text: "Ашиглалтын зардал"
    },
    {
        key: 6,
        text: "Хуваарилалт"
    },
    {
        key: 7,
        text: "Тохиргоо"
    },
    {
        key: 8,
        text: "Барилгын хөрөнгө оруулалт"
    },
    {
        key: 9,
        text: "ЗТС төвлөрүүлэлт"
    }
];