import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
import { ButtonDefault, PanelBase, LoadSpin } from "dw-components";
import { ChoiceGroup } from "@fluentui/react";
import { API } from "../../../API/API";
import { message } from "antd";

const AccountChange = (props) => {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [selectedKey, setSelectedKey] = useState();
    const [options, setOptions] = useState();

    useEffect(() => {
        getData();
        setSelectedKey(userInfo?.schoolid)
    }, [props?.isOpen]);

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    const radioButtonOnChange = (event, option) => {
        setSelectedKey(option.key);
    };

    const getData = async () => {
        let tUserSchoolIdDatas = {};

        if (userInfo?.username) {
            setLoading(true);
            await API.get("/api/budget/getUserSchoolIds?username=" + userInfo.username).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData?.length > 0) {
                        tUserSchoolIdDatas = response?.data?.retData;
                    }
                }
            }).catch(() => {
                setLoading(false);
            });
            setLoading(false);
        }
        setOptions(tUserSchoolIdDatas);
    }

    const onLogin = async (event) => {
        try {
            setLoading(true);

            let tExists = [];
            await API.get("/api/budget/authWithSchoolId?username=" + userInfo?.username + "&schoolid=" + selectedKey)
                .then(response => {
                    if (response?.status == 200 && response?.data?.retData?.length > 0) {
                        tExists = response?.data?.retData;
                    }
                }).catch(() => {
                    setLoading(false);
                });
            if (tExists?.length > 0) {
                let token = userInfo?.token;
                let expires_in = userInfo?.expires_in;
                setUserInfo({ ...tExists[0], token: token, expires_in: expires_in });
                localStorage.setItem("userInfo", JSON.stringify({ ...tExists[0], token: token, expires_in: expires_in }));
                if (tExists[0].schooltypeid == 1) {
                    navigate("/budget");
                } else if (tExists[0].schooltypeid == 2 || tExists[0].schooltypeid == 3 || tExists[0].schooltypeid == 6) {
                    navigate("/budget/branch");
                } else if (tExists[0].schooltypeid == 4 || tExists[0].schooltypeid == 7) {
                    navigate("/budget/department");
                }
            }
            setLoading(false);
            onDismiss();
            message.success("Эрх амжилттай солигдлоо");
        } catch (error) {
            setLoading(false);
            message.error("Амжилтгүй " + error?.message);
        }
    }

    return (
        <PanelBase
            headerText={"Эрх хооронд шилжих"}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            buttons={
                loading ? <LoadSpin /> : (options ? (options.length > 1 ? <ButtonDefault text={"Шилжих"} onClick={onLogin} /> : null) : null)
            }>
            {options ? (options.length > 1 ? (
                <ChoiceGroup
                    selectedKey={selectedKey}
                    options={options}
                    onChange={radioButtonOnChange}
                    label="Шилжих эрхээ сонгоно уу"
                    required={true} />
            ) : <h4>Танд өөр эрх байхгүй тул шилжих боломжгүй.</h4>) : null}
        </PanelBase>
    );
};

export default AccountChange;
