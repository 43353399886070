import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, message, Space, Steps } from "antd";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { ButtonDefault, ButtonSecondary, LoadScreen, LoadSpin } from "dw-components";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { API } from "../../../API/API";
import BudgetDtl from "../BudgetDtl/BudgetDtl";
import { UserContext } from "../../../Context/UserContext";
import Suggestion from "../Suggestion/Suggestion";
import BudgetDtlIndicator from "../../BudgetDtlIndicator/BudgetDtlIndicator";
import IndicatorChart from '../../../svg/stepIcons/IndicatorChart';
import IndicatorIncrease from '../../../svg/stepIcons/IndicatorIncrease';
import IndicatorDecrease from '../../../svg/stepIcons/IndicatorDecrease';
import IndicatorSum from '../../../svg/stepIcons/IndicatorSum';
import IndicatorSuggestion from '../../../svg/stepIcons/IndicatorSuggestion';
import { ReactComponent as History } from '../../../svg/history.svg';
import ReportSchool from "../../Budget/Reports/ReportSchool";

const BudgetIndicators = () => {

    const { userInfo } = useContext(UserContext);
    const { Step } = Steps;
    const params = useParams();
    const [current, setCurrent] = useState(parseInt(params?.type) || 0);
    const [data, setData] = useState({});
    const [budgetDtl, setBudgetDtl] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [edit, setEdit] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [showAskSent, setShowAskSent] = useState(false);

    const getData = async () => {
        let tData = {};
        let tInfoData = {};

        if (params?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    budgetQuery {
                      budgetOpen(id: $id) {
                        iD
                        yEAR
                        sTARTDATE
                        eNDDATE
                        oWNERNAME
                        sTATUSID
                        sTATUSNAME
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(params?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.budgetQuery?.budgetOpen;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(() => {
                setLoading(false);
            });

            await API.get("/api/budget/budgetTotal?budgetid=" + params?.id + "&datatype=" + userInfo?.schooltypeid + "&datatypeid=" + userInfo?.schoolid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData?.length > 0) {
                        tInfoData = response?.data?.retData[0];
                    }
                }
            }).catch(() => {
                setLoading(false);
            });

            setLoading(false);
        }

        setData(tData);
        setInfoData(tInfoData);
    }

    useEffect(() => {
        getData();
        if (params?.type) {
            setCurrent(parseInt(params?.type) || 0);
        }
    }, [params?.id, params?.type]);

    const onSave = async () => {
        if (edit && budgetDtl?.length > 0) {
            setLoadingSave(true);

            let tBudgetDtl = budgetDtl.filter(r => {
                if (r.ischild == 1 && r.edit) {
                    return true;
                } else {
                    return false;
                }
            });

            if (tBudgetDtl?.length > 0) {

                await API.post("/api/budget/updBudgetDep", tBudgetDtl).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        message.success("Амжилттай.");
                        setEdit(false);
                    }

                }).catch(() => {
                    setLoadingSave(false);
                })
            }

            await getData();
            setLoadingSave(false);
        }
    }

    const onEdit = (pData) => {
        setEdit(true);
        setBudgetDtl(pData);
    }

    const onClickStep = (value) => {
        setCurrent(value);
    }

    const sentConfirm = () => {
        setShowAskSent(true);
    }

    const onSent = async () => {
        setLoadingSave(true);
        await API.post("/api/budget/budgetToSent?budgetid=" + params?.id + "&senttype=1&sourceid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                message.success("Амжилттай.")
                setShowAskSent(false);
            }
            else if(response?.data?.retType == 1) {
                setShowAskSent(false);
                message.warning("Цалин зөрүүтэй тул илгээх боломжгүй байна!");
            }
        }).catch(() => {
            setLoadingSave(false);
        });
        setLoadingSave(false);

        await getData();
    }

    let vDisable = false;
    if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        if(infoData?.statusid != 1)
        vDisable = true;
    }
    else {
        vDisable = true;
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    let itemList;
    let incomeList;
    let issueList;

    switch (current) {
        case 0:
            itemList = (<BudgetDtlIndicator disable={vDisable} id={params?.id} statusId={infoData?.statusid}/>)
            break;
        case 1:
            incomeList = (<BudgetDtl disable={vDisable} id={params?.id} edit={edit} onEdit={onEdit} type="1" total={infoData?.incomeprice} />)
            break;
        case 2:
            issueList = (<BudgetDtl disable={vDisable} id={params?.id} edit={edit} onEdit={onEdit} type="2" total={infoData?.issueprice} />)
            break;
        case 3:
            itemList = (<BudgetDtl disable={vDisable} id={params?.id} edit={edit} onEdit={onEdit} type="0" total={parseFloat(infoData?.incomeprice) + parseFloat(infoData?.issueprice)} />)
            break;
        case 4:
            itemList = (<Suggestion disable={vDisable} id={params?.id} total={parseFloat(infoData?.incomeprice) + parseFloat(infoData?.issueprice)} />)
            break;
            case 5:
                itemList = (<ReportSchool id={params?.id} />)
                break;
        default:
            break;
    }
    return (
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "95vh" }}>
            <div style={{ padding: 16, backgroundColor: "#ffffff", height: params?.type ? 56 : 125, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                <div style={{ marginRight: 8 }}>
                    <Row gutter={16} justify="space-between">
                        <Col>
                            <Row gutter={8} justify="start" align="top">
                                <Col>
                                    <h3 className="budget-title">{data.yEAR}</h3>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 20, marginTop: 1 }}>
                                        <div style={{ color: '#212121' }}>{infoData?.statusname}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>(Нээсэн: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.sTARTDATE},</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>Хаах: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.eNDDATE})</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={16} justify="end">
                                <Space>
                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                        <History />
                                        <div style={{ fontSize: 14, marginLeft: 4, marginRight: 8, fontWeight: "600" }}>Түүх</div>
                                    </div>
                                    {loadingSave ? (
                                        <LoadSpin />
                                    ) : (edit ? (
                                        <ButtonDefault
                                            text="Хадгалах"
                                            onClick={onSave}
                                        />
                                    ) : (!vDisable && (
                                            <ButtonDefault
                                                text="Илгээх"
                                                onClick={sentConfirm}
                                            />
                                        )
                                    )
                                    )}
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: 8, marginRight: 64 }}>
                    {!params?.type && (
                        <Steps current={current} onChange={onClickStep}>
                            <Step title="---" description="Үзүүлэлт" icon={<IndicatorChart active={current == 0 ? true : false} />} />
                            <Step title="---" description="Орлого" icon={<IndicatorIncrease active={current == 1 ? true : false} />} />
                            <Step title="---" description="Зарлага" icon={<IndicatorDecrease active={current == 2 ? true : false} />} />
                            <Step title="---" description="Нийт" icon={<IndicatorSum active={current == 3 ? true : false} />} />
                            <Step title="---" description="Санал" icon={<IndicatorSuggestion active={current == 4 ? true : false} />} />
                        </Steps>
                    )}
                </div>
            </div>
            <div style={{ marginLeft: 16 }}>
                {itemList}
                {incomeList}
                {issueList}
            </div>
            <Dialog
                hidden={!showAskSent}
                onDismiss={() => {
                    setShowAskSent(false);
                }}
                minWidth={400}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Та илгээхдээ итгэлтэй байна уу?",
                    closeButtonAriaLabel: 'Болих',
                    subText: "Та төсөв илгээснээр засварлах боломжгүй болно!."
                }}
            >
                <DialogFooter>
                    <Space>
                        <ButtonSecondary text="Болих" onClick={() => { setShowAskSent(false); }} />
                        <ButtonDefault text="Илгээх" onClick={onSent} />
                    </Space>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default BudgetIndicators;