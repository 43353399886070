const BRANCHSCHOOLS = [
  { key: "ALL", text: "Бүгд" },
  { key: "SASA", text: "ШУС - Захиргаа" },
  { key: "BOH", text: "ШУС - Хүмүүнлэгийн ухааны салбар" },
  { key: "BONS", text: "ШУС - Байгалийн ухааны салбар" },
  { key: "DOSS", text: "ШУС - Нийгмийн ухааны салбар" },
  { key: "GRADS", text: "Ахисан түвшний сургууль" },
  { key: "BS", text: "Бизнесийн сургууль" },
  {
    key: "SIRPA",
    text: "Олон улсын харилцаа, нийтийн удирдлагын сургууль",
  },
  { key: "SOL", text: "Хууль зүйн сургууль" },
  {
    key: "SEAS",
    text: "Хэрэглээний шинжлэх ухааны сургууль",
  },
  { key: "ZA", text: "Завхан сургууль" },
  { key: "ERD", text: "Эрдэнэт сургууль" },
  {
    key: "EECBEHS",
    text: "Байгаль-Эх Лицей Ахлах Сургууль, Экологийн Боловсролын төв",
  },
  {
    key: "IZTRC",
    text: "Өвөр зайсан сургалт судалгааны төв",
  },
  { key: "IMS", text: "Монгол судлалын хүрээлэн" },
  {
    key: "JICA",
    text: "Монгол-Японы хүний нөөцийн хөгжлийн төв",
  },
  { key: "MBOOKS", text: "МУИС хэвлэлийн газар" },
  { key: "MPRESS", text: "МУИС хэвлэх үйлдвэр" },
  { key: "RAI", text: "Эрдэм шинжилгээ инноваци" },
  { key: "EI", text: "Эдийн засгийн хүрээлэн" },
  {
    key: "NRC",
    text: "Цөмийн физикийн судалгааны төв",
  },
  { key: "DORM", text: "Оюутны байр" },
  { key: "CED", text: "Төвлөрсөн сан" },
];

const BRANCHSCHOOLSKEYS = {
  ALL: "Бүгд",
  BOH: "ШУС - Хүмүүнлэгийн ухааны салбар",
  BONS: "ШУС - Байгалийн ухааны салбар",
  DOSS : "ШУС - Нийгмийн ухааны салбар",
  GRADS : "Ахисан түвшний сургууль",
  BS: "Бизнесийн сургууль",
  SIRPA: "Олон улсын харилцаа, нийтийн удирдлагын сургууль",
  SOL: "Хууль зүйн сургууль",
  SEAS: "Хэрэглээний шинжлэх ухааны сургууль",
  ZA: "Завхан сургууль",
  ERD: "Эрдэнэт сургууль",
  EECBEHS: "Байгаль-Эх Лицей Ахлах Сургууль, Экологийн Боловсролын төв",
  IZTRC: "Өвөр зайсан сургалт судалгааны төв",
  IMS: "Монгол судлалын хүрээлэн",
  JICA: "Монгол-Японы хүний нөөцийн хөгжлийн төв",
  MBOOKS: "МУИС хэвлэлийн газар",
  MPRESS: "МУИС хэвлэх үйлдвэр",
  RAI: "Эрдэм шинжилгээ инноваци",
  EI: "Эдийн засгийн хүрээлэн",
  NRC: "Цөмийн физикийн судалгааны төв",
  DORM: "Оюутны байр",
  CED: "Төвлөрсөн сан",
};


export {
  BRANCHSCHOOLS,
  BRANCHSCHOOLSKEYS
}