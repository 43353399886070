import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle, ButtonDefault, ButtonSecondary, LoadSpin, LoadScreen } from "dw-components";
import Purchase from "./Purchase";
import Investment from "./Investment";
import { message, Row, Col, Space } from "antd";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { API } from "../../../../API/API";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";
import FileDownload from 'js-file-download';
import { URLTYPE } from "../../../../Const/PurchaseInvestment";
import ButtonReport from "../../../../Components/ButtonReport/ButtonReport";

const PurchaseSuggestion = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const { userInfo } = useContext(UserContext);
    const [purInvStatusData, setPurInvStatusData] = useState({});

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSend, setLoandingSend] = useState(false);
    const [showAskSent, setShowAskSent] = useState(false);

    const getStatusData = async () => {
        setLoading(true);
        let tPurInvStatusData = [];

        await APIGraphQL.post("", {
            query: `query ($id: Int, $schoolid: Int, $type: String) {
                    budgetQuery {
                        getBudgetPurInvSchoolStatus (id: $id, schoolid: $schoolid, type: $type) {
                            sCHOOLID
                            sTATUSID
                            sTATUSNAME
                            bUDGETID
                            bUDGETPURINVID
                        }
                    }
                }`,
            variables: {
                id: parseInt(params?.id),
                schoolid: parseInt(userInfo.schoolid),
                type: params?.type
            },
        }).then((response) => {
            let tDResp = response?.data?.data?.budgetQuery?.getBudgetPurInvSchoolStatus;
            if (tDResp?.sCHOOLID) {
                tPurInvStatusData = tDResp;
            }
        }).catch(() => {
            setLoading(false);
        });

        setPurInvStatusData(tPurInvStatusData);
        setLoading(false);
    }

    useEffect(async () => {
        getStatusData();
    }, [params?.id])


    let vDisable = false;
    if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        if (props?.statusid > 3 || purInvStatusData?.sTATUSID > 1)
            vDisable = true;
    } else {
        vDisable = true;
    }

    const onCalculate = async () => {
        setLoadingSave(true)

        let tBudgetId;
        let vBInvSuccess = false;

        if (params?.type == "pi") {
            tBudgetId = purInvStatusData.bUDGETID;
        } else {
            tBudgetId = params?.id;
        }
        
        await API.post("api/budget/calcBudgetInvestment?budgetid=" + tBudgetId + "&schoolid=" + userInfo?.schoolid + 
        "&depid=" + userInfo?.schoolid +
        "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                vBInvSuccess = true;
            }
        }).catch(() => {
            vBInvSuccess = false
        });

        let vBPurchSuccess = false;
        await API.post("api/budget/calcBudgetPurchase?budgetid=" + tBudgetId + "&schoolid=" + userInfo?.schoolid + 
        "&depid=" + userInfo?.schoolid +
        "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                vBPurchSuccess = true;
            }
        }).catch(() => {
            vBPurchSuccess = false
        });

        if (vBInvSuccess && vBPurchSuccess) {
            setLoadingSave(false);
            message.success("Амжилттай");
        } else {
            setLoadingSave(false);
            message.error("Амжилтгүй");
        }
    }

    const onBack = () => {
        navigate("/budget/branch/indicators/" + params?.id);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/budgetInvestmentDtlTemp?id=" + params?.id + "&schoolid=" + userInfo?.schoolid + "&type=" + params?.type + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Хөрөнгө оруулалт сургууль." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const sentConfirm = () => {
        setShowAskSent(true);
    }

    const onSent = async () => {
        setLoandingSend(true);

        await API.post("/api/budget/budgetPurchInvToSent?id=" + params?.id + "&schoolid=" + userInfo?.schoolid + "&type=" + params?.type).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                message.success("Амжилттай.")
                setShowAskSent(false);
            }
        }).catch(() => {
            setLoandingSend(false);
        });

        setLoandingSend(false);
        getStatusData();

        if (params?.type == URLTYPE.BUDGET) navigate("/budget/branch/indicators/" + params?.id);
        if (params?.type == URLTYPE.PURCHASEINVESTMENT) navigate("/budget/branch");
    }

    if (loading) {
        return <LoadScreen />
    }

    return (
        <div>
            <div style={{ margin: 16 }}>
                <Row>
                    <Col span={21}>
                        <div style={{ marginLeft: 16, marginTop: 16 }}>
                            <PageTitle>Худалдан авалт Хөрөнгө оруулалтын санал</PageTitle>
                            {userInfo?.schoolname}
                        </div>
                    </Col>
                    <Col span={3}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                            {/* <ButtonSecondary text={"Буцах"} onClick={onBack} /> */}
                            {!vDisable ? (
                                <>
                                    {purInvStatusData.bUDGETID ? (
                                        <div style={{ marginLeft: 16 }}>{loadingSave ? <LoadSpin /> : <ButtonDefault text={"Тооцоолох"} onClick={onCalculate} />}</div>
                                    ) : null}
                                    <div style={{ marginLeft: 16 }}>{loadingSend ? <LoadSpin /> : <ButtonDefault text={"Илгээх"} onClick={sentConfirm} />}</div>
                                </>
                            ) : null}
                            <div style={{ paddingLeft: 16, float: "left" }}>{loadingDownload ? <LoadSpin /> : <ButtonReport size="small" title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />}</div>
                        </div>
                        <Row style={{ marginTop: 10, paddingRight: 20, float: "Right" }}>
                                <Col justify="end">
                                       /мян.төгрөг/
                                </Col>
                           </Row>
                    </Col>
                </Row>
                {userInfo?.indicatorids?.includes("581") &&
                    <Investment
                        itemId={params?.id}
                        budgetid={purInvStatusData.bUDGETID}
                        urlType={params?.type}
                        statusId={purInvStatusData.sTATUSID}
                        secondaryId={params?.type == "pi" ? purInvStatusData.bUDGETID : purInvStatusData.bUDGETPURINVID}
                        disable={vDisable}
                    />
                }
                {userInfo?.indicatorids?.includes("582") &&
                    <div style={{ marginTop: 48, paddingBottom: 48 }}>
                        <Purchase
                            itemId={params?.id}
                            budgetid={purInvStatusData.bUDGETID}
                            urlType={params?.type}
                            statusId={purInvStatusData.sTATUSID}
                            secondaryId={params?.type == "pi" ? purInvStatusData.bUDGETID : purInvStatusData.bUDGETPURINVID}
                            disable={vDisable}
                        />
                    </div>
                }
            </div>
            <Dialog
                hidden={!showAskSent}
                onDismiss={() => {
                    setShowAskSent(false);
                }}
                minWidth={400}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Та илгээхдээ итгэлтэй байна уу?",
                    closeButtonAriaLabel: 'Болих',
                    subText: "Та ХӨ ХА илгээснээр засварлах боломжгүй болно!."
                }}
            >
                <DialogFooter>
                    <Space>
                        <ButtonSecondary text="Болих" onClick={() => { setShowAskSent(false); }} />
                        <ButtonDefault text="Илгээх" onClick={onSent} />
                    </Space>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default PurchaseSuggestion;
