export const EditIndicatorConst = [
    {
        key: 63,
        text: "Цалин"
    },
    {
        key: 88,
        text: "Урамшуулал"
    },
    {
        key: 60,
        text: "Томилолт"
    }
];