import React, { useEffect, useState, useReducer } from "react";
import { Layout, message } from "antd";
import { IconButton } from "@fluentui/react";
import {
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { GridDefault, PageHeader } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import HrPlanPanel from "./Panel/HrPlanPanel";
import DegreePanel from "./Panel/DegreePanel";

import { filterObjectArray } from "../../../Utils/FilterAndSearch";
import { leftFilterData } from "../../../Const/Const";

const HrPlan = () => {
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const HrPlanCollectionRef = collection(db, "hrPlan");

  const [hrPlanId, setHrPlanId] = useState(0);
  const [showNew, setShowNew] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({});
  const [filterValue, setFilterValue] = useState("2022");
  const [filterData, setFilterData] = useState(leftFilterData.hr_plan);
  const [gridFilter, setGridFilter] = useState([]);

  const columns = [
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      }
    },
    {
      name: "Сүлжээний зэрэглэл, шатлал",
      fieldName: "ratingHierarchy",
      minColSize: "mid",
    },
    {
      name: "Үндсэн цалин",
      fieldName: "salary",
      colSize: "mid",
    },
    {
      name: "Албан тушаал",
      fieldName: "position",
      minColSize: "mid",
    },
    {
      name: "Орон тоо",
      fieldName: "vacancy",
      minColSize: "mid",
    },
    {
      name: "Байгууллагын нэгж",
      fieldName: "department",
      minColSize: "mid",
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
            <IconButton
              iconProps={{ iconName: "Delete" }}
              title="Delete"
              ariaLabel="Delete"
              onClick={() => onDeleteClick(item.id)}
            />
          </div>
        );
      },
    },
  ];

  const degreeColumns = [
    {
      name: "Байгууллагын нэгж",
      fieldName: "department",
      minColSize: "mid",
    },
    {
      name: "Эрдмийн зэрэг, цол",
      fieldName: "degree",
      minColSize: "mid",
    },
    {
      name: "Албан тушаал",
      fieldName: "position",
      minColSize: "mid",
    },
    {
      name: "Орон тоо",
      fieldName: "vacancy",
      colSize: "mid",
    },
    {
      name: "Судалгааны суурь зардлын олгох дээд хэмжээ",
      fieldName: "maxResearchCost",
      minColSize: "large",
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
            <IconButton
              iconProps={{ iconName: "Delete" }}
              title="Delete"
              ariaLabel="Delete"
              onClick={() => onDeleteClick(item.id)}
            />
          </div>
        );
      },
    },
  ];

  const Query = query(
    HrPlanCollectionRef,
    where("category", "==", filterValue)
  );

  const getGridFilterData = async () => {
    let tGridFilter = [
      {
        data: [
          {
            key: "2022",
            text: "2022",
          },
          {
            key: "2021",
            text: "2021",
          },
        ],
        key: "category",
        text: "Category",
      },
      {
        data: [
          {
            key: "HEAD",
            text: "Төв салбар",
          },
          {
            key: "BRANCH",
            text: "Салбар сургууль",
          },
        ],
        key: "branch",
        text: "Салбар",
      },
    ];

    //get some grid filter here
    setGridFilter(tGridFilter);
  };

  const changeFilter = (key) => {
    setFilterValue(key);
    getData(key);
  };

  const onSearch = (data) => {
    setData(data);
  };

  const onGridFilterChange = async (gridfiltervalue) => {
    getData(filterValue, gridfiltervalue);
  };

  const deleteHrPlan = async (id) => {
    try {
      const HrPlanDoc = doc(db, "hrPlan", id);
      await deleteDoc(HrPlanDoc);
      message.success("Амжилттай устгагдлаа");
      forceUpdate();
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  // Get list data from firebase
  useEffect(() => {
    getData("2022");
    getGridFilterData();
  }, [filterData]);

  const getData = async (p_filterValue, p_gridFilter) => {
    let filteredObjects = [];
    setLoading(true);
    let objects = await getDocs(Query);
    objects = objects.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    if (p_gridFilter?.length > 0) {
      filteredObjects = filterObjectArray(objects, p_gridFilter);
    } else {
      filteredObjects = objects;
    }

    setLoading(false);
    setData(objects);
    setFilteredData(filteredObjects);
  };

  const onNew = () => {
    setHrPlanId(0);
    setShowNew(true);
  };

  const onDismiss = () => {
    setHrPlanId(0);
    setShowNew(false);
  };

  const onEditClick = (id) => {
    setHrPlanId(id);
    setShowNew(true);
  };

  const onDeleteClick = (id) => {
    deleteHrPlan(id);
  };

  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          showLeftFilter
          changeFilter={changeFilter}
          filtervalue={filterValue}
          filterData={filterData}
          buttonTitle="Хүний нөөцийн мэдээлэл"
          title={filterValue === "degree" ? "Эрдмийн зэрэг" : filterValue}
          onButtonClick={onNew}
          dataCount={`${filterValue}) (${filteredData.length}`}
          columns={filterValue === "degree" ? degreeColumns : columns}
          data={data}
          filteredData={filteredData}
          onSearch={onSearch}
          gridFilter={gridFilter}
          onGridFilterChange={onGridFilterChange}
          loading={loading}
        />
        <div style={{ margin: 16 }}>
          <GridDefault
            columns={filterValue === "degree" ? degreeColumns : columns}
            data={filteredData}
          />
          <HrPlanPanel
            itemId={hrPlanId}
            isOpen={showNew}
            onDismiss={onDismiss}
            forceUpdate={forceUpdate}
          />
        </div>
      </Layout>
    </Layout>
  );
};

export default HrPlan;
