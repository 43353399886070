const ASSETCATEGORIES = [
  { key: "Барилга, Байгууламж", text: "Барилга, Байгууламж" },
  { key: "Өргөтгөл, их засвар", text: "Өргөтгөл, их засвар" },
  { key: "Тоног төхөөрөмж, тээврийн хэрэгсэл", text: "Тоног төхөөрөмж, тээврийн хэрэгсэл" },

  { key: "Тавилга эд хогшил, бусад, үндсэн(биет бус) хөрөнгө", text: "Тавилга эд хогшил, бусад, үндсэн (биет бус) хөрөнгө" },
  { key: "Стратегийн нөөц хөрөнгө", text: "Стратегийн нөөц хөрөнгө" }
];

export default ASSETCATEGORIES;
