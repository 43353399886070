export const BUDGETSTATUS = [
    {
      'key': 1,
      'text': 'Шинэ'
    },
    {
      'key': 2,
      'text': 'Нээлттэй'
    },
    {
      'key': 3,
      'text': 'Боловсруулж байна'
    },
    {
      'key': 4,
      'text': 'Боловсруулсан'
    },
    {
      'key': 5,
      'text': 'Баталсан'
    },
    {
      'key': 6,
      'text': 'Хуваарилсан'
    }
  ];
