import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdownMulti} from "dw-components"; 
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";

const SecurityObjectSchoolPanel = (props) => {
  const [data, setData] = useState({});
  const [dSecurityObjects, setSecurityObjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [mainSchools, setMainSchools] = useState([]);
  const [depSchools, setDepSchools] = useState([]);
  const [dECat, setDECat] = useState([]);
  const {userInfo } = useContext(UserContext);

  const getData = async () => {

    let tData = [];
    let tDSecurityObjects = [];
    let tMainSchools = [];
    let tDECat = [];
    await APIGraphQL.post("", {
      query: `query ($id: Int) {
      sharedQuery 
      {
        getSecurityObjectDetail(id: $id) {
          iD
          sECURITYOBJECTID
          sECURITYOBJECTNAME
          sCHOOLID
          sCHOOLNAME
          dEPIDS
          sHAREPERCENT
          eCONOMYCATEGORYID
          eCONOMYCATEGORYNAME
          cREATED
          cREATEDBY
        }   
      
        getSecurityObjectList {
          key: iD
          text: nAME
        }

        parentschooldep {
          key: iD
          text: nAME
        }  

        ecoCatByCalcType(calctypeid: 73) {
          key: iD
          text: nAME
        }
      }
    }`,
      variables: {
        "id": parseInt(props?.id)
      },
    }).then(response => {
      let tSharedQuery = response?.data?.data?.sharedQuery;
      if (tSharedQuery?.getSecurityObjectDetail?.iD) {
        tData = tSharedQuery?.getSecurityObjectDetail;
        tData.dEPIDS = tData?.dEPIDS?.split(',').map(Number);
      }

      if (tSharedQuery?.getSecurityObjectList?.length > 0) {
        tDSecurityObjects = tSharedQuery?.getSecurityObjectList;
      }

      if (tSharedQuery?.parentschooldep?.length > 0) {
        tMainSchools = tSharedQuery?.parentschooldep;
      }

      if (tSharedQuery?.ecoCatByCalcType?.length > 0) {
        tDECat = tSharedQuery?.ecoCatByCalcType;
      }

    }).catch(() => {
      setLoading(false);
    });
    setSecurityObjects(tDSecurityObjects);
    let tCurrentDeps = await getSchools(tData?.sCHOOLID);
    setMainSchools(tMainSchools);
    setDepSchools(tCurrentDeps);
    setDECat(tDECat);
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;

        if(fieldname == "sCHOOLNAME") {
          let tSchools = await getSchools(key);
          setDepSchools(tSchools);
        }
      }

      setData(tData);
    }
  };

  const getSchools = async (pSchoolId) => {
      let tSchools = [];
      await APIGraphQL.post("", {
          query: `query ($schoolid: Int) {
              sharedQuery {
                  schoolByParentId(schoolid: $schoolid) {
                      key: iD
                      text: nAME
                  }
              }
            }`,
          variables: {
              "schoolid": pSchoolId
          }
      }).then(response => {
          let tResp = response?.data?.data;    
          if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
              tSchools = tResp?.sharedQuery?.schoolByParentId;
      }
      }).catch(() => {
          setLoading(false);
      });
      return tSchools;
  }

  const onSave = async () => {
    let isValid = true;
    let tData = { ...data }
    if (!tData?.sECURITYOBJECTID ||
      !tData?.eCONOMYCATEGORYID ||
      !tData?.sCHOOLID
    ) { isValid = false };
    if (isValid) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      if(tData.dEPIDS)
          tData.dEPIDS = tData.dEPIDS.toString();
      else
          tData.dEPIDS = tData.sCHOOLID.toString();
        
      if(!tData?.sHAREPERCENT)
        tData.sHAREPERCENT = 0;
          
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: SecurityObjectSchoolInputType) {
          sharedMutation {
            addSecurityObjectSchool(data: $data) {
                        iD
                    }
                }
            }`,
        variables: {
          "data": tData,
        },
      }).then((response) => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addSecurityObjectSchool?.iD) {
          tSuccess = true;
        }
      }).catch(() => {
        setLoadingSave(false);
      })
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  };

  const onDismiss = () => {
    setData({});
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation delSecurityObjectSchool($id: Int) {
      sharedMutation {
        delSecurityObjectSchool(id: $id)
      }
    }`,
      variables: {
        "id": parseInt(props.id),
      },
    }).then((response) => {
      if (response?.data?.data?.sharedMutation?.delSecurityObjectSchool == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
      }
    });
  };

  return (
    <PanelBase
      headerText={"Харуул, хамгаалалтын тохиргоо нэмэх"}
      isOpen={props.isOpen}
      PanelType="medium"
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
    >
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          loading={loading}
          required title="Харуул, хамгаалалтын байр"
          keyfield="sECURITYOBJECTID"
          keyfieldname="sECURITYOBJECTNAME"
          onChanged={fieldOnChanged}
          defaultValue={data.sECURITYOBJECTID}
          data={dSecurityObjects} />
      </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Сургууль"
            keyfield="sCHOOLID"
            keyfieldname="sCHOOLNAME"
            defaultValue={data.sCHOOLID}
            data={mainSchools}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdownMulti
            required
            onChanged={fieldOnChanged}
            title="Тэнхим"
            keyfield="dEPIDS"
            defaultValue={data.dEPIDS}
            data={depSchools}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat
            required
            onChanged={fieldOnChanged}
            title="Хуваарилагдах %"
            keyfield="sHAREPERCENT"
            defaultValue={data.sHAREPERCENT}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Эдийн засгийн ангилал"
            keyfield="eCONOMYCATEGORYID"
            keyfieldname="eCONOMYCATEGORYNAME"
            defaultValue={data.eCONOMYCATEGORYID}
            data={dECat}
          />
        </div>
    </PanelBase>
  );
};

export default SecurityObjectSchoolPanel;
