import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, LoadSpin, FieldDropdownMulti } from "dw-components";
import { UserContext } from "../../../../Context/UserContext";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserType } from "../../../../Const/UserType";
import { UserRole } from "../../../../Const/UserRole";
import { MenuRole } from "../../../../Const/MenuRole";
import FieldComboBoxMultiSelectAll from "../../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";
import { UserIndicatorRole } from "../../../../Const/UserIndicatorRole";
import { message } from "antd";
import moment from "moment";
import { encrypt, deccrypt } from "../../../../Utils/SharedFunc";

const UserPanel = (props) => {

    const [data, setData] = useState({});
    const { userInfo } = useContext(UserContext);
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [oParent, setOParent] = useState([]);
    const [oDepartment, setOdepartment] = useState([]);
    const [oPositon, setOPosition] = useState([]);

    const getData = async () => {
        let tDParent = [];
        let tDPositions = [];
        let tData = {};
        let tDSchool = [];
        if (props?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                      getUser(id: $id) {
                        iD
                        sCHOOLID
                        sCHOOLNAME
                        pARENTID
                        pARENTNAME
                        uSERNAME
                        iSACTIVE
                        pOSITIONID
                        pOSITIONNAME
                        eMPLOYEENAME
                        tYPEID
                        tYPENAME
                        mENUROLEIDS
                        rOLEIDS
                        cREATED
                        cREATEDBY
                        iNDICATORIDS
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(props?.id),
                },
            }).then((response) => {
                let tResp = response?.data?.data?.sharedQuery?.getUser;
                if (tResp?.iD) {
                    tData = tResp;
                    tData.rOLEIDS = tData?.rOLEIDS?.split(',').map(Number);
                    tData.mENUROLEIDS = tData?.mENUROLEIDS?.split(',').map(Number);
                    tData.iNDICATORIDS = tData?.iNDICATORIDS?.split(',').map(Number);
                }
            }).catch(() => {
                setLoading(false);
            });
        }
      
        await APIGraphQL.post("", {
            query: `query ($typeid: Int, $id: Int){
            sharedQuery {
                getSchoolsbyType(typeid: $typeid){
                    key: iD
                    text: nAME
                  }
                getPositionsfiltered(isuseuser: 1) {
                    key: iD
                    text: nAME
                }
                getSchoolDepList(id: $id) {
                  key: iD
                  text: nAME
                }
            }
        }`,
        variables: {
          "typeid": tData?.tYPEID,
          "id": tData?.pARENTID
        }
        }).then(response => {
            let tPResp = response?.data?.data?.sharedQuery?.getSchoolsbyType;
            if (tPResp?.length > 0) {
                tDParent = tPResp;
            }
            let tResp = response?.data?.data?.sharedQuery?.getPositionsfiltered;
            if (tResp?.length > 0) {
                tDPositions = tResp;
            } 
            let tDResp = response?.data?.data?.sharedQuery?.getSchoolDepList;
            if (tDResp?.length > 0) {
                tDSchool = tDResp;
                tDSchool.push({key: tDParent[0]?.key, text: tDParent[0]?.text});
            }
        }).catch(() => {
            setLoading(false);
        });
        setOParent(tDParent);
        setOPosition(tDPositions);
        if(tData?.tYPEID == 5) {
            setOdepartment([...tDParent]);
        }
        else {
            setOdepartment([...tDSchool]);
        }
        
        setData(tData);
        setLoading(false);
    }

    
   
   

    useEffect(() => {
        getData();
    }, [props?.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
        let tData = { ...data };
        tData[field] = key;
        if (fieldname) {
            tData[fieldname] = text;
        }
        if (field == "pARENTID") {
            let tDSchool = [];
            await APIGraphQL.post("", {
            query: `query ($id: Int) {
                sharedQuery {
                  getSchoolDepList(id: $id) {
                    key: iD
                    text: nAME
                  }
                }
              }`,
              variables: {
                "id": parseInt(key)
            }
            }).then(response => {       
                let tDResp = response?.data?.data?.sharedQuery?.getSchoolDepList;
                if (tDResp?.length > 0) {
                    tDSchool = tDResp;
                }
            })
            tDSchool.push({key: key, text: text});
            setOdepartment([...tDSchool]);
        }
        else if (field == "tYPEID") {
            let tSchool = [];
            await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
                sharedQuery {
                  getSchoolsbyType(typeid: $typeid){
                    key: iD
                    text: nAME
                  }
                }
              }`,
              variables: {
                "typeid": parseInt(key)
            }
            }).then(response => {       
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyType;
                if (tSResp?.length > 0) {
                    tSchool = tSResp;
                }
            })
            
            if(field == "pARENTID" && key == 1) {
                let tDSchool = [];
                await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                        getSchoolDepList(id: $id) {
                            key: iD
                            text: nAME
                        }
                    }
                }`,
                variables: {
                    "id": tSchool[0]?.key
                }
                }).then(response => {       
                    let tDResp = response?.data?.data?.sharedQuery?.getSchoolDepList;
                    if (tDResp?.length > 0) {
                        tDSchool = tDResp;
                    }
                })
                tDSchool.push({key: tSchool[0]?.key, text: tSchool[0]?.text});
                setOdepartment([...tDSchool]);
                tData.pARENTID = tSchool[0]?.key;
                tData.pARENTNAME = tSchool[0]?.text;    
            }

            if(key == 5) {
                setOdepartment([...tSchool]);
            }
            setOParent(tSchool);
            setOdepartment([...tSchool]);
        }
        setData(tData);
        }
    };
    
    const onSave = async () => {
        let isValid = true;
        setCheckRule(true);
        let tData = { ...data }
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
         if (tData.uSERNAME.match(validRegex)) {
            if (!tData?.uSERNAME ||
                !tData?.tYPEID ||
                !tData?.sCHOOLID ||
                !tData?.pOSITIONID ||
                !tData?.rOLEIDS ||
                !tData?.mENUROLEIDS ||
                !tData?.iNDICATORIDS)
            isValid = false ;
            if (isValid) {
                let tSuccess = false;
                tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
                if(tData.rOLEIDS)
                    tData.rOLEIDS = tData.rOLEIDS.filter(e => e !== 'selectAll').sort().toString();
                if(tData.mENUROLEIDS)
                    tData.mENUROLEIDS = tData.mENUROLEIDS.filter(e => e !== 'selectAll').sort().toString();
                if(tData.iNDICATORIDS)
                    tData.iNDICATORIDS = tData.iNDICATORIDS.sort().toString();
                if (!props.id) {
                    tData.iD = 0;
                    tData.iSACTIVE = 1;
                    tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                    tData.cREATEDBY = userInfo?.username;
                }

                if(tData.tYPEID == 5) {
                    tData.pARENTID = tData?.sCHOOLID;
                }

                setLoadingSave(true);
                await APIGraphQL.post("", {
                    query: ` mutation ($data: UsersInputType) {
                        sharedMutation{
                        addUser(data: $data){
                            iD
                        }
                        }
                    }`,
                    variables: {
                        "data": tData
                    },
                }).then((response) => {
                    setLoadingSave(false);
                    if (response?.data?.data?.sharedMutation?.addUser?.iD) {
                        tSuccess = true;
                    }
                }).catch(() => {
                    setLoadingSave(false);
                })
                if (tSuccess) {
                    message.success("Амжилттай");
                    onDismiss();
                    if (props.afterSave) {
                        await props.afterSave();
                    }
                }
            }
         }
         else {
            message.warning("И-мэйл хаягийн формат буруу байна.");
        }
    }

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteUser($id: Int) {
                sharedMutation {
                    deleteUser(id: $id)
                }
            }`,
            variables: {
                "id": parseInt(props.id),
            },
        }).then((response) => {
            if (response?.data?.data?.sharedMutation?.deleteUser == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай");
                onDismiss();
            }
        });
    };

    const onDismiss = () => {
        setData({});
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <PanelBase
            headerText={props?.id ? "Засах" : "Нэмэх"}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="medium"
            delete={props.id > 0 ? true : false}
            onDelete={onDelete}
            buttons={loadingSave ? <LoadSpin /> :
                <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} />
            }
        >
            <div>
                <div style={{ marginTop: 25 }}>
                    <FieldText title="И-мэйл хаяг" keyfield="uSERNAME" defaultValue={data.uSERNAME} onChanged={fieldOnChanged} required checkreq={checkRule} loading={loading} />
                </div>
                <div style={{ marginTop: 25 }}>
                    <FieldText title="Ажилтаны нэр" keyfield="eMPLOYEENAME" defaultValue={data.eMPLOYEENAME} onChanged={fieldOnChanged} required checkreq={checkRule} loading={loading} />
                </div>    
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
                {(data.tYPEID != 5 && data.tYPEID != 1 &&
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Сургууль" keyfield="pARENTID" keyfieldname="pARENTNAME" defaultValue={data.pARENTID} data={oParent}/>
                </div>
                )}
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Тэнхим"} keyfield="sCHOOLID" keyfieldname="sCHOOLNAME" defaultValue={data.sCHOOLID} data={oDepartment} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Албан тушаал" keyfield="pOSITIONID" keyfieldname="pOSITIONNAME" defaultValue={data.pOSITIONID} data={oPositon} />
                </div> 
                <div style={{ marginTop: 13 }}>
                    <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Меню эрх" keyfield="mENUROLEIDS" defaultValue={data.mENUROLEIDS} data={MenuRole} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Эрх" keyfield="rOLEIDS" defaultValue={data.rOLEIDS} data={UserRole} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdownMulti required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Үзүүлэлт" keyfield="iNDICATORIDS" defaultValue={data.iNDICATORIDS} data={UserIndicatorRole} />
                </div>
            </div>
        </PanelBase>
    )
}

export default UserPanel;

