import React, { useEffect, useState, useReducer } from "react";
import { Layout, message, Table } from "antd";
import { IconButton } from "@fluentui/react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { PageHeader } from "dw-components";
import { convertAssetsToTree, getAssetParents } from "../../../Utils";
import Sidemenu from "../../Sidemenu/Sidemenu";
import AssetsPanel from "./Panel/AssetsPanel";

const Assets = () => {
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const AssetsCollectionRef = collection(db, "assets");

  const [showNew, setShowNew] = useState(false);

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const [assetId, setAssetId] = useState(0);
  const [data, setData] = useState([]);

  let parentCats = [
    {
      categoryName: "Барилга, Байгууламж",
      code: "Барилга, Байгууламж",
      key: "Барилга, Байгууламж",
      children: [],
    },
    {
      categoryName: "Өргөтгөл, их засвар",
      code: "Өргөтгөл, их засвар",
      key: "Өргөтгөл, их засвар",
      children: [],
    },
    {
      categoryName: "Тоног төхөөрөмж, тээврийн хэрэгсэл",
      code: "Тоног төхөөрөмж, тээврийн хэрэгсэл",
      key: "Тоног төхөөрөмж, тээврийн хэрэгсэл",
      children: [],
    },
    {
      categoryName: "Тавилга эд хогшил, бусад, үндсэн(биет бус) хөрөнгө",
      code: "Тавилга эд хогшил, бусад, үндсэн(биет бус) хөрөнгө",
      key: "Тавилга эд хогшил, бусад, үндсэн(биет бус) хөрөнгө",
      children: [],
    },
    {
      categoryName: "Стратегийн нөөц хөрөнгө",
      code: "Стратегийн нөөц хөрөнгө",
      key: "Стратегийн нөөц хөрөнгө",
      children: [],
    },
  ];

  const columns = [
    {
      title: "Хөрөнгийн ангиллын нэр",
      dataIndex: "categoryName",
      key: "categoryName",
      width: "40%",
    },
    {
      title: "Зах зээлийн үнэ",
      dataIndex: "marketPrice",
      key: "marketPrice",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (text, record) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(record.code)}
            />
            <IconButton
              iconProps={{ iconName: "Delete" }}
              title="Delete"
              ariaLabel="Delete"
              onClick={() => onDeleteClick(record.code)}
            />
          </div>
        );
      },
    },
  ];

  // Get list data from firebase
  useEffect(async () => {
    let objects = await getDocs(AssetsCollectionRef);
    objects = objects.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    const { parents, childs } = await getAssetParents(parentCats, objects);
    const treeData = await convertAssetsToTree(parents, childs);

    setData(treeData);
  }, [reducerValue]);

  const deleteAsset = async (id) => {
    try {
      const AssetDoc = doc(db, "assets", id);
      await deleteDoc(AssetDoc);
      message.success("Амжилттай устгагдлаа");
      forceUpdate();
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onNew = () => {
    setAssetId(0);
    setShowNew(true);
  };

  const onDismiss = () => {
    setAssetId(0);
    setShowNew(false);
  };

  const onEditClick = (id) => {
    setAssetId(id);
    setShowNew(true);
  };

  const onDeleteClick = (id) => {
    deleteAsset(id);
  };

  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          title="Нийт"
          buttonTitle="Хөрөнгийн худалдан авалт"
          onButtonClick={onNew}
          data={data}
          columns={columns}
          dataCount={data.length}
        />
        <div style={{ margin: 16 }}>
          <Table
            size="small"
            columns={columns}
            dataSource={data}
            childrenColumnName="children"
            expandable={{ expandRowByClick: true }}
          />
          <AssetsPanel
            itemId={assetId}
            isOpen={showNew}
            onDismiss={onDismiss}
            forceUpdate={forceUpdate}
          ></AssetsPanel>
        </div>
      </Layout>
    </Layout>
  );
};

export default Assets;
