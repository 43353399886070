import React, { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { GridDefault, ColEdit, PageHeader } from "dw-components";
import { BUDGETSTATUS } from "../../Const/BudgetStatus";

const Reports = () => {
  const navigate = useNavigate();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const BudgetCollectionRef = collection(db, "budgets");

  const [data, setData] = useState({});
  const [columns, setColumns] = useState([
    {
      name: "Төсөв",
      fieldName: "budgetName",
      minColSize: "mid",
      onRender: (record) => {
        return (
          <div>
            <ColEdit onClick={() => onDetails(record.id)}>
              {record.budgetName}
            </ColEdit>
          </div>
        );
      },
    },
    {
      name: "Нээх огноо",
      fieldName: "openedDate",
      minColSize: "mid",
    },
    {
      name: "Хаах огноо",
      fieldName: "closeDate",
      minColSize: "mid",
    },
    {
      name: "Албан тушаалтны нэр",
      fieldName: "employeeName",
      minColSize: "mid",
    },
    {
      name: "Төлөв",
      fieldName: "status",
      minColSize: "mid",
      onRender: (item) => {
        return BUDGETSTATUS[item.status];
      },
    },
  ]);

  // Get list data from firebase
  useEffect(async () => {
    // get closed budgets
    const Query = query(
      BudgetCollectionRef,
      where("status", "==", "CLOSED")
    );
    let objects = await getDocs(Query);
    objects = objects.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setData(objects);
  }, [reducerValue]);

  const onDetails = (id) => {
    navigate("/report/details", {
      state: { budgetId: id },
    });
  };

  return (
    <div
      style={{
        padding: "60px 16px 680px",
        backgroundColor: "rgb(247, 247, 247)",
      }}
    >
      <PageHeader
        title="Нийт"
        data={data}
        columns={columns}
        dataCount={data.length}
      />
      <div style={{ margin: 16 }}>
        <GridDefault columns={columns} data={data}></GridDefault>
      </div>
    </div>
  );
};

export default Reports;
