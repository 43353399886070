import React, { useEffect, useState } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefaultShimmer, PageHeader, Number } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import moment from 'moment';
import * as xlsx from "xlsx";
import MeterPanel from "./Panel/MeterPanel";

const Meter = () => {

  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const [meterId, setMeterId] = useState();
  const [data, setData] = useState({});
  const [oData, setOData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    }, {
      name: "Хэмжүүрийн дугаар",
      fieldName: "mETERNO",
      maxWidth: 100,
      minWidth: 100
    }, {
      name: "Төрөл",
      fieldName: "tYPENAME",
      maxWidth: 150,
      minWidth: 150
    }, {
      name: "Объект",
      fieldName: "bUILDINGNAME",
      maxWidth: 150,
      minWidth: 150
    }, {
      name: "Бүртгэсэн огноо",
      fieldName: "cREATED",
      maxWidth: 200,
      minWidth: 200
    }, { 
      name: "Үйлдэл",
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.iD)}
            />
          </div>
        );
      }
    }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getMeterList {
            iD
            mETERNO
            wMETERID
            wMETERNO
            tYPEID
            tYPENAME
            bUILDINGID
            bUILDINGNAME
            cREATED
          }
        }
      }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getMeterList;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
    setOData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setMeterId();
    setShowNew(true);
  }

  const onDismiss = () => {
    setMeterId();
    setShowNew(false);
  }

  const onEditClick = (id) => {
    setMeterId(id);
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const downloadExcel = async () => {
    let tData = [];
    data?.map(r => {
      tData.push({
        "Хэмжүүрийн дугаар": r?.mETERNO,
        "Төрөл": r?.tYPENAME,
        "Объект": r?.bUILDINGNAME,
        "Бүртгэсэн огноо": r?.cREATED
      });
    });
    const ws = xlsx.utils.json_to_sheet(tData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Тоолуурын бүртгэл");
    xlsx.writeFile(wb, "Тоолуурын бүртгэл " + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx");
  }

  return (
    <div>
      <Row style={{ minHeight: "95vh" }} wrap={false}>
        <Col flex="none">
          <Sidemenu />
        </Col>
        <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
          <PageHeader
            title={"Тоолуурын бүртгэл"}
            dataCount={data?.length}
            buttonTitle="Тоолуур нэмэх"
            onButtonClick={onNew}
            onSearch={onSearch}
            data={oData}
            filteredData={data}
            columns={columns}
            addRightButton={(
              <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                <Space size={16} >
                  <div onClick={downloadExcel}>
                    <ExcelIcon />
                  </div>
                </Space>
              </div>
            )}
          />
          <div style={{ margin: 16 }}>
            <GridDefaultShimmer columns={columns} data={data} loading={loading}></GridDefaultShimmer>
            <MeterPanel
              id={meterId}
              isOpen={showNew}
              onDismiss={onDismiss}
              afterSave={getData}
            ></MeterPanel>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Meter;
