import React, { useState, useEffect, useContext } from "react";
import { PageTitle, ButtonDefault, FieldNumberFormat, ButtonSecondary, FieldDropdown } from "dw-components";
import { message, Row, Col, Space } from "antd";
import { DataGrid } from "devextreme-react";
import { useParams } from "react-router-dom";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../../../API/API";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment, { months } from "moment";
import { UserContext } from "../../../../Context/UserContext";
import { Icon } from '@fluentui/react/lib/Icon';

const FundDetail = (props) => {

    const [dataEdit, setDataEdit] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [dEcoCat, setEcoCatData] = useState([]);
    const [dSchedule, setScheduleData] = useState([]);
    const [dScheduleDis, setSchDisData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [dSchools, setDSchools] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const { userInfo } = useContext(UserContext);
    const params = useParams();

  
    const onSave = async () => {

        let tDataEdit = dataEdit;
        if (tDataEdit?.length > 0 ) {
            setLoadingSave(true);
            let vSuccess = false;
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < dSchedule.length; j++)
                {
                    if(tR[dSchedule[j].key]) {
                        let tRNew = {};
                        tRNew.sCHOOLID = tR.schoolid;
                        tRNew.bUDGETFUNDID = tR.budgetfundid;
                        tRNew.sCHEDULEAMOUNT = tR[dSchedule[j].key];
                        tRNew.sCHEDULENAME = dSchedule[j].text;
                        tRNew.sCHEDULEID = dSchedule[j].scheduleid;
                        tRNew.eCOCATID = dSchedule[j].parentid;
                        tRNew.iD = 0;
                        tRNew.bUDGETID = parseInt(params?.id);
                        tRNew.fUNDSCHEDULEID = dSchedule[j].key;
                        tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                        tRNew.cREATEDBY = userInfo?.username;
                        tBSecDtl[k] = tRNew;
                        k += 1;
                    }
                }
            }
            await APIGraphQL.post("", {
                query: `mutation ($data: [BudgetFundDetailInputType]) {
                    budgetMutation {
                        addBudgetFundDetail(data: $data) {
                            iD
                        }
                    }
                }`,
                variables: {
                    "data": tBSecDtl
                }
            }).then(response => {
                if (response?.data?.data?.budgetMutation?.addBudgetFundDetail?.length > 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
            getData();
        } 

    }
    
    const getData = async () => {
        
        let tData = [];
        let tdEcoCat = [];
        let tdSchedule = [];
        let tdSchools = [];
        setLoading(true);
        await API.get("/api/budget/getFundSchedule?budgetid=" + params?.id)
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data.length > 0) {
                    tData = response?.data?.retData?.data;
                }
                if (response?.data?.retData?.ecocat.length > 0) {
                    tdEcoCat = response?.data?.retData?.ecocat;
                }
                if (response?.data?.retData?.schedule.length > 0) {
                    tdSchedule = response?.data?.retData?.schedule;
                }
            }
        }).catch(() => {
            setLoading(false);
        });
        await APIGraphQL.post("", {
            query: `{
            sharedQuery {
                getSchoolList {
                key: iD
                text: nAME
              }
            }
          }`
          }).then(response => {
      
            let tCResp = response?.data?.data?.sharedQuery?.getSchoolList;
            if (tCResp) {
              tdSchools = tCResp;
            }
          }).catch(() => {
            setLoading(false);
          });
        setLoading(true);

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        let tdScheduleDis = [];
        tdScheduleDis = [...new Map(tdSchedule.map(item =>
            [item["scheduleid"], item])).values()];
        
        let subtotal = 0;
        let total = 0;
        tData.forEach(tRow => {
            total = 0;
            tdEcoCat?.forEach(el => {
                subtotal = 0;
                tdSchedule.filter(t => t.parentid == el.key).forEach(es => {
                    subtotal += tRow[es.key];
                })
                tRow[el.key + "_TOTAL"] = subtotal;
            })
            
            tdScheduleDis.forEach(el => {
                subtotal = 0;
                tdSchedule.filter(t => t.scheduleid == el.scheduleid).forEach(e => {
                    subtotal += tRow[e.key];
                })
                tRow[el.scheduleid + "_SUBTOTAL"] = subtotal;
                total += subtotal;
            })
            tRow["total"] = total;
        })

        setData(tData);
        setDataEdit(tData);
        setDSchools(tdSchools);
        setEcoCatData(tdEcoCat);
        setScheduleData(tdSchedule);
        setSchDisData(tdScheduleDis);
    }

    useEffect(async () => {
        getData();
    }, [params?.id]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }
        let tDFieldSchedule = dSchedule.filter(t=>t.key == pField);
        if(tDFieldSchedule?.length > 0) {
            let tFieldOthers = dSchedule.filter(t=>t.parentid == tDFieldSchedule[0].parentid);
            let subtotal = 0;
            tFieldOthers?.forEach(el=> {
                subtotal += tDataEdit[pIndex][el.key];
            })
            tDataEdit[pIndex][tDFieldSchedule[0].parentid + "_TOTAL"] = subtotal;
            let total = 0;
            dScheduleDis.forEach(el => {
                subtotal = 0;
                dSchedule.filter(t => t.scheduleid == el.scheduleid).forEach(e => {
                    subtotal += tDataEdit[pIndex][e.key];
                })
                tDataEdit[pIndex][el.scheduleid + "_SUBTOTAL"] = subtotal;
                total += subtotal;
            })
            tDataEdit[pIndex]["total"] = total;
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
   
    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation deleteBuildingInvestment($id: Int) {
                    sharedMutation {
                        deleteBuildingInvestment(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { });
            message.success("Амжилттай");
        }

        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    
    return (
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
            <div style={{ padding: 4, backgroundColor: "#ffffff", height: 32, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                  <Row>
                      <Col span={21}>
                        <div style={{ marginLeft: 0 }}>
                            <PageTitle>Захиргаа төвлөрсөн сангийн төвлөрүүлэлт</PageTitle>
                        </div>
                       </Col>
                    </Row>
                   </div>
                    <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
                       <div style={{ padding: 6, backgroundColor: "#ffffff", height: 56, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                           <div style={{ marginRight: 8 }}>
                         <Row gutter={16} justify="space-between">
                          <Col> 
                              <Row gutter={30} justify="start" > 
                              <Space>
                                  <Col s>
                                        <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                                  </Col>
                                  </Space>
                              </Row>
                          </Col>
                        <Col>
                            <Row gutter={8} justify="end">
                                <Space>
                                      <ButtonDefault text="Хадгалах" onClick={() => onSave()}/>
                                </Space>
                            </Row>
                        </Col>
                      </Row>
                  </div>
          </div>
          <div style={{ marginTop: 0 }}>
              <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
              >
                <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                    <Column width={50} caption="№" alignment='center' allowResizing={true} fixed={true} 
                        cellRender={(item) => {
                            return (
                                <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                            );
                        }}
                    ></Column>
                  <Column
                      minWidth={500}
                      keyfield="schoolid"
                      dataField="schoolname"
                      caption="Сургуулийн нэр"
                      alignment='left'
                      width={200}
                      format="fixedPoint"
                      fixed={true} 
                      cellRender={(item) => {
                          return (
                              <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "schoolid", pNameValue, "schoolname")} defaultValue={item.data?.schoolid} data={dSchools} />
                          );
                      }} />
              
                  {dEcoCat?.map(r => {
                      return (
                              <Column
                                  key={r.key}
                                  caption={r.text} 
                                  alignment={"center"}>
                       {dSchedule.filter(t=>t.parentid == r.key)?.map(t => {
                      return (
                              <Column
                                  key={t.key}
                                  caption={t.text} 
                                  alignment={"center"}
                                  dataField={t.key?.toString()}
                                  width={150}
                                  format={"fixedPoint"}
                                  cellRender={item => {
                                      return <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, t.key)} defaultValue={item.data?.[t.key]} />
                                  }}
                                  />
                        );
                   })
                }
                      <Column
                      dataField={r.key.toString() + "_TOTAL" }
                      caption="Дүн"
                      alignment={"center"}
                      minWidth={100}
                      cellRender={item => {
                          return <FieldNumberFormat disable= {true} onChanged={() => {}} defaultValue={item.data?.[r.key.toString() + "_TOTAL"]}/>
                        }}
                      />
                              </Column>
                      );
                  })
                  }

         <Column caption={"Нийт"} alignment='center'>
                {dScheduleDis?.map(r => {
                      return (
                              <Column
                                  key={r.scheduleid}
                                  caption={r.text} 
                                  alignment={"center"}
                                  dataField={r.scheduleid?.toString() + "_SUBTOTAL"}
                                  width={150}
                                  format={"fixedPoint"}
                                  cellRender={item => {
                                      return <FieldNumberFormat disable= {true} onChanged={() => {}} defaultValue={item.data?.[r.scheduleid?.toString() + "_SUBTOTAL"]} />
                                    }} />
                                    );
                                })}            
                        </Column>
                    <Column
                      dataField="total"
                      caption="Нийт дүн"
                      alignment={"center"}
                      minWidth={120}
                      cellRender={item => {
                          return (
                                <FieldNumberFormat disable= {true} onChanged={() => {}}defaultValue={item.data?.total}/>
                            );
                      }} />
                    <Summary>
                    <TotalItem
                        column="№"
                        displayFormat="Нийт:"
                    />
                    <TotalItem
                        column="total"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        alignment="right"
                        displayFormat="{0}₮"
                    />
                </Summary>
                  <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
              </DataGrid>
                  </div>
                  </div>
                  </div>
      );
    
  }

export default FundDetail;