import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdown, Row, Col } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, GroupItem, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { APIGraphQL } from "../../API/APIGraphQL";
import { UserContext } from "../../Context/UserContext";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import ChangeRatePanel from "../BudgetDtlIndicator/ChangeRatePanel/ChangeRatePanel";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";
import { FastBackwardFilled } from "@ant-design/icons";

const SalaryGridPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [showChangeRate, setShowChangeRate] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);
        setLoadingSave(true);
        let vSuccess = false;
        if(props?.id && (depid || schoolid)) {

            if (tDataEdit?.length > 0) {
                await API.post("/api/budget/addBudgetSalary?budgetid=" + props?.id + "&schoolid=" + (props?.levelid == 1 ? (!depid ? userInfo?.schoolid : depid) : (!schoolid ? userInfo?.schoolid : schoolid)) + "&username=" + userInfo?.username,
                    tDataEdit
                ).then(response => {
                    if (response?.status == 200 && response?.data.retType == 0) {
                        API.post("/api/budget/calcBudgetSalary?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                        "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                        "&userschoolid=" + userInfo?.schoolid).then(response => {
                            if (response?.status == 200 && response?.data?.retType == 0) {
                                message.success("Амжилттай");
                                vSuccess = true;
                            }
                        }).catch(() => {
                            setLoading(false);
                        });
                    }
                }).catch(() => {
    
                    setLoadingSave(false);
                });
            }

        }

        if (vSuccess) {
            await props.afterSave();
        }
        setLoadingSave(false);
        props.onDismiss();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;

        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        await API.get("/api/budget/budgetSalaryTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Цалингийн товчоо." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/budgetSalary?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);

        setLoading(false);
        setData(tData);
        setDataEdit(tData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;

        if (pField == "basesalary" || pField == "degree" || pField == "position" || pField == "qualifications"
            || pField == "added" || pField == "skill") {
            tDataEdit[pIndex]["additionalamount"] = (tDataEdit[pIndex]["degree"] || 0)
                + (tDataEdit[pIndex]["position"] || 0)
                + (tDataEdit[pIndex]["qualifications"] || 0)
                + (tDataEdit[pIndex]["added"] || 0)
                + (tDataEdit[pIndex]["skill"] || 0);
            tDataEdit[pIndex]["total"] = (tDataEdit[pIndex]["basesalary"] || 0)
                + (tDataEdit[pIndex]["additionalamount"] || 0);
            tDataEdit[pIndex]["grandtotal"] = (tDataEdit[pIndex]["calcmonth"] || 0) * (tDataEdit[pIndex]["total"] || 0);
        } else if (pField == "calcmonth") {
            tDataEdit[pIndex]["grandtotal"] = pValue * (tDataEdit[pIndex]["total"] || 0);
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    const onShowChangeRate = async () => {
        setShowChangeRate(true);
    }

    const onDismiss = async () => {
        setShowChangeRate(false);
    }

    const onChangeRate = async (rateData) => {
        setLoadingSave(true);
        let vSuccess = false;
        if(rateData?.sCHOOLIDS) {
            await API.post("/api/budget/changeBudgetRateSalary?userschoolid=" + userInfo?.schoolid,
                rateData
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    message.success("Амжилттай");
                    vSuccess = true;
                }
                else if (response?.data.retType == 2) {
                    message.warning("Хувь оруулна уу!");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
        }

        if (vSuccess) {
            await props.afterSave();
        }
        setLoadingSave(false);
        props.onDismiss();
    }

    return (
        <PanelBase
            headerText="Цалингийн зардал оруулах"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={(
                <div style={{ display: "flex" }}>
                    {!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    }
                    {disable && userInfo?.schooltypeid == 1 &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onShowChangeRate} text="Хувиар өөрчлөх" />)}
                        </div>
                    }
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                /мян.төгрөг/
            </div>
            {loading ? (<LoadSpin />) : (
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column Width={50} caption="№" alignment='center' allowResizing={true}
                            cellRender={(item) => {
                                return (
                                    <div> {item.key?.arrayIndex + 1 + "."} </div>
                                );
                            }}
                        />
                        <Column
                            dataField="categoryname"
                            caption="Ангилал"
                            groupIndex={0}
                            alignment='center'
                        />
                        <Column
                            width={250}
                            dataField="positionname"
                            caption="Үзүүлэлт"
                            alignment='center'
                            cellRender={(item) => {
                                return (
                                    <div style={{ height: 38 }}>
                                        {item.text}
                                    </div>
                                );
                            }}
                        />
                        <Column
                            dataField="staff"
                            caption="Ажилчдын тоо"
                            alignment='center'
                            width={80}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.staff} />
                                );
                            }} />
                        <Column
                            dataField="basesalary"
                            caption="Үндсэн цалин"
                            alignment='center'
                            width={150}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "basesalary")} defaultValue={item.data?.basesalary} fixedDecimalScale={true} />
                                );
                            }} />

                        <Column caption="Нэмэгдлүүд" alignment='center'>
                            <Column
                                dataField="degree"
                                caption="Эрдмийн зэрэг цолны"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "degree")} defaultValue={item.data?.degree} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="position"
                                caption="Албан тушаалын"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "position")} defaultValue={item.data?.position} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="qualifications"
                                caption="Мэргэшлийн зэргийн"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "qualifications")} defaultValue={item.data?.qualifications} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="added"
                                caption="Хавсран ажилласан"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "added")} defaultValue={item.data?.added} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="skill"
                                caption="Ур чадварын"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "skill")} defaultValue={item.data?.skill} fixedDecimalScale={true} />
                                    );
                                }} />
                        </Column>
                        <Column
                            dataField="additionalamount"
                            caption="Нэмэгдлийн дүн"
                            alignment='center'
                            format="fixedPoint"
                            width={150}
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "additionalamount")} defaultValue={item.data?.additionalamount} fixedDecimalScale={true} />
                                );
                            }} />
                        <Column
                            dataField="total"
                            caption="Сарын нийт цалин"
                            alignment='center'
                            minwidth={150}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.total} fixedDecimalScale={true} />
                                );
                            }} />
                        <Column
                            dataField="calcmonth"
                            caption="Нийт тооцох сар"
                            alignment='center'
                            minwidth={150}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetsalaryid, pValue, "calcmonth")} defaultValue={item.data?.calcmonth} />
                                );
                            }} />
                        <Column
                            dataField="grandtotal"
                            caption="Жилийн нийт цалин"
                            alignment='center'
                            minwidth={150}
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.grandtotal} fixedDecimalScale={true} />
                                );
                            }} />
                        <Summary>
                            <GroupItem
                                column="staff"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="basesalary"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="degree"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="position"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="qualifications"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="added"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="skill"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="additionalamount"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="total"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="grandtotal"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="staff"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="basesalary"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="degree"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="position"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="qualifications"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="added"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="skill"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="additionalamount"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="total"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column="grandtotal"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGrid>
                    {!disable &&
                        <div style={{ marginTop: 24 }}>
                            Анхааруулга: Цалин бодоход ажилчдын тоо заавал оруулсан байх шаардлагатайг анхаарна уу!
                        </div>
                    }
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                    <ChangeRatePanel isOpen={showChangeRate} budgetid={props?.id} indid={props?.indId} 
                        onDismiss={onDismiss}
                        title="Цалин"
                        showRate={true}
                        onChangeRate={onChangeRate}/>
                </div>
            )}
        </PanelBase >
    );
}

export default SalaryGridPanel;