export const baseParseFloat = (pValue) => {
    let tValue = 0;

    if (pValue && !isNaN(parseFloat(pValue))) {
        tValue = parseFloat(pValue);
    }
    return tValue;
}

export const setAllDep = (isShow, pschoolid, userschoolid, selectedschoolid, levelid = 1) => {
    let alldep = 0;
    if(isShow)
    {
        if(pschoolid == userschoolid) {
            alldep = 1;
        }
        else if(pschoolid == selectedschoolid && levelid != 2){
            alldep = 1;
        }
    }
    return alldep;
}

export const setDisableIndicators = (pCoverageid, pSchooltypeid, alldep, statusid, currentschooltypeid = -1) => {
    let tDisable = false;
    if(alldep == 1) {
        tDisable = true;
    }

    if(currentschooltypeid == 3) {
        tDisable = true; 
    }
    return tDisable;
}

export const month2digits =  (month) => { 
    return (month < 10 ? '0' : '') + month;
}

import { API } from "../API/API";
export const calcfunc  = async(funcname, budgetid, schoolid, depid, userschoolid) => {
    let vSuccess = false;
    await API.post("api/budget/" + funcname + "?budgetid=" + budgetid + "&schoolid=" + schoolid + 
    "&depid=" + depid +
    "&userschoolid=" + userschoolid).then(response => {
        if (response?.status == 200 && response?.data.retType == 0) {
            vSuccess = true;
        }
    });
    return vSuccess;
}

import CryptoJS from 'crypto-js';
import { resetIds } from "@fluentui/utilities";
export const encrypt = (number) => {
    var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
    var encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(number),
        key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        },
    );
    return encrypted.toString();
};

export const deccrypt = (number) => {
    var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
    var decrypted = CryptoJS.AES.decrypt(
        number,
        key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        },
    );
    return decrypted.toString(CryptoJS.enc.Utf8).toString();
};