import axios from 'axios';
import { message } from 'antd';
import { getBaseURL } from './APIConfig';

let errorMessage = "Амжилтгүй боллоо. ";

const auth = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
};

const API = axios.create({
    baseURL: getBaseURL(),
    withCredentials: false,
    timeout: 240000,
    headers: auth
});

API.interceptors.request.use(function (config) {

    if (document.getElementsByClassName('l-c-container').length > 0) {
        document.getElementsByClassName('l-c-container')[0].style = 'display:block';
    }
    return config;
}, function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
    if (document.getElementsByClassName('l-c-container').length > 0) {
        document.getElementsByClassName('l-c-container')[0].style = 'display:none';
    }

    if (response.status !== 200) {
        message.error(errorMessage + response.message);
    }

    if (!response?.config?.ignoreRetType && response.data.rettype != undefined && response?.data?.retType != 0) {
        message.error(errorMessage + response?.data?.retMsg);
    }

    return response;
}, async function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
});

export { API };