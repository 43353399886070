import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldNumberFormat, LoadSpin, PanelBase} from "dw-components";
import FieldComboBoxMultiSelectAll from "../../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { FOREIGN, LESSONTYPE } from "../../../../Const/BudgetTuitionFeeConst";
import { UserContext } from "../../../../Context/UserContext";

const TuitionFeePanel = (props) => {
  const [data, setData] = useState({});
  const [dTLevel, setDTLevel] = useState([]);
  const [dBYear, setDBYear] = useState([]);
  const [dECat, setDECat] = useState([]);
  const [dSchools, setDBSchools] = useState([]);
  const [dSchoolIds, setDBSchoolIds] = useState([]);
  const [checkRule, setCheckRule] = useState(false);

  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);

    let tDTLevel = [];
    let tDBYear = [];
    let tDECat = [];
    let tDSchool = [];

    await APIGraphQL.post("", {
      query: `query ($locationid: Int) {
        sharedQuery {
          getTrainingLevelList {
            key: iD
            text: nAME
          }
          getBudgetYearList {
            key: yEAR
            text: yEAR
          }
          tuitionEconomyCategorys {
            key: iD
            text: nAME
          }
          schoolbylocation(locationid: $locationid) {
            key: iD
            text: nAME
          }
        }
      }`,
      variables: {
        "locationid": 1
      }
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.getTrainingLevelList;
      let tSResp = response?.data?.data?.sharedQuery?.getBudgetYearList;
      let tEResp = response?.data?.data?.sharedQuery?.tuitionEconomyCategorys;
      let tLResp = response?.data?.data?.sharedQuery?.schoolbylocation;
      if (tResp?.length > 0) {
        tDTLevel = tResp;
      }
      if (tSResp?.length > 0) {
        tDBYear = tSResp;
      }
      if (tEResp?.length > 0) {
        tDECat = tEResp;
      }
      if (tLResp?.length > 0) {
        tDSchool = tLResp;
        let schoolids = [...new Set(tDSchool.map((item) => item["key"]))];
        setDBSchoolIds(schoolids);
        // tDSchool.push({ key: schoolids, text: "Бүгд" });
      }
    }).catch(() => {
      setLoading(false);
    });
    setDTLevel(tDTLevel);
    setDBYear(tDBYear);
    setDECat(tDECat);
    setDBSchools(tDSchool);
    let tData = {};
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getTuitionFee(id: $id) {
              iD
              tRAININGLEVELID
              tRAININGLEVELNAME
              lESSONTYPEID
              lESSONTYPENAME
              bATCHHOURLYFEE
              tRAININGTYPEID
              tRAININGTYPENAME
              sEMESTERID
              sEMESTERNAME
              yEAR
              eCONOMYCATEGORYID
              sCHOOLIDS
              iSFOREIGN
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getTuitionFee;
          if (tResp?.iD) {
            tData = tResp;
            tData.sCHOOLIDS = tData.sCHOOLIDS.split(',').map(Number);
          }
        }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      if(fieldname == "lESSONTYPENAME") {
        if(key == 1) {
          tData.sCHOOLIDS = dSchoolIds;
        }
        else {
          tData.sCHOOLIDS = "";
        }
      }
      if(field == "eCONOMYCATEGORYID") {
      await APIGraphQL.post("", {
          query: `query ($id: Int) {
            sharedQuery {
              economycategory(id: $id) {
                iNDID: cALCTYPEID
              }
            }
          }`,
          variables: {
              "id": key
          }
      }).then(response => {
          let tResp = response?.data?.data.sharedQuery?.economycategory.iNDID;   
            if (tResp) {
              tData.iNDID = parseInt(tResp);
          }
      }).catch(() => {
          setLoading(false);
      });
  }
  setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.tRAININGLEVELID ||
      !data?.lESSONTYPEID ||
      !data?.bATCHHOURLYFEE ||
      !data?.yEAR ||
      !data?.eCONOMYCATEGORYID) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      tData.sCHOOLIDS = tData.sCHOOLIDS.filter(e => e !== 'selectAll').toString();
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: TuitionFeeInputType) {
          sharedMutation {
            addTuitionFee(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addTuitionFee?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteTuitionFee($id: Int) {
        sharedMutation {
          deleteTuitionFee(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteTuitionFee == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div className="add-asset">
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" checkreq={checkRule} defaultValue={data.eCONOMYCATEGORYID} data={dECat} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Гадаад/Дотоод" keyfield="iSFOREIGN" checkreq={checkRule} defaultValue={data.iSFOREIGN} data={FOREIGN} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Сургалтын түвшин" keyfield="tRAININGLEVELID" keyfieldname="tRAININGLEVELNAME" checkreq={checkRule} defaultValue={data.tRAININGLEVELID} data={dTLevel} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Хичээлийн хэлбэр сонгох" keyfield="lESSONTYPEID" keyfieldname="lESSONTYPENAME" checkreq={checkRule} defaultValue={data.lESSONTYPEID} data={LESSONTYPE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} title="Сургууль сонгох" keyfield="sCHOOLIDS" checkreq={checkRule} defaultValue={data.sCHOOLIDS} data={dSchools} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Багц цагийн төлбөр" keyfield="bATCHHOURLYFEE" checkreq={checkRule} defaultValue={data.bATCHHOURLYFEE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Төсвийн жил" keyfield="yEAR" checkreq={checkRule} defaultValue={data.yEAR} data={dBYear} />
        </div>
      </div>
    </PanelBase>
  );
};

export default TuitionFeePanel;
