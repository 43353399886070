import React, { useEffect, useState } from "react";
import { Row, Col,Space } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefaultShimmer, PageHeader } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { APIGraphQL } from "../../../API/APIGraphQL";
import UserPanel from "./Panel/UserPanel";
import {ReactComponent as ExcelIcon} from '../../../svg/excel.svg';
import moment from "moment";
import * as xlsx from "xlsx";

const User = (props) => {

    const [data, setData] = useState({});
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const [userId, setUserId] = useState();

    const [columns] = useState([
        {
            name: "№",
            onRender: (row, index) => {
                return <div> {index + 1 + "."} </div>
            },
            maxWidth: 25,
            minWidth: 25
        },
        {
            name: "И-мэйл хаяг",
            fieldName: "uSERNAME",
            maxWidth: 100,
            minWidth: 70

        },
        {
            name: "Ажилтаны нэр",
            fieldName: "eMPLOYEENAME",
            maxWidth: 120,
            minWidth: 70

        },
        {
            name: "Төрөл",
            fieldName: "tYPENAME",
            maxWidth: 180,
            minWidth: 120  
   
        },
        {
            name: "Нэгж",
            fieldName: "sCHOOLNAME",
            minColSize: "mid",
        },
        {
            name: "Албан тушаал",
            fieldName: "pOSITIONNAME",
            maxWidth: 180,
            minWidth: 120  
        },
        {
            name: "Эрх",
            fieldName: "rOLENAME",
            maxWidth: 180,
            minWidth: 120  
        },
        {
            name: "Бүртгэсэн огноо",
            fieldName: "cREATED",
            maxWidth: 120,
            minWidth: 120  
        },
        {
            fieldName: "action",
            minColSize: "mid",
            onRender: (item) => {
                return (
                    <div>
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            title="Edit"
                            ariaLabel="Edit"
                            onClick={() => onEditClick(item.iD)}
                        />
                    </div>
                );
            },
        },
    ]);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  getUsersList {
                    iD
                    sCHOOLID
                    sCHOOLNAME
                    pARENTID
                    pARENTNAME
                    uSERNAME
                    iSACTIVE
                    pOSITIONID
                    pOSITIONNAME
                    eMPLOYEENAME
                    tYPEID
                    tYPENAME
                    rOLEID
                    rOLENAME
                    cREATED
                    cREATEDBY
                  }
                }
              }`,
        }).then((response) => {
            let tResp = response?.data?.data?.sharedQuery?.getUsersList;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        })
        setLoading(false);
        setData(tData);    
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (data) => {
        setData(data);
    };

    const onNew = () => {
        setShowNew(true);
    };

    const onDismiss = () => {
        setUserId();
        setShowNew(false);
    };

    const onEditClick = (id) => {
        setUserId(id);
        setShowNew(true);
    };
    const downloadExcel=async()=> {
        let tData =[];
        data?.map(r => {
          tData.push({
            "И-мэйл хаяг": r?.uSERNAME,
            "Ажилтаны нэр": r?.eMPLOYEENAME,
            "Төрөл": r?.tYPENAME,
            "Нэгжийн нэр": r?.sCHOOLNAME,
            "Албан тушаал": r?.pOSITIONNAME,
            "Эрх": r?.rOLENAME,
            "Бүртгэсэн огноо": r?.cREATEDBY
          });
        });
        const ws = xlsx.utils.json_to_sheet(tData);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Хэрэглэгчийн жагсаалт");
        xlsx.writeFile(wb,"Хэрэглэгчийн жагсаалт " + moment().format ("YYYY-MM-DD HH-mm-ss") + ".xlsx");
      }
    return (
        <div>
            <Row style={{ minHeight: "95vh" }} wrap={false}>
                <Col flex="none">
                    
                </Col>
                <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
                    <PageHeader
                        title={"Нийт"}
                        dataCount={data?.length}
                        buttonTitle="Хэрэглэгч"
                        onButtonClick={onNew}
                        columns={columns}
                        onSearch={onSearch}
                        data={oData}
                        loading={loading}
                        filteredData={data} 
                        addRightButton={(
                            <div style={{marginRight: 6, marginTop: 2, cursor:'pointer'}}>
                              <Space size={16}>
                                <div onClick={downloadExcel}>
                                  <ExcelIcon/>
                                </div>
                              </Space>
                            </div>
                          )}/>
                    <div style={{ padding: 16 }}>
                        <GridDefaultShimmer columns={columns} data={data} loading={loading} />
                        <UserPanel id={userId} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default User;