import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldDropdown} from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { APIGraphQL } from "../../API/APIGraphQL";
import { UserContext } from "../../Context/UserContext";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import FileDownload from "js-file-download";

const BudgetNormative = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {
        let vSuccess = false;

        if (depid || schoolid) {
            try {
                setLoadingSave(true);
                await API.post("/api/budget/calcBudgetNormative?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                    "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                    "&userschoolid=" + userInfo?.schoolid +
                    "&username=" + userInfo?.username).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            vSuccess = true;
                            message.success("Амжилттай");
                        }
                    });
            } finally {
                setLoadingSave(false);
            }
        }

        if (vSuccess) {
            await props.afterSave();
            props.onDismiss();
        }
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        setLoading(true);

        await API.get("/api/budget/normativeItem?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);

        setLoading(false);
        setData(tData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);

        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        await API.get("/api/budget/budgetNormativeItemIndTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Норматив бараа материал." + pData?.filetype);
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    return (
        <PanelBase
            headerText="Норматив бараа материал"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={(
                <div style={{ display: "flex" }}>
                    {!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Тооцоолох" />)}
                        </div>
                    }
                    <div style={{ float: "left" }}>
                        <ButtonReport title="Татах" onClick={onExportDoc} />
                    </div>
                </div>
            )}

        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                /мян.төгрөг/
            </div>

            {loading ? (
                <LoadSpin />
            ) : (
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column width={50} caption="№" alignment='center' allowResizing={true}
                            cellRender={(item) => {
                                return (
                                    <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                );
                            }}
                        ></Column>
                        <Column
                            dataField="name"
                            caption="Норматив бараа материал"
                            alignment='center'
                        />
                        <Column
                            dataField="positionname"
                            caption="Ажлын байр"
                            alignment='center'
                            width={200} />
                        <Column
                            dataField="staff"
                            caption="Орон тоо"
                            alignment='center'
                            format="fixedPoint"
                            width={150} />
                        <Column
                            dataField="unitname"
                            caption="Хэмжих нэгж"
                            alignment='center'
                            width={150} />
                        <Column
                            dataField="quota"
                            caption="Норм"
                            alignment='center'
                            format="#,##0.00"
                            width={150} />
                        <Column
                            dataField="unitprice"
                            caption="Нэгж үнэ"
                            alignment='center'
                            format="#,##0.0000"
                            width={150} />
                        <Column
                            dataField="totalprice"
                            caption="Нийт үнэ"
                            alignment='center'
                            format="#,##0.00"
                            minwidth={150} />
                        <Summary>
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="totalprice"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}₮"
                            />
                        </Summary>
                    </DataGrid>
                    {!disable &&
                        <div style={{ marginTop: 24 }}>
                            Анхааруулга: Ажилчдын орон тооны мэдээлэл оруулсаны дараа Норматив бараа материалын зардал тооцоологдохыг анхаарна уу!
                        </div>
                    }
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BudgetNormative;