import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, Col, Row, FieldDropdown, FieldText, ButtonSecondary } from "dw-components";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import { baseParseFloat } from "../../Utils/SharedFunc";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { Icon } from '@fluentui/react/lib/Icon';
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const BTripForeignPanel = (props) => {

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [dBTravel, setDBTravel] = useState([]);
    const [dCountry, setDCountry] = useState([]);
    const [dBDiv, setDBDiv] = useState([]);
    const [rate, setRate] = useState(0);

    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);


    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);

        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.budgetid = parseInt(props?.id);

                tR.schoolname = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.schoolname = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.schoolname = dDeps[tIndex].text;
                    }
                }
                tR.schoolid = parseInt(currentschoolid);

                delete tR.arrayIndex;
                delete tR.edit;
                if (tR.id == 0) {
                    tR.createdby = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.createdby = userInfo?.username;

                tDataEdit[i] = tR;
            }

            await API.post("api/budget/calcBForeignTrip?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid,
                tDataEdit
            ).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    vSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDBTravel = [];

        let tRate = 0;
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        setLoading(true);
        await API.get("/api/budget/budgetForeignBTrip?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `query ($budgetid: Int) {
                budgetQuery {
                    budget(id: $budgetid) {
                    rATE
                    }
                }
                    sharedQuery {
                    getForeignBusinessTripListByRegion(budgetid: $budgetid) {
                        key: rEGIONID
                        text: rEGIONNAME
                    }
                }
            }
              `,
            variables: {
                "budgetid": parseInt(props?.id)
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getForeignBusinessTripListByRegion?.length > 0) {
                tDBTravel = tResp?.sharedQuery?.getForeignBusinessTripListByRegion;
            }
            if (tResp?.budgetQuery?.budget?.rATE > 0) {
                tRate = parseFloat(tResp?.budgetQuery?.budget?.rATE).toFixed(3);
            }
        }).catch(() => {
            setLoading(false);
        });
        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setRate(tRate);
        await getAttachData(pSchoolId);
        setLoading(false);

        setData(tData);
        setDataEdit(tData);
        setDBTravel(tDBTravel);
    }
    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }
    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }
    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);
        getSPlan();

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }
        if (pField == "regionid") {
            let tDCountry = [];
            await APIGraphQL.post("", {
                query: `query ($budgetid: Int,$regionid: Int) {
                        sharedQuery {
                            getFTripByCountry(budgetid: $budgetid,regionid: $regionid) {
                            key: cOUNTRYID
                            text: cOUNTRYNAME
                        }
                    }
                  }
                  `,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "regionid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data;
                if (tResp?.sharedQuery?.getFTripByCountry?.length > 0) {
                    tDCountry = tResp?.sharedQuery?.getFTripByCountry;
                }
            }).catch(() => {
                setLoading(false);
            });
            setDCountry(tDCountry);
        }
        if (pField == "countryid") {
            let dBDiv = [];
            await APIGraphQL.post("", {
                query: `query ( $budgetid: Int,$regionid: Int, $countryid: Int) {
                        sharedQuery {
                            getBTripByDivision(budgetid: $budgetid, regionid: $regionid, countryid: $countryid ) {
                            key: dIVISIONID
                            text: dIVISIONNAME
                        }
                    }
                  }
                  `,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "regionid": tDataEdit[pIndex]["regionid"],
                    "countryid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data;
                if (tResp?.sharedQuery?.getBTripByDivision?.length > 0) {
                    dBDiv = tResp?.sharedQuery?.getBTripByDivision;
                }
            }).catch(() => {
                setLoading(false);
            });
            setDBDiv(dBDiv);
        }
        if (pField == "divisionid") {
            await APIGraphQL.post("", {
                query: `query ( $divisionid: Int, $budgetid: Int  ) {
                            sharedQuery {
                                getBTripByDiv( divisionid: $divisionid,  budgetid: $budgetid) {
                                businesstripid: iD
                                travelcost: tRAVELCOST
                                housingcost: hOUSINGCOST
                                travelday: tRAVELDAY
                                unittravelcost: tRAVELCOST
                                unithousingcost: hOUSINGCOST
                                unittravelday: tRAVELDAY
                            }
                        }
                      }
                      `,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "divisionid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getBTripByDiv;

                if (tResp) {
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                    let tR = tDataEdit[pIndex];
                    tDataEdit[pIndex]["mnttotalcost"] = parseFloat((baseParseFloat(tR.qty) * baseParseFloat(tR.day) * ((baseParseFloat(tR.rate) * (baseParseFloat(tR.travelcost) + baseParseFloat(tR.housingcost) + baseParseFloat(tR.travelday))) + baseParseFloat(tR.visa)) + baseParseFloat(tR.gift)).toFixed(2));
                    setData(tDataEdit);
                }
            }).catch(() => {
                setLoading(false);
            });
        } else if (pField == "qty") {
            let tR = tDataEdit[pIndex];
            let rate2 = baseParseFloat(tR.rate || 0);
            let day = baseParseFloat(tR.day || 0);
            tDataEdit[pIndex]["travelcost"] = parseFloat((tR.unittravelcost || 0) * pValue).toFixed(2);
            tDataEdit[pIndex]["housingcost"] = parseFloat((tR.unithousingcost || 0) * pValue * day).toFixed(2);
            tDataEdit[pIndex]["travelday"] = parseFloat((tR.unittravelday || 0) * pValue * day).toFixed(2);
            let travelcost = baseParseFloat(tR.travelcost || 0);
            let housingcost = baseParseFloat(tR.housingcost || 0);
            let travelday = baseParseFloat(tR.travelday || 0);
            let visa = baseParseFloat(tR.visa || 0);
            let gift = baseParseFloat(tR.gift || 0);
            tDataEdit[pIndex]["mnttotalcost"] = parseFloat(travelcost * rate2 + (rate2 * (housingcost + travelday + visa) + gift)).toFixed(2);
        } else if (pField == "qty" || pField == "day") {
            let tR = tDataEdit[pIndex];
            let rate2 = baseParseFloat(tR.rate || 0);
            let qty = baseParseFloat(tR.qty || 0);
            tDataEdit[pIndex]["housingcost"] = parseFloat((tR.unithousingcost || 0) * pValue * qty).toFixed(2);
            tDataEdit[pIndex]["travelday"] = parseFloat((tR.unittravelday || 0) * pValue * qty).toFixed(2);
            let travelcost = baseParseFloat(tR.travelcost || 0);
            let housingcost = baseParseFloat(tR.housingcost || 0);
            let travelday = baseParseFloat(tR.travelday || 0);
            let visa = baseParseFloat(tR.visa || 0);
            let gift = baseParseFloat(tR.gift || 0);
            tDataEdit[pIndex]["mnttotalcost"] = parseFloat(travelcost * rate2 + (rate2 * (housingcost + travelday + visa) + gift)).toFixed(2);

        } else if (pField == "housingcost" || pField == "travelcost" || pField == "travelday" || pField == "visa" || pField == "gift") {
            let tR = tDataEdit[pIndex];
            let rate2 = baseParseFloat(tR.rate || 0);
            let travelcost = baseParseFloat(tR.travelcost || 0);
            let housingcost = baseParseFloat(tR.housingcost || 0);
            let travelday = baseParseFloat(tR.travelday || 0);
            let visa = baseParseFloat(tR.visa || 0);
            let gift = baseParseFloat(tR.gift || 0);
            tDataEdit[pIndex]["mnttotalcost"] = parseFloat(travelcost * rate2 + (rate2 * (housingcost + travelday + visa) + gift)).toFixed(2);
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);

        if (pField == "sptopprid") {
            tDataEdit[pIndex]["spgoalid"] = null;
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spgoalid") {
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spstrid") {
            tDataEdit[pIndex]["spindid"] = null;
        }
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length,
            rate: rate
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        let vSuccess = false;
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation delBudgetVehicleTax($id: Int) {
                    budgetMutation {
                      delBudgetForeignBTrip(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => {
                if (response?.data?.data?.budgetMutation?.delBudgetForeignBTrip == 1) {
                    vSuccess = true;
                }
            });
        }

        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        if (vSuccess) {
            await API.post("api/budget/calcBForeignTrip?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid,
                tDataEdit
            ).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    vSuccess = true;
                }
            }).catch(() => {
            });
        }
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Гадаад томилолт"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={(!disable && (
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 16 }}>
                        {loadingSave ? (<LoadSpin />) :
                            (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                    </div>
                </div>
            ))}>
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div>
                        <Row justify="space-between">
                            <Col>
                                {(!disable &&
                                    <Row justify="start" >
                                        <Col style={{ marginTop: 24 }}>
                                            <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Row style={{ marginTop: 30 }}>
                                <Col justify="end">
                                    /мян.төгрөг/
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                dataField="regionname"
                                caption="Бүс нутаг"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.regionid, pValue, "regionid", pNameValue, "regionname")} defaultValue={item.data?.regionid} data={(item.data?.regionid || item.data?.regionid > 0) ? [{ key: item.data?.regionid, text: item.data?.regionname }] : dBTravel} />
                                    );
                                }} />
                            <Column
                                dataField="countryname"
                                caption="Улс"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.countryid, pValue, "countryid", pNameValue, "countryname")} defaultValue={item.data?.countryid} data={(item.data?.countryid || item.data?.countryid > 0) ? [{ key: item.data?.countryid, text: item.data?.countryname }] : dCountry} />
                                    );
                                }} />
                            <Column
                                dataField="divisionname"
                                caption="Хот"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.divisionid, pValue, "divisionid", pNameValue, "divisionname")} defaultValue={item.data?.divisionid} data={(item.data?.divisionid || item.data?.divisionid > 0) ? [{ key: item.data?.divisionid, text: item.data?.divisionname }] : dBDiv} />
                                    );
                                }} />
                            <Column
                                dataField="note"
                                caption="Томилолтын зорилго"
                                alignment='center'
                                minwidth={300}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                    );
                                }} />
                            <Column
                                dataField="qty"
                                caption="Хүний тоо"
                                alignment='center'
                                width={80}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "qty")} defaultValue={item.data?.qty} />
                                    );
                                }} />
                            <Column
                                dataField="day"
                                caption="Томилолтын хоног"
                                alignment='center'
                                width={90}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "day")} defaultValue={item.data?.day} />
                                    );
                                }} />
                            <Column
                                dataField="rate"
                                caption="Ханш"
                                width={150}
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={parseFloat(item.data?.rate).toFixed(3)} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="travelcost"
                                caption="Замын зардал $"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "travelcost")} defaultValue={item.data?.travelcost} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="housingcost"
                                caption="Байрны зардал $"
                                alignment='center'
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "housingcost")} defaultValue={item.data?.housingcost} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="travelday"
                                caption="Зам хоног /Хоногийн/ $"
                                format="fixedPoint"
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "travelday")} defaultValue={item.data?.travelday} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="visa"
                                caption="Дотоод унааны зардал $"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "visa")} defaultValue={item.data?.visa} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="gift"
                                caption="Бусад ₮"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "gift")} defaultValue={item.data?.gift} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="mnttotalcost"
                                caption="Дүн ₮"
                                alignment='center'
                                minWidth={200}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.mnttotalcost} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="output"
                                caption="Хүрэх үр дүн /outputs/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "output")} defaultValue={item.data?.output} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.output} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="outcome"
                                caption="Хүрэх үр дагавар /outcome/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "outcome")} defaultValue={item.data?.outcome} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.outcome} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="impact"
                                caption="Үр нөлөө /Impact/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "impact")} defaultValue={item.data?.impact} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.impact} </div>
                                        );
                                    }
                                }} />

                            <Column
                                dataField="sptopprname"
                                caption="Тэргүүлэх чиглэл"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "sptopprid", pNameValue, "sptopprname")}
                                                defaultValue={item.data?.sptopprid}
                                                data={splan.filter(x => x.typeid == 2)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.sptopprname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spgoalname"
                                caption="Зорилт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spgoalid", pNameValue, "spgoalname")}
                                                defaultValue={item.data?.spgoalid}
                                                data={splan.filter(x => x.typeid == 3 && x.parentid == item.data?.sptopprid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spgoalname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spstrname"
                                caption="Стратеги"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spstrid", pNameValue, "spstrname")}
                                                defaultValue={item.data?.spstrid}
                                                data={splan.filter(x => x.typeid == 4 && x.parentid == item.data?.spgoalid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spstrname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spindname"
                                caption="Хэмжих үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spindid", pNameValue, "spindname")}
                                                defaultValue={item.data?.spindid}
                                                data={splan.filter(x => x.typeid == 5 && x.parentid == item.data?.spstrid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spindname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="kpiname"
                                caption="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "kpiid", pNameValue, "kpiname")} defaultValue={item.data?.kpiid} data={kpis} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.kpiname} </div>
                                        );
                                    }
                                }} />
                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="mnttotalcost"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BTripForeignPanel;