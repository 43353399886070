import React, { useContext, useState, useEffect } from "react";
import { ButtonDefault, FieldDate, FieldDropdown, PanelBase, LoadSpin, FieldNumberFormat, FieldText } from "dw-components";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem} from "devextreme-react/data-grid";
import { month2digits } from "../../../../Utils/SharedFunc";
import moment from "moment";

const OperationWaterPanel = (props) => {

  const { userInfo } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);
  const [userCode, setUserCode] = useState([]);
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState(false);
  const [usercodeid, setusercodeid] = useState(props?.usercodeid);
  const [selecteddate, setselecteddate] = useState(!props?.date ? new Date() : props?.date);
  const [selectedcostdate, setselectedcostdate] = useState(+(props?.costdate?.toString().substring(5, 7)));
  const [prevData, setPrevData] = useState([]);
  const currentyear = new Date(selecteddate).getFullYear().toString();
  const dCostDate = [
    {key:1, text: currentyear + ".01"},
    {key:2, text: currentyear + ".02"},
    {key:3, text: currentyear + ".03"},
    {key:4, text: currentyear + ".04"},
    {key:5, text: currentyear + ".05"},
    {key:6, text: currentyear + ".06"},
    {key:7, text: currentyear + ".07"},
    {key:8, text: currentyear + ".08"},
    {key:9, text: currentyear + ".09"},
    {key:10, text: currentyear + ".10"},
    {key:11, text: currentyear + ".11"},
    {key:12, text: currentyear + ".12"}
  ];
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    let tData = [];
    let tUserCode = [];
    setLoading(true);
    await APIGraphQL.post("", {
        query: `{
            sharedQuery {
              usercodelist {
                key: iD
                text: cODE
              }
            }
          }
          `
    }).then(response => {
        if (response?.data?.data?.sharedQuery?.usercodelist?.length > 0) {
            tUserCode = response?.data?.data?.sharedQuery?.usercodelist;
        }
    });

    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String){
          sharedQuery {
            watercostbyusercode(usercodeid: $usercodeid, costdate: $costdate) {
              iD
              cOSTDATE
              uSERCODEID
              uSERCODE
              fMETERID
              wMETERNO
              bUILDINGID
              bUILDINGNAME
              dATE
              fRESHQTY
              wASTEQTY
              fRESHWATER
              wASTEWATER
              fRESHUSE
              wASTEUSE
              fRESHAMOUNT
              wASTEAMOUNT
              oTHERCOST
              vAT
              aDDCOST
              tOTALAMOUNT
              fMETERID
              fMETERNO
            }
          }
        }
        `,
        variables: {
            "usercodeid": parseInt(props?.usercodeid),
            "costdate": props?.costdate?.toString()
        }
  }).then(response => {
      if (response?.data?.data?.sharedQuery?.watercostbyusercode?.length > 0) {
          tData = response?.data?.data?.sharedQuery?.watercostbyusercode;
      }
  });    

  for (let i = 0; i < tData?.length; i++) {
      tData[i].arrayIndex = i;
  }
  setPreviousData(props?.costdate);
  setLoading(false);
  setData(tData);
  setUserCode(tUserCode);
  setDataEdit(tData);
}

useEffect(() => {
  getData();
}, [props?.usercodeid, props?.costdate, props?.date]);

  const fieldOnChanged = async(key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }

        if (field == "uSERCODE") {
          setusercodeid(key);
          await APIGraphQL.post("", {
            query: `query ($usercodeid: Int){
                sharedQuery {
                  meterbyusercodeid(usercodeid: $usercodeid, typeid: 2) {
                    fMETERID: iD
                    fMETERNO: mETERNO
                    wMETERID: iD
                    wMETERNO
                    bUILDINGID
                    bUILDINGNAME
                    uSERCODEID
                    uSERCODE
                    wATERBASECOST
                    fRESHWATER
                    wASTEWATER
                  }
                }
              }
              `,
              variables: {
                  "usercodeid": key
              }
        }).then(response => {
            if (response?.data?.data?.sharedQuery?.meterbyusercodeid?.length > 0) {
                tData = response?.data?.data?.sharedQuery?.meterbyusercodeid;
            }
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setData(tData);
        setDataEdit(tData);
      } else if (field == "dATE") {
          setselecteddate(key);
      } else if (field == "cOSTDATE") {
          setPreviousData(text);
      }
    }
  }

  const gridfieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
      let tDataEdit = [...dataEdit];
      tDataEdit[pIndex][pField] = pValue;
      if (pFieldName) {
          tDataEdit[pIndex][pFieldName] = pNameValue;
      }
      if (pField == "fRESHQTY") {
          if (prevData?.length > 0) {
            let tIndex = prevData?.map(r => r.fMETERID).indexOf(tDataEdit[pIndex]["fMETERID"]);
            let fprevQty = 0;
            if(tIndex >= 0)
              fprevQty = prevData[tIndex].fRESHQTY;
            tDataEdit[pIndex]["fRESHUSE"] =  (pValue - fprevQty);
            tDataEdit[pIndex]["fRESHAMOUNT"] = ((tDataEdit[pIndex]["fRESHUSE"] || 0) * (tDataEdit[pIndex]["fRESHWATER"] || 0));
            tDataEdit[pIndex]["tOTALAMOUNT"] = ((tDataEdit[pIndex]["fRESHAMOUNT"] || 0) + (tDataEdit[pIndex]["wASTEAMOUNT"] || 0) + pValue + (tDataEdit[pIndex]["wATERBASECOST"] || 0)) * 1.1 + (tDataEdit[pIndex]["aDDCOST"] || 0);
          }
      } else if (pField == "wASTEQTY") {
          if (prevData?.length > 0) {
            let tIndex = prevData?.map(r => r.wMETERID).indexOf(tDataEdit[pIndex]["wMETERID"]);
            let wprevQty = 0;
            if(tIndex >= 0)
              wprevQty = prevData[tIndex].wASTEQTY;
            tDataEdit[pIndex]["wASTEUSE"] =  (pValue - wprevQty);
            tDataEdit[pIndex]["wASTEAMOUNT"] = ((tDataEdit[pIndex]["wASTEUSE"] || 0) * (tDataEdit[pIndex]["wASTEWATER"] || 0));
            tDataEdit[pIndex]["tOTALAMOUNT"] = ((tDataEdit[pIndex]["fRESHAMOUNT"] || 0) + (tDataEdit[pIndex]["wASTEAMOUNT"] || 0) + pValue + (tDataEdit[pIndex]["wATERBASECOST"] || 0)) * 1.1 + tDataEdit[pIndex]["aDDCOST"];
        }
      } else if (pField == "oTHERCOST") {
            tDataEdit[pIndex]["tOTALAMOUNT"] = ((tDataEdit[pIndex]["fRESHAMOUNT"] || 0) + (tDataEdit[pIndex]["wASTEAMOUNT"] || 0) + pValue + (tDataEdit[pIndex]["wATERBASECOST"] || 0)) * 1.1 + (tDataEdit[pIndex]["aDDCOST"] || 0);
      } else if (pField == "aDDCOST") {
            tDataEdit[pIndex]["tOTALAMOUNT"] = ((tDataEdit[pIndex]["fRESHAMOUNT"] || 0) + (tDataEdit[pIndex]["wASTEAMOUNT"] || 0) + (tDataEdit[pIndex]["oTHERCOST"] || 0) + (tDataEdit[pIndex]["wATERBASECOST"] || 0)) * 1.1 + pValue;
      }
      tDataEdit[pIndex].edit = true;
      setDataEdit(tDataEdit);
  }

  const setPreviousData = async(text) => {
    setselectedcostdate(+(text?.toString().substring(5, 7)));
    let currentmonth = text + ".01";
    let currentDate = moment(new Date(currentmonth));
    let currentdate = new Date(currentmonth);
    let lastmonth = month2digits(currentdate.getMonth());
    var costdate = currentdate.getFullYear() + "." + lastmonth;
    let tPrevData = [];
    let prevs = 0;
    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String){
          sharedQuery {
            watercostbydate(usercodeid: $usercodeid, costdate: $costdate) {
              fRESHQTY
              wASTEQTY
              dATE
              fMETERID
              wMETERID
            }
          }
        }
        `,
        variables: {
          "usercodeid": usercodeid,
          "costdate": costdate?.toString()
        }
    }).then(response => {
        if (response?.data?.data?.sharedQuery?.watercostbydate?.length > 0) {
          tPrevData = response?.data?.data?.sharedQuery?.watercostbydate;
          let prevDate = moment(new Date(tPrevData[0].dATE));
          prevs = currentDate.diff(prevDate, 'days');
        }
    });

    setPrevData(tPrevData);
  }

  const onSave = async () => {
    let tDataEdit = dataEdit?.filter(r => r.edit);
    setLoadingSave(true);
    if (tDataEdit?.length > 0) {
        for (let i = 0; i < tDataEdit.length; i++) {
            let tR = { ...tDataEdit[i] };
            if (!isNaN(parseFloat(tR.tOTALMOUNT))) {
                tR.vAT = tR.tOTALMOUNT / 10;
            }
            tR.dATE = selecteddate;
            tR.cOSTDATE = "";
            let tIndex =  dCostDate?.map(r => r.key).indexOf(selectedcostdate);
            if(tIndex >= 0)
              tR.cOSTDATE = dCostDate[tIndex].text;
            delete tR.arrayIndex;
            delete tR.edit;
            delete tR.wATERBASECOST;
            if (!tR.iD) {
                tR.iD = 0;
                tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                tR.cREATEDBY = userInfo?.username;
            }
            tDataEdit[i] = tR;
        }

        await APIGraphQL.post("", {
            query: `mutation ($data: [WaterCostInputType]) {
                sharedMutation {
                    addWaterCost(data: $data) {
                        iD
                    }
                }
            }`,
            variables: {
                "data": tDataEdit
            }
        }).then(response => {
            if (response?.data?.data?.sharedMutation?.addWaterCost?.length > 0) {
                message.success("Амжилттай");
            }
        }).catch(() => {
            setLoadingSave(false);
        });
    }

    setLoadingSave(false);
    await props?.afterSave();
    props?.onDismiss();
  }

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    setCheckRule(false);
    setData({});
  }

  const onDelete = async () => {
      let tSuccess = false;
      await APIGraphQL.post("", {
          query: `mutation deleteWaterCost($usercodeid: Int, $costdate: String) {
                  sharedMutation {
                    deleteWaterCost(usercodeid: $usercodeid, costdate: $costdate)
                  }
                }`,
            variables: {
              "usercodeid": parseInt(props?.usercodeid),
              "costdate": props?.costdate.toString()
          },
      }).then((response) => {
          if (response?.data?.data?.sharedMutation?.deleteWaterCost == 1) {
              tSuccess = true;
              if (props.afterSave) {
                  props.afterSave();
              }
          }
          if (tSuccess) {
              message.success("Амжилттай");
              onDismiss();
          }
      });
  };

  return (
    <PanelBase
      headerText="Цэвэр, бохир усны мэдээлэл оруулах"
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      warning={warning}
      PanelType="large"
      delete={props?.usercodeid > 0 ? true : false}
      onDelete={onDelete}
      buttons={
        loading ? <LoadSpin /> :
          <ButtonDefault
            text="Хадгалах"
            onClick={onSave}
          />
      }
    >
      <div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Хэрэглэгчийн код" keyfield="uSERCODE" defaultValue={props?.usercodeid} checkreq={checkRule} data={userCode} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDate required onChanged={fieldOnChanged} title="Заалт авсан огноо" keyfield="dATE" checkreq={checkRule} defaultValue={selecteddate} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Заалт оруулах сар" keyfield="cOSTDATE" defaultValue={selectedcostdate} checkreq={checkRule} data={dCostDate} />
        </div>
        {loading ? (<LoadSpin />) : (
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                        <Column
                            width={150}
                            dataField="bUILDINGNAME"
                            caption="Обьект"
                            alignment='center'
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.bUILDINGNAME} />
                                );
                            }} />
                        <Column caption="Хэмжүүрийн дугаар" width={100} alignment='center'>
                          <Column 
                            caption="Цэвэр" 
                            alignment='center'
                            dataField="fMETERNO"
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.fMETERNO} />
                                );
                            }} />
                            <Column 
                              caption="Бохир" 
                              alignment='center'
                              dataField="wMETERNO"
                              cellRender={(item) => {
                                  return (
                                      <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.wMETERNO} />
                                  );
                              }} />
                        </Column>
                        <Column caption="Заалт" width={100} alignment='center'>
                          <Column
                              dataField="fRESHQTY"
                              caption="Цэвэр"
                              alignment='center'
                              format="fixedPoint"
                              cellRender={(item) => {
                                return (
                                    <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "fRESHQTY")} defaultValue={item.data?.fRESHQTY} />
                                );
                            }} />
                          <Column
                              dataField="wASTEQTY"
                              caption="Бохир"
                              alignment='center'
                              format="fixedPoint"
                              cellRender={(item) => {
                                return (
                                    <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "wASTEQTY")} defaultValue={item.data?.wASTEQTY} />
                                );
                            }} />
                        </Column>
                        <Column caption="Зөрүү" width={100} alignment='center'>
                          <Column
                              dataField="fRESHUSE"
                              caption="Цэвэр"
                              alignment='center'
                              format="fixedPoint"
                              cellRender={(item) => {
                                return (
                                  <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.fRESHUSE} />
                                );
                            }} />
                            <Column
                                dataField="wASTEUSE"
                                caption="Бохир"
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                  return (
                                    <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.wASTEUSE} />
                                  );
                              }} />
                        </Column>
                        <Column caption="Бусад зардал" width={100} alignment='center'>
                          <Column
                              dataField="wATERBASECOST"
                              caption="Суурь хураамж"
                              alignment='center'
                              width={100}
                              format="fixedPoint"
                              cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "wATERBASECOST")} defaultValue={item.data?.wATERBASECOST} />
                                );
                            }} />
                            <Column
                                dataField="aDDCOST"
                                caption="Нэмэлт"
                                alignment='center'
                                format="fixedPoint"
                                width={100}
                                cellRender={(item) => {
                                  return (
                                      <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "aDDCOST")} defaultValue={item.data?.aDDCOST} />
                                  );
                              }} />
                              <Column
                                  dataField="oTHERCOST"
                                  caption="Бусад"
                                  alignment='center'
                                  format="fixedPoint"
                                  width={100}
                                  cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "oTHERCOST")} defaultValue={item.data?.oTHERCOST} />
                                    );
                                }} />
                        </Column>
                        <Column
                            dataField="fRESHAMOUNT"
                            caption="Цэвэр"
                            width={150}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.fRESHAMOUNT} />
                                );
                            }} />
                        <Column
                            dataField="wASTEAMOUNT"
                            caption="Бохир"
                            width={150}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.wASTEAMOUNT} />
                                );
                            }} />
                        <Column
                            dataField="tOTALAMOUNT"
                            caption="НӨАТ-тай дүн"
                            minwidth={150}
                            alignment='center'
                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.tOTALAMOUNT} />
                                );
                            }} />
                        <Summary>
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="tOTALAMOUNT"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGrid>
                </div>
            )}
      </div>
    </PanelBase>
  );
}

export default OperationWaterPanel;
