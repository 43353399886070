import React, { useState, useEffect } from "react";
import { PanelBase, ButtonDefault, FieldNumberFormat, FieldDropdown, LoadSpin } from "dw-components";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { Col, message, Row } from "antd";
import { Icon } from '@fluentui/react/lib/Icon';
import { BudgetYear } from "../../../../Const/BudgetYear";

const BonusConfigPanel = (props) => {

  const [data, setData] = useState([]);
  const [dataDtl, setDataDtl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [checkRule, setCheckRule] = useState(false);
  const [dECat, setDECat] = useState([]);
  const [dCalcEC, setDCalcEC] = useState([]);

  const getData = async () => {
    setLoading(true);

    let tData = [];
    let tDataDtl = [];
    let tDECat = [];
    let tDCalcEC = [];

    await APIGraphQL.post("", {
      query: `query ($id: Int, $bonusid: Int) {
        sharedQuery {
          bonusById(id: $id) {
            iD
            yEAR
            pERCENT
            eCONOMYCATEGORYID
            eCONOMYCATEGORYNAME
          }
          ecoCatByCalcType(calctypeid: 88) {
            key: iD
            text: nAME
          }
          childEconomyCat {
            key: iD
            text: nAME
          }
          bonusCalcEC(bonusid: $bonusid) {
            iD
            bONUSID
            cALCECID
            cALCECNAME
          }
        }
      }`,
      variables: {
        "id": props?.id ? parseInt(props?.id) : -1,
        "bonusid": props?.id ? parseInt(props?.id) : -1
      }
    }).then(response => {
      let tSharedQuery = response?.data?.data?.sharedQuery;
      if (tSharedQuery?.ecoCatByCalcType?.length > 0) {
        tDECat = tSharedQuery?.ecoCatByCalcType;
      }
      if (tSharedQuery?.childEconomyCat?.length > 0) {
        tDCalcEC = tSharedQuery?.childEconomyCat;
      }
      if (tSharedQuery?.bonusById) {
        tData = tSharedQuery?.bonusById;
      }
      if (tSharedQuery?.bonusCalcEC) {
        tDataDtl = tSharedQuery?.bonusCalcEC;
      }
    }).catch(() => {
      setLoading(false);
    });

    for (let i = 0; i < tDataDtl?.length; i++) {
      tDataDtl[i].arrayIndex = i;
    }

    setData(tData);
    setDECat(tDECat);
    setDCalcEC(tDCalcEC);
    setDataDtl(tDataDtl);
    setCheckRule(false);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const onDismiss = () => {
    if (props.onDismiss) {
      setData({});
      setDataDtl([]);
      props.onDismiss();
    }
  }

  const onSave = async () => {

    let tData = { ...data };
    let tDataDtl = [...dataDtl];
    let vRule = true;

    setCheckRule(true);
    if (!tData?.yEAR
      || !tData?.pERCENT
      || !tData?.eCONOMYCATEGORYID) {
      vRule = false;
    }

    if (tDataDtl?.length <= 0) {
      vRule = false;
      message.warning("Тооцох эдийн засгийн ангилал хоосон байна.");
    }

    if (vRule) {

      let vId;

      setLoadingSave(true);

      await APIGraphQL.post("", {
        query: `mutation ($data: BonusInputType) {
          sharedMutation {
            addBonus(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        if (response?.data?.data?.sharedMutation?.addBonus?.iD) {
          vId = response?.data?.data?.sharedMutation?.addBonus?.iD;
        }
      }).catch(() => {
        setLoadingSave(false);
      });

      if (vId) {

        for (let i = 0; i < tDataDtl.length; i++) {
          let tR = { ...tDataDtl[i] };
          tR.bONUSID = parseInt(vId);
          delete tR.arrayIndex;
          tDataDtl[i] = tR;
        }

        let vSuccess = false;
        await APIGraphQL.post("", {
          query: `mutation ($data: [BonusCalcECInputType]) {
            sharedMutation {
              addBonusCalcEC(data: $data) {
                iD
              }
            }
          }`,
          variables: {
            "data": tDataDtl
          }
        }).then(response => {
          if (response?.data?.data?.sharedMutation?.addBonusCalcEC?.length > 0) {
            vSuccess = true;
          }
        }).catch(() => {
          setLoadingSave(false);
        });

        if (vSuccess) {
          message.success("Амжилттай");
          if (props?.afterSave) {
            props?.afterSave();
          }
        }
      }

      setLoadingSave(false);
    }
  }

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const fieldOnChangedDtl = async (pIndex, key, text, field, fieldName) => {
    let tDataDtl = [...dataDtl];
    if (pIndex == -1) {

      let tDataRow = {
        iD: 0,
        [field]: key,
        arrayIndex: tDataDtl?.length
      }

      if (fieldName) {
        tDataRow = {
          ...tDataRow,
          [fieldName]: text
        }
      }
      tDataDtl.push({ ...tDataRow });

    } else {

      tDataDtl[pIndex] = {
        ...tDataDtl[pIndex],
        [field]: key
      };

      if (fieldName) {
        tDataDtl[pIndex] = {
          ...tDataDtl[pIndex],
          [fieldName]: text
        };
      }
    }

    setDataDtl(tDataDtl);
  }

  const onDeleteDtl = async (pIndex, pId) => {
    if (pId > 0) {
      await APIGraphQL.post("", {
        query: `mutation ($id: Int) {
          sharedMutation {
            deleteBonusCalcEC(id: $id)
          }
        }`,
        variables: {
          "id": parseInt(pId)
        }
      }).then(response => { });
    }

    let tDataDtl = [...dataDtl];
    tDataDtl.splice(pIndex, 1);
    setDataDtl(tDataDtl);
  }

  const onDelete = async () => {
    if (props?.id > 0) {
      await APIGraphQL.post("", {
        query: `mutation ($id: Int) {
          sharedMutation {
            deleteBonus(id: $id)
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        message.success("Амжилттай");
        if (props?.afterSave) {
          props?.afterSave();
        }
      });
    }
  }

  return (
    <PanelBase
      headerText="Урамшуулал тохиргоо"
      isOpen={props.isOpen}
      PanelType="medium"
      delete={props?.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
    >
      <div style={{ marginTop: 24 }}>
        <div>
          <FieldDropdown loading={loading} required title="Жил" onChanged={fieldOnChanged} keyfield="yEAR" defaultValue={data.yEAR} data={BudgetYear} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required title="Хувь" onChanged={fieldOnChanged} keyfield="pERCENT" defaultValue={data.pERCENT} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown loading={loading} required title="Эдийн засгийн ангилал" onChanged={fieldOnChanged} keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" defaultValue={data.eCONOMYCATEGORYID} data={dECat} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13, fontWeight: 500, fontSize: 14 }}>
          Тооцох эдийн засгийн ангилал
        </div>
        {dataDtl?.map((r, index) => {
          return (
            <Row key={index} gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={1}>
                <div onClick={() => onDeleteDtl(r?.arrayIndex, r.iD)}>
                  <Icon style={{ marginTop: 13 }} iconName="Delete" />
                </div>
              </Col>
              <Col span={23}>
                <FieldDropdown onChanged={(key, text, field, fieldName) => fieldOnChangedDtl(r?.arrayIndex, key, text, field, fieldName)} keyfield="cALCECID" keyfieldname="cALCECNAME" defaultValue={r.cALCECID} data={dCalcEC} />
              </Col>
            </Row>
          )
        })}

        <Row gutter={[16, 16]} key={dataDtl?.length} style={{ marginTop: 8 }}>
          <Col span={24}>
            <FieldDropdown placeholder="..." loading={loading} onChanged={(key, text, field, fieldName) => fieldOnChangedDtl(-1, key, text, field, fieldName)} keyfield="cALCECID" keyfieldname="cALCECNAME" defaultValue={null} data={dCalcEC} />
          </Col>
        </Row>
      </div>
    </PanelBase >
  );
};

export default BonusConfigPanel;