import React, { useState, useEffect } from 'react';
import IndicatorTitle from '../../../Components/IndicatorTitle/IndicatorTitle';
import { API } from '../../../API/API';
import FileDownload from 'js-file-download';
import { Space, Popover, message, Button, DatePicker } from 'antd';
import { FieldDropdown } from "dw-components";
import moment from 'moment';

import ButtonReport from '../../../Components/ButtonReport/ButtonReport';

const Reports = (props) => {

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [filterValue, setFilterValue] = useState(0);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [newData, setNewData] = useState([]);
    const [nfilterValue, setNFilterValue] = useState(0);

    const getData = async () => {
        setLoading(true);
        let tData = {};
        await API.get("/api/budget/getSchoolBranchList").then(response => {
            if (response?.data?.retType == 0) {
                tData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onButtonClick = (buttonId) => {
        setShowDatePicker(true);
        setActiveButton(buttonId);
    };

    const fieldOnChanged = async (key, text, field, fieldName) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;

            if (fieldName) {
                tData[fieldName] = text;
            }

            if (field == "pSCHOOLID" && tData.pSCHOOLID == 107) {
                setLoading(true);
                let tNData = {};
                await API.get("/api/budget/getSchoolBranchListChild?schoolid=107").then(response => {
                    if (response?.data?.retType == 0) {
                        tNData = response?.data?.retData;
                    }
                }).catch(() => {
                    setLoading(false);
                });
                setLoading(false);
                setNewData(tNData);
            }
        };
    };

    const onDateChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    };

    const exportWithDates = async (pData) => {
        if (pData?.id == "5") {
            if (startDate && endDate) {
                setLoadingDownload(true);

                await API.get("/api/budget/budgetPerformanceReportTemp?schoolid=" + filterValue + "&begindate=" + moment(startDate).format("YYYY.MM.DD") + "&enddate=" + moment(endDate).format("YYYY.MM.DD") + "&filetype=" + pData?.filetype, {
                    'responseType': 'blob'
                }).then(response => {
                    if (response?.data?.size == 0) {
                        message.warning("Маягт хоосон байна.");
                    } else {
                        FileDownload(response.data, "Гүйцэтгэл_ " + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                    }
                }).catch(response => {
                    setLoadingDownload(false);
                });
                setLoadingDownload(false);
                setShowDatePicker(false);
            }
        } else if (pData?.id == "6") {
            if (startDate && endDate) {
                setLoadingDownload(true);

                await API.get("/api/budget/budgetPerformanceReportTemp?schoolid=0&begindate=" + moment(startDate).format("YYYY.MM.DD") + "&enddate=" + moment(endDate).format("YYYY.MM.DD") + "&filetype=" + pData?.filetype, {
                    'responseType': 'blob'
                }).then(response => {
                    if (response?.data?.size == 0) {
                        message.warning("Маягт хоосон байна.");
                    } else {
                        FileDownload(response.data, "Гүйцэтгэл_ " + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                    }
                }).catch(response => {
                    setLoadingDownload(false);
                });
                setLoadingDownload(false);
                setShowDatePicker(false);
            }
        }
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        if (pData?.id == "1" || pData?.id == "2") {
            await API.get("/api/budget/budgetReportTemp?budgetid=" + props?.id + "&typeid=" + pData?.id + "&schoolid=" + filterValue + "&filetype=" + pData?.filetype, {
                'responseType': 'blob'
            }).then(response => {
                FileDownload(response.data, "BudgetReport" + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
            }).catch(response => {
                setLoadingDownload(false);
            });
        } else if (pData?.id == "3") {
            await API.get("/api/budget/budgetInvestmentReport?budgetid=" + props?.id + "&filetype=" + pData?.filetype, {
                'responseType': 'blob'
            }).then(response => {
                if (response?.data?.size == 0) {
                    message.warning("Маягт хоосон байна.");
                } else {
                    FileDownload(response.data, "ТӨБЗГ_ " + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                }
            }).catch(response => {
                setLoadingDownload(false);
            });
        } else if (pData?.id == "4") {
            await API.get("/api/budget/budgetInvestmentUZReport?budgetid=" + props?.id + "&filetype=" + pData?.filetype, {
                'responseType': 'blob'
            }).then(response => {
                if (response?.data?.size == 0) {
                    message.warning("Маягт хоосон байна.");
                } else {
                    FileDownload(response.data, "УЗ Тайлан_ " + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                }
            }).catch(response => {
                setLoadingDownload(false);
            });
        }
        setLoadingDownload(false);
    }


    return (
        <div>
            <div style={{ marginTop: 24 }}>
                <IndicatorTitle value="Монгол улсын их сургууль" />
                <Space>
                    <ButtonReport id={1} title="Орлогын тайлан" onClick={onExportDoc} />
                    <ButtonReport id={2} title="Зарлагын тайлан" onClick={onExportDoc} />
                    <ButtonReport id={3} title="ТӨБЗГ" onClick={onExportDoc} />
                    <ButtonReport id={4} title="УЗ Тайлан" onClick={onExportDoc} />
                    <div>
                        <Button style={{ backgroundColor: '#f7f7f7', border: 'none', color: "black important!" }} onClick={() => onButtonClick(6)}>Төсвийн гүйцэтгэлийн тайлан</Button>
                        {showDatePicker && activeButton === 6 && (
                            <Popover
                                content={
                                    <DatePicker.RangePicker
                                        format="YYYY.MM.DD"
                                        onChange={onDateChange}
                                    />
                                }
                                title="Эхлэх болон дуусах огноо сонгоно уу!"
                                trigger="click"
                                visible={showDatePicker}
                                onVisibleChange={(visible) => {
                                    if (!visible) {
                                        setShowDatePicker(false);
                                        setActiveButton(null);
                                    }
                                }}
                            >
                                <ButtonReport id={6} onClick={exportWithDates}>Export</ButtonReport>
                            </Popover>
                        )}
                    </div>
                </Space>
            </div>
            <div style={{ marginTop: 24 }}>
                <IndicatorTitle value="Салбар сургуулиуд" />
                <Space>
                <div style={{ width: 250, marginBottom: 16 }}>
                    <FieldDropdown
                        onChanged={(pValue, pNameValue) => {
                            setFilterValue(pValue);
                            setNFilterValue(pValue);
                            fieldOnChanged(pValue, pNameValue, "pSCHOOLID", "pSCHOOLNAME");
                        }}
                        data={data}
                    />
            </div>
                {nfilterValue == 107 ?
                    <div style={{ width: 250, marginBottom: 16 }}>
                        <FieldDropdown onChanged={(pValue) => setFilterValue(pValue)} data={newData} />
                    </div>
                    : null}
                     </Space>
                     <div>
                <Space >
                    <ButtonReport id={1} title="Орлогын тайлан" onClick={onExportDoc} />
                    <ButtonReport id={2} title="Зарлагын тайлан" onClick={onExportDoc} />
                    {/* <ButtonReport id={5} title="Төсвийн гүйцэтгэлийн тайлан" onClick={onExportDoc} /> */}
                    <div>
                        <Button style={{ backgroundColor: '#f7f7f7', border: 'none', color: "black important!" }} onClick={() => onButtonClick(5)}>Төсвийн гүйцэтгэлийн тайлан</Button>
                        {showDatePicker && activeButton === 5 && (
                            <Popover
                                content={
                                    <DatePicker.RangePicker
                                        format="YYYY.MM.DD"
                                        onChange={onDateChange}
                                    />
                                }
                                title="Эхлэх болон дуусах огноо сонгоно уу!"
                                trigger="click"
                                visible={showDatePicker}
                                onVisibleChange={(visible) => {
                                    if (!visible) {
                                        setShowDatePicker(false);
                                        setActiveButton(null);
                                    }
                                }}
                            >
                                <ButtonReport id={5} onClick={exportWithDates}>Export</ButtonReport>
                            </Popover>
                        )}
                    </div>
                </Space>
                </div>
            </div>
        </div>
    );
}

export default Reports;