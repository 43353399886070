import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefault, PageHeader } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import VehiclePanel from "./Panel/VehiclePanel";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import { Row, Col, Space } from "antd";
import moment from 'moment';
import * as xlsx from "xlsx";

const Vehicle = () => {

  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const [vehicleId, setVehicleId] = useState();
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([
  {
    name: "№",
    maxWidth: 25,
    minWidth: 25,
    onRender: (row, index) => {
      return <div> {index + 1 + "."} </div>
    }
  },
  {
    name: "Тээврийн хэрэгслийн ангилал",
    fieldName: "cATEGORYNAME",
    minColSize: "mid",
  }, {
    name: "Төрөл",
    fieldName: "tYPENAME",
    colSize: "mid",
  }, {
    name: "Марк",
    fieldName: "mARK",
    colSize: "mid",
  }, {
    name: "Улсын дугаар",
    fieldName: "nUMBER",
    colSize: "mid",
  }, {
    name: "Түлш",
    fieldName: "fUELTYPENAME",
    colSize: "mid",
  }, {
    name: "Хөдөлгүүрийн багтаамж",
    fieldName: "eNGINENUMBER",
    colSize: "large"
  }, {
    name: "Цилиндр",
    fieldName: "cYLINDER",
    colSize: "mid",
  }, {
    name: "Даац /тн/",
    fieldName: "cARRYINGCAPACITY",
    colSize: "mid",
  }, {
    name: "Суудлын тоо",
    fieldName: "sEAT",
  }, {
    fieldName: "action",
    onRender: (item) => {
      return (
        <div>
          <IconButton
            iconProps={{ iconName: "Edit" }}
            title="Edit"
            ariaLabel="Edit"
            onClick={() => onEditClick(item.iD)}
          />
        </div>
      );
    }
  }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getVehicleList {
            iD
            cATEGORYID
            cATEGORYNAME
            tYPEID
            tYPENAME
            mARK
            nUMBER
            fUELTYPEID
            fUELTYPENAME
            eNGINENUMBER
            cYLINDER
            cARRYINGCAPACITY
            sEAT
          }
        }
      }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getVehicleList;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setVehicleId();
    setShowNew(true);
  };

  const onDismiss = () => {
    setVehicleId();;
    setShowNew(false);
  };

  const onEditClick = (id) => {
    setVehicleId(id);
    setShowNew(true);
  }

  const downloadExcel = async () => {
    let tData = [];
    data?.map(r => {
        tData.push({
            "Тээврийн хэрэгсэлийн ангилал": r?.cATEGORYNAME,
            "Төрөл": r?.tYPENAME,
            "Марк": r?.mARK,
            "Улсын дугаар": r?.nUMBER,
            "Түлш": r?.fUELTYPENAME,
            "Хөдөлгүүрийн багтаамж": r?.eNGINENUMBER,
            "Цилиндр": r?.cYLINDER,
            "Даац": r?.cARRYINGCAPACITY,
            "Суудлын тоо": r?.sEAT
        });
    });
    const ws = xlsx.utils.json_to_sheet(tData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Тээврийн хэрэгсэл");
    xlsx.writeFile(wb,"Тээврийн хэрэгсэл" + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx");
}
  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          title="Нийт"
          buttonTitle="Тээврийн хэрэгсэл"
          onButtonClick={onNew}
          data={data}
          dataCount={data.length}
          columns={columns}
          addRightButton={(
            <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                <Space size={16} >
                    <div onClick={downloadExcel}>
                        <ExcelIcon />
                    </div>
                </Space>
            </div>
        )}
        />
        <div style={{ margin: 16 }}>
          <GridDefault columns={columns} data={data} loading={loading}></GridDefault>
          <VehiclePanel
            id={vehicleId}
            isOpen={showNew}
            onDismiss={onDismiss}
            afterSave={getData}
          ></VehiclePanel>
        </div>
      </Layout>
    </Layout>
  );
};

export default Vehicle;
