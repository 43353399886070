import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldCheck, LoadSpin } from "dw-components";
import { message } from "antd";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import POSITIONCATEGORY from "../../../../Const/POSITIONCATEGORY";
import { UserContext } from "../../../../Context/UserContext";

const PositionPanel = (props) => {
  const [data, setData] = useState({});

  const { userInfo } = useContext(UserContext);
  const [checkRule, setCheckRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {

    let tData = {};
    if (props?.id) {
      setLoading(true);
      await APIGraphQL.post("", {
        query: `query($id: Int) {
          sharedQuery{
            getPosition(id: $id){
              iD
              cODE
              nAME
              cATEGORYID
              cATEGORYNAME
              iSACTIVE  
              iSUSEUSER  
              cREATED
              cREATEDBY
            }
          }
        }`,
        variables: {
          id: parseInt(props?.id),
        },
      }).then((response) => {
          let tResp = response?.data?.data?.sharedQuery?.getPosition;
          if (tResp?.iD) {
            tData = tResp;
          }
        })
      setLoading(false);
    }
    setData(tData);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    let isValid = true;
    setCheckRule(true);

    let tData = { ...data }

    if (
      !tData?.cODE ||
      !tData?.nAME ||
      !tData?.cATEGORYNAME
    ){isValid = false};
      
    if (isValid) {
      let tSuccess = false;
      tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
      tData.iSUSEUSER = tData?.iSUSEUSER == true ? 1 : 0;
      if (!props.id) {
        tData.iD = 0;
        tData.iSACTIVE = 1;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      await APIGraphQL.post("", {
        query: `mutation($data: PositionInputType){
            sharedMutation{
              addPosition(data: $data){
                iD
              }
            }
          }`,
        variables: {
          data: tData,
        },
      }).then((response) => {
          setLoadingSave(false);
          if (response?.data?.data?.sharedMutation?.addPosition?.iD) {
            tSuccess = true;
          }
        })
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation DeletePosition($id: Int) {
        sharedMutation {
          deletePosition(id: $id)
          }
        }`,
      variables: {
        id: parseInt(props.id),
      },
    }).then((response) => {
      if (response?.data?.data?.sharedMutation?.deletePosition == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
      }
    });
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  return (
    <PanelBase 
      headerText={props?.id ? "Засах" : "Нэмэх"} 
      isOpen={props.isOpen} 
      onDismiss={props.onDismiss} 
      PanelType="medium"
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      buttons={loadingSave ? <LoadSpin /> :
      <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} />
      }
    >
      <div>
        <div style={{ marginTop: 25 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Ажлын байрны код" keyfield="cODE" defaultValue={data.cODE} checkreq={checkRule} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldText loading={loading} required onChanged={fieldOnChanged} title="Ажлын байр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required title="Ангилал" keyfield="cATEGORYID" keyfieldname="cATEGORYNAME" onChanged={fieldOnChanged} checkreq={checkRule} defaultValue={data.cATEGORYID} data={POSITIONCATEGORY} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldCheck loading={loading} required onChanged={fieldOnChanged} title="Идэвхтэй эсэх" keyfield="iSACTIVE" defaultValue={data.iSACTIVE ==0 ? data.iSACTIVE : 1} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldCheck loading={loading} required onChanged={fieldOnChanged} title="Эрх тохируулах эсэх" keyfield="iSUSEUSER" defaultValue={data.iSUSEUSER} />
        </div>
      </div>
    </PanelBase>
  );
};

export default PositionPanel;
