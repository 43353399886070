import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridDefaultShimmer, ColEdit, PageHeader } from "dw-components";
import PurchInvNewPanel from "./Panels/PurchInvNewPanel";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { URLTYPE } from "../../../Const/PurchaseInvestment"

const PurchaseInvestmentList = () => {

    const navigate = useNavigate();

    const [budgetId, setBudgetId] = useState(0);
    const [showNew, setShowNew] = useState(false);
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columns] = useState([
        {
            name: "Төсөв",
            fieldName: "yEAR",
            minColSize: "mid",
            onRender: (record) => {
                return (
                    <div>
                        <ColEdit onClick={() => onDetails(record.iD)}>{record.yEAR}</ColEdit>
                    </div>
                );
            },
        },
        {
            name: "Нээх огноо",
            fieldName: "sTARTDATE",
            minColSize: "mid",
        },
        {
            name: "Хаах огноо",
            fieldName: "eNDDATE",
            minColSize: "mid",
        },
        {
            name: "Албан тушаалтны нэр",
            fieldName: "oWNERNAME",
            minColSize: "mid",
        },
        {
            name: "Төлөв",
            fieldName: "sTATUSNAME"
        }
    ]);

    const [filterValue, setFilterValue] = useState('open');
    const [filterData, setFilterData] = useState([{
        key: 'open',
        text: 'Нээлттэй'
    }, {
        key: 'closed',
        text: 'Хаалттай'
    }]);

    const getData = async () => {
        let tData = [];
        setLoading(true);
        await APIGraphQL.post("", {
            query: `query ($rowstate: String) {
                budgetQuery {
                  budgetPurchaseInvestments(rowstate: $rowstate) {
                    iD
                    yEAR
                    sTARTDATE
                    eNDDATE
                    oWNERNAME
                    sTATUSID
                    sTATUSNAME
                  }
                }
              }
              `,
            variables: {
                "rowstate": filterValue
            }
        }).then(response => {
            if (response?.data?.data?.budgetQuery?.budgetPurchaseInvestments?.length > 0) {
                tData = response?.data?.data?.budgetQuery?.budgetPurchaseInvestments;
            }
        });
        setLoading(false);
        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, [filterValue]);

    const onDetails = (pId) => {
        navigate("/purchaseinvestment/suggestion/" + URLTYPE.PURCHASEINVESTMENT + "/" + pId);
    };

    const onNew = () => {
        setBudgetId(-1);
        setShowNew(true);
    };

    const onDismiss = () => {
        setBudgetId(-1);
        setShowNew(false);
    };

    const onChangeFilter = (p_key, p_name) => {
        setFilterValue(p_key);
    }

    const onSearch = (p_data) => {
        setData(p_data);
    }

    return (
        <div
            style={{
                backgroundColor: "rgb(247, 247, 247)",
            }}
        >
            <PageHeader
                showLeftFilter
                changeFilter={onChangeFilter}
                filterValue={filterValue}
                filterData={filterData}
                buttonTitle="Шинэ ХӨ ХА үүсгэх"
                onButtonClick={onNew}
                data={oData}
                filteredData={data}
                columns={columns}
                dataCount={data?.length}
                onSearch={onSearch} />
            <div style={{ marginLeft: 16, marginRight: 16, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}> 
                <GridDefaultShimmer columns={columns} data={data} loading={loading} />
                <PurchInvNewPanel id={budgetId} isOpen={showNew} onDismiss={onDismiss} afterSave={getData}></PurchInvNewPanel>
            </div>
        </div>
    );
};

export default PurchaseInvestmentList;
