import React, { useEffect, useState, useReducer } from "react";
import { Layout, Table, message } from "antd";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { PageHeader, ButtonIcon } from "dw-components";
// import { flattenData, groupsDataGenerator } from "../../../Utils";
import Sidemenu from "../../Sidemenu/Sidemenu";
import IndicatorPanel from "./Panels/IndicatorPanel";
import GroupPanel from "./Panels/GroupPanel";
import { concatStyleSets, IconButton } from "@fluentui/react";
import { categoryTree, groupListData, getOthersData } from "../../../Utils";

import Can from "../../../Components/Can/Can";
import { BRANCHSCHOOLS, BRANCHSCHOOLKEYS, BRANCHSCHOOLENKEYS } from "../../../Const/Columns";

const OtherIndicators = (props) => {
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
    const IndicatorCollectionRef = collection(db, "otherIndicators");
    const IndicatorGroupsCollectionRef = collection(db, "otherGroups");

    const [showInticatorNew, setShowInticatorNew] = useState(false);
    const [showGroupNew, setShowGroupNew] = useState(false);
    const [selectedIndicatorId, setSelectedIndicatorId] = useState("");

    const [indicatorGroups, setIndicatorGroups] = useState();
    const [data, setData] = useState([]);

    let vCalculateMethodPanel = null;

    let branchCols = BRANCHSCHOOLS.map((item) => ({
        title: BRANCHSCHOOLKEYS[item],
        dataIndex: item,
        key: item,
        width: 120,
        align: "left",
        render: (text, record) => {
            if (record.indicatorId) {
                if (record.values[BRANCHSCHOOLENKEYS[item]]) {
                    let object = record.values[BRANCHSCHOOLENKEYS[item]];
                    for (const key in object) {
                        if (Object.hasOwnProperty.call(object, key)) {
                            return parseInt(object[key]);
                        }
                    }
                } else {
                    return 0;
                }
            }
        },
    }));

    const columns = [
        {
            title: "Үзүүлэлтийн нэр",
            dataIndex: "name",
            key: "name",
            width: 350,
            fixed: "left",
        },
        {
            title: "МУИС нэгтгэл",
            dataIndex: "value",
            key: "value",
            fixed: "left",
            width: 150,
            editable: true,
            render: (text, record) => {
                if (record.indicatorId) {
                    let total = 0;

                    if (record.values) {
                        for (const branchSchool in record.values) {
                            if (Object.hasOwnProperty.call(record.values, branchSchool)) {

                                let object = record.values[branchSchool];
                                for (const key in object) {
                                    if (Object.hasOwnProperty.call(object, key)) {
                                        total += parseInt(object[key]);
                                    }
                                }
                            }
                        }
                    }

                    // if (record.CED) total += parseInt(record.CED);
                    return total;
                }
            },
        },
        ...branchCols,
        {
            title: "",
            dataIndex: "",
            key: "x",
            fixed: "right",
            width: 100,
            render: (text, record) => {
                if (record.indicatorId) {
                    return (
                        <div>
                            <IconButton iconProps={{ iconName: "Edit" }} title="Edit" ariaLabel="Edit" onClick={() => onEditClick(record.indicatorId)} />
                            <IconButton iconProps={{ iconName: "Delete" }} title="Delete" ariaLabel="Delete" onClick={() => onDeleteClick(record.indicatorId)} />
                        </div>
                    );
                }
            },
        },
    ];

    // Get list data from firebase
    useEffect(async () => {
        const indicatorDocs = await getDocs(IndicatorCollectionRef);
        const IndicatorGroupDocs = await getDocs(IndicatorGroupsCollectionRef);

        setIndicatorGroups(
            IndicatorGroupDocs.docs.map((doc) => ({
                key: doc.data().groupName,
                text: doc.data().groupName,
            }))
        );

        const indicators = indicatorDocs.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
            ...doc.data(),
        }));

        const categories = await categoryTree(indicatorGroups, "groupParent", "groupName");

        const groupedIndicators = await groupListData(indicators);
        const listData = await getOthersData(categories, groupedIndicators);

        setData(listData);
    }, [reducerValue]);

    const onIndicatorNew = () => {
        setShowInticatorNew(true);
    };

    const onGroupNew = () => {
        setShowGroupNew(true);
    };

    const onIndicatorDismiss = () => {
        setSelectedIndicatorId("");
        setShowInticatorNew(false);
    };

    const onGroupDismiss = () => {
        setShowGroupNew(false);
    };

    const deleteIndicator = async (id) => {
        try {
            const IndicatorDoc = doc(db, "otherIndicators", id);
            await deleteDoc(IndicatorDoc);
            message.success("Амжилттай устгагдлаа");
            forceUpdate();
        } catch (error) {
            console.log(error);
            message.error("Амжилтгүй");
        }
    };

    const onEditClick = (id) => {
        setSelectedIndicatorId(id);
        setShowInticatorNew(true);
    };

    const onDeleteClick = (id) => {
        deleteIndicator(id);
    };

    return (
            <div>
                <Can
                    perform="IND001:EDIT"
                    yes={() => (
                        <PageHeader
                            title="Нийт(0)"
                            buttonTitle="Үзүүлэлт нэмэх"
                            onButtonClick={onIndicatorNew}
                            data={data}
                            columns={columns}
                            addButton={
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ButtonIcon onClick={onGroupNew} iconName="Switch" text="Бүлэг нэмэх" />
                                    </div>
                                </div>
                            }
                        />
                    )}
                />
                <Can perform="IND001:VIEW" yes={() => <PageHeader title="Нийт(0)" onButtonClick={onIndicatorNew} data={data} columns={columns} />} />
                <div style={{ margin: 16 }}>
                    <Table size="small" columns={columns} dataSource={data} childrenColumnName="children" scroll={{ x: 1500, y: 700 }} bordered pagination={false} />
                    <IndicatorPanel
                        isOpen={showInticatorNew}
                        indicatorId={selectedIndicatorId}
                        onDismiss={onIndicatorDismiss}
                        groups={indicatorGroups}
                        forceUpdate={forceUpdate}
                    ></IndicatorPanel>
                    <GroupPanel isOpen={showGroupNew} onDismiss={onGroupDismiss} groups={indicatorGroups} forceUpdate={forceUpdate}></GroupPanel>
                    {vCalculateMethodPanel}
                </div>
        </div>
    );
};

export default OtherIndicators;
