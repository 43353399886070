import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { IconButton } from "@fluentui/react";
import { PageHeader, GridDefault } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { APIGraphQL } from "../../../API/APIGraphQL";
import SecurityObjectSchoolPanel from "./Panels/SecurityObjectSchoolPanel";
import {ReactComponent as ExcelIcon} from '../../../svg/excel.svg';
import {Row, Col, Space} from "antd";
import moment from "moment";
import * as xlsx from "xlsx";

const SecurityObjectSchool = () => {
  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityObjectId, setSecurityObjectSchoolId] = useState(0);
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([
    {
      name: "№",
      maxWidth: 25,
      minWidth: 25,
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      }
    },
    {
      name: "Харуулын байр",
      fieldName: "sECURITYOBJECTNAME",
      minWidth: 150,
      maxWidth: 150
    },
    {
      name: "Харъяа сургууль",
      fieldName: "sCHOOLNAME",
      minWidth: 200,
      maxWidth: 200
    },
    {
      name: "Хувь",
      fieldName: "sHAREPERCENT",
      colSize: "mid",
    },
    {
      name: "Эдийн засгийн ангилал",
      fieldName: "eCONOMYCATEGORYNAME",
      minWidth: 200,
      maxWidth: 200
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.iD)}
            />
          </div>
        );
      },
    },
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getSecurityObjectSchoolList {
            iD
            sECURITYOBJECTID
            sECURITYOBJECTNAME
            sCHOOLID
            sCHOOLNAME
            dEPIDS
            sHAREPERCENT
            eCONOMYCATEGORYID
            eCONOMYCATEGORYNAME
            cREATED
            cREATEDBY
        }   
      }
    }`}).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getSecurityObjectSchoolList;
      if (tResp?.length > 0) {
          tData = tResp;
        }
    }).catch(() => {
      setLoading(false);
    });

    setData(tData);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setSecurityObjectSchoolId();
    setShowNew(true);
  };

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const onDismiss = () => {
    setSecurityObjectSchoolId();
    setShowNew(false);
  };

  const onEditClick = (id) => {
    setSecurityObjectSchoolId(id);
    setShowNew(true);
  };
  const downloadExcel=async()=> {
    let tData =[];
    data?.map(r => {
      tData.push({
        "Харуулын байр": r?.sECURITYOBJECTNAME,
        "Харьяалагдах нэгж": r?.sCHOOLNAME,
        "Хувь": r?.sHAREPERCENT,
        "Эдийн засгийн ангилал": r?.eCONOMYCATEGORYNAME
      });
    });
    const ws = xlsx.utils.json_to_sheet(tData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Харуул хамгаалалт");
    xlsx.writeFile(wb, "Харуул хамгаалалт "+moment().format ("YYYY-MM-DD HH-mm-ss")+".xlsx");
  }

  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          title="Нийт"
          dataCount={data?.length}
          buttonTitle="Нэмэх"
          onButtonClick={onNew}
          onSearch={onSearch}
          data={data}
          columns={columns}
          addRightButton={(
            <div style={{marginRight: 6, marginTop: 2, cursor:'pointer'}}>
              <Space size={16}>
                <div onClick={downloadExcel}>
                  <ExcelIcon/>
                </div>
              </Space>
            </div>
          )}
        />
        <div style={{ margin: 16 }}>
        <GridDefault columns={columns} data={data} loading={loading}></GridDefault>
        <SecurityObjectSchoolPanel
          id={securityObjectId}
          isOpen={showNew}
          onDismiss={onDismiss}
          afterSave={getData}
        ></SecurityObjectSchoolPanel>
        </div>
      </Layout>
    </Layout>
  );
};

export default SecurityObjectSchool;
