import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, LoadSpin, PanelBase } from "dw-components";
import FieldNumberFormat from "../../../../Components/FieldNumberFormat/FieldNumberFormat";
import { message } from "antd";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import { UserContext } from "../../../../Context/UserContext";
import { BudgetYear } from "../../../../Const/BudgetYear";

const ProductPanel = (props) => {

  const [data, setData] = useState({});
  const [dECat, setDECat] = useState([]);
  const [dUnit, setDUnit] = useState([]);
  const [dPos, setDPos] = useState([]);

  const { userInfo } = useContext(UserContext);
  const [checkRule, setCheckRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);

    let tDECat = [];
    let tDUnit = [];
    let tDPos = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          normeconomycategorys {
            key: iD
            text: nAME 
          }
          getUnits{
            key: iD
            text: nAME
          }
          getPositions{
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.normeconomycategorys;
      let tUResp = response?.data?.data?.sharedQuery?.getUnits;
      let tPResp = response?.data?.data?.sharedQuery?.getPositions;
      if (tResp?.length > 0) {
        tDECat = tResp;
      }
      if (tUResp?.length > 0) {
        tDUnit = tUResp;
      }
      if (tPResp?.length > 0) {
        tDPos = tPResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDECat(tDECat);
    setDUnit(tDUnit);
    setDPos(tDPos);

    let tData = {};
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
            sharedQuery {
              normativeItem(id: $id) {
                iD
                eCONOMYCATEGORYID
                nAME
                uNITID
                qUOTA
                uNITPRICE
                pOSITIONID
                yEAR
              }
            }
          }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.normativeItem;
        if (tResp?.iD) {
          tData = tResp;
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldName) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      setData(tData);
    }
  }

  const onSave = async () => {
    let vRule = true;
    setCheckRule(true);

    let tData = { ...data };

    if (!tData?.eCONOMYCATEGORYID ||
      !tData?.nAME ||
      !tData?.uNITID ||
      !tData?.qUOTA ||
      !tData?.uNITPRICE ||
      !tData?.pOSITIONID)
      vRule = false;

    if (vRule) {

      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      tData.uNITPRICE = parseFloat(tData.uNITPRICE);
      tData.qUOTA = parseInt(tData.qUOTA);
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation($data: NormativeItemInputType) {
                sharedMutation {
                  addNormativeItem(data: $data) {
                    iD
                  }
                }
              }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addNormativeItem?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteNormativeItem($id: Int) {
        sharedMutation {
          deleteNormativeItem(id: $id)
        }
      }
      `,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteNormativeItem == 0) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText="Бараа нэмэх"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <ButtonDefault
            text={props.id ? "Засах" : "Хадгалах"}
            onClick={onSave}
          />
        </div>
      }
    >
      <div className="add-product">
        <div style={{ marginTop: 25 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" defaultValue={data.eCONOMYCATEGORYID} data={dECat} checkreq={checkRule} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Нормативт бараа материал" keyfield="nAME" defaultValue={data.nAME} data={dECat} checkreq={checkRule} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Хэмжих нэгж" keyfield="uNITID" keyfieldname="uNITNAME" defaultValue={data.uNITID} checkreq={checkRule} data={dUnit} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Норм" keyfield="qUOTA" defaultValue={data.qUOTA} checkreq={checkRule} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Нэгжийн үнэ" keyfield="uNITPRICE" defaultValue={data.uNITPRICE} decimalScale={4}/> </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Албан тушаал" keyfield="pOSITIONID" defaultValue={data.pOSITIONID} checkreq={checkRule} data={dPos} /> </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Төсвийн жил" keyfield="yEAR" defaultValue={data.yEAR} checkreq={checkRule} data={BudgetYear} />
        </div>
      </div>
    </PanelBase>
  );
};

export default ProductPanel;
