import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ColEdit, FieldNumberFormat, FieldText, LoadScreen, LoadSpin } from 'dw-components';
import { API } from '../../../API/API';
import { TreeList } from 'devextreme-react';
import { Button, Row, Col, Space } from 'antd';
import { Column, Scrolling, Selection } from 'devextreme-react/tree-list';
import { UserContext } from '../../../Context/UserContext';
import FileDownload from 'js-file-download';
import { useParams } from "react-router-dom";
import FileAttach from './FileAttach/FileAttach';
import { ReactComponent as GridAttachIcon } from '../../../svg/gridAttach.svg';
import ButtonReport from '../../../Components/ButtonReport/ButtonReport';

const BudgetDtl = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shorten, setShorten] = useState(1);

    const [expanded, setExpanded] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [infoData, setInfoData] = useState({});
    const params = useParams();

    const [attachId, setAttachId] = useState();
    const [attachName, setAttachName] = useState();

    const getData = async () => {
        setLoading(true);
        let tData = [];
        let tInfoData = {};
        let budgetid = 0;
        if (props?.id) {
            budgetid = props?.id;
        }
        else if(params?.id) {
            budgetid = params?.id;
        }

        if(budgetid > 0) {
            await API.get("/api/budget/budgetDtlDepartment?budgetid=" + budgetid + "&departmentid=" + userInfo?.schoolid
                + "&datatype=" + props?.type + "&userid=" + userInfo?.id).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        tData = response?.data?.retData?.budget;
                    }
                }).catch(() => {
                    setLoading(false);
                });
        }
        let income = 0;
        let issue = 0;
        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
            if(tData[i]?.typeid == 1) {
                income += tData[i]?.price;
            }
            else if(tData[i]?.typeid == 2) {
                issue += tData[i]?.price;
            }
        }

        tInfoData.incomeprice = income;
        tInfoData.issueprice = issue;
        setData(tData);
        setDataEdit(tData);
        setLoading(false);
        setInfoData(tInfoData);    
    }

    const onOptionChanged = useCallback((e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    }, []);


    useEffect(() => {
        getData();
    }, [props?.id, params?.id]);

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
        if (props?.onEdit) {
            props.onEdit(tDataEdit);
        }
    }

    const onAttach = (pAttachId, pAttachName) => {
        setAttachId(pAttachId);
        setAttachName(pAttachName);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let apiname = "/api/budget/budgetReportTemp?budgetid=" + props?.id + "&typeid=" + props?.type + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;
        if(props?.type == 0)
             apiname = "/api/budget/budgetReportTempFull?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;

        await API.get(apiname, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, (props?.type == 1 ? "Орлогын төсөв." : props?.type == 2 ? "Зарлагын төсөв." : "Нийт төсөв.") + pData?.filetype);
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getChildSummary = (p_node, p_col) => {
        let tSummary = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tSummary += getChildSummary(p_node.children[i], p_col);
            }
        } else {
            if (!isNaN(parseFloat(p_node?.data?.[p_col]))) {
                return parseFloat(p_node?.data?.[p_col]);
            } else {
                return 0;
            }
        }
        return tSummary;
    }

    const getChildCount = (p_node) => {
        let tCount = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tCount += getChildCount(p_node.children[i]);
            }
        } else {
            if (p_node?.data?.ischild == 1) {
                return 1;
            } else {
                return 0;
            }
        }
        return tCount;
    }

    const onNodesInitialized = useCallback((e) => {
        e.component.forEachNode((node) => {
            if (node?.data?.ischild == 0) {
                let tValue = getChildSummary(node, "price");
                node.data["price"] = parseFloat(tValue);

                let childCount = getChildCount(node);
                if (childCount == 0 && node?.data?.calctypeid) {
                    node.visible = false;
                }
            }
        });
    }, []);

    let showTotalSum = [];
    if (props?.type == 1) {
        showTotalSum = [
            <Space offset={4}>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 68 }}>Нийт Орлого:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice} />
            </Space>]
    } else if (props?.type == 2) {
        showTotalSum = [
            <Space>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 77 }}>Нийт Зарлага:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.issueprice} />
            </Space>]
    } else if (props?.type == 0) {
        showTotalSum = [
            <Space>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Нийт Орлого:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice} />
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Нийт Зарлага:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.issueprice} />
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Зөрүү:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice - infoData?.issueprice} />
            </Space>]
    }

    return (
        <div>
            {loading ? (
                <LoadSpin />
            ) : (
                <div style={{ marginTop: 16, marginRight: 16 }} className='defaultTreeList'>
                    <div style={{ marginRight: 8, marginLeft: 8 }}>
                        <Row gutter={[16, 16]} justify="space-between" align="middle">
                            <Row>
                                <Col>
                                    <Button
                                        style={{ marginLeft: 8 }}
                                        type={expanded ? null : "primary"}
                                        onClick={() => {
                                            setExpanded(prevExpanded => !prevExpanded)
                                            setExpandedRowKeys([]);
                                        }}
                                        size="small"
                                    >{expanded ? 'Бүгдийг хаах' : 'Бүгдийг нээх'}</Button>
                                </Col>
                                <Col style={{ marginLeft: 8 }}>
                                    <ButtonReport size="small" title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {showTotalSum}
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <TreeList
                        id="id"
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        keyExpr="ecocatid"
                        parentIdExpr="ecocatparentid"
                        showRowLines
                        showBorders
                        autoExpandAll={expanded}
                        expandedRowKeys={expandedRowKeys}
                        onOptionChanged={onOptionChanged}
                        onNodesInitialized={onNodesInitialized}
                        height={"calc(100vh - " + (props.type == "0" ? "210" : "180") + "px)"}
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" />
                        <Selection mode="single" />
                        <Column
                            minWidth={500}
                            dataField="fullname"
                            caption="Эдийн засгийн ангилал" />
                        <Column
                            dataField="price"
                            caption="Төсөв"
                            width={160}
                            cellRender={(item) => {
                                if (item?.data?.ischild == 1 && item?.data?.bdepid) {
                                    if (item?.data?.calctypeid == 2 || item?.data?.calctypeid == 51) {
                                        return (
                                            <FieldNumberFormat disable={props.disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "price")} defaultValue={item.data?.price}  fixedDecimalScale={true} />
                                        );
                                    } else {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.price * shorten} />
                                        );
                                    }
                                } else {
                                    return <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item?.text} />;
                                }
                            }} />
                        <Column
                            dataField="percent"
                            caption="Хувь"
                            width={80}
                            cellRender={(item) => {
                                let tPercent = 0;
                                if (!props?.edit) {
                                    tPercent = ((item?.data?.price || 0) * 100) / (props?.total || 1);
                                }
                                return (
                                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={tPercent} />
                                );
                            }} />
                        <Column
                            dataField="note"
                            caption="Тайлбар"
                            cellRender={(item) => {
                                if (item?.data?.ischild == 1 && item?.data?.bdepid) {
                                    if (item?.data?.calctypeid == 2 || item?.data?.calctypeid == 51) {
                                        return (
                                            <FieldText disable={props.disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "note")} defaultValue={item.data?.note} />
                                        );
                                    } else {
                                        return (
                                            <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.note} />
                                        );
                                    }
                                } else {
                                    return null;
                                }
                            }} />
                        <Column
                            dataField="attachfiles"
                            caption="Файл"
                            width={110}
                            cellRender={(item) => {
                                if (item?.data?.ischild == 1) {
                                    if (item?.data?.attachfiles > 0) {
                                        return (
                                            <ColEdit onClick={() => onAttach(item?.data?.id, item?.data?.fullname)}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ marginTop: 4 }}>
                                                        <GridAttachIcon />
                                                    </div>
                                                    <div style={{ marginLeft: 4, marginTop: -2 }}>
                                                        {item?.data?.attachfiles}
                                                    </div>
                                                </div>
                                            </ColEdit>
                                        );
                                    } else if (!props?.disable) {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <ColEdit onClick={() => onAttach(item?.data?.id, item?.data?.fullname)}>Файл хавсаргах</ColEdit>
                                            </div>
                                        );
                                    }
                                }

                                return null;
                            }} />
                    </TreeList>
                </div>
            )}
            <FileAttach disable={props?.disable} budgetid={props?.id} id={attachId} name={attachName} isOpen={attachId ? true : false} onDismiss={() => { setAttachId(); }} afterSave={getData} />
        </div>
    );
}

export default BudgetDtl;