import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";

const SecurityObjectSchoolPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [columns, setColumns] = useState([]);
    const [basesalary, setBaseSalary] = useState(0);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0 && props?.id) {
            setLoadingSave(true);
            let vSuccess = false;
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < columns.length; j++)
                {
                    let tRNew = {};
                    tRNew.iD = tR[columns[j].id.toString() + "_ID"];
                    tRNew.bUDGETID = parseInt(props?.id);
                    tRNew.sECURITYOBJECTID = tR.securityobjectid;
                    tRNew.sECURITYOBJECTNAME = tR.securityobjectname;
                    tRNew.sECURITYOBJECTSCHOOLID = tR[columns[j].id.toString() + "_SECURITYOBJECTSCHOOLID"];
                    tRNew.sECURITYOBJECTSCHOOLID = !tRNew.sECURITYOBJECTSCHOOLID ? 0: tRNew.sECURITYOBJECTSCHOOLID;
                    tRNew.pARTNERID = tR.partnerid;
                    tRNew.pARTNERNAME = tR.partnername;
                    tRNew.bASESALARY = basesalary;
                    tRNew.gUARDQTY = tR.guardqty;
                    tRNew.gUARDSALARY = tR.guardsalary;
                    tRNew.tOTALSALARY = tR.totalsalary;
                    tRNew.sCHOOLID = columns[j].id;
                    tRNew.sCHOOLNAME = columns[j].name;
                    tRNew.sCHOOLAMOUNT = tR[columns[j].id.toString()];
                    if (!tRNew.iD || tRNew.iD == 0) {
                        tRNew.iD = 0;
                        tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                    }
                    tRNew.cREATEDBY = userInfo?.username;
                    tBSecDtl[k] = tRNew;
                    k += 1;
                }
            }
            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetSecurityInputType]) {
            //         budgetMutation {
            //             addBudgetSecurity(data: $data) {
            //                 iD
            //             }
            //         }
            //     }`,
            //     variables: {
            //         "data": tBSecDtl
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetSecurity?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });

            await API.post("api/budget/calcBSecurity?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid, tBSecDtl).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                vSuccess = true;
                message.success("Амжилттай");
            }
            }).catch(() => {
                setLoading(false);
            });

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }

        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/budgetSecurityTemp?budgetid=" + props?.id + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Харуул хамгаалалт" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getData = async () => {
        let tColumns = [];
        let tData = [];
        setLoading(true);
        await API.get("/api/budget/getSecurityObjectSchoolList?budgetid=" + props?.id)
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data.length > 0) {
                    tData = response?.data?.retData?.data;
                    setBaseSalary(tData[0].basesalary);
                }
                if (response?.data?.retData?.schools.length > 0) {
                    tColumns = response?.data?.retData?.schools;
                }
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData();
        setLoading(false);
        setColumns(tColumns);
        setData(tData);
        setDataEdit(tData);
        setDisable(props?.disable);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const getAttachData = async () => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + userInfo?.schoolid + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "guardqty") {
            tDataEdit[pIndex]["totalsalary"] = pValue * tDataEdit[pIndex]["guardsalary"];
            for (let i = 0; i < columns?.length; i++) {
                tDataEdit[pIndex][columns[i].id.toString()] = tDataEdit[pIndex][columns[i].id.toString() + "_SHAREPERCENT"] * tDataEdit[pIndex]["totalsalary"] /100;
            }
        } else if (pField == "guardsalary") {
            tDataEdit[pIndex]["totalsalary"] = pValue * tDataEdit[pIndex]["guardqty"];
            for (let i = 0; i < columns?.length; i++) {
                tDataEdit[pIndex][columns[i].id.toString()] = tDataEdit[pIndex][columns[i].id.toString() + "_SHAREPERCENT"] * tDataEdit[pIndex]["totalsalary"] /100;
            }
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
    
    const fieldSalaryOnChanged = async (key) => {
        let tDataEdit = [...dataEdit];
        for (let i = 0; i < columns?.length; i++) {
            for (let j = 0; j < tDataEdit?.length; j++)
            {
                tDataEdit[j]["guardsalary"] = key;
                tDataEdit[j]["totalsalary"] = key * tDataEdit[j]["guardqty"];
                tDataEdit[j][columns[i].id.toString()] = data[j][columns[i].id.toString() + "_SHAREPERCENT"] * tDataEdit[j]["totalsalary"] /100;
                tDataEdit[j].edit = true;
            }
        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
        setBaseSalary(key);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <PanelBase
            headerText="Харуул, хамгаалалт"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={
                <div style={{ display: "flex" }}>
                    {(!disable &&
                    <div style={{ marginRight: 16 }}>
                        {loadingSave ? (<LoadSpin />) :
                            (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                    </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            }
        >
         <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                    /мян.төгрөг/
         </div>
        <div style={{ marginTop: 16 }}>
            <FieldNumberFormat onChanged={(key) => fieldSalaryOnChanged(key)} title="Хамгаалагчийн сарын цалин" keyfield="basesalary" defaultValue={basesalary} fixedDecimalScale={true}/>
        </div>
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <ColumnFixing enabled={true} />
                            <Column Width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="securityobjectname"
                                caption="Обьект"
                                width={300}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} defaultValue={item.data?.securityobjectname}/>
                                    );
                                }} />
                            <Column caption="Хамгаалагчийн" alignment='center'>
                            <Column
                                dataField="guardqty"
                                caption="тоо"
                                width={100}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "guardqty")} defaultValue={item.data?.guardqty} />
                                    );
                                }} />
                            <Column
                                dataField="guardsalary"
                                caption="сарын цалин"
                                alignment='center'
                                width={140}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "guardsalary")} defaultValue={item.data?.guardsalary} fixedDecimalScale={true} />
                                    );
                                }} />
                            </Column>
                            <Column
                                dataField="totalsalary"
                                caption="Обьект дэд нэгж"
                                alignment='center'
                                minwidth={140}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.totalsalary} fixedDecimalScale={true} />
                                    );
                                }} />
                <Column caption="ХАРУУЛ ХАМГААЛАЛТ"  alignment='center'>
                    {columns?.map(r => {
                    return (
                        <Column caption={r.name}
                                alignment='center'
                                dataField={r.id?.toString()}
                                format="fixedPoint"
                                cellRender={item => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, r.id?.toString() )} defaultValue={item?.data?.[r.id]} fixedDecimalScale={true} />
                                      );
                                }}
                            />
                            );
                    })}
                </Column>
                                
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalsalary"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={userInfo?.schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(userInfo?.schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default SecurityObjectSchoolPanel;