import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import IndicatorItem from '../../Components/IndicatorItem/IndicatorItem';
import IndicatorTitle from '../../Components/IndicatorTitle/IndicatorTitle';
import SalaryGridPanel from '../Indicator/SalaryGridPanel';
import PositionForm from '../Indicator/PositionForm';
import { LoadScreen } from 'dw-components';
import { UserContext } from '../../Context/UserContext';
import { API } from '../../API/API';
import BudgetTuitionFee from '../Indicator/BudgetTuitionFee';
import BTripGridPanel from '../Indicator/BTripGridPanel';
import BudgetNormative from '../Indicator/BudgetNormative';
import BudgetFuelFee from '../Indicator/BudgetFuelFee';
import BTripForeignPanel from '../Indicator/BTripForeignPanel';
import RentSquareForm from '../Indicator/RentSquareForm';
import BudgetVehicleTax from '../Indicator/BudgetVehicleTax';
import { APIGraphQL } from '../../API/APIGraphQL';
import { URLTYPE } from '../../Const/PurchaseInvestment';
import BudgetPayment from '../Indicator/BudgetPayment';
import BudgetBonus from '../Indicator/BudgetBonus';
import SecurityObjectSchoolPanel from '../Indicator/SecurityObjectSchoolPanel';
import BResearchIncome from '../Indicator/BResearchIncome';
import BHourlyWagePanel from '../Indicator/BHourlyWagePanel';
import StudentInfoPanel from '../Indicator/StudentInfoPanel';
import OtherIndicatorPanel from '../Indicator/OtherIndicatorPanel';
import PensionPanel from '../Indicator/PensionPanel';
import BudgetBCStudentPanel from '../Indicator/BudgetBCStudentPanel';
import BProServiceIncome from '../Indicator/BProServiceIncome';
import BProjectAndInnovationCost from '../Indicator/BProjectAndInnovationCost';
import BRepairCost from '../Indicator/BRepairCost';
import BBuildingRepairCost from '../Indicator/BBuildingRepairCost';
import BConsultingCostPanel from '../Indicator/BConsultingCost';
import LightCostGridPanel from '../Indicator/LightCostGridPanel';
import WaterCostGridPanel from '../Indicator/WaterCostGridPanel';
import HeatCostGridPanel from '../Indicator/HeatCostGridPanel';
import BetweenCostPanel from '../Indicator/BetweenCostPanel';
import BudgetInternetPanel from '../Indicator/BudgetInternetPanel';
import BStationeryPanel from '../Indicator/BStationeryPanel';
import BEmpSupportPanel from '../Indicator/BEmpSupportPanel';

const BudgetDtlIndicator = (props) => {
    const params = useParams();
    const { userInfo } = useContext(UserContext);
    const navigate = useNavigate();
    const [data, setData] = useState({});

    const [indId, setIndId] = useState();
    const [indType, setIndType] = useState();
    const [showPanel, setShowPanel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dSchool, setDSchool] = useState([]);
    const [dParentSchool, setDParentSchool] = useState([]);

    const [attachDataCount, setAttachDataCount] = useState([]);
    const [dEconomyRole, setEconomyRole] = useState([]);

    const getData = async () => {
        let tData = { ...data };
        let tEconomyRole = [];
        let tDSchool = [];
        let tDParentSchool = [];
        if (props?.id && userInfo?.schoolid) {
            setLoading(true);
            await API.get("/api/budget/budgetIndicatorInfo?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData?.info.length > 0) {
                        tData = response?.data?.retData.info[0];
                    }
                    if (response?.data?.retData?.role.length > 0) {
                        tEconomyRole = response?.data?.retData.role;
                    }
                }
            }).catch(() => {
                setLoading(false);
            });

            if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 3 || userInfo?.schooltypeid == 6) {
                await APIGraphQL.post("", {
                    query: `query ($schoolid: Int) {
                        sharedQuery {
                          schoolByParentId(schoolid: $schoolid) {
                            key: iD
                            text: nAME
                          }
                        }
                      }`,
                    variables: {
                        "schoolid": parseInt(userInfo?.schoolid)
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.schoolByParentId;
                    if (tResp?.length > 0) {
                        tDSchool = tResp;
                        tDSchool.push({ key: userInfo?.schoolid, text: "Бүх тэнхим" });
                    }
                }).catch(() => {
                    setLoading(false);
                })
            } else if (userInfo?.schooltypeid == 1) {
                await APIGraphQL.post("", {
                    query: `{
                        sharedQuery {
                            schoolAll {
                            key: iD
                            text: nAME
                          }
                        }
                      }`
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.schoolAll;
                    if (tResp?.length > 0) {
                        tDParentSchool = tResp;
                        tDParentSchool.push({ key: userInfo?.schoolid, text: "Бүх сургууль" });
                    }
                }).catch(() => {
                    setLoading(false);
                })
            }

            await getAttachDataCount();
            setLoading(false);
        }

        setDParentSchool(tDParentSchool);
        setData(tData);
        setDSchool(tDSchool);
        setEconomyRole(tEconomyRole);
    }

    const getAttachDataCount = async () => {
        let tAttachDataCount = {};
        await API.get("/api/budget/budgetIndAttach?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.length > 0) {
                    tAttachDataCount = response?.data?.retData?.reduce((obj, item) => Object.assign(obj, { [item.id]: item.attachfiles }), {});
                }
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachDataCount(tAttachDataCount);
    }

    useEffect(() => {
        getData();
    }, [props?.id, props?.statusId, props?.disable]);

    const onChangeIndicator = (pId, pTitle, pType) => {
        setIndId(pId);
        setShowPanel(true);
        setIndType(pType ? pType : null);
    }

    const onPurchase = () => {
        if (params?.id && (userInfo?.schooltypeid == 4 || userInfo?.schooltypeid == 7)) {
            navigate("/purchaseinvestment/department/suggestion/" + URLTYPE.BUDGET + "/" + params?.id);
        } else if (params?.id && userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 3 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 5) {
            navigate("/purchaseinvestment/branch/suggestion/" + URLTYPE.BUDGET + "/" + params?.id);
        } else if (params?.id && userInfo?.schooltypeid == 1) {
            navigate("/purchaseinvestment/suggestion/" + URLTYPE.BUDGET + "/" + params?.id);
        }
    };

    const onDismiss = () => {
        setIndId();
        setShowPanel();
    }

    const setVisibility = (calctypeid) => {
        let isVisible = false;

        if (userInfo?.indicatorids == "63") { //Zuvhun tsalin harah
            if (",63,88,74,100,".includes("," + calctypeid + ",")) {
                isVisible = true;
            }
            return isVisible;
        } else if (userInfo?.indicatorids == "1") { //Tsalingaas busdiig harah
            if (",63,88,74,100,".includes("," + calctypeid + ",")) {
                return isVisible;
            }
        } else if (!userInfo?.indicatorids) {
            return false;
        } else if (!userInfo?.indicatorids?.includes("63")) { //Tsalingaas busdiig harah
            if (",63,88,74,100,".includes("," + calctypeid + ",")) {
                return false;
            }
        }
        let economyRole = dEconomyRole.filter((val) => val.calctypeid == calctypeid);
        if (economyRole.length > 0) {
            if (userInfo?.schooltypeid == 7 && economyRole[0]?.levelid == 1 && economyRole[0]?.schoolids.includes(userInfo?.schoolid)) {
                isVisible = true;
            }
            if (userInfo?.schooltypeid == 4 && economyRole[0]?.levelid == 1)
                isVisible = true;

            if ((userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 3 || userInfo?.schooltypeid == 6)
                && economyRole[0]?.levelid != 3
                && economyRole[0]?.schoolids.includes(userInfo?.schoolid))
                isVisible = true;

            if (userInfo?.schooltypeid == 1)
                isVisible = true;

            else if (userInfo?.schooltypeid == 5 && economyRole[0]?.levelid != 3 && economyRole[0]?.schoolids.includes(userInfo?.schoolid))
                isVisible = true;
        }
        else if (userInfo?.schooltypeid == 1)
            isVisible = true;
        return isVisible;
    }

    const setFieldVisibility = (calctypeid, typeid) => {
        let isVisible = false;
        let economyRole = dEconomyRole.filter((val) => val.calctypeid == calctypeid);
        if (economyRole.length > 0) {
            if (userInfo?.schooltypeid == 1) {
                if (economyRole[0].levelid == 1) { //Тэнхим 
                    isVisible = true;
                }
                else if (economyRole[0].levelid == 2 && typeid == 2) { //Сургууль
                    isVisible = true;
                }
            }
            else if ((userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) && economyRole[0].levelid == 1 && typeid == 1) {
                isVisible = true;
            }
            else if (userInfo?.schooltypeid == 3 &&
                (economyRole[0].levelid == 1 && typeid == 1 || typeid == 2)) {
                isVisible = true;
            }
            else if (userInfo?.schooltypeid == 5) {
                isVisible = false;
            }
        }
        return isVisible;
    }

    const setIndiciatorDisable = (calctypeid) => {
        let tDisable = props?.disable;
        let economyRole = dEconomyRole.filter((val) => val.calctypeid == calctypeid);
        if (economyRole.length > 0) {
            if (userInfo?.schooltypeid == 1) { //MUIS
                //
            }
            else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) { //School
                if (userInfo?.roleids.includes("3") && props?.statusId > 4 || !(userInfo?.roleids.includes("3")) && props?.statusId > 2 || economyRole[0].coverageid == 3) {
                    tDisable = true;
                }
            }
            else if (userInfo?.schooltypeid == 3) { //SubSchool
                tDisable = true;
            }
            else if (userInfo?.schooltypeid == 4 || userInfo?.schooltypeid == 7) { //Department
                if (props?.statusId > 1 || (economyRole[0].coverageid != 1 && economyRole[0].levelid == 1)) {
                    tDisable = true;
                }
            }
            else if (userInfo?.schooltypeid == 5) { //Unit
                if (props?.statusId > 4) {
                    tDisable = true;
                }
            }
        }
        else {
            tDisable = true;
        }
        console.group(tDisable);
        return tDisable;
    }

    const getCoverageid = (calctypeid) => {
        let coverageid = 0;
        let economyRole = dEconomyRole.filter((val) => val.calctypeid == calctypeid);

        if (economyRole.length > 0) {
            coverageid = economyRole[0].coverageid;
        }
        return coverageid;
    }

    const getLevelid = (calctypeid) => {
        let levelid = 0;
        let economyRole = dEconomyRole.filter((val) => val.calctypeid == calctypeid);

        if (economyRole.length > 0) {
            levelid = economyRole[0].levelid;
        }
        return levelid;
    }

    const getTitle = (calctypeid, pValue) => {
        let rValue = "харах";
        if (calctypeid) {
            let tECData = {};
            let tIndex = dEconomyRole.map(r => r.calctypeid).indexOf(calctypeid);
            if (tIndex >= 0) {
                tECData = dEconomyRole[tIndex];
            }
            if (tECData?.coverageid == 1) {
                if (userInfo?.schooltypeid == 4 || userInfo?.schooltypeid == 7 || userInfo?.schooltypeid == 5) {
                    rValue = "оруулах";
                }

                if ((userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 5) && pValue != 0) {
                    rValue = "засах";
                }
            }
            if (tECData?.coverageid == 2) {
                if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 5) {
                    rValue = "оруулах";
                }
                if (userInfo?.schooltypeid == 1 && pValue != 0) {
                    rValue = "засах";
                }
            }

            if (tECData?.coverageid == 2 && tECData?.levelid == 2) {
                if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 5) { // Сургууль төвлөрсөн сан
                    rValue = "оруулах";
                }
            }

            if (tECData?.coverageid == 3) {
                if (userInfo?.schooltypeid == 1) {
                    rValue = "оруулах";
                }
            }
            return " " + rValue;
        }
    }

    let vPanel = null;

    if (indId == 63) {
        vPanel = (
            <SalaryGridPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 100) {
        vPanel = (
            <PositionForm
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );

    } else if (indId == 101) {
        vPanel = (
            <StudentInfoPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
            />
        );
    } else if (indId == 102) {
        vPanel = (
            <OtherIndicatorPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 3) {
        vPanel = (
            <BudgetTuitionFee
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Сургалтын орлого"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 8) {
        vPanel = (
            <BResearchIncome
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 9) {
        vPanel = (
            <BProServiceIncome
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 60) {
        if (indType == 1) {
            vPanel = (
                <BTripGridPanel
                    id={props?.id}
                    indId={601}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    showDep={setFieldVisibility(indId, 1)}
                    showSchool={setFieldVisibility(indId, 2)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        } else if (indType == 2) {
            vPanel = (
                <BTripForeignPanel
                    id={props?.id}
                    indId={602}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    showDep={setFieldVisibility(indId, 1)}
                    showSchool={setFieldVisibility(indId, 2)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        }
    } else if (indId == 52) {
        vPanel = (
            <BudgetNormative
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 61) {
        vPanel = (
            <BudgetFuelFee
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 7) {
        vPanel = (
            <RentSquareForm
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 67) {
        vPanel = (
            <BudgetVehicleTax
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 68) {
        vPanel = (
            <BudgetPayment
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName="Хэвлэл мэдээлэл, маркетинг"
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 69) {
        vPanel = (
            <BudgetPayment
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName="Гишүүнчлэл, гаалийн татвар"
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 70) {
        vPanel = (
            <BudgetPayment
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName="Аудит баталгаажуулалт, зэрэглэл тогтоох"
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 71) {
        vPanel = (
            <BudgetPayment
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName="Бусад төлбөр хураамж"
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 72) {
        vPanel = (
            <BudgetPayment
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName="Мэдээлэл технологийн үйлчилгээ"
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 88) {
        vPanel = (
            <BudgetBonus
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 73) {
        vPanel = (
            <SecurityObjectSchoolPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={false}
                showSchool={false}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 74) {
        vPanel = (
            <BHourlyWagePanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 75) {
        if (indType == 1) {
            vPanel = (
                <PensionPanel
                    id={props?.id}
                    indId={indId}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    showDep={setFieldVisibility(indId, 1)}
                    showSchool={setFieldVisibility(indId, 2)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        }
    } else if (indId == 76) {
        vPanel = (
            <BudgetBCStudentPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Оюутны үйлчилгээний зардал"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 77) {
        vPanel = (
            <BProjectAndInnovationCost
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 78) {
        vPanel = (
            <BudgetBCStudentPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Сургалтын үйлчилгээний зардал"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 79) {
        vPanel = (
            <BProjectAndInnovationCost
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 80) {
        vPanel = (
            <BRepairCost
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 82) {
        vPanel = (
            <BBuildingRepairCost
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 83) {
        vPanel = (
            <BudgetBCStudentPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Нэг удаагийн тэтгэмж, шагнал"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 84) {
        vPanel = (
            <BudgetBCStudentPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Дотоод үйл ажиллагаа"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 85) {
        vPanel = (
            <BudgetBCStudentPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Хангамж бараа материалын зардал"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 86) {
        vPanel = (
            <BConsultingCostPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Зөвлөх үйлчилгээний зардал"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 87) {
        if (indType == 1) {
            vPanel = (
                <HeatCostGridPanel
                    id={props?.id}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        } else if (indType == 2) {
            vPanel = (
                <WaterCostGridPanel
                    id={props?.id}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        } else if (indType == 3) {
            vPanel = (
                <LightCostGridPanel
                    id={props?.id}
                    isOpen={showPanel}
                    onDismiss={onDismiss}
                    afterSave={getData}
                    afterAttach={getAttachDataCount}
                    disable={setIndiciatorDisable(indId)}
                    coverageid={getCoverageid(indId)}
                    levelid={getLevelid(indId)}
                />
            );
        }
    } else if (indId == 10) {
        vPanel = (
            <BetweenCostPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                indName={"Сургууль хоорондын тооцоо"}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 91) {
        vPanel = (
            <BudgetInternetPanel
                id={props?.id}
                isOpen={showPanel}
                onDismiss={onDismiss}
                afterSave={getData}
                indId={indId}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    }
    else if (indId == 11) {
        vPanel = (
            <BudgetTuitionFee
                id={props?.id}
                indId={indId}
                secIndId={92}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Хос хавсрах хөтөлбөр орлого"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 92) {
        vPanel = (
            <BudgetTuitionFee
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Хос хавсрах хөтөлбөр зардал"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    } else if (indId == 93) {
        vPanel = (
            <BStationeryPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Бичиг хэргийн зардал /Захиргаа/"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    }  else if (indId == 94) {
        vPanel = (
            <BStationeryPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Бичиг хэргийн зардал /Сургалт/"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    }  else if (indId == 95) {
        vPanel = (
            <BEmpSupportPanel
                id={props?.id}
                indId={indId}
                isOpen={showPanel}
                onDismiss={onDismiss}
                indName={"Багш, сургалтын ажилтны дэмжлэг"}
                afterSave={getData}
                afterAttach={getAttachDataCount}
                disable={setIndiciatorDisable(indId)}
                showDep={setFieldVisibility(indId, 1)}
                showSchool={setFieldVisibility(indId, 2)}
                coverageid={getCoverageid(indId)}
                levelid={getLevelid(indId)}
            />
        );
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div>
            <>
                <div style={{ marginTop: 24 }}>
                    <IndicatorTitle value="Ерөнхий үзүүлэлт" />
                    {setVisibility(100) && ( // Salary dagaj yavna
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={100} title={"Ажилчдын тоо"} value={data?.numberofemployees} attach={attachDataCount?.[100]} onClick={onChangeIndicator} typename={getTitle(100, data?.numberofemployees)} />
                        </div>
                    )}
                    {setVisibility(101) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={101} title={"Оюутны тоо, багц цаг"} value={data?.numberofstudents ? 'null' : 0} attach={attachDataCount?.[101]} onClick={onChangeIndicator} typename={getTitle(101, data?.numberofstudents)} />
                        </div>
                    )}
                    {setVisibility(102) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={102} title={"Бусад үзүүлэлт"} value={data?.other ? 'null' : 0} attach={attachDataCount?.[102]} onClick={onChangeIndicator} typename={getTitle(102, data?.other)} />
                        </div>
                    )}
                </div>
                <div style={{ marginTop: 24 }}>
                    <IndicatorTitle value="Орлогын дэд маягтууд" />
                    {setVisibility(8) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={8} title={"ОДМ1 - Судалгааны орлого"} value={data?.researchfee} attach={attachDataCount?.[8]} onClick={onChangeIndicator} typename={getTitle(8, data?.researchfee)} />
                        </div>
                    )}  {setVisibility(3) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={3} title={"ОДМ2 - Сургалтын орлого"} value={data?.tuitionfee} attach={attachDataCount?.[3]} onClick={onChangeIndicator} typename={getTitle(3, data?.tuitionfee)} />
                        </div>
                    )}
                    {setVisibility(7) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={7} title={"ОДМ3 - Түрээсийн орлого"} value={data?.rentsquare} attach={attachDataCount?.[7]} onClick={onChangeIndicator} typename={getTitle(7, data?.rentsquare)} />
                        </div>
                    )}
                    {setVisibility(9) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={9} title={"ОДМ4 - Мэргэжлийн үйлчилгээний орлого"} value={data?.proserviceincome} attach={attachDataCount?.[9]} onClick={onChangeIndicator} typename={getTitle(9, data?.proserviceincome)} />
                        </div>
                    )}
                    {setVisibility(10) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={10} title={"ОДМ5 - Сургууль хоорондын тооцоо"} value={data?.autexp + data?.sprexp} attach={attachDataCount?.[10]} onClick={onChangeIndicator} typename={getTitle(11, data?.autexp || data?.sprexp)} />
                        </div>
                    )}
                    {setVisibility(11) && (
                        <div style={{ marginTop: 8 }}>
                            <IndicatorItem id={11} title={"ОДМ6 - Хос хавсрах хөтөлбөр"} value={data?.tuitdual} attach={attachDataCount?.[11]} onClick={onChangeIndicator} typename={getTitle(11, data?.tuitdual)} />
                        </div>
                    )}
                </div>
            </>
            <div style={{ marginTop: 24 }}>
                <IndicatorTitle value="Зарлагын дэд маягтууд" />
                {setVisibility(63) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={63} title={"ЗДМ1 - Цалингийн зардал"} value={data?.salary} attach={attachDataCount?.[63]} onClick={onChangeIndicator} typename={getTitle(63, data?.salary)} />
                    </div>
                )}
                {setVisibility(88) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={88} title={"ЗДМ2 - Урамшууллын зардал"} value={data?.bonus} attach={attachDataCount?.[88]} onClick={onChangeIndicator} typename={getTitle(88, data?.bonus)} />
                    </div>
                )}
                {setVisibility(74) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={74} title={"ЗДМ3 - Цагийн хөлс, илүү цагийн зардал"} value={data?.hourlywage} attach={attachDataCount?.[74]} onClick={onChangeIndicator} typename={getTitle(74, data?.hourlywage)} />
                    </div>
                )}
                {setVisibility(87) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={87} title={"ЗДМ4 - Түлш халаалтын зардал"} value={data?.heatcost} attach={attachDataCount?.[871]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 1)} typename={getTitle(87, data?.heatcost)} />
                    </div>
                )}
                {setVisibility(87) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={87} title={"ЗДМ5 - Цэвэр, бохир усны зардал"} value={data?.watercost} attach={attachDataCount?.[872]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 2)} typename={getTitle(87, data?.watercost)} />
                    </div>
                )}
                {setVisibility(87) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={87} title={"ЗДМ6 - Гэрэл цахилгааны зардал"} value={data?.lightcost} attach={attachDataCount?.[873]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 3)} typename={getTitle(87, data?.lightcost)} />
                    </div>
                )}
                {setVisibility(61) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={61} title={"ЗДМ7 - Шатахууны нормын зардал"} value={data?.fuelnorm} attach={attachDataCount?.[61]} onClick={onChangeIndicator} typename={getTitle(61, data?.fuelnorm)} />
                    </div>
                )}
                {setVisibility(52) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={52} title={"ЗДМ8 - Норматив бараа материалын зардал"} value={data?.normative} attach={attachDataCount?.[52]} onClick={onChangeIndicator} typename={getTitle(52, data?.normative)} />
                    </div>
                )}
                {setVisibility(60) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={60} title={"ЗДМ9 - Гадаад томилолтын зардал"} value={data?.btripforeign} attach={attachDataCount?.[602]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 2)} typename={getTitle(60, data?.btripforeign)} />
                    </div>
                )}
                {setVisibility(60) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={60} title={"ЗДМ10 - Дотоод томилолтын зардал"} value={data?.btrip} attach={attachDataCount?.[601]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 1)} typename={getTitle(60, data?.btrip)} />
                    </div>
                )}
                {userInfo?.schooltypeid == 1 && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={73} title={"ЗДМ11 - Харуул, хамгаалалтын зардал"} value={data?.securityobject} attach={attachDataCount?.[73]} onClick={onChangeIndicator} typename={getTitle(73, data?.securityobject)} />
                    </div>
                )}
                {setVisibility(68) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={68} title={"ЗДМ12 - Хэвлэл мэдээлэл, маркетингийн зардал"} value={data?.paymenT68} attach={attachDataCount?.[68]} onClick={onChangeIndicator} typename={getTitle(68, data?.paymenT68)} />
                    </div>
                )}
                {setVisibility(86) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={86} title={"ЗДМ13 - Зөвлөх үйлчилгээний зардал"} value={data?.concost} attach={attachDataCount?.[86]} onClick={onChangeIndicator} typename={getTitle(86, data?.concost)} />
                    </div>
                )}
                {setVisibility(69) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={69} title={"ЗДМ14 - Гишүүнчлэл, гаалийн татварын зардал"} value={data?.paymenT69} attach={attachDataCount?.[69]} onClick={onChangeIndicator} typename={getTitle(69, data?.paymenT69)} />
                    </div>
                )}
                {setVisibility(71) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={71} title={"ЗДМ15 - Бусад төлбөр хураамжийн зардал"} value={data?.paymenT71} attach={attachDataCount?.[71]} onClick={onChangeIndicator} typename={getTitle(71, data?.paymenT71)} />
                    </div>
                )}
                {setVisibility(70) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={70} title={"ЗДМ16 - Аудит баталгаажуулалт, зэрэглэл тогтоох зардал"} value={data?.paymenT70} attach={attachDataCount?.[70]} onClick={onChangeIndicator} typename={getTitle(70, data?.paymenT70)} />
                    </div>
                )}
                {setVisibility(67) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={67} title={"ЗДМ17 - Авто машины татвар хураамжийн зардал"} value={data?.vehicletax} attach={attachDataCount?.[67]} onClick={onChangeIndicator} typename={getTitle(67, data?.vehicletax)} />
                    </div>
                )}
                {setVisibility(72) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={72} title={"ЗДМ18 - Мэдээлэл технологийн үйлчилгээний зардал"} value={data?.paymenT72} attach={attachDataCount?.[72]} onClick={onChangeIndicator} typename={getTitle(72, data?.paymenT72)} />
                    </div>
                )}
                {setVisibility(78) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={78} title={"ЗДМ19 - Сургалтын үйлчилгээний зардал"} value={data?.bclesson} attach={attachDataCount?.[78]} onClick={onChangeIndicator} typename={getTitle(78, data?.bclesson)} />
                    </div>
                )}
                {setVisibility(76) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={76} title={"ЗДМ20 - Оюутны үйлчилгээний зардал"} value={data?.bcstudent} attach={attachDataCount?.[76]} onClick={onChangeIndicator} typename={getTitle(76, data?.bcstudent)} />
                    </div>)}
                {setVisibility(77) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={77} title={"ЗДМ21 - Эрдэм шинжилгээ, судалгаа, инновацийн зардал"} value={data?.innovationcost} attach={attachDataCount?.[77]} onClick={onChangeIndicator} typename={getTitle(77, data?.innovationcost)} />
                    </div>
                )}
                {setVisibility(79) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={79} title={"ЗДМ22 - Төсөл, гэрээт ажилтай холбоотой зардлууд"} value={data?.projectcost} attach={attachDataCount?.[79]} onClick={onChangeIndicator} typename={getTitle(79, data?.projectcost)} />
                    </div>
                )}
                {setVisibility(80) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={80} title={"ЗДМ23 - Эд хогшлын зардал"} value={data?.repaircost} attach={attachDataCount?.[80]} onClick={onChangeIndicator} typename={getTitle(80, data?.repaircost)} />
                    </div>
                )}
                {setVisibility(82) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={82} title={"ЗДМ24 - Урсгал засварын зардал"} value={data?.buildingrepaircost} attach={attachDataCount?.[82]} onClick={onChangeIndicator} typename={getTitle(82, data?.buildingrepaircost)} />
                    </div>
                )}
                {setVisibility(75) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={75} title={"ЗДМ25 - Тэтгэвэр, тэтгэмжийн зардал"} value={data?.pension} attach={attachDataCount?.[75]} onClick={(pId, pTitle) => onChangeIndicator(pId, pTitle, 1)} typename={getTitle(75, data?.pension)} />
                    </div>
                )}
                {setVisibility(58) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={58} title={"ЗДМ26 - Худалдан авалт, хөрөнгө оруулалтын зардал"} value={data?.budgetinvestment + data?.budgetpurchase} attach={attachDataCount?.[58]} onClick={onPurchase} typename={getTitle(58, (data?.budgetinvestment || data?.budgetpurchase))} />
                    </div>
                )}
                {setVisibility(83) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={83} title={"ЗДМ27 - Нэг удаагийн тэтгэмж, шагналын зардал"} value={data?.bcotcost} attach={attachDataCount?.[83]} onClick={onChangeIndicator} typename={getTitle(83, data?.bcotcost)} />
                    </div>
                )}
                {setVisibility(84) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={84} title={"ЗДМ28 - Дотоод үйл ажиллагааны зардал"} value={data?.bcinternal} attach={attachDataCount?.[84]} onClick={onChangeIndicator} typename={getTitle(84, data?.bcinternal)} />
                    </div>
                )}
                {setVisibility(85) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={85} title={"ЗДМ29 - Хангамж бараа материалын зардал"} value={data?.bcitem} attach={attachDataCount?.[85]} onClick={onChangeIndicator} typename={getTitle(85, data?.bcitem)} />
                    </div>
                )}
                {userInfo?.schooltypeid == 1 && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={91} title={"ЗДМ30 - Интернэтийн зардал"} value={data?.internet} attach={attachDataCount?.[91]} onClick={onChangeIndicator} typename={getTitle(91, data?.internet)} />
                    </div>
                )}
                {setVisibility(92) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={92} title={"ЗДМ31 - Хос хавсрах хөтөлбөр зардал"} value={data?.tuitdualout} attach={attachDataCount?.[92]} onClick={onChangeIndicator} typename={getTitle(92, data?.tuitdualout)} />
                    </div>
                )}
                {setVisibility(93) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={93} title={"ЗДМ32 - Бичиг хэргийн зардал /Захиргаа/"} value={data?.stationerY93} attach={attachDataCount?.[93]} onClick={onChangeIndicator} typename={getTitle(93, data?.stationerY93)} />
                    </div>
                )}
                {setVisibility(94) && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={94} title={"ЗДМ33 - Бичиг хэргийн зардал /Сургалт/"} value={data?.stationerY94} attach={attachDataCount?.[94]} onClick={onChangeIndicator} typename={getTitle(94, data?.stationerY94)} />
                    </div>
                )}
                {userInfo?.schooltypeid == 1 && (
                    <div style={{ marginTop: 8 }}>
                        <IndicatorItem id={95} title={"ЗДМ34 -  Багш, сургалтын ажилтны дэмжлэг зардал"} value={data?.empsup} attach={attachDataCount?.[95]} onClick={onChangeIndicator} typename={getTitle(95, data?.empsup)} />
                    </div>
                )}
            </div>
            {vPanel}
        </div>
    );
}

export default BudgetDtlIndicator;