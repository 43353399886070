import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldCheck, FieldNumber, LoadSpin } from "dw-components";
import { message } from "antd";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import { UserContext } from "../../../../Context/UserContext";

const RentSquarePanel = (props) => {

    const [data, setData] = useState({});
    const [dCat, setDCAT] = useState([]);
    const [dUnit, setDUnit] = useState([]);

    const { userInfo } = useContext(UserContext);
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const getData = async () => {

        let tDCat = [];
        let TDUnit = [];

        await APIGraphQL.post("", {
            query: `{
          sharedQuery {
            renteconomycategorys {
              key: iD
              text: nAME
            }
            getUnits{
                key: iD
                text: nAME
              }
          }
        }`
        }).then(response => {

            let tResp = response?.data?.data?.sharedQuery?.renteconomycategorys;
            let tUResp = response?.data?.data?.sharedQuery?.getUnits;
            if (tResp?.length > 0) {
                tDCat = tResp;
            }
            if (tResp?.length > 0) {
                TDUnit = tUResp;
            }
        }).catch(() => {
            setLoading(false);
        });
        setDCAT(tDCat)
        setDUnit(TDUnit)

        let tData = {};
        if (props?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                        sharedQuery {
                            getRentSquare(id: $id) {
                            iD
                            nAME
                            eCONOMYCATEGORYID
                            eCONOMYCATEGORYNAME
                            sQUARESIZE
                            uNITID
                            uNITNAME
                            iSACTIVE
                            cREATED
                            cREATEDBY
                            }
                        }
                    }`,
                variables: {
                    "id": parseInt(props?.id),
                },
            }).then((response) => {
                let tResp = response?.data?.data?.sharedQuery?.getRentSquare;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(() => {
                setLoading(false);
            });
        }
        setData(tData);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
            }
        };

    const onSave = async () => {
        let isValid = true;
        setCheckRule(true);

        let tData = { ...data }

        if (!tData?.nAME ||
            !tData?.eCONOMYCATEGORYID ||
            !tData?.sQUARESIZE ||
            !tData?.uNITID
        ) { isValid = false };

        if (isValid) {
            let tSuccess = false;
            tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
            tData.sQUARESIZE = parseFloat(tData.sQUARESIZE);

            if (!props.id) {
                tData.iD = 0;
                tData.iSACTIVE = 1;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
            }
            setLoadingSave(true);
            await APIGraphQL.post("", {
                query: `mutation ($data: RentSquareInputType) {
            sharedMutation {
              addRentSquare(data: $data) {
                iD
              }
            }
          }`,
                variables: {
                    "data": tData,
                },
            }).then((response) => {
                setLoadingSave(false);
                if (response?.data?.data?.sharedMutation?.addRentSquare?.iD) {
                    tSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            })
            if (tSuccess) {
                message.success("Амжилттай");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    };

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation DeleteRentSquare($id: Int) {
        sharedMutation {
          deleteRentSquare(id: $id)
        }
      }`,
            variables: {
                "id": parseInt(props.id),
            },
        }).then((response) => {
            if (response?.data?.data?.sharedMutation?.deleteRentSquare == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай");
                onDismiss();
            }
        });
    };

    const onDismiss = () => {
        setData({});
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    return (
        <PanelBase
            headerText={props?.id ? "Засах" : "Нэмэх"}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="medium"
            delete={props.id > 0 ? true : false}
            onDelete={onDelete}
            buttons={loadingSave ? <LoadSpin /> :
                <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} />
            }
        >
            <div>
                <div style={{ marginTop: 25 }}>
                    <FieldText required loading={loading} onChanged={fieldOnChanged} title="Түрээслүүлэх талбай" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown loading={loading} required title="Ангилал" keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" onChanged={fieldOnChanged} checkreq={checkRule} defaultValue={data.eCONOMYCATEGORYID} data={dCat} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumber loading={loading} required title="Талбайн хэмжээ" keyfield="sQUARESIZE" onChanged={fieldOnChanged} checkreq={checkRule} defaultValue={data.sQUARESIZE} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown loading={loading} required title="Хэмжих нэгж" keyfield="uNITID" keyfieldname="uNITNAME" onChanged={fieldOnChanged} checkreq={checkRule} defaultValue={data.uNITID} data={dUnit} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldCheck loading={loading} required onChanged={fieldOnChanged} title="Идэвхтэй эсэх" keyfield="iSACTIVE" defaultValue={data.iSACTIVE == 0 ? data.iSACTIVE : 1} />
                </div>
            </div>
        </PanelBase>
    );
};

export default RentSquarePanel;
