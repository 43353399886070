import React, { useState, useEffect, useContext } from "react";
import { PanelBase, ButtonDefault, FieldNumberFormat, FieldDropdown, LoadSpin, FieldCheck } from "dw-components";
import { APIGraphQL } from '../../../API/APIGraphQL';
import { UserContext } from '../../../Context/UserContext';
import FieldComboBoxMultiSelectAll from "../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";
import { FOREIGN} from "../../../Const/BudgetTuitionFeeConst";

const ChangeRatePanel = (props) => {
  const { userInfo } = useContext(UserContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dSchool, setDSchool] = useState([]);
  const [data, setData] = useState({});

  const getData = async () => {
      let tDSchool = [];
      let tData = {iD: 0, bUDGETID: props?.budgetid, iNDID: props?.indid};
      await APIGraphQL.post("", {
      query: `query ($indid: Int, $budgetid: Int) {
          sharedQuery {
            getSchoolList {
              key: iD
              text: nAME
            }
            getChangeRateByIndId(indid: $indid, budgetid: $budgetid) {
              iD
              bUDGETID
              iNDID
              iNDNAME
              cATEGORYID
              cATEGORYNAME
              rATE
              rATETYPE
              sCHOOLIDS
              cREATED
              cREATEDBY
            }
          }
        }`,
      variables: {
          "indid": parseInt(props?.indid),
          "budgetid": parseInt(props?.budgetid)
      }
      }).then(response => {
          let tResp = response?.data?.data?.sharedQuery;
          if (tResp?.getSchoolList?.length > 0) {
              tDSchool = tResp?.getSchoolList;
          }
          if (tResp?.getChangeRateByIndId?.iD) {
              tData = tResp?.getChangeRateByIndId;
              tData.sCHOOLIDS = tData?.sCHOOLIDS?.split(',').map(Number);
            }
      }).catch(() => {
          setLoading(false);
      })
      setDSchool(tDSchool);
      setData(tData);
    }

  const onCalculate = () => {
    if (data?.sCHOOLIDS) {
      data.sCHOOLIDS = data.sCHOOLIDS.filter(e => e !== 'selectAll').toString();
      data.bUDGETID = props?.budgetid;
      data.rATETYPE = data.rATETYPE ? 1 : 0;
      data.iNDID = props?.indid;
      data.cREATEDBY = userInfo?.username;
      data.categoryId = data.cATEGORYID;
      props.onChangeRate(data);
      props?.onDismiss();
    }
 }

 const fieldOnChanged = async (key, text, field, fieldname) => {
     if (field) {
     let tData = { ...data };
     tData[field] = key;
     if (fieldname) {
       tData[fieldname] = text;
     }
     setData(tData);
   }
 }

  useEffect(() => {
    getData();
  }, [props?.budgetid]);

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  return (
    <PanelBase
      headerText={props?.title + " хувиар өөрчлөх"}
      isOpen={props.isOpen}
      PanelType="small"
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Тооцох" onClick={onCalculate} />}
    >
      <div style={{ marginTop: 24 }}>
        {props?.showCategory &&
          <div>
            <FieldDropdown onChanged={fieldOnChanged} title="Ангилал" keyfield="cATEGORYID" keyfieldname="cATEGORYNAME" defaultValue={data.cATEGORYID} data={FOREIGN} />
          </div>
        }
        <div style={{ marginTop: 13 }}>
          <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} title="Сургууль" keyfield="sCHOOLIDS" defaultValue={data.sCHOOLIDS} data={dSchool} />
        </div>
        {props?.showRate && 
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Хувь" keyfield="rATE" defaultValue={data.rATE} />
          </div>
        }
        {props?.showRate && 
          <div style={{ marginTop: 13 }}>
            <FieldCheck loading={loading} required onChanged={fieldOnChanged} title="Буцаах эсэх" keyfield="rATETYPE" defaultValue={data.rATETYPE == 1 ? data.rATETYPE : 0} />
          </div>
        }
      </div>
    </PanelBase >
  );
};

export default ChangeRatePanel;