
import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, FieldText, ButtonSecondary } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const BStationeryPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {
        let tDataEdit = [...dataEdit];

        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            let nDataEdit = [];
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                nDataEdit.push({
                    iD: parseInt(tR.id ?? 0),
                    bUDGETID: parseInt(props?.id),
                    sCHOOLID: parseInt(schoolid),
                    sTATIONERYID: parseInt(tR?.stationeryid ?? 0),
                    gENINDTYPEID: parseInt(tR?.genindtypeid ?? 0),
                    gENINDTYPENAME: tR?.genindtypename,
                    sTATIONERYINDID: parseInt(tR?.stationeryindid ?? 0),
                    sTATIONERYINDNAME: tR?.stationeryindname,
                    pAPERQTY: parseFloat(tR?.paperqty ?? 0),
                    pAPERPRICE: parseFloat(tR?.paperprice ?? 0),
                    tOTALPRICE: parseFloat(tR?.totalprice ?? 0),
                    iNDID: props?.indId,
                    cREATED: tR?.cREATED ?? moment().format("YYYY.MM.DD HH:mm:SS"),
                    cREATEDBY: tR?.cREATEDBY ?? userInfo?.username,
                    qTY: parseFloat(tR?.qty ?? 0),
                    cANONPRICE: parseFloat(tR?.canonprice ?? 0),
                    cANONQTY: parseFloat(tR?.canonqty ?? 0),
                    eCOCATID: parseInt(tR?.ecocatid ?? 0),
                    eCOCATNAME: tR?.ecocatname,
                    pRINTERPRICE: parseFloat(tR?.printerprice ?? 0),
                    tOTALCANONQTY: parseFloat(tR?.totalcanonqty ?? 0),
                    tOTALPAPERQTY: parseFloat(tR?.totalpaperqty ?? 0),
                    tOTALPRINTERQTY: parseFloat(tR?.totalprinterqty ?? 0)
                });
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetStationeryInputType]) {
            //             budgetMutation {
            //                 addBudgetStationery(data: $data) {
            //                 iD
            //               }
            //             }
            //           }`,
            //     variables: {
            //         "data": nDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetStationery?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBudgetStationery?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid + "&indid=" + props?.indId, nDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        // setLoadingDownload(true);
        // let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        // let alldep = isalldep;
        // if (alldep == 0) {
        //     alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        // }
        // if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
        //     pSchoolId = 0;
        // await API.get("/api/budget/budgetResearchFeeTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
        //     'responseType': 'blob'
        // }).then(response => {
        //     FileDownload(response.data, "Бичиг хэрэг" + "." + pData?.filetype);

        // }).catch(response => {
        //     setLoadingDownload(false);
        // });
        // setLoadingDownload(false);
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/budgetStationery?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&indid=" + props?.indId).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data?.length > 0) {
                    tData = response?.data?.retData?.data;
                } else if (response?.data?.retData?.length > 0) {
                    tData = response?.data?.retData;
                }
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
            tData[i].totalpaperprice = tData[i].totalpaperqty * tData[i].paperprice;
            tData[i].totalcanonprice = tData[i].totalcanonqty * tData[i].canonprice;
            tData[i].totalprinterprice = tData[i].totalprinterqty * tData[i].printerprice;
            tData[i].totalprice = tData[i].totalpaperprice + tData[i].totalcanonprice + tData[i].totalprinterprice;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                    sharedQuery {
                        schoolByParentId(schoolid: $schoolid) {
                            key: iD
                            text: nAME
                        }
                    }
                  }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);


    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "paperprice") {
            tDataEdit[pIndex]["totalprice"] = parseFloat(((parseFloat(tDataEdit[pIndex]["totalpaperqty"] ?? 0) / 500) * pValue).toFixed(2));
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
                sharedQuery {
                  getSchoolsbyTypes(typeid: $typeid){
                    key: iD
                    text: nAME
                  }
                }
              }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);

    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText={props?.indName}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"

            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Тооцоолох" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div>
                        <Row justify="space-between">
                            <Row style={{ marginTop: 30 }}>
                                <Col justify="end">
                                    /мян.төгрөг/
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="ecocatname"
                                caption="Эдийн засгийн ангилал"
                                width={250}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.ecocatname} />
                                    );
                                }} />
                            {props?.indId == 93 &&
                                <Column
                                    dataField="stationeryindname"
                                    caption="Ангилал"
                                    width={250}
                                    alignment='center'
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.stationeryindname} />
                                        );
                                    }} />
                            }
                            <Column
                                dataField="qty"
                                caption="Хүний тоо"
                                width={100}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "qty")} defaultValue={item.data?.qty} />
                                    );
                                }} /> 
                            {props?.indId == 93 &&
                                <Column
                                    dataField="paperqty"
                                    caption="Цаасны норм"
                                    alignment='center'
                                    width={100}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "paperqty")} defaultValue={item.data?.paperqty} />
                                        );
                                    }} />
                            }   
                            <Column
                                caption="Нийт тоо"
                                alignment='center'>
                                <Column
                                    dataField="totalpaperqty"
                                    caption="Цаас /боодол/"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalpaperqty")} defaultValue={item.data?.totalpaperqty} />
                                        );
                                    }} />
                                <Column
                                    dataField="totalprinterqty"
                                    caption="Хэвлэгчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalprinterqty")} defaultValue={item.data?.totalprinterqty} />
                                        );
                                    }} />
                                <Column
                                    dataField="totalcanonqty"
                                    caption="Хувилагчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalcanonqty")} defaultValue={item.data?.totalcanonqty} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                caption="Нэгж үнэ"
                                alignment='center'>
                                <Column
                                    dataField="paperprice"
                                    caption="Цаас"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "paperprice")} defaultValue={item.data?.paperprice} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="printerprice"
                                    caption="Хэвлэгчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "printerprice")} defaultValue={item.data?.printerprice} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="canonprice"
                                    caption="Хувилагчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "canonprice")} defaultValue={item.data?.canonprice} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                caption="Нийт үнэ"
                                alignment='center'>
                                <Column
                                    dataField="totalpaperprice"
                                    caption="Цаас"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalpaperprice")} defaultValue={parseFloat(item.data?.totalpaperqty * item.data?.paperprice).toFixed(2)} fixedDecimalScale={true}  />
                                        );
                                    }} />
                                <Column
                                    dataField="totalprinterprice"
                                    caption="Хэвлэгчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalprinterprice")} defaultValue={parseFloat(item.data?.totalprinterqty * item.data?.printerprice).toFixed(2)} fixedDecimalScale={true}  />
                                        );
                                    }} />
                                <Column
                                    dataField="totalcanonprice"
                                    caption="Хувилагчийн хор"
                                    alignment='center'
                                    width={120}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalcanonprice")} defaultValue={parseFloat(item.data?.totalcanonqty * item.data?.canonprice).toFixed(2)} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                dataField="totalprice"
                                caption="Нийт"
                                minWidth={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalprice")} defaultValue={item.data?.totalpaperprice + item.data?.totalprinterprice + item.data?.totalcanonprice} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalprice"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BStationeryPanel;