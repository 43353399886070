import React, { useEffect, useState, useContext } from "react";
import { FieldDropdown, FieldNumberFormat, FieldText, Row, Col, LoadSpin, Space, PanelBase, ButtonDefault, ButtonSecondary } from "dw-components";
import { APIGraphQL } from "../../API/APIGraphQL";
import { UserContext } from "../../Context/UserContext";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, ColumnFixing, Summary, TotalItem } from "devextreme-react/data-grid";
import moment from "moment";
import { message } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { API } from "../../API/API";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const RentSquareForm = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dUnit, setDUnit] = useState([]);
    const [dRentSqueare, setDRentSquare] = useState([]);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);

        setLoadingSave(true);

        let vSuccess = false;

        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.bUDGETID = parseInt(props?.id);

                tR.sCHOOLNAME = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                }
                tR.sCHOOLID = parseInt(currentschoolid);
                tR.iD = tR.id;
                tR.rENTSQUAREID = tR.rentsquareid;
                tR.rENTSQUARENAME = tR.rentsquarename;
                tR.rENTERNAME = tR.rentername;
                tR.sQUARESIZE = tR.squaresize;
                tR.uNITID = tR.unitid;
                tR.uNITNAME = tR.unitname;
                tR.uNITPRICE = tR.unitprice;
                tR.dURATION = tR.duration;
                tR.nOTE = tR.note;
                tR.tUBZG = tR.tubzg;
                tR.tOTALPRICE = tR.totalprice;
                   
                delete tR.arrayIndex;
                delete tR.edit;
                delete tR.id;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.schoolname;
                delete tR.rentsquareid;
                delete tR.rentsquarename;
                delete tR.rentername;
                delete tR.squaresize;
                delete tR.unitid;
                delete tR.unitname;
                delete tR.unitprice;
                delete tR.duration;
                delete tR.note;
                delete tR.created;
                delete tR.createdby;
                delete tR.totalprice;
                delete tR.tubzg;
                delete tR.depcode;
                delete tR.schoolcode;
                delete tR.year;

                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;
                tDataEdit[i] = tR;
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetRentSquareInputType]) {
            //         sharedMutation {
            //             addBudgetRentSquare(data: $data) {
            //                 iD
            //             }
            //         }
            //     }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.sharedMutation?.addBudgetRentSquare?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBRentSquare?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
        }
        //vSuccess = await calcfunc("calcBRentSquare", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
        setLoadingSave(false);
        await props.afterSave();
        props?.onDismiss();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDRentSquare = [];
        let tDUnit = [];

        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/getbudgetRentSquare?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });


        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  getRentSquareList {
                    key: iD
                    text: nAME
                    value: sQUARESIZE
                  }
                  getUnits {
                    key: iD
                    text: nAME
                  }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getRentSquareList?.length > 0) {
                tDRentSquare = tResp?.sharedQuery?.getRentSquareList;
            }
            if (tResp?.sharedQuery?.getUnits?.length > 0) {
                tDUnit = tResp?.sharedQuery?.getUnits;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);

        setLoading(false);
        setDRentSquare(tDRentSquare);
        setDUnit(tDUnit);
        setData(tData);
        setDataEdit(tData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {

        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "rentsquareid") {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                      getRentSquare(id: $id) {
                        unitname: uNITNAME
                        squaresize: sQUARESIZE
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getRentSquare;
                if (tResp) {
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                    setData(tDataEdit);
                }
            }).catch(() => {
                setLoading(false);
            });
        } else if (pField == "unitprice") {
            tDataEdit[pIndex]["totalprice"] = pValue * (tDataEdit[pIndex]["duration"] || 0) * (tDataEdit[pIndex]["squaresize"] || 0);
            tDataEdit[pIndex]["tubzg"] = (pValue * (tDataEdit[pIndex]["duration"] || 0) * (tDataEdit[pIndex]["squaresize"] || 0)) * 0.4;
        } else if (pField == "duration") {
            tDataEdit[pIndex]["totalprice"] = (tDataEdit[pIndex]["unitprice"] || 0) * pValue * (tDataEdit[pIndex]["squaresize"] || 0);
            tDataEdit[pIndex]["tubzg"] = (pValue * (tDataEdit[pIndex]["unitprice"] || 0) * (tDataEdit[pIndex]["squaresize"] || 0)) * 0.4;
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    sharedMutation {
                      delBudgetRentSquare(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.sharedMutation?.delBudgetRentSquare == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBRentSquare?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
            }
             //vSuccess = await calcfunc("calcBRentSquare", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
           }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }

    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Түрээсийн орлого тооцох"
            isOpen={props.isOpen}
            PanelType="large"
            onDismiss={props.onDismiss}
            buttons={!disable && (<div> {loadingSave ? (<LoadSpin />) : (<ButtonDefault text="Хадгалах" onClick={onSave} />)}</div>)}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөг/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders>
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} fixed={true} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                width={250} dataField="rentsquarename" caption="Түрээслүүлэх талбай /хаяг/" fixed={true} alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown
                                            disable={disable}
                                            onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "rentsquareid", pNameValue, "rentsquarename")}
                                            defaultValue={item.data?.rentsquareid}
                                            data={dRentSqueare} />
                                    );
                                }}
                            >
                            </Column>
                            <Column
                                width={250} dataField="rentername" caption="Түрээслэгч" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText
                                            disable={disable}
                                            onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "rentername")}
                                            defaultValue={item.data?.rentername} />
                                    );
                                }}
                            />
                            <Column
                                width={125} dataField="squaresize" caption="Талбайн хэмжээ" alignment='center' format="fixedPoint" data={dRentSqueare}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={() => { }} defaultValue={item.data?.squaresize} />
                                    );
                                }}
                            />
                            <Column
                                width={120} dataField="unitname" caption="Хэмжих нэгж" alignment='center' format="fixedPoint" data={dRentSqueare}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.unitname} />
                                    );
                                }}
                            />
                            <Column
                                width={150} dataField="unitprice" caption="Нэгж талбайн үнэ(₮)" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat
                                            disable={disable}
                                            onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "unitprice")}
                                            defaultValue={item.data?.unitprice} fixedDecimalScale={true} />
                                    );
                                }}
                            />
                            <Column
                                width={120} dataField="duration" caption="Хугацаа /сар/" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat
                                            disable={disable}
                                            onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "duration")}
                                            defaultValue={item.data?.duration} fixedDecimalScale={true} />
                                    );
                                }}
                            />
                            <Column
                                width={120} dataField="totalprice" caption="Нийт үнийн дүн" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalprice")} defaultValue={item.data?.totalprice} fixedDecimalScale={true} />
                                    );
                                }}
                            />
                            <Column
                                width={120} dataField="tubzg" caption="Үүнээс ТӨБЗГ-т" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "tubzg")} defaultValue={item.data?.tubzg} fixedDecimalScale={true} />
                                    );
                                }}
                            />
                            <Column
                                minwidth={250} dataField="note" caption="Тайлбар" alignment='center'
                                cellRender={(item) => {
                                    if(!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.note} </div>
                                        );
                                    }
                                }}
                            />
                            {!disable &&
                                <Column
                                    dataField="action"
                                    caption=""
                                    width={40}
                                    cellRender={(item) => {
                                        return (
                                            <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                                <Icon iconName="Delete" />
                                            </div>
                                        );
                                    }} />
                            }
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalprice"
                                    summaryType="sum"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                                <TotalItem
                                    column="tubzg"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase>
    )
}

export default RentSquareForm;
