import React, { useState, useEffect, useContext } from "react";
import {PageTitle, ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdownMulti, ButtonSecondary, FieldDropdown } from "dw-components";
import { message, PageHeader,  Button, Row, Col, Space, Modal } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../../API/API";
import { APIGraphQL } from "../../../API/APIGraphQL";
import moment from "moment";
import { UserContext } from "../../../Context/UserContext";
import { Icon } from '@fluentui/react/lib/Icon';

const BuildingInvestment = (props) => {

    const [dataEdit, setDataEdit] = useState([]);
    const [data, setData] = useState([]);
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [year, setYearData] = useState(0);
    const [dEndYear, setEndYearData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [dBuilding, setDBuilding] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const { userInfo } = useContext(UserContext);

  
    const onSave = async () => {

        let tDataEdit = dataEdit;
        if (tDataEdit?.length > 0 ) {
            setLoadingSave(true);
            let vSuccess = false;
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < years.length; j++)
                {
                    let tRNew = {};
                    tRNew.bUILDINGID = tR.buildingid;
                    tRNew.bUILDINGNAME = tR.buildingname;
                    tRNew.aMOUNT = tR[years[j].key];
                    tRNew.yEAR = years[j].key;
                    tRNew.iD = 0;
                    tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                    tRNew.cREATEDBY = userInfo?.username;
                    tBSecDtl[k] = tRNew;
                    k += 1;
                }
            }
            await APIGraphQL.post("", {
                query: `mutation ($data: [BuildingInvestmentInputType]) {
                    sharedMutation {
                        addBuildingInvestment(data: $data) {
                            iD
                        }
                    }
                }`,
                variables: {
                    "data": tBSecDtl
                }
            }).then(response => {
                if (response?.data?.data?.sharedMutation?.addBuildingInvestment?.length > 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
            getData();
        } 

    }
    
    const getData = async () => {
        let tData = [];
        let tYears = [];
        let tDBuilding = [];
        setLoading(true);
        await API.get("/api/budget/getBuildInvestConfig")
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data.length > 0) {
                    tData = response?.data?.retData?.data;
                }
                if (response?.data?.retData?.years.length > 0) {
                    tYears = response?.data?.retData?.years;
                }
            }
        }).catch(() => {
            setLoading(false);
        });
        await APIGraphQL.post("", {
            query: `{
            sharedQuery {
              getBuildingListAll {
                key: iD
                text: nAME
              }
            }
          }`
          }).then(response => {
      
            let tCResp = response?.data?.data?.sharedQuery?.getBuildingListAll;
            if (tCResp) {
              tDBuilding = tCResp;
            }
          }).catch(() => {
            setLoading(false);
          });
        setLoading(true);

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setData(tData);
        setDataEdit(tData);
        setDBuilding(tDBuilding);
        setYears(tYears);
    }


    useEffect(() => {
        getData();
    }, [props?.id, props?.disable]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
   
    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation deleteBuildingInvestment($id: Int) {
                    sharedMutation {
                        deleteBuildingInvestment(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { });
            message.success("Амжилттай");
        }

        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    
    return (
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
        <div style={{ padding: 6, backgroundColor: "#ffffff", height: 36, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
              <Row>
                  <Col span={21}>
                    <div style={{ marginLeft: 0,  }}>
                        <PageTitle>Барилгын дунд хугацааны хөрөнгө оруулалт</PageTitle>
                    </div>
                   </Col>
                </Row>
               </div>
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
            <div style={{ padding: 6, backgroundColor: "#ffffff", height: 48, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                  <div style={{ marginRight: 8 }}>
                      <Row gutter={16} justify="space-between">
                          <Col>
                              <Row gutter={30} justify="start">
                                  <Col>
                                        <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                                  </Col>
                              </Row>
                          </Col>
                        <Col>
                            <Row gutter={16} justify="end">
                                <Space>
                                      <ButtonDefault text="Хадгалах" onClick={() => onSave()}/>
                                </Space>
                            </Row>
                        </Col>
                      </Row>
                  </div>
          </div>
          <div style={{ marginTop: 0, }}>
              <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
              >
                <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                    <Column width={50} caption="№" alignment='center' allowResizing={true} fixed={true} 
                        cellRender={(item) => {
                            return (
                                <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                            );
                        }}
                    ></Column>
                  <Column
                      minWidth={500}
                      keyfield="bUILDINGID"
                      dataField="bUILDINGNAME"
                      caption="Барилгын нэр"
                      alignment='left'
                      width={200}
                      format="fixedPoint"
                      fixed={true} 
                      cellRender={(item) => {
                          return (
                              <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "buildingid", pNameValue, "buildingname")} defaultValue={item.data?.buildingid} data={dBuilding} />
                          );
                      }} />
                  <Column
                      dataField="total"
                      caption="Нийт хөрөнгө оруулалт"
                      alignment={"center"}
                      minWidth={120}
                      fixed={true} 
                      cellRender={item => {
                          return (
                                <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "total")} defaultValue={item.data?.total}/>
                            );
                      }} />
                  {years?.map(r => {
                      return (
                              <Column
                                  key={r.key}
                                  caption={r.text} 
                                  alignment={"center"}
                                  dataField={r.key?.toString()}
                                  width={150}
                                  format={"fixedPoint"}
                                  cellRender={item => {
                                      return <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, r.key)} defaultValue={item.data?.[r.key]} />
                                  }}
                              />
                              
                      );
                  })
                  }
                  <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
              </DataGrid>
                  </div>
                  </div>
                  </div>
      );
    
  }

export default BuildingInvestment;