import React, { useContext, useState, useEffect } from "react";
import { ButtonDefault, FieldDate, FieldDropdown, PanelBase, LoadSpin, FieldNumberFormat, FieldText, FieldNumber } from "dw-components";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { month2digits } from "../../../../Utils/SharedFunc";
import moment from "moment";

const OperationHeatPanel = (props) => {

  const { userInfo } = useContext(UserContext);

  const [dataHeader, setDataHeader] = useState({});
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);

  const [userCode, setUserCode] = useState([]);
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState(false);

  const [prevData, setPrevData] = useState([]);
  const currentyear = new Date(!props?.date ? new Date() : props?.date).getFullYear().toString();
  const dCostDate = [
    { key: 1, text: currentyear + ".01" },
    { key: 2, text: currentyear + ".02" },
    { key: 3, text: currentyear + ".03" },
    { key: 4, text: currentyear + ".04" },
    { key: 5, text: currentyear + ".05" },
    { key: 6, text: currentyear + ".06" },
    { key: 7, text: currentyear + ".07" },
    { key: 8, text: currentyear + ".08" },
    { key: 9, text: currentyear + ".09" },
    { key: 10, text: currentyear + ".10" },
    { key: 11, text: currentyear + ".11" },
    { key: 12, text: currentyear + ".12" }
  ];

  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    let tData = [];
    let tUserCode = [];
    let tDataH = {};
    let tDataHeader = {
      dATE: !props?.date ? new Date() : props?.date,
      cOSTDATE: +(props?.costdate?.toString().substring(5, 7)),
      uSERCODE: props?.usercodeid,
    };

    setLoading(true);
    await APIGraphQL.post("", {
      query: `{
            sharedQuery {
              usercodelist {
                key: iD
                text: cODE
              }
            }
          }
          `
    }).then(response => {
      if (response?.data?.data?.sharedQuery?.usercodelist?.length > 0) {
        tUserCode = response?.data?.data?.sharedQuery?.usercodelist;
      }
    });

    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String) {
        sharedQuery {
          heatcostbyusercode(usercodeid: $usercodeid, costdate: $costdate) {
            iD
            uSERCODEID
            uSERCODE
            aMOUNT
            bUILDINGID
            bUILDINGNAME
            cOSTDATE
            cREATED
            cREATEDBY
            dATE
            mETERID
            mETERNO
            tARIFF
            tOTALAMOUNT
            uSE
            qTY
            vAT
          }
          heatdtlcostbyusercode(usercodeid: $usercodeid, costdate: $costdate) {
            iD
            aDDCOST
            oTHERCOST
            eXCLUDEDVAT
            vAT
            iNCLUDEDVAT
          }
        }
      }`,
      variables: {
        "usercodeid": parseInt(tDataHeader?.uSERCODE),
        "costdate": props?.costdate?.toString()
      }
    }).then(response => {
      if (response?.data?.data?.sharedQuery?.heatcostbyusercode?.length > 0) {
        tData = response?.data?.data?.sharedQuery?.heatcostbyusercode;
      }

      if (response?.data?.data?.sharedQuery?.heatdtlcostbyusercode) {
        tDataH = response?.data?.data?.sharedQuery?.heatdtlcostbyusercode;
      }
    });
    tDataHeader = { ...tDataHeader, ...tDataH };
    for (let i = 0; i < tData?.length; i++) {
      tData[i].arrayIndex = i;
    }
    
    setLoading(false);

    setData(tData);
    setDataEdit(tData);

    setUserCode(tUserCode);
    setDataHeader(tDataHeader);

    setCheckRule(false);

    setPreviousData(tDataHeader?.cOSTDATE);
  }

  useEffect(() => {
    getData();
  }, [props?.usercodeid, props?.costdate, props?.date]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = [...data];
      let tDataHeader = { ...dataHeader };

      tDataHeader[field] = key;
      if (fieldname) {
        tDataHeader[fieldname] = text;
      }

      if (field == "aDDCOST" || field == "oTHERCOST") {
        let tTotal=0;
      data?.map(r => {
          tTotal=tTotal +r.tOTALAMOUNT
      });
        tDataHeader["eXCLUDEDVAT"] = ((tDataHeader["oTHERCOST"] ?? 0) + (tDataHeader["aDDCOST"] ?? 0)+tTotal);
      }
      if (tDataHeader["eXCLUDEDVAT"]) {
        tDataHeader["vAT"] = ((tDataHeader["eXCLUDEDVAT"] ?? 0) / 100 * 10);
      }
      if (tDataHeader["vAT"]) {
        tDataHeader["iNCLUDEDVAT"] = ((tDataHeader["eXCLUDEDVAT"] ?? 0) + (tDataHeader["vAT"] ?? 0));
      }

      if (field == "uSERCODE") {
        await APIGraphQL.post("", {
          query: `query ($usercodeid: Int){
                sharedQuery {
                  meterbyusercodeid(usercodeid: $usercodeid, typeid: 1) {
                    mETERID: iD
                    mETERNO
                    bUILDINGID
                    bUILDINGNAME
                    tARIFF
                    uSERCODEID
                    uSERCODE
                  }
                }
              }
              `,
          variables: {
            "usercodeid": key
          }
        }).then(response => {
          if (response?.data?.data?.sharedQuery?.meterbyusercodeid?.length > 0) {
            tData = response?.data?.data?.sharedQuery?.meterbyusercodeid;
          }
        });

        for (let i = 0; i < tData?.length; i++) {
          tData[i].arrayIndex = i;
        }

        setData(tData);
        setDataEdit(tData);

      } else if (field == "cOSTDATE") {
        setPreviousData(text);
      }

      setDataHeader(tDataHeader);
    }
  }

  const gridfieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
    let tDataEdit = [...dataEdit];
    tDataEdit[pIndex][pField] = pValue;
    if (pFieldName) {
      tDataEdit[pIndex][pFieldName] = pNameValue;
    }

    if (pField == "qTY") {

      let prevQty = 0;

      if (prevData?.length > 0) {
        let tIndex = prevData?.map(r => r.mETERID).indexOf(tDataEdit[pIndex]["mETERID"]);
        if (tIndex >= 0)
          prevQty = prevData[tIndex].qTY;
      }
      let tUSE = pValue - prevQty;
      tDataEdit[pIndex]["uSE"] = parseFloat((tUSE + (tUSE * 0.05)).toFixed(2));
      tDataEdit[pIndex]["tOTALAMOUNT"] = (tDataEdit[pIndex]["uSE"] * tDataEdit[pIndex]["tARIFF"]);

    }
    tDataEdit[pIndex].edit = true;
    setDataEdit(tDataEdit);
  }

  const setPreviousData = async (text) => {
    let currentmonth = text + ".01";
    let currentdate = new Date(currentmonth);
    let lastmonth = month2digits(currentdate.getMonth());
    var costdate = currentdate.getFullYear() + "." + lastmonth;
    let tPrevData = [];

    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String){
          sharedQuery {
            heatcostbydate(usercodeid: $usercodeid, costdate: $costdate) {
              qTY
              dATE
              mETERID
            }
          }
        }
        `,
      variables: {
        "usercodeid": dataHeader?.uSERCODE,
        "costdate": costdate?.toString()
      }
    }).then(response => {
      if (response?.data?.data?.sharedQuery?.heatcostbydate?.length > 0) {
        tPrevData = response?.data?.data?.sharedQuery?.heatcostbydate;
      }
    });
    setPrevData(tPrevData);
  }

  const onSave = async () => {

    let vSuccess = true;
    let vRule = true;
    let tDataHeader = { ...dataHeader };
    setCheckRule(true);

    if (!dataHeader?.uSERCODE
      || !dataHeader?.cOSTDATE
      || !dataHeader?.dATE) {
      vRule = false;
    }

    if (vRule) {
      setLoadingSave(true);

      tDataHeader.uSERCODEID = parseInt(tDataHeader.uSERCODE ?? 0);

      let tIndex = dCostDate?.map(r => r?.key).indexOf(tDataHeader.cOSTDATE);
      tDataHeader.cOSTDATE = tIndex >= 0 ? dCostDate[tIndex].text?.toString() : "";

      tDataHeader.cREATED = tDataHeader.cREATED ?? moment().format("YYYY.MM.DD HH:mm:SS");
      tDataHeader.cREATEDBY = tDataHeader.cREATEDBY ?? userInfo?.username;

      delete tDataHeader.dATE;
      delete tDataHeader.uSERCODE;

      await APIGraphQL.post("", {
        query: `mutation ($data: HeatDetailCostInputType) {
          sharedMutation {
              addHeatDetailCost(data: $data) {
                  iD
              }
          }
      }`,
        variables: {
          "data": tDataHeader
        }
      }).then(response => {
        if (response?.data?.data?.sharedMutation?.addHeatDetailCost?.length > 0) {
          message.success("Амжилттай");
        }
      }).catch(() => {
        setLoadingSave(false);
        vSuccess = false;
      });

      if (dataEdit?.length > 0) {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0) {
          for (let i = 0; i < tDataEdit.length; i++) {
            let tR = { ...tDataEdit[i] };
            if (!isNaN(parseFloat(tR.tOTALMOUNT))) {
              tR.vAT = tR.tOTALMOUNT / 10;
              tR.aMOUNT = tR.tOTALMOUNT - tR.vAT;
            }
            tR.dATE = tDataHeader?.dATE;
            tR.cOSTDATE = tDataHeader?.cOSTDATE;
            delete tR.arrayIndex;
            delete tR.edit;
            if (!tR.iD) {
              tR.iD = 0;
              tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
              tR.cREATEDBY = userInfo?.username;
            }
            tDataEdit[i] = tR;
          }

          await APIGraphQL.post("", {
            query: `mutation ($data: [HeatCostInputType]) {
              sharedMutation {
                  addHeatCost(data: $data) {
                      iD
                  }
              }
          }`,
            variables: {
              "data": tDataEdit
            }
          }).then(response => {
            if (response?.data?.data?.sharedMutation?.addHeatCost?.length > 0) {
              // message.success("Амжилттай");
            }
          }).catch(() => {
            setLoadingSave(false);
            vSuccess = false;
          });
        }
      }

      setLoadingSave(false);

      if (vSuccess) {
        await props?.afterSave();
        props?.onDismiss();
      }
    }
  }

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    setCheckRule(false);
    setData([]);
    setDataHeader({});
  }

  const onDelete = async () => {
    await APIGraphQL.post("", {
      query: `mutation deleteHeatCost($usercodeid: Int, $costdate: String) {
                  sharedMutation {
                    deleteHeatCost(usercodeid: $usercodeid, costdate: $costdate)
                  }
                }`,
      variables: {
        "usercodeid": parseInt(dataHeader?.uSERCODE),
        "costdate": props?.costdate.toString()
      },
    }).then((response) => {
      if (response?.data?.data?.sharedMutation?.deleteHeatCost == 1) {
        message.success("Амжилттай");
        if (props.afterSave) {
          props.afterSave();
        }
        onDismiss();
      }
    });
  };

  return (
    <PanelBase
      headerText="Түлш халаалтын мэдээлэл оруулах"
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      warning={warning}
      PanelType="medium"
      delete={dataHeader?.uSERCODE > 0 ? true : false}
      onDelete={onDelete}
      buttons={
        loadingSave ? <LoadSpin /> :
          <ButtonDefault
            text="Хадгалах"
            onClick={onSave}
          />
      }
    >
      <div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Хэрэглэгчийн код" keyfield="uSERCODE" defaultValue={dataHeader?.uSERCODE} checkreq={checkRule} data={userCode} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDate required onChanged={fieldOnChanged} title="Заалт авсан огноо" keyfield="dATE" checkreq={checkRule} defaultValue={dataHeader?.dATE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Заалт оруулах сар" keyfield="cOSTDATE" defaultValue={dataHeader?.cOSTDATE} checkreq={checkRule} data={dCostDate} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat onChanged={fieldOnChanged} title="Нэмэлт зардал" keyfield="aDDCOST" defaultValue={dataHeader?.aDDCOST} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat onChanged={fieldOnChanged} title="Бусад зардал" keyfield="oTHERCOST" defaultValue={dataHeader?.oTHERCOST} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat disable={true} onChanged={fieldOnChanged} title="Татварын өмнөх дүн" keyfield="eXCLUDEDVAT" defaultValue={dataHeader?.eXCLUDEDVAT} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat disable={true} onChanged={fieldOnChanged} title="НӨАТ" keyfield="vAT" defaultValue={dataHeader?.vAT} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat disable={true} onChanged={fieldOnChanged} title="Дүн" keyfield="iNCLUDEDVAT" defaultValue={dataHeader?.iNCLUDEDVAT} checkreq={checkRule} />
        </div>
        {loading ? (<LoadSpin />) : (
          <div style={{ marginTop: 24 }}>
            <DataGrid
              dataSource={data}
              columnAutoWidth={true}
              wordWrapEnabled={true}
              showRowLines
              showBorders
            >
              <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
              <Column width={50} caption="№" alignment='center' allowResizing={true}
                cellRender={(item) => {
                  return (
                    <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                  );
                }}
              ></Column>
              <Column
                width={150}
                dataField="bUILDINGNAME"
                caption="Обьект"
                alignment='center'
                cellRender={(item) => {
                  return (
                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.bUILDINGNAME} />
                  );
                }} />
              <Column
                width={100}
                dataField="mETERNO"
                caption="Хэмжүүрийн дугаар"
                alignment='center'
                cellRender={(item) => {
                  return (
                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.mETERNO} />
                  );
                }} />
              <Column
                dataField="qTY"
                caption="Заалт"
                alignment='center'
                width={80}
                format="fixedPoint"
                cellRender={(item) => {
                  return (
                    <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "qTY")} defaultValue={item.data?.qTY} />
                  );
                }} />
              <Column
                dataField="uSE"
                caption="Нийт дулаан"
                width={80}
                alignment='center'
                format="fixedPoint"
                cellRender={(item) => {
                  return (
                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.uSE} />
                  );
                }} />
              <Column
                dataField="tOTALAMOUNT"
                caption="НӨАТ-гүй дүн"
                minwidth={80}
                alignment='center'
                format="fixedPoint"
                cellRender={(item) => {
                  return (
                    <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.tOTALAMOUNT} />
                  );
                }} />
              <Summary>
                <TotalItem
                  column="№"
                  displayFormat="Нийт:"
                />
                <TotalItem
                  column="tOTALAMOUNT"
                  summaryType="sum"
                  valueFormat="#,##0.00"
                  alignment="right"
                  displayFormat="{0}"
                />
              </Summary>
            </DataGrid>
          </div>
        )}
      </div>
    </PanelBase>
  );
}

export default OperationHeatPanel;
