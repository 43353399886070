const POSITIONCATEGORY = [
  { key: 1, text: "БАГШ" },
  { key: 2, text: "УДИРДАХ АЛБАН ТУШААЛТАН" },
  { key: 3, text: "СУРГАЛТЫН ҮЙЛЧИЛГЭЭНИЙ АЖИЛТАН" },
  { key: 4, text: "ТЭНХИМИЙН ТУСЛАХ АЖИЛТАН"},
  { key: 5, text: "МЭРГЭЖЛИЙН АЛБАН ХААГЧ"},
  { key: 6, text: "ТЕХНИКИЙН БОЛОН ГҮЙЦЭТГЭХ АЛБАН ХААГЧ"},
];


export default POSITIONCATEGORY;