export const VehicleFuelType = [
    {
        key: 1,
        text: "А-80"
    },
    {
        key: 2,
        text: "АИ-92"
    },
    {
        key: 3,
        text: "АИ-95"
    },
    {
        key: 4,
        text: "АИ-98"
    },
    {
        key: 5,
        text: "Дизель түлш"
    },
    {
        key: 6,
        text: "LPG"
    }
];