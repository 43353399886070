import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../../../Context/UserContext";
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin, FieldText, FieldTextArea, FieldNumberFormat, showLoadScreen } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../../API/APIGraphQL";
import { URLTYPE } from "../../../../../Const/PurchaseInvestment";

const InvestmentPanel = (props) => {
    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState({});
    const [checkRule, setCheckRule] = useState(false);
    const [warning, setWarning] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dPItem, setDPItem] = useState([]);
    const [dPurInv, setDPurInv] = useState([]);
    const [disableSchool, setDisableSchool] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [dBudgetYears, setBudgetYears] = useState([]);

    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);

    const isMovable = [
        {
            key: 1,
            text: "Тийм",
        },
        {
            key: 2,
            text: "Үгүй",
        },
    ]

    const fieldOnChanged = async (key, text, field, fieldName) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;

            if (fieldName) {
                tData[fieldName] = text;
            }

            if (field == "pASTYEARC2PRICE" && tData.pASTYEARC2PRICE == 0) {
                tData.cURRENTYEARPRICE = tData.tOTALBUDGET
            }
            else if (field == "pASTYEARC2PRICE") {
                tData.cURRENTYEARPRICE = tData.tOTALBUDGET - key
            };

            if (field == "sPTOPPRID") {
                tData.sPGOALID = null;
                tData.sPINDID = null;
                tData.sPSTRID = null;
            }

            if (field == "sPGOALID") {
                tData.sPINDID = null;
                tData.sPSTRID = null;
            }

            if (field == "sPSTRID") {
                tData.sPINDID = null;
            }

            if (field == "qTY") {
                tData.tOTALBUDGET = tData.uNITPRICE * key || 0;
                if (tData.iSMOVABLE == 1) {
                    if (tData.tOTALBUDGET != 0 && tData.pASTYEARC2PRICE != 0) {
                        tData.cURRENTYEARPRICE = tData.tOTALBUDGET - tData.pASTYEARC2PRICE
                    } else {
                        tData.cURRENTYEARPRICE = 0
                    }
                } else if (tData.iSMOVABLE == 2) {
                    tData.cURRENTYEARPRICE = tData.tOTALBUDGET || 0
                }
            };

            if (field == "pURINVID") {
                let tItem = [];
                await APIGraphQL.post("", {
                    query: `query ($purinvid: Int) {
                    sharedQuery {
                      getPurchaseItemByEC(purinvid: $purinvid) {
                        key: iD
                        text: nAME
                      }
                    }
                  }`,
                    variables: {
                        "purinvid": parseInt(key)
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.getPurchaseItemByEC;
                    if (tResp) {
                        tItem = tResp;
                    }
                }).catch(() => {
                    setLoading(false);
                });
                setDPItem(tItem);
            }
            setData(tData);

            if (field == "iNVESTMENTITEMID") {
                await APIGraphQL.post("", {
                    query: `query ($investmentitemid: Int) {
            sharedQuery {
              getPurchaseItem(id: $investmentitemid) {                        
                pURINVID
                pURINVNAME
                uNITID
                uNITNAME
                uNITPRICE
              }
            }
          }`,
                    variables: {
                        "investmentitemid": parseInt(key)
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.getPurchaseItem;
                    if (tResp) {
                        tData = { ...tData, ...tResp };
                    }
                }).catch(() => {
                    setLoading(false);
                });
            }

            setData(tData);
        }
    };
    const getDepartments = async (schoolId) => {
        setLoading(true);
        let tDepartments = [];
        await APIGraphQL.post("", {
            query: `query ($id: Int) {
              sharedQuery {
                getSchoolDepList (id: $id) {
                    key: iD
                    text: nAME
                }
              }
            }`,
            variables: {
                "id": schoolId,
            }
        }).then((response) => {
            let tDResp = response?.data?.data?.sharedQuery?.getSchoolDepList;

            if (tDResp?.length > 0) {
                tDepartments = tDResp;
            }
        })
            .catch(() => {
                setLoading(false);
            });

        setDepartments(tDepartments);
        setLoading(false);
    }

    const getData = async () => {
        setLoading(true)
        let tDPurInv = [];
        let tYears = [];
        await getDepartments(userInfo?.schoolid);
        await APIGraphQL.post("", {
            query: `query {
              sharedQuery {
                getPurchaseItemEC {
                    key: pURINVID
                    text: pURINVNAME
                }
                getBudgetYearList {
                  key: yEAR
                  text: yEAR
                }
              }
            }`,
            variables: {
            }
        }).then((response) => {
            let tPIResp = response?.data?.data?.sharedQuery?.getPurchaseItemEC;
            if (tPIResp?.length > 0) {
                tDPurInv = tPIResp;
            }

            let tResp = response?.data?.data?.sharedQuery?.getBudgetYearList;
            if (tResp?.length > 0) {
                tYears = tResp;
            }
        })
            .catch(() => {
                setLoading(false);
            });

        setDPurInv(tDPurInv);
        setBudgetYears(tYears);

        let tData = {};
        if (props?.id) {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                sharedQuery {
                  getBudgetInvestment(id: $id) {
                    iD
                    iNVESTMENTITEMID
                    iNVESTMENTITEMNAME
                    qTY
                    uNITID
                    uNITNAME
                    uNITPRICE
                    sTARTEDYEAR
                    pASTYEARC2PRICE
                    cURRENTYEARPRICE
                    pERPERCENT
                    iSMOVABLE
                    nOTE
                    bUDGETID
                    sCHOOLID
                    sCHOOLNAME
                    pURINVID
                    pURINVNAME
                    cREATED
                    cREATEDBY
                    tOTALBUDGET
                    dEPARTMENTID
                    dEPARTMENTNAME
                    uNIVERSITYID
                    uNIVERSITYNAME
                    bUDGETPURINVID
                    dEPARTMENTPRICE
                    sCHOOLPRICE
                    uNIVERSITYPRICE
                    kPIID
                    kPINAME
                    oUTCOME
                    oUTPUT
                    iMPACT
                    sPGOALID
                    sPGOALNAME
                    sPINDID
                    sPINDNAME
                    sPSTRID
                    sPSTRNAME
                    sPTOPPRID
                    sPTOPPRNAME
                  }
                }
              }`,
                variables: {
                    "id": parseInt(props?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getBudgetInvestment;
                if (tResp?.iD) {
                    tData = tResp;
                    setDisableSchool(true);
                }
            }).catch(() => {
                setLoading(false);
            });
        }

        setData({ ...tData, sCHOOLID: userInfo?.schoolid, sCHOOLNAME: userInfo?.schoolname });
        setLoading(false)
    };

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    useEffect(() => {
        setDisableSchool(false)
        getData();
        getSPlan();
    }, [props?.id]);

    const onSave = async () => {
        let vRule = true;
        setCheckRule(true);

        let tData = { ...data };

        if (!tData?.iNVESTMENTITEMNAME ||
            // !tData?.cURRENTYEARPRICE ||
            // !tData?.tOTALBUDGET ||
            !tData?.uNITID ||
            !tData?.sCHOOLID ||
            !tData?.sCHOOLNAME ||
            !tData?.iSMOVABLE) {
            vRule = false
        };

        if (userInfo?.schooltypeid != 5 && !tData.dEPARTMENTID) {
            vRule = false;
        }

        if (!tData?.pERPERCENT)
            tData.pERPERCENT = 0;

        if (vRule) {
            if (tData?.qTY == null || tData?.qTY == undefined) {
                tData.qTY = 0;
            }

            if (tData?.tOTALBUDGET == null || tData?.tOTALBUDGET == undefined) {
                tData.tOTALBUDGET = 0;
            }

            if (userInfo?.schooltypeid == 5) {
                tData.dEPARTMENTID = tData.sCHOOLID;
                tData.dEPARTMENTNAME = tData.sCHOOLNAME;
            }
            let tSuccess = false;
            if (!props.id) {
                tData.iD = 0;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
                tData.uNIVERSITYID = 1;
                tData.uNIVERSITYNAME = "Монгол улсын их сургууль"

                if (props?.type && props.type == URLTYPE.BUDGET) {
                    tData.bUDGETID = parseInt(props?.itemId);
                    tData.bUDGETPURINVID = props?.secondaryId
                } else if (props.type && props.type == URLTYPE.PURCHASEINVESTMENT) {
                    tData.bUDGETPURINVID = parseInt(props?.itemId);
                    tData.bUDGETID = props?.secondaryId
                }
            }

            tData.sCHOOLPRICE = tData.cURRENTYEARPRICE;
            tData.uNIVERSITYPRICE = tData.cURRENTYEARPRICE;

            tData.sPGOALNAME = splan.find(x => x.key == tData.sPGOALID)?.text;
            tData.sPINDNAME = splan.find(x => x.key == tData.sPINDID)?.text;
            tData.sPSTRNAME = splan.find(x => x.key == tData.sPSTRID)?.text;
            tData.sPTOPPRNAME = splan.find(x => x.key == tData.sPTOPPRID)?.text;
            tData.kPINAME = kpis.find(x => x.key == tData.kPIID)?.text;

            setLoadingSave(true);
            await APIGraphQL.post("", {
                query: `mutation($data: BudgetInvestmentInputType) {
                    sharedMutation {
                      addBudgetInvestment(data: $data) {
                        iD
                      }
                    }
                  }`,
                variables: {
                    data: tData,
                },
            }).then((response) => {
                setLoadingSave(false);
                if (response?.data?.data?.sharedMutation?.addBudgetInvestment?.iD) {
                    tSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    };

    const onDismiss = () => {
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteBudgetInvestment($id: Int) {
            sharedMutation {
                deleteBudgetInvestment(id: $id)
            }
          }
          `,
            variables: {
                "id": parseInt(props.id)
            }
        }).then(response => {
            if (response?.data?.data?.sharedMutation?.deleteBudgetInvestment == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
            }
        });
    }

    let showfield;
    if (data.iSMOVABLE == 1) {
        showfield = (<div style={{ marginTop: 13 }}>
            <FieldNumberFormat title="Санхүүжилт хийсэн дүн (өмнөх оны эцсээр, төгрөг)" onChanged={fieldOnChanged} keyfield="pASTYEARC2PRICE" defaultValue={data?.pASTYEARC2PRICE} />
        </div>)
    }
    return (
        <PanelBase
            headerText={props?.id ? "Засах" : "Нэмэх"}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            onDelete={onDelete}
            warning={warning}
            PanelType="medium"
            buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
            delete={props?.id ? true : false}
        >
            <div className="add-product">
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown disable={disableSchool} loading={loading} required onChanged={fieldOnChanged} title="Тэнхим" keyfield="dEPARTMENTID" keyfieldname="dEPARTMENTNAME" defaultValue={data.dEPARTMENTID} checkreq={checkRule} data={departments} />
                </div>
                <div style={{ marginTop: 13 }}>
                    {props.id ?
                        <FieldText disable={true} onChanged={fieldOnChanged} title="Хөрөнгийн ангилал" keyfield="pURINVNAME" defaultValue={data.pURINVNAME} checkreq={checkRule} />
                        :
                        <FieldDropdown required onChanged={fieldOnChanged} title="Хөрөнгийн ангилал" keyfield="pURINVID" keyfieldname="pURINVNAME" defaultValue={data.pURINVID} checkreq={checkRule} data={dPurInv} />
                    }
                </div>
                <div style={{ marginTop: 13 }}>
                    {props.id ?
                        <FieldText disable={true} onChanged={fieldOnChanged} title="Хөрөнгийн нэр төрөл" keyfield="iNVESTMENTITEMNAME" defaultValue={data.iNVESTMENTITEMNAMEME} checkreq={checkRule} />
                        :
                        <FieldDropdown required onChanged={fieldOnChanged} title="Хөрөнгийн нэр төрөл" keyfield="iNVESTMENTITEMID" keyfieldname="iNVESTMENTITEMNAME" defaultValue={data.iNVESTMENTITEMID} checkreq={checkRule} data={dPItem} />
                    }
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldText disable={true} required loading={loading} onChanged={fieldOnChanged} title="Хэмжих нэгж" keyfield="uNITID" keyfieldname="uNITNAME" defaultValue={data.uNITNAME} checkreq={checkRule} />                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Нэгж бүрийн үнэ (төгрөг)" keyfield="uNITPRICE" defaultValue={data.uNITPRICE} fixedDecimalScale={true} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required onChanged={fieldOnChanged} title="Он дамжсан эсэх" keyfield="iSMOVABLE" defaultValue={data.iSMOVABLE} checkreq={checkRule} data={isMovable} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat required title="Тоо хэмжээ" onChanged={fieldOnChanged} keyfield="qTY" defaultValue={data?.qTY} />
                </div>
                {showfield}
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat required title="Төсөвт онд зарцуулах санхүүжилт дүн (төгрөг)" onChanged={fieldOnChanged} keyfield="cURRENTYEARPRICE" defaultValue={data?.cURRENTYEARPRICE} checkreq={checkRule} fixedDecimalScale={true} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat required disable={true} title="Нийт төсөвт өртөг(төгрөг)" onChanged={fieldOnChanged} keyfield="tOTALBUDGET" defaultValue={data?.tOTALBUDGET} checkreq={checkRule} fixedDecimalScale={true} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required onChanged={fieldOnChanged} title="Барьж эхэлсэн (эхлэх) огноо" keyfield="" defaultValue={data.sTARTEDYEAR} checkreq={checkRule} data={dBudgetYears} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat title="Барилгын гүйцэтгэл (%)" onChanged={fieldOnChanged} keyfield="pERPERCENT" defaultValue={data?.pERPERCENT} checkreq={checkRule} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Тайлбар" onChanged={fieldOnChanged} keyfield="nOTE" defaultValue={data?.nOTE} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Хүрэх үр дүн /outputs/" onChanged={fieldOnChanged} keyfield="oUTPUT" defaultValue={data?.oUTPUT} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Хүрэх үр дагавар /outcome/" onChanged={fieldOnChanged} keyfield="oUTCOME" defaultValue={data?.oUTCOME} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Үр нөлөө /Impact/" onChanged={fieldOnChanged} keyfield="iMPACT" defaultValue={data?.iMPACT} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Тэргүүлэх чиглэл"
                        keyfield="sPTOPPRID"
                        onChanged={fieldOnChanged}
                        defaultValue={data?.sPTOPPRID}
                        data={splan.filter(x => x.typeid == 2)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Зорилт"
                        keyfield="sPGOALID"
                        onChanged={fieldOnChanged}
                        defaultValue={data?.sPGOALID}
                        data={splan.filter(x => x.typeid == 3 && x.parentid == data?.sPTOPPRID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Стратеги"
                        keyfield="sPSTRID"
                        onChanged={fieldOnChanged}
                        defaultValue={data?.sPSTRID}
                        data={splan.filter(x => x.typeid == 4 && x.parentid == data?.sPGOALID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Хэмжих үзүүлэлт"
                        keyfield="sPINDID"
                        onChanged={fieldOnChanged}
                        defaultValue={data?.sPINDID}
                        data={splan.filter(x => x.typeid == 5 && x.parentid == data?.sPSTRID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                        keyfield="kPIID"
                        onChanged={fieldOnChanged}
                        defaultValue={data?.kPIID}
                        data={kpis}
                    />
                </div>
            </div>
        </PanelBase>
    );
};

export default InvestmentPanel;
