export const FUNDER = [
 {
   'key': 1,
   'text': 'Өөрийн хөрөнгөөр'
 },
 {
   'key': 2,
   'text': 'Төвлөрсөн сан'
 }
];
