export const SalaryColumns = [
    {
        key: "basesalary",
        text: "Үндсэн цалин"
    },
    {
        key: "degree",
        text: "Эрдмийн зэрэг цолны"
    },
    {
        key: "position",
        text: "Албан тушаалын"
    },
    {
        key: "qualifications",
        text: "Мэргэшлийн зэргийн"
    },
    {
        key: "added",
        text: "Хавсран ажилласан"
    },
    {
        key: "skill",
        text: "Ур чадварын"
    },
    {
        key: "additionalamount",
        text: "Нэмэгдлийн дүн"
    },
    {
        key: "total",
        text: "Нийт цалин"
    }
];