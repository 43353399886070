import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, PanelBase, FieldNumberFormat, FieldText, LoadSpin, } from "dw-components";
import { message } from "antd";
import { PanelType } from "@fluentui/react";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import { BudgetYear } from "../../../../Const/BudgetYear";
import { UserContext } from "../../../../Context/UserContext";


const BusinessTripInitPanel = (props) => {
  const [data, setData] = useState({});
  const [dECat, setDECat] = useState([]);
  const [dRegion, setDRegion] = useState([]);
  const [dCountry, setDCountry] = useState([]);
  const [dDiv, setDDiv] = useState([]);
  const [dDist, setDDist] = useState([]);

  const { userInfo } = useContext(UserContext);
  const [checkRule, setCheckRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);


  const getData = async () => {
    setLoading(true);

    let tDECat = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          btripeconomycategorys {
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.btripeconomycategorys;

      if (tResp?.length > 0) {
        tDECat = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDECat(tDECat);
    let tdRegion = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getRegionList {
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.getRegionList;

      if (tResp?.length > 0) {
        tdRegion = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDRegion(tdRegion);

    let tData = {};
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
        sharedQuery {
          getBusinessTrip(id: $id) {
            iD
            eCONOMYCATEGORYID
            tYPEID
            tYPENAME
            eCONOMYCATEGORYNAME
            rEGIONID
            rEGIONNAME
            cOUNTRYID
            cOUNTRYNAME
            dIVISIONID
            dIVISIONNAME
            dISTRICTID
            dISTRICTNAME
            tRAVELDAY
            tRAVELCOST
            hOUSINGCOST
            yEAR
          }
        }
      }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getBusinessTrip;
        if (tResp?.iD) {
          tData = tResp;
        }
      }).catch(response => {
        setLoading(false);
      });
          let tDECat = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          btripeconomycategorys {
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.btripeconomycategorys;

      if (tResp?.length > 0) {
        tDECat = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDECat(tDECat);
      await onFillCountry(tData?.rEGIONID);
      await onFillDivision(tData?.cOUNTRYID);
      await onFillDistrict(tData?.dIVISIONID);
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const onFillCountry = async(pRegionID)=>{
    
    let tDCountry = [];
        if (pRegionID > 0) {
          await APIGraphQL.post("", {
            query: `query ($regionid: Int) {
            sharedQuery {
              getCountryByRegion(regionid: $regionid) {
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
              "regionid": parseInt(pRegionID)
            }
          }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getCountryByRegion;
            if (tResp) {
              if (tResp?.length > 0) {
                tDCountry = tResp;
              }
            }
          }).catch(() => {
            setLoading(false);
          });
        }
        setDCountry(tDCountry);
  }
  const onFillDivision = async(pCountryID)=>{
    
    let tDDiv = [];
        if (pCountryID>0) {
          await APIGraphQL.post("", {
            query: `query ($countryid: Int) {
            sharedQuery {
              getDivisionByCountry(countryid: $countryid) {
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
              "countryid": parseInt(pCountryID)
            }
          }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getDivisionByCountry;
            if (tResp) {
              if (tResp?.length > 0) {
                tDDiv = tResp;
              }
            }
          }).catch(() => {
            setLoading(false);
          });
        }
        setDDiv(tDDiv);
  }
  const onFillDistrict = async(pDivisionID)=>{
    let tDDist = [];
    if (pDivisionID) {
      await APIGraphQL.post("", {
        query: `query ($divisionid: Int) {
        sharedQuery {
          getDistrictByDiv(divisionid: $divisionid) {
            key: iD
            text: nAME
          }
        }
      }`,
        variables: {
          "divisionid": parseInt(pDivisionID)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getDistrictByDiv;
        if (tResp) {
          if (tResp?.length > 0) {
            tDDist = tResp;
          }
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setDDist(tDDist);
  }

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }

      if (field == "tYPEID") {
        let tdRegion = [];
        if (tData.tYPEID == 2) {
          await APIGraphQL.post("", {
            query: `{
            sharedQuery {
              getRegionList {
                key: iD
                text: nAME
              }
            }
          }`,
          }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getRegionList;
            if (tResp) {
              if (tResp?.length > 0) {
                tdRegion = tResp;
              }
            }
          }).catch(() => {
            setLoading(false);
          });
        }
        else {
            tData.rEGIONID = 1;
            tData.cOUNTRYID = 1;
            await onFillDivision(1);
          }
        setDRegion(tdRegion);
      }          
      if (field == "rEGIONID") {
      await onFillCountry(key);
    }
      if (field == "cOUNTRYID") {
        await onFillDivision(key);
      }

      if (field == "dIVISIONID") {
        await onFillDistrict(key);
      }
      setData(tData);
    }
  };

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.tYPEID ||
      !data?.yEAR ||
      !data?.eCONOMYCATEGORYID ||
      !data?.dIVISIONID ||
      !data?.tRAVELDAY ||
      !data?.tRAVELCOST ||
      !data?.hOUSINGCOST) {
      vRule = false;
    }

    if (data?.tYPEID == 2) {
      if (!data.cOUNTRYID) {
        vRule = false;
      }
    } else {
      if (!data.dISTRICTID) {
        vRule = false;
      }
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
        if (tData.tYPEID == 1) {
          tData.tYPENAME = "Дотоод";
          tData.cOUNTRYNAME = "Монгол";
          tData.rEGIONNAME = "Азийн хөгжиж буй болон буурай хөгжилтэй орнууд";
        } else {
          tData.tYPENAME = "Гадаад";
        }
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: BusinessTripInputType) {
          sharedMutation {
            addBusinessTrip(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addBusinessTrip?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation DeleteBusinessTrip($id: Int) {
        sharedMutation {
          deleteBusinessTrip(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteBusinessTrip == 0) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText="Томилолтын мэдээ нэмэх"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      Type={PanelType.medium}
      isFooterAtBottom={true}
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: 16 }}>
            <ButtonDefault text={props?.tripId ? "Засах" : "Хадгалах"} onClick={onSave} />
          </div>
        </div>
      }
    >
      <div style={{ marginTop: 25 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Гадаад/Дотоод" keyfield="tYPEID" defaultValue={data.tYPEID} data={[{ key: 2, text: "Гадаад" }, { key: 1, text: "Дотоод" }]} checkreq={checkRule} />
      </div>
      <div style={{ marginTop: 25 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Төсвийн жил" keyfield="yEAR" defaultValue={data.yEAR} data={BudgetYear} checkreq={checkRule} />
      </div>
      <div style={{ marginTop: 25 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" defaultValue={data.eCONOMYCATEGORYID} data={dECat} checkreq={checkRule} />
      </div>
      <div style={{ marginTop: 13 }}>
        {data.tYPEID == 2 && (
          <FieldDropdown required onChanged={fieldOnChanged} title="Бүс нутаг" keyfield="rEGIONID" keyfieldname="rEGIONNAME" defaultValue={data.rEGIONID} data={dRegion} />
        )}
      </div>
      <div style={{ marginTop: 13 }}>
        {data.tYPEID == 2 && (
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Улс" keyfield="cOUNTRYID" keyfieldname="cOUNTRYNAME" defaultValue={data.cOUNTRYID} data={dCountry} />
        )}
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Аймаг/Хот" keyfield="dIVISIONID" keyfieldname="dIVISIONNAME" defaultValue={data.dIVISIONID} checkreq={checkRule} data={dDiv} />
      </div>
      <div style={{ marginTop: 13 }}>
        {data.tYPEID == 1 && (
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Сум/Дүүрэг" keyfield="dISTRICTID" keyfieldname="dISTRICTNAME" defaultValue={data.dISTRICTID} checkreq={checkRule} data={dDist} />
        )}
        </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title={data.tYPEID == 1 ? "Зам хоног ₮" : "Зам хоног $"} keyfield="tRAVELDAY" defaultValue={data.tRAVELDAY} checkreq={checkRule} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title={data.tYPEID == 1 ? "Замын зардал ₮" : "Замын зардал $"} keyfield="tRAVELCOST" defaultValue={data.tRAVELCOST} checkreq={checkRule} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title={data.tYPEID == 1 ? "Байрны зардал ₮" : "Байрны зардал $"} keyfield="hOUSINGCOST" defaultValue={data.hOUSINGCOST} checkreq={checkRule} />
      </div>
    </PanelBase>
  );
};
export default BusinessTripInitPanel;
