import React, { useState, useEffect, useReducer } from "react";
import { FieldDropdown, GridDefaultShimmer } from "dw-components";
import { db } from "../../../Firebase/firebase-config";
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { Row, Col, Table, Typography } from "antd";

import {
  categoryTree,
  groupListData,
  convertToTableTree,
  getParentCategories,
  methodDataToList,
} from "../../../Utils";

const { Text } = Typography;

const BudgetReport = (props) => {
  //Дата оноох функц
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [total, setTotal] = useState();

  const [data, setData] = useState([]);

  const [totalIncome, setTotalIncome] = useState();
  const [totalOutcome, setTotalOutcome] = useState();
  const [prevInitBalance, setPrevInitBalance] = useState();
  const [prevLastBalance, setPrevLastBalance] = useState();

  const [selectedCalculateMethod, setSelectedCalculateMethod] = useState("");
  const [selectedCalculateMethodData, setSelectedCalculateMethodData] =
    useState();
  const [selectedBudgetId, setSelectedBudgetId] = useState("");
  const [selectedIndicatorData, setSelectedIndicatorData] = useState("");

  const [showCalculateMethod, setShowCalculateMethod] = useState(false);
  const [calculateMethodData, setCalculateMethodData] = useState({});
  const [bCalculateMethodData, setBCalculateMethodData] = useState({});
  const [hCalculateMethodData, setHCalculateMethodData] = useState({});
  const [budgetReq, setBudgetReq] = useState({});

  const [departmentTotal, setDepartmentTotal] = useState(0);
  const [branchTotal, setBranchTotal] = useState(0);
  const [headTotal, setHeadTotal] = useState(0);

  const [parentCategories, setParentCategories] = useState([]);

  const methodCollectionRef = collection(db, "budgetMethods");
  const BudgetIndicatorsRef = collection(db, "budgetIndicators");
  const BudgetRequestsRef = collection(db, "budgetRequests");
  const IndicatorGroupsRef = collection(db, "indicatorGroups");

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  let branchRowTotal = 0;
  let departmentRowTotal = 0;
  let headRowTotal = 0;

  const columns = [
    {
      title: "Эдийн засгийн ангилал",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text, record) => {
        if (record.methodId) {
          return record.name;
        } else {
          return record.code + " - " + record.name;
        }
      },
    },
    {
      title: "Салбар сургуулийн санал",
      width: "5%",
      align: "left",
      children: [
        {
          title: "Тоо хэмжээ",
          dataIndex: "bCount",
          key: "bCount",
          width: "8%",
          align: "right",
          render: (text, record) => {
            let index = 0;
            if (record.methodId) {
              ++index;
              return <div>{index}</div>;
            }
          },
        },
        {
          title: "Дүн",
          dataIndex: "currentBudget",
          key: "currentBudget",
          width: "8%",
          align: "right",
          render: (text, record) => {
            if (record.methodId) {
              let rowData =
                bCalculateMethodData[record.indicatorId][record.name];
              if (rowData) {
                return (
                  <div>
                    {mntFormatter.format(
                      rowData?.totalBudgetAmount.replace(/,/g, "")
                    ) + "₮"}
                  </div>
                );
              }
            } else {
              let totalAmount = 0;
              const calcTotalAmount = (record) => {
                if (record.children && record.children.length > 0) {
                  record.children.map((row) => {
                    if (row.methodId) {
                      let amount = 0;
                      let methodData =
                        bCalculateMethodData[row.indicatorId][row.name];
                      if (methodData) {
                        amount = parseFloat(
                          methodData?.totalBudgetAmount.replace(/,/g, "")
                        );
                      }
                      totalAmount += amount;
                    } else {
                      calcTotalAmount(row);
                    }
                  });
                }

                return totalAmount;
              };

              let total = calcTotalAmount(record);

              if (parentCategories.includes(record.code)) {
                switch (record.category) {
                  case "income":
                    branchRowTotal += total;
                    break;
                  case "outcome":
                    branchRowTotal -= total;
                    break;
                }

                setTotals(branchRowTotal, "BRANCH");
              }

              return (
                <div className="parent-total">
                  {mntFormatter.format(total) + "₮"}
                </div>
              );
            }
          },
        },
      ],
    },
    {
      title: "Батлагдсан төсөв",
      width: "5%",
      align: "left",
      children: [
        {
          title: "Тоо хэмжээ",
          dataIndex: "hCount",
          key: "hCount",
          width: "8%",
          align: "right",
          render: (text, record) => {
            let index = 0;
            if (record.methodId) {
              ++index;
              return <div>{index}</div>;
            }
          },
        },
        {
          title: "Дүн",
          dataIndex: "currentBudget",
          key: "currentBudget",
          width: "8%",
          align: "right",
          render: (text, record) => {
            if (record.methodId) {
              let methodData =
                hCalculateMethodData[record.indicatorId][record.name];
              if (methodData) {
                return (
                  <div>
                    {mntFormatter.format(
                      methodData.totalBudgetAmount.replace(/,/g, "")
                    ) + "₮"}
                  </div>
                );
              }
            } else {
              let totalAmount = 0;
              const calcTotalAmount = (record) => {
                if (record.children && record.children.length > 0) {
                  record.children.map((row) => {
                    if (row.methodId) {
                      let amount = 0;
                      let methodData =
                        hCalculateMethodData[row.indicatorId][row.name];
                      if (methodData) {
                        amount = parseFloat(
                          methodData?.totalBudgetAmount.replace(/,/g, "")
                        );
                      }
                      totalAmount += amount;
                    } else {
                      calcTotalAmount(row);
                    }
                  });
                }

                return totalAmount;
              };

              let total = calcTotalAmount(record);

              if (parentCategories.includes(record.code)) {
                switch (record.category) {
                  case "income":
                    headRowTotal += total;
                    break;
                  case "outcome":
                    headRowTotal -= total;
                    break;
                }

                setTotals(headRowTotal, "HEAD");
              }

              return (
                <div className="parent-total">
                  {mntFormatter.format(total) + "₮"}
                </div>
              );
            }
          },
        },
        {
          title: "Тайлбар",
          dataIndex: "description",
          key: "description",
          width: "30%",
          render: (text, record) => {
            if (record.methodId) {
              return (
                <div>
                  {
                    hCalculateMethodData[record.indicatorId][record.name]
                      ?.description
                  }
                </div>
              );
            }
          },
        },
      ],
    },
  ];

  const setTotals = async (amount, type) => {
    switch (type) {
      case "BRANCH":
        setBranchTotal(amount);
        break;
      case "DEPARTMENT":
        setDepartmentTotal(amount);
        break;
      case "HEAD":
        setHeadTotal(amount);
        break;
    }
  };

  //Loading screen-ны функц
  const [loading, setLoading] = useState(false);

  //Дата оноож байгаа функц
  useEffect(async () => {
    // branch method data
    const branchQuery = query(
      methodCollectionRef,
      where("budgetId", "==", props?.budgetId),
      where("type", "==", "BRANCH")
    );

    const BranchMethodsDocs = await getDocs(branchQuery);
    const branchMethods = BranchMethodsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const branchMethodsObj = branchMethods.reduce(
      (obj, current) => ({
        ...obj,
        [current.indicatorId]: {
          ...obj[current.indicatorId],
          [current.department]: current,
        },
      }),
      {}
    );

    // head method data
    const hMethodQuery = query(
      methodCollectionRef,
      where("budgetId", "==", props?.budgetId),
      where("type", "==", "HEAD")
    );

    const hMethodsDocs = await getDocs(hMethodQuery);
    const hMethods = hMethodsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const hMethodsObj = hMethods.reduce(
      (obj, current) => ({
        ...obj,
        [current.indicatorId]: {
          ...obj[current.indicatorId],
          [current.department]: current,
        },
      }),
      {}
    );

    // indicator groups
    const IndicatorGroupDocs = await getDocs(IndicatorGroupsRef);
    const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // indicators of current budget
    const indicatorQuery = query(
      BudgetIndicatorsRef,
      where("budgetId", "==", props?.budgetId)
    );
    const indicatorDocs = await getDocs(indicatorQuery);
    const indicators = indicatorDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const categories = await categoryTree(
      indicatorGroups,
      "groupParent",
      "groupName"
    );

    const parentCats = await getParentCategories(categories);
    const groupedIndicators = await groupListData(indicators);
    const listData = await convertToTableTree(categories, groupedIndicators);
    const methodList = await methodDataToList(listData, branchMethodsObj);

    setParentCategories(parentCats);
    setHCalculateMethodData(hMethodsObj);
    setBCalculateMethodData(branchMethodsObj);
    setData(methodList);
  }, [reducerValue]);

  //Утга буцаах функц
  return (
    <div style={{ padding: "65px 20px 30px" }}>
      <h2 className="indicator-total">Нийт</h2>
      <Table
        columns={columns}
        dataSource={data}
        childrenColumnName="children"
        pagination={false}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={2} colSpan={2} align="right">
                  <b>Нийт төсөв:</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1} align="right">
                  <Text type="danger">
                    {mntFormatter.format(branchTotal) + "₮"}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={1} align="right">
                  <b>Нийт төсөв:</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} colSpan={1} align="right">
                  <Text type="danger">
                    {mntFormatter.format(headTotal) + "₮"}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default BudgetReport;
