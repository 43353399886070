import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Table, message } from "antd";
import { Pivot, PivotItem, IconButton, Stack } from "@fluentui/react";
import { ButtonDefault, ButtonSecondary } from "dw-components";
import {
  doc,
  getDocs,
  addDoc,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import {
  categoryTree,
  convertToTableTree,
  groupListData,
  setRowStatus,
  getIndicators,
} from "../../Utils";
import { BUDGETSTATUS } from "../../Const/BudgetStatus";

const BudgetIndicators = (props) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { budgetData, budgetId } = state;

  const [data, setData] = useState([]);

  const [disableAction, setDisableAction] = useState(false);
  const [dataCount, setDataCount] = useState();

  const IndicatorCollectionRef = collection(db, "indicators");
  const IndicatorGroupsCollectionRef = collection(db, "indicatorGroups");
  const BudgetCollectionRef = collection(db, "budgets");
  const BudgetIndicatorsCollectionRef = collection(db, "budgetIndicators");

  useEffect(async () => {
    let indicatorDocs;
    let indicators;
    const IndicatorGroupDocs = await getDocs(IndicatorGroupsCollectionRef);
    const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (!budgetId) {
      //get only active indicators
      const Query = query(
        IndicatorCollectionRef,
        where("isActive", "==", true)
      );
      indicatorDocs = await getDocs(Query);
      indicators = indicatorDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    } else {
      const Query = query(
        BudgetIndicatorsCollectionRef,
        where("budgetId", "==", budgetId)
      );
      indicatorDocs = await getDocs(Query);
      indicators = indicatorDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    }

    const categories = await categoryTree(
      indicatorGroups,
      "groupParent",
      "groupName"
    );

    const groupedIndicators = await groupListData(indicators);
    const listData = await convertToTableTree(categories, groupedIndicators);

    setData(listData);
  }, []);

  const columns = [
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
      width: "13%",
    },
    {
      title: "Эдийн засгийн ангиллын нэр",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: (a, b) => a.orderNo - b.orderNo,
      defaultSortOrder: "ascend",
      render: (text, record) => {
        if(!record.indicatorId) {
          return <span className="indicator-group">{text}</span>
        } else {
          return text
        }
      }
    },
    {
      title: "Төлөв",
      dataIndex: "isActive",
      key: "isActive",
      width: "10%",
      render: (text, record) => {
        if (record.indicatorId) {
          return {
            props: {
              style: {
                color: record.isActive ? "green" : "red",
                fontWeight: 500,
              },
            },
            children: record.isActive ? "Идэвхитэй" : "Идэвхигүй",
          };
        }
      },
    },
    {
      title: "Бүрэлдэхүүн сургуулиас санал авах",
      dataIndex: "branchSchoolReq",
      key: "branchSchoolReq",
      width: "15%",
      render: (text, record) => {
        if (record.indicatorId) {
          return {
            props: {
              style: {
                color: record.branchSchoolReq ? "green" : "red",
                fontWeight: 500,
              },
            },
            children: record.branchSchoolReq ? "Тийм" : "Үгүй",
          };
        }
      },
    },
    {
      title: "Нэгжээс санал авах",
      dataIndex: "departmentReq",
      key: "departmentReq",
      width: "10%",
      render: (text, record) => {
        if (record.indicatorId) {
          return {
            props: {
              style: {
                color: record.departmentReq ? "green" : "red",
                fontWeight: 500,
              },
            },
            children: record.departmentReq ? "Тийм" : "Үгүй",
          };
        }
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (text, record) => {
        if (record.indicatorId) {
          return (
            <div>
              <IconButton
                styles={{
                  root: { float: "left", height: "inherit" },
                }}
                menuIconProps={{ iconName: "More" }}
                menuProps={{
                  items: [
                    {
                      key: "changeActive",
                      text: "Төлөв солих",
                      iconProps: { iconName: "Edit" },
                      onClick: () => onChangeStatus(record, "isActive"),
                    },
                    {
                      key: "branchSchoolReq",
                      text: "Бүрэлдэхүүн сургуулиас санал авах",
                      iconProps: { iconName: "WindowEdit" },
                      onClick: () => onChangeStatus(record, "branchSchoolReq"),
                      disabled: !record.isActive ? true : false
                    },
                    {
                      key: "departmentReq",
                      text: "Нэгжээс санал авах",
                      iconProps: { iconName: "SingleColumnEdit" },
                      onClick: () => onChangeStatus(record, "departmentReq"),
                      disabled: (!record.branchSchoolReq ? true : false) || (!record.isActive ? true : false)
                    },
                  ],
                  directionalHintFixed: true,
                }}
              />
            </div>
          );
        }
      },
    },
  ];

  // Writing data to firebase
  const createBudget = async (budgetData, groupedIndicators) => {
    try {
      const IndicatorGroupDocs = await getDocs(IndicatorGroupsCollectionRef);
      const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const groups = indicatorGroups.reduce(
        (obj, cur) => ({ ...obj, [cur.groupName]: cur }),
        {}
      );

      const indicators = await getIndicators(groupedIndicators);
      const response = await addDoc(BudgetCollectionRef, budgetData);

      indicators.map(async (item) => {
        await addDoc(BudgetIndicatorsCollectionRef, {
          budgetId: response.id,
          branchSchool: item.data.branchSchool,
          calculateMethod: item.data.calculateMethod,
          getFeedback: item.data.getFeedback,
          groupName: item.data.groupName,
          indicatorCode: item.data.indicatorCode,
          indicatorName: item.data.indicatorName,
          indicatorId: item.data.id,
          category: groups[item.data.groupName].indicatorCategory,
          isActive: item.isActive,
          orderNo: item.orderNo,
          branchSchoolReq: item.branchSchoolReq,
          departmentReq: item.departmentReq
        });
      });
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const updateBudget = async (budgetData, groupedIndicators) => {
    try {
      const BudgetCollectionDoc = doc(db, "budgets", budgetId);
      await updateDoc(BudgetCollectionDoc, budgetData);

      const indicators = await getIndicators(groupedIndicators);
      indicators.map(async (item) => {
        const BudgetIndicatorCollectionDoc = doc(
          db,
          "budgetIndicators",
          item.indicatorId
        );
        await updateDoc(BudgetIndicatorCollectionDoc, {
          isActive: item.isActive,
          branchSchoolReq: item.branchSchoolReq,
          departmentReq: item.departmentReq
        });
      });

      message.success("Амжилттай засагдлаа");
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const onChangeStatus = async (record, field) => {
    const temp = await setRowStatus([...data], record.name, field);
    setData(temp);
  };

  const onSubmit = async () => {
    setDisableAction(true);
    await createBudget(budgetData, data);
    navigate("/budget");
  };

  const onEdit = async () => {
    setDisableAction(true);
    await updateBudget(budgetData, data);
    navigate("/budget");
  };

  const onOpen = async () => {
    setDisableAction(true);

    if (budgetId) {
      await updateBudget({ ...budgetData, status: "OPENED" }, data);
    } else {
      await createBudget({ ...budgetData, status: "OPENED" }, data);
    }

    navigate("/budget");
  };

  return (
    <div>
      <div style={{ margin: "16px 20px 16px" }}>
        <Row className="budget-header">
          <Col span={5}>
            <h2 className="budget-title">{budgetData.budgetName}</h2>
            <Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Нээсэн огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">{budgetData.openedDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Хаах огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value"> {budgetData.closeDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">
                    Албан тушаалтны нэр: &nbsp;
                  </h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetData.employeeName}
                  </h4>
                </Stack.Item>
              </Stack>
            </Stack>
          </Col>
          <Col span={16} style={{ marginTop: 36 }}>
            <h4>Төлөв</h4>
            <h4 className="budget-status">
              {BUDGETSTATUS[budgetData.status || "NEW"]}
            </h4>
          </Col>
          <Col
            span={3}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ marginLeft: budgetId ? 60 : 40 }}>
              <ButtonSecondary
                text={budgetId ? "Засах" : "Хадгалах"}
                onClick={budgetId ? onEdit : onSubmit}
                disable={disableAction}
              />
            </div>
            <ButtonDefault
              text="Нээх"
              onClick={onOpen}
              disable={disableAction}
            />
          </Col>
        </Row>
      </div>
      <Pivot
        defaultSelectedKey="1"
        styles={{
          linkIsSelected: {
            "&::before": {
              backgroundColor: "#0358A7",
              height: 3,
            },
          },
          link: {
            height: 48,
          },
          root: {
            height: 48,
            float: "left",
            paddingLeft: 16,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            textAlignLast: "justify",
          },
        }}
      >
        <PivotItem headerText="Үзүүлэлт">
          <div style={{ padding: "65px 20px 30px" }}>
            <h2 className="indicator-total">Нийт({dataCount})</h2>
            <Table
              bordered
              defaultExpandAllRows={true}
              size="small"
              columns={columns}
              dataSource={data}
              childrenColumnName="children"
              expandable={{
                expandRowByClick: true,
                defaultExpandAllRows: true,
                indentSize: 30
                // expandIcon: ({ expanded, onExpand, record }) =>
                //   expanded ? (
                //     <DownOutlined onClick={(e) => onExpand(record, e)} />
                //   ) : (
                //     <RightOutlined onClick={(e) => onExpand(record, e)} />
                //   ),
              }}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
            />
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default BudgetIndicators;
