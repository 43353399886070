import React, { useState, useEffect, useContext } from "react";
import { PanelBase, ButtonDefault, FieldNumberFormat, FieldDropdown, LoadSpin } from "dw-components";
import { APIGraphQL } from '../../../API/APIGraphQL';
import { UserContext } from '../../../Context/UserContext';

const PercentChangeAllPanel = (props) => {
  const { userInfo } = useContext(UserContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolid, setSchoolid] = useState([1]);
  const [percent, setPercent] = useState();
  const [dSchool, setDSchool] = useState({});

  const getData = async () => {
      let tDSchool = [];
      await APIGraphQL.post("", {
      query: `query ($schoolid: Int) {
          sharedQuery {
            schoolByParentId(schoolid: $schoolid) {
              key: iD
              text: nAME
            }
          }
        }`,
      variables: {
          "schoolid": parseInt(userInfo?.schoolid)
      }
      }).then(response => {
          let tResp = response?.data?.data?.sharedQuery?.schoolByParentId;
          if (tResp?.length > 0) {
              tDSchool = tResp;
              tDSchool.push({key: userInfo?.schoolid, text:"Бүгд"});
          }
      }).catch(() => {
          setLoading(false);
      })
      setDSchool(tDSchool);
    }

  const onCalculate = () => {
    if (schoolid && percent) {
      props.onPercentChanged(percent, schoolid);
      props?.onDismiss();
    }
 }

 const onChangeSchool = (key, field) => {
  if(field == "schoolid")
  {
    setSchoolid(key);
  }
  else 
    setPercent(key);
 }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  return (
    <PanelBase
      headerText="Хувиар өөрчлөх"
      isOpen={props.isOpen}
      PanelType="small"
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Тооцох" onClick={onCalculate} />}
    >
      <div style={{ marginTop: 24 }}>
        <div>
          <FieldDropdown loading={loading} required onChanged={(key, fieldName)=>onChangeSchool(key, "schoolid")} title="Сургууль" keyfield="schoolid" defaultValue={userInfo?.schoolid} data={dSchool} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={(key, fieldName) =>onChangeSchool(key, "percent")} title="Хувь" keyfield="pERCENT" defaultValue={0} />
        </div>
      </div>
    </PanelBase >
  );
};

export default PercentChangeAllPanel;