import React, { useState, useEffect } from 'react';
import "./App.css";
import "antd/dist/antd.css";
import "dw-components/dist/index.css";
import { Header } from "dw-components";
import { BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import Navigations from "./Navigation/Navigations";
import { UserContext } from './Context/UserContext';
import LoadingC from "./Components/Loading/LoadingCenter";

const App = () => {

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      setUserInfo({ ...JSON.parse(localStorage.getItem("userInfo")) });
    }
    initializeIcons();
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <BrowserRouter>
          <Navigations />
        </BrowserRouter>
      </UserContext.Provider>
      <LoadingC></LoadingC>
    </div>
  );
}

export default App;
