import React, { useEffect, useState } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import { PageHeader, Number, GridDefaultShimmerStick } from "dw-components";
import BusinessTripInitPanel from "../Panel/BusinessTripInitPanel";
import { ReactComponent as ExcelIcon } from '../../../../svg/excel.svg';
import FileDownload from 'js-file-download';
import { API } from '../../../../API/API';
import { APIGraphQL } from "../../../../API/APIGraphQL";

const BusinessTripInit = (props) => {

  const [showNew, setShowNew] = useState(false);
  const [data, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [tripId, setTripId] = useState();
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('in');
  const [filteredData, setFilteredData] = useState([]);
  const [gridFilter, setGridFilter] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [filterData, setFilterData] = useState([{
    key: 'in',
    text: 'Дотоод'
  }, {
    key: 'out',
    text: 'Гадаад'
  }]);

  const [columns, setColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    },
    {
      name: "Томилолт",
      fieldName: "typename",
      maxWidth: 70,
      minWidth: 50
    }, {
      name: "Улс",
      fieldName: "countryname",
      maxWidth: 120,
      minWidth: 100
    }, {
      name: "Аймаг/Хот",
      fieldName: "divisionname",
      maxWidth: 120,
      minWidth: 100
    }, {
      name: "Сум/Дүүрэг",
      fieldName: "districtname",
      maxWidth: 120,
      minWidth: 100
    }, {
      name: "Зам хоног",
      fieldName: "travelday",
      number: true,
      summary: true,
      maxWidth: 70,
      minWidth: 50
    }, {
      name: "Замын зардал",
      fieldName: "travelcost",
      maxWidth: 120,
      minWidth: 100,
      summary: true,
      number: true,
      onRender: (item) => {
        return (<Number value={item?.travelcost} />)
      }
    }, {
      name: "Байрны зардал",
      fieldName: "housingcost",
      maxWidth: 120,
      minWidth: 100,
      number: true,
      summary: true,
      onRender: (item) => {
        return (<Number value={item?.housingcost} />)
      }
    }, {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      }
    }
  ]);

  const getData = async (p_filter) => {
    setLoading(true);
    let typeid = filterValue == 'in' ? 1 : 2;
    let tFitler = [];
    if (p_filter?.length > 0) {
      tFitler = p_filter;
    }

    let tData = [];
    await API.post("/api/budget/businessTripConfigList?typeid=" + typeid, tFitler).then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.length > 0) {
          tData = response?.data?.retData;
        }
      }
    }).catch(() => {
      setLoading(false);
    });
    let tGridFilter = [];

    if (typeid == 2) {
      await APIGraphQL.post("", {
        query: `{
          sharedQuery {
            getCountryList {
              key: iD
              text: nAME
            }
          }
        }`,
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getCountryList;
        if (tResp?.length > 0) {
          tGridFilter.push({
            key: "countryid",
            text: "Улс",
            data: tResp
          });
        }
      });
    } else {
      let tResp = [];
      await APIGraphQL.post("", {
        query: `query ($countryid: Int) {
            sharedQuery {
              getDivisionByCountry(countryid: $countryid) {
                key: iD
                text: nAME
              }
            }
          }`,
        variables: {
          "countryid": 1
        }
      }).then(response => {
        tResp = response?.data?.data?.sharedQuery?.getDivisionByCountry;
        if (tResp) {
          if (tResp?.length > 0) {
            tGridFilter.push({
              key: "divisionid",
              text: "Хот",
              data: tResp
            });
          }
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setLoading(false);
    setData(tData);
    setOData(tData);
    setGridFilter(tGridFilter);
  }

  useEffect(() => {
    getData();
  }, [filterValue]);

  const onNew = () => {
    setTripId();
    setShowNew(true);
  }

  const onDismiss = () => {
    setTripId();
    setShowNew(false);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const onChangeFilter = (p_key, p_name) => {
    setFilterValue(p_key);
  }

  const onEditClick = (id) => {
    setTripId(id);
    setShowNew(true);
  }

  const downloadExcel = async () => {
    let tFitler = [];
    if (filteredData?.length > 0) {
      tFitler = filteredData;
    }

    let typeid = filterValue == 'in' ? 1 : 2;
    await API.post("/api/budget/businessTripConfigListTemp?typeid=" + typeid + "&filetype=xlsx", tFitler, {
      'responseType': 'blob'
    }).then(response => {
      FileDownload(response.data, "Томилолтын мэдээ.xlsx");

    }).catch(response => {
      setLoadingDownload(false);
    });
    setLoadingDownload(false);
  }

  const onGridFilterChange = async (p_gridfiltervalue) => {
    setFilteredData(p_gridfiltervalue);
    getData(p_gridfiltervalue);
  }

  return (
    <div>
      <PageHeader
        showLeftFilter
        changeFilter={onChangeFilter}
        filterValue={filterValue}
        filterData={filterData}
        buttonTitle="Томилолтын мэдээ"
        onButtonClick={onNew}
        onSearch={onSearch}
        data={oData}
        filteredData={data}
        columns={columns}
        addRightButton={(
          <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
            <Space size={16} >
              <div onClick={downloadExcel}>
                <ExcelIcon />
              </div>
            </Space>
          </div>
        )}
        gridFilter={gridFilter}
        onGridFilterChange={onGridFilterChange}
      />
      <GridDefaultShimmerStick columns={columns} data={data} loading={loading} summary></GridDefaultShimmerStick>
      <BusinessTripInitPanel
        id={tripId}
        isOpen={showNew}
        onDismiss={onDismiss}
        afterSave={getData}
      ></BusinessTripInitPanel>
    </div>
  );
};

export default BusinessTripInit;
