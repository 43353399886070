import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldCheck, FieldDropdownMulti, LoadSpin } from "dw-components";
import { message } from "antd";
import { APIGraphQL } from "../../../API/APIGraphQL";
import moment from "moment";
import { UserContext } from "../../../Context/UserContext";
import { SCHOOLTYPE } from "../../../Const/SchoolType";
import FieldDocumentAttach from "../../../Components/FieldDocumentAttach/FieldDocumentAttach";
import { API } from "../../../API/API";

const NewDocument = (props) => {
   const [data, setData] = useState({});
   const { userInfo } = useContext(UserContext);
   const [checkRule, setCheckRule] = useState(false);
   const [loading, setLoading] = useState(false);
   const [loadingSave, setLoadingSave] = useState(false);
   const [attachData, setAttachData] = useState([]);
   const [files, setFiles] = useState([]);
   const [isBranchDep, setBranchDep] = useState(false);
   const dschoolType = SCHOOLTYPE.filter(m => m.key == 2 || m.key == 4);

   useEffect(() => {
      if (props.id > 0) {
         getData();
         getDocumentAttach();
      } else {
         setData({});
         setFiles([]);
         setAttachData([]);
      }

      if (userInfo.schooltypeid == 2 || userInfo.schooltypeid == 4 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 7) {
         setBranchDep(true);
      }
   }, [props?.isOpen]);

   const getData = async () => {
      let tData = {};
      setLoading(true);
      await APIGraphQL.post("", {
         query: `query ($id: Int) {
            sharedQuery {
               getDocument(id: $id) {
                  iD
                  nAME
                  dOCUMENTCATEGORYID
                  dOCUMENTCATEGORYNAME
                  sCHOOLTYPEIDS
                  cREATED
                  cREATEDBY
               }
            }
         }`,
         variables: {
            "id": parseInt(props?.id),
         },
      }).then((response) => {
         let tResp = response?.data?.data?.sharedQuery?.getDocument;
         if (tResp?.iD) {
            tData = tResp;
            tData.sCHOOLTYPEIDS = tData?.sCHOOLTYPEIDS?.split(',').map(Number);
         }
      }).catch(() => {
         setLoading(false);
      });
      setData(tData);
      setLoading(false);
   }

   const getDocumentAttach = async () => {
      let tAttachData = [];
      await API.get("/api/budget/getDocumentAttach?documentid=" + (props?.id || 0))
      .then(response => {
         if (response?.status == 200 && response?.data?.retType == 0) {
            tAttachData = response?.data?.retData;
         }
      }).catch(() => {
         setLoading(false);
      });
      setAttachData(tAttachData);
   }

   const fieldOnChanged = async (key, text, field, fieldname) => {
      if (field) {
         let tData = { ...data };
         tData[field] = key;
         if (fieldname) {
            tData[fieldname] = text;
         }
         setData(tData);
      }
   };

   const onSave = async () => {
      let isValid = true;
      setCheckRule(true);

      let tData = { ...data } 
      if (!tData?.nAME ||
          !tData?.dOCUMENTCATEGORYID || 
          !tData?.sCHOOLTYPEIDS) { isValid = false; }

      if (isValid) {
         let tSuccess = false;
         tData.sCHOOLTYPEIDS = tData?.sCHOOLTYPEIDS?.toString();

         if (props?.categoryData) {
            tData.dOCUMENTCATEGORYNAME = props?.categoryData?.filter(r => r.key == tData.dOCUMENTCATEGORYID)[0]?.text
         }
         if (!props.id) {
            tData.iD = 0;
            tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
            tData.cREATEDBY = userInfo?.username;
         }
         setLoadingSave(true);
         await APIGraphQL.post("", {
            query: `mutation ($document: DocumentInputType) {
               sharedMutation {
                  addDocument(document: $document) {
                     iD
                  }
               }
            }`,
            variables: {
               "document": tData,
            },
         }).then((response) => {
            setLoadingSave(false);
            if (response?.data?.data?.sharedMutation?.addDocument?.iD) {
               tSuccess = true;
               const formData = new FormData();
                  files.map(item => {
                     formData.append("files", item);
                  });
                  formData.append("filetype", 'documentattach');
                  formData.append("documentid", response?.data?.data?.sharedMutation?.addDocument?.iD);
                  formData.append("schoolid", parseInt(userInfo?.schoolid) || null );
                  formData.append("ismulti", true);
                  API.post('/api/budget/uploadDocument', formData)
                  .then(response => {
                     if (response?.status == 200 && response?.data?.retType == 0) {
                        tSuccess = true;
                     } else {
                        tSuccess = false;
                        message.error("Файл оруулахад алдаа гарлаа", response?.data?.retMsg);
                     }
                  })
                  .catch(e => {
                     message.error('Алдаа гарлаа', e);
                  });
            }
         }).catch(() => {
            setLoadingSave(false);
         })
         if (tSuccess) {
            message.success("Амжилттай");
            onDismiss();
            if (props.afterSave) {
               await props.afterSave();
            }
         }
      }
   };

   const onDelete = async () => {
      let tSuccess = false;
      await APIGraphQL.post("", {
         query: `mutation deleteDocument($id: Int) {
         sharedMutation {
            deleteDocument(id: $id)
         }
      }`,
         variables: {
            "id": parseInt(props.id),
         },
      }).then((response) => {
         if (response?.data?.data?.sharedMutation?.deleteDocument == 1) {
            tSuccess = true;
            if (props.afterSave) {
               props.afterSave();
            }
         }
         if (tSuccess) {
            message.success("Амжилттай");
            onDismiss();
         }
      });
   };

   const onDismiss = () => {
      setData({});
      setFiles([]);
      setAttachData([]);
      setCheckRule(false);
      if (props.onDismiss) {
         props.onDismiss();
      }
   };

   return (
      <PanelBase
         headerText={props?.id ? "Баримт бичиг засах" : "Баримт бичиг нэмэх"}
         isOpen={props.isOpen}
         onDismiss={props.onDismiss}
         PanelType="medium"
         delete={props.id > 0 && !isBranchDep ? true : false}
         onDelete={onDelete}
         buttons={
            loadingSave ? <LoadSpin /> :
               !isBranchDep ? <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} /> : null
         }
      >
         <div>
            <div style={{ marginTop: 24 }}>
               <FieldText disable={isBranchDep} required loading={loading} onChanged={fieldOnChanged} title="Баримт бичгийн нэр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} />
            </div>
            <div style={{ marginTop: 12 }}>
               <FieldDropdown disable={isBranchDep} required loading={loading} onChanged={fieldOnChanged} title="Ангилал" keyfield="dOCUMENTCATEGORYID" defaultValue={data.dOCUMENTCATEGORYID} checkreq={checkRule} data={props?.categoryData}/>
            </div>
            <div style={{ marginTop: 12 }}>
               <FieldDropdownMulti disable={isBranchDep} required loading={loading} onChanged={fieldOnChanged} title="Харах эрх" keyfield="sCHOOLTYPEIDS" checkreq={checkRule} defaultValue={data.sCHOOLTYPEIDS} data={dschoolType} />
            </div>
            <div style={{ marginTop: 23 }}>
               <h4>Баримт бичиг</h4>
            </div>
            <div style={{ marginTop: 13 }}>
               <FieldDocumentAttach 
                  documentid={data?.iD}
                  title="Файл хавсаргах" 
                  keyfield='documentattach' 
                  defaultValue={attachData}
                  afterUpload={(data, tFile) => {
                     setAttachData([...attachData, data]);
                     setFiles([...files, tFile]);
                  }}
                  afterSave={() => { 
                     getDocumentAttach();
                  }}
                  disable={isBranchDep ? true : false}
               />
            </div>

         </div>
      </PanelBase>
   );
};

export default NewDocument;
