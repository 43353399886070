import React from 'react';
import { ColEdit, Number } from 'dw-components';
import { Icon } from '@fluentui/react/lib/Icon';
import { Space } from 'antd';
import { ReactComponent as GridAttachIcon } from '../../svg/gridAttach.svg';
import { ReactComponent as EditIndicatorIcon } from '../../svg/indicatorIcons/editIndicator.svg';
import { ReactComponent as SeeIndicatorIcon } from '../../svg/indicatorIcons/seeIndicator.svg';
import { ReactComponent as AddIndicatorIcon } from '../../svg/indicatorIcons/addIndicator.svg';

const IndicatorItem = (props) => {

    return (
        <div style={{
            height: 40,
            width: 700,
            borderRadius: 'calc(.15rem - 1px)',
            boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
            display: 'flex',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            paddingRight: 16,
            justifyContent: 'space-between'
        }}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {props?.typename?.toString() == " засах" ? (<EditIndicatorIcon />)
                : props?.typename?.toString() == " оруулах" ? (<AddIndicatorIcon/>)
                :(<SeeIndicatorIcon/>) }
                <div style={{marginLeft:8}}>
                    <ColEdit onClick={() => props.onClick(props.id, props.title)}>{props.title}</ColEdit>
                </div> 
            </div>
            <div>
                <Space size={16}>
                    {props?.value ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Number value={parseFloat(props?.value).toFixed(2)} />
                            <div style={{ marginTop: 3, marginLeft: 8 }}>
                                <Icon style={{ color: '#107e3e' }} iconName="SkypeCheck" />
                            </div>
                        </div>
                    ) : null}
                    {props?.attach && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginTop: 2 }}>
                                <GridAttachIcon />
                            </div>
                            <div style={{ marginLeft: 8, fontWeight: 400, fontSize: 12 }}>
                                {props?.attach}
                            </div>
                        </div>
                    )}
                </Space>
            </div>
        </div>
    );
}

export default IndicatorItem;