import React, { useEffect, useState, useRef } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Space, Radio, Button } from "antd";

// with auto complete
const AmountShortenButton = (props) => {
    const [selectedShorten, setSelectedShorten] = useState();

    useEffect(() => {
        onShortenClear();
    }, [props?.clearShorten]);

    const onGroupChange = ({ target: { value } }) => {
        switch (value) {
            case "thousand":
                props.setShorten(0.001);
                setSelectedShorten(value);
                break;
            case "million":
                props.setShorten(0.000001);
                setSelectedShorten(value);
                break;
            case "billion":
                props.setShorten(0.000000001);
                setSelectedShorten(value);
                break;
            default:
                props.setShorten(1);
                setSelectedShorten(null);
                break;
        }
    };

    const onShortenClear = () => {
        props.setShorten(1);
        setSelectedShorten(null);
    };

    let showToggleButton;
    if (selectedShorten) {
        showToggleButton = <Button type="primary" shape="circle" icon={<CloseOutlined size="small" />} defaultValue={selectedShorten} size="small" onClick={onShortenClear} />;
    } else {
        showToggleButton = null;
    }

    return (
        <Space align="center" style={{ marginBottom: 16 }}>
            <div style={{ color: '#605E5C', fontWeight: 450, fontSize: 14 }}>Хураангуйлах:</div>
            <Radio.Group size="small" value={selectedShorten} onChange={onGroupChange} optionType="button" buttonStyle="solid">
                <Radio.Button value="thousand">Мянга</Radio.Button>
                <Radio.Button value="million">Сая</Radio.Button>
                <Radio.Button value="billion">Тэрбум</Radio.Button>
            </Radio.Group>
            {showToggleButton}
        </Space>
    );
};

export default AmountShortenButton;
