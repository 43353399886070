import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdown, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";
import { APIGraphQL } from "../../API/APIGraphQL";

const OtherIndicatorPanel = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);

        setLoadingSave(true);
        let vSuccess = false;
        if (tDataEdit?.length > 0 && (depid || schoolid)) {
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.budgetid = parseInt(props?.id);

                tR.schoolname = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.schoolname = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.schoolname = dDeps[tIndex].text;
                    }
                }
                tR.schoolid = parseInt(currentschoolid);
                tDataEdit[i] = tR;
            }

            await API.post("/api/budget/addBudgetOtherInd",
                tDataEdit
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    message.success("Амжилттай");
                    vSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

        }
        setLoadingSave(false);
        await props.afterSave();
        props.onDismiss();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/budgetOtherIndicator?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });


        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        setLoading(false);
        setData(tData);
        setDataEdit(tData);
    }

    const setSchools = async(pSchoolId) => 
    {
        const schools = await getSchools(pSchoolId);
        if(userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);            
        }
        else if(userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6 ){
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;    
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
        }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);
    }, [props?.id]);


    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
        query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
          variables: {
            "typeid": parseInt(typeid)
        }
        }).then(response => {       
        let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
            setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);  
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Бусад үзүүлэлт"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="medium"
            buttons={(
                <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                </div>
            )}
        >
            {props?.showSchool && (
            <div style={{ marginTop: 13 }}>
                <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
            </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                 <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
            </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                    /мян.төгрөг/
         </div>
            {loading ? (<LoadSpin />) : (
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column width={50} caption="№" alignment='center' allowResizing={true}
                            cellRender={(item) => {
                                return (
                                    <div> {item.key?.arrayIndex + 1 + "."} </div>
                                );
                            }}
                        />
                        <Column
                            maxWidth={80}
                            dataField="indicatorname"
                            caption="Үзүүлэлт"
                            cellRender={(item) => {
                                return (
                                    <div style={{ height: 38 }}>
                                        {item.text}
                                    </div>
                                );
                            }}
                        />
                        <Column
                            maxWidth={80}
                            dataField="unitname"
                            caption="Хэмжих нэгж"
                            cellRender={(item) => {
                                return (
                                    <div style={{ height: 38 }}>
                                        {item.text}
                                    </div>
                                );
                            }}
                        />
                        <Column
                            maxWidth={80}
                            dataField="amount"
                            caption="Дүн"

                            format="fixedPoint"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={item?.data?.disable == 1} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.amount, pValue, "amount")} defaultValue={item.data?.amount} fixedDecimalScale={true} />
                                );
                            }} />
                        <Column
                            dataField="note"
                            caption="Тайлбар"
                            width={150}
                            format="fixedPoint"
                            cellRender={(item) => {
                                if(!props?.disable) {
                                    return (
                                        <FieldText disable={props?.disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                    );
                                }
                                else {
                                    return (
                                        <div>{item.data?.note} </div>
                                    );
                                }
                            }} />
                            
                        <Summary>
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="amount"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGrid>
                </div>
            )}
        </PanelBase >
    );
}

export default OtherIndicatorPanel;