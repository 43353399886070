export const UserRole = [
    {
        key: 1,
        text: "Санал оруулах"
    },
    {
        key: 2,
        text: "Засварлах хянах"
    },
    {
        key: 3,
        text: "Батлах"
    },
    {
        key: 4,
        text: "Харах"
    },
    {
        key: 5,
        text: "Хуваарилах"
    }
];