import React, { useEffect, useState, useReducer } from "react";
import { Layout, message } from "antd";
import { IconButton } from "@fluentui/react";
import {
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../../../../Firebase/firebase-config";
import { GridDefault } from "dw-components";
import Sidemenu from "../../../Sidemenu/Sidemenu";
import HrPlanPanel from "./Panel/HrPlanPanel";
import PageHeaderOptions from "../../../../Components/PageHeaderOptions/PageHeaderOptions";
import DegreePanel from "./Panel/DegreePanel";
import { BRANCHSCHOOLSKEYS } from "../../../../Const/BranchSchools";

const HrPlan = () => {
  const userRole = {};
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const HrPlanCollectionRef = collection(db, "hrPlan");

  const [showNew, setShowNew] = useState(false);
  const [total, setTotal] = useState();

  const [hrPlanId, setHrPlanId] = useState(0);
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([
    {
      name: "Сүлжээний зэрэглэл, шатлал",
      fieldName: "ratingHierarchy",
      colSize: "mid",
    },
    {
      name: "Үндсэн цалин",
      fieldName: "salary",
      colSize: "mid",
    },
    {
      name: "Албан тушаал",
      fieldName: "position",
      minColSize: "mid",
    },
    {
      name: "Орон тоо",
      fieldName: "vacancy",
      colSize: "mid",
    },
    {
      name: "Бүрэлдэхүүн болон салбар сургууль, нэгж",
      fieldName: "branchSchool",
      minColSize: "mid",
      onRender: (record) => {
        return BRANCHSCHOOLSKEYS[record.branchSchool];
      },
    },
    {
      name: "Тэнхим, газар хэлтэс, алба",
      fieldName: "department",
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      },
    },
  ]);

  const [degreeColumns, setDegreeColumns] = useState([
    {
      name: "Эрдмийн зэрэг, цол",
      fieldName: "degree",
      minColSize: "mid",
    },
    {
      name: "Албан тушаал",
      fieldName: "position",
      minColSize: "mid",
    },
    {
      name: "Орон тоо",
      fieldName: "vacancy",
      colSize: "mid",
    },
    {
      name: "Судалгааны суурь зардлын олгох дээд хэмжээ",
      fieldName: "maxResearchCost",
      minColSize: "large",
    },
    {
      name: "Салбар сургууль",
      fieldName: "branchSchool",
      minColSize: "mid",
      onRender: (record) => {
        return BRANCHSCHOOLSKEYS[record.branchSchool];
      },
    },
    {
      name: "Байгууллагын нэгж",
      fieldName: "department",
      minColSize: "mid",
    },
    {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      },
    },
  ]);

  const [selectedTab, setSelectedTab] = useState("2022");
  const [selectedTitle, setSelectedTitle] = useState("2022");

  const [menuProps, setMenuProps] = useState({
    items: [
      {
        key: "2022",
        text: "2022",
        href: "",
        target: "_blank",
        onClick: () => {
          onChangeSelectedTab("2022");
          setSelectedTitle("2022");
        },
      },
      {
        key: "2021",
        text: "2021",
        href: "",
        target: "_blank",
        onClick: () => {
          onChangeSelectedTab("2021");
          setSelectedTitle("2021");
        },
      },
      {
        key: "2020",
        text: "2020",
        href: "",
        target: "_blank",
        onClick: () => {
          onChangeSelectedTab("2020");
          setSelectedTitle("2020");
        },
      },
      {
        key: "Эрдмийн зэрэг",
        text: "Эрдмийн зэрэг",
        href: "",
        target: "_blank",
        onClick: () => {
          onChangeSelectedTab("degree");
          setSelectedTitle("Эрдмийн зэрэг");
        },
      },
    ],
  });

  const Query = query(
    HrPlanCollectionRef,
    where("category", "==", selectedTab),
    where("branchSchool", "==", userRole.companyname)
  );

  // Get list data from firebase
  useEffect(async () => {
    let objects = await getDocs(Query);
    objects = objects.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setData(objects);
    setTotal(objects.length);
  }, [reducerValue, selectedTab]);

  const onDismiss = () => {
    setHrPlanId(0);
    setShowNew(false);
  };

  const onChangeSelectedTab = (pValue) => {
    setSelectedTab(pValue);
    setSelectedTitle(pValue.text);
  };

  const onEditClick = (id) => {
    setHrPlanId(id);
    setShowNew(true);
  };

  let vList = null;
  let vPanel = null;
  if (selectedTab == "degree") {
    vList = <GridDefault columns={degreeColumns} data={data}></GridDefault>;
    vPanel = (
      <DegreePanel
        itemId={hrPlanId}
        isOpen={showNew}
        onDismiss={onDismiss}
        forceUpdate={forceUpdate}
      ></DegreePanel>
    );
  } else {
    vList = <GridDefault columns={columns} data={data}></GridDefault>;
    vPanel = (
      <HrPlanPanel
        itemId={hrPlanId}
        isOpen={showNew}
        onDismiss={onDismiss}
        forceUpdate={forceUpdate}
      ></HrPlanPanel>
    );
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      
      <Layout>
        <PageHeaderOptions
          title={selectedTitle}
          data={data}
          columns={columns}
          menuProps={menuProps}
        />
        <div style={{ margin: 16 }}>
          {vList}
          {vPanel}
        </div>
      </Layout>
    </Layout>
  );
};

export default HrPlan;
