import { Space, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import NewDocument from './New/NewDocument';
import { APIGraphQL } from '../../API/APIGraphQL';
import { PageHeader, GridDefaultShimmer, ColEdit } from 'dw-components';
import { ReactComponent as ExcelIcon } from '../../svg/excel.svg';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import moment from 'moment';
import * as xlsx from "xlsx";

const Document = (props) => {

   const [data, setData] = useState({});
   const [oData, setOData] = useState([]);
   const [categoryData, setCategoryData] = useState([]);
   const [showNew, setShowNew] = useState(false);
   const [documentID, setDocumentID] = useState();
   const [loading, setLoading] = useState(false);
   const [isBranchDep, setBranchDep] = useState(false);
   const { userInfo } = useContext(UserContext);

   let columns = [
      {
         name: "№",
         maxWidth: 25,
         minWidth: 25,
         onRender: (row, index) => {
         return <div> {index + 1 + "."} </div>
         }
      },
      {
         name: "Баримт бичгийн нэр",
         fieldName: "nAME",
         minColSize: "mid",
         onRender: (record) => {
            return (
               <div>
                  <ColEdit onClick={() => onEdit(record.iD)}>{record.nAME}</ColEdit>
               </div>
           );
         },
      },
      {
         name: "Ангилал",
         fieldName: "dOCUMENTCATEGORYNAME",
         minColSize: "mid"
      },
      {
         name: "Харах эрх",
         fieldName: "sCHOOLTYPEIDS",
         minColSize: "mid",
         onRender: (record) => {
            let arr = record?.sCHOOLTYPEIDS?.split(',');
            return (
               <div style={{
                  display: 'flex',
                  gap: '10px',
                  height: '20px',
                  width: '61px'
                  }}>
                  {arr?.map(item => (
                     <span style={{background: item == 2 ? '#CAE4F3' : '#EAE3A6', borderRadius: '10px', padding: '2px 8px',}}
                     >{item == 2 ? 'Сургууль' : 'Тэнхим'}</span>
                  ))}
               </div>
            )
         }
      },
      {
         name: "Байршуулсан огноо",
         fieldName: "cREATED",
         minColSize: "mid"
      }
   ];

   if (isBranchDep) {
      columns = columns.filter(item => item?.fieldName !== 'dOCUMENTCATEGORYNAME' && item.fieldName !== 'sCHOOLTYPEIDS');
   }

   useEffect(() => {
      if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 4 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 7) {
         setBranchDep(true);
      }
      getData();
   }, []);

   const getData = async () => {
      setLoading(true);
      let tData = [];
      await APIGraphQL.post("", {
         query: `{
            sharedQuery {
               getDocumentList {
                  iD
                  nAME
                  dOCUMENTCATEGORYNAME
                  sCHOOLTYPEIDS
                  cREATED
                  cREATEDBY
               }
               getDocumentCategoryList {
                  key: iD
                  text: nAME
               }
            }
         }`
      }).then(response => {
         let tResp = response?.data?.data?.sharedQuery?.getDocumentList;
         let tRespCategory = response?.data?.data?.sharedQuery?.getDocumentCategoryList;
         if (tResp?.length > 0) {
            tData = tResp;
         }
         if (tRespCategory?.length > 0) {
            setCategoryData(tRespCategory);
         }
      });
      if (userInfo?.schooltypeid == 4 || userInfo?.schooltypeid == 7) {
         tData = tData.filter(item => item.sCHOOLTYPEIDS.includes(4));
      } else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6 || userInfo?.schooltypeid == 5) {
         tData = tData.filter(item => item.sCHOOLTYPEIDS.includes(2));
      }
      setLoading(false);
      setData(tData);
      setOData(tData);
   }

   const onSearch = (data) => {
      setData(data);
   };

   const onNew = () => {
      setDocumentID(0);
      setShowNew(true);
   };

   const onEdit = (pId) => {
      setDocumentID(pId);
      setShowNew(true);
   }
   
   const onDismiss = () => {
      setDocumentID(0);
      setShowNew(false);
   }

   const downloadExcel = async () => {
      let tData = [];
      data?.map(r => {
          tData.push({
              "Баримт бичгийн нэр": r?.nAME,
              "Ангилал": r?.dOCUMENTCATEGORYNAME,
              "Харах эрх": r?.sCHOOLTYPEIDS,
              "Байршуулсан огноо": r?.cREATED
          });
      });
      const ws = xlsx.utils.json_to_sheet(tData);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Баримт бичиг");
      xlsx.writeFile(wb,"Баримт бичиг " + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx");
    }

   return (
      <div>
         <Row style={{minHeight: "95vh" }} wrap={false}>
            <Col flex="none">
               
            </Col>
            <Col flex="auto" style={{ backgroundColor:"#f0f2f5" }}>
               <PageHeader
                  title={"Нийт"}
                  dataCount={data?.length}
                  buttonTitle={!isBranchDep ? "Баримт бичиг" : ""}
                  onButtonClick={onNew}
                  columns={columns}
                  onSearch={onSearch}
                  data={oData}
                  loading={loading}
                  filteredData={data}
                  addRightButton={(
                     <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                        <Space size={16} >
                              <div onClick={downloadExcel}>
                                 <ExcelIcon />
                              </div>
                        </Space>
                     </div>
                  )}
                  />
               <div style={{ padding: 16 }}>
                  <GridDefaultShimmer columns={columns} data={data} loading={loading}/>
                  <NewDocument id={documentID} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} categoryData={categoryData} isBranchDep={isBranchDep}/>
               </div>
            </Col>
         </Row>
      </div>
  );
}

export default Document;