import React, { useEffect, useState } from "react";
import { GridDefaultShimmer, ColEdit, PageHeader } from "dw-components";
import OperationLightPanel from "./Panel/OperationLightPanel";
import OperationHeatPanel from "./Panel/OperationHeatPanel";
import OperationWaterPanel from "./Panel/OperationWaterPanel";
import { APIGraphQL } from "../../../API/APIGraphQL";

const Operations = () => {

    const [indusercode, setIndusercode] = useState(0);
    const [indcostdate, setIndcostdate] = useState("");
    const [inddate, setInddate] = useState();
    const [showNew, setShowNew] = useState(false);
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterValue, setFilterValue] = useState('heat');
    const [filterData, setFilterData] = useState([{
        key: 'heat',
        text: 'Түлш халаалт'
    }, {
        key: 'water',
        text: 'Цэвэр, бохир ус'
    }, {
        key: 'light',
        text: 'Гэрэл цахилгаан'
    }]);

    const [columns] = useState([
        {
            name: "Огноо",
            fieldName: "cOSTDATE",
            minColSize: "mid",
            onRender: (record) => {
                return (
                    <div>
                        <ColEdit onClick={() => onEditClick(record.uSERCODEID, record.cOSTDATE, record.dATE)}>{record.cOSTDATE }</ColEdit>
                    </div>
                );
            },
        },
        {
            name: "Хэрэглэгчийн код",
            fieldName: "uSERCODE",
            minColSize: "mid",
        },
        {
            name: "Нийт дүн",
            fieldName: "tOTALAMOUNT",
            minColSize: "mid",
            summary: true,
            number: true,
        },

        {
            name: "Мэдээлэл оруулсан огноо",
            fieldName: "cREATED",
            minColSize: "mid",
        },
        {
            name: "Мэдээлэл оруулсан хэрэглэгч",
            fieldName: "cREATEDBY",
            minColSize: "mid",
        }
    ]);

    const getData = async () => {
        let tData = [];
        setLoading(true);
        let colname = "";
        if(filterValue == "light") {
            colname = `lightcostlist {
                            cOSTDATE
                            dATE
                            tOTALAMOUNT
                            uSERCODEID
                            uSERCODE
                            cREATEDBY
                            cREATED
                        }`;
        } else if(filterValue == "heat") {
            colname = `heatcostlist {
                            cOSTDATE
                            dATE
                            tOTALAMOUNT
                            uSERCODEID
                            uSERCODE
                            cREATEDBY
                            cREATED
                        }`;
        } else {
            colname = `watercostlist {
                            cOSTDATE
                            dATE
                            tOTALAMOUNT
                            uSERCODEID
                            uSERCODE
                            cREATEDBY
                            cREATED
                        }`;
        }
        await APIGraphQL.post("", {
            query: `{
                budgetQuery {
                    `+ colname +`
                }
              }
              `
        }).then(response => {
                if(filterValue == "light") {
                    if (response?.data?.data?.budgetQuery?.lightcostlist?.length > 0)
                        tData = response?.data?.data?.budgetQuery?.lightcostlist;
                } else if(filterValue == "heat") {
                    if (response?.data?.data?.budgetQuery?.heatcostlist?.length > 0)
                        tData = response?.data?.data?.budgetQuery?.heatcostlist;
                } else {
                    if (response?.data?.data?.budgetQuery?.watercostlist?.length > 0)
                        tData = response?.data?.data?.budgetQuery?.watercostlist;
                }
         });
        setLoading(false);
        setData(tData);
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, [filterValue]);


    const onEditClick = (id, month, date) => {
        setIndusercode(id);
        setIndcostdate(month);
        setInddate(date);
        setShowNew(true);
      }

    const onNew = () => {
        setIndusercode();
        setIndcostdate();
        setShowNew(true);
    };

    const onDismiss = () => {
        setIndusercode();
        setIndcostdate();
        setShowNew(false);
    };

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onChangeFilter = (p_key, p_name) => {
      setFilterValue(p_key);
    }

    return (
        <div
            style={{
                backgroundColor: "rgb(247, 247, 247)",
            }}
        >
            <PageHeader
                showLeftFilter
                changeFilter={onChangeFilter}
                filterValue={filterValue}
                filterData={filterData}
                buttonTitle= {filterValue == "light" ? "Гэрэл цахилгаан" : filterValue == "heat" ? "Түлш, халаалт" : "Цэвэр, бохир ус"}
                onButtonClick={onNew}
                onSearch={onSearch}
                data={oData}
                filteredData={data}
                columns={columns}/>
                <div style={{ marginLeft: 16, marginRight: 16, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                <GridDefaultShimmer columns={columns} data={data} loading={loading} />
                {filterValue == "light" && 
                    <OperationLightPanel usercodeid={indusercode} costdate={indcostdate} date={inddate} isOpen={showNew} onDismiss={onDismiss} afterSave={getData}></OperationLightPanel>
                }
                {filterValue == "heat" && 
                    <OperationHeatPanel usercodeid={indusercode} costdate={indcostdate} date={inddate} isOpen={showNew} onDismiss={onDismiss} afterSave={getData}></OperationHeatPanel>
                }
                {filterValue == "water" && 
                    <OperationWaterPanel usercodeid={indusercode} costdate={indcostdate} date={inddate} isOpen={showNew} onDismiss={onDismiss} afterSave={getData}></OperationWaterPanel>
                }
            </div>
        </div>
    );
};

export default Operations;
