const DEPARTMENTS = {
    SOL: ["Нийтийн эрх зүйн тэнхим", "Хувийн эрх зүйн тэнхим", "Практик сургалтын төв", "Захиргаа"],
    SEAS: [
        "Хими, биологийн инженерчлэлийн тэнхим",
        "Хэрэглээний математикийн тэнхим",
        "Хүрээлэн буй орчин, ойн инженерчлэлийн тэнхим",
        "Электроник, холбооны инженерчлэлийн тэнхим",
        "Мэдээлэл, компьютерийн ухааны тэнхим",
        "Захиргаа",
    ],
};

const DEPARTMENTKEYS = {
    "Хими, биологийн инженерчлэлийн тэнхим": "ХБИТ",
    "Хэрэглээний математикийн тэнхим": "ХМТ",
    "Хүрээлэн буй орчин, ойн инженерчлэлийн тэнхим": "ХБООИТ",
    "Электроник, холбооны инженерчлэлийн тэнхим": "ЭХИТ",
    "Мэдээлэл, компьютерийн ухааны тэнхим": "МКУТ",
    Захиргаа: "ЗАХ",

    "Нийтийн эрх зүйн тэнхим": "НЭЗТ",
    "Хувийн эрх зүйн тэнхим": "ХЭЗТ",
    "Практик сургалтын төв": "ПСТ",
};

const BRANCHSCHOOLS = [
    "ШУС - Хүмүүнлэгийн ухааны салбар",
    "ШУС - Байгалийн ухааны салбар",
    "ШУС - Нийгмийн ухааны салбар",
    "Ахисан түвшний сургууль",
    "Бизнесийн сургууль",
    "Олон улсын харилцаа, нийтийн удирдлагын сургууль",
    "Хууль зүйн сургууль",
    "Хэрэглээний шинжлэх ухааны сургууль",
    "Завхан сургууль",
    "Эрдэнэт сургууль",
    "Байгаль-Эх Лицей Ахлах Сургууль",
    "Экологийн Боловсролын төв",
    "Өвөр зайсан сургалт судалгааны төв",
    "Монгол судлалын хүрээлэн",
    "Монгол-Японы хүний нөөцийн хөгжлийн төв",
    "МУИС хэвлэлийн газар",
    "МУИС хэвлэх үйлдвэр",
    "Эрдэм шинжилгээ инноваци",
    "Эдийн засгийн хүрээлэн",
    "Цөмийн физикийн судалгааны төв",
    "Оюутны байр",
    "Төвлөрсөн сан",
];

const BRANCHSCHOOLKEYS = {
    "ШУС - Хүмүүнлэгийн ухааны салбар": "ШУС-ХУС",
    "ШУС - Байгалийн ухааны салбар": "ШУС-БУС",
    "ШУС - Нийгмийн ухааны салбар": "ШУС-НУС",
    "Ахисан түвшний сургууль": "АХС",
    "Бизнесийн сургууль": "БС",
    "Олон улсын харилцаа, нийтийн удирдлагын сургууль": "ОУХНУС",
    "Хууль зүйн сургууль": "ХЗС",
    "Хэрэглээний шинжлэх ухааны сургууль": "ХШУС",
    "Завхан сургууль": "ЗС",
    "Эрдэнэт сургууль": "ЭС",
    "Байгаль-Эх Лицей Ахлах Сургууль": "БЭЛАС",
    "Экологийн Боловсролын төв": "ЭБТ",
    "Өвөр зайсан сургалт судалгааны төв": "ӨЗССТ",
    "Монгол судлалын хүрээлэн": "МСХ",
    "Монгол-Японы хүний нөөцийн хөгжлийн төв": "МЯХНХТ",
    "МУИС хэвлэлийн газар": "МХГ",
    "МУИС хэвлэх үйлдвэр": "МХҮ",
    "Эрдэм шинжилгээ инноваци": "ЭШИ",
    "Эдийн засгийн хүрээлэн": "ЭЗХ",
    "Цөмийн физикийн судалгааны төв": "ЦФСТ",
    "Оюутны байр": "ОБ",
    "Төвлөрсөн сан": "ТС",
};

const BRANCHSCHOOLENKEYS = {
    Бүгд: "ALL",
    "ШУС - Хүмүүнлэгийн ухааны салбар": "BOH",
    "ШУС - Байгалийн ухааны салбар": "BONS",
    "ШУС - Нийгмийн ухааны салбар": "DOSS",
    "Ахисан түвшний сургууль": "GRADS",
    "Бизнесийн сургууль": "BS",
    "Олон улсын харилцаа, нийтийн удирдлагын сургууль": "SIRPA",
    "Хууль зүйн сургууль": "SOL",
    "Хэрэглээний шинжлэх ухааны сургууль": "SEAS",
    "Завхан сургууль": "ZA",
    "Эрдэнэт сургууль": "ERD",
    "Байгаль-Эх Лицей Ахлах Сургууль, Экологийн Боловсролын төв": "EECBEHS",
    "Өвөр зайсан сургалт судалгааны төв": "IZTRC",
    "Монгол судлалын хүрээлэн": "IMS",
    "Монгол-Японы хүний нөөцийн хөгжлийн төв": "JICA",
    "МУИС хэвлэлийн газар": "MBOOKS",
    "МУИС хэвлэх үйлдвэр": "MPRESS",
    "Эрдэм шинжилгээ инноваци": "RAI",
    "Эдийн засгийн хүрээлэн": "EI",
    "Цөмийн физикийн судалгааны төв": "NRC",
    "Оюутны байр": "DORM",
    "Төвлөрсөн сан": "CED",
};

export { DEPARTMENTS, DEPARTMENTKEYS, BRANCHSCHOOLS, BRANCHSCHOOLKEYS, BRANCHSCHOOLENKEYS };
