import axios from 'axios';
import { message } from 'antd';

let apiBaseUrl = 'https://localhost:7000/graphql';

if (window?.location?.hostname != 'localhost') {
    apiBaseUrl = window.location.protocol.concat("//").concat(window.location.hostname).concat(":8080/graphql");
}

let errorMessage = "Амжилтгүй боллоо. ";

const auth = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
};

const APIGraphQL = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: false,
    timeout: 240000,
    headers: auth
});

APIGraphQL.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
});

APIGraphQL.interceptors.response.use(function (response) {

    if (response.status !== 200) {
        message.error(errorMessage + response.message);
    }

    if (response?.data?.errors?.length > 0) {
        message.error(response?.data?.errors[0].message);
    }

    return response;
}, async function (error) {
    message.error(errorMessage + error.message);
    return Promise.reject(error);
});

export { APIGraphQL };