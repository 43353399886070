import React, { useEffect, useState, useReducer } from "react";
import { addDoc, collection, getDoc, doc, updateDoc } from "firebase/firestore";
import {
  ButtonDefault,
  FieldDropdown,
  FieldText,
  PanelBase,
  FieldCheck,
  FieldDropdownMulti,
} from "dw-components";

import FieldComboBox from "../../../../Components/FieldComboBox/FieldComboBox";
import { DropdownMenuItemType } from "@fluentui/react";
import { db } from "../../../../Firebase/firebase-config";
import { MessageConst } from "../../../../Const/MessageConst";
import { message } from "antd";
import { BRANCHSCHOOLS } from "../../../../Const/BranchSchools";

const IndicatorPanel = (props) => {
  const [data, setData] = useState({});
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [indicatorGroups, setIndicatorGroups] = useState();

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      setData(tData);
    }
  };
  const IndicatorCollectionRef = collection(db, "otherIndicators");
  // Writing data to firebase
  const createIndicator = async (payload) => {
    try {
      await addDoc(IndicatorCollectionRef, {...payload, values: {}});
      message.success("Үзүүлэлт амжилттай нэмэгдлээ");
      setData({});
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const getIndicator = async (id) => {
    try {
      const IndicatorDoc = doc(db, "otherIndicators", id);
      const response = await getDoc(IndicatorDoc);
      setData(response.data());
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const updateIndicator = async (payload, id) => {
    try {
      const IndicatorDoc = doc(db, "otherIndicators", id);
      await updateDoc(IndicatorDoc, payload);
      message.success("Амжилттай засагдлаа");
      setData({});
    } catch (error) {
      console.log(error)
      message.error("Амжилтгүй");
    }
  };

  const onEdit = async () => {
    await updateIndicator(data, props?.indicatorId);
    props.onDismiss();
    props.forceUpdate();
  };

  const onSubmit = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.indicatorName) {
      isValid = false;
      setWarning("Үзүүлэлтийн нэр" + MessageConst.fieldRequired);
    }

    if (isValid) {
      setIsDisabled(true);
      await createIndicator(data);
      props.onDismiss();
      setData({});
      props.forceUpdate();
      setIsDisabled(false);
      setCheckRule(false);
    }
  };

  useEffect(() => {
    setData({});
    if (props.indicatorId) {
      getIndicator(props.indicatorId);
    }
  }, [props.isOpen]);

  return (
    <PanelBase
      headerText={props.indicatorId ? "Үзүүлэлт засах" : "Үзүүлэлт нэмэх"}
      isOpen={props.isOpen}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      warning={warning}
      PanelType="medium"
      buttons={
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props.indicatorId ? "Засах" : "Хадгалах"}
              onClick={props.indicatorId ? onEdit : onSubmit}
              disable={isDisabled}
            />
          </div>
        </div>
      }
    >
      <div className="register-indicator">
        <div style={{ marginTop: 13 }}>
          <FieldText
            required
            onChanged={fieldOnChanged}
            title="Үзүүлэлтийн нэр"
            keyfield="indicatorName"
            defaultValue={data.indicatorName}
            checkreq={checkRule}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldComboBox
            required
            onChanged={fieldOnChanged}
            title="Үзүүлэлтийн бүлэг"
            keyfield="groupName"
            defaultValue={data.groupName}
            checkreq={checkRule}
            data={props?.groups}
          />
        </div>
      </div>
    </PanelBase>
  );
};

export default IndicatorPanel;
