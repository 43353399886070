import React, { useState, useEffect } from "react";
import {
  ButtonDefault,
  FieldDropdown,
  PanelBase,
  FieldNumber,
  FieldTextArea,
  ButtonSecondary,
  FieldText,
} from "dw-components";

import { Row, Col, message } from "antd";
import FieldUpload from "../../../Components/FieldUpload/FieldUpload";
import FieldNumberFormat from "../../../Components/FieldNumberFormat/FieldNumberFormat";
import { BRANCHSCHOOLS } from "../../../Const/BranchSchools";
import { DEPARTMENTS } from "../../../Const/Departments";
import { MessageConst } from "../../../Const/MessageConst";

//БЭЛАС
const BelasPanel = (props) => {
  const [data, setData] = useState({
    calculateMethodType: "belas",
    indicatorId: props.indicatorData.indicatorId,
    category: props.indicatorData.category,
    distribution: "month",
  });
  const [checkRule, setCheckRule] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);

  const nextStep = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.branchSchool) {
      isValid = false;
      setWarning("Салбар сургууль" + MessageConst.fieldRequired);
    } else if (data.branchSchool != "ALL" && !data.department) {
      isValid = false;
      setWarning("Байгууллага нэгж" + MessageConst.fieldRequired);
    } else if (!data.studentQTY) {
      isValid = false;
      setWarning("Суралцагчийн тоо" + MessageConst.fieldRequired);
    } else if (!data.payment) {
      isValid = false;
      setWarning("Төлбөр" + MessageConst.fieldRequired);
    }
    // else if (!data.duration) {
    //   isValid = false;
    //   setWarning("Хугацаа" + MessageConst.fieldRequired);
    // }

    if (isValid) {
      setCurrentStep((r) => r + 1);
      await changeDistribution(data.distribution);
    }
  };

  const prevStep = () => {
    setCurrentStep((r) => r - 1);
  };

  const changeDistribution = async (key) => {
    let tempData;
    switch (key) {
      case "month":
        tempData = { ...data };
        let perMonth =
          parseFloat(tempData.totalBudgetAmount.replace(/,/g, "")) / 12;
        setData({
          ...tempData,
          distribution: key,
          month1: perMonth,
          month2: perMonth,
          month3: perMonth,
          month4: perMonth,
          month5: perMonth,
          month6: perMonth,
          month7: perMonth,
          month8: perMonth,
          month9: perMonth,
          month10: perMonth,
          month11: perMonth,
          month12: perMonth,
        });
        break;
      case "season":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month10;
        delete tempData.month11;

        let perSeason =
          parseFloat(tempData.totalBudgetAmount.replace(/,/g, "")) / 4;

        setData({
          ...tempData,
          distribution: key,
          month3: perSeason,
          month6: perSeason,
          month9: perSeason,
          month12: perSeason,
        });
        break;
      case "year":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month3;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month6;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month9;
        delete tempData.month10;
        delete tempData.month11;
        delete tempData.month12;
        setData({
          ...tempData,
          distribution: key,
        });
        break;
      default:
        break;
    }
  };

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;

      let duration = 0;
      let qty = tData.studentQTY || 0;
      let payment = tData.payment || "0";

      switch (tData.duration) {
        case "month":
          duration = 12;
          break;
        case "year":
          duration = 1;
          break;
        case "season":
          duration = 4;
          break;
        default:
          duration = 0;
          break;
      }

      if (field == "studentQTY") {
        qty = tData.studentQTY;
      } else if (field == "payment") {
        payment = tData.payment;
      }

      tData.totalBudgetAmount = (
        duration *
        parseInt(qty) *
        parseFloat(payment.replace(/,/g, ""))
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setData(tData);

      if (field == "distribution") {
        await changeDistribution(key);
      }
    }
  };

  const createBelas = async (payload) => {
    try {
      props.setTempData({
        ...payload,
        type: props.type,
        userId: userRole.userid,
      });
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSave = async () => {
    setIsDisabled(true);
    await createBelas(data);
    props.onDismiss();
    setIsDisabled(false);
  };

  useEffect(() => {
    if (props?.data) {
      setData({ ...props.data, ...data });
    }

    if (userRole.role == "DEPARTMENT" && !data.branchSchool) {
      let tData = { ...props?.data, ...data };
      tData.branchSchool = userRole.companyname;
      tData.department = userRole.department;
      setData(tData);
    }
    setDisableSchool(true);
  }, [props.isOpen]);

  let vPanel = null;
  let showDepartment = null;

  if (data.branchSchool == "ALL" || !data.branchSchool) {
    showDepartment = null;
  } else {
    showDepartment = (
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          required
          onChanged={fieldOnChanged}
          title="Байгууллагын нэгж"
          keyfield="department"
          defaultValue={data.department}
          checkreq={checkRule}
          data={DEPARTMENTS[data.branchSchool]}
          disable={disableSchool}
        />
      </div>
    );
  }

  if (currentStep == 0) {
    vPanel = (
      <PanelBase
        headerText={props.name}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        warning={warning}
        buttons={
          <div style={{ display: "flex" }}>
            <div>
              <ButtonDefault text="Үргэлжлүүлэх" onClick={nextStep} />
            </div>
          </div>
        }
      >
        <div className="RegisterBelas">
          <div style={{ marginTop: 25 }}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Салбар сургууль"
              keyfield="branchSchool"
              defaultValue={data.branchSchool}
              checkreq={checkRule}
              data={BRANCHSCHOOLS}
              disable={disableSchool}
            />
          </div>
          {showDepartment}
          <div style={{ marginTop: 13 }}>
            <FieldNumber
              onChanged={fieldOnChanged}
              title="Суралцагчийн тоо"
              keyfield="studentQTY"
              defaultValue={data.studentQTY}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Төлбөр"
              keyfield="payment"
              defaultValue={data.payment}
              checkreq={checkRule}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Хугацаа"
              keyfield="duration"
              defaultValue={data.duration}
              checkreq={checkRule}
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Нийт дүн"
              disable={true}
              keyfield="totalBudgetAmount"
              defaultValue={data.totalBudgetAmount}
              checkreq={checkRule}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldTextArea
              onChanged={fieldOnChanged}
              title="Тайлбар"
              keyfield="description"
              defaultValue={data.description}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldUpload
              filetype="pdf"
              onChanged={fieldOnChanged}
              title="Файл хавсаргах (Зөвхөн PDF файл)"
              keyfield="belasfile"
              defaultValue={data.belasfile}
            />
          </div>
        </div>
      </PanelBase>
    );
  } else {
    vPanel = (
      <PanelBase
        headerText="Нэмэх 2/2"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        buttons={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 16 }}>
              <ButtonSecondary onClick={prevStep} text="Буцах" />
            </div>
            <div>
              <ButtonDefault
                onClick={onSave}
                text={props?.calculateMethodId ? "Засах" : "Хадгалах"}
                disable={isDisabled}
              />
            </div>
          </div>
        }
      >
        <div className="RegisterBelas">
          <Col span={12}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Төсвийн хуваарилалт"
              keyfield="distribution"
              defaultValue={data.distribution}
              checkreq={checkRule}
              defaultSelectedKey="month"
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </Col>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="1-р сар"
                keyfield="month1"
                defaultValue={data.month1}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="7-р сар"
                keyfield="month7"
                defaultValue={data.month7}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="2-р сар"
                keyfield="month2"
                defaultValue={data.month2}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="8-р сар"
                keyfield="month8"
                defaultValue={data.month8}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="3-р сар"
                keyfield="month3"
                defaultValue={data.month3}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="9-р сар"
                keyfield="month9"
                defaultValue={data.month9}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="4-р сар"
                keyfield="month4"
                defaultValue={data.month4}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="10-р сар"
                keyfield="month10"
                defaultValue={data.month10}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="5-р сар"
                keyfield="month5"
                defaultValue={data.month5}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="11-р сар"
                keyfield="month11"
                defaultValue={data.month11}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="6-р сар"
                keyfield="month6"
                defaultValue={data.month6}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="12-р сар"
                keyfield="month12"
                defaultValue={data.month12}
              />
            </Col>
          </Row>
        </div>
      </PanelBase>
    );
  }

  return vPanel;
};

export default BelasPanel;
