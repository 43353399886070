import React, { useEffect, useState } from "react";
import { getDoc, doc, addDoc, updateDoc, collection } from "firebase/firestore";
import {
  ButtonDefault,
  FieldText,
  PanelBase,
  FieldDropdown,
  FieldNumberFormat,
} from "dw-components";
import { db } from "../../../../../Firebase/firebase-config";
import { message } from "antd";
import { MessageConst } from "../../../../../Const/MessageConst";
import { BRANCHSCHOOLS } from "../../../../..//Const/BranchSchools";
import { DEPARTMENTS } from "../../../../../Const/Departments";
import FieldComboBox from "../../../../../Components/FieldComboBox/FieldComboBox";

const DegreePanel = (props) => {
  const userRole = {};
  const [data, setData] = useState({
    category: "degree",
  });
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      setData(tData);
    }
  };

  // Writing data to firebase
  const createHrPlan = async (payload) => {
    try {
      const HrPlanCollectionRef = collection(db, "hrPlan");
      await addDoc(HrPlanCollectionRef, payload);
      message.success("Амжилттай нэмэгдлээ");
      setData({});
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const getHrPlan = async (id) => {
    try {
      const hrPlanDoc = doc(db, "hrPlan", id);
      const response = await getDoc(hrPlanDoc);

      let tempData = { ...response.data(), id: response.id };
      tempData.branchSchool = userRole.companyname;
      setIsDisableFields(true);
      setData(tempData);
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const updateHrPlan = async (payload, id) => {
    try {
      const hrPlanDoc = doc(db, "hrPlan", id);
      await updateDoc(hrPlanDoc, payload);
      message.success("Амжилттай засагдлаа");
      setData({});
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSubmit = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.vacancy) {
      isValid = false;
      setWarning("Орон тоо" + MessageConst.fieldRequired);
    }

    if (isValid) {
      setIsDisabled(true);
      await createHrPlan(data);
      props.onDismiss();
      props.forceUpdate();
    }
  };

  const onEditSubmit = async () => {
    await updateHrPlan(data, props?.itemId);
    props.onDismiss();
    props.forceUpdate();
  };

  useEffect(() => {
    if (props.itemId) {
      getHrPlan(props.itemId);
    }
  }, [props.isOpen]);

  return (
    <PanelBase
      headerText={props?.itemId ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      warning={warning}
      buttons={
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.itemId ? "Засах" : "Хадгалах"}
              onClick={props?.itemId ? onEditSubmit : onSubmit}
              disable={isDisabled}
            />
          </div>
        </div>
      }
    >
      <div className="add-asset">
        <div style={{ marginTop: 25 }}>
          <FieldComboBox
            required
            onChanged={fieldOnChanged}
            title="Бүрэлдэхүүн болон салбар сургууль, нэгж"
            keyfield="branchSchool"
            disable={true}
            defaultValue={data.branchSchool}
            checkreq={checkRule}
            data={BRANCHSCHOOLS}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldComboBox
            required
            onChanged={fieldOnChanged}
            title="Тэнхим, газар хэлтэс, алба"
            keyfield="department"
            disable={true}
            defaultValue={data.department}
            checkreq={checkRule}
            data={DEPARTMENTS[data.branchSchool]}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Эрдмийн зэрэг, цол"
            keyfield="degree"
            checkreq={checkRule}
            defaultValue={data.degree}
            disable={isDisableFields}
            data={[
              { key: "Дэд профессор", text: "Дэд профессор" },
              { key: "Профессор", text: "Профессор" },
              { key: "Доктор", text: "Доктор" },
            ]}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Албан тушаал"
            keyfield="position"
            checkreq={checkRule}
            disable={isDisableFields}
            defaultValue={data.position}
            data={[
              { key: "Ахлах багш", text: "Ахлах багш" },
              { key: "Ахлах нягтлан бодогч", text: "Ахлах нягтлан бодогч" },
              { key: "Багш", text: "Багш" },
              { key: "Гэрээт дэд профессор", text: "Гэрээт дэд профессор" },
              { key: "Гэрээт профессор", text: "Гэрээт профессор" },
              { key: "Дадлагажигч багш", text: "Дадлагажигч багш" },
              { key: "Дэд профессор", text: "Дэд профессор" },
              { key: "Захирлын туслах", text: "Захирлын туслах" },
              {
                key: "Зөвлөх дэд профессор /ТТ/",
                text: "Зөвлөх дэд профессор /ТТ/",
              },
              { key: "Зөвлөх профессор /ТТ/", text: "Зөвлөх профессор /ТТ/" },
              {
                key: "Лаборант /Ком-ийн лаборатори/",
                text: "Лаборант /Ком-ийн лаборатори/",
              },
              { key: "Мэргэжилтэн /бүртгэл/", text: "Мэргэжилтэн /бүртгэл/" },
              {
                key: "Мэргэжилтэн /хичээл зохицуулалт/",
                text: "Мэргэжилтэн /хичээл зохицуулалт/",
              },
              {
                key: "Мэргэжилтэн /хөтөлбөрийн биелэлт/",
                text: "Мэргэжилтэн /хөтөлбөрийн биелэлт/",
              },
              { key: "Мэргэжилтэн /элсэлт/", text: "Мэргэжилтэн /элсэлт/" },
              { key: "Нягтлан бодогч", text: "Нягтлан бодогч" },
              { key: "Профессор", text: "Профессор" },
              {
                key: "Тэнхимийн туслах ажилтан",
                text: "Тэнхимийн туслах ажилтан",
              },
              { key: "Тэнхимийн эрхлэгч", text: "Тэнхимийн эрхлэгч" },
              { key: "дэд профессор", text: "дэд профессор" },
              {
                key: "Тэнхимийн эрхлэгч, Профессор",
                text: "Тэнхимийн эрхлэгч, Профессор",
              },
            ]}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat
            required
            onChanged={fieldOnChanged}
            title="Орон тоо"
            keyfield="vacancy"
            checkreq={checkRule}
            defaultValue={data.vacancy}
          />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat
            required
            onChanged={fieldOnChanged}
            title="Судалгааны суурь зардлын олгох дээд хэмжээ"
            keyfield="maxResearchCost"
            checkreq={checkRule}
            disable={isDisableFields}
            defaultValue={data.maxResearchCost}
          />
        </div>
      </div>
    </PanelBase>
  );
};

export default DegreePanel;
