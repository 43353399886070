export const HEAD = [
  // ---------- Тохиргоо ------------
  // Үзүүлэлт
  {
    id: 2,
    permission: "IND001:EDIT",
  },
  //Хүний нөөцийн төлөвлөгөө
  {
    id: 3,
    permission: "HHRP001:VIEW",
  },
  {
    id: 4,
    permission: "HRP:EDIT",
  },
  // Объектийн бүртгэл
  {
    id: 5,
    permission: "OBR001:VIEW",
  },
  {
    id: 6,
    permission: "OBR001:EDIT",
  },
  // Барааны жагсаалт
  {
    id: 7,
    permission: "PRD001:VIEW",
  },
  {
    id: 8,
    permission: "PRD001:EDIT",
  },
  // Томилолтын мэдээ
  {
    id: 9,
    permission: "BST001:VIEW",
  },
  {
    id: 10,
    permission: "BST001:EDIT",
  },
  // Хөрөнгийн жагсаалт
  {
    id: 11,
    permission: "PRP001:VIEW",
  },
  {
    id: 12,
    permission: "PRP001:EDIT",
  },
  // Сургалтын төлбөр
  {
    id: 13,
    permission: "TTF001:VIEW",
  },
  {
    id: 14,
    permission: "TTF001:EDIT",
  },
  // Шатахууны төлбөр
  {
    id: 15,
    permission: "FLN001:VIEW",
  },
  {
    id: 16,
    permission: "FLN001:EDIT",
  },

  // -------------- ТӨСӨВ -----------------
  // Төвсийн жагсаалт
  {
    id: 17,
    permission: "BDH001:VIEW",
  },
  {
    id: 18,
    permission: "BDH001:EDIT",
  },
  // Төвсийн үүсгэх үзүүлэлтүүд
  {
    id: 19,
    permission: "BDH002:VIEW",
  },
  {
    id: 20,
    permission: "BDH002:EDIT",
  },
  // Ирүүлсэн төсвийн саналууд
  {
    id: 21,
    permission: "BDH003:VIEW",
  },
  {
    id: 22,
    permission: "BDH003:EDIT",
  },
  // Ирүүлсэн салбар сургуулын төсөв
  {
    id: 21,
    permission: "BDH004:VIEW",
  },
  {
    id: 22,
    permission: "BDH004:EDIT",
  },
  // ---------- МЕНЮ ------------
  {
    id: 23,
    permission: "HDH:VIEW",
  },
  {
    id: 24,
    permission: "SDH:VIEW",
  },
];

export const BRANCH = [
  // ---------- Тохиргоо ------------
  // Үзүүлэлт
  {
    id: 1,
    permission: "IND001:VIEW",
  },
  //Хүний нөөцийн төлөвлөгөө
  {
    id: 3,
    permission: "BHRP001:VIEW",
  },
  {
    id: 4,
    permission: "HRP:EDIT",
  },
  // Объектийн бүртгэл
  {
    id: 5,
    permission: "OBR001:VIEW",
  },
  {
    id: 6,
    permission: "OBR001:EDIT",
  },
  // Барааны жагсаалт
  {
    id: 7,
    permission: "PRD001:VIEW",
  },
  {
    id: 8,
    permission: "PRD001:EDIT",
  },
  // Томилолтын мэдээ
  {
    id: 9,
    permission: "BST001:VIEW",
  },
  {
    id: 10,
    permission: "BST001:EDIT",
  },
  // Хөрөнгийн жагсаалт
  {
    id: 11,
    permission: "PRP001:VIEW",
  },
  {
    id: 12,
    permission: "PRP001:EDIT",
  },
  // Сургалтын төлбөр
  {
    id: 13,
    permission: "TTF001:VIEW",
  },
  {
    id: 14,
    permission: "TTF001:EDIT",
  },
  // Шатахууны төлбөр
  {
    id: 15,
    permission: "FLN001:VIEW",
  },
  {
    id: 16,
    permission: "FLN001:EDIT",
  },

  // -------------- ТӨСӨВ -----------------
  // Төвсийн жагсаалт
  {
    id: 17,
    permission: "BDB001:VIEW",
  },
  {
    id: 18,
    permission: "BDB001:EDIT",
  },
  // Нэгжийн ирүүлсэн саналууд
  {
    id: 19,
    permission: "BDB002:VIEW",
  },
  {
    id: 20,
    permission: "BDB002:EDIT",
  },

  // ---------- МЕНЮ ------------
  {
    id: 22,
    permission: "HDB:VIEW",
  },
  {
    id: 23,
    permission: "SDB:VIEW",
  },
];

export const DEPARTMENT = [
  // ---------- Тохиргоо ------------
  // Үзүүлэлт
  {
    id: 1,
    permission: "OIN001:VIEW",
  },
  //Эдийн засгийн ангилал
  {
    id: 1,
    permission: "IND001:VIEW",
  },
  //Хүний нөөцийн төлөвлөгөө
  {
    id: 3,
    permission: "HRP001:VIEW",
  },
  // Объектийн бүртгэл
  {
    id: 5,
    permission: "OBR001:VIEW",
  },
  // Барааны жагсаалт
  {
    id: 7,
    permission: "PRD001:VIEW",
  },
  // Томилолтын мэдээ
  {
    id: 9,
    permission: "BST001:VIEW",
  },
  // Хөрөнгийн жагсаалт
  {
    id: 11,
    permission: "PRP001:VIEW",
  },
  // Сургалтын төлбөр
  {
    id: 13,
    permission: "TTF001:VIEW",
  },
  // Шатахууны төлбөр
  {
    id: 15,
    permission: "FLN001:VIEW",
  },
  // -------------- ТӨСӨВ -----------------
  // Төвсийн жагсаалт
  {
    id: 17,
    permission: "BDD001:VIEW",
  },
  {
    id: 18,
    permission: "BDD001:EDIT",
  },
  // Санал өгөх
  {
    id: 19,
    permission: "BDD002:VIEW",
  },
  {
    id: 20,
    permission: "BDD002:EDIT",
  },

  // ---------- МЕНЮ ------------
  {
    id: 22,
    permission: "HDD:VIEW",
  },
  {
    id: 23,
    permission: "SDD:VIEW",
  },
];

export const ROLES = {
  HEAD: HEAD,
  BRANCH: BRANCH,
  DEPARTMENT: DEPARTMENT,
};
