export const SCHOOLTYPE = [{
    key: 2,
    text: "Сургууль"
}, {
    key: 3,
    text: "Салбар сургууль"
}, {
    key: 4,
    text: "Тэнхим"
}, {
    key: 5,
    text: "Бие даасан нэгж"
}, {
    key: 6,
    text: "Төвлөрсөн сан"
}, {
    key: 7,
    text: "Газар, хэлтэс"
}]