import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { IconButton } from "@fluentui/react";
import { APIGraphQL } from "../../../API/APIGraphQL";
import Number from "../../../Components/Number/Number";
import { PageHeader, GridDefaultShimmerStick } from "dw-components";
import ProductPanel from "./Panels/ProductPanel";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import FileDownload from 'js-file-download';
import { API } from '../../../API/API';

const Products = (props) => {

  const [showNew, setShowNew] = useState(false);
  const [productId, setProductId] = useState();
  const [loading, setLoading] = useState(false);
  const [gridFilter, setGridFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [data, setData] = useState();
  const [oData, setOData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    },
    {
      name: "Эдийн засгийн ангилал",
      fieldName: "economycategoryname",
      maxWidth: 200,
      minWidth: 150
    }, {
      name: "Нормативт бараа материал",
      fieldName: "name",
      maxWidth: 200,
      minWidth: 150
    }, {
      name: "Хэмжих нэгж",
      fieldName: "unitname",
      maxWidth: 120,
      minWidth: 100
    }, {
      name: "Норм",
      fieldName: "quota",
      maxWidth: 70,
      minWidth: 50,
      summary: true,
      number: true
    }, {
      name: "Нэгжийн үнэ",
      fieldName: "unitprice",
      onRender: (item) => {
        return (<Number value={item?.unitprice} decimalScale = {4} />);
      },
      maxWidth: 120,
      minWidth: 100,
      summary: true,
      number: true
    }, {
      name: "Албан тушаал",
      fieldName: "positionname",
      minColSize: "mid"
    }, {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)
              }
            />
          </div>
        );
      }
    }]);

  const getData = async (p_filter) => {
    setLoading(true);

    let tGridFilter = [];
    let tFitler = [];
    if (p_filter?.length > 0) {
      tFitler = p_filter;
    }

    let tData = [];
    await API.post("/api/budget/normativeItemConfigList", tFitler).then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.length > 0) {
          tData = response?.data?.retData;
        }
      }
    }).catch(() => {
      setLoading(false);
    });

    await APIGraphQL.post("", {
      query: `{
        sharedQuery 
        {
          ecoCatByCalcType(calctypeid: 52) {
            key: iD
            text: nAME
          }
      }
    }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.ecoCatByCalcType;
      if (tResp?.length > 0) {
        tGridFilter.push({
          key: "economycategory.id",
          text: "Ангилал",
          data: tResp
        });
      }
    });

    await API.get("/api/budget/filterNormativePositions").then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.length > 0) {
          tGridFilter.push({
            key: "normativeitem.positionid",
            text: "Албан тушаал",
            data: response?.data?.retData
          });
        }
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
    setOData(tData);
    setGridFilter(tGridFilter);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setShowNew(true);
  };

  const onDismiss = () => {
    setProductId();
    setShowNew(false);
  };

  const onSearch = (p_data) => {
    setData(p_data);
  };

  const onEditClick = (id) => {
    setProductId(id);
    setShowNew(true);
  };

  const downloadExcel = async () => {
    let tFitler = [];
    if (filteredData?.length > 0) {
      tFitler = filteredData;
    }

    await API.post("/api/budget/normativeItemListTemp?filetype=xlsx", tFitler, {
      'responseType': 'blob'
    }).then(response => {
      FileDownload(response.data, "Норматив бараа материал.xlsx");

    }).catch(response => {
      setLoadingDownload(false);
    });
    setLoadingDownload(false);
  }

  const onGridFilterChange = async (p_gridfiltervalue) => {
    setFilteredData(p_gridfiltervalue);
    getData(p_gridfiltervalue);
  }

  return (
    <div>
      <PageHeader
        title={"Нийт"}
        dataCount={data?.length}
        buttonTitle="Нормативт бараа материал"
        onButtonClick={onNew}
        onSearch={onSearch}
        data={oData}
        filteredData={data}
        columns={columns}
        addRightButton={(
          <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
            <Space size={16} >
              <div onClick={downloadExcel}>
                <ExcelIcon />
              </div>
            </Space>
          </div>
        )}
        gridFilter={gridFilter}
        onGridFilterChange={onGridFilterChange}
      />
      <GridDefaultShimmerStick columns={columns} data={data} loading={loading} summary />
      <ProductPanel
        id={productId}
        isOpen={showNew}
        onDismiss={onDismiss}
        afterSave={getData}
      ></ProductPanel>
    </div>
  );
};

export default Products;
