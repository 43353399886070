import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin, FieldText, FieldTextArea, FieldNumberFormat } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";
import { URLTYPE } from "../../../../Const/PurchaseInvestment";

const PurchasePanel = (props) => {
    const { userInfo } = useContext(UserContext)

    const [data, setData] = useState({});
    const [checkRule, setCheckRule] = useState(false);
    const [warning, setWarning] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dUnit, setDUnit] = useState([]);
    const [dPurInv, setDPurInv] = useState([]);
    const [school, setSchool] = useState();
    const [dPItem, setDPItem] = useState([]);
    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);


    const isMovable = [
        {
            key: 1,
            text: "Тийм",
        },
        {
            key: 2,
            text: "Үгүй",
        },
    ]
    
    const fieldOnChanged = async (key, text, field, fieldName) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;

            if (fieldName) {
                tData[fieldName] = text;
            }

            if (field == "uNITPRICE") {
                tData.tOTALBUDGET = key * tData.qTY || 0;
            }

            if (field == "qTY") {
                tData.tOTALBUDGET = tData.uNITPRICE * key || 0;
                tData.cURRENTYEARPRICE = tData.tOTALBUDGET
            }

            if (field == "sPTOPPRID") {
                tData.sPGOALID = null;
                tData.sPINDID = null;
                tData.sPSTRID = null;
            }

            if (field == "sPGOALID") {
                tData.sPINDID = null;
                tData.sPSTRID = null;
            }

            if (field == "sPSTRID") {
                tData.sPINDID = null;
            }

            if (field == "pASTYEARC2PRICE" && tData.pASTYEARC2PRICE == 0) { tData.cURRENTYEARPRICE = tData.tOTALBUDGET } else
            if (field == "pASTYEARC2PRICE") { tData.cURRENTYEARPRICE = tData.tOTALBUDGET - key };


            if (field == "pURINVID") {
                
                let tItem= [];
                await APIGraphQL.post("", {
                    query: `query ($purinvid: Int) {
                    sharedQuery {
                      getPurchaseItemByEC(purinvid: $purinvid) {
                        key: iD
                        text: nAME
                      }
                    }
                  }`,
                    variables: {
                        "purinvid": parseInt(key)
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.getPurchaseItemByEC;
                    if (tResp) {
                        tItem = tResp;
                    }
                }).catch(() => {
                    setLoading(false);
                });
                setDPItem(tItem);
            }
            setData(tData);
            if (field == "pURCHASEITEMID") {
                await APIGraphQL.post("", {
                    query: `query ($purchaseitemid: Int) {
                    sharedQuery {
                      getPurchaseItem(id: $purchaseitemid) {
                        pURINVID
                        pURINVNAME
                        uNITID
                        uNITNAME
                        uNITPRICE
                      }
                    }
                  }`,
                    variables: {
                        "purchaseitemid": parseInt(key)
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.getPurchaseItem;
                    if (tResp) {
                        tData = { ...tData, ...tResp };
                    }
                }).catch(() => {
                    setLoading(false);
                });
            }

            setData(tData);
        }
    };

    const getData = async () => {
        setLoading(true)
        let tDUnit = [];
        let tDSchool = {};
        let tDPurInv = [];

        await APIGraphQL.post("", {
            query:  `query ( $id: Int) {
                sharedQuery {
                                    
                  getUnits{
                      key: iD
                      text: nAME
                  }
                  getSchoolParent (id: $id) {
                      iD
                      nAME
                  }
                  getPurchaseItemPEC {
                    key: pURINVID
                    text: pURINVNAME
                  }
                }
              }`,
            variables: {
                id: userInfo.schoolid
            },
        }).then((response) => {
            let tUResp = response?.data?.data?.sharedQuery?.getUnits;
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolParent;
            let tPIResp = response?.data?.data?.sharedQuery?.getPurchaseItemPEC;

            if (tUResp?.length > 0) {
                tDUnit = tUResp;
            }
            if (tSResp) {
                tDSchool = tSResp;
            }
            if (tPIResp?.length > 0) {
                tDPurInv = tPIResp;
            }

        }).catch(() => {
            setLoading(false);
        });

        setDUnit(tDUnit);
        setSchool(tDSchool);
        setDPurInv(tDPurInv);

        let tData = {};
        if (props?.id) {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                sharedQuery {
                  getBudgetPurchase(id: $id) {
                    iD
                    pURCHASEITEMID
                    pURCHASEITEMNAME
                    qTY
                    uNITID
                    uNITNAME
                    uNITPRICE
                    cURRENTYEARPRICE
                    pASTYEARC2PRICE
                    iSMOVABLE
                    nOTE
                    bUDGETID
                    sCHOOLID
                    sCHOOLNAME
                    pARENTPURINVID
                    pURINVID
                    pURINVNAME
                    iSCHILD
                    cREATED
                    cREATEDBY
                    tOTALBUDGET
                    dEPARTMENTID
                    dEPARTMENTNAME
                    uNIVERSITYID
                    uNIVERSITYNAME
                    bUDGETPURINVID
                    dEPARTMENTPRICE
                    sCHOOLPRICE
                    uNIVERSITYPRICE
                    kPIID
                    kPINAME
                    oUTCOME
                    oUTPUT
                    iMPACT
                    sPGOALID
                    sPGOALNAME
                    sPINDID
                    sPINDNAME
                    sPSTRID
                    sPSTRNAME
                    sPTOPPRID
                    sPTOPPRNAME
                  }
                }
              }`,
                variables: {
                    id: parseInt(props?.id),
                },
            }).then((response) => {
                let tResp = response?.data?.data?.sharedQuery?.getBudgetPurchase;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(() => {
                setLoading(false);
            });
        }

        setData(tData);
        setLoading(false);
    };

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    useEffect(() => {
        getData();
        getSPlan();
    }, [props?.id]);

    const onSave = async () => {
        let vRule = true;
        setCheckRule(true);

        let tData = { ...data };

        if (!tData?.pURCHASEITEMNAME ||
            !tData?.qTY || 
            !tData?.uNITPRICE ||
            !tData?.uNITID ||
           // !tData?.tOTALBUDGET||
            !tData?.iSMOVABLE) vRule = false;

        if (vRule) {

            if (tData?.qTY == null || tData?.qTY == undefined) {
                tData.qTY = 0;
            }

            if (tData?.tOTALBUDGET == null || tData?.tOTALBUDGET == undefined) {
                tData.tOTALBUDGET = 0;
            } 

            if (tData?.pASTYEARC2PRICE == null || tData?.pASTYEARC2PRICE == undefined) {
                tData.pASTYEARC2PRICE = 0;
            } 

            let tSuccess = false;
            if (!props.id) {
                tData.iD = 0;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
                tData.dEPARTMENTID = parseInt(userInfo?.schoolid);
                tData.dEPARTMENTNAME = userInfo?.schoolname;
                tData.sCHOOLID = school?.iD;
                tData.sCHOOLNAME = school?.nAME;
                tData.uNIVERSITYID = 1;
                tData.uNIVERSITYNAME = "Монгол улсын их сургууль"
                
                if(props?.type && props.type == URLTYPE.BUDGET){
                    tData.bUDGETID = parseInt(props?.itemId);
                    tData.bUDGETPURINVID = props?.secondaryId
                } else if (props.type && props.type == URLTYPE.PURCHASEINVESTMENT) {
                    tData.bUDGETPURINVID = parseInt(props?.itemId);
                    tData.bUDGETID = props?.secondaryId
                }
            }
            
            tData.dEPARTMENTPRICE = tData.tOTALBUDGET;
            tData.sCHOOLPRICE = tData.tOTALBUDGET;
            tData.uNIVERSITYPRICE = tData.tOTALBUDGET;

            tData.sPGOALNAME = splan.find(x => x.key == tData.sPGOALID)?.text;
            tData.sPINDNAME = splan.find(x => x.key == tData.sPINDID)?.text;
            tData.sPSTRNAME = splan.find(x => x.key == tData.sPSTRID)?.text;
            tData.sPTOPPRNAME = splan.find(x => x.key == tData.sPTOPPRID)?.text;
            tData.kPINAME = kpis.find(x => x.key == tData.kPIID)?.text;
            
            setLoadingSave(true);
            await APIGraphQL.post("", {
                query: `mutation($data: BudgetPurchaseInputType) {
                    sharedMutation {
                      addBudgetPurchase(data: $data) {
                        iD
                      }
                    }
                  }`,
                variables: {
                    data: tData,
                },
            }).then((response) => {
                setLoadingSave(false);
                if (response?.data?.data?.sharedMutation?.addBudgetPurchase?.iD) {
                    tSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    };

    const onDismiss = () => {
        setData({});
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    };

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteBudgetPurchase($id: Int) {
            sharedMutation {
                deleteBudgetPurchase(id: $id)
            }
          }
          `,
            variables: {
                id: parseInt(props.id),
            },
        }).then((response) => {
            if (response?.data?.data?.sharedMutation?.deleteBudgetPurchase == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
            }
        });
    };

    let showfields;
    if(data.iSMOVABLE == 1) {
        showfields = <>
            <div style={{ marginTop: 13 }}>
                <FieldNumberFormat title="Санхүүжилт хийсэн дүн (өмнөх оны эцсээр, төгрөг)" onChanged={fieldOnChanged} keyfield="pASTYEARC2PRICE" defaultValue={data?.pASTYEARC2PRICE} />
            </div>
            <div style={{ marginTop: 13 }}>
                <FieldNumberFormat disable={true} title="Төсөвт онд худалдан авах (нийт) үнийн дээд хязгаар (төгрөг)" onChanged={fieldOnChanged} keyfield="cURRENTYEARPRICE" defaultValue={data?.cURRENTYEARPRICE} />
            </div>
        </>
    }

    return (
        <PanelBase
            headerText={props?.id ? "Засах" : "Нэмэх"}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            onDelete={onDelete}
            warning={warning}
            PanelType="medium"
            buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
            delete={props?.id ? true : false}
        >
            <div>
            <div style={{ marginTop: 13 }}>            
                {props.id ?
                    <FieldText disable={true} onChanged={fieldOnChanged} title="Хөрөнгийн ангилал" keyfield="pURINVNAME" defaultValue={data.pURINVNAME} checkreq={checkRule} />
                        :
                    <FieldDropdown required onChanged={fieldOnChanged} title="Хөрөнгийн ангилал" keyfield="pURINVID" keyfieldname="pURINVNAME" defaultValue={data.pURINVID} checkreq={checkRule} data={dPurInv} />
                }
                 </div>
                   <div style={{ marginTop: 13 }}>
                {props.id?
                    <FieldText disable={true} onChanged={fieldOnChanged} title="Хөрөнгийн нэр төрөл" keyfield="pURCHASEITEMNAME" defaultValue={data.pURCHASEITEMNAME} checkreq={checkRule} />
                    :
                    <FieldDropdown required  onChanged={fieldOnChanged} title="Хөрөнгийн нэр төрөл" keyfield="pURCHASEITEMID" keyfieldname="pURCHASEITEMNAME" defaultValue={data.pURCHASEITEMID} checkreq={checkRule} data={dPItem}/>
                }
                </div> 
                <div style={{ marginTop: 13 }}>
                <FieldText disable={true} loading={loading} onChanged={fieldOnChanged} title="Хэмжих нэгж" keyfield="uNITID" keyfieldname="uNITNAME" defaultValue={data.uNITNAME} checkreq={checkRule} />               
                 </div>
                <div style={{ marginTop: 13 }}>
                 <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Нэгж бүрийн үнэ (төгрөг)" keyfield="uNITPRICE" defaultValue={data.uNITPRICE}/>
                </div>  
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat title="Тоо хэмжээ" onChanged={fieldOnChanged} keyfield="qTY" defaultValue={data?.qTY} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldNumberFormat disable={true} title="Нийт төсөвт өртөг(төгрөг)" onChanged={fieldOnChanged} keyfield="tOTALBUDGET" defaultValue={data?.tOTALBUDGET} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required onChanged={fieldOnChanged} title="Он дамжсан эсэх" keyfield="iSMOVABLE" defaultValue={data.iSMOVABLE} checkreq={checkRule} data={isMovable} />
                </div>
                {showfields}
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Тайлбар" onChanged={fieldOnChanged} keyfield="nOTE" defaultValue={data?.nOTE} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Хүрэх үр дүн /outputs/" onChanged={fieldOnChanged} keyfield="oUTPUT" defaultValue={data?.oUTPUT} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Хүрэх үр дагавар /outcome/" onChanged={fieldOnChanged} keyfield="oUTCOME" defaultValue={data?.oUTCOME} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldTextArea title="Үр нөлөө /Impact/" onChanged={fieldOnChanged} keyfield="iMPACT" defaultValue={data?.iMPACT} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Тэргүүлэх чиглэл"
                        keyfield="sPTOPPRID"
                        onChanged={fieldOnChanged} 
                        defaultValue={data?.sPTOPPRID}
                        data={splan.filter(x => x.typeid == 2)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Зорилт"
                        keyfield="sPGOALID"
                        onChanged={fieldOnChanged} 
                        defaultValue={data?.sPGOALID}
                        data={splan.filter(x => x.typeid == 3 && x.parentid == data?.sPTOPPRID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Стратеги"
                        keyfield="sPSTRID"
                        onChanged={fieldOnChanged} 
                        defaultValue={data?.sPSTRID}
                        data={splan.filter(x => x.typeid == 4 && x.parentid == data?.sPGOALID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Хэмжих үзүүлэлт"
                        keyfield="sPINDID"
                        onChanged={fieldOnChanged} 
                        defaultValue={data?.sPINDID}
                        data={splan.filter(x => x.typeid == 5 && x.parentid == data?.sPSTRID)}
                    />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown
                        title="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                        keyfield="kPIID"
                        onChanged={fieldOnChanged} 
                        defaultValue={data?.kPIID}
                        data={kpis}
                    />
                </div>
            </div>
        </PanelBase>
    );
};

export default PurchasePanel;
