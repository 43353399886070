import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, Col, Row, FieldDropdown, ButtonSecondary, FieldNumber } from "dw-components";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem, GroupItem } from "devextreme-react/data-grid";
import { message } from "antd";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { Icon } from '@fluentui/react/lib/Icon';
import { UserType } from "../../Const/UserType";

const BHourlyWagePanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [dataOver, setDataOver] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);

    const [dTLevel, setDTLevel] = useState([]);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        let tDataOverEdit = dataOver;

        if (!tDataOverEdit.amount)
            tDataOverEdit.amount = 0;

        if ((tDataEdit?.length > 0 || tDataOverEdit?.amount >= 0) && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.schoolname = "";
                delete tR.arrayIndex;
                delete tR.edit;
                if (tR.id == 0 || !tR.id) {
                    tR.id = 0;
                    tR.created = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.createdby = userInfo?.username;
                delete tR.iD;
                tDataEdit[i] = tR;
            }
            if (!tDataOverEdit?.id) {
                tDataOverEdit.id = 0;
            }

            await API.post("api/budget/calcBHourlyWage?budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid + "&overtimeid=" + tDataOverEdit.id + "&overtimeamount=" + tDataOverEdit.amount,
                tDataEdit
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    message.success("Амжилттай");
                    vSuccess = true;
                }
            }).catch(() => {
                setLoading(false);
            })

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            message.warning("Мэдээлэл бүрэн оруулна уу!");
            props.onDismiss();
        }
        await props.afterSave();
    }

    const getData = async (pSchoolId, isalldep = 0, deplength = 0) => {
        
        let tData = [];
        let tDataOver = {};
        let tDLevel = [];

        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/budgetHourlyWageOvertime?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.wage;
                if (response?.data?.retData.over.length > 0)
                    tDataOver = response?.data?.retData.over[0];
                    let tSchoolTypeID = -1;
                    let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                    if (tIndex >= 0) {
                        tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                    }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                        getTrainingLevelList {
                            key: iD
                            text: nAME
                        }
                    }
                }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getTrainingLevelList?.length > 0) {
                tDLevel = tResp?.sharedQuery?.getTrainingLevelList;
            }
        }).catch(() => {
            setLoading(false);
        });

        setLoading(true);

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setDataOver(tDataOver);
        setDTLevel(tDLevel);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const fieldOverOnChanged = (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...dataOver };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setDataOver(tData);
        }
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onDelete = async (pIndex, pId) => {
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBudgetHourlyWage(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { });
        }

        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1, tddep?.length);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Цагийн хөлс, илүү цагийн зардал оруулах"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="medium"
            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div>
                        <Row justify="space-between">
                            <Col>
                                {(!disable &&
                                    <Row justify="start" >
                                        <Col style={{ marginTop: 24 }}>
                                            <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Row style={{ marginTop: 30 }}>
                                <Col justify="end">
                                    /мян.төгрөг/
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="parentschoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    format="fixedPoint"
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    format="fixedPoint"
                                    groupIndex={1} />
                            )}
                            <Column
                                dataField="traininglevelname"
                                caption="Нэмэгдэл хөлс, цагийн хөлс, бусад"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "traininglevelid", pNameValue, "traininglevelname")} defaultValue={item.data?.traininglevelid} data={dTLevel} />
                                    );
                                }} />
                            <Column
                                dataField="contractfee"
                                caption="Гэрээт"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "contractfee")} defaultValue={item.data?.contractfee} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="additional"
                                caption="Нэмэгдэл"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "additional")} defaultValue={item.data?.additional} fixedDecimalScale={true} />
                                    );
                                }} />
                            ({!disable &&
                                <Column
                                    dataField="action"
                                    caption=""
                                    width={30}
                                    cellRender={(item) => {
                                        return (
                                            <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                                <Icon iconName="Delete" />
                                            </div>
                                        );
                                    }} />
                            })
                            <GroupItem
                                column="contractfee"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <GroupItem
                                column="additional"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="contractfee"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                                <TotalItem
                                    column="additional"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldNumber disable={disable} required onChanged={fieldOverOnChanged} title="Илүү цаг" keyfield="amount" defaultValue={dataOver?.amount} fixedDecimalScale={true} />
                    </div>
                    {!disable &&
                        <div style={{ marginTop: 24 }}>
                            Анхааруулга: Цалин бодсоны дараа цагийн хөлс, илүү цаг тооцоологдохыг анхаарна уу!
                        </div>
                    }
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                    </div>
                </div>

            )}
        </PanelBase >
    );
}

export default BHourlyWagePanel;