import React, { useEffect, useState } from "react";
import { GridDefaultShimmer, ColEdit, PageHeader } from "dw-components";
import FundDetail from "./Panel/FundDetail";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { useNavigate } from "react-router-dom";
import { getYearStart } from "@fluentui/react";

const Fund = () => {

    const navigate = useNavigate();
    const [indid, setIndid] = useState(0);
    const [showNew, setShowNew] = useState(false);
    const [data, setData] = useState([]);
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [filterValue, setFilterValue] = useState('notfund');
    // const [filterData, setFilterData] = useState([{
    //     key: 'notfund',
    //     text: 'Төвлөрүүлээгүй'
    // }, {
    //     key: 'fund',
    //     text: 'Төвлөрүүлсэн'
    // }]);

    const [columns, setColumns] = useState([
        {
            name: "Төсөв",
            fieldName: "bUDYEAR",
            minColSize: "mid",
            onRender: (item) => {
                return (
                    <div>
                        <ColEdit onClick={() => onEditClick(item.bUDID)}>{item.bUDYEAR}</ColEdit>
                    </div>
                );
            },
        },
        {
            name: "Нээсэн огноо",
            fieldName: "sTARTDATE",
            minColSize: "mid",

        },
        {
            name: "Албан тушаалтны нэр",
            fieldName: "oWNERNAME",
            minColSize: "mid",
        },
        {
            name: "Төлөв",
            fieldName: "sTATUSNAME",
            onRender: (item) => {
                return (
                  item.budgetfunds?.sTATUSNAME
                );
              }
        }
    ]);

    const [filterValue, setFilterValue] = useState('open');
    const [filterData, setFilterData] = useState([{
        key: 'open',
        text: 'Нээлттэй'
    }, {
        key: 'closed',
        text: 'Хаалттай'
    }]);

    const getData = async () => {
        let tData = [];
        let latestYear = 0;
        setLoading(true);
        await APIGraphQL.post("", {
            query: `query ($rowstate: String) {
                budgetQuery {
                  budgets(rowstate: $rowstate) {
                    bUDID:iD
                    bUDYEAR: yEAR
                    sTARTDATE
                    eNDDATE
                    oWNERNAME
                    sTATUSID
                    sTATUSNAME
                    budgetfunds {
                    iD
                    yEAR
                    bUDGETID
                    sTATUSNAME
                    }
                  }
                }
              }
              `,
            variables: {
                "rowstate": filterValue
            }
        }).then(response => {
            let tResp = response?.data?.data?.budgetQuery?.budgets;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        }).catch(response => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }
    useEffect(() => {
        getData();
    }, [filterValue]);


    const onEditClick = (pId) => {
        navigate("/budget/fund/" + pId);
      }

    const onDismiss = () => {
        setIndid();
        setShowNew(false);
    };

    const onSearch = (p_data) => {
        setData(p_data);
    }

    const onChangeFilter = (p_key, p_name) => {
      setFilterValue(p_key);
    }

    return (
        <div
            style={{
                backgroundColor: "rgb(247, 247, 247)", 
            }}
        >
            <PageHeader
                showLeftFilter
                changeFilter={onChangeFilter}
                filterValue={filterValue}
                filterData={filterData}
                onSearch={onSearch}
                data={oData}
                filteredData={data}
                dataCount={data?.length}
                columns={columns}/>
                <div style={{ marginLeft: 8, marginRight: 8, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                <GridDefaultShimmer columns={columns} data={data} loading={loading} />
                </div>
        </div>
    );
};

export default Fund;
