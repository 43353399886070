import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { Layout, Table, Form, Input, message } from "antd";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../Firebase/firebase-config";
import { PageHeader } from "dw-components";
import Sidemenu from "../../../Sidemenu/Sidemenu";
import { IconButton, registerIconAlias } from "@fluentui/react";
import { categoryTree, groupListData, getOthersBranchData } from "../../../../Utils";

import Can from "../../../../Components/Can/Can";

import { DEPARTMENTS, DEPARTMENTKEYS } from "../../../../Const/Columns";

const EditableContext = createContext(null);

const OtherIndicators = (props) => {
    const userRole = {};
    const IndicatorCollectionRef = collection(db, "otherIndicators");
    const IndicatorGroupsCollectionRef = collection(db, "otherGroups");

    const [saved, setSaved] = useState(1)
    const [data, setData] = useState([]);

    const handleSave = (row, editedData) => {
        const tempData = [...data];
        tempData.map((item) => {
            item.children.map(async (r) => {
                if (r.code == row.code) {
                    r.values = row.values;
                    const IndicatorDoc = doc(db, "otherIndicators", row.indicatorId);

                    if (!row.values) row.values = {}
                    if (row.values[userRole.companyname]) {
                        await updateDoc(IndicatorDoc, {
                            values: { ...row.values, [userRole.companyname]: { ...row.values[userRole.companyname], ...editedData } },
                        });
                    } else {
                        row.values[userRole.companyname] = {};
                        await updateDoc(IndicatorDoc, {
                            values: { ...row.values, [userRole.companyname]: { ...row.values[userRole.companyname], ...editedData } },
                        });
                    }

                    setSaved(saved + 1)
                    message.success("Амжилттай засагдлаа");
                }
            });
            return item;
        });
    }

    let departmentCols = DEPARTMENTS[userRole.companyname].map((item) => ({
        title: DEPARTMENTKEYS[item],
        dataIndex: item,
        key: item,
        width: 120,
        align: "left",
        // editable: true,
        render: (text, record) => {
            if (record.indicatorId) {
                if (record.values[item]) {
                    return record.values[item]
                } else {
                    return 0
                }
            }
        },
    }));

    const columns = [
        {
            title: "Үзүүлэлтийн нэр",
            dataIndex: "name",
            key: "name",
            width: 350,
            fixed: "left",
        },
        {
            title: "Нэгтгэл",
            dataIndex: "value",
            key: "value",
            fixed: "left",
            width: 150,
            // editable: true,
            render: (text, record) => {
                if (record.indicatorId) {
                    let total = 0;

                    if (record.values) {
                        for (const key in record.values) {
                            if (Object.hasOwnProperty.call(record.values, key)) {
                                total += parseInt(record.values[key]);

                            }
                        }
                    }
                    return total;
                }
            },
        },
        ...departmentCols,
    ];

    // Get list data from firebase
    useEffect(async () => {
        const indicatorDocs = await getDocs(IndicatorCollectionRef);
        const IndicatorGroupDocs = await getDocs(IndicatorGroupsCollectionRef);

        const indicators = indicatorDocs.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
            ...doc.data(),
        }));

        const categories = await categoryTree(indicatorGroups, "groupParent", "groupName");

        const groupedIndicators = await groupListData(indicators);
        const listData = await getOthersBranchData(categories, groupedIndicators, userRole.companyname);
        setData(listData);
    }, [saved]);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record.values[dataIndex],
            });
        };

        const save = async () => {
            try {
                const editedValue = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, values: { ...record.values, ...editedValue } }, editedValue);
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const customColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => {
                if (record.indicatorId) {
                    return {
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave: handleSave,
                    };
                }
            },
        };
    });

    return (
        <div style={{ margin: 16 }}>
            <Table
                components={components}
                rowClassName={() => "editable-row"}
                columns={customColumns}
                dataSource={data}
                childrenColumnName="children"
                scroll={{ x: 1500 }}
                size="small"
                bordered
                pagination={false}
            />
        </div>
    );
};

export default OtherIndicators;
