export const BudgetYear = [
    {
        key: "2022",
        text: "2022"
    },
    {
        key: "2023",
        text: "2023"
    },
    {
        key: "2024",
        text: "2024"
    },
    {
        key: "2025",
        text: "2025"
    },
    {
        key: "2026",
        text: "2026"
    },
    {
        key: "2027",
        text: "2027"
    },
    {
        key: "2028",
        text: "2028"
    },
    {
        key: "2029",
        text: "2029"
    },
    {
        key: "2030",
        text: "2030"
    }
];