import React from 'react';
import { CommandButton } from '@fluentui/react';

const ButtonReport = (props) => {

    const onChange = (pValue) => {
        if (props?.onClick) {
            props.onClick(pValue);
        }
    }

    return (
        <CommandButton
            text={props?.title}
            iconProps={{ iconName: 'DownloadDocument' }}
            onChange={onChange}
            styles={{
                root: {
                    height: 28
                }
            }}
            menuProps={{
                items: [
                    {
                        key: 'Excel',
                        text: 'Excel',
                        iconProps: { iconName: 'ExcelDocument' },
                        onClick: () => onChange({ id: props?.id, name: props?.title, filetype: "xlsx" })
                    },
                    {
                        key: 'Pdf',
                        text: 'Pdf',
                        iconProps: { iconName: 'PDF' },
                        onClick: () => onChange({ id: props?.id, name: props?.title, filetype: "pdf" })
                    },
                ]
            }}
        />
    );
}

export default ButtonReport;