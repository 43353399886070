export const Coverage = [
    {
        key: 1,
        text: "Бүгд"
    },
    {
        key: 4,
        text: "Газар, хэлтэс"
    },
    {
        key: 2,
        text: "Сургууль, Төвлөрсөн сан"
    },
    {
        key: 3,
        text: "МУИС"
    }
];

export const Level = [
    {
        key: 1,
        text: "Тэнхим"
    },
    {
        key: 4,
        text: "Газар, хэлтэс"
    },
    {
        key: 2,
        text: "Сургууль, Төвлөрсөн сан"
    },
    {
        key: 3,
        text: "МУИС"
    }
];