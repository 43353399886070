import React, { useEffect, useState } from "react";
import { Row, Col,Space } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefaultShimmer, PageHeader } from "dw-components";
import { APIGraphQL } from "../../../API/APIGraphQL";
import GeneralIndicatorPanel from "./Panel/GeneralIndicatorPanel";
import {ReactComponent as ExcelIcon} from '../../../svg/excel.svg';
import moment from "moment";
import * as xlsx from "xlsx";

const GeneralIndicators = (props) => {

    const [data, setData] = useState({});
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const [indid, setindid] = useState();

    const [columns] = useState([
        {
            name: "№",
            onRender: (row, index) => {
                return <div> {index + 1 + "."} </div>
            },
            maxWidth: 25,
            minWidth: 25
        },
        {
            name: "Ерөнхий үзүүлэлт",
            fieldName: "iNDICATORNAME",
            maxWidth: 100,
            minWidth: 70

        },
        {
            name: "Ангилал",
            fieldName: "cATEGORYNAME",
            maxWidth: 120,
            minWidth: 70

        },
        {
            name: "Хамрах хүрээ",
            fieldName: "cOVERAGENAME",
            maxWidth: 180,
            minWidth: 120  
   
        },
        {
            name: "Түвшин",
            fieldName: "lEVELNAME",
            minColSize: "mid",
        },
        {
            fieldName: "action",
            minColSize: "mid",
            onRender: (item) => {
                return (
                    <div>
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            title="Edit"
                            ariaLabel="Edit"
                            onClick={() => onEditClick(item.iD)}
                        />
                    </div>
                );
            },
        },
    ]);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  getGeneralIndicatorlist {
                    iD
                    cATEGORYID
                    cATEGORYNAME
                    iNDICATORID
                    iNDICATORNAME
                    cOVERAGEID
                    cOVERAGENAME
                    lEVELID
                    lEVELNAME
                    sCHOOLIDS
                    cREATED
                    cREATEDBY
                  }
                }
              }`,
        }).then((response) => {
            let tResp = response?.data?.data?.sharedQuery?.getGeneralIndicatorlist;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        })
        setLoading(false);
        setData(tData);    
        setOData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (data) => {
        setData(data);
    };

    const onNew = () => {
        setShowNew(true);
    };

    const onDismiss = () => {
        setindid();
        setShowNew(false);
    };

    const onEditClick = (id) => {
        setindid(id);
        setShowNew(true);
    };
    const downloadExcel=async()=> {
        let tData =[];
        data?.map(r => {
          tData.push({
            "Ерөнхий үзүүлэлт": r?.iNDICATORNAME,
            "Ангилал": r?.cATEGORYNAME,
            "Хамрах хүрээ": r?.cOVERAGENAME,
            "Түвшин": r?.lEVELNAME,
            "Бүртгэсэн": r?.cREATEDBY
          });
        });
        const ws = xlsx.utils.json_to_sheet(tData);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Ерөнхий үзүүлэлт");
        xlsx.writeFile(wb,"Ерөнхий үзүүлэлт" + moment().format ("YYYY-MM-DD HH-mm-ss") + ".xlsx");
      }
    return (
        <div>
            <Row style={{ minHeight: "95vh" }} wrap={false}>
                <Col flex="none">
                    
                </Col>
                <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
                    <PageHeader
                        title={"Нийт"}
                        dataCount={data?.length}
                        buttonTitle="Ерөнхий үзүүлэлт"
                        onButtonClick={onNew}
                        columns={columns}
                        onSearch={onSearch}
                        data={oData}
                        loading={loading}
                        filteredData={data} 
                        addRightButton={(
                            <div style={{marginRight: 6, marginTop: 2, cursor:'pointer'}}>
                              <Space size={16}>
                                <div onClick={downloadExcel}>
                                  <ExcelIcon/>
                                </div>
                              </Space>
                            </div>
                          )}/>
                    <div style={{ padding: 16 }}>
                        <GridDefaultShimmer columns={columns} data={data} loading={loading} />
                        <GeneralIndicatorPanel id={indid} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default GeneralIndicators;