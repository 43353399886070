import React, { useEffect, useState } from "react";
import { ComboBox, SelectableOptionMenuItemType, DropdownMenuItemType } from "@fluentui/react";
import { Color } from "../../Const/Const";
import { MessageConst } from "../../Const/MessageConst";
import { Shimmer } from "@fluentui/react";

const FieldComboBoxMultiSelectAll = (props) => {
    const [value, setValue] = useState();

    const options = [
        { key: 'selectAll', text: 'Бүгдийг сонгох', itemType: SelectableOptionMenuItemType.SelectAll },
        { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider }
    ].concat(props.data);

    const selectableOptions = options.filter(
        option =>
          (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    const onChangeHandler = (_, option) => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = value.filter(key => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                var tValue;
                selectAllState ? tValue = [] : tValue = (['selectAll', ...selectableOptions.map(o => o.key)]);
                setValue(tValue)
                props.onChanged(tValue, option.text, props.keyfield, '', props.title);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option.key]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setValue(updatedKeys);
                props.onChanged(updatedKeys, option.text, props.keyfield, '', props.title);
            }
        }
    };

    useEffect(() => {
        var _props$defaultValue;
        var tValue = [];

        if (((_props$defaultValue = props.defaultValue) === null || _props$defaultValue === void 0 ? void 0 : _props$defaultValue.length) > 0) {
            tValue = props.defaultValue;
        }
        setValue(tValue);
    }, [props.defaultValue]);

    let vColor = { fontWeight: 600 };
    if (props.gray) {
        vColor = {
            color: Color.graySecond,
            fontWeight: 400,
        };
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
            <div style={{ fontSize: 14, ...vColor }}>{props.title}</div>
            {props.required && <div style={{ color: Color.red }}>*</div>}
        </div>
        <div style={{ marginTop: 6 }}>
          {props.loading ? (
            <div>
                <Shimmer />
                <Shimmer />
            </div>
          ) : (
            <ComboBox
                multiSelect
                options={options ? options : []}
                onChange={onChangeHandler}
                selectedKey={props.defaultValue}
                disabled={props.disable ? true : false}
                placeholder={props.placeholder ? props.placeholder : ""}
                errorMessage={
                    props.required &&
                    !value &&
                    props.checkreq &&
                    props.data?.length > 0 &&
                    !props.disable
                    ? props.title + MessageConst.fieldRequired
                    : null
                }
                className="combobox-details"
            />
          )}
        </div>
      </div>
    );
};

export default FieldComboBoxMultiSelectAll;