import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle, ButtonDefault, ButtonSecondary, LoadSpin, LoadScreen, FieldNumberFormat } from "dw-components";
import Purchase from "./Purchase";
import Investment from "./Investment";
import { message, Row, Col, Space, Modal } from "antd";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { API } from "../../../API/API";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { UserContext } from "../../../Context/UserContext";
import { URLTYPE } from "../../../Const/PurchaseInvestment";
import FileDownload from 'js-file-download';
import ButtonReport from "../../../Components/ButtonReport/ButtonReport";

const PurchaseSuggestion = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const { userInfo } = useContext(UserContext);
    const [purInvData, setPurInvData] = useState({});

    const [showPrice, setShowPrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [showAskConfirm, setShowAskConfirm] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [editpurchaseDtl, setPurchaseEditDtl] = useState(false);
    const [editinvestmentDtl, setInvestmentEditDtl] = useState(false);
    const [purchaseDtl, setPurchaseDtl] = useState([]);
    const [investmentDtl, setInvestmentDtl] = useState([]);
    const [changingPrice, setchangingPrice] = useState(0);

    const getData = async () => {
        setLoading(true)
        let tData = {};

        await APIGraphQL.post("", {
            query: `query ($id: Int, $type: String) {
                    budgetQuery {
                        getBudgetPurchaseInvestment (id: $id, type: $type) {
                            iD
                            sTATUSID
                            sTATUSNAME
                            sTARTDATE
                            yEAR
                            sTARTDATE
                            eNDDATE
                            oWNERNAME
                            cREATED
                            cREATEDBY
                            bUDGETID
                        }
                    }
                }`,
            variables: {
                id: parseInt(params?.id),
                type: params?.type
            },
        }).then((response) => {
            let tDResp = response?.data?.data?.budgetQuery?.getBudgetPurchaseInvestment;
            if (tDResp?.iD) {
                tData = tDResp;
            }
        }).catch(() => {
            setLoading(false);
        });

        setPurInvData(tData);
        setLoading(false);
    }

    useEffect(async () => {
        getData();
    }, [params?.id]);

    const onCalculate = async () => {
        setLoadingSave(true)

        let tBudgetId;
        let vBInvSuccess = false;

        if (params?.type == "pi") {
            tBudgetId = purInvData.bUDGETID;
        } else {
            tBudgetId = params?.id;
        }
        
        await API.post("api/budget/calcBudgetInvestment?budgetid=" + tBudgetId + "&schoolid=" + userInfo?.schoolid + 
        "&depid=" + userInfo?.schoolid +
        "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                vBInvSuccess = true;
            }
        }).catch(() => {
            vBInvSuccess = false
        });

        let vBPurchSuccess = false;
        await API.post("api/budget/calcBudgetPurchase?budgetid=" + tBudgetId + "&schoolid=" + userInfo?.schoolid + 
        "&depid=" + userInfo?.schoolid +
        "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                vBPurchSuccess = true;
            }
        }).catch(() => {
            vBPurchSuccess = false
        });

        if (vBInvSuccess && vBPurchSuccess) {
            setLoadingSave(false);
            message.success("Амжилттай");
            // navigate("/budget/confirm/" + params?.id);
        } else {
            setLoadingSave(false);
            message.error("Амжилтгүй");
        }
    }

    
    const onSaveEditPrice = async () => {
        let tBudgetId;
        if (params?.type == "pi") {
            tBudgetId = purInvData.bUDGETID;
        } else {
            tBudgetId = params?.id;
        }
        let vSuccess = false;
        if(purchaseDtl.length > 0) {
            setLoadingSave(true)
            await API.post("api/budget/changemultitemprice?budgetid=" + tBudgetId + "&typeid=2&unitprice=" + changingPrice, purchaseDtl
            ).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    vSuccess = true;
                }
            }).catch(() => {
                vSuccess = false
            });
        } else if(investmentDtl.length > 0) {
            setLoadingSave(true)
            await API.post("api/budget/changemultitemprice?budgetid=" + tBudgetId + "&typeid=1&unitprice=" + changingPrice, investmentDtl
            ).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    vSuccess = true;
                }
            }).catch(() => {
                vSuccess = false
            });
        }
        setShowPrice(false);   
        setLoadingSave(false);
        if (vSuccess) {
            message.success("Амжилттай");
        } else {
            setLoadingSave(false);
            message.error("Амжилтгүй");
        }
        setchangingPrice(0);
        setPurchaseDtl([]);
        setInvestmentDtl([]);
        getData();
    }
    const onEditPrice = async () => {
        if(purchaseDtl.length > 0) {
            let isExist = purchaseDtl.filter(el=>el.purchaseitemid != purchaseDtl[0]?.purchaseitemid);
            if(isExist?.length > 0) {
                message.warn("Ижил бараанууд сонгож үнэ засах боломжтой!");
                return;
            }
            
            setShowPrice(true);
        } else if(investmentDtl.length > 0) {
            let isExist = investmentDtl.filter(el=>el.investmentitemid != investmentDtl[0]?.investmentitemid);
            if(isExist?.length > 0) {
                message.warn("Ижил бараанууд сонгож үнэ засах боломжтой!");
                return;
            }
            setShowPrice(true);
        }
    }

    const onPurchasePriceChange = (pData) => {
        setPurchaseEditDtl(true);
        setPurchaseDtl(pData);
    }

    const onInvestmentPriceChange = (pData) => {
        setInvestmentEditDtl(true);
        setInvestmentDtl(pData);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/budgetInvestmentDtlTemp?id=" + params?.id + "&schoolid=" + userInfo?.schoolid + "&type=" + params?.type + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Хөрөнгө орууллагын мэдүүлэг" + "." + pData?.filetype);
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const toOpen = async () => {
        if (params?.id) {
            setLoadingOpen(true);
            await API.get("/api/budget/budgetPurchInvToOpen?purinvid=" + params?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай.")
                }
            }).catch(() => {
                setLoadingOpen(false);
            });
            setLoadingOpen(false);
            await getData();
        }
    }

    const onConfirm = async () => {
        setLoadingConfirm(true);

        await API.post("/api/budget/budgetPurchInvToConfirm?id=" + params?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                message.success("Амжилттай.")
            }
        }).catch(() => {
            setLoadingConfirm(false);
        });

        setLoadingConfirm(false);

        if (params?.type == URLTYPE.BUDGET) {
            navigate("/budget/confirm/" + params?.id);
        }
        if (params?.type == URLTYPE.PURCHASEINVESTMENT) {
            navigate("/budget");
        }
    }

    let vDisable = false;
    if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        if (props?.statusid > 3)
            vDisable = true;
    } else {
        vDisable = true;
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    return (
        <div>
            <div style={{ margin: 16 }}>
                <Row>
                    <Col span={21}>
                        <div style={{ marginLeft: 16, marginTop: 16  }}>
                            <PageTitle>Худалдан авалт Хөрөнгө оруулалтын санал</PageTitle>
                            {userInfo?.schoolname}
                        </div>
                    </Col>
                    <Col span={3}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                            {!vDisable ? (
                                <div style={{ marginLeft: 16 }}>{loadingSave ? <LoadSpin /> : <ButtonDefault text={"Тооцоолох"} onClick={onCalculate} />}</div>
                            ) : null}
                                <div style={{ marginLeft: 16 }}>{loadingSave ? <LoadSpin /> : <ButtonSecondary text={"Шинэчлэх(Үнэ)"} onClick={onEditPrice} />}</div>
                            {params?.type == URLTYPE.PURCHASEINVESTMENT ? (
                                <>
                                    {purInvData.sTATUSID == 1 ? (<div style={{ marginLeft: 16 }}>{loadingSave ? <LoadSpin /> : <ButtonDefault text={"Нээх"} onClick={toOpen} />}</div>) : null}
                                    {!purInvData.bUDGETID ? (
                                        purInvData.sTATUSID == 2 ? (<div style={{ marginLeft: 16 }}>{loadingSave ? <LoadSpin /> : <ButtonDefault text={"Батлах"} onClick={onConfirm} />}</div>) : null
                                    ) : null}
                                </>
                            ) : null}
                            <div style={{ paddingLeft: 16, float: "left" }}>{loadingDownload ? <LoadSpin /> :  <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />}</div>
                        </div>
                             <Row style={{ marginTop: 10, paddingRight: 20, float: "Right" }}>
                                <Col justify="end">
                                       /мян.төгрөг/
                                </Col>
                           </Row>
                    </Col>
                </Row>
                {userInfo?.indicatorids?.includes("581") &&
                    <Investment
                        itemId={params?.id}
                        budgetid={purInvData.bUDGETID}
                        urlType={params?.type}
                        statusId={purInvData.sTATUSID}
                        secondaryId={params?.type == "pi" ? purInvData.bUDGETID : purInvData.iD}
                        disable={vDisable}
                        onEdit={onInvestmentPriceChange}
                    />
                }
                {userInfo?.indicatorids?.includes("582") &&
                    <div style={{ marginTop: 48, paddingBottom: 48 }}>
                        <Purchase
                            itemId={params?.id}
                            budgetid={purInvData.bUDGETID}
                            urlType={params?.type}
                            statusId={purInvData.sTATUSID}
                            secondaryId={params?.type == "pi" ? purInvData.bUDGETID : purInvData.iD}
                            disable={vDisable}
                            onEdit={onPurchasePriceChange}
                        />
                    </div>
                }
            </div>
            <Modal
                visible={showPrice}
                onCancel={() => { setShowPrice(false); }}
                onOk={onSaveEditPrice}
                cancelText="Болих"
                okText="Хадгалах"
                title="Нэгжийн дүн оруулах">
                <FieldNumberFormat onChanged={(key, field) => { setchangingPrice(key); }} keyfield="uNITPRICE" defaultValue={0}/>
            </Modal>
            <Dialog
                hidden={!showAskConfirm}
                onDismiss={() => {
                    setShowAskConfirm(false);
                }}
                minWidth={400}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Та батлахдаа итгэлтэй байна уу?",
                    closeButtonAriaLabel: 'Болих',
                    subText: "Та ХӨ ХА баталснаар засварлах боломжгүй болно!."
                }}
            >
                <DialogFooter>
                    <Space>
                        <ButtonSecondary text="Болих" onClick={() => { setShowAskConfirm(false); }} />
                        <ButtonDefault text="Батлах" onClick={onConfirm} />
                    </Space>
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default PurchaseSuggestion;
