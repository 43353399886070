import { Layout, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import SideMenu from '../../Sidemenu/Sidemenu';
import { TreeList, Column, Selection, Scrolling } from 'devextreme-react/tree-list';
import { ButtonSecondary, ColEdit, LoadSpin, ButtonIcon } from 'dw-components';
import { ReactComponent as AddIcon } from '../../../svg/add.svg';
import NewSchoolDep from './NewSchoolDep';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { IconButton } from '@fluentui/react';

const SchoolDep = (props) => {

    const [data, setData] = useState([]);

    const [showNewSchool, setShowNewSchool] = useState(false);
    const [schoolId, setSchoolId] = useState();
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  schooldeps {
                    iD
                    pARENTID
                    cODE
                    nAME
                    sCHOOLTYPEID
                    sCHOOLTYPENAME
                    eXTERNALCODE
                    iSACTIVE
                  }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.schooldeps;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        }).catch(response => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onEdit = (pId) => {
        setSchoolId(pId);
        setShowNewSchool(true);
    }

    return (
        <Layout style={{ minHeight: "95vh" }}>
            <Layout>
                {loading ? (<LoadSpin />) : (
                    <div style={{ margin: 16 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Space>
                                <ButtonSecondary
                                    icon={<AddIcon />}
                                    text="Сургууль, нэгж"
                                    onClick={() => {
                                        setSchoolId();
                                        setShowNewSchool(true);
                                    }}
                                />
                            </Space>
                        </div>
                        <TreeList
                            id="iD"
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            keyExpr="iD"
                            parentIdExpr="pARENTID"
                            autoExpandAll={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" />
                            <Selection mode="single" />
                            <Column
                                minWidth={250}
                                dataField="nAME"
                                caption="Сургууль, нэгж"
                                cellRender={cellRender => {
                                    if (cellRender?.data?.sCHOOLTYPEID == 4 || cellRender?.data?.sCHOOLTYPEID == 7) {
                                        return (
                                            <ColEdit onClick={() => onEdit(cellRender?.data?.iD)}>{cellRender?.displayValue}</ColEdit>
                                        )
                                    } else {
                                        return (
                                            <div>{cellRender?.displayValue}</div>
                                        )
                                    }
                                }} />
                            <Column
                                dataField="iSACTIVE"
                                caption="Төлөв"
                                cellRender={cellRender => {
                                    if (cellRender?.data?.sCHOOLTYPEID == 4 || cellRender?.data?.sCHOOLTYPEID == 7) {
                                        return (<div>{cellRender?.displayValue == 0 ? "Идэвхгүй" : "Идэвхтэй"}</div>);
                                    } else {
                                        return (
                                            <div className='smallIcon'>
                                                <IconButton
                                                    iconProps={{
                                                        iconName: "Edit"
                                                    }}
                                                    style={{
                                                        width: 14,
                                                        height: 14
                                                    }}
                                                    onClick={() => onEdit(cellRender?.data?.iD)}
                                                />
                                            </div>
                                        );
                                    }
                                }} />
                        </TreeList>
                        <NewSchoolDep
                            id={schoolId}
                            isOpen={showNewSchool}
                            onDismiss={() => {
                                setSchoolId();
                                setShowNewSchool(false);
                            }}
                            afterSave={getData}
                        />
                    </div>
                )}
            </Layout>
        </Layout>
    );
}

export default SchoolDep;