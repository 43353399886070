import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin, FieldDropdownMulti } from "dw-components";
import { UserContext } from "../../../../Context/UserContext";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { Coverage, Level } from '../../../../Const/Coverage';
import { message } from "antd";
import moment from "moment";
import GeneralMethods from '../../../../Const/GeneralMethods';
import FieldComboBoxMultiSelectAll from "../../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";

const GeneralIndicatorPanel = (props) => {

    const [data, setData] = useState({});
    const { userInfo } = useContext(UserContext);
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dSchools, setDBSchools] = useState([]);
    const [dLevels, setLevels] = useState(Level);
    const [dTraininglevel, setDTraininglevel] = useState([]);
    const [dOtherIndicators, setOtherIndicators] = useState([]);
    const [dCategory, setDcategory] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const getData = async () => {
        let tData = {};
        let tTraining = [];
        let tOthers = [];
        let tSchools = [];
        setLoading(true);
        await APIGraphQL.post("", {
            query: `query ($id: Int) {
                sharedQuery {
                    getGeneralindicator(id: $id) {
                        iD
                        cATEGORYID
                        cATEGORYNAME
                        iNDICATORID
                        iNDICATORNAME
                        cOVERAGEID
                        cOVERAGENAME
                        lEVELID
                        lEVELNAME
                        sCHOOLIDS
                        cREATED
                        cREATEDBY
                    }

                    schoolAll {
                        key: iD
                        text: nAME
                    }
                    getTrainingLevelList {
                        key: iD
                        text: nAME
                    }
                    getOtherIndicatorlist {
                        key: iD
                        text: nAME
                    }
                }
                }`,
            variables: {
                "id": parseInt(props?.id),
            },
        }).then((response) => {
            let tResp = response?.data?.data?.sharedQuery;
            if (tResp?.getGeneralindicator?.iD) {
                tData = tResp?.getGeneralindicator;
                tData.sCHOOLIDS = tData?.sCHOOLIDS?.split(',').map(Number);
            }

            if (tResp?.schoolAll?.length > 0) {
                tSchools = tResp?.schoolAll;
            }
            if (tResp?.getTrainingLevelList?.length > 0) {
                tTraining = tResp?.getTrainingLevelList;
            }
            if (tResp?.getOtherIndicatorlist?.length > 0) {
                tOthers = tResp?.getOtherIndicatorlist;
            }
        }).catch(() => {
            setLoading(false);
        });
        setData(tData);
        setDBSchools(tSchools);
        setDTraininglevel(tTraining);
        setOtherIndicators(tOthers);
        setCategory(tData?.iNDICATORID);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const fieldOnChanged = async (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            if(fieldname == "iNDICATORNAME") {
                setCategory(key);
            } else if(fieldname == "cOVERAGENAME") {
                if(key == 1)
                {
                    tData.lEVELID = 1;
                    tData.lEVELNAME = "Тэнхим";
                }
                if(key == 2)
                    setLevels(Level.filter(item => item.key !== 3));
                else
                    setLevels(Level);
            }
            setData(tData);
        }
    };

    const setCategory = async (key) => {
        let tDPos = [];
        if(key == 100) {
            tDPos = [];
        } else if(key == 101) {
            tDPos = [...dTraininglevel];
        } else if(key == 102) {
            tDPos = [...dOtherIndicators];
        }
        setDcategory(tDPos);
    }

    const onSave = async () => {
        let isValid = true;
        setCheckRule(true);
        let tData = { ...data }
        
        if (!tData?.iNDICATORID ||
            !tData?.lEVELID ||
            !tData?.cOVERAGEID ||
            !tData?.sCHOOLIDS)
        isValid = false ;
        if (isValid) {
            let tSuccess = false;
            if(tData.iNDICATORID == 100) {
                tData.cATEGORYID = 0;
                tData.cATEGORYNAME = "";
            }
            if(tData.sCHOOLIDS)
                tData.sCHOOLIDS = tData.sCHOOLIDS.sort().toString();
            if (!props.id) {
                tData.iD = 0;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
            }

            setLoadingSave(true);
            await APIGraphQL.post("", {
                query: ` mutation ($data: GeneralIndicatorInputType) {
                    sharedMutation{
                      addGeneralIndicator(data: $data){
                        iD
                      }
                    }
                  }`,
                variables: {
                    "data": tData
                },
            }).then((response) => {
                setLoadingSave(false);
                if (response?.data?.data?.sharedMutation?.addGeneralIndicator?.iD) {
                    tSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            })
            if (tSuccess) {
                message.success("Амжилттай");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    }

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteGeneralIndicator($id: Int) {
                sharedMutation {
                    deleteGeneralIndicator(id: $id)
                }
            }`,
            variables: {
                "id": parseInt(props.id),
            },
        }).then((response) => {
            if (response?.data?.data?.sharedMutation?.deleteGeneralIndicator == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай");
                onDismiss();
            }
        });
    };

    const onDismiss = () => {
        setData({});
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    return (
        <PanelBase
            headerText={props?.id ? "Засах" : "Нэмэх"}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="medium"
            delete={props.id > 0 ? true : false}
            onDelete={onDelete}
            buttons={loadingSave ? <LoadSpin /> :
                <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} />
            }
        >
            <div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Ерөнхий үзүүлэлт" keyfield="iNDICATORID" keyfieldname="iNDICATORNAME" defaultValue={data.iNDICATORID} data={GeneralMethods} />
                </div>
                {(data?.iNDICATORID != 100 &&
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown required loading={loading} onChanged={fieldOnChanged} checkreq={checkRule} title="Ангилал" keyfield="cATEGORYID" keyfieldname="cATEGORYNAME" defaultValue={data.cATEGORYID} data={dCategory}/>
                </div>
                )}
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown loading={loadingData} onChanged={fieldOnChanged} title="Хамрах хүрээ" keyfield="cOVERAGEID" keyfieldname="cOVERAGENAME" defaultValue={data.cOVERAGEID} data={Coverage} />
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown disable={data.cOVERAGEID == 1} loading={loadingData} onChanged={fieldOnChanged} title="Түвшин" keyfield="lEVELID" keyfieldname="lEVELNAME" defaultValue={!data?.lEVELID ? 1 : data?.lEVELID} data={dLevels} />
                </div>
                {(data?.lEVELID != 3 &&
                    <div style={{ marginTop: 13 }}>
                    <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} title="Үзүүлэлт харуулах сургууль, нэгж" keyfield="sCHOOLIDS" defaultValue={data.sCHOOLIDS} data={dSchools} />
                    </div>
                )}
            </div>
        </PanelBase>
    )
}

export default GeneralIndicatorPanel;

