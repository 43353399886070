import { message } from 'antd';
import { ButtonDefault, FieldDropdown, PanelBase, LoadSpin, FieldTextArea, ButtonSecondary } from 'dw-components';
import React, { useEffect, useState, useContext } from 'react';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { MATHACTIONCONST, INDICATORTYPECONST, OTHERCALCMETHODS } from '../../../Const/FormulaConst';
import POSITIONCATEGORY from '../../../Const/POSITIONCATEGORY';
import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { Icon } from '@fluentui/react/lib/Icon';
import moment from "moment";
import { UserContext } from "../../../Context/UserContext";
import FieldNumberFormat from "../../../Components/FieldNumberFormat/FieldNumberFormat";

const NewCatFormula = (props) => {

    const {userInfo } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [formulas, setFormulas] = useState("");
    const [dEcocats, setEcoCats] = useState([]);
    const [dInicators, setDIndicators] = useState([]);
    const [dTraininglevel, setDTraininglevel] = useState([]);
    const [dOtherIndicators, setOtherIndicators] = useState([]);
    const [dCategory, setDcategory] = useState([]);
    const [dPosition, setDPosition] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);

    const getData = async () => {
        setLoadingData(true);
        let tData = [];
        let tchildeco = [];
        let tTraining = [];
        let tOthers = [];
        let tPositions = [];
        await APIGraphQL.post("", {
            query: `query ($id: Int) {
                sharedQuery {
                    formuladetails(id: $id) {
                        iD
                        fORMULAID
                        eCOCATID
                        cALCECOCATID
                        cALCECOCATNAME
                        aCTIONID
                        aCTIONNAME
                        qTYSTR
                        tYPEID
                        tYPENAME
                        cATEGORYID
                        cATEGORYNAME
                        pOSCATID
                        pOSCATNAME
                        cREATED
                        cREATEDBY
                    }
                    childEconomyCat {
                        key: iD
                        text: fULLNAME
                    }
                    getTrainingLevelList {
                        key: iD
                        text: nAME
                    }
                    getOtherIndicatorlist {
                        key: iD
                        text: nAME
                    }
                    getOtherIndicatorlist {
                        key: iD
                        text: nAME
                    }
                    getPositionsfiltered(isuseuser: 0) {
                        key: iD
                        text: nAME
                    }
                }
            }
            `,
            variables: {
                "id": parseInt(props?.id)
            }
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery;
            if (tResp?.formuladetails?.length > 0) {
                tData = tResp?.formuladetails;
            }
            if (tResp?.childEconomyCat?.length > 0) {
                tchildeco = tResp?.childEconomyCat;
            }
            if (tResp?.getTrainingLevelList?.length > 0) {
                tTraining = tResp?.getTrainingLevelList;
            }
            if (tResp?.getOtherIndicatorlist?.length > 0) {
                tOthers = tResp?.getOtherIndicatorlist;
            }
            if (tResp?.getPositionsfiltered?.length > 0) {
                tPositions = tResp?.getPositionsfiltered;
            }
        }).catch(() => {
            setLoadingData(false);
        });     

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        setEcoCats(tchildeco);
        setData(tData);
        setDataEdit(tData);
        setLoadingData(false);
        setDTraininglevel(tTraining);
        setOtherIndicators(tOthers);
        setDPosition(tPositions);
    }

    useEffect(() => {
        setFormulas(props?.formulaview);
        getData();
    }, [props?.catid, props?.id, props?.formulaview]);

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if(pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if(pFieldName == "tYPENAME") {
            if(pValue == 1) {
                setDIndicators(dEcocats);
            }
            else {
                setDIndicators(OTHERCALCMETHODS);
            }
        } else if(pFieldName == "cALCECOCATNAME") {
            let tDPos = [];
            if(pValue == 100) {
                tDPos = [...POSITIONCATEGORY];
            } else if(pValue == 101) {
                tDPos = [...dTraininglevel];
            } else if(pValue == 102) {
                tDPos = [...dOtherIndicators];
            }
            setDcategory(tDPos);
        } else if(pFieldName == "cATEGORYNAME") {
            let tPositions = [];
            await APIGraphQL.post("", {
                query: `query ($categoryid: Int) {
                    sharedQuery {
                      getPositionsByCategoryID(categoryid: $categoryid) {
                            key: iD
                            text: nAME
                        }
                    }
                  }`,
                variables: {
                    "categoryid": pValue
                }
            }).then(response => {
                let tResp = response?.data?.data;    
                if (tResp?.sharedQuery?.getPositionsByCategoryID?.length > 0) {
                  tPositions = tResp?.sharedQuery?.getPositionsByCategoryID;
            }
            }).catch(() => {
                setLoading(false);
            });
            setDPosition(tPositions);
        }

        setDataEdit(tDataEdit);
    }

    const onSave = async () => {
        let tDataEdit = [ ...dataEdit ];
        let formuShow = "";
        let formu = "";

        tDataEdit.forEach((el)=>{
            if(el.aCTIONNAME) {
                formuShow += " " + el.aCTIONNAME.toString() + " ";
                formu += " " + el.aCTIONNAME.toString() + " ";
            }
            if(el.cALCECOCATNAME) {
                if(el.cATEGORYNAME) {
                    if(el.pOSCATNAME) {
                        formuShow += " '" + el.cALCECOCATNAME.toString() + "." + el.cATEGORYNAME.toString() + "." + el.pOSCATNAME.toString() + "' ";
                    } else {
                        formuShow += " '" + el.cALCECOCATNAME.toString() + "." + el.cATEGORYNAME.toString() + "' ";
                    }
                }
                else {
                    formuShow += " '" + el.cALCECOCATNAME.toString() + "' ";
                }
                formu += " " + el.cALCECOCATID.toString() + " ";
            }
            if(el.qTYSTR) {
                el.qTYSTR = parseFloat(el.qTYSTR);
                formu += " " + el.qTYSTR + " ";
                formuShow += " " + el.qTYSTR + " ";
                el.qTYSTR = el.qTYSTR.toString();
            }
        });
        setFormulas(formuShow);
        var result = -1;
        try {
            result = eval(formu);
        } catch {
            message.warning("Томьёо буруу байна. Дахин шалгана уу!");
        }

        if(result != -1)
        {
            let vSuccess = false;
            setLoadingSave(true);
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.fORMULAID = props?.id ? parseInt(props?.id) : 0;
                tR.eCOCATID = parseInt(props?.catid);

                delete tR.arrayIndex;
                tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                tR.cREATEDBY = userInfo?.username;
                tDataEdit[i] = tR;
            }
            await APIGraphQL.post("", {
                query: `mutation ($data: [FormulaDetailInputType]) {
                    sharedMutation {
                     addformuladetail(data: $data) {
                        iD
                      }
                    }
                  }`,
                variables: {
                    "data": tDataEdit
                }
            }).then(response => {
                if (response?.data?.data?.sharedMutation?.addformuladetail?.length > 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {

            });
            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
                await props.afterSave();
            }
        }
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            iD: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteformula($id: Int) {
            sharedMutation {
                deleteformula(id: $id)
                }
            }`,
            variables: {
            "id": parseInt(props.id)
            }
        }).then(response => {
            if (response?.data?.data?.sharedMutation?.deleteformula == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
            }
        });
    }

    const onDeletedetail = async (pIndex, pId) => {
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation delFormulaDetail($id: Int) {
                        sharedMutation {
                                delFormulaDetail(id: $id)
                            }
                        }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { });
        }

        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    return (
        <PanelBase
            headerText={"Томьёо оруулах"}
            isOpen={props?.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            delete={props?.id > 0 ? true : false}
            onDelete={onDelete}
            buttons={loading
                ? <LoadSpin />
                : <ButtonDefault
                    text={"Хадгалах"}
                    onClick={onSave}
                />
            }
        >
            <div>
                <div style={{ marginTop: 25 }}>
                    <FieldTextArea disable={true} loading={loadingData} title="Томьёо" keyfield="formulas" defaultValue={formulas} />
                </div>
            </div>
            <div style={{ marginTop: 24 }}>
                <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
            </div>
            <div style={{ marginTop: 24 }}>
                <DataGrid
                    dataSource={data}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    showRowLines
                    showBorders
                >
                    <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                    <Column width={50} caption="№" alignment='center' allowResizing={true}
                        cellRender={(item) => {
                            return (
                                <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                            );
                        }}
                    ></Column>
                    <Column
                        dataField="aCTIONNAME"
                        caption="Үйлдэл"
                        alignment='center'
                        width={100}
                        format="fixedPoint"
                        cellRender={(item) => {
                            return (
                                <FieldDropdown onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "aCTIONID", pNameValue, "aCTIONNAME")} defaultValue={item.data?.aCTIONID} data={MATHACTIONCONST} />
                            );
                        }} />
                    <Column
                        width={300} dataField="tYPENAME" caption="Төрөл" format="fixedPoint"
                        cellRender={(item) => {
                            return (
                                <FieldDropdown onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "tYPEID", pNameValue, "tYPENAME")} defaultValue={item.data?.tYPEID} data={INDICATORTYPECONST} />
                            );
                        }}
                    />
                    <Column
                        width={500} dataField="cALCECOCATNAME" caption="Үзүүлэлт"
                        cellRender={(item) => {
                            return (
                                <FieldDropdown onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "cALCECOCATID", pNameValue, "cALCECOCATNAME")} defaultValue={item.data?.cALCECOCATID} 
                                data={(item.data?.cALCECOCATID || item.data?.cALCECOCATID > 0)? [{ key: item.data?.cALCECOCATID, text: item.data?.cALCECOCATNAME }] : dInicators} />
                            );
                        }}
                    />
                    <Column
                        width={500} dataField="cATEGORYID" caption="Ангилал"
                        cellRender={(item) => {
                        return (
                            <FieldDropdown onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "cATEGORYID", pNameValue, "cATEGORYNAME")} defaultValue={item.data?.cATEGORYID} 
                            data={(item.data?.cATEGORYID || item.data?.cATEGORYID > 0)? [{ key: item.data?.cATEGORYID, text: item.data?.cATEGORYNAME }] : dCategory} />
                        );
                        }}
                    />
                    <Column
                        width={500} dataField="pOSCATID" caption="Ажлын байр"
                        cellRender={(item) => {
                        return (
                            <FieldDropdown onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "pOSCATID", pNameValue, "pOSCATNAME")} defaultValue={item.data?.pOSCATID} 
                            data={(item.data?.pOSCATID || item.data?.pOSCATID > 0)? [{ key: item.data?.pOSCATID, text: item.data?.pOSCATNAME }] : dPosition} />
                        );
                        }}
                    />
                    <Column
                        dataField="qTYSTR"
                        caption="Тогтмол"
                        minwidth={150}
                        alignment='center'
                        cellRender={(item) => {
                            return (
                                <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "qTYSTR")} decimalScale={3} defaultValue={item.data?.qTYSTR} />
                            );
                        }} />
                        <Column
                            dataField="action"
                            caption=""
                            width={30}
                            cellRender={(item) => {
                                return (
                                    <div onClick={() => onDeletedetail(item?.data?.arrayIndex, item?.data?.iD)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                        <Icon iconName="Delete" />
                                    </div>
                                );
                            }} />
                </DataGrid>
            </div>

        </PanelBase>
    );
}

export default NewCatFormula;