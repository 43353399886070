import React, { useEffect, useState } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefaultShimmer, PageHeader } from "dw-components";
import Sidemenu from "../../Sidemenu/Sidemenu";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import moment from 'moment';
import * as xlsx from "xlsx";
import EditIndicatorPanel from "./Panel/EditIndicatorPanel";

const EditIndicator = () => {

  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [id, setId] = useState();
  const [columns, setColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    }, {
      name: "Сургууль",
      fieldName: "sCHOOLNAME",
      maxWidth: 200,
      minWidth: 200
    },
    {
      name: "Үзүүлэлт",
      fieldName: "iNDICATORNAME",
      maxWidth: 280,
      minWidth: 280
    }, {
      name: "Үйлдэл",
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.iD)}
            />
          </div>
        );
      }
    }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getEditIndicatorList {
            iD
            sCHOOLNAME
            iNDICATORNAME
          }
        }
      }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getEditIndicatorList;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });

    setLoading(false);

    setData(tData);
    setOData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setId();
    setShowNew(true);
  }

  const onDismiss = () => {
    setId();
    setShowNew(false);
  }

  const onEditClick = (id) => {
    setId(id);
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const downloadExcel = async () => {
    let tData = [];
    data?.map(r => {
      tData.push({
        "код": r?.cODE
      });
    });
    const ws = xlsx.utils.json_to_sheet(tData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Үзүүлэлт засах тохиргоо");
    xlsx.writeFile(wb, "Үзүүлэлт засах тохиргоо" + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx");
  }

  return (
    <div>
      <Row style={{ minHeight: "95vh" }} wrap={false}>
        <Col flex="none">
          <Sidemenu />
        </Col>
        <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
          <PageHeader
            title="Үзүүлэлт засах тохиргоо"
            dataCount={data?.length}
            buttonTitle="Нэмэх"
            onButtonClick={onNew}
            onSearch={onSearch}
            data={oData}
            filteredData={data}
            columns={columns}
            addRightButton={(
              <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                <Space size={16} >
                  <div onClick={downloadExcel}>
                    <ExcelIcon />
                  </div>
                </Space>
              </div>
            )}
          />
          <div style={{ margin: 16 }}>
            <GridDefaultShimmer columns={columns} data={data} loading={loading}></GridDefaultShimmer>
            <EditIndicatorPanel
              id={id}
              isOpen={showNew}
              onDismiss={onDismiss}
              afterSave={getData}
            ></EditIndicatorPanel>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditIndicator;
