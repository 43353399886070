export const STRATEGYPLAN = [{
    key: 2,
    text: "Тэргүүлэх чиглэл"
}, {
    key: 3,
    text: "Зорилт"
}, {
    key: 4,
    text: "Стратеги"
}, {
    key: 5,
    text: "Үзүүлэлт"
}]