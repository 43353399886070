import React, {Component} from "react";
import {Spin} from 'antd';
import './LoadingCenter.css';

class loadingCenter extends Component {
    render() {
        return (<div className='l-c-container'>
                <div className='loadingCenter'><Spin /></div>
         </div>);
    }
}

export default loadingCenter;