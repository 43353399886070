import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { PageHeader, GridDefault, ColEdit } from "dw-components";
import BonusConfigPanel from "../Panel/BonusConfigPanel";
import { APIGraphQL } from "../../../../API/APIGraphQL";

const BonusConfig = (props) => {

  const [data, setData] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);

  const [columns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      }
    },
    {
      name: "Жил",
      fieldName: "yEAR",
      colSize: "mid",
      onRender: (item) => {
        return (
          <ColEdit onClick={() => onEdit(item.iD)}>
            {item?.yEAR}
          </ColEdit>
        );
      }
    },
    {
      name: "Эдийн засгийн ангилал нэр",
      fieldName: "eCONOMYCATEGORYNAME",
      minColSize: "large"
    },
    {
      name: "Хувь",
      fieldName: "pERCENT",
      colSize: "mid"
    }
  ]);

  const getData = async () => {
    setLoading(true);
    let tData = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          bonus {
            iD
            yEAR
            pERCENT
            eCONOMYCATEGORYNAME
          }
        }
      }
      `
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.bonus;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onDismiss = () => {
    setId();
    setShowNew(false);
  }

  const onEdit = (pId) => {
    setId(pId);
    setShowNew(true);
  }

  const onNew = () => {
    setId();
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const afterSave = async () => {
    setShowNew(false);
    await getData();
  }

  return (
    <Layout style={{ minHeight: "95vh" }}>
      
      <Layout>
        <PageHeader
          title="Нийт"
          dataCount={data?.length}
          buttonTitle="Нэмэх"
          onButtonClick={onNew}
          onSearch={onSearch}
          data={data}
          columns={columns}
        />
        <div style={{ margin: 16 }}>
          <GridDefault
            columns={columns}
            data={data}
            loading={loading} />
          <BonusConfigPanel
            id={id}
            isOpen={showNew}
            onDismiss={onDismiss}
            afterSave={afterSave}
          />
        </div>
      </Layout>
    </Layout>
  );
};

export default BonusConfig;
