import React, { useEffect, useContext, useState } from "react";
import { ButtonDefault, FieldDate, FieldDropdown, FieldText, PanelBase, LoadSpin, FieldNumberFormat } from "dw-components";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { MessageConst } from "../../../Const/MessageConst";
import { UserContext } from "../../../Context/UserContext";
import { APIGraphQL } from "../../../API/APIGraphQL";

const BudgetNewPanel = (props) => {

  const navigate = useNavigate();

  const { userInfo } = useContext(UserContext);
  const [data, setData] = useState({});
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState(false);
  const [dBudgetYears, setBudgetYears] = useState([]);

  const getData = async () => {
    let tYears = [];
    await APIGraphQL.post("", {
    query: `{
        sharedQuery {
          getBudgetYearList {
            key: yEAR
            text: yEAR
          }
        }
      }`
    }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getBudgetYearList;
        if (tResp?.length > 0) {
          tYears = tResp;
        }
    }).catch(() => {
        setLoading(false);
    })
    setBudgetYears(tYears);
  }

  const fieldOnChanged = (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;
    if (!data.yEAR) {
      isValid = false;
      setWarning("Төсвийн жил" + MessageConst.fieldRequired);
    } else if (!data.sTARTDATE) {
      isValid = false;
      setWarning("Нээх огноо" + MessageConst.fieldRequired);
    } else if (!data.eNDDATE) {
      isValid = false;
      setWarning("Хаах огноо" + MessageConst.fieldRequired);
    } else if (!data.oWNERNAME) {
      isValid = false;
      setWarning("Албан тушаалтны нэр" + MessageConst.fieldRequired);
    } else if (!data.rATE) {
      isValid = false;
      setWarning("Ханш" + MessageConst.fieldRequired);
    }

    if (isValid) {
      try {
        setLoading(true);
        let tData = { ...data };

        tData.iD = 0;
        tData.sTATUSID = 1;
        tData.sTATUSNAME = "Шинэ";
        tData.cREATEDBY = userInfo?.username;

        await APIGraphQL.post("", {
          query: `mutation ($data: BudgetInputType) {
            budgetMutation {
              addBudget(data: $data) {
                iD
              }
            }
          }
          `,
          variables: {
            "data": tData
          }
        }).then(response => {
          setLoading(false);
          let tId = response?.data?.data?.budgetMutation?.addBudget?.iD

          if (tId == "-1") {
            message.warn("Төсөв бүртгэгдсэн байна.");
          }
          else if (tId) {
            message.success("Амжилттай");
            navigate("/budget/confirm/" + tId);
            onDismiss();
          }
        }).catch(response => {
          setLoading(false);
        });

      } catch (error) {
        message.error(error?.message);
        setLoading(false);
      }
    }
  }

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    setCheckRule(false);
    setData({});
  }
  

  useEffect(() => {
    getData();
  }, []);

  return (
    <PanelBase
      headerText="Нэмэх"
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      warning={warning}
      PanelType="medium"
      buttons={
        loading ? <LoadSpin /> :
          <ButtonDefault
            text="Хадгалах"
            onClick={onSave}
          />
      }
    >
      <div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Төсвийн жил" keyfield="yEAR" defaultValue={data.yEAR} checkreq={checkRule} data={dBudgetYears} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDate required onChanged={fieldOnChanged} title="Нээсэн огноо" keyfield="sTARTDATE" checkreq={checkRule} defaultValue={data.sTARTDATE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDate required onChanged={fieldOnChanged} title="Хаах огноо" keyfield="eNDDATE" checkreq={checkRule} defaultValue={data.eNDDATE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText required onChanged={fieldOnChanged} title="Албан тушаалтны нэр" keyfield="oWNERNAME" checkreq={checkRule} defaultValue={data.oWNERNAME} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required onChanged={fieldOnChanged} title="Ханш" keyfield="rATE" checkreq={checkRule} defaultValue={data.rATE} />
        </div>
      </div>
    </PanelBase>
  );
}

export default BudgetNewPanel;
