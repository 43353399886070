import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldNumberFormat, FieldText, LoadSpin, PanelBase } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";

const PurchaseItemPanel = (props) => {
  const [data, setData] = useState({});
  const [dPInv, setDPInv] = useState([]);
  const [dUnit, setDUnit] = useState([]);
  const [checkRule, setCheckRule] = useState(false);

  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);

    let tdPInv = [];
    let tDUnit = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getPIPurchaseAndInvestmentList {
            key: iD
            text: nAME
          }
          getUnits {
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.getPIPurchaseAndInvestmentList;
      let tUResp = response?.data?.data?.sharedQuery?.getUnits;
      if (tResp?.length > 0) {
        tdPInv = tResp;
      }
      if (tUResp?.length > 0) {
        tDUnit = tUResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDPInv(tdPInv);
    setDUnit(tDUnit);

    let tData = {};
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getPurchaseItem(id: $id) {
              iD
              nAME
              pURINVID
              pURINVNAME
              uNITID
              uNITNAME
              uNITPRICE
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getPurchaseItem;
        if (tResp?.iD) {
          tData = tResp;

        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);



  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.nAME ||
      !data?.pURINVID ||
      !data?.uNITID ||
      !data?.uNITPRICE) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: PurchaseItemInputType) {
          sharedMutation {
            addPurchaseItem(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addPurchaseItem?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deletePurchaseItem($id: Int) {
        sharedMutation {
          deletePurchaseItem(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deletePurchaseItem == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div className="add-asset">
        <div style={{ marginTop: 13 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Хөрөнгийн нэр, төрлийн нэр" keyfield="nAME" checkreq={checkRule} defaultValue={data.nAME} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Хөрөнгийн ангилал" keyfield="pURINVID" keyfieldname="pURINVNAME" checkreq={checkRule} defaultValue={data.pURINVID} data={dPInv} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Хэмжих нэгж" keyfield="uNITID" keyfieldname="uNITNAME" checkreq={checkRule} defaultValue={data.uNITID} data={dUnit} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Нэгжийн үнэ" keyfield="uNITPRICE" checkreq={checkRule} defaultValue={data.uNITPRICE} />
        </div>
      </div>
    </PanelBase>
  );
};

export default PurchaseItemPanel;
