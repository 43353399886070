import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { GridDefaultShimmer, ColEdit, PageHeader } from 'dw-components';
import NewCatFormula from './NewCatFormula';
import { APIGraphQL } from '../../../API/APIGraphQL';

const EconomyCategoryFormula = (props) => {

    const [data, setData] = useState([]);

    const [showNewCat, setShowNewCat] = useState(false);
    const [catId, setCatId] = useState();
    const [indId, setindId] = useState();
    const [dformulaview, setdformulaview] = useState();
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([
        {
          name: "№",
          minWidth: "20px",
          onRender: (row, index) => {
            return <div> {index + 1 + "."} </div>
          },
          maxWidth: 25,
          minWidth: 25
        }, {
          name: "Эдийн засгийн ангилал",
          fieldName: "fULLNAME",
          minColSize: "mid",
          onRender: (item) => {
            return (
              <ColEdit onClick={() => onEdit(item.cATID, item.formulas?.iD, item.formulas?.fORMULAVIEW)}>
                {item?.fULLNAME}
              </ColEdit>
            );
          }
        }, {
          name: "Томьёо",
          fieldName: "fORMULAVIEW",
          minColSize: "mid",
          onRender: (item) => {
            return (
              item.formulas?.fORMULAVIEW
            );
          }
        }
      ]);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                    ecoCatByCalcType(calctypeid: 54) {
                      cATID: iD
                      fULLNAME
                      formulas {
                        iD
                        fORMULAVIEW
                      }
                  }
                }
              }
              `
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.ecoCatByCalcType;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        }).catch(response => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onEdit = (pcatid, pId, pView) => {
        setCatId(pcatid);
        setindId(pId);
        setdformulaview(pView);
        setShowNewCat(true);
    }

    return (
        <div>
      <Row style={{ minHeight: "95vh" }} wrap={false}>
        <Col flex="none">
          
        </Col>
        <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
        <PageHeader
          title={"Нийт"}
          dataCount={data?.length}
        />
        <div style={{ margin: 16 }}>
          <GridDefaultShimmer columns={columns} data={data} loading={loading}></GridDefaultShimmer>
            <NewCatFormula
                catid={catId}
                id={indId}
                formulaview={dformulaview}
                isOpen={showNewCat}
                onDismiss={() => {
                    setCatId();
                    setShowNewCat(false);
                }}
                afterSave={getData}
            />
           </div>
        </Col>
      </Row>
    </div>
    );
}

export default EconomyCategoryFormula;