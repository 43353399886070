import React, { useEffect, useState } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import { GridDefaultShimmer, PageHeader, Number } from "dw-components";
import TuitionFeePanel from "./Panel/TuitionFeePanel";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import FileDownload from 'js-file-download';
import { API } from '../../../API/API';

const TuitionFee = () => {

  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tuitionId, setTuitionId] = useState();
  const [data, setData] = useState({});
  const [oData, setOData] = useState([]);
  const [gridFilter, setGridFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [columns, setColumns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    }, {
      name: "Эдийн засгийн ангилал",
      fieldName: "economycategoryname",
      minColSize: "small" 
    },{
      name: "Сургалтын түвшин",
      fieldName: "traininglevelname",
      minColSize: "small" 
    }, {
      name: "Хичээлийн хэлбэр",
      fieldName: "lessontypename",
      minColSize: "small" 
    }, {
      name: "Багц цагийн төлбөр",
      fieldName: "batchhourlyfee",
      maxWidth: 200,
      minWidth: 200,
      summary: true,
      number: true,
      onRender: (item) => {
        return ( <Number value={item.batchhourlyfee}/> ); }
    }, {
        name: "Гадаад/Дотоод",
        fieldName: "isforeign",
        minColSize: "mid",
        onRender: (record) => {
            return (record.isforeign == 0 ? "Дотоод" : "Гадаад")
        }
    }, {
      name: "Төсвийн жил",
      fieldName: "year",
      minColSize: "mid",
    }, {
      fieldName: "action",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      }
    }
  ]);

  const getData = async (p_filter) => {
    setLoading(true);
    let tGridFilter = [];
    let tFitler = [];
    if (p_filter?.length > 0) {
        tFitler = p_filter;
    }

    let tData = [];
    await API.post("/api/budget/tuitionFeeConfigList", tFitler).then(response => {
        if (response?.status == 200 && response?.data?.retType == 0) {
            if (response?.data?.retData?.data?.length > 0) {
                tData = response?.data?.retData?.data;
            }
            if (response?.data?.retData?.category?.length > 0) {
              tGridFilter.push({
                key: "tuitionfee.traininglevelid",
                text: "Сургалтын түвшин",
                data: response?.data?.retData?.category
              });
            }
            if (response?.data?.retData?.type?.length > 0) {
              tGridFilter.push({
                key: "tuitionfee.lessontypeid",
                text: "Хичээлийн хэлбэр",
                data: response?.data?.retData?.type
              });
            }
            if (response?.data?.retData?.training?.length > 0) {
              tGridFilter.push({
                key: "tuitionfee.trainingtypeid",
                text: "Сургалтын хэлбэр",
                data: response?.data?.retData?.training
              });
            }
        }
    }).catch(() => {
     setLoading(false);
    });  
    setLoading(false);
    setData(tData);
    setOData(tData);
    setGridFilter(tGridFilter);
  }

  useEffect(() => {
    getData();
  }, []);

  const onNew = () => {
    setTuitionId();
    setShowNew(true);
  }

  const onDismiss = () => {
    setTuitionId();
    setShowNew(false);
  }

  const onEditClick = (id) => {
    setTuitionId(id);
    setShowNew(true);
  }

  const onSearch = (filteredData) => {
    setData(filteredData);
  }

  const downloadExcel = async () => {
    let tFitler = [];
    if (filteredData?.length > 0) {
      tFitler = filteredData;
    }

    await API.post("/api/budget/tuitionFeeConfigListTemp?filetype=xlsx", tFitler, {
      'responseType': 'blob'
    }).then(response => {
      FileDownload(response.data, "Сургалтын төлбөр.xlsx");

    }).catch(response => {
        setLoadingDownload(false);
    });
    setLoadingDownload(false);
  }

  const onGridFilterChange = async (p_gridfiltervalue) => {
    setFilteredData(p_gridfiltervalue);
    getData(p_gridfiltervalue);
  }

  return (
    <div>
      <Row style={{ minHeight: "95vh" }} wrap={false}>
        <Col flex="none">
          
        </Col>
        <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
        <PageHeader
          title={"Нийт"}
          dataCount={data?.length}
          buttonTitle="Сургалтын төлбөр"
          onButtonClick={onNew}
          onSearch={onSearch}
          data={oData}
          filteredData={data}
          columns={columns}
          addRightButton={(
            <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                <Space size={16} >
                    <div onClick={downloadExcel}>
                        <ExcelIcon />
                    </div>
                </Space>
            </div>
        )}
        gridFilter={gridFilter}
        onGridFilterChange={onGridFilterChange}
        />
        <div style={{ margin: 16 }}>
          <GridDefaultShimmer columns={columns} data={data} loading={loading} summary></GridDefaultShimmer>
          <TuitionFeePanel
            id={tuitionId}
            isOpen={showNew}
            onDismiss={onDismiss}
            afterSave={getData}
          ></TuitionFeePanel>
        </div>
        </Col>
      </Row>
    </div>
  );
};

export default TuitionFee;
