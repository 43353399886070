import { Space, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import NewDocumentCategory from './NewDocumentCategory';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { IconButton } from '@fluentui/react';
import { PageHeader, GridDefaultShimmer } from 'dw-components';
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';

const DocumentCategory = (props) => {

   const [data, setData] = useState({});
   const [oData, setOData] = useState([]);
   const [showNew, setShowNew] = useState(false);
   const [documentID, setDocumentID] = useState();
   const [loading, setLoading] = useState(false);

   const columns = [
      {
         name: "№",
         maxWidth: 25,
         minWidth: 25,
         onRender: (row, index) => {
         return <div> {index + 1 + "."} </div>
         }
      },
      {
         name: "Ангиллын нэр",
         fieldName: "nAME",
         minColSize: "mid"
      },
      {
         name: "Төлөв",
         fieldName: "iSACTIVE",
         minColSize: "mid",
         onRender: (record) => {
            return (record.iSACTIVE == 0 ? "Идэвхгүй" : "Идэвхтэй")
         }
      },
      {
         name: "Үйлдэл",
         fieldName: "action",
         minColSize: "mid",
         onRender: (item) => {
            return (
               <div>
                  <IconButton
                     iconProps={{ iconName: "Edit" }}
                     title="Edit"
                     ariaLabel="Edit"
                     onClick={() => onEdit(item.iD)}
                  />
               </div>
            );
         },
      },
   ];

   useEffect(() => {
      getData();
   }, []);

   const getData = async () => {
      setLoading(true);
      let tData = [];
      await APIGraphQL.post("", {
         query: `{
            sharedQuery {
               getDocumentCategoryList {
                  iD
                  nAME
                  iSACTIVE
                  cREATED
                  cREATEDBY
               }
            }
         }`
      }).then(response => {
         let tResp = response?.data?.data?.sharedQuery?.getDocumentCategoryList;
         if (tResp?.length > 0) {
            tData = tResp;
         }
      });
      setLoading(false);
      setData(tData);
      setOData(tData);
   }

   const onSearch = (data) => {
      setData(data);
   };

   const onNew = () => {
      setDocumentID();
      setShowNew(true);
   };

   const onEdit = (pId) => {
      setDocumentID(pId);
      setShowNew(true);
   }
   
   const onDismiss = () => {
      setDocumentID();
      setShowNew(false);
   }

   const downloadExcel = async () => {
      let tData = [];
      data?.map(r => {
          tData.push({
              "Ангиллын нэр": r?.nAME,
              "Төлөв": r?.iSACTIVE == 0 ? "Идэвхгүй" : "Идэвхтэй"
          });
      });
      const ws = xlsx.utils.json_to_sheet(tData);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Ангилал");
      xlsx.writeFile(wb,"Ангилал " + moment().format("YYYY-MM-DD HH-mm-ss") + ".xlsx");
    }

   return (
      <div>
         <Row style={{minHeight: "95vh" }} wrap={false}>
            <Col flex="none">
               
            </Col>
            <Col flex="auto" style={{ backgroundColor:"#f0f2f5" }}>
               <PageHeader
                  title={"Нийт"}
                  dataCount={data?.length}
                  buttonTitle="Ангилал нэмэх"
                  onButtonClick={onNew}
                  columns={columns}
                  onSearch={onSearch}
                  data={oData}
                  loading={loading}
                  filteredData={data}
                  addRightButton={(
                     <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                        <Space size={16} >
                              <div onClick={downloadExcel}>
                                 <ExcelIcon />
                              </div>
                        </Space>
                     </div>
                  )}
                  />
               <div style={{ padding: 16 }}>
                  <GridDefaultShimmer columns={columns} data={data} loading={loading}/>
                  <NewDocumentCategory id={documentID} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} />
               </div>
            </Col>
         </Row>
      </div>
  );
}

export default DocumentCategory;