import React, { useContext, useEffect, useState, useCallback } from 'react';
import { LoadScreen, Number, ColEdit } from 'dw-components';
import { API } from '../../../API/API';
import { TreeList } from 'devextreme-react';
import { Button } from 'antd';
import { Column, Scrolling, Selection, FilterRow } from 'devextreme-react/tree-list';
import SuggestionGridPanel from '../../Config/Suggestion/SuggestionGridPanel';
import AmountShortenButton from '../../../Components/AmountShortenButton/AmountShortenButton';
import { UserContext } from '../../../Context/UserContext';
import { ReactComponent as GridAttachIcon } from '../../../svg/gridAttach.svg';
import FileAttach from '../../Department/BudgetDtl/FileAttach/FileAttach';

const Suggestion = (props) => {

    const { userInfo } = useContext(UserContext);
    const [showPanel, setShowPanel] = useState(false);
    const [indId, setIndId] = useState(0);
    const [schoolid, setSchoolid] = useState(0);
    const [attachschoolid, setAttachSchoolid] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shorten, setShorten] = useState(1);

    const [expanded, setExpanded] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [attachId, setAttachId] = useState();
    const [attachName, setAttachName] = useState();

    const getData = async () => {
        setLoading(true);
        let tData = [];
        if (props?.id) {
            await API.get("/api/budget/suggestions?budgetid=" + props?.id + "&userid=" + userInfo?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
        }
        setData(tData);
        setLoading(false);
    }

    const customFormat = useCallback(
        (value) => {
            if (shorten != 1) {
                return (value * shorten).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
        },
        [shorten]
    );

    const onOptionChanged = useCallback((e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [props?.id]);

    const onEdit = (pid, ecocatid, schoolid) => {
        if (schoolid == 0) {
            setIndId(ecocatid);
        } else {
            setIndId(pid);
        }
        setSchoolid(schoolid);
        setShowPanel(true);
    }

    const onDismiss = () => {
        setIndId(0);
        setSchoolid(0);
        setShowPanel(false);
    }

    const getChildSummary = (p_node, p_col) => {
        let tSummary = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tSummary += getChildSummary(p_node.children[i], p_col);
            }
        } else {
            if (!isNaN(parseFloat(p_node?.data?.[p_col]))) {
                return parseFloat(p_node?.data?.[p_col]);
            } else {
                return 0;
            }
        }
        return tSummary;
    }

    const onNodesInitialized = useCallback((e) => {
        e.component.forEachNode((node) => {
            if (node?.data?.schoolid == 0) {
                let tValue = getChildSummary(node, "price");
                node.data["price"] = parseFloat(tValue);

                tValue = getChildSummary(node, "schoolprice");
                node.data["schoolprice"] = parseFloat(tValue);

                tValue = getChildSummary(node, "universityprice");
                node.data["universityprice"] = parseFloat(tValue);

            }
        });
    }, []);

    const onAttach = (pAttachId, pAttachName, pSchoolid) => {
        setAttachId(pAttachId);
        setAttachName(pAttachName);
        setAttachSchoolid(pSchoolid);
    }


    if (loading) {
        return <LoadScreen />
    }

    return (
        <div style={{ marginTop: 16 }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <AmountShortenButton setShorten={setShorten} />
                <Button
                    style={{ marginLeft: 16 }}
                    type={expanded ? null : "primary"}
                    onClick={() => {
                        setExpanded(prevExpanded => !prevExpanded)
                        setExpandedRowKeys([]);
                    }}
                    size="small"
                >{expanded ? 'Бүгдийг хаах' : 'Бүгдийг нээх'}</Button>
            </div>
            <TreeList
                id="id"
                dataSource={data}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                keyExpr="ecocatid"
                parentIdExpr="ecocatparentid"
                showRowLines
                showBorders
                rowAlternationEnabled={true}
                autoExpandAll={expanded}
                expandedRowKeys={expandedRowKeys}
                onOptionChanged={onOptionChanged}
                onNodesInitialized={onNodesInitialized}
                height={"calc(100vh - 180px)"}
            >
                <Scrolling columnRenderingMode="standard" mode="standard" />
                <Selection mode="single" />
                <FilterRow visible={true} />
                <Column
                    minWidth={500}
                    dataField="fullname"
                    caption="Эдийн засгийн ангилал"
                    cellRender={item => {
                        if (item?.data?.ischild >= 1 && !props?.disable) {
                            return (
                                <ColEdit onClick={() => onEdit(item?.data?.id, item?.data?.ecocatid, item?.data?.schoolid)}>
                                    {item?.data?.fullname}
                                </ColEdit>
                            )
                        } else {
                            return item?.text;
                        }
                    }}
                />
                <Column caption="Нэгжийн санал">
                    <Column
                        caption="Дүн"
                        dataField="price"
                        format="#,##0.00"
                        width={150} />
                    <Column
                        caption="Хувь"
                        dataField="percent"
                        format="fixedPoint"
                        width={60}
                        cellRender={item => {
                            return <Number value={((item?.data?.price || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="note"
                        caption="Тайлбар"
                    />
                    <Column
                        dataField="attachfilesdep"
                        caption="Файл"
                        width={80}
                        cellRender={(item) => {
                            if (item?.data?.attachfilesdep > 0) {
                                return (
                                    <ColEdit onClick={() => onAttach(item?.data?.id, item?.data?.fullname, item?.data?.schoolid)}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginTop: 4 }}>
                                                <GridAttachIcon />
                                            </div>
                                            <div style={{ marginLeft: 4, marginTop: -2 }}>
                                                {item?.data?.attachfilesdep}
                                            </div>
                                        </div>
                                    </ColEdit>
                                );
                            }
                            return null;
                        }} />
                </Column>
                <Column caption="Сургуулийн санал">
                    <Column
                        caption="Дүн"
                        dataField="schoolprice"
                        format="#,##0.00"
                        width={150} />
                    <Column
                        caption="Хувь"
                        dataField="schoolpercent"
                        format="fixedPoint"
                        width={60}
                        cellRender={item => {
                            return <Number value={((item?.data?.schoolprice || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="schoolnote"
                        caption="Тайлбар"
                    />
                    <Column
                        dataField="attachfiles"
                        caption="Файл"
                        width={80}
                        cellRender={(item) => {
                            if (item?.data?.attachfiles > 0) {
                                return (
                                    <ColEdit onClick={() => onAttach(item?.data?.id, item?.data?.fullname)}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginTop: 4 }}>
                                                <GridAttachIcon />
                                            </div>
                                            <div style={{ marginLeft: 4, marginTop: -2 }}>
                                                {item?.data?.attachfiles}
                                            </div>
                                        </div>
                                    </ColEdit>
                                );
                            } 
                            return null;
                        }} />
                </Column>
                <Column caption="Нэгтгэл төсөв">
                    <Column
                        caption="Дүн"
                        dataField="universityprice"
                        format="#,##0.00"
                        width={150} />
                    <Column
                        caption="Хувь"
                        dataField="universitypercent"
                        format="fixedPoint"
                        width={60}
                        cellRender={item => {
                            return <Number value={((item?.data?.universityprice || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="universitynote"
                        caption="Тайлбар"
                    />
                </Column>
            </TreeList>
            <SuggestionGridPanel
                id={props?.id}
                ecocatid={indId}
                schoolid={schoolid}
                isOpen={showPanel}
                total={props.total}
                onDismiss={onDismiss}
                afterSave={getData}
            />
            <FileAttach disable={props?.disable} budgetid={props?.id} schoolid={attachschoolid} id={attachId} name={attachName} isOpen={attachId ? true : false} onDismiss={() => { setAttachId(); }} afterSave={getData} />
        </div>
    );
}

export default Suggestion;