import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, FieldText, ButtonSecondary, FieldDate } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Scrolling, Summary, TotalItem, GroupItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { Icon } from '@fluentui/react/lib/Icon';
import { UserType } from "../../Const/UserType";

const BResearchIncome = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dEconomy, setDEconomy] = useState([]);

    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.iD = tR.id;
                tR.bUDGETID = parseInt(props?.id);
                tR.aMOUNT = tR.amount;
                tR.eCONOMYCATEGORYNAME = tR.economycategoryname;
                tR.eCONOMYCATEGORYID = tR.economycategoryid;
                tR.nOTE = tR.note;
                tR.sTARTDATE = tR.startdate;
                tR.eNDDATE = tR.enddate;
                tR.nAME = tR.name;
                delete tR.id;
                delete tR.depcode;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.schoolname;
                delete tR.amount;
                delete tR.economycategoryname;
                delete tR.economycategoryid;
                delete tR.note;
                delete tR.name;
                delete tR.startdate;
                delete tR.enddate;
                delete tR.created;
                delete tR.createdby;
                delete tR.schoolcode;
                delete tR.depcode;
                delete tR.year;
                
                let currentschoolid = schoolid;
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                } 
                tR.sCHOOLID = parseInt(currentschoolid);
                let tIndex = dSchools.map(r => r.key).indexOf(currentschoolid);
                tR.sCHOOLNAME = "";
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                } 

                delete tR.arrayIndex;
                delete tR.edit;
                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;

                tDataEdit[i] = tR;
            }
            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetResearchIncomeInputType]) {
            //         budgetMutation {
            //          addBudgetResearchIncome(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetResearchIncome?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBResearchIncome?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            //vSuccess = await calcfunc("calcBResearchIncome", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;
        await API.get("/api/budget/budgetResearchFeeTemp?budgetid=" + props?.id +  "&schoolid=" + pSchoolId + "&alldep="+ alldep + "&userschoolid=" + userInfo?.schoolid +"&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Судалгааны орлого" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDEcoCat = [];
        
        let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;
        setLoading(true);
        await API.get("/api/budget/budgetResearchFee?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData?.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  ecoCatByCalcType(calctypeid: 8) {
                   key: iD
                   text: nAME
                 }
                }
                }`
        }).then(response => {
            let tResp = response?.data?.data;  
            if (tResp?.sharedQuery?.ecoCatByCalcType?.length > 0) {
              tDEcoCat = tResp?.sharedQuery?.ecoCatByCalcType;
            }
        }).catch(() => {
            setLoading(false);
        });

        setLoading(true);        

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setDEconomy(tDEcoCat);
    }

    const setSchools = async(pSchoolId) => 
    {
        const schools = await getSchools(pSchoolId);
        if(userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);            
        }
        else if(userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6){
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;    
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
        }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);


    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBudgetResearchIncome(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetResearchIncome == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBResearchIncome?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            }
                //vSuccess = await calcfunc("calcBResearchIncome", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)

        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
        query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
          variables: {
            "typeid": parseInt(typeid)
        }
        }).then(response => {       
        let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
            setDschools(tSchool);

    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);  
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Судалгааны орлого"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            
            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
               <div style={{ marginTop: 13 }}>
                   <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
               </div>
            )}
            {props?.showSchool && (
               <div style={{ marginTop: 24 }}>
                   <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
               </div>
            )}
            {props?.showDep && (
            <div style={{ marginTop: 24 }}>
                <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
            </div>
        )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөг/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true}  />
                          {disable && props?.showSchool && (
                              <Column
                              dataField="schoolcode"
                              caption="Сургууль"
                              width={150}
                              alignment='right'
                              groupIndex={0}/>
                          )}
                          {disable && props?.showDep && (
                            <Column
                            dataField="depcode"
                            caption="Тэнхим"
                            width={150}
                            alignment='right'
                            groupIndex={1} />
                        )}
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="name"
                                caption="Төсөл хөтөлбөрийн нэр"
                                width={600}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                     <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "name")} defaultValue={item.data?.name} />
                                    );
                                }} />
                            <Column
                                dataField="economycategoryname"
                                caption="Ангилал"
                                width={300}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.iD, pValue, "economycategoryid", pNameValue, "economycategoryname")} defaultValue={item.data?.economycategoryid} data={dEconomy} />
                                    );
                                }} />
                            <Column
                                dataField="startdate"
                                caption="Эхлэх огноо"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDate disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "startdate")} defaultValue={item.data?.startdate} />
                                    );
                                }} />
                            <Column
                                dataField="enddate"
                                caption="Дуусах огноо"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                     <FieldDate disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "enddate")} defaultValue={item.data?.enddate} />
                                    );
                                }} />
                            <Column
                                dataField="amount"
                                caption="Дүн"
                                alignment='center'
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "amount")} defaultValue={item.data?.amount} fixedDecimalScale={true} />
                                    );
                                }} />
                             <Column
                                 dataField="note"
                                 caption="Тайлбар"
                                 minWidth={300}
                                 alignment='center'
                                 format="fixedPoint"
                                 cellRender={(item) => {
                                    if(!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.note} </div>
                                        );
                                    }
                                 }} />
                                 ({!disable && 
                                 <Column
                                    dataField="action"
                                    caption=""
                                    width={30}
                                    cellRender={(item) => {
                                        return (
                                            <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                                <Icon iconName="Delete" />
                                            </div>
                                        );
                                    }} />
                                })
                            <Summary>
                                <GroupItem
                                    column="amount"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    showInGroupFooter={false}
                                    alignByColumn={true}
                                    displayFormat="{0}&nbsp;&nbsp;" />
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="amount"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                     <div style={{ marginTop: 24 }}>
                              Тайлбар: Зөвхөн төсвийн жилд хамаарах хугацааны орлогыг оруулна.
                      </div>
                        <div style={{ marginTop: 24 }}>
                            <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                        </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BResearchIncome;