import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Typography } from "antd";
import { ColEdit } from "dw-components";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { DEPARTMENTS, DEPARTMENTKEYS } from "../../../Const/Columns";
import {
  categoryTree,
  groupListData,
  convertToTableTree,
  getParentCategories,
} from "../../../Utils";
//calculate method panels
import ByInputIncomePanel from "../../CalculateMethods/Income/ByInputIncomePanel";
import ByPerformanceIncomePanel from "../../CalculateMethods/Income/ByPerformanceIncomePanel";
import BelasPanel from "../../CalculateMethods/Income/BelasPanel";

const { Text } = Typography;

const BranchTotalIndicators = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedCalculateMethod, setSelectedCalculateMethod] = useState("");
  const [selectedCalculateMethodData, setSelectedCalculateMethodData] =
    useState();
  const [selectedBudgetId, setSelectedBudgetId] = useState("");
  const [selectedIndicatorData, setSelectedIndicatorData] = useState("");

  const [showCalculateMethod, setShowCalculateMethod] = useState(false);
  const [calculateMethodData, setCalculateMethodData] = useState({});
  const [bCalculateMethodData, setBCalculateMethodData] = useState({});

  const [indicatorTotals, setIndicatorTotals] = useState({});
  const [rowTotals, setRowTotals] = useState({});
  const [colTotals, setColTotals] = useState({});
  const [parentCategories, setParentCategories] = useState([]);

  const methodCollectionRef = collection(db, "budgetMethods");
  const BudgetIndicatorsRef = collection(db, "budgetIndicators");
  const BudgetRequestsRef = collection(db, "budgetRequests");
  const IndicatorGroupsRef = collection(db, "indicatorGroups");

  let tRowTotal = 0;
  let totals = {};
  let initColumns = [
    {
      title: "Эдийн засгийн ангилал",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: (a, b) => a.orderNo - b.orderNo,
      defaultSortOrder: "ascend",
      render: (text, record) => {
        if (record.indicatorId) {
          return record.code + " - " + record.name;
        } else {
          return (
            <span className="indicator-group">
              {record.code + " - " + record.name}
            </span>
          );
        }
      },
    },
    {
      title: "Нэгтгэл төсөв",
      dataIndex: "totalBudget",
      key: "totalBudget",
      width: "5%",
      align: "right",
      render: (text, record) => {
        let totalAmount = 0;
        if (!record.indicatorId) {
          const calcTotalAmount = (record) => {
            if (record.children && record.children.length > 0) {
              record.children.map((row) => {
                if (row.indicatorId) {
                  let amount = 0;
                  let methodData = bCalculateMethodData[row.indicatorId];
                  if (methodData) {
                    for (const key in methodData) {
                      amount = parseFloat(
                        methodData[key].totalBudgetAmount.replace(/,/g, "")
                      );
                      totals = {
                        ...totals,
                        [row.indicatorId]: amount,
                      };

                      totalAmount += amount;
                    }
                  }

                  setIndicatorTotals(totals);
                  props.setTotals(totals);
                } else {
                  calcTotalAmount(row);
                }
              });
            }

            return totalAmount;
          };

          let total = calcTotalAmount(record);

          if (parentCategories.includes(record.code)) {
            if (record.category == "income") {
              tRowTotal += total;
            } else if (record.category == "outcome") {
              tRowTotal -= total;
            }

            setRowTotals(tRowTotal);
          }

          return (
            <div className="parent-total">
              {mntFormatter.format(total || 0) + "₮"}
            </div>
          );
        } else {
          const calcTotalAmount = (record) => {
            let amount = 0;
            let methodData = bCalculateMethodData[record.indicatorId];
            if (methodData) {
              for (const key in methodData) {
                amount = parseFloat(
                  methodData[key].totalBudgetAmount.replace(/,/g, "")
                );
                totalAmount += amount;
              }
            }

            return totalAmount;
          };

          let total = calcTotalAmount(record);

          return (
            <div className="parent-total">
              {mntFormatter.format(total) + "₮"}
            </div>
          );
        }
      },
    },
  ];

  let [columns, setColumns] = useState([]);

  useEffect(async () => {
    // get department requests
    const DepartmentReqQuery = query(
      BudgetRequestsRef,
      where("budgetId", "==", props.budgetId),
      where("branchSchool", "==", props.budgetRequest.branchSchool),
      where("sentFrom", "==", "DEPARTMENT")
    );

    const DepartmentReqDocs = await getDocs(DepartmentReqQuery);
    const DepartmentReqs = DepartmentReqDocs.docs.map(
      (doc) => doc.data().department
    );

    // get department calculate method datas
    const Query = query(
      methodCollectionRef,
      where("budgetId", "==", props.budgetId),
      where("branchSchool", "==", props.budgetRequest.branchSchool),
      where("type", "==", "DEPARTMENT")
      // where("category", "==", "income")
    );

    const MethodsDocs = await getDocs(Query);
    const methods = MethodsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const methodsObj = methods.reduce(
      (obj, current) => ({
        ...obj,
        [current.indicatorId]: {
          ...obj[current.indicatorId],
          [current.department]: current,
        },
      }),
      {}
    );

    let bMethodsObj;
    if (props.budgetRequest) {
      const bMethodQuery = query(
        methodCollectionRef,
        where("budgetId", "==", props.budgetId),
        where("type", "==", "BRANCH")
        // where("category", "==", "income")
      );

      const bMethodsDocs = await getDocs(bMethodQuery);
      const bMethods = bMethodsDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      bMethodsObj = bMethods.reduce(
        (obj, current) => ({
          ...obj,
          [current.indicatorId]: {
            ...obj[current.indicatorId],
            [current.department]: current,
          },
        }),
        {}
      );
      await calcTotals(bMethodsObj);
    } else {
      bMethodsObj = JSON.parse(JSON.stringify(methodsObj));
      for (let key in bMethodsObj) {
        for (let item in bMethodsObj[key]) {
          bMethodsObj[key][item].type = "BRANCH";
          bMethodsObj[key][item].description = "";
        }
      }
      await calcTotals(bMethodsObj);
    }

    // indicator groups
    // const indicatorGroupQuery = query(
    //   IndicatorGroupsRef,
    //   where("indicatorCategory", "==", "income")
    // );
    const IndicatorGroupDocs = await getDocs(IndicatorGroupsRef);
    const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // indicators of current budget
    const indicatorQuery = query(
      BudgetIndicatorsRef,
      where("budgetId", "==", props.budgetId)
      // where("category", "==", "income")
    );
    const indicatorDocs = await getDocs(indicatorQuery);
    const indicators = indicatorDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const categories = await categoryTree(
      indicatorGroups,
      "groupParent",
      "groupName"
    );

    const parentCats = await getParentCategories(categories);
    const groupedIndicators = await groupListData(indicators);
    const listData = await convertToTableTree(categories, groupedIndicators);
    // const methodList = await methodDataToList(listData, methodsObj);

    setParentCategories(parentCats);
    setBCalculateMethodData(bMethodsObj);
    setCalculateMethodData(methodsObj);
    setData(listData);

    props.setMethodsData(bMethodsObj);

    setIsLoading(false);
  }, [props.budgetRequest]);

  useEffect(async () => {
    if (Object.keys(bCalculateMethodData).length !== 0) {
      const departmentCols = await buildColumns(
        props.budgetRequest.branchSchool,
        bCalculateMethodData
      );

      setColumns([...initColumns, ...departmentCols]);
    }
  }, [bCalculateMethodData]);

  useEffect(async () => {
    props.setTotals(indicatorTotals);
  }, [indicatorTotals]);

  const buildColumns = async (branchSchool, methods) => {
    let columns = [];
    let tColTotal = {};

    if (DEPARTMENTS[branchSchool]) {
      columns = DEPARTMENTS[branchSchool].map((req) => ({
        title: DEPARTMENTKEYS[req],
        dataIndex: "department",
        key: req,
        width: "5%",
        align: "right",
        render: (text, record) => {
          if (record.indicatorId) {
            if (methods[record.indicatorId]) {
              const methodData = methods[record.indicatorId][req];
              if (methodData) {
                return (
                  <div>
                    <ColEdit
                      onClick={() => {
                        onCalculateMethodNew({
                          indicatorId: record.indicatorId,
                          calculateMethod: record.data.calculateMethod,
                          category: record.data.category,
                          name: record.name,
                          department: methodData.department,
                        });
                      }}
                    >
                      {mntFormatter.format(
                        methodData.totalBudgetAmount.replace(/,/g, "")
                      ) + "₮"}
                    </ColEdit>
                  </div>
                );
              } else {
                return mntFormatter.format(0) + "₮";
              }
            } else {
              return mntFormatter.format(0) + "₮";
            }
          } else {
            let totalAmount = 0;

            const calcTotalAmount = (record) => {
              if (record.children && record.children.length > 0) {
                record.children.map((row) => {
                  if (row.indicatorId) {
                    let amount = 0;
                    let methodData = methods[row.indicatorId];
                    if (methodData) {
                      if (methodData[req]) {
                        amount = parseFloat(
                          methodData[req].totalBudgetAmount.replace(/,/g, "")
                        );
                      }
                    }
                    totalAmount += amount;
                  } else {
                    calcTotalAmount(row);
                  }
                });
              }

              return totalAmount;
            };

            let total = calcTotalAmount(record);

            if (parentCategories.includes(record.code)) {
              if (record.category == "income") {
                if (!tColTotal[DEPARTMENTKEYS[req]])
                  tColTotal[DEPARTMENTKEYS[req]] = 0;
                tColTotal = {
                  ...tColTotal,
                  [DEPARTMENTKEYS[req]]: (tColTotal[DEPARTMENTKEYS[req]] +=
                    total),
                };
              } else if (record.category == "outcome") {
                if (!tColTotal[DEPARTMENTKEYS[req]])
                  tColTotal[DEPARTMENTKEYS[req]] = 0;
                tColTotal = {
                  ...tColTotal,
                  [DEPARTMENTKEYS[req]]: (tColTotal[DEPARTMENTKEYS[req]] -=
                    total),
                };
              }
            }

            setColTotals(tColTotal);
            return (
              <div className="parent-total">
                {mntFormatter.format(total) + "₮"}
              </div>
            );
          }
        },
      }));
    }
    return columns;
  };

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const calcTotals = async (data) => {
    let tIncome = 0;
    let tOutcome = 0;

    for (let i in data) {
      for (let j in data[i]) {
        let value = data[i][j];
        let amount = parseFloat(value.totalBudgetAmount.replace(/,/g, ""));
        if (value.category == "income") {
          tIncome += amount;
        } else if (value.category == "outcome") {
          tOutcome += amount;
        }
      }
    }

    props.setTotalIncome(tIncome);
    props.setTotalOutcome(tOutcome);
  };

  const setTempCalculateMethodData = async (data) => {
    let temp = { ...bCalculateMethodData };
    temp[data.indicatorId][data.department] = { ...data };

    await calcTotals(temp);

    setBCalculateMethodData(temp);
    props.setMethodsData(temp);
  };

  const onCalculateMethodNew = (data) => {
    setName(data.name);
    setSelectedCalculateMethod(data.calculateMethod);
    setSelectedIndicatorData({
      indicatorId: data.indicatorId,
      category: data.category,
    });
    setShowCalculateMethod(true);

    if (props.budgetId) {
      setSelectedBudgetId(props.budgetId);
      setSelectedCalculateMethodData(
        bCalculateMethodData[data.indicatorId][data.department]
      );
    }
  };

  const onCalculateMethodDismiss = () => {
    setSelectedCalculateMethod("");
    setSelectedIndicatorData({});
    setShowCalculateMethod(false);
    setSelectedBudgetId("");
    setSelectedCalculateMethodData({});
  };

  let vCalculateMethodPanel = null;
  switch (selectedCalculateMethod) {
    // income
    case "byInputIncome":
      vCalculateMethodPanel = (
        <ByInputIncomePanel
          name={name}
          type={"BRANCH"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByInputIncomePanel>
      );
      break;
    case "byPerformanceIncome":
      vCalculateMethodPanel = (
        <ByPerformanceIncomePanel
          name={name}
          type={"BRANCH"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByPerformanceIncomePanel>
      );
      break;

    case "БЭЛАС":
      vCalculateMethodPanel = (
        <BelasPanel
          name={name}
          type={"BRANCH"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></BelasPanel>
      );
      break;
    default:
      break;
  }

  let showColTotals;
  if (DEPARTMENTS[props.budgetRequest.branchSchool]) {
    showColTotals = DEPARTMENTS[props?.budgetRequest?.branchSchool].map(
      (item) => {
        return (
          <Table.Summary.Cell index={1} colSpan={1} align="right">
            <Text type="success" strong>
              {mntFormatter.format(colTotals[DEPARTMENTKEYS[item]]) + "₮"}
            </Text>
          </Table.Summary.Cell>
        );
      }
    );
  }

  return (
    <div style={{ padding: "65px 20px 30px" }}>
      <Table
        loading={isLoading}
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        childrenColumnName="children"
        pagination={false}
        className="branch-indicators-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1} align="right">
                  <b>Нийт төсөв:</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1} align="right">
                  <Text type="success" strong>
                    {mntFormatter.format(rowTotals) + "₮"}
                  </Text>
                </Table.Summary.Cell>
                {showColTotals}
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {vCalculateMethodPanel}
    </div>
  );
};

export default BranchTotalIndicators;
