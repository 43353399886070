export const DEPARTMENTS = {
  BOH: [
    { key: "Тэнхимийн туслах ажилтан", text: "Тэнхимийн туслах ажилтан" },
    { key: "Гэрээт", text: "Гэрээт" },
    { key: "Цагийн багш", text: "Цагийн багш" },
    { key: "Дадлага", text: "Дадлага" },
    { key: "Захиргаа", text: "Захиргаа" },
    { key: "Түүхийн тэнхим", text: "Түүхийн тэнхим" },
    {
      key: "Монгол хэл, хэл шинжлэлийн тэнхим",
      text: "Монгол хэл, хэл шинжлэлийн тэнхим",
    },
    { key: "Үйлчилгээний ажилчид", text: "Үйлчилгээний ажилчид" },
    { key: "Гадаад хэлний төв", text: "Гадаад хэлний төв" },
    { key: "Европ судлалын тэнхим", text: "Европ судлалын тэнхим" },
    { key: "Ази судлалын тэнхим", text: "Ази судлалын тэнхим" },
    {
      key: "Их Британи Америк судлалын тэнхим",
      text: "Их Британи Америк судлалын тэнхим",
    },
    {
      key: "Утга зохиол, урлаг судлалын тэнхим",
      text: "Утга зохиол, урлаг судлалын тэнхим",
    },
    {
      key: "Философи, шашин судлалын тэнхим",
      text: "Философи, шашин судлалын тэнхим",
    },
    { key: "Зөвлөх", text: "Зөвлөх" },
  ],
  SASA: [],
  BONS: [
    { key: "Химийн тэнхим", text: "Химийн тэнхим" },
    { key: "Физизийн тэнхим", text: "Физизийн тэнхим" },
    { key: "Биологийн тэнхим", text: "Биологийн тэнхим" },
    { key: "Газар зүйн тэнхим", text: "Газар зүйн тэнхим" },
    { key: "Гэрээт", text: "Гэрээт" },
    { key: "Математикийн тэнхим", text: "Математикийн тэнхим" },
    { key: "Геологи, геофизикийн тэнхим", text: "Геологи, геофизикийн тэнхим" },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  DOSS: [
    {
      key: "Боловсрол, сэтгэл судлалын тэнхим",
      text: "Боловсрол, сэтгэл судлалын тэнхим",
    },
    {
      key: "Сэтгүүл зүй, олон нийтийн харилцааны тэнхим",
      text: "Сэтгүүл зүй, олон нийтийн харилцааны тэнхим",
    },
    {
      key: "Нийгмийн судалгааны хүрээлэн",
      text: "Нийгмийн судалгааны хүрээлэн",
    },
    { key: "Үйлчилгээний ажилчид", text: "Үйлчилгээний ажилчид" },
    {
      key: "Археологи, антропологийн тэнхим",
      text: "Археологи, антропологийн тэнхим",
    },
    {
      key: "Социологи, нийгмийн ажлын тэнхим",
      text: "Социологи, нийгмийн ажлын тэнхим",
    },
    { key: "Улс төр судлалын тэнхим", text: "Улс төр судлалын тэнхим" },
    { key: "Эдийн засгийн тэнхим", text: "Эдийн засгийн тэнхим" },
  ],
  GRADS: [],
  BS: [
    { key: "Бизнес консалтинг төв", text: "Бизнес консалтинг төв" },
    {
      key: "Маркетинг, худалдааны тэнхим",
      text: "Маркетинг, худалдааны тэнхим",
    },
    { key: "Менежментийн тэнхим", text: "Менежментийн тэнхим" },
    {
      key: "Нягтлан бодох бүртгэлийн тэнхим",
      text: "Нягтлан бодох бүртгэлийн тэнхим",
    },
    { key: "Санхүүгийн тэнхим", text: "Санхүүгийн тэнхим" },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  SIRPA: [
    { key: "Нийтийн удирдлагын тэнхим", text: "Нийтийн удирдлагын тэнхим" },
    {
      key: "Олон улсын харилцааны тэнхим",
      text: "Олон улсын харилцааны тэнхим",
    },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  SOL: [
    { key: "Нийтийн эрх зүйн тэнхим", text: "Нийтийн эрх зүйн тэнхим" },
    { key: "Хувийн эрх зүйн тэнхим", text: "Хувийн эрх зүйн тэнхим" },
    { key: "Практик сургалтын төв", text: "Практик сургалтын төв" },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  SEAS: [
    {
      key: "Хими, биологийн инженерчлэлийн тэнхим",
      text: "Хими, биологийн инженерчлэлийн тэнхим",
    },
    {
      key: "Хэрэглээний математикийн тэнхим",
      text: "Хэрэглээний математикийн тэнхим",
    },
    {
      key: "Хүрээлэн буй орчин, ойн инженерчлэлийн тэнхим",
      text: "Хүрээлэн буй орчин, ойн инженерчлэлийн тэнхим",
    },
    {
      key: "Электроник, холбооны инженерчлэлийн тэнхим",
      text: "Электроник, холбооны инженерчлэлийн тэнхим",
    },
    {
      key: "Мэдээлэл, компьютерийн ухааны тэнхим",
      text: "Мэдээлэл, компьютерийн ухааны тэнхим",
    },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  ZA: [
    {
      key: "Нягтлан бодох бүртгэлийн тэнхим",
      text: "Нягтлан бодох бүртгэлийн тэнхим",
    },
    {
      key: "Санхүү, хангамж үйлчилгээний алба",
      text: "Санхүү, хангамж үйлчилгээний алба",
    },
    {
      key: "Санхүү, эдийн засгийн тэнхим",
      text: "Санхүү, эдийн засгийн тэнхим",
    },
    { key: "Ерөнхий эрдмийн тэнхим", text: "Ерөнхий эрдмийн тэнхим" },
    { key: "Сургалт, оюутны алба", text: "Сургалт, оюутны алба" },
    {
      key: "Эрдэм шинжилгээ, инновацийн алба",
      text: "Эрдэм шинжилгээ, инновацийн алба",
    },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  ERD: [
    {
      key: "Санхүү, хангамж үйлчилгээний алба",
      text: "Санхүү, хангамж үйлчилгээний алба",
    },
    {
      key: "Санхүү, эдийн засгийн тэнхим",
      text: "Санхүү, эдийн засгийн тэнхим",
    },
    { key: "Хүмүүнлэгийн ухааны тэнхим", text: "Хүмүүнлэгийн ухааны тэнхим" },
    { key: "Бизнесийн ухааны тэнхим", text: "Бизнесийн ухааны тэнхим" },
    {
      key: "Нягтлан бодох бүртгэлийн тэнхим",
      text: "Нягтлан бодох бүртгэлийн тэнхим",
    },
    { key: "Сургалт, оюутны алба", text: "Сургалт, оюутны алба" },
    {
      key: "Эрдэм шинжилгээ, инновацийн алба",
      text: "Эрдэм шинжилгээ, инновацийн алба",
    },
    { key: "Удирдлага", text: "Удирдлага" },
    { key: "Үйлчилгээ", text: "Үйлчилгээ" },
  ],
  EECBEHS: [
    { key: "Цагийн багш", text: "Цагийн багш" },
    { key: "Захиргаа", text: "Захиргаа" },
    { key: "Сургалт үйлчилгээ", text: "Сургалт үйлчилгээ" },
    { key: "Экологи боловсролын төв", text: "Экологи боловсролын төв" },
  ],
  IZTRC: [],
  IMS: [{ key: "Захиргаа", text: "Захиргаа" }],
  JICA: [
    { key: "Ерөнхий удирдлага", text: "Ерөнхий удирдлага" },
    { key: "Хангамжийн  ажилтан", text: "Хангамжийн  ажилтан" },
    {
      key: "Номын сан хамтын ажиллагааны тасаг",
      text: "Номын сан хамтын ажиллагааны тасаг",
    },
  ],
  MBOOKS: [
    { key: "МУИС Хэвлэлийн газар", text: "МУИС Хэвлэлийн газар" },
    { key: "Захиргаа", text: "Захиргаа" },
  ],
  MPRESS: [],
  RAI: [],
  EI: [{ key: "Цагийн багш", text: "Цагийн багш" }],
  NRC: [],
  DORM: [],
  CED: [
    {
      key: "Захиргаа, хүний нөөцийн газар",
      text: "Захиргаа, хүний нөөцийн газар",
    },
    {
      key: "Хяналт шинжилгээ, үнэлгээний алба",
      text: "Хяналт шинжилгээ, үнэлгээний алба",
    },
    {
      key: "Мэдээллийн технологийн газар",
      text: "Мэдээллийн технологийн газар",
    },
    { key: "Номын сан", text: "Номын сан" },
    { key: "Хамтын ажиллагааны хэлтэс", text: "Хамтын ажиллагааны хэлтэс" },
    { key: "Эрдэм шинжилгээний хэлтэс", text: "Эрдэм шинжилгээний хэлтэс" },
    { key: "Санхүү, төлөвлөлтийн газар", text: "Санхүү, төлөвлөлтийн газар" },
    { key: "Хичээлийн 1 дүгээр байр", text: "Хичээлийн 1 дүгээр байр" },
    {
      key: "Цөмийн физикийн судалгааны төв",
      text: "Цөмийн физикийн судалгааны төв",
    },
    { key: "Оюутны 3 дугаар байр", text: "Оюутны 3 дугаар байр" },
    { key: "Оюутны 4 дүгээр байр", text: "Оюутны 4 дүгээр байр" },
    { key: "Оюутны 5 дугаар байр", text: "Оюутны 5 дугаар байр" },
    {
      key: "Инноваци, Технологи дамжуулах төв",
      text: "Инноваци, Технологи дамжуулах төв",
    },
    { key: "Сантехникийн хэсэг", text: "Сантехникийн хэсэг" },
    { key: "Хангамж үйлчилгээний газар", text: "Хангамж үйлчилгээний газар" },
    { key: "Хичээлийн 2 дугаар байр", text: "Хичээлийн 2 дугаар байр" },
    { key: "Хүн эмнэлгийн салбар", text: "Хүн эмнэлгийн салбар" },
    {
      key: "Хөтөлбөр, чанарын баталгаажуулалтын хэлтэс",
      text: "Хөтөлбөр, чанарын баталгаажуулалтын хэлтэс",
    },
    { key: "Барилгын бригад", text: "Барилгын бригад" },
    {
      key: "Багшийн хөгжил, цахим сургалтын төв",
      text: "Багшийн хөгжил, цахим сургалтын төв",
    },
    { key: "Элсэлт, бүртгэлийн хэлтэс", text: "Элсэлт, бүртгэлийн хэлтэс" },
    { key: "Авто гараж", text: "Авто гараж" },
    { key: "Цахилгааны хэсэг", text: "Цахилгааны хэсэг" },
    { key: "Биеийн тамирын тэнхим", text: "Биеийн тамирын тэнхим" },
    { key: "Оюутан, төгсөгчийн хэлтэс", text: "Оюутан, төгсөгчийн хэлтэс" },
    { key: "Номын сан /үйлчилгээ/", text: "Номын сан /үйлчилгээ/" },
    { key: "Захиргаа", text: "Захиргаа" },
    { key: "Оюутны 1 дүгээр байр", text: "Оюутны 1 дүгээр байр" },
    { key: "Оюутны 2 дугаар байр", text: "Оюутны 2 дугаар байр" },
  ],
};