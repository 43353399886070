import React, { useEffect, useState } from "react";
import '../../../App.css';
import PieChart, {Series, Label, Connector, Font, Title, Export
} from 'devextreme-react/pie-chart';

import {Chart, CommonSeriesSettings, ArgumentAxis, Legend, Grid, Crosshair, ValueAxis, Tooltip, Point, SeriesTemplate
} from 'devextreme-react/chart';
import { LoadScreen, PageHeader } from 'dw-components';
import { API } from '../../../API/API';
import { APIGraphQL } from '../../../API/APIGraphQL';

const Dashboard = (props) => {

  const [data, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [dataSchool, setDataSchool] = useState([]);
  const [dataProfit, setDataProfit] = useState([]);
  const [dataExpense, setDataExpense] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterData, setFilterData] = useState([]);

  const budgetSources = [
    { value: 'profit', name: 'Орлого' },
    { value: 'expense', name: 'Зарлага' },
  ];

  const getData = async (p_name) => {
    setLoading(true);
   
    let tDataset = [];
    let filt = filterData.filter(r=>r.key == filterValue);
    if(filt[0]?.iD > 0)
    {
        await API.get("/api/budget/budgetDashboardData?budgetid=" + filt[0]?.iD + "&schoolid=0").then(response => {
          if (response?.status == 200 && response?.data?.retType == 0) {
            tDataset = response?.data?.retData;
          }
        }).catch(() => {
          setLoading(false);
        });
        setData(tDataset["budgetstatus"]);
        setOData(tDataset["budgetstatus"]);
        setDataSchool(tDataset["budgetbyschool"]);
        setDataProfit(tDataset["profitbycategory"]);
        setDataExpense(tDataset["expensebycategory"]);
        setLoading(false);
    }
  }

  const getBudgets = async () => {
    if(filterValue == "") {
      let filterDataSet = [];
      let latestid = 0;
      await APIGraphQL.post("", {
        query: `query ($rowstate: String) {
            budgetQuery {
              budgets(rowstate: $rowstate) {
                key: yEAR
                text: yEAR
                iD
              }
            }
          }
          `,
        variables: {
            "rowstate": "open"
        }
      }).then(response => {
          if (response?.data?.data?.budgetQuery?.budgets?.length > 0) {
              filterDataSet = response?.data?.data?.budgetQuery?.budgets;
              latestid = filterDataSet[0]?.key;
          }
      });
      setFilterData(filterDataSet);
      setFilterValue(latestid);
    } 
  }

  useEffect(() => {
    getBudgets();
    getData(filterValue);
  }, [filterValue]);

  const onChangeFilter = (p_key, p_name) => {
    setFilterValue(p_name);
  }

  if (loading) {
    return <LoadScreen />
  }

  return (
    <div className="row">
      <PageHeader
        showLeftFilter
        changeFilter={onChangeFilter}
        data={oData}
        filterValue={filterValue}
        filteredData={data}
        filterData={filterData}/>
      <div className="dashboardcolumn">
        <div style={{ backgroundColor: "#ffffff", border: '1px solid #C2C5C6', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <PieChart
            id="pie"
            dataSource={data}
            palette="Pastel"
          >
            <Title text="Төлөв сургуулиар">
              <Font color="black" size={20} family={"Segoe UI"} weight={600} />
            </Title>
            <Series
              argumentField="statusname"
              valueField="cntschool"
            >
              <Label visible={true}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>

            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="top"
            />
            <Export enabled={true} />
          </PieChart>
        </div>
      </div>
      <div className="dashboardcolumn">
        <div style={{ backgroundColor: "#ffffff", border: '1px solid #C2C5C6', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <Chart
            id="chart"
            dataSource={dataSchool}
            palette="Bright"
          >
            <CommonSeriesSettings
              type="spline"
              argumentField="schoolcode"
            >
              <Point hoverMode="allArgumentPoints" />
            </CommonSeriesSettings>
            {
              budgetSources.map((item) => <Series
                key={item.value}
                valueField={item.value}
                name={item.name} />)
            }
            <ArgumentAxis
              valueMarginsEnabled={false}
              discreteAxisDivisionMode="crossLabels"
            >
              <Grid visible={true} />
            </ArgumentAxis>
            <Crosshair
              enabled={true}
              color="#949494"
              width={3}
              dashStyle="dot"
            >
              <Label
                visible={true}
                backgroundColor="#949494"
              >
                <Font
                  color="#fff"
                  size={12}
                />
              </Label>
            </Crosshair>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="bottom"
              equalColumnWidth={true}
            />
            <Title text="Орлого, зарлага сургуулиар">
              <Font color="black" size={20} family={"Segoe UI"} weight={600} />
            </Title>
            <Export enabled={true} />
            <Tooltip enabled={true} valueField="schoolname" />
          </Chart>
        </div>
      </div>
      <div className="dashboardcolumn">
        <div style={{ backgroundColor: "#ffffff", border: '1px solid #C2C5C6', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <Chart
            id="chart"
            dataSource={dataProfit}
            palette="Ocean"
          >
            <Title text={"Орлого, эдийн засгийн ангиллаар"}>
              <Font color="black" size={20} family={"Segoe UI"} weight={600} />
            </Title>
            <CommonSeriesSettings argumentField="fullname"
              valueField="totalprice" type="stackedBar" />
            <ArgumentAxis>
              <Label
                wordWrap={'normal'}
                rotationAngle={20}
                overlappingBehavior={'rotate'}
              >
                <Font size={10} />
              </Label>
            </ArgumentAxis>
            <SeriesTemplate nameField="budgetyear" />
            <ValueAxis position="right">
              <Title text="Жилээр" />
            </ValueAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="top"
            />
            <Export enabled={true} />
            <Tooltip
              enabled={true}
              location="edge"
            />
          </Chart>
        </div>
      </div>
      <div className="dashboardcolumn">
        <div style={{ backgroundColor: "#ffffff", border: '1px solid #C2C5C6', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
          <Chart
            id="chart"
            dataSource={dataExpense}
            palette="Office"
          >
            <Title text={"Зарлага, эдийн засгийн ангиллаар"}>
              <Font color="black" size={20} family={"Segoe UI"} weight={600} />
            </Title>
            <CommonSeriesSettings argumentField="fullname" valueField="totalprice" type="stackedBar" />
            <ArgumentAxis>
              <Label
                wordWrap={'normal'}
                rotationAngle={20}
                overlappingBehavior={'rotate'}
              >
                <Font size={10} />
              </Label>
            </ArgumentAxis>
            <SeriesTemplate nameField="budgetyear" />

            <ValueAxis position="right">
              <Title text="Жилээр" />
            </ValueAxis>
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              itemTextPosition="top"
            />
            <Export enabled={true} />
            <Tooltip
              enabled={true}
              location="edge"
            />
          </Chart>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;