import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, message, Progress } from "antd";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { GridDefault, ColEdit, ButtonDefault } from "dw-components";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { BRANCHSCHOOLSKEYS } from "../../Const/BranchSchools";
import moment from "moment";
import { BUDGETSTATUS } from "../../Const/BudgetStatus";

const BudgetDetails = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { budgetId, budgetData } = state;

  const [sentRequests, setSentRequests] = useState({});
  const [checkingRequests, setCheckingRequests] = useState({});
  const [approvedRequests, setApprovedRequests] = useState({});

  const BudgetRequestRef = collection(db, "budgetRequests");

  useEffect(async () => {
    const BudgetReqSent = query(
      BudgetRequestRef,
      where("budgetId", "==", budgetId),
      where("status", "==", "SENT"),
      where("sentFrom", "==", "BRANCH")
    );

    const BudgetReqChecking = query(
      BudgetRequestRef,
      where("budgetId", "==", budgetId),
      where("status", "==", "CHECKING"),
      where("sentFrom", "==", "BRANCH")
    );

    const BudgetReqApproved = query(
      BudgetRequestRef,
      where("budgetId", "==", budgetId),
      where("status", "==", "APPROVED"),
      where("sentFrom", "==", "BRANCH")
    );

    const BudgetReqSentDocs = await getDocs(BudgetReqSent);
    const sentReqs = BudgetReqSentDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setSentRequests(sentReqs);

    const BudgetReqCheckingDocs = await getDocs(BudgetReqChecking);
    const checkingReqs = BudgetReqCheckingDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setCheckingRequests(checkingReqs);

    const BudgetReqApprovedDocs = await getDocs(BudgetReqApproved);
    const approvedReqs = BudgetReqApprovedDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setApprovedRequests(approvedReqs);
  }, []);

  const columns = [
    {
      name: "Салбар сургууль",
      fieldName: "branchSchool",
      minColSize: "large",
      onRender: (item) => {
        return (
          <ColEdit
            onClick={() =>
              onDetails({
                budgetRequestId: item.id,
                budgetData: budgetData,
              })
            }
          >
            <div>{BRANCHSCHOOLSKEYS[item.branchSchool]}</div>
          </ColEdit>
        );
      },
    },
    {
      name: "Илгээсэн огноо",
      fieldName: "sentDate",
      minColSize: "mid",
      onRender: (item) => {
        return moment(item.sentDate).format("YYYY.MM.DD hh:mm:ss").toString();
      },
    },
    {
      name: "Албан тушаалтны нэр",
      fieldName: "userId",
      minColSize: "large",
    },
    {
      name: "Түүх",
      fieldName: "closeDate",
      minColSize: "mid",
    },
  ];

  const closeBudget = async () => {
    const BudgetCollectionDoc = doc(db, "budgets", budgetId);
    await updateDoc(BudgetCollectionDoc, {
      status: "CLOSED",
    });
  };

  const openBudget = async () => {
    const BudgetCollectionDoc = doc(db, "budgets", budgetId);
    await updateDoc(BudgetCollectionDoc, {
      status: "OPENED",
    });
  };

  const onDetails = (data) => {
    navigate("/budget/confirmation", {
      state: {
        budgetId: data.budgetData.id,
        budgetData: data.budgetData,
        budgetRequestId: data.budgetRequestId,
      },
    });
  };

  const onClose = async () => {
    await closeBudget();
    navigate("/budget");
  };

  const onOpen = async () => {
    await openBudget();
    navigate("/budget");
  };

  return (
    <div style={{ paddingTop: 48 }}>
      <div style={{ margin: "16px 20px 16px" }}>
        <Row className="budget-header">
          <Col span={5}>
            <h2 className="budget-title">{budgetData.budgetName} Нэгтгэл</h2>
            <Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Нээсэн огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">{budgetData.openedDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Хаах огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value"> {budgetData.closeDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">
                    Албан тушаалтны нэр: &nbsp;
                  </h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetData.employeeName}
                  </h4>
                </Stack.Item>
              </Stack>
            </Stack>
          </Col>
          <Col span={2} style={{ marginTop: 36 }}>
            <h4>Төлөв</h4>
            <h4 className="budget-status">{BUDGETSTATUS[budgetData.status]}</h4>
          </Col>
          <Col span={15} style={{ marginTop: 36 }}>
            <h4>Нийт дүн</h4>
            <h4 className="budget-balance">0.00₮</h4>
          </Col>
          <Col
            span={1}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <h4>Гүйцэтгэл</h4>
            <h4 className="budget-balance">
              <Progress percent={20} showInfo={true} />
            </h4> */}
            <ButtonDefault
              text="Хаах"
              onClick={onClose}
              // disable={status.includes(budgetReq?.status) ? true : false}
            />
            <ButtonDefault
              text="Нээх"
              onClick={onOpen}
              // disable={status.includes(budgetReq?.status) ? true : false}
            />
          </Col>
          <Col span={11} style={{ marginTop: 36 }}></Col>
        </Row>
      </div>
      <Pivot
        defaultSelectedKey="0"
        styles={{
          linkIsSelected: {
            "&::before": {
              backgroundColor: "#0358A7",
              height: 3,
            },
          },
          link: {
            height: 48,
          },
          root: {
            height: 48,
            float: "left",
            paddingLeft: 16,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            textAlignLast: "justify",
          },
        }}
      >
        <PivotItem headerText="Шинэ">
          <div
            style={{ padding: "65px 20px 550px", backgroundColor: "#f7f7f7" }}
          >
            <h2 className="indicator-total">Нийт({sentRequests.length})</h2>
            <GridDefault columns={columns} data={sentRequests}></GridDefault>
          </div>
        </PivotItem>
        <PivotItem headerText="Хянаж буй">
          <div
            style={{ padding: "65px 20px 550px", backgroundColor: "#f7f7f7" }}
          >
            <h2 className="indicator-total">Нийт({checkingRequests.length})</h2>
            <GridDefault
              columns={columns}
              data={checkingRequests}
            ></GridDefault>
          </div>
        </PivotItem>
        <PivotItem headerText="Баталсан">
          <div
            style={{ padding: "65px 20px 550px", backgroundColor: "#f7f7f7" }}
          >
            <h2 className="indicator-total">Нийт({approvedRequests.length})</h2>
            <GridDefault
              columns={columns}
              data={approvedRequests}
            ></GridDefault>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default BudgetDetails;
