import { db } from "../Firebase/firebase-config";
import { updateDoc, doc } from "firebase/firestore";

// group data by groupName and flatten
const flattenData = async (itemList) => {
    // group by field
    const groupedItems = itemList.reduce((r, a) => {
        r[a.groupName] = r[a.groupName] || [];
        r[a.groupName].push(a);
        return r;
    }, Object.create(null));

    // flatten
    let flattenItemList = [];
    for (let item in groupedItems) {
        const values = groupedItems[item];
        values.map((r) => {
            flattenItemList.push(r);
        });
    }

    return flattenItemList;
};

// get groups data from flatten list
const groupsDataGenerator = async (itemsList, fieldName) => {
    const groupObjArr = [];
    const groupNames = [...new Set(itemsList.map((item) => item[fieldName]))];

    groupNames.forEach((gn) => {
        const groupLength = itemsList.filter((item) => item[fieldName] === gn).length;

        const groupIndex = itemsList.map((item) => item[fieldName]).indexOf(gn);

        groupObjArr.push({
            key: gn,
            name: gn,
            level: 0,
            count: groupLength,
            startIndex: groupIndex,
        });
    });

    return groupObjArr;
};

// generate tree data by group parents
const categoryTree = async (data, pField, chField) => {
    let parents = [];
    data.map((row) => {
        if (!row[pField]) {
            parents.push(row);
        }
    });

    const childCalc = async (parents, data) => {
        parents.map((row) => {
            let child = data.filter((r) => r[pField] == row[chField]);
            if (child || child.length > 0) {
                row.children = child;
                childCalc(child, data);
            }
        });

        return parents;
    };

    const treeData = await childCalc(parents, data);

    return treeData;
};

// const convertToAntdTree = async (data) => {
//   let newArray = [];
//   let child = [];
//   data.map(async(row) => {
//     if (row.children && row.children.length > 0) {
//       child = await convertToAntdTree(row.children);
//     }
//     newArray.push({
//       title: row.groupName,
//       key: row.groupName,
//       children: child,
//     });
//   });

//   return newArray;
// };

const groupListData = async (list) => {
    const groupedList = list.reduce((r, a) => {
        r[a.groupName] = r[a.groupName] || [];
        r[a.groupName].push(a);
        return r;
    }, Object.create(null));

    return groupedList;
};

// convert data to antd table
const convertToTableTree = async (categories, indicators) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];
            if (tempChild && tempChild.length > 0) {
                tempChild.map((r) => {
                    let temp = {
                        orderNo: r.orderNo,
                        name: r.indicatorName,
                        code: r.indicatorCode,
                        key: r.indicatorCode,
                        indicatorId: r.id,
                        isActive: r.isActive,
                        branchSchoolReq: r.branchSchoolReq,
                        departmentReq: r.departmentReq,
                        category: r.category,
                        data: { ...r },
                    };

                    if (!("branchSchoolReq" in r)) {
                        temp.branchSchoolReq = true;
                        temp.departmentReq = true;
                    }

                    child.push(temp);
                });
            }

            let response = await convertToTableTree(row.children, indicators);
            child = [...child, ...response];
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];
            if (tempChild && tempChild.length > 0) {
                tempChild.map((r) => {
                    let temp = {
                        orderNo: r.orderNo,
                        name: r.indicatorName,
                        code: r.indicatorCode,
                        key: r.indicatorCode,
                        indicatorId: r.id,
                        isActive: r.isActive,
                        branchSchoolReq: r.branchSchoolReq,
                        departmentReq: r.departmentReq,
                        category: r.category,
                        data: { ...r },
                    };

                    if (!("branchSchoolReq" in r)) {
                        temp.branchSchoolReq = true;
                        temp.departmentReq = true;
                    }

                    child.push(temp);
                });
            }
        }

        newArray.push({
            orderNo: row.orderNo,
            name: row.groupName,
            code: row.groupCode,
            key: row.groupCode,
            children: child,
            category: row.indicatorCategory,
        });
    });

    return newArray;
};

const convertToTableTreeList = async (categories, indicators) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];

            if (tempChild && tempChild.length > 0) {
                tempChild.map((row) => {
                    child.push({
                        orderNo: row.orderNo,
                        name: row.indicatorName,
                        code: row.indicatorCode,
                        key: row.indicatorCode,
                        indicatorId: row.id,
                        calculateMethod: row.calculateMethod,
                        branchSchool: row.branchSchool,
                        getFeedback: row.getFeedback,
                        isActive: row.isActive,
                    });
                });
            }
            let response = await convertToTableTreeList(row.children, indicators);
            child = [...child, ...response];
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];

            if (tempChild && tempChild.length > 0) {
                tempChild.map((row) => {
                    child.push({
                        orderNo: row.orderNo,
                        name: row.indicatorName,
                        code: row.indicatorCode,
                        key: row.indicatorCode,
                        indicatorId: row.id,
                        calculateMethod: row.calculateMethod,
                        branchSchool: row.branchSchool,
                        getFeedback: row.getFeedback,
                        isActive: row.isActive,
                    });
                });
            }
        }
        newArray.push({
            orderNo: row.orderNo,
            name: row.groupName,
            code: row.groupCode,
            key: row.groupCode,
            groupId: row.id,
            children: child,
        });
    });

    return newArray;
};

const getFilterData = async (categories, indicators) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            child = await getFilterData(row.children, indicators);
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];

            if (tempChild && tempChild.length > 0) {
                tempChild.map((row) => {
                    child.push({
                        text: row.indicatorName,
                        value: row.indicatorName,
                    });
                });
            }
        }
        newArray.push({
            text: row.groupName,
            value: row.groupName,
        });
    });

    return newArray;
};

const getOthersDeparmentData = async (categories, indicators, branchSchool, department) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            child = await getOthersDeparmentData(row.children, indicators);
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];
            if (tempChild && tempChild.length > 0) {
                tempChild.map((row) => {
                    if (!row.values[branchSchool]) row.values[branchSchool] = {};
                    child.push({
                        name: row.indicatorName,
                        code: row.id,
                        key: row.id,
                        indicatorId: row.id,
                        value: row.values[branchSchool][department] || 0,
                    });
                });
            }
        }
        newArray.push({
            name: row.groupName,
            code: row.groupCode,
            key: row.groupCode,
            children: child,
        });
    });

    return newArray;
};

const getOthersBranchData = async (categories, indicators, branchSchool) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            child = await getOthersBranchData(row.children, indicators);
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];

            if (tempChild && tempChild.length > 0) {
                tempChild.map((row) => {
                    if (!row.values[branchSchool]) row.values[branchSchool] = {};
                    child.push({
                        name: row.indicatorName,
                        code: row.id,
                        key: row.id,
                        indicatorId: row.id,
                        values: row.values[branchSchool] || 0,
                    });
                });
            }
        }
        newArray.push({
            name: row.groupName,
            code: row.groupName,
            key: row.groupName,
            children: child,
        });
    });

    return newArray;
};

const setRowStatus = async (listData, id, field) => {
    for (let item of listData) {
        if (item.children && item.children.length > 0) {
            for (let childItem of item.children) {
                if (childItem.indicatorId == id) {
                    switch (childItem[field]) {
                        case true:
                            childItem[field] = false;
                            if (field == "branchSchoolReq") childItem.departmentReq = false;
                            if (field == "isActive") {
                                childItem.departmentReq = false;
                                childItem.branchSchoolReq = false;
                            }

                            break;
                        case false:
                            childItem[field] = true;
                            if (field == "branchSchoolReq") childItem.departmentReq = true;
                            if (field == "isActive") {
                                childItem.departmentReq = true;
                                childItem.branchSchoolReq = true;
                            }
                            break;
                    }

                    const BudgetReqDoc = doc(db, "budgetIndicators", id);
                    await updateDoc(BudgetReqDoc, {
                        isActive: childItem.isActive,
                        departmentReq: childItem.departmentReq,
                        branchSchoolReq: childItem.branchSchoolReq,
                    });

                    break;
                } else {
                    if (childItem.children && childItem.children.length > 0) {
                        await setRowStatus(childItem.children, id, field);
                    }
                }
            }
        } else {
            if (item.indicatorId == id) {
                switch (item[field]) {
                    case true:
                        item[field] = false;
                        if (field == "branchSchoolReq") item.departmentReq = false;
                        if (field == "isActive") {
                            item.departmentReq = false;
                            item.branchSchoolReq = false;
                        }
                        break;
                    case false:
                        item[field] = true;
                        if (field == "branchSchoolReq") item.departmentReq = true;
                        if (field == "isActive") {
                            item.departmentReq = true;
                            item.branchSchoolReq = true;
                        }
                        break;
                }

                const BudgetReqDoc = doc(db, "budgetIndicators", id);
                await updateDoc(BudgetReqDoc, {
                    isActive: item.isActive,
                    departmentReq: item.departmentReq,
                    branchSchoolReq: item.branchSchoolReq,
                });

                break;
            }
        }
    }

    return listData;
};

const numberFormat = async (number) => {
    let mntFormatter = Intl.NumberFormat("mn-MN", {
        useGrouping: true,
        minimumFractionDigits: 2,
    });

    return mntFormatter.format(number);
};

const methodDataToListOld = async (listData, methods) => {
    for (let item of listData) {
        if (item.children && item.children.length > 0) {
            for (let childItem of item.children) {
                if (childItem.children && childItem.children.length > 0) {
                    await methodDataToListOld(childItem.children, methods);
                } else {
                    childItem.children = [];
                    for (const method in methods) {
                        if (method == childItem["indicatorId"]) {
                            for (const item in methods[method]) {
                                let itemData = methods[method][item];
                                childItem.children.push({
                                    name: itemData.department,
                                    code: itemData.department,
                                    key: itemData.id,
                                    methodId: itemData.id,
                                    indicatorId: itemData.indicatorId,
                                    indicatorData: childItem.data,
                                });
                            }
                        }
                    }
                }
            }
        } else {
            item.children = [];
            for (const method in methods) {
                if (method == item["indicatorId"]) {
                    for (const i in methods[method]) {
                        let itemData = methods[method][i];
                        item.children.push({
                            name: itemData.department,
                            code: itemData.department,
                            key: itemData.id,
                            methodId: itemData.id,
                            indicatorId: itemData.indicatorId,
                            indicatorData: item.data,
                        });
                    }
                }
            }
        }
    }
    return listData;
};

const methodDataToList = async (listData, methods) => {
    for (let item of listData) {
        if (item.children && item.children.length > 0) {
            for (let childItem of item.children) {
                if (childItem.children && childItem.children.length > 0) {
                    await methodDataToList(childItem.children, methods);
                } else {
                    childItem.departmentMethods = [];
                    for (const method in methods) {
                        if (method == childItem["indicatorId"]) {
                            for (const item in methods[method]) {
                                let itemData = methods[method][item];

                                childItem.departmentMethods.push({
                                    name: itemData.department,
                                    code: itemData.department,
                                    key: itemData.id,
                                    methodId: itemData.id,
                                    indicatorId: itemData.indicatorId,
                                    indicatorData: childItem.data,
                                });
                            }
                        }
                    }
                }
            }
        } else {
            item.departmentMethods = [];
            for (const method in methods) {
                if (method == item["indicatorId"]) {
                    for (const i in methods[method]) {
                        let itemData = methods[method][i];
                        item.departmentMethods.push({
                            name: itemData.department,
                            code: itemData.department,
                            key: itemData.id,
                            methodId: itemData.id,
                            indicatorId: itemData.indicatorId,
                            indicatorData: item.data,
                        });
                    }
                }
            }
        }
    }

    return listData;
};

const getOthersData = async (categories, indicators) => {
    let newArray = [];
    categories.map(async (row) => {
        let child = [];
        if (row.children && row.children.length > 0) {
            child = await getOthersBranchData(row.children, indicators);
        } else {
            let tempChild = indicators[row.groupName] ? indicators[row.groupName] : [];

            if (tempChild && tempChild.length > 0) {
                tempChild.map((r) => {
                    child.push({
                        name: r.indicatorName,
                        code: r.id,
                        key: r.id,
                        indicatorId: r.id,
                        ...r,
                    });
                });
            }
        }
        newArray.push({
            name: row.groupName,
            code: row.groupName,
            key: row.groupName,
            children: child,
        });
    });

    return newArray;
};

let indicators = [];
const getIndicators = async (data) => {
    data.map(async (row) => {
        if (row.children) {
            await getIndicators(row.children);
        } else {
            indicators.push(row);
        }
    });
    return indicators;
};

const getParentCategories = async (categories) => {
    let parents = [];
    categories.map((item) => {
        parents.push(item.groupCode);
    });

    return parents;
};

const getAssetParents = async (parentCats, assets) => {
    if (assets.length > 0) {
        assets.map((ch) => {
            parentCats.map(async (p) => {
                if (ch.mainCategory && ch.mainCategory == p.categoryName) {
                    p.children.push({
                        categoryName: ch.categoryName,
                        code: ch.id,
                        key: ch.id,
                        marketPrice: ch.marketPrice,
                        children: [],
                    });
                    assets = assets.filter((i) => {
                        return i !== ch;
                    });
                }
            });
        });
    }

    return { parents: parentCats, childs: assets };
};

const convertAssetsToTree = async (parentCats, assets) => {
    parentCats.map(async (p) => {
        if (p.children && p.children.length == 0) {
            assets.map((ch) => {
                if (p.code == ch.parentCategory) {
                    p.children.push({
                        categoryName: ch.categoryName,
                        code: ch.id,
                        key: ch.id,
                        marketPrice: ch.marketPrice,
                        children: [],
                    });

                    assets = assets.filter((item) => {
                        return item !== ch;
                    });
                }
            });
            if (assets.length > 0 && p.children.length > 0) {
                await convertAssetsToTree(p.children, assets);
            }
        } else {
            if (assets.length > 0 && p.children.length > 0) {
                await convertAssetsToTree(p.children, assets);
            }
        }
    });

    return parentCats;
};

const convertSuggestionToTree = async (parentCats, assets) => {
    parentCats.map(async (p) => {
        if (p.children && p.children.length == 0) {
            assets.map((ch) => {
                if (p.parentAsset == ch.asset) {
                    p.children.push({
                        indicatorName: ch.assets,
                        ...ch,
                        code: ch.id,
                        key: ch.id,
                    });

                    assets = assets.filter((item) => {
                        return item !== ch;
                    });
                }
            });
        }
    });

    return parentCats;
};

export {
    flattenData,
    groupsDataGenerator,
    categoryTree,
    convertToTableTree,
    groupListData,
    convertToTableTreeList,
    setRowStatus,
    numberFormat,
    methodDataToList,
    methodDataToListOld,
    getFilterData,
    getOthersBranchData,
    getOthersData,
    getIndicators,
    getParentCategories,
    getAssetParents,
    convertAssetsToTree,
    getOthersDeparmentData,
    convertSuggestionToTree,
};
