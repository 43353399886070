import { message } from 'antd';
import { ButtonDefault, FieldCheck, FieldDropdown, FieldNumber, FieldText, PanelBase, LoadSpin } from 'dw-components';
import React, { useEffect, useState } from 'react';
import { APIGraphQL } from '../../../API/APIGraphQL';
import CALCULATEMETHODS from '../../../Const/CalculateMethods';
import { Coverage, Level } from '../../../Const/Coverage';
import ALLOCATIONTYPECONST from '../../../Const/AllocationTypeConst';
import FieldComboBoxMultiSelectAll from "../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";

const NewCat = (props) => {

    const [initData] = useState({
        iSACTIVE: true,
        cOVERAGEID: Coverage[0].key,
        cOVERAGENAME: Coverage[0].text
    });
    const [data, setData] = useState({ ...initData });
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dECat, setDECat] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [dSchools, setDBSchools] = useState([]);
    const [dLevels, setLevels] = useState(Level);

    const getData = async () => {
        setLoadingData(true);

        let tDECat = [];
        await APIGraphQL.post("", {
            query: `{
                    sharedQuery {
                      parenteconomycat {
                        key: iD
                        text: fULLNAME
                      }
                    }
                  }
                  `
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.parenteconomycat;
            if (tResp?.length > 0) {
                tDECat = tResp;
            }
        }).catch(response => {
            setLoadingData(false);
        });
        setDECat(tDECat);

        let tData = { ...initData };
        let tSchools = [];
        if (props?.id) {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                      economycategory(id: $id) {
                        iD
                        pARENTID
                        cODE
                        nAME
                        fULLNAME
                        oRDERBY
                        cALCTYPEID
                        cALCTYPENAME
                        iSACTIVE
                        iSCHILD
                        tYPEID
                        tYPENAME
                        cOVERAGEID
                        cOVERAGENAME
                        sCHOOLIDS
                        lEVELID
                        lEVELNAME
                        aLLOCATETYPE
                        aLLOCATETYPEID
                      }

                      schoolAll {
                        key: iD
                        text: nAME
                      }
                    }
                  }
                  `,
                variables: {
                    "id": parseInt(props?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery;
                if (tResp?.economycategory?.iD) {
                    tData = tResp?.economycategory;
                    tData.sCHOOLIDS = tData?.sCHOOLIDS?.split(',').map(Number);
                }

                if (tResp?.schoolAll?.length > 0) {
                    tSchools = tResp?.schoolAll;
                }
            }).catch(response => {
                setLoadingData(false);
            });
        }
        setData(tData);
        setDBSchools(tSchools);
        setLoadingData(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const onDismiss = () => {
        setData({ ...initData });
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const fieldOnChanged = (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            if(fieldname == "cOVERAGENAME")
            {
                if(key == 1)
                {
                    tData.lEVELID = 1;
                    tData.lEVELNAME = "Тэнхим";
                }
                if(key == 2)
                    setLevels(Level.filter(item => item.key !== 3));
                else
                    setLevels(Level);
            }
            setData(tData);
        }
    }

    const onSave = async () => {
        let vRule = true;
        setCheckRule(true);

        let tData = { ...data };

        if (props.type == 1) {
            if (!tData?.cODE
                || !tData?.nAME
                || !tData?.oRDERBY) {
                vRule = false;
            }
        } else {
            if (!tData?.pARENTID
                || !tData?.nAME
                || !tData?.cALCTYPEID
                || !tData?.oRDERBY) {
                vRule = false;
            }
        }

        if (vRule) {
            let tSuccess = false;

            tData.oRDERBY = parseInt(tData?.oRDERBY);
            tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
            tData.fULLNAME = tData?.cODE ? (tData?.cODE + " - " + tData?.nAME) : tData?.nAME;
            tData.lEVELID = !tData.lEVELID ? 1 : tData.lEVELID
            if (!props?.id) {
                tData.iD = 0;
                if (props.type == 1) {
                    tData.pARENTID = tData.pARENTID ? parseInt(tData.pARENTID) : 0;
                    tData.iSCHILD = 0;
                    tData.iSACTIVE = 1;
                    tData.cALCTYPEID = null;
                    tData.cALCTYPENAME = null;
                    tData.tYPEID = null;
                    tData.tYPENAME = null;
                } else {
                    tData.iSCHILD = 1;
                }
            }

            if(tData.sCHOOLIDS)
                tData.sCHOOLIDS = tData.sCHOOLIDS.filter(e => e !== 'selectAll').toString();

            if (props.type == 2) {
                if (tData.cALCTYPEID < 50) {
                    tData.tYPEID = 1;
                    tData.tYPENAME = "Орлогын үзүүлэлт";
                } else {
                    tData.tYPEID = 2;
                    tData.tYPENAME = "Зарлагын үзүүлэлт";
                }
            }

            setLoading(true);
            await APIGraphQL.post("", {
                query: `mutation($data: EconomyCategoryInputType) {
                    sharedMutation {
                      addEconomyCategory (data: $data) {
                        iD
                      }
                    }
                  }`,
                variables: {
                    "data": tData
                }
            }).then(response => {
                setLoading(false);
                if (response?.data?.data?.sharedMutation?.addEconomyCategory?.iD) {
                    tSuccess = true;
                }

            }).catch(response => {
                setLoading(false);
            });

            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    }

    return (
        <PanelBase
            headerText={props.type == 1 ? "Ангилал" : "Үзүүлэлт"}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="medium"
            buttons={loading
                ? <LoadSpin />
                : <ButtonDefault
                    text={"Хадгалах"}
                    onClick={onSave}
                />
            }
        >
            <div>
                <div style={{ marginTop: 25 }}>
                    <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Ангилал" keyfield="pARENTID" defaultValue={data.pARENTID} checkreq={checkRule} data={dECat} /> </div>
                <div style={{ marginTop: 13 }}>
                    <FieldText loading={loadingData} required={props?.type == 2 ? false : true} onChanged={fieldOnChanged} title="Код" keyfield="cODE" defaultValue={data.cODE} checkreq={checkRule} /> </div>
                <div style={{ marginTop: 13 }}>
                    <FieldText loading={loadingData} required onChanged={fieldOnChanged} title="Нэр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} /> </div>
                {props.type == 2 && (
                    <div>
                        <div style={{ marginTop: 13 }}>
                            <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Тооцох аргачлал" keyfield="cALCTYPEID" keyfieldname="cALCTYPENAME" defaultValue={data.cALCTYPEID} checkreq={checkRule} data={CALCULATEMETHODS} />
                        </div>
                        <div style={{ marginTop: 13 }}>
                            <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Хуваарилах аргачлал" keyfield="aLLOCATETYPEID" keyfieldname="aLLOCATETYPE" defaultValue={data.aLLOCATETYPEID} checkreq={checkRule} data={ALLOCATIONTYPECONST} />
                        </div>
                        <div style={{ marginTop: 13 }}>
                            <FieldDropdown loading={loadingData} onChanged={fieldOnChanged} title="Хамрах хүрээ" keyfield="cOVERAGEID" keyfieldname="cOVERAGENAME" defaultValue={data.cOVERAGEID} data={Coverage} />
                        </div>
                        <div style={{ marginTop: 13 }}>
                            <FieldDropdown disable={data.cOVERAGEID == 1} loading={loadingData} onChanged={fieldOnChanged} title="Түвшин" keyfield="lEVELID" keyfieldname="lEVELNAME" defaultValue={!data?.lEVELID ? 1 : data?.lEVELID} data={dLevels} />
                        </div>
                    {(data?.lEVELID != 3 &&
                        <div style={{ marginTop: 13 }}>
                        <FieldComboBoxMultiSelectAll required loading={loading} onChanged={fieldOnChanged} title="Үзүүлэлт харуулах сургууль, нэгж" keyfield="sCHOOLIDS" defaultValue={data.sCHOOLIDS} data={dSchools} />
                        </div>
                    )}
                    <div style={{ marginTop: 13 }}>
                            <FieldCheck loading={loadingData} required onChanged={fieldOnChanged} title="Идэвхитэй эсэх" keyfield="iSACTIVE" defaultValue={data.iSACTIVE} checkreq={checkRule} />
                    </div>
                </div>
                )}
                <div style={{ marginTop: 13 }}>
                    <FieldNumber loading={loadingData} required onChanged={fieldOnChanged} title="Дарааллын дугаар" keyfield="oRDERBY" defaultValue={data.oRDERBY} checkreq={checkRule} /> </div>
            </div>
        </PanelBase>
    );
}

export default NewCat;