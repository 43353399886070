import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, LoadSpin, FieldNumberFormat } from "dw-components"; 
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { BuildingWindowDesign } from "../../../../Const/BuildingWindowDesign";
import moment from "moment";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";

const BuildingPanel = (props) => {
  const [data, setData] = useState({});
  const [dBuilding, setBuilding] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [roomUsages, setRoomUsages] = useState([]);
  const [mainSchools, setMainSchools] = useState([]);
  const [schools, setSchools] = useState([]);
  const [bWindowDesign, setWindowDesign] = useState(BuildingWindowDesign);
  const { userInfo } = useContext(UserContext);

  const getData = async () => {

    let tData = [];
    let tDBuilding = [];
    let tDRoomUsages = [];
    let tMainSchools = [];
    let tSchools = [];    
    let typeid = props?.typeid == 'class' ? 1 : 2;

    await APIGraphQL.post("", {
      query: `query ($id: Int, $typeid: Int) {
        sharedQuery 
        {
            getBuildingDetail(id: $id) {
            iD
            bUILDINGID
            rOOMCODE
            mAINSCHOOLID
            mAINSCHOOLNAME
            sCHOOLID
            sCHOOLNAME
            rOOMUSAGEID
            rOOMUSAGENAME
            sQUARESIZE
            uSEFULSQUARE
            pUBLICSQUARE
            lENGTH
            hEIGTH
            wIDTH
            vOLUME
            sEATQTY
            sHOWERQTY
            bEDQTY
            wINDOWQTY
            wINDOWDESIGNID
            wINDOWDESIGNNAME
            tOILETSEATQTY
            uRINALQTY
            sINKQTY
            cREATED
            cREATEDBY
        }   
      
        getBuildingList(typeid: $typeid) {
          key: iD
          text: nAME
        }

        parentschooldep {
          key: iD
          text: nAME
        }

        schooldeps {
          key: iD
          text: nAME
        }

        getRoomUsageList {
          key: iD
          text: nAME
        }
      }
    }`,
      variables: {
        "id": parseInt(props?.id),
        "typeid": typeid,
      },
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getBuildingDetail;
      if (tResp?.iD) {
        tData = tResp;
      }

      let tRespBuilding = response?.data?.data?.sharedQuery?.getBuildingList;
      if (tRespBuilding?.length > 0) {
        tDBuilding = tRespBuilding;
      }

      let tRespMain = response?.data?.data?.sharedQuery?.parentschooldep;
      if (tRespMain?.length > 0) {
        tMainSchools = tRespMain;
      }

      let tRespSchool = response?.data?.data?.sharedQuery?.schooldeps;
      if (tRespSchool?.length > 0) {
        tSchools = tRespSchool;
      }
      let tRespRoom = response?.data?.data?.sharedQuery?.getRoomUsageList;
      if (tRespRoom?.length > 0) {
        tDRoomUsages = tRespRoom;
      }
    }).catch(() => {
      setLoading(false);
    });

    setBuilding(tDBuilding);
    setMainSchools(tMainSchools);
    setSchools(tSchools);
    setData(tData);
    setRoomUsages(tDRoomUsages);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id, props?.typeid]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  };
  const onSave = async () => {
    let isValid = true;
    let tData = { ...data }
    if (!tData?.bUILDINGID ||
      !tData?.rOOMCODE ||
      !tData?.sCHOOLID
    ) { isValid = false };
    if (isValid) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      tData.vOLUME = tData.lENGTH * tData.wIDTH * tData.hEIGTH;
      delete tData["bUILDINGNAME"];
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: BuildingDetailInputType) {
          sharedMutation {
            addBuildingDetail(data: $data) {
                        iD
                    }
                }
            }`,
        variables: {
          "data": tData,
        },
      }).then((response) => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addBuildingDetail?.iD) {
          tSuccess = true;
        }
      }).catch(() => {
        setLoadingSave(false);
      })
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  };

  const onDismiss = () => {
    setData({});
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation DeleteBuildingDetail($id: Int) {
      sharedMutation {
        deleteBuildingDetail(id: $id)
      }
    }`,
      variables: {
        "id": parseInt(props.id),
      },
    }).then((response) => {
      if (response?.data?.data?.sharedMutation?.deleteBuildingDetail == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай");
        onDismiss();
      }
    });
  };

  return (
    <PanelBase
      headerText={props.typeid == 1 ? "Хичээлийн байр нэмэх" : "Дотуур байр нэмэх"}
      isOpen={props.isOpen}
      PanelType="medium"
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
    >
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          loading={loading}
          required title="Байр"
          keyfield="bUILDINGID"
          keyfieldname="bUILDINGNAME"
          onChanged={fieldOnChanged}
          defaultValue={data.bUILDINGID}
          data={dBuilding} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldText
          onChanged={fieldOnChanged}
          required title="Өрөөний дугаар, код"
          keyfield="rOOMCODE"
          keyfieldname="rOOMCODE"
          defaultValue={data.rOOMCODE}
        />
      </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Харъяаллын үндсэн нэгж"
            keyfield="mAINSCHOOLID"
            keyfieldname="mAINSCHOOLNAME"
            defaultValue={data.mAINSCHOOLID}
            data={mainSchools}
          />
        </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          required
          onChanged={fieldOnChanged}
          title="Харъяалагдах нэгж"
          keyfield="sCHOOLID"
          keyfieldname="sCHOOLNAME"
          defaultValue={data.sCHOOLID}
          data={schools}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          required
          onChanged={fieldOnChanged}
          title="Өрөөний зориулалт"
          keyfield="rOOMUSAGEID"
          keyfieldname="rOOMUSAGENAME"
          defaultValue={data.rOOMUSAGEID}
          data={roomUsages}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Талбай хэмжээ"
          keyfield="sQUARESIZE"
          defaultValue={data.sQUARESIZE}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Ашигтай талбай"
          keyfield="uSEFULSQUARE"
          defaultValue={data.uSEFULSQUARE}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Нийтийн эзэмшлийн талбай"
          keyfield="pUBLICSQUARE"
          defaultValue={data.pUBLICSQUARE}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Урт"
          keyfield="lENGTH"
          defaultValue={data.lENGTH}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Өндөр"
          keyfield="hEIGTH"
          defaultValue={data.hEIGTH}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Өргөн"
          keyfield="wIDTH"
          defaultValue={data.wIDTH}
        />
      </div>
        {props.typeid == 1 ?
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Суудлын тоо"
          keyfield="sEATQTY"
          defaultValue={data.sEATQTY}
        />
      </div> : null}
        {props.typeid == 2 ?
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Орны тоо"
              keyfield="bEDQTY"
              defaultValue={data.bEDQTY}
            />
          </div> : null}
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Цонхны тоо"
          keyfield="wINDOWQTY"
          defaultValue={data.wINDOWQTY}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          required
          onChanged={fieldOnChanged}
          title="Цонхны хийцлэл"
          keyfield="wINDOWDESIGNID"
          keyfieldname="wINDOWDESIGNNAME"
          defaultValue={data.wINDOWDESIGNID}
          data={bWindowDesign}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Суултуурын тоо"
          keyfield="tOILETSEATQTY"
          defaultValue={data.tOILETSEATQTY}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Шээлтүүрийн тоо"
          keyfield="uRINALQTY"
          defaultValue={data.uRINALQTY}
        />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat
          required
          onChanged={fieldOnChanged}
          title="Угаалтуурын тоо"
          keyfield="sINKQTY"
          defaultValue={data.sINKQTY}
        />
        {props.typeid == 2 ?
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Шүршүүрийн тоо"
              keyfield="sHOWERQTY"
              defaultValue={data.sHOWERQTY}
            />
          </div> : null}
      </div>
    </PanelBase>
  );
};

export default BuildingPanel;
