import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldCheck, FieldNumber, LoadSpin } from "dw-components";
import { message } from "antd";
import { APIGraphQL } from "../../../API/APIGraphQL";
import moment from "moment";
import { UserContext } from "../../../Context/UserContext";

const NewDocumentCategory = (props) => {
   const [data, setData] = useState({});
   const { userInfo } = useContext(UserContext);
   const [checkRule, setCheckRule] = useState(false);
   const [loading, setLoading] = useState(false);
   const [loadingSave, setLoadingSave] = useState(false);

   const getData = async () => {
      let tData = {};
      if (props?.id) {
         setLoading(true);
         await APIGraphQL.post("", {
            query: `query ($id: Int) {
               sharedQuery {
                  getDocumentCategory(id: $id) {
                     iD
                     nAME
                     iSACTIVE
                     cREATED
                     cREATEDBY
                  }
               }
            }`,
            variables: {
               "id": parseInt(props?.id),
            },
         }).then((response) => {
            let tResp = response?.data?.data?.sharedQuery?.getDocumentCategory;
            if (tResp?.iD) {
               tData = tResp;
            }
         }).catch(() => {
            setLoading(false);
         });
      }
      setData(tData);
      setLoading(false);
   }

   useEffect(() => {
      getData();
   }, [props?.id]);

   const fieldOnChanged = async (key, text, field, fieldname) => {
      if (field) {
         let tData = { ...data };
         tData[field] = key;
         if (fieldname) {
            tData[fieldname] = text;
         }
         setData(tData);
      }
   };

   const onSave = async () => {
      let isValid = true;
      setCheckRule(true);

      let tData = { ...data } 
      if (!tData?.nAME) { isValid = false; }

      if (isValid) {
         let tSuccess = false;

         if (!props.id) {
            tData.iD = 0;
            tData.iSACTIVE = 1;
            tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
            tData.cREATEDBY = userInfo?.username;
         }
         tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
         setLoadingSave(true);
         await APIGraphQL.post("", {
            query: `mutation ($documentCategory: DocumentCategoryInputType) {
               sharedMutation {
                  addDocumentCategory(documentCategory: $documentCategory) {
                     iD
                  }
               }
            }`,
            variables: {
               "documentCategory": tData,
            },
         }).then((response) => {
            setLoadingSave(false);
            if (response?.data?.data?.sharedMutation?.addDocumentCategory?.iD) {
               tSuccess = true;
            }
         }).catch(() => {
            setLoadingSave(false);
         })
         if (tSuccess) {
            message.success("Амжилттай");
            onDismiss();
            if (props.afterSave) {
               await props.afterSave();
            }
         }
      }
   };

   const onDelete = async () => {
      let tSuccess = false;
      await APIGraphQL.post("", {
         query: `mutation deleteDocumentCategory($id: Int) {
         sharedMutation {
            deleteDocumentCategory(id: $id)
         }
      }`,
         variables: {
            "id": parseInt(props.id),
         },
      }).then((response) => {
         if (response?.data?.data?.sharedMutation?.deleteDocumentCategory == 1) {
            tSuccess = true;
            if (props.afterSave) {
               props.afterSave();
            }
         }
         if (tSuccess) {
            message.success("Амжилттай");
            onDismiss();
         }
      });
   };

   const onDismiss = () => {
      setData({});
      setCheckRule(false);
      if (props.onDismiss) {
         props.onDismiss();
      }
   };

   return (
      <PanelBase
         headerText={props?.id ? "Засах" : "Нэмэх"}
         isOpen={props.isOpen}
         onDismiss={props.onDismiss}
         PanelType="medium"
         delete={props.id > 0 ? true : false}
         onDelete={onDelete}
         buttons={loadingSave ? <LoadSpin /> :
            <ButtonDefault text={props.id ? "Засах" : "Хадгалах"} onClick={onSave} />
         }
      >
         <div>
            <div style={{ marginTop: 25 }}>
               <FieldText required loading={loading} onChanged={fieldOnChanged} title="Баримт бичгийн ангиллын нэр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} />
            </div>
            <div style={{ marginTop: 13 }}>
               <FieldCheck loading={loading} required onChanged={fieldOnChanged} title="Идэвхтэй эсэх" keyfield="iSACTIVE" defaultValue={data.iSACTIVE == 0 ? data.iSACTIVE : 1} />
            </div>
         </div>
      </PanelBase>
   );
};

export default NewDocumentCategory;
