import React from "react";
import { ROLES } from "../../Const/Roles";

export const CheckPermission = (action, rules = undefined) => {
  const userRole = {};

  if (action === undefined) return true;

  if (rules === undefined) {
    rules = ROLES[userRole?.role];
  }

  const permissions = rules;
  if (!permissions) {
    return false;
  }

  if (
    permissions &&
    ((action.length > 6 && permissions.some((e) => e.permission === action)) ||
      (action.length <= 6 &&
        permissions.some((e) => e.permission.startsWith(action))))
  ) {
    return true;
  }

  return false;
};

function Can(props) {
  return CheckPermission(props.perform, props.userRole)
    ? props.yes()
    : props.no();
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
