import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Space, message, Popconfirm, Steps, Modal } from "antd";
import { ButtonDefault, ButtonSecondary, LoadSpin, LoadScreen, Warning, FieldDropdownMulti } from "dw-components";
import { Button } from 'devextreme-react/button';
import Reports from "./Reports/Reports";
import { APIGraphQL } from "../../API/APIGraphQL";
import { API } from "../../API/API";
import BudgetDtl from "./BudgetDtl/BudgetDtl";
import Suggestion from "./Suggestion/Suggestion";
import BudgetDtlIndicator from "../BudgetDtlIndicator/BudgetDtlIndicator";
import IndicatorChart from '../../svg/stepIcons/IndicatorChart';
import IndicatorIncrease from '../../svg/stepIcons/IndicatorIncrease';
import IndicatorDecrease from '../../svg/stepIcons/IndicatorDecrease';
import IndicatorSum from '../../svg/stepIcons/IndicatorSum';
import IndicatorSuggestion from '../../svg/stepIcons/IndicatorSuggestion';
import IndicatorReport from '../../svg/stepIcons/IndicatorReport';
import { ReactComponent as History } from '../../svg/history.svg';
import { UserContext } from "../../Context/UserContext";
import BudgetDtlIndicatorLog from "../BudgetDtlIndicator/BudgetDtlIndicatorLog";

const BudgetConfirm = (props) => {

    const navigate = useNavigate();
    const params = useParams();

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState({});
    const [infoData, setInfoData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editDtl, setEditDtl] = useState(false);
    const [editPercent, setEditPercent] = useState(false);
    const [editPercentAmount, setEditPercentAmount] = useState(0);
    const [editPercentSchool, setEditPercentSchool] = useState(1);
    const [budgetDtlSchool, setBudgetDtlSchool] = useState([]);

    const [checkRule, setCheckRule] = useState(false);
    const { Step } = Steps;
    const [current, setCurrent] = useState(parseInt(params?.type) || 0);

    const [showReturn, setShowReturn] = useState(false);
    const [schStatus, setSchStatus] = useState([]);
    const [retSSch, setRetSSch] = useState([]);
    const [showHistory, setShowHistory] = useState(false);

    const fieldOnChanged = (key, text, field, fieldname) => {
        if (field) {
            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
            setEdit(true);
        }
    }

    const getData = async () => {

        let tData = {};
        let tInfoData = {};

        if (params?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    budgetQuery {
                      budget(id: $id) {
                        iD
                        sTATUSID
                        sTATUSNAME
                        cASEID
                        cASENAME
                        sTARTDATE
                        yEAR
                        sTARTDATE
                        eNDDATE
                        rATE
                        oWNERNAME
                        cREATED
                        cREATEDBY
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(params?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.budgetQuery?.budget;
                if (tResp?.iD) {
                    tData = tResp;
                }
            }).catch(response => {
                setLoading(false);
            });

            await API.get("/api/budget/budgetTotal?budgetid=" + params?.id + "&datatype=" + userInfo?.schooltypeid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    if (response?.data?.retData?.length > 0) {
                        tInfoData = response?.data?.retData[0];
                    }
                }
            }).catch(() => {
                setLoading(false);
            });

            setLoading(false);
        }
        setInfoData(tInfoData);
        setData(tData);
        setEdit(false);
        setEditDtl(false);
        setEditPercent(false);
    }

    useEffect(async () => {
        getData();
        if (params?.type) {
            setCurrent(parseInt(params?.type) || 0);
        }
    }, [params?.id, params?.type]);

    const deleteBudget = async () => {
        if (params?.id) {
            setLoading(true);
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBudget(id: $id)
                    }
                  }
                  `,
                variables: {
                    "id": parseInt(params?.id)
                }
            }).then(respnose => {
                setLoading(false);
                message.success("Амжилттай");
                navigate("/budget");

            }).catch(() => {
                setLoading(false);
            });
        }
    }

    const onSave = async (pStatusId, pStatusName) => {

        setCheckRule(true);

        let isValid = true;

        let tData = { ...data };

        if (!tData.sTARTDATE
            || !tData.eNDDATE
            || !tData.oWNERNAME) {
            isValid = false;
        }

        if (isValid) {

            tData.sTATUSID = pStatusId;
            tData.sTATUSNAME = pStatusName;

            setLoading(true);
            await APIGraphQL.post("", {
                query: `mutation ($data: BudgetInputType) {
                  budgetMutation {
                    addBudget(data: $data) {
                      iD
                    }
                  }
                }
                `,
                variables: {
                    "data": tData
                }
            }).then(response => {
                setLoading(false);
                if (response?.data?.data?.budgetMutation?.addBudget?.iD) {
                    message.success("Амжилттай");
                }

            }).catch(response => {
                setLoading(false);
            });
            await getData();
        }
    }


    const onSaveDtl = async () => {
        if (editDtl) {
            setLoadingSave(true);
            if (budgetDtlSchool?.length > 0) {
                let tBudgetDtlSchool = budgetDtlSchool.filter(r => r.ischild == 1 && r.edit);

                if (tBudgetDtlSchool?.length > 0) {
                    await API.post("/api/budget/updBudgetSchoolHead", tBudgetDtlSchool).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            message.success("Амжилттай.");
                            setEditDtl(false);
                        }
                    }).catch(() => {
                        setLoadingSave(false);
                    });
                }
            }

            await getData();
            setLoadingSave(false);
        }

        if(editPercent)
        {
            setLoadingSave(true);
            await API.post("/api/budget/updBudgetFull?budgetid="+ params?.id +"&percentAmount="+ editPercentAmount + "&schoolid=" + editPercentSchool).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай.");
                    setEditDtl(false);
                }
            }).catch(() => {
                setLoadingSave(false);
            });
            await getData();
            setLoadingSave(false);
        }
    }

    const onChangeStatus = async (vStatusId, vStatusName) => {

        setLoadingSave(true);
        await API.post("/api/budget/budgetToApprove?budgetid=" + params?.id + "&statusid=" + vStatusId + "&statusname=" + vStatusName + "&username=" + userInfo?.username).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                message.success("Амжилттай.");
            } else {
                message.warning("Амжилтгүй." + response?.data?.retMsg);
            }
        }).catch(() => {
            setLoadingSave(false);
        });

        setLoadingSave(false);
        await getData();
    }

    const onCopyBudget = async () => {
        let newbudgetid = -1;
        setLoadingSave(true);
        await API.post("/api/budget/copyBudget?budgetid=" + params?.id + "&username=" + userInfo?.username).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                newbudgetid = response?.data?.retData;
                message.success("Тодотгосон төсөв амжилттай үүслээ");
            } else {
                message.warning("Тодотгосон төсөв үүсгэхэд алдаа гарлаа. " + response?.data?.retMsg);
            }
        }).catch(() => {
            setLoadingSave(false);
        });

        setLoadingSave(false);
        if (newbudgetid > 0)
            navigate("/budget/confirm/" + newbudgetid);
        else
            message.warning("Тодотгосон төсөв үүсгэхэд алдаа гарлаа");
    }

    const onPercenChange = async (vPercent, vSchoolId) => {
        setEditPercent(true);
        setEditPercentAmount(vPercent);
        setEditPercentSchool(vSchoolId);
    }

    const toOpen = async () => {
        if (params?.id) {
            setLoadingOpen(true);
            await API.get("/api/budget/budgetToOpen?budgetid=" + params?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай.")
                }
            }).catch(response => {
                setLoadingOpen(false);
            });
            setLoadingOpen(false);
            await getData();
        }
    }

    const onEditSchool = (pData) => {
        setEditDtl(true);
        setBudgetDtlSchool(pData);
    }

    const onClickStep = (value) => {
        setCurrent(value);
    }

    const onReturnAsk = async () => {
        setLoadingSave(true);
        let tSchStatus = [];
        await API.get("/api/budget/getReturnSch?budgetid=" + params?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.length > 0) {
                    tSchStatus = response?.data?.retData;
                }
            }
        }).catch(() => {
            setLoadingSave(false);
        });
        setLoadingSave(false);
        setSchStatus(tSchStatus);
        setShowReturn(true);
    }

    const onReturn = async () => {
        if (retSSch?.length > 0) {
            setShowReturn(false);
            setLoadingSave(true);

            let vSuccess = false;
            await API.post("/api/budget/retSchStatus", retSSch).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай.");
                    vSuccess = true;
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            setLoadingSave(false);
            if (vSuccess) {
                setRetSSch([]);
                await getData();
            }
        }
    }

    const onShowHistory = async () => {
       setShowHistory(true);
    }

    let vDisable = false;
    if ((userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        if (data?.sTATUSID > 3)
            vDisable = true;
    } else {
        vDisable = true;
    }

    if (loading) {
        return (
            <LoadScreen />
        );
    }

    let itemList;
    let incomeList;
    let issueList;

    switch (current) {
        case 0:
            itemList = (<BudgetDtlIndicator disable={vDisable} id={params?.id} statusId={data?.sTATUSID} />)
            break;
        case 1:
            incomeList = (<BudgetDtl disable={vDisable} id={params?.id} statusId={data?.sTATUSID} onEdit={onEditSchool} onPercent={onPercenChange} type="1" total={infoData?.incomeprice} />)
            break;
        case 2:
            issueList = (<BudgetDtl disable={vDisable} id={params?.id} statusId={data?.sTATUSID} onEdit={onEditSchool} onPercent={onPercenChange} type="2" total={infoData?.issueprice} />)
            break;
        case 3:
            itemList = (<BudgetDtl disable={vDisable} id={params?.id} statusId={data?.sTATUSID} onEdit={onEditSchool} onPercent={onPercenChange} type="0" total={parseFloat(infoData?.incomeprice) + parseFloat(infoData?.issueprice)} />)
            break;
        case 4:
            itemList = (<Suggestion disable={vDisable} id={params?.id} total={parseFloat(infoData?.incomeprice) + parseFloat(infoData?.issueprice)} />)
            break;
        case 5:
            itemList = (<Reports id={params?.id} />)
            break;
        default:
            break;
    }

    let vStatus = null;

    if (data?.sTATUSID == 2) {
        vStatus = (
            <Button
              text="Буцаах"
              onClick={onReturnAsk}
            />
        );
    } else if (data?.sTATUSID == 3 && (userInfo?.roleids?.includes("1") || userInfo?.roleids?.includes("2"))) {
        vStatus = (
            <Space>
                <Button
                  text="Буцаах"
                  onClick={onReturnAsk}
                />
                <ButtonDefault
                    text="Боловсруулсан"
                    onClick={() => onChangeStatus(4, "Боловсруулсан")}
                />
            </Space>
        );
    } else if (data?.sTATUSID == 4 && userInfo?.roleids?.includes("3")) {
        vStatus = (
            <ButtonDefault
                text="Баталсан"
                onClick={() => onChangeStatus(5, "Баталсан")}
            />
        );
    } else if (data?.sTATUSID == 5) {
        vStatus = (
            <ButtonDefault
                text="Тодотгох"
                onClick={() => onCopyBudget()}
            />
        );
    }

    return (
        <div style={{ backgroundColor: "#f7f7f7", minHeight: "110vh" }}>
            <div style={{ padding: 16, backgroundColor: "#ffffff", height: params?.type ? 56 : 125, boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}>
                <div style={{ marginRight: 8 }}>
                    <Row gutter={16} justify="space-between">
                        <Col>
                            <Row gutter={8} justify="start" align="top">
                                <Col>
                                    <h3 className="budget-title">{data.yEAR}</h3>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 20, marginTop: 1 }}>
                                        <div style={{ color: '#212121' }}>{data?.sTATUSNAME + " - " + data?.cASENAME}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>(Нээсэн: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.sTARTDATE},</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>Хаах: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.eNDDATE})</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: 'flex', fontWeight: 450, fontSize: 14, marginLeft: 8, marginTop: 7 }}>
                                        <div style={{ color: '#212121' }}>Албан тушаалтны нэр: </div>
                                        <div style={{ marginLeft: 8, color: '#212121' }}>{data?.oWNERNAME}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={16} justify="end">
                                <Space>
                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                        <History />
                                        <div style={{ fontSize: 14, marginLeft: 4, marginRight: 8, fontWeight: "600" }} onClick={onShowHistory}>Түүх</div>
                                    </div>
                                    {data?.sTATUSID == 1 && (
                                        <Popconfirm
                                            title="Төсвийг устгах уу?"
                                            onConfirm={deleteBudget}
                                            okText="Устгах"
                                            cancelText="Болих">
                                            <ButtonSecondary text="Устгах" />
                                        </Popconfirm>
                                    )}
                                    {editDtl || editPercent ? (
                                        loadingSave ? (
                                            <LoadSpin />
                                        ) : (
                                            <ButtonDefault
                                                text="Хадгалах"
                                                onClick={onSaveDtl}
                                            />
                                        )
                                    ) : (
                                        edit && data?.sTATUSID == 1 ? (
                                            <ButtonDefault
                                                text="Хадгалах"
                                                onClick={() => onSave(1, "Шинэ")}
                                            />
                                        ) : (
                                            data?.sTATUSID == 1 ? (
                                                loadingOpen ? (
                                                    <LoadSpin />
                                                ) : (
                                                    <ButtonDefault
                                                        text="Нээх"
                                                        onClick={toOpen} />
                                                )
                                            ) : (
                                                loadingSave ? (
                                                    <LoadSpin />
                                                ) : vStatus
                                            )
                                        )
                                    )}
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginTop: 8, marginRight: 64 }}>
                    {!params?.type && (
                        <Steps current={current} onChange={onClickStep}>
                            <Step title="---" description="Үзүүлэлт" icon={<IndicatorChart active={current == 0 ? true : false} />} />
                            <Step title="---" description="Орлого" icon={<IndicatorIncrease active={current == 1 ? true : false} />} />
                            <Step title="---" description="Зарлага" icon={<IndicatorDecrease active={current == 2 ? true : false} />} />
                            <Step title="---" description="Нийт" icon={<IndicatorSum active={current == 3 ? true : false} />} />
                            <Step title="---" description="Санал" icon={<IndicatorSuggestion active={current == 4 ? true : false} />} />
                            <Step title="---" description="Тайлан" icon={<IndicatorReport active={current == 5 ? true : false} />} />
                        </Steps>
                    )}
                </div>
                <div>
                    {itemList}
                    {incomeList}
                    {issueList}
                    <BudgetDtlIndicatorLog 
                        id ={params?.id}
                        isOpen={showHistory}
                        onDismiss={() => {
                            setShowHistory(false);
                        }} />
                </div>
                <Modal
                    visible={showReturn}
                    onCancel={() => { setShowReturn(false); }}
                    onOk={onReturn}
                    cancelText="Болих"
                    okText="Буцаах"
                    title="Төсөв буцаах">
                    {schStatus?.length > 0 ? (
                        <FieldDropdownMulti onChanged={(key, text, field) => { setRetSSch(key); }} title="Сургууль сонгох" keyfield="schstatus" defaultValue={null} data={schStatus} />
                    ) : (
                        <Warning value="Буцаах төсөв байхгүй байна." />
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default BudgetConfirm;
