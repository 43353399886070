export const UserIndicatorRole = [
    {
        key: 1,
        text: "Бусад"
    },
    {
        key: 63,
        text: "Цалин"
    },
    {
        key: 581,
        text: "Барилга, их засвар ХА,ХО"
    },
    {
        key: 582,
        text: "Бусад ХА,ХО"
    }
];