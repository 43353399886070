import React, { useState, useEffect } from "react";
import { PanelBase, LoadSpin, FieldDropdown } from "dw-components";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling} from "devextreme-react/data-grid";
import { APIGraphQL } from "../../API/APIGraphQL";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import CALCULATEMETHODS from "../../Const/CalculateMethods";

const BudgetDtlIndicatorLog = (props) => {

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const onExportDoc = async (pData) => {
        // setLoadingDownload(true);
        // let alldep = 0;
        // let pschoolid = userInfo?.schoolid;
        // if (userInfo?.schooltypeid < 4) {
        //     pschoolid = depid;
        //     if(depid == schoolid || depid == userInfo?.schoolid || schoolid == userInfo?.schoolid)
        //     {
        //         alldep = 1;
        //         pschoolid = 0;
        //         if(userInfo?.schooltypeid > 1)
        //             pschoolid = userInfo?.schoolid;

        //     }
        // }
        // await API.get("/api/budget/budgetBuildingRepairCostTemp?budgetid=" + props?.id +  "&schoolid=" + pschoolid + "&alldep=" + alldep + "&userschoolid="+ userInfo?.schoolid +"&filetype=" + pData?.filetype, {
        //     'responseType': 'blob'
        // }).then(response => {
        //     FileDownload(response.data, "Урсгал засварын зардал" + "." + pData?.filetype);

        // }).catch(response => {
        //     setLoadingDownload(false);
        // });
        // setLoadingDownload(false);
    }

    const getData = async (indid) => {
        if(!indid) {
            return;
        }
        let tData = [];
        if(indid == 63) {
            
            setLoading(true);
            await APIGraphQL.post("", {
                query: `query ($budgetid: Int){
                    sharedQuery {
                        getBudgetSalaryLog(budgetid: $budgetid) {
                            iD
                            bODY
                            cREATED
                            cREATEDBY
                        }
                        }   
                    }`,
                    variables: {
                        "budgetid": parseInt(props?.id)
                    }
            }).then(response => {
                let tResp = response?.data?.data;  
                if (tResp?.sharedQuery?.getBudgetSalaryLog?.length > 0) {
                    tData = tResp?.sharedQuery?.getBudgetSalaryLog;
                }
            }).catch(() => {
                setLoading(false);
            });
            setLoading(false);        
        }


        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        setData(tData);
    }

    useEffect(() => {
    }, [props?.id]);

    const onDismiss = () => {
        setData([]);
        
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onChangeIndicator = async (key) => {
        getData(key);
    }

    return (
        <PanelBase
            headerText="Түүх харах"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="medium"
            buttons={(
                <div style={{ display: "flex" }}>
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >   <div style={{ marginTop: 13 }}>
                <FieldDropdown onChanged={onChangeIndicator} title="Үзүүлэлт" data={CALCULATEMETHODS} />
            </div>
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="cREATEDBY"
                                caption="Хэрэглэгч"
                                width={120}/>
                            <Column
                                dataField="cREATED"
                                caption="Огноо"
                                width={120}/>
                            <Column
                                dataField="bODY"
                                caption="Түүх"
                                minwidth={200}/>
                        </DataGrid>
                    </div>
                </div>
                
            )}
        </PanelBase >
    );
}

export default BudgetDtlIndicatorLog;