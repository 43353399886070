import React, { useState } from "react";
import Upload from "rc-upload/lib/Upload";
import { message } from "antd";
import { Color, uploadFileFormat } from "../../Const/Const";
import { ReactComponent as UploadIcon } from "../../svg/uploadIcon.svg";
import { ProgressIndicator } from "@fluentui/react";

import { storage } from "../../Firebase/firebase-config";
import { ref, uploadBytes } from "firebase/storage";

const FieldUpload = (props) => {
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState(false);

  const uploadProps = {
    multiple: false,
    data: {},
    onStart(file) {
      setLoading(true);
    },
    onSuccess(res, file) {
      setLoading(false);
      message.success("Амжилттай")
    },
    onError(err) {
      setLoading(false);
      message.error("onError", err);
    },
    customRequest({ file, filename, onError, onSuccess }) {
      setWarning();
      let vCheck = true;
      let fileExt = file?.name?.split(".").pop();

      if (
        uploadFileFormat
          .map((r) => r.filetype)
          .indexOf("." + fileExt?.toString()) < 0
      ) {
        vCheck = false;
        setWarning("Зөвшөөрөгдсөн файлын төрөл биш байна.");
      }

      if (props.filetype && props.filetype != fileExt) {
        vCheck = false;
        setWarning("Зөвхөн " + props.filetype + " файл оруулах боломжтой.");
      }

      if (vCheck) {
        // eslint-disable-next-line no-undef
        const formData = new FormData();
        formData.append(file.name, file);

        const imageRef = ref(storage, `files${file.name}`);
        uploadBytes(imageRef, file)
          .then((response) => {
            onSuccess(response, file);
          })
          .catch(onError);
      } else {
        setLoading(false);
      }

      return {
        abort() {},
      };
    },
  };

  let vColor = { fontWeight: 600 };
  if (props.gray) {
    vColor = {
      color: Color.graySecond,
      fontWeight: 400,
    };
  }

  if (loading) {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: 14, ...vColor }}>{props.title}</div>
          {props.required && <div style={{ color: Color.red }}>*</div>}
        </div>
        <ProgressIndicator />
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: 14, ...vColor }}>{props.title}</div>
        {props.required && <div style={{ color: Color.red }}>*</div>}
      </div>
      <div
        style={{
          marginTop: 6,
          height: 30,
          border: "1px solid #DDDDDD",
          display: "flex",
          alignItems: "center",
          paddingLeft: 16,
        }}
      >
        <div style={{ marginRight: 8, height: 20 }}>
          <UploadIcon />
        </div>
        <Upload
          {...uploadProps}
          accept={props.filetype ? "." + props.filetype : "*"}
        >
          <div style={{ fontSize: 14, color: Color.blue, cursor: "pointer" }}>
            Энд дарж оруулна уу.
          </div>
        </Upload>
      </div>
      {warning ? (
        <div
          style={{
            color: "rgb(164, 38, 44)",
            fontSize: 12,
            fontWeight: 400,
            paddingTop: 5,
          }}
        >
          {warning}
        </div>
      ) : null}
    </div>
  );
};

export default FieldUpload;
