export const YESNO = [
 {
   'key': 1,
   'text': 'Тийм'
 },
 {
   'key': 0,
   'text': 'Үгүй'
 }
];
