import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import {
  ButtonDefault,
  FieldDropdown,
  FieldText,
  PanelBase,
} from "dw-components";
import FieldComboBox from "../../../../Components/FieldComboBox/FieldComboBox";
import { db } from "../../../../Firebase/firebase-config";
import { MessageConst } from "../../../../Const/MessageConst";

import { message } from "antd";

const GroupPanel = (props) => {
  const [data, setData] = useState({});
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      setData(tData);
    }
  };

  // Writing data to firebase
  const IndicatorGroupsCollectionRef = collection(db, "otherGroups");
  const createIndicatorGroup = async (payload) => {
    try {
      await addDoc(IndicatorGroupsCollectionRef, payload);
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSubmit = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.groupName) {
      isValid = false;
      setWarning("Үзүүлэлтийн бүлгийн нэр" + MessageConst.fieldRequired);
    }

    if (isValid) {
      setIsDisabled(true);
      await createIndicatorGroup(data);
      message.success("Үзүүлэлт амжилттай нэмэгдлээ");
      props.onDismiss();
      props.forceUpdate();
      setIsDisabled(false);
      setCheckRule(false);
    }
  };

  return (
    <PanelBase
      headerText="Бүлэг нэмэх"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      warning={warning}
      PanelType="medium"
      buttons={
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text="Хадгалах"
              onClick={onSubmit}
              disable={isDisabled}
            />
          </div>
        </div>
      }
    >
      <div className="register-indicator">
        <div style={{ marginTop: 25 }}>
          <FieldText
            required
            onChanged={fieldOnChanged}
            title="Үзүүлэлтийн бүлгийн нэр"
            keyfield="groupName"
            defaultValue={data.groupName}
            checkreq={checkRule}
          />
        </div>
      </div>
    </PanelBase>
  );
};

export default GroupPanel;
