import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Row, Col, FieldNumberFormat, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";

const BudgetInternetPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [dataGateway, setDataGateway] = useState([]);
    const [dataGatewayEdit, setDataGatewayEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);
    const [disable, setDisable] = useState(false);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0 && props?.id) {
            setLoadingSave(true);
            let vSuccess = false;
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < dataGateway.length; j++)
                {
                    let tRNew = {};
                    tRNew.iD = tR[dataGateway[j].key.toString() + "_ID"];
                    tRNew.bUDGETID = parseInt(props?.id);
                    tRNew.iNTERNETOBJECTID = tR.internetobjectid;
                    tRNew.iNTERNETOBJECTNAME = tR.internetobjectname;
                    tRNew.iNTERNETOBJECTSCHOOLID = tR.internetobjectschoolid;
                    tRNew.gATEWAYID = dataGateway[j].key;
                    tRNew.gATEWAYNAME = dataGateway[j].text;
                    tRNew.sPEED = tR[dataGateway[j].key.toString()];
                    tRNew.pRICE = tR[dataGateway[j].key.toString() + "_UNITPRICE"];
                    tRNew.gRANDTOTAL = tR.grandtotal;
                    tRNew.tOTAL = tR.total;
                    tRNew.sCHOOLID = tR.schoolid;
                    tRNew.sCHOOLNAME = tR.schoolname;
                    if (!tRNew.iD || tRNew.iD == 0) {
                        tRNew.iD = 0;
                        tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                    }
                    tRNew.cREATEDBY = userInfo?.username;
                    tBSecDtl[k] = tRNew;
                    k += 1;
                }
            }
            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetInternetInputType]) {
            //         budgetMutation {
            //             addBudgetInternet(data: $data) {
            //                 iD
            //             }
            //         }
            //     }`,
            //     variables: {
            //         "data": tBSecDtl
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetInternet?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBInternet?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid,tBSecDtl).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    API.post("api/budget/updateInternetGateway?budgetid=" + props?.id, dataGatewayEdit).catch(() => {
                        setLoading(false);
                    });
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }

        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/budgetSecurityTemp?budgetid=" + props?.id + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Харуул хамгаалалт" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getData = async () => {
        let tGates = [];
        let tData = [];
        setLoading(true);
        await API.get("/api/budget/getInternetList?budgetid=" + props?.id)
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.gates?.length > 0) {
                    tGates = response?.data?.retData?.gates;
                }
                if (response?.data?.retData?.data?.length > 0) {
                    tData = response?.data?.retData?.data;
                }
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tGates?.length; i++) {
            tGates[i].arrayIndex = i;
        }

        let totalspeed = 0;
        let totalprice = 0;
        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
            totalprice = 0;
            totalspeed = 0;
            tGates.forEach((el) => {
                totalspeed += parseFloat(tData[i][el.key.toString()]);
                tData[i][el.key.toString() + "_UNITPRICE"] = parseFloat(el.unitprice / 1000 * tData[i][el.key.toString()]).toFixed(2);
                totalprice += parseFloat(tData[i][el.key.toString() + "_UNITPRICE"]);
            });
            tData[i]["totalspeed"] = totalspeed;
            tData[i]["grandtotal"] = totalprice;
            tData[i]["total"] = totalprice * 12;
        }

        await getAttachData();
        setLoading(false);
        setDataGateway(tGates);
        setDataGatewayEdit(tGates);
        setData(tData);
        setDataEdit(tData);
        setDisable(props?.disable);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const getAttachData = async () => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + userInfo?.schoolid + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        let totalspeed = 0;
        let totalprice = 0;
        let tGates = [...dataGateway];
        tGates.forEach((el) => {
            totalspeed += tDataEdit[pIndex][el.key.toString()];
            tDataEdit[pIndex][el.key.toString() + "_UNITPRICE"] = el.unitprice / 1000 * tDataEdit[pIndex][el.key.toString()];
            totalprice += tDataEdit[pIndex][el.key.toString() + "_UNITPRICE"];
        });
        tDataEdit[pIndex]["totalspeed"] = totalspeed;
        tDataEdit[pIndex]["grandtotal"] = totalprice;
        tDataEdit[pIndex]["total"] = totalprice * 12;
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }
    
    const fieldGatesOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataGatewayEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        tDataEdit[pIndex].edit = true;
        setDataGatewayEdit(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onCalculate = async () => {
        let tGates = [...dataGatewayEdit];
        let tData = [];
        setLoading(true);
        await API.get("/api/budget/calcInternetObjectSchool?budgetid=" + props?.id)
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.length > 0) {
                    tData = response?.data?.retData;
                }
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
            tGates.forEach((el) => {
                tData[i][el.key.toString() + "_UNITPRICE"] = el.unitprice * tData[i].speed;
            });
        }

        setLoading(false);
        setData(tData);
        setDataEdit(tData);
    }

    return (
        <PanelBase
            headerText="Интернэт"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                    <div style={{ marginRight: 16 }}>
                        {loadingSave ? (<LoadSpin />) :
                            (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                    </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
            >
            {loading ? (<LoadSpin />) : (
            <div>
                    <Row justify="space-between">
                    <Row style={{ marginTop: 30 }}>
                        <Col justify="end">
                                    /төгрөг/
                        </Col>
                    </Row>
                </Row>
                        <DataGrid
                            dataSource={dataGateway}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="text"
                                caption="Гарц"
                                width={300}
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => {}} defaultValue={item.data?.text} />
                                    );
                                }}
                                />
                            <Column
                                dataField="unitprice"
                                caption="Нэгж үнэ /НӨАТ-тэй/"
                                alignment='center'
                                width={200}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldGatesOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "unitprice")} defaultValue={item.data?.unitprice} fixedDecimalScale={true} />
                                    );
                                }} />
                        </DataGrid>
                    <Col> 
                        {(!disable &&
                            <Row justify="start" > 
                                <Col style={{ marginTop: 24 }}>
                                        <ButtonDefault onClick={onCalculate} text="Тооцоолох" />
                                </Col>
                            </Row>
                        )}
                    </Col>
                   <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            <Column
                                dataField="internetobjectname"
                                caption="Салбар нэгж"
                                width={300}
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "internetobjectname")} defaultValue={item.data?.internetobjectname} />
                                    );
                                }}/>
                    {dataGateway?.map(r => {
                        return (
                            <Column caption={r.text + " ХУРД. Mbps"}
                                    alignment='center'
                                    width={200}
                                    dataField={r.key?.toString()}
                                    format="fixedPoint"
                                    cellRender={item => {
                                        return (
                                            <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, r.key?.toString() )} defaultValue={item?.data?.[r.key]} fixedDecimalScale={true} />
                                        );
                                    }}
                                />
                                );
                        })
                    }
                    <Column caption={"Нийт ХУРД. Mbps"}
                            alignment='center'
                            dataField="totalspeed"
                            width={200}
                            format="fixedPoint"
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalspeed")} defaultValue={item?.data?.totalspeed} fixedDecimalScale={true} />
                                );
                            }}
                        />
                    {dataGateway?.map(r => {
                        return (
                            <Column caption={"Сар бүрийн үнэ " + r.text}
                                    alignment='center'
                                    dataField={r.key?.toString() + "_UNITPRICE"}
                                    width={200}
                                    format="fixedPoint"
                                    cellRender={item => {
                                        return (
                                            <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, r.key?.toString() + "_UNITPRICE" )} defaultValue={item?.data?.[r.key?.toString() + "_UNITPRICE"]} fixedDecimalScale={true} />
                                        );
                                    }}
                                />
                                );
                        })
                    }
                    <Column caption="Сард нийт"
                            alignment='center'
                            width={200}
                            dataField="grandtotal"
                            format="fixedPoint"
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "grandtotal")} defaultValue={item?.data?.grandtotal} fixedDecimalScale={true} />
                                );
                            }}
                        />
                    <Column caption="Нийт"
                            alignment='center'
                            minwidth={200}
                            dataField="total"
                            format="fixedPoint"
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "total")} defaultValue={item?.data?.total} fixedDecimalScale={true} />
                                );
                            }}
                        />
                <Summary>
                    <TotalItem
                        column="№"
                        displayFormat="Нийт:"
                    />
                    <TotalItem
                        column="totalspeed"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        alignment="right"
                        displayFormat="{0}₮"
                    />
                    <TotalItem
                        column="grandtotal"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        alignment="right"
                        displayFormat="{0}₮"
                    />
                    <TotalItem
                        column="total"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        alignment="right"
                        displayFormat="{0}₮"
                    />
                </Summary> 
            </DataGrid> 
                </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={userInfo?.schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(userInfo?.schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BudgetInternetPanel;