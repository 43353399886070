import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldNumberFormat, LoadSpin, PanelBase } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";

const BuildingSchoolCostPanel = (props) => {
  const [data, setData] = useState({});
  const [dBuilding, setDBuilding] = useState([]);
  const [dSchool, setDSchool] = useState([]);
  const [checkRule, setCheckRule] = useState(false);

  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);
    let tDBuilding = [];
    let tDSchool = [];
    let tData = {};

    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getBuildingSchoolCost(id: $id) {
              iD
              bUILDINGID
              bUILDINGNAME
              sCHOOLID
              sCHOOLNAME
              lIGHTCOSTPERCENT
              wATERCOSTPERCENT
              hEATCOSTPERCENT
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery;
        if (tResp?.getBuildingSchoolCost.iD) {
          tData = tResp?.getBuildingSchoolCost;
        }
      }).catch(() => {
        setLoading(false);
      });
    }

    await APIGraphQL.post("", {
      query: `{
      sharedQuery {
        getBuildingListAll {
          key: iD
          text: nAME
        }
        getSchoolList {
          key: iD
          text: nAME
        }
      }
    }`
    }).then(response => {

      let tCResp = response?.data?.data?.sharedQuery?.getBuildingListAll;
      if (tCResp) {
        tDBuilding = tCResp;
      }
      let tUResp = response?.data?.data?.sharedQuery?.getSchoolList;
      if (tUResp) {
        tDSchool = tUResp;
      }
    }).catch(() => {
      setLoading(false);
    });

    setLoading(false);
    setData(tData);
    setDBuilding(tDBuilding);
    setDSchool(tDSchool);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;
    let tData = { ...data };
    if (!data?.bUILDINGID ||
        !data?.sCHOOLID ||
        !data?.lIGHTCOSTPERCENT ||
        !data?.wATERCOSTPERCENT ||
        !data?.hEATCOSTPERCENT ) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: BuildingSchoolCostInputType) {
          sharedMutation {
            addBuildingSchoolCost(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addBuildingSchoolCost?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteBuildingSchoolCost($id: Int) {
        sharedMutation {
          deleteBuildingSchoolCost(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteBuildingSchoolCost == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Байр ашиглалтын мэдээлэл засах" : "Байр ашиглалт нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Объект" keyfield="bUILDINGID" keyfieldname="bUILDINGNAME" checkreq={checkRule} defaultValue={data.bUILDINGID} data={dBuilding} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Сургууль" keyfield="sCHOOLID" keyfieldname="sCHOOLNAME" checkreq={checkRule} defaultValue={data.sCHOOLID} data={(data?.sCHOOLID || data?.sCHOOLID > 0) ? [{ key: data?.sCHOOLID, text: data?.sCHOOLNAME }] : dSchool}/>
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Цэвэр, бохир усны эзлэх хувь" keyfield="wATERCOSTPERCENT" checkreq={checkRule} defaultValue={data.wATERCOSTPERCENT} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Түлш, халаалтад эзлэх хувь" keyfield="hEATCOSTPERCENT" checkreq={checkRule} defaultValue={data.hEATCOSTPERCENT} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Гэрэл, цахилгаанд эзлэх хувь" keyfield="lIGHTCOSTPERCENT" checkreq={checkRule} defaultValue={data.lIGHTCOSTPERCENT} />
      </div>
    </PanelBase>
  );
};

export default BuildingSchoolCostPanel;
