export const VehicleTaxColumns = [
    {
        key: "tax",
        text: "АТБӨЯХ-н татвар"
    },
    {
        key: "airpollutiontax",
        text: "Агаарын бохирдол татвар"
    },
    {
        key: "highwaytax",
        text: "Авто зам ашиглалтын татвар"
    },
    {
        key: "diagnostictax",
        text: "Техник үзлэг"
    }
];