import React, { useEffect, useState, useContext } from 'react';
import IndicatorTitle from '../../../Components/IndicatorTitle/IndicatorTitle';
import { API } from '../../../API/API';
import FileDownload from 'js-file-download';
import { Space, message , Popover, Button, DatePicker} from 'antd';
import moment from 'moment';
import { UserContext } from '../../../Context/UserContext';
import ButtonReport from '../../../Components/ButtonReport/ButtonReport';
import { APIGraphQL } from '../../../API/APIGraphQL';

const ReportSchool = (props) => {
    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        let tData = {};
        setLoading(true);
        await APIGraphQL.post("", {
            query: `query ($id: Int) {
        sharedQuery {
          schoolType(id: $id) {
            sCHOOLTYPEID
          }
        }
      }`,
            variables: {
                "id": parseInt(userInfo?.schoolid)
            }
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.schoolType;
            if (tResp) {
                tData = tResp;
            }
        }).catch(() => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }
    useEffect(() => {
        getData();
    }, []);


    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const onButtonClick = () => {
        setShowDatePicker(true);
    };

    const onDateChange = (dates) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    };

    const exportWithDates = async (pData) => {
        if (startDate && endDate) {
            setLoadingDownload(true);

            await API.get("/api/budget/budgetPerformanceReportTemp?schoolid=" + userInfo.schoolid + "&begindate=" + moment(startDate).format("YYYY.MM.DD") + "&enddate=" + moment(endDate).format("YYYY.MM.DD") + "&filetype=" + pData?.filetype, {
                'responseType': 'blob'
            }).then(response => {
                if (response?.data?.size == 0) {
                    message.warning("Маягт хоосон байна.");
                } else {
                    FileDownload(response.data, "Гүйцэтгэл_ " + moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                }
            }).catch(response => {
                setLoadingDownload(false);
            });
            setLoadingDownload(false); 
            setShowDatePicker(false); 
        }
    };

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        if (data.sCHOOLTYPEID == 7 || data.sCHOOLTYPEID == 4) {

            if (pData?.id == "3") {
                await API.get("/api/budget/budgetInvestmentSchoolReport?budgetid=" + props?.id + "&schoolid=" + userInfo.schoolid + "&schooltype=" + "departmentid" + "&filetype=" + pData?.filetype, {
                    'responseType': 'blob'
                }).then(response => {
                    if (response?.data?.size == 0) {
                        message.warning("Маягт хоосон байна.");
                    } else {
                        FileDownload(response.data,"ТӨБЗГ_"+ moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                    }
                }).catch(response => {
                    setLoadingDownload(false);
                });
            }
        }
        else if (data.sCHOOLTYPEID == 2 || data.sCHOOLTYPEID == 5 || data.sCHOOLTYPEID == 6 || data.sCHOOLTYPEID == 3) {
            if (pData?.id == "3") {
                await API.get("/api/budget/budgetInvestmentSchoolReport?budgetid=" + props?.id + "&schoolid=" + userInfo.schoolid + "&schooltype=" + "schoolid" + "&filetype=" + pData?.filetype, {
                    'responseType': 'blob'
                }).then(response => {
                    if (response?.data?.size == 0) {
                        message.warning("Маягт хоосон байна.");
                    } else {
                        FileDownload(response.data,"ТӨБЗГ_"+  moment().format("YYYY.MM.DD HH:mm:SS") + "." + pData?.filetype);
                    }
                }).catch(response => {
                    setLoadingDownload(false);
                });
            }
        }
        setLoadingDownload(false);
    }


    return (
        <div>
            <div style={{ marginTop: 24 }}>
                <IndicatorTitle value="" />
                <Space>
                    <ButtonReport id={3} title="ТӨБЗГ" onClick={onExportDoc} />
                    <div>
                        <Button onClick={onButtonClick}>Төсвийн гүйцэтгэлийн тайлан</Button>
                        {showDatePicker && (
                            <Popover
                                content={
                                    <DatePicker.RangePicker
                                        format="YYYY.MM.DD"
                                        onChange={onDateChange}
                                    />
                                }
                                title="Select Start and End Date"
                                trigger="click"
                                visible={showDatePicker}
                                onVisibleChange={(visible) => {
                                    if (!visible) setShowDatePicker(false);
                                }}
                            >
                                <ButtonReport id={5} onClick={exportWithDates}>Export</ButtonReport>
                            </Popover>
                        )}
                    </div>
                </Space>
            </div>
        </div>
    );
}

export default ReportSchool;