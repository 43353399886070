import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, ButtonSecondary, FieldText } from "dw-components";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Scrolling, Summary, TotalItem, GroupItem} from "devextreme-react/data-grid";
import { message } from "antd";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { Icon } from '@fluentui/react/lib/Icon';
import { UserType } from "../../Const/UserType";

const BRepairCost = (props) => {

    const {userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [dEconomy, setDEconomy] = useState([]);
    const [dUnit, setDUnit] = useState([]);

    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.iD = tR.id;
                tR.bUDGETID = parseInt(props?.id);
                tR.rEPAIRCOSTNAME = tR.repaircostname;
                tR.rEPAIRCOSTID = tR.repaircostid;
                tR.nAME = tR.name;
                tR.uNITID = tR.unitid;
                tR.uNITNAME = tR.unitname;
                tR.nOTE = tR.note;
                tR.qTY = tR.qty;
                tR.uNITAMOUNT = tR.unitamount;
                tR.tOTALAMOUNT = tR.totalamount;

                
                tR.oUTPUT = tR.output;
                tR.oUTCOME = tR.outcome;
                tR.iMPACT = tR.impact;
                tR.sPTOPPRID = tR.sptopprid;
                tR.sPTOPPRNAME = tR.sptopprname;
                tR.sPGOALID = tR.spgoalid;
                tR.sPGOALNAME = tR.spgoalname;
                tR.sPSTRID = tR.spstrid;
                tR.sPSTRNAME = tR.spstrname;
                tR.sPINDID = tR.spindid;
                tR.sPINDNAME = tR.spindname;
                tR.kPIID = tR.kpiid;
                tR.kPINAME = tR.kpiname;

                delete tR.id;
                delete tR.depcode;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.unitamount;
                delete tR.totalamount;
                delete tR.qty;
                delete tR.unitid;
                delete tR.unitname;
                delete tR.repaircostname;
                delete tR.repaircostid;
                delete tR.note;
                delete tR.name;
                delete tR.created;
                delete tR.createdby;
                delete tR.schoolcode;
                delete tR.depcode;
                delete tR.year;
                delete tR.arrayIndex;
                delete tR.edit;

                delete tR.output;
                delete tR.outcome;
                delete tR.impact;
                delete tR.sptopprid;
                delete tR.sptopprname;
                delete tR.spgoalid;
                delete tR.spgoalname;
                delete tR.spstrid;
                delete tR.spstrname;
                delete tR.spindid;
                delete tR.spindname;
                delete tR.kpiid;
                delete tR.kpiname;
                
                let currentschoolid = schoolid;
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                } 
                tR.sCHOOLID = parseInt(currentschoolid);
                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;

                tDataEdit[i] = tR;
            }
            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetRepairCostInputType]) {
            //         budgetMutation {
            //          addBudgetRepairCost(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetRepairCost?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBRepairCost?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
            //vSuccess = await calcfunc("calcBRepairCost", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let alldep = 0;
        let pschoolid = userInfo?.schoolid;
        if (userInfo?.schooltypeid < 4) {
            pschoolid = depid;
            if(depid == schoolid || depid == userInfo?.schoolid || schoolid == userInfo?.schoolid)
            {
                alldep = 1;
                pschoolid = 0;
                if(userInfo?.schooltypeid > 1)
                    pschoolid = userInfo?.schoolid;

            }
        }
        await API.get("/api/budget/budgetRepairCostTemp?budgetid=" + props?.id +  "&schoolid=" + pschoolid + "&alldep=" + alldep + "&userschoolid="+ userInfo?.schoolid +"&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Эд хогшилын зардал" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDEcoCat = [];
        let tDUnit = [];
        
        let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;
        setLoading(true);
        await API.get("/api/budget/budgetRepairCost?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&calctypeid=" + props?.indId).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData?.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                        getRepairCostList {
                            key: iD
                            text: nAME
                        }
                        getUnits {
                            key: iD
                            text: nAME
                        }
                    }   
                }`
        }).then(response => {
            let tResp = response?.data?.data;  
            if (tResp?.sharedQuery?.getRepairCostList?.length > 0) {
              tDEcoCat = tResp?.sharedQuery?.getRepairCostList;
            }
            if (tResp?.sharedQuery?.getUnits?.length > 0) {
              tDUnit = tResp?.sharedQuery?.getUnits;
            }
        }).catch(() => {
            setLoading(false);
        });

        setLoading(true);        

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setDEconomy(tDEcoCat);
        setDUnit(tDUnit);
    }

    const setSchools = async(pSchoolId) => 
    {
        const schools = await getSchools(pSchoolId);
        if(userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);            
        }
        else if(userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6){
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;    
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
        }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);
        getSPlan();

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "unitamount") {
            tDataEdit[pIndex]["totalamount"] = (tDataEdit[pIndex]["qty"] || 0) * pValue;
        }
        else if (pField == "qty") {
            tDataEdit[pIndex]["totalamount"] = (tDataEdit[pIndex]["unitamount"] || 0) * pValue;
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);

        if (pField == "sptopprid") {
            tDataEdit[pIndex]["spgoalid"] = null;
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spgoalid") {
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spstrid") {
            tDataEdit[pIndex]["spindid"] = null;
        }
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBudgetRepairCost(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetRepairCost == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBRepairCost?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            }
                //vSuccess = await calcfunc("calcBRepairCost", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
        }

        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
        query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
          variables: {
            "typeid": parseInt(typeid)
        }
        }).then(response => {       
        let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
            setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);  
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Эд хогшил"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
               <div style={{ marginTop: 13 }}>
                   <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
               </div>
            )}
            {props?.showSchool && (
               <div style={{ marginTop: 24 }}>
                   <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
               </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөг/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                         
                         {disable && props?.showSchool && (
                              <Column
                              dataField="schoolcode"
                              caption="Сургууль"
                              width={150}
                              alignment='right'
                              groupIndex={0}/>
                          )}
                          {disable && props?.showDep && (
                            <Column
                            dataField="depcode"
                            caption="Тэнхим"
                            width={150}
                            alignment='right'
                            groupIndex={1} />
                        )}
                            <Column
                                dataField="name"
                                caption="Хөрөнгийн нэр"
                                wordwrap='break-word'
                                minWidth={400}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "name")} defaultValue={item.data?.name} />
                                    );
                                }} />
                            <Column
                                dataField="repaircostname"
                                caption="Ангилал"
                                alignment='center'
                                width={250}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "repaircostid", pNameValue, "repaircostname")} defaultValue={item.data?.repaircostid} data={dEconomy} />
                                    );
                                }} />
                            <Column
                                width={120} dataField="unitname" caption="Хэмжих нэгж" alignment='center' format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "unitid", pNameValue, "unitname")} defaultValue={item.data?.unitid} data={dUnit} />
                                    );
                                }}
                            />
                            <Column
                                dataField="qty"
                                caption="Тоо хэмжээ"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "qty")} defaultValue={item.data?.qty} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="unitamount"
                                caption="Нэг бүрийн үнэ"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "unitamount")} defaultValue={item.data?.unitamount} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="totalamount"
                                caption="Нийт төсөвт өртөг"
                                width={200}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.totalamount} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="note"
                                caption="Тайлбар"
                                minwidth={300}
                                cellRender={(item) => {
                                    if(!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.note} </div>
                                        );
                                    }
                                }} />
                             <Column
                                dataField="output"
                                caption="Хүрэх үр дүн /outputs/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "output")} defaultValue={item.data?.output} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.output} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="outcome"
                                caption="Хүрэх үр дагавар /outcome/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "outcome")} defaultValue={item.data?.outcome} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.outcome} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="impact"
                                caption="Үр нөлөө /Impact/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "impact")} defaultValue={item.data?.impact} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.impact} </div>
                                        );
                                    }
                                }} />

                            <Column
                                dataField="sptopprname"
                                caption="Тэргүүлэх чиглэл"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "sptopprid", pNameValue, "sptopprname")}
                                                defaultValue={item.data?.sptopprid}
                                                data={splan.filter(x => x.typeid == 2)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.sptopprname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spgoalname"
                                caption="Зорилт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spgoalid", pNameValue, "spgoalname")}
                                                defaultValue={item.data?.spgoalid}
                                                data={splan.filter(x => x.typeid == 3 && x.parentid == item.data?.sptopprid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spgoalname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spstrname"
                                caption="Стратеги"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spstrid", pNameValue, "spstrname")}
                                                defaultValue={item.data?.spstrid}
                                                data={splan.filter(x => x.typeid == 4 && x.parentid == item.data?.spgoalid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spstrname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spindname"
                                caption="Хэмжих үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spindid", pNameValue, "spindname")}
                                                defaultValue={item.data?.spindid}
                                                data={splan.filter(x => x.typeid == 5 && x.parentid == item.data?.spstrid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spindname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="kpiname"
                                caption="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "kpiid", pNameValue, "kpiname")} defaultValue={item.data?.kpiid} data={kpis} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.kpiname} </div>
                                        );
                                    }
                                }} />
                                ({!disable && <Column
                                     dataField="action"
                                     caption=""
                                     width={30}
                                     cellRender={(item) => {
                                         return (
                                             <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                                 <Icon iconName="Delete" />
                                             </div>
                                         );
                                     }} />
                                 })
                            <GroupItem
                                column="totalamount"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                showInGroupFooter={false}
                                alignByColumn={true}
                                displayFormat="{0}&nbsp;&nbsp;" />
                            <Summary>
                            <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalamount"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                    </div>
                </div>
                
            )}
        </PanelBase >
    );
}

export default BRepairCost;