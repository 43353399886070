import React, { useState, useEffect, useContext } from "react";
import { PanelBase, ButtonDefault, FieldNumberFormat, FieldDropdown, LoadSpin, PageTitle } from "dw-components";
import { APIGraphQL } from "../../API/APIGraphQL";
import { Col, Divider, message, Row } from "antd";
import { UserContext } from "../../Context/UserContext";
import moment from "moment";
import { Icon } from '@fluentui/react/lib/Icon';
import POSITIONCATEGORY from "../../Const/POSITIONCATEGORY";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import { API } from "../../API/API";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";

const PositionForm = (props) => {

  const { userInfo } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dPosition, setDPosition] = useState([]);
  const [attachData, setAttachData] = useState([]);

  const [disable, setDisable] = useState(false);
  const [dSchools, setDschools] = useState([]);
  const [dDeps, setDdeps] = useState([]);
  const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
  const [depid, setDepId] = useState(userInfo?.schoolid);


  const onSave = async () => {

    let tData = data?.filter(r => r.positionid && r.staff);

    if (tData.length > 0 && (depid || schoolid)) {
      setLoadingSave(true);
      let tSuccess = false;

      for (let i = 0; i < tData.length; i++) {
        let tR = { ...tData[i] };

        tR.bUDGETID = parseInt(props?.id);
                
        tR.sCHOOLNAME = "";
        let currentschoolid = schoolid;
        let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
        if (tIndex >= 0) {
            tR.sCHOOLNAME = dSchools[tIndex].text;
        }
        if (props?.levelid == 1) {
            currentschoolid = depid;
            let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
            if (tIndex >= 0) {
                tR.sCHOOLNAME = dDeps[tIndex].text;
            }
        } 
        tR.sCHOOLID = parseInt(currentschoolid);

        tR.iD = tR.id;
        tR.pOSITIONID = tR.positionid;
        tR.pOSITIONNAME = tR.positionname;
        tR.sTAFF = tR.staff;
        tR.cATEGORYID = tR.categoryid;
        tR.cATEGORYNAME = tR.categoryname;
        delete tR.arrayIndex;
        delete tR.id;
        delete tR.budgetid;
        delete tR.schoolid;
        delete tR.schoolname;
        delete tR.positionid;
        delete tR.positionname;
        delete tR.staff;
        delete tR.categoryid;
        delete tR.categoryname;
        delete tR.created;
        delete tR.createdby;
        delete tR.schoolcode;
        delete tR.depcode;
        delete tR.year;

        if (tR.iD == 0) {
          tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
        }
        tR.cREATEDBY = userInfo?.username;
        tData[i] = tR;
      }

      await APIGraphQL.post("", {
        query: `mutation ($data: [BudgetPositionInputType]) {
          sharedMutation {
            addBudgetPositions(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        if (response?.data?.data?.sharedMutation?.addBudgetPositions?.length > 0) {
          API.post("api/budget/calcformula?budgetid=" + props?.id + "&userschoolid=" + userInfo?.schoolid + "&schoolid="+ (!schoolid ? userInfo?.schoolid : schoolid) + "&depid=" + (!depid ? userInfo?.schoolid : depid)).then(response => {
            if (response?.status == 200 && response?.data.retType == 0) {
              message.success("Амжилттай");
              tSuccess = true;
            }
            else {
              tSuccess = false;
            }
        });
        }
      }).catch(() => {
        setLoadingSave(false);
      });
      setLoadingSave(false);

      if (tSuccess) {
        message.warning("Ажлын байранд өөрчлөлт орсон тул цалин болон урамшууллыг дахин тооцоолох товч дарна уу!");
        onDismiss();
      }
    } else {
      onDismiss();
    }
    await props.afterSave();
  }

  const getData = async (pSchoolId, isalldep = 0) => {
    setLoading(true);
    let tDPosition = [];
    let tData = [];
    let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;

      await API.get("/api/budget/getbudgetPosition?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
        if (response?.status == 200 && response?.data?.retType == 0) {
            tData = response?.data?.retData.data;
            let tSchoolTypeID = -1;
            let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
            if (tIndex >= 0) {
                tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
            }
            let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
            setDisable(props?.disable || tDisable);
        }
    }).catch(() => {
        setLoading(false);
    });
    await APIGraphQL.post("", {
      query: ` {
        sharedQuery {
          getPositionsfiltered(isuseuser: 0) {
            key: iD
            text: nAME
          }
        }
      }
      `
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery;
      if (tResp?.getPositionsfiltered?.length > 0) {
        tDPosition = tResp.getPositionsfiltered;
      }
    }).catch(() => {
      setLoading(false);
    });

    for (let i = 0; i < tData?.length; i++) {
      tData[i].arrayIndex = i;
    }

    await getAttachData(pSchoolId);
    setData(tData);
    setDPosition(tDPosition);
    setLoading(false);
  }


  const getAttachData = async (pSchoolId) => {
    let tAttachData = [];
    await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        tAttachData = response?.data?.retData;
      }
    }).catch(() => {
      setLoading(false);
    });
    setAttachData(tAttachData);
  }

  const setSchools = async(pSchoolId) => 
  {
      const schools = await getSchools(pSchoolId);
      if(userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
          setDschools(schools);
          setSchoolId(pSchoolId);            
      }
      else if(userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6){
          setDdeps(schools);
          setDepId(pSchoolId);
      }
  }

  const getSchools = async (pSchoolId) => {
      let tSchools = [];
      await APIGraphQL.post("", {
          query: `query ($schoolid: Int) {
              sharedQuery {
                  schoolByParentId(schoolid: $schoolid) {
                      key: iD
                      text: nAME
                  }
              }
            }`,
          variables: {
              "schoolid": pSchoolId
          }
      }).then(response => {
          let tResp = response?.data?.data;    
          if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
              tSchools = tResp?.sharedQuery?.schoolByParentId;
              tSchools.push({ key: pSchoolId, text: "Бүгд" });
      }
      }).catch(() => {
          setLoading(false);
      });
      return tSchools;
  }

  useEffect(() => {
      let vSchoolId = userInfo?.schoolid;
      setSchools(vSchoolId);
      getData(vSchoolId);

  }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }


  const fieldOnChanged = async (pIndex, key, text, field, fieldName) => {
    let tData = [...data];
    
    if(fieldName == "categoryname") {
      let tPositions = [];
      await APIGraphQL.post("", {
          query: `query ($categoryid: Int) {
              sharedQuery {
                getPositionsByCategoryID(categoryid: $categoryid) {
                      key: iD
                      text: nAME
                  }
              }
            }`,
          variables: {
              "categoryid": key
          }
      }).then(response => {
          let tResp = response?.data?.data;    
          if (tResp?.sharedQuery?.getPositionsByCategoryID?.length > 0) {
            tPositions = tResp?.sharedQuery?.getPositionsByCategoryID;
      }
      }).catch(() => {
          setLoading(false);
      });
      setDPosition(tPositions);
    }
      if (pIndex == -1) {

        let tDataRow = {
          id: 0,
          arrayIndex: tData?.length,
          [field]: key
        }
  
        if (fieldName) {
          tDataRow = {
            ...tDataRow,
            [fieldName]: text
          }
        }
        tData.push({ ...tDataRow });
  
      } else {
  
        tData[pIndex] = {
          ...tData[pIndex],
          [field]: key
        };
  
        if (fieldName) {
          tData[pIndex] = {
            ...tData[pIndex],
            [fieldName]: text
          };
        }
      }
        setData(tData);
  }

  const onDelete = async (pIndex, pId) => {
    if (pId > 0) {
      await APIGraphQL.post("", {
        query: `mutation ($id: Int) {
          sharedMutation {
            delBudgetPosition(id: $id)
          }
        }`,
        variables: {
          "id": parseInt(pId)
        }
      }).then(response => { });
    }

    message.warning("Ажлын байранд өөрчлөлт орсон тул цалин болон урамшууллыг дахин тооцоолох товч дарна уу!");

    let tData = [...data];
    tData.splice(pIndex, 1);
    for (let i = 0; i < tData?.length; i++) {
      tData[i].arrayIndex = i;
    }
    setData(tData);
  }

  const onChangeSchool = async (pSchoolId) => {
      let tddep = await getSchools(pSchoolId);
      setSchoolId(pSchoolId);
      setDdeps(tddep);  
      setDepId(pSchoolId);
      await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
  }

  const onChangeDep = async (pSchoolId) => {
      setDepId(pSchoolId);
      await getData(pSchoolId);
  }

  return (
    <PanelBase
      headerText="Ажилчдын мэдээлэл"
      isOpen={props.isOpen}
      PanelType="medium"
      onDismiss={onDismiss}
      buttons={!disable && (loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />)}
    >
      
      {props?.showSchool && (
      <div style={{ marginTop: 24 }}>
          <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title="Сургууль" keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
      </div>
      )}
      {props?.showDep && (
          <div style={{ marginTop: 24 }}>
              <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
          </div>
      )}
      <div style={{ marginTop: 24 }}>
        {POSITIONCATEGORY?.map(rcat => {
          let tData = data?.filter(r => r.categoryid == rcat.key);
          if (tData?.length > 0) {
            return (
              tData?.map((r, index) => {
                return (
                  <div key={index}>
                    {index == 0 && <div style={{ marginTop: 24, width: '100%', background: '#ECECEC', padding: '4px 24px', borderRadius: 2 }}>
                      <div style={{ fontSize: 16, fontWeight: 600 }}>{rcat.text}</div>
                    </div>}
                    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                      <Col span={8}>
                        <FieldDropdown disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Ангилал" : null} keyfield="categoryid" keyfieldname="categoryname" defaultValue={r.categoryid} data={POSITIONCATEGORY} />
                      </Col>
                      <Col span={10}>
                        <FieldDropdown disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Ажлын байр" : null} keyfield="positionid" keyfieldname="positionname" defaultValue={r.positionid} data={(r.positionid || r.positionid > 0) ? [{ key: r.positionid, text: r.positionname}] : dPosition} />
                      </Col>
                      <Col span={4}>
                        <FieldNumberFormat disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Орон тоо" : null} keyfield="staff" defaultValue={r.staff} />
                      </Col>
                      <Col span={disable ? 0 : 1}>
                        <div onClick={() => onDelete(r.arrayIndex, r.id)}>
                          <Icon style={{ marginTop: index == 0 ? 32 : 13 }} iconName="Delete" />
                        </div>
                      </Col>
                    </Row>
                    {index + 1 == tData?.length && <Divider />}
                  </div>
                )
              })
            );
          }
        })}
        {
          data?.filter(r => !r.categoryid)?.map((r, index) => {
          return (
              <Row key={index} gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={8}>
                  <FieldDropdown disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Ангилал" : null} keyfield="categoryid" keyfieldname="categoryname" defaultValue={r.categoryid} data={POSITIONCATEGORY} />
                </Col>
                <Col span={10}>
                  <FieldDropdown disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Ажлын байр" : null} keyfield="positionid" keyfieldname="positionname" defaultValue={r.positionid} data={(r.positionid || r.positionid > 0) ? [{ key: r.positionid, text: r.positionname}] : dPosition} />
                </Col>
                <Col span={4}>
                  <FieldNumberFormat disable={disable} onChanged={(key, text, field, fieldName) => fieldOnChanged(r.arrayIndex, key, text, field, fieldName)} title={index == 0 ? "Орон тоо" : null} keyfield="staff" defaultValue={r.staff} />
                </Col>
                <Col span={disable ? 0 : 1}>
                  <div onClick={() => onDelete(r.arrayIndex, r.id)}>
                    <Icon style={{ marginTop: index == 0 ? 32 : 13 }} iconName="Delete" />
                  </div>
                </Col>
              </Row>
            );
          })
        }
        {!disable && (
          <Row gutter={[16, 16]} key={data?.length} style={{ marginTop: 8 }}>
          <Col span={8}>
            <FieldDropdown placeholder="..." loading={loading} onChanged={(key, text, field, fieldName) => fieldOnChanged(-1, key, text, field, fieldName)} title={data?.length == 0 ? "Ангилал" : null} keyfield="categoryid" keyfieldname="categoryname" defaultValue={null} data={POSITIONCATEGORY} />
          </Col>
            <Col span={10}>
              <FieldDropdown placeholder="..." loading={loading} onChanged={(key, text, field, fieldName) => fieldOnChanged(-1, key, text, field, fieldName)} title={data?.length == 0 ? "Ажлын байр" : null} keyfield="positionid" keyfieldname="positionname" defaultValue={null} data={dPosition} />
            </Col>
            <Col span={4}>
              <FieldNumberFormat placeholder="..." loading={loading} onChanged={(key, text, field, fieldName) => fieldOnChanged(-1, key, text, field, fieldName)} title={data?.length == 0 ? "Орон тоо" : null} keyfield="staff" defaultValue={null} />
            </Col>
          </Row>
        )}
        {
          schoolid != userInfo?.schoolid && (
            <div style={{ marginTop: 24 }}>
              <FieldUploadRest visible={false} id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
            </div>
          )
        }
      </div>
    </PanelBase>
  );
};

export default PositionForm;
