import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColEdit, GridDefaultShimmer, PageHeader, ButtonDefault, ColWarn } from "dw-components";
import { APIGraphQL } from "../../../API/APIGraphQL";
import { Dialog } from "@fluentui/react";
import { Space } from "antd";
import { UserContext } from "../../../Context/UserContext";

const BudgetList = () => {

  const navigate = useNavigate();

  const { userInfo } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [columns, setColumns] = useState([
    {
      name: "Төсөвт жил",
      fieldName: "budgetName",
      minColSize: "mid",
      onRender: (record) => {
        return (
          <div>
            <ColEdit onClick={() => onDtl(record?.iD)}>
              {record.yEAR}
            </ColEdit>
          </div>
        );
      }
    },
    {
      name: "Нээсэн огноо",
      fieldName: "sTARTDATE",
      minColSize: "mid"
    },
    {
      name: "Хаах огноо",
      fieldName: "eNDDATE",
      minColSize: "mid"
    },
    {
      name: "Төлөв",
      fieldName: "sTATUSNAME",
      minColSize: "mid",
      onRender: (record) => {
        return record?.budgetSchoolStatus?.sTATUSNAME
      }
    },
    {
      name: "Үүсгэсэн",
      fieldName: "cREATED"
    }
  ]);

  const getData = async () => {
    let tData = [];
    let latestYear = 0;
    setLoading(true);
    await APIGraphQL.post("", {
      query: `query ($schoolid: Int) {
        budgetQuery {
          budgetsOpen(schoolid: $schoolid) {
            iD
            yEAR
            sTARTDATE
            eNDDATE
            sTATUSID
            sTATUSNAME
            cREATED
            budgetSchoolStatus(schoolid: $schoolid) {
              sTATUSID
              sTATUSNAME
            }
          }
        }
      }`,
      variables: {
        "schoolid": parseInt(userInfo?.schoolid)
      }
    }).then(response => {
      let tResp = response?.data?.data?.budgetQuery?.budgetsOpen;
      if (tResp?.length > 0) {
        tData = tResp;
        let tResult = tResp.filter(tResp => tResp?.sTATUSID == 2);
        if (tResult?.length == 0) {
          //setShowDialog(true)
        }
        latestYear = Math.max(...tData.map(item => item.iD));
        tData = tData.filter(item => item.iD != latestYear);
      }
    }).catch(() => {
      setLoading(false);
    });
    setLoading(false);
    setData(tData);
    setOData(tData);
  }

  useEffect(() => {
    getData();
  }, []);

  const onDtl = (pId) => {
    navigate("/budget/department/indicators/" + pId);
  }

  const onSearch = (p_data) => {
    setData(p_data);
  }
  const onExit = () => {
    setShowDialog(false);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(247, 247, 247)",
        minHeight: "90vh"
      }}
    >
      <PageHeader
        title="Нийт"
        data={oData}
        filteredData={data}
        columns={columns}
        dataCount={data?.length}
        onSearch={onSearch}
      />
      <div style={{ marginLeft: 16, marginRight: 16, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
        <GridDefaultShimmer columns={columns} data={data} loading={loading} />
      </div>
      <Dialog
        minWidth={580}
        isOpen={showDialog}
        dialogContentProps={{
          showCloseButton: true,
          title: "Төсөв"
        }}>
        <div style={{ fontWeight: 400, fontSize: 16 }}>
          <Space>
            <ColWarn show />
            Нээлттэй төсөв байхгүй байна.
          </Space>
        </div>
        <div style={{ marginTop: 30, float: "right" }}>
          <ButtonDefault onClick={onExit} text="Гарах" />
        </div>
      </Dialog>
    </div>
  );
};

export default BudgetList;