import React, { useContext, useState, useEffect } from "react";
import { ButtonDefault, FieldDate, FieldDropdown, PanelBase, LoadSpin, FieldNumberFormat, FieldText } from "dw-components";
import { message } from "antd";
import { UserContext } from "../../../../Context/UserContext";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem} from "devextreme-react/data-grid";
import { month2digits } from "../../../../Utils/SharedFunc";
import moment from "moment";

const OperationLightPanel = (props) => {

  const { userInfo } = useContext(UserContext);
  const [data, setData] = useState([]);
  
  const [initData] = useState({
    uSERCODEID: props?.usercodeid,
    dATE: !props?.date ? new Date() : props?.date,
    cOSTDATE: +(props?.costdate?.toString().substring(5, 7))
});
  const [datadetail, setDataDetail] = useState({...initData});
  const [dataEdit, setDataEdit] = useState([]);
  const [userCode, setUserCode] = useState([]);
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState(false);
  const [currentdays, setcurrentdays] = useState(0);
  const [prevData, setPrevData] = useState([]);
  const currentyear = new Date(datadetail?.dATE).getFullYear().toString();
  const dCostDate = [
    {key:1, text: currentyear + ".01"},
    {key:2, text: currentyear + ".02"},
    {key:3, text: currentyear + ".03"},
    {key:4, text: currentyear + ".04"},
    {key:5, text: currentyear + ".05"},
    {key:6, text: currentyear + ".06"},
    {key:7, text: currentyear + ".07"},
    {key:8, text: currentyear + ".08"},
    {key:9, text: currentyear + ".09"},
    {key:10, text: currentyear + ".10"},
    {key:11, text: currentyear + ".11"},
    {key:12, text: currentyear + ".12"}
  ];
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    let tData = [];
    let tDataDetail = {};
    let tUserCode = [];
    setLoading(true);
    await APIGraphQL.post("", {
        query: `{
            sharedQuery {
              usercodelist {
                key: iD
                text: cODE
              }
            }
          }
          `
    }).then(response => {
        if (response?.data?.data?.sharedQuery?.usercodelist?.length > 0) {
            tUserCode = response?.data?.data?.sharedQuery?.usercodelist;
        }
    });

    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String){
          sharedQuery {
            lightcostbyusercode(usercodeid: $usercodeid, costdate: $costdate) {
              iD
              cOSTDATE
              uSERCODEID
              uSERCODE
              mETERID
              mETERNO
              bUILDINGID
              bUILDINGNAME
              dATE
              dAY
              qTY
              tARIFF
              cOEFFICIENT
              pOWERTARIFF
              uSE
              tOTALAMOUNT
              vAT
              cREATED
              cREATEDBY
            }
            lightcostdetail(usercodeid: $usercodeid, costdate: $costdate) {
              iD
              uSERCODEID
              mETERAMOUNT
              aDDCOST
              cREATED
              cREATEDBY
              sERVICEVAT
              tVCOST
            }
          }
        }
        `,
        variables: {
            "usercodeid": parseInt(props?.usercodeid),
            "costdate": props?.costdate?.toString()
        }
      }).then(response => {
          if (response?.data?.data?.sharedQuery?.lightcostbyusercode?.length > 0) {
              tData = response?.data?.data?.sharedQuery?.lightcostbyusercode;
          }
          if (response?.data?.data?.sharedQuery?.lightcostdetail?.iD) {
            tDataDetail = response?.data?.data?.sharedQuery?.lightcostdetail;
          }
      });    

      for (let i = 0; i < tData?.length; i++) {
          tData[i].arrayIndex = i;
      }
      setUserCode(tUserCode);
      setPreviousData(props?.costdate);
      setLoading(false);
      setData(tData);
      setDataEdit(tData);
      
      if(!tDataDetail?.length) {
        tDataDetail.uSERCODEID= props?.usercodeid;
        tDataDetail.dATE= !props?.date ? new Date() : props?.date;
        tDataDetail.cOSTDATE= +(props?.costdate?.toString().substring(5, 7));
      }
      setDataDetail(tDataDetail);
}

useEffect(() => {
  getData();
}, [props?.usercodeid, props?.costdate, props?.date]);

  const fieldOnChanged = async(key, text, field, fieldname) => {
    if (field) {
      let tData = [ ...dataEdit ];
        if (field == "uSERCODE") {
          await APIGraphQL.post("", {
            query: `query ($usercodeid: Int){
                sharedQuery {
                  meterbyusercodeid(usercodeid: $usercodeid, typeid: 3) {
                    mETERID: iD
                    mETERNO
                    bUILDINGID
                    bUILDINGNAME
                    tARIFF
                    cOEFFICIENT
                    pOWERTARIFF
                    uSERCODEID
                    uSERCODE
                  }
                }
              }
              `,
              variables: {
                  "usercodeid": key
              }
        }).then(response => {
            if (response?.data?.data?.sharedQuery?.meterbyusercodeid?.length > 0) {
                tData = response?.data?.data?.sharedQuery?.meterbyusercodeid;
            }
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setData(tData);
        setDataEdit(tData);
      } else {
        if (field == "cOSTDATE") {
          setPreviousData(text);
        }
        let tData = { ...datadetail };
        tData[field] = key;
        if (fieldname) {
          tData[fieldname] = text;
        }
        setDataDetail(tData);
      }
    }
  }

  const gridfieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
      let tDataEdit = [...dataEdit];
      tDataEdit[pIndex][pField] = pValue;
      if (pFieldName) {
          tDataEdit[pIndex][pFieldName] = pNameValue;
      }

      if (pField == "qTY") {
        if (prevData?.length > 0) {
          let tIndex = prevData?.map(r => r.mETERID).indexOf(tDataEdit[pIndex]["mETERID"]);
          let prevQty = 0;
          if(tIndex >= 0)
            prevQty = prevData[tIndex].qTY;
          tDataEdit[pIndex]["uSE"] = tDataEdit[pIndex]["cOEFFICIENT"] * (pValue - prevQty);
          tDataEdit[pIndex]["tOTALAMOUNT"] = (tDataEdit[pIndex]["uSE"] / currentdays / 12 * tDataEdit[pIndex]["pOWERTARIFF"] + tDataEdit[pIndex]["uSE"] * tDataEdit[pIndex]["tARIFF"]) * 1.1;
        }
      }
      tDataEdit[pIndex].edit = true;
      setDataEdit(tDataEdit);
  }

  const setPreviousData = async(text) => {
    let tData = {...datadetail};
    tData.cOSTDATE = (+(text?.toString().substring(5, 7)));
    let currentDate = moment(new Date(tData?.dATE));
    let currentmonth = text + ".01";
    let currentdate = new Date(currentmonth);
    let lastmonth = month2digits(currentdate.getMonth());
    var costdate = currentdate.getFullYear() + "." + lastmonth;
    let tPrevData = [];
    let prevs = 0;
    await APIGraphQL.post("", {
      query: `query ($usercodeid: Int, $costdate: String){
          sharedQuery {
            lightcostbydate(usercodeid: $usercodeid, costdate: $costdate) {
              qTY
              dATE
              mETERID
            }
          }
        }
        `,
        variables: {
          "usercodeid": tData?.uSERCODEID,
          "costdate": costdate?.toString()
        }
    }).then(response => {
        if (response?.data?.data?.sharedQuery?.lightcostbydate?.length > 0) {
          tPrevData = response?.data?.data?.sharedQuery?.lightcostbydate;
          let prevDate = moment(new Date(tPrevData[0].dATE));
          prevs = currentDate.diff(prevDate, 'days');
        }
    });
    setPrevData(tPrevData);
    setcurrentdays(prevs);
  }

  const onSave = async () => {
    let tDataEdit = dataEdit?.filter(r => r.edit);
    let tdata = {...datadetail};
    setLoadingSave(true);
    let tSuccess = false;
    if (!tdata.iD) {
      tdata.iD = 0;
      tdata.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
      tdata.cREATEDBY = userInfo?.username;
    }
      let selecteddate = tdata.dATE;
      delete tdata.dATE;
      let tIndex =  dCostDate?.map(r => r.key).indexOf(tdata?.cOSTDATE);
      if(tIndex >= 0)
        tdata.cOSTDATE = dCostDate[tIndex].text;

      await APIGraphQL.post("", {
          query: `mutation ($data: LightDetailCostInputType) {
              sharedMutation {
                  addLightDetailCost(data: $data) {
                      iD
                  }
              }
          }`,
          variables: {
              "data": tdata
          }
      }).then(response => {
          if (response?.data?.data?.sharedMutation?.addLightDetailCost?.iD > 0) {
            tSuccess = true;
          }
      }).catch(() => {
          setLoadingSave(false);
      });

    if (tDataEdit?.length > 0) {
      for (let i = 0; i < tDataEdit.length; i++) {
          let tR = { ...tDataEdit[i] };
          if (!isNaN(parseFloat(tR.tOTALAMOUNT))) {
            tR.vAT = tR.tOTALMOUNT / 10;
            tR.aMOUNT = tR.tOTALMOUNT - tR.vAT;
          }
          tR.dATE = selecteddate;
          tR.cOSTDATE = tdata.cOSTDATE;
          
          // let tIndex =  dCostDate?.map(r => r.key).indexOf(tdata?.cOSTDATE);
          // if(tIndex >= 0)
          //   tR.cOSTDATE = dCostDate[tIndex].text;

          tR.dAY = currentdays;
          delete tR.arrayIndex;
          delete tR.edit;
          if (!tR.iD) {
              tR.iD = 0;
              tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
              tR.cREATEDBY = userInfo?.username;
          }
          tDataEdit[i] = tR;
      }
        if(tSuccess) {
          await APIGraphQL.post("", {
              query: `mutation ($data: [LightCostInputType]) {
                  sharedMutation {
                      addLightCost(data: $data) {
                          iD
                      }
                  }
              }`,
              variables: {
                  "data": tDataEdit
              }
          }).then(response => {
              if (response?.data?.data?.sharedMutation?.addLightCost?.length > 0) {
                  message.success("Амжилттай");
              }
          }).catch(() => {
              setLoadingSave(false);
          });
        }
    }

    setLoadingSave(false);
    await props?.afterSave();
    props?.onDismiss();
  }

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    setCheckRule(false);
    setData({});
  }

  const onDelete = async () => {
      let tSuccess = false;
      await APIGraphQL.post("", {
          query: `mutation deleteLightCost($usercodeid: Int, $costdate: String) {
                  sharedMutation {
                    deleteLightCost(usercodeid: $usercodeid, costdate: $costdate)
                  }
                }`,
            variables: {
              "usercodeid": parseInt(props?.usercodeid),
              "costdate": props?.costdate.toString()
          },
      }).then((response) => {
          if (response?.data?.data?.sharedMutation?.deleteLightCost == 1) {
              tSuccess = true;
              if (props.afterSave) {
                  props.afterSave();
              }
          }
          if (tSuccess) {
              message.success("Амжилттай");
              onDismiss();
          }
      });
  };

  return (
    <PanelBase
      headerText="Гэрэл цахилгааны мэдээлэл оруулах"
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      warning={warning}
      PanelType="medium"
      delete={props?.usercodeid > 0 ? true : false}
      onDelete={onDelete}
      buttons={
        loading ? <LoadSpin /> :
          <ButtonDefault
            text="Хадгалах"
            onClick={onSave}
          />
      }
    >
      <div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Хэрэглэгчийн код" keyfield="uSERCODE" defaultValue={datadetail?.uSERCODEID} checkreq={checkRule} data={userCode} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDate required onChanged={fieldOnChanged} title="Заалт авсан огноо" keyfield="dATE" checkreq={checkRule} defaultValue={datadetail?.dATE} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required onChanged={fieldOnChanged} title="Заалт оруулах сар" keyfield="cOSTDATE" defaultValue={datadetail?.cOSTDATE} checkreq={checkRule} data={dCostDate} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required onChanged={fieldOnChanged} title="Мед.Хүр.төлбөр" keyfield="aDDCOST" defaultValue={datadetail?.aDDCOST} />
        </div>            
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required onChanged={fieldOnChanged} title="НӨАТ(үйлч)" keyfield="sERVICEVAT" defaultValue={datadetail?.sERVICEVAT} />
        </div>                   
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required onChanged={fieldOnChanged} title="ТВ-ийн хураамж" keyfield="tVCOST" defaultValue={datadetail?.tVCOST} />
        </div>                   
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required onChanged={fieldOnChanged} title="Тоолуургүй хэрэглээ" keyfield="mETERAMOUNT" defaultValue={datadetail?.mETERAMOUNT} />
        </div>                           
        {loading ? (<LoadSpin />) : (
                <div style={{ marginTop: 24 }}>
                    <DataGrid
                        dataSource={data}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        showRowLines
                        showBorders
                    >
                        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                        <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                        <Column
                            width={150}
                            dataField="bUILDINGNAME"
                            caption="Обьект"
                            alignment='center'
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.bUILDINGNAME} />
                                );
                            }} />
                        <Column
                            width={100}
                            dataField="mETERNO"
                            caption="Хэмжүүрийн дугаар"
                            alignment='center'
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.mETERNO} />
                                );
                            }} />
                        <Column
                            dataField="qTY"
                            caption="Заалт"
                            alignment='center'
                            width={80}
                            format="fixedPoint"
                            cellRender={(item) => {
                              return (
                                  <FieldNumberFormat onChanged={(pValue) => gridfieldOnChanged(item?.data?.arrayIndex, item?.data?.iD, pValue, "qTY")} defaultValue={item.data?.qTY} />
                              );
                          }} />
                            <Column
                                dataField="uSE"
                                caption="Хэрэглээ Ка.ц"
                                width={80}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.uSE} />
                                    );
                                }} />
                            <Column
                                dataField="tOTALAMOUNT"
                                caption="НӨАТ-тэй дүн"
                                minwidth={80}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.tOTALAMOUNT} />
                                    );
                                }} />
                        <Summary>
                            <TotalItem
                                column="№"
                                displayFormat="Нийт:"
                            />
                            <TotalItem
                                column="tOTALAMOUNT"
                                summaryType="sum"
                                valueFormat="#,##0.00"
                                alignment="right"
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGrid>
                </div>
            )}
      </div>
    </PanelBase>
  );
}

export default OperationLightPanel;
