import React, { useState, useEffect, useContext } from "react";
import { ColEdit, PageHeader, LoadScreen } from "dw-components";
import { API } from "../../../API/API";
import { TreeList } from "devextreme-react";
import { IconButton } from "@fluentui/react";
import { Column, Scrolling, Selection } from "devextreme-react/tree-list";
import PurchasePanel from "./Panels/PurchasePanel";
import { UserContext } from "../../../Context/UserContext";
import { ReactComponent as GridAttachIcon } from '../../../svg/gridAttach.svg';
import FileAttach from '../BudgetDtl/FileAttach/FileAttach';

const Purchase = (props) => {
    const { userInfo } = useContext(UserContext);

    const [showNew, setShowNew] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    const [attachId, setAttachId] = useState();
    const [attachName, setAttachName] = useState();

    const [selectedPurchaseId, setSelectedPurchaseId] = useState();

    const getData = async () => {
        setLoading(true);
        let tData = [];

        if (props?.itemId) {
            await API.get("/api/budget/budgetPurchaseDtl?id=" + props?.itemId + "&schoolid=" + userInfo.schoolid + "&type=" + props?.urlType)
                .then((response) => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        tData = response?.data?.retData;
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
            setData(tData);
        }
        setLoading(false);
    };

    // Get list data from firebase
    useEffect(async () => {
        getData();
    }, [props?.itemId]);

    const onNew = () => {
        setSelectedPurchaseId(null);
        setShowNew(true);
    };

    const onDismiss = () => {
        setSelectedPurchaseId(null);
        setShowNew(false);
    };

    const onEdit = (cData) => {
        setSelectedPurchaseId(cData?.row?.data?.realrecordid);
        setShowNew(true);
    };

    const onAttach = (pAttachId, pAttachName) => {
        setAttachId(pAttachId);
        setAttachName(pAttachName);
    }

    if (loading) {
        return <LoadScreen />
    }

    return (
        <div>
            <div style={{ margin: 16 }}>
                <PageHeader
                    title="Бусад"
                    buttonTitle={props?.disable ? null : "Нэмэх" }
                    onButtonClick={props?.disable ? null : onNew }
                    data={data}
                    dataCount={data.length} 
                />
                <TreeList
                    id="id"
                    dataSource={data}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    keyExpr="id"
                    parentIdExpr="parentid"
                    showRowLines
                    showBorders
                    rowAlternationEnabled={true}
                    autoExpandAll={true}
                >
                    <Scrolling columnRenderingMode="standard" mode="standard" />
                    <Selection mode="single" />
                    <Column dataField="name" caption="Эргэлтийн бус хөрөнгийн төрөл, нэр" minWidth={400} 
                          cellRender={item => {
                            if(item.data?.ischild <= 1) {
                                return (
                                    <div style={{fontWeight:700}}>{item.data?.name}
                                    </div>
                                    );
                            } else {
                                return (
                                    <div style={{fontWeight:400}}>{item.data?.name}
                                    </div>
                                    );
                            }
                        }}
                    />
                    <Column dataField="qty" caption="Тоо хэмжээ" alignment="right" format="fixedpoint"/>
                    <Column dataField="unitname" caption="Хэмжих нэгж" alignment="right" />
                    <Column dataField="unitprice" caption="Нэг бүрийн үнэ" alignment="right" format="#,##0.00"/>
                    <Column dataField="totalbudget" caption="Нийт төсөвт өртөг" format="#,##0.00" minWidth={200} />
                    <Column dataField="currentyearprice" caption="Төсөвт онд худалдан авах (нийт) үнийн дээд хязгаар (төгрөг)" alignment="right" format="#,##0.00" minWidth={200}/>
                    <Column dataField="pastyearC2PRICE" caption="Санхүүжилт хийсэн дүн өмнөх оны эцэс" format="#,##0.00" minWidth={200} />
                    <Column
                        dataField="ismovable"
                        caption="Он дамжих эсэх"
                        cellRender={(item) => {
                            if (item?.data?.ismovable) {
                                if (item?.data?.ismovable == 1) {
                                    return "Тийм";
                                } else {
                                    return "Үгүй";
                                }
                            }

                            return null;
                        }}
                    />
                    <Column dataField="note" caption="Тайлбар" minWidth={400} />
                    <Column dataField="attachfiles" caption="Файл" width={110}
                            cellRender={(item) => {
                                if (item?.data?.ischild == 2) {
                                    if (item?.data?.attachfiles > 0) {
                                        return (
                                            <ColEdit onClick={() => onAttach(item?.data?.realrecordid, item?.data?.name)}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ marginTop: 4 }}>
                                                        <GridAttachIcon />
                                                    </div>
                                                    <div style={{ marginLeft: 4, marginTop: -2 }}>
                                                        {item?.data?.attachfiles}
                                                    </div>
                                                </div>
                                            </ColEdit>
                                        );
                                    } else if (!props?.disable) {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <ColEdit onClick={() => onAttach(item?.data?.realrecordid, item?.data?.name)}>Файл хавсаргах</ColEdit>
                                            </div>
                                        );
                                    }
                                }

                                return null;
                            }} />
                    <Column dataField="output" caption="Хүрэх үр дүн /outputs/"/>
                    <Column dataField="outcome" caption="Хүрэх үр дагавар /outcome/"/>
                    <Column dataField="impact" caption="Үр нөлөө /Impact/"/>
                    <Column dataField="sptopprname" caption="Тэргүүлэх чиглэл"/>
                    <Column dataField="spgoalname" caption="Зорилт"/>
                    <Column dataField="spstrname" caption="Стратеги"/>
                    <Column dataField="spindname" caption="Хэмжих үзүүлэлт"/>
                    <Column dataField="kpiname" caption="Гүйцэтгэлийн түлхүүр үзүүлэлт"/>
                    <Column caption="Үйлдэл" cellRender={item=>{
                        if(item?.data?.ischild == 2 && !props?.disable){
                            return <IconButton iconProps={{iconName: "edit"}} onClick={() => onEdit(item)} style={{ height: 16, width: 16, marginLeft: 8}} />
                        }
                    }}>
                    </Column>
                </TreeList>
            </div>
            <FileAttach disable={props?.disable} budgetid={props?.budgetid} type="purchase" id={attachId} name={attachName} isOpen={attachId ? true : false} onDismiss={() => { setAttachId(); }} afterSave={getData} />
            <PurchasePanel
                itemId={props?.itemId}
                secondaryId={props?.secondaryId}
                type={props?.urlType}
                id={selectedPurchaseId}
                isOpen={showNew}
                onDismiss={onDismiss}
                afterSave={getData}
            />
        </div>
    );
};

export default Purchase;
