import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message, Upload } from 'antd';
import { ReactComponent as UploadIcon } from '../../svg/uploadIcon.svg';
import { ReactComponent as DownloadIcon } from '../../svg/downloadIcon.svg';
import { ReactComponent as FileDeleteIcon } from '../../svg/fileDeleteIcon.svg';
import { API } from '../../API/API';
import FileDownload from 'js-file-download';
import { ProgressIndicator } from '@fluentui/react';
import { getBaseURL } from '../../API/APIConfig';

const FieldDocumentAttach = (props) => {

    const uploadFileFormat = [
        {
            filetype: ".txt",
            name: "text/plain"
        }, {
            filetype: ".pdf",
            name: "application/pdf"
        },
        {
            filetype: ".docx",
            name: "application/vnd.ms-word"
        }, {
            filetype: ".xls",
            name: "application/vnd.ms-excel"
        }, {
            filetype: ".xlsx",
            name: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        {
            filetype: ".png",
            name: "image/png"
        }, {
            filetype: ".jpg",
            name: "image/jpeg"
        }, {
            filetype: ".jpeg",
            name: "image/jpeg"
        }, {
            filetype: ".gif",
            name: "image/gif"
        }, {
            filetype: ".csv",
            name: "text/csv"
        }
    ];

    // const [token, setToken] = useState();
    const [warning, setWarning] = useState();
    const [loading, setLoading] = useState(false);

    useState(() => {
    }, []);

    const uploadProps = {
        //action: '/api/budget/uploadDocument',
        multiple: false,
        data: {},
        headers: {
            // Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
        onStart(file) {
            setLoading(true);
        },
        onSuccess(res, file) {
            setLoading(false);
            // if (props?.afterSave) {
            //     props?.afterSave();
            // }
        },
        onError(err) {
            setLoading(false);
            message.error('onError', err);
        },
        onProgress({ percent }, file) {
        },
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
        }) {

            setWarning();
            let vCheck = true;
            let fileExt = file?.name?.split('.').pop();

            if (uploadFileFormat.map(r => r.filetype).indexOf("." + fileExt?.toString()) < 0) {
                vCheck = false;
                setWarning("Зөвшөөрөгдсөн файлын төрөл биш байна.");
            }

            if (props.filetype && props.filetype != fileExt) {
                vCheck = false;
                setWarning("Зөвхөн " + props.filetype + " файл оруулах боломжтой.");
            }

            if (vCheck) {
                if (props.afterUpload) {
                    props.afterUpload(
                        {
                            id: null,
                            filename: file?.name
                        }
                    , file);
                }
                onSuccess();
            } else {
                setLoading(false);
            }
            return {
                abort() {
                }
            };
        },
    };

    let vColor = { fontWeight: 600 };
    if (props.gray) {
        vColor = {
            color: "#6A6D70",
            fontWeight: 400
        };
    }

    const onDownload = async (pId, pName) => {
        if (pId && pName) {
            await API.get('/api/budget/downloadDocument?type=' + props.keyfield + "&name=" + pName + "&documentid=" + props?.documentid, {
                'responseType': 'blob'
            }).then(response => {
                if (response.status == 200) {
                    FileDownload(response.data, pName);
                }
            });
        }
    }

    const onDelete = async (pId) => {
        if (pId) {
            await API.post('/api/budget/deleteDocumentAttach?id=' + pId).then(response => {
                if (response.status == 200) {
                    if (props?.afterSave) {
                        props?.afterSave();
                    }
                }
            });
        }
    }

    if (loading) {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: 14, ...vColor }}>{props.title}</div>{props.required && (<div style={{ color: "#ff0000" }}>*</div>)}
                </div>
                <ProgressIndicator />
            </div>
        );
    }

    return (
        <div>
            {!props?.disable && 
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: 14, ...vColor }}>{props.title}</div>{props.required && (<div style={{ color: "#ff0000" }}>*</div>)}
                </div>
                
                <div style={{ marginTop: 6, height: 30, border: '1px solid #DDDDDD', display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
                    <div style={{ marginRight: 8, height: 20 }}>
                        <UploadIcon />
                    </div>
                    <Upload
                        disabled={props?.disable ? true : false}
                        {...uploadProps}
                        accept={props.filetype ? ("." + props.filetype) : ("*")}
                    >
                        <div style={{ fontSize: 14, color: "#0358A7", cursor: 'pointer' }}>Энд дарж оруулна уу.</div>
                    </Upload>
                </div>
            </>}

            <div style={{ marginTop: 24 }}>
                {props?.defaultValue?.length > 0 && (
                    props?.defaultValue?.map((r, index) => {
                        return (
                            <div key={index} style={{ marginTop: 8, height: 30, border: '1px solid #DDDDDD', display: 'flex', alignItems: 'center', paddingLeft: 16, justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <div style={{ marginRight: 8, height: 20 }}>
                                        <DownloadIcon />
                                    </div>
                                    <div onClick={() => onDownload(r?.id, r?.filename)} style={{ fontSize: 14, color: "#0358A7" }}>{r?.filename}</div>
                                </div>
                                {!props?.disable && 
                                <div onClick={() => onDelete(r?.id)} style={{ marginRight: 16, cursor: 'pointer' }}>
                                    <FileDeleteIcon />
                                </div>}
                            </div>
                        );
                    })
                )}
            </div>
            {warning ? (
                <div style={{ color: 'rgb(164, 38, 44)', fontSize: 12, fontWeight: 400, paddingTop: 5 }}>
                    {warning}
                </div>
            ) : null}
            {(props.required && !props?.defaultValue?.id && props.checkreq) ? (
                <div style={{ color: 'rgb(164, 38, 44)', fontSize: 12, fontWeight: 400, paddingTop: 5 }}>
                    {props.title + (props.lang == "en" ? "" : " талбарын утга хоосон байна.")}
                </div>
            ) : null}
        </div>
    );
}

export default FieldDocumentAttach;