export const ConstCss = {
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  flexCenter: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  textNormal: {
    fontSize: 14,
    color: "#212121",
  },
};

export const Color = {
  white: "#ffffff",
  black: "#000000",
  primary: "#0358A7",
  grayBackground: "#F7F7F7",
  grayHeaderBack: "#F2F2F2",
  backText: "#212121",
  blue: "#0358A7",
  blueBackground: "#0358A7",
  yellow: "#FFC700",
  gray: "#828282",
  graySecond: "#6A6D70",
  grayText: "#605E5C",
  blackSecond: "#32363A",
  red: "#ff0000",
  grayNoData: "#BABABA",
  greyTableText: "#201F1E",
  warn: "#E9730C",
  tagPrimary: "#CAE4F3",
  tagSecondary: "#E9E9E9",
  grayButton: "#CCCCCC",
  blueLink: "#0854A0",
  blackThird: "#373429",
};

export const uploadFileFormat = [
  {
    filetype: ".txt",
    name: "text/plain",
  },
  {
    filetype: ".pdf",
    name: "application/pdf",
  },
  {
    filetype: ".docx",
    name: "application/vnd.ms-word",
  },
  {
    filetype: ".xls",
    name: "application/vnd.ms-excel",
  },
  {
    filetype: ".xlsx",
    name: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  {
    filetype: ".png",
    name: "image/png",
  },
  {
    filetype: ".jpg",
    name: "image/jpeg",
  },
  {
    filetype: ".jpeg",
    name: "image/jpeg",
  },
  {
    filetype: ".gif",
    name: "image/gif",
  },
  {
    filetype: ".csv",
    name: "text/csv",
  },
];


export const leftFilterData = {
  hr_plan: [
    {
      'key': '2022',
      'text': '2022'
    },
    {
      'key': '2021',
      'text': '2021'
    },
    {
      'key': '2020',
      'text': '2020'
    },
    {
      'key': "degree",
      'text': "Эрдмийн зэрэг"
    },

  ]
}