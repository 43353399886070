export const GENDER = [
 {
   'key': 1,
   'text': 'Эр'
 },
 {
   'key': 2,
   'text': 'Эм'
 }
];
