import { Pivot, PivotItem } from "@fluentui/react";
import React, { useState } from "react";
import BudgetReport from "./PivotItems/BudgetReport";
import { useLocation } from "react-router-dom";


const ReportDetails = () => {
  const [data, setData] = useState({
    vendor_id: null,
  });

  const { state } = useLocation();
  const { budgetId } = state;

  return (
    <div style={{ paddingTop: 48 }}>
      <Pivot
        defaultSelectedKey="0"
        styles={{
          linkIsSelected: {
            "&::before": {
              backgroundColor: "#0358A7",
              height: 3,
            },
          },
          link: {
            height: 48,
          },
          root: {
            height: 48,
            float: "left",
            paddingLeft: 16,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            textAlignLast: "justify",
          },
        }}
      >
        <PivotItem headerText="Төсөв">
            <BudgetReport budgetId={budgetId}></BudgetReport>
        </PivotItem>
        <PivotItem headerText="Төсвийн гүйцэтгэл">
          {/* <h1>Тө</h1> */}
          {/* <BudgetPerformanceReport></BudgetPerformanceReport> */}
        </PivotItem>
        <PivotItem headerText="Батлагдсан маягт">
          {/* <h1>Батлагдсан маягт</h1> */}
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default ReportDetails;
