import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldNumberFormat, LoadSpin } from "dw-components";
import { message } from "antd";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import moment from "moment";
import { BudgetYear } from "../../../../Const/BudgetYear";
import { UserContext } from "../../../../Context/UserContext";

const FuelNormPanel = (props) => {
  const [data, setData] = useState({});
  const [dVehicle, setDVehicle] = useState([]);
  const [dECat, setDECat] = useState([]);

  const [checkRule, setCheckRule] = useState(false);
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);


  const getData = async () => {
    setLoading(true);
    let tDVehicle = [];
    let tDECat = [];
    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getVehicleList {
            key: nUMBER
            text: nUMBER   
          }
          vehicleEconomyCategorys{
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.getVehicleList;
      let tECResp = response?.data?.data?.sharedQuery?.vehicleEconomyCategorys;
      if (tResp?.length > 0) {
        tDVehicle = tResp;
      }
      if (tECResp?.length > 0) {
        tDECat = tECResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDVehicle(tDVehicle);
    setDECat(tDECat);

    let tData = {};
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getVehicleFuelNorm(id: $id) {
              iD
              eCONOMYCATEGORYID
              eCONOMYCATEGORYNAME
              yEAR
              vEHICLEID
              mARK
              tYPEID
              tYPENAME
              cATEGORYID
              cATEGORYNAME
              nUMBER
              fUELTYPEID
              fUELTYPENAME
              oILNORM
              oILPRICE
              fUELNORM
              fUELDAYNORM
              fUELPRICE
              oWNERID
              oWNERNAME
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getVehicleFuelNorm;
        if (tResp?.iD) {
          tData = tResp;
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);



  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      if (field == "nUMBER") {
        await APIGraphQL.post("", {
          query: `query ($code: String) {
            sharedQuery {
              getVehicleByNumber(code: $code) {
                cATEGORYID
                cATEGORYNAME
                tYPEID
                tYPENAME
                vEHICLEID: iD
                mARK
                fUELTYPEID
                fUELTYPENAME
                vehicleFuelNorm{
                  oILNORM
                  oILPRICE
                }
              }
            }
          }`,
          variables: {
            "code": key
          }
        }).then(response => {
          let tResp = response?.data?.data?.sharedQuery?.getVehicleByNumber;
          if (tResp) {
            tData = { ...tData, ...tResp, ...tResp?.vehicleFuelNorm };
          }
        }).catch(() => {
          setLoading(false);
        });
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.cATEGORYNAME ||
      !data?.tYPENAME ||
      !data?.nUMBER ||
      !data?.mARK ||
      !data?.fUELTYPENAME ||
      !data?.yEAR ||
      !data?.oWNERNAME ||
      !data?.eCONOMYCATEGORYID ||
      !data?.fUELNORM ||
      !data?.fUELDAYNORM ||
      !data?.fUELPRICE) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;

      delete tData.vehicleFuelNorm;

      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
        tData.iSACTIVE = 1;
      }
      setLoadingSave(true);

      await APIGraphQL.post("", {
        query: `mutation ($data: VehicleFuelNormInputType) {
          sharedMutation {
            addVehicleFuelNorm(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addVehicleFuelNorm?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteVehicleFuelNorm($id: Int) {
        sharedMutation {
          deleteVehicleFuelNorm(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteVehicleFuelNorm == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }


  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div className="add-product">
        <div style={{ marginTop: 13 }}>
          <FieldDropdown loading={loading} required onChanged={fieldOnChanged} title="Улсын дугаар" keyfield="nUMBER" defaultValue={data.nUMBER} data={dVehicle} />
        </div>
        <div style={{ marginTop: 25 }}>
          <FieldText disable={true} onChanged={fieldOnChanged} title="Тээврийн хэрэгслийн ангилал" keyfield="cATEGORYID" keyfieldname="cATEGORYNAME" defaultValue={data.cATEGORYNAME} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText disable={true} onChanged={fieldOnChanged} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPENAME} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText disable={true} onChanged={fieldOnChanged} title="Марк" keyfield="mARK" defaultValue={data.mARK} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText disable={true} onChanged={fieldOnChanged} title="Түлш" keyfield="fUELTYPEID" keyfieldname="fUELTYPENAME" defaultValue={data.fUELTYPENAME} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown loading={loading} required onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" checkreq={checkRule} defaultValue={data.eCONOMYCATEGORYID} data={dECat} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown loading={loading} required onChanged={fieldOnChanged} title="Төсвийн жил" keyfield="yEAR" defaultValue={data.yEAR} data={BudgetYear} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Тээврийн хэрэгслийн норм (л)" keyfield="fUELNORM" defaultValue={data.fUELNORM} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Өдрийн норм (л)" keyfield="fUELDAYNORM" defaultValue={data.fUELDAYNORM} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Шатахууны үнэ" keyfield="fUELPRICE" defaultValue={data.fUELPRICE} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText loading={loading} required onChanged={fieldOnChanged} title="Эд хариуцагч" keyfield="oWNERNAME" defaultValue={data.oWNERNAME} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Тосны норм" keyfield="oILNORM" defaultValue={data.oILNORM} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat loading={loading} required onChanged={fieldOnChanged} title="Тосны үнэ" keyfield="oILPRICE" defaultValue={data.oILPRICE} checkreq={checkRule} />
        </div>
      </div>
    </PanelBase>
  );
};

export default FuelNormPanel;
