export const getBaseURL = () => {
    let apiBaseUrl = 'https://localhost:7010';

    if (window?.location?.hostname != 'localhost') {
        apiBaseUrl = window.location.protocol.concat("//").concat(window.location.hostname).concat(":8081");
    }
    return apiBaseUrl;
}

export const getRedirectUrl = () => {
    let redirectUrl = 'http://localhost:3000/callback';

    if (window?.location?.hostname != 'localhost') {
        redirectUrl = window.location.protocol.concat("//").concat(window.location.hostname).concat("/callback");
    }
    return redirectUrl;
}
