export const UserType = [
    {
        key: 1,
        text: "Захиргаа, төвлөрсөн сан"
    },
    {
        key: 2,
        text: "Бүрэлдэхүүн болон салбар сургууль"
    },
    {
        key: 5,
        text: "Бие даасан нэгж"
    },
    {
        key: 6,
        text: "Орон нутгийн салбар сургууль"
    }
];