export const VehicleCategory = [
    {
        key: 1,
        text: "A"
    },
    {
        key: 2,
        text: "B"
    },
    {
        key: 3,
        text: "C"
    },
    {
        key: 4,
        text: "D"
    },
    {
        key: 5,
        text: "E"
    },
    {
        key: 6,
        text: "M"
    }
];