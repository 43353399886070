import React, { useState, useEffect } from "react";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import {
  ButtonDefault,
  FieldDropdown,
  FieldTextArea,
  PanelBase,
  Row,
  Col,
  ButtonSecondary,
} from "dw-components";
import { IconButton } from "@fluentui/react";
import FieldUpload from "../../../Components/FieldUpload/FieldUpload";
import FieldNumberFormat from "../../../Components/FieldNumberFormat/FieldNumberFormat";
import FieldTextBorderless from "../../../Components/FieldTextBorderless/FieldTextBorderless";
import FieldDropdownBorderless from "../../../Components/FieldDropdownBorderless/FieldDropdownBorderless";
import { BRANCHSCHOOLS } from "../../../Const/BranchSchools";
import { DEPARTMENTS } from "../../../Const/Departments";
import { message } from "antd";
import { MessageConst } from "../../../Const/MessageConst";
import { async } from "@firebase/util";

// Нормативт зардал
const FuelNormPanel = (props) => {
  const userRole = {};
  const [data, setData] = useState({
    calculateMethodType: "fuelNorm",
    indicatorId: props.indicatorData.indicatorId,
    category: props.indicatorData.category,
    distribution: "month",
  });

  const [vehicles, setVehicles] = useState([]);
  const [vehicleNumbers, setVehicleNumbers] = useState([]);
  const [vehicleOwners, setVehicleOwners] = useState([]);
  const [vehiclesData, setVehiclesData] = useState({});
  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);

  const [checkRule, setCheckRule] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const nextStep = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.branchSchool) {
      isValid = false;
      setWarning("Салбар сургууль" + MessageConst.fieldRequired);
    } else if (data.branchSchool != "ALL" && !data.department) {
      isValid = false;
      setWarning("Байгууллага нэгж" + MessageConst.fieldRequired);
    } else if (!data.vehicles || data.vehicles.length == 0) {
      isValid = false;
      setWarning("Тээврийн хэрэгсэл сонгоогүй байна");
    }

    if (isValid) {
      setCurrentStep((r) => r + 1);
      await changeDistribution(data.distribution);
    }
  };

  const prevStep = () => {
    setCurrentStep((r) => r - 1);
  };

  const changeDistribution = async (key) => {
    let tempData;
    switch (key) {
      case "month":
        tempData = { ...data };
        let perMonth = totalBudgetAmount / 12;
        setData({
          ...tempData,
          distribution: key,
          month1: perMonth,
          month2: perMonth,
          month3: perMonth,
          month4: perMonth,
          month5: perMonth,
          month6: perMonth,
          month7: perMonth,
          month8: perMonth,
          month9: perMonth,
          month10: perMonth,
          month11: perMonth,
          month12: perMonth,
        });
        break;
      case "season":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month10;
        delete tempData.month11;

        let perSeason = totalBudgetAmount / 4;

        setData({
          ...tempData,
          distribution: key,
          month3: perSeason,
          month6: perSeason,
          month9: perSeason,
          month12: perSeason,
        });
        break;
      case "year":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month3;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month6;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month9;
        delete tempData.month10;
        delete tempData.month11;
        delete tempData.month12;
        setData({
          ...tempData,
          distribution: key,
        });
        break;
      default:
        break;
    }
  };

  const FuelNormRef = collection(db, "fuelNorm");

  const getVehicles = async () => {
    const response = await getDocs(FuelNormRef);

    if (!response.empty) {
      let availableVehicles = response.docs.map(
        (doc) => doc.data().vehicleMark
      );

      let uniq = [...new Set(availableVehicles)];

      setVehicles(uniq.map((item) => ({ key: item, text: item })));
      return availableVehicles;
    } else {
      return [];
    }
  };

  const getVehicleByMark = async (mark) => {
    const vehicleQuery = query(FuelNormRef, where("vehicleMark", "==", mark));
    const response = await getDocs(vehicleQuery);
    if (!response.empty) {
      let availableVehicles = response.docs.map(
        (doc) => doc.data().vehicleNumber
      );

      let uniq = [...new Set(availableVehicles)];

      setVehicleNumbers(uniq.map((item) => ({ key: item, text: item })));
      return availableVehicles;
    } else {
      return [];
    }
  };

  const getVehicleByNumber = async (number) => {
    const vehicleQuery = query(
      FuelNormRef,
      where("vehicleNumber", "==", number)
    );
    const response = await getDocs(vehicleQuery);
    if (!response.empty) {
      let availableVehicles = response.docs.map((doc) => ({
        key: doc.id,
        text: doc.data().owner,
      }));

      let tVehicles = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const vData = tVehicles.reduce(
        (obj, current) => ({ ...obj, [current.id]: current }),
        {}
      );

      setVehiclesData(vData);
      setVehicleOwners(availableVehicles);
      return availableVehicles;
    }
  };

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;

      if (field == "branchSchool") {
        tData = {};
        tData[field] = key;
      }

      if (field == "distribution") {
        await changeDistribution(key);
      }

      setData(tData);
    }
  };

  const itemFieldOnChanged = async (pRecord, pIndex, pField, pValue) => {
    let tData = { ...data };

    if (pField == "workDays") {
      const tValue = parseInt(pValue) || 0;
      const tFuelPrice =
        parseFloat(tData["vehicles"][pIndex]["fuelPrice"]) || 0;
      const tDayNorm =
        parseFloat(tData["vehicles"][pIndex]["vehicleDayNorm"]) || 0;

      tData["vehicles"][pIndex][pField] = tValue;
      tData["vehicles"][pIndex]["totalPrice"] = tValue * tFuelPrice * tDayNorm;

      await calcTotal(tData.vehicles);
    }

    setData(tData);
  };

  const itemSelectOnChanged = async (key, text, field) => {
    let tData = { ...data };

    if (!tData.vehicles) {
      tData.vehicles = [];
    }

    if (field == "vehicleMark") {
      await getVehicleByMark(key);
    }

    if (field == "vehicleNumber") {
      await getVehicleByNumber(key);
    }

    if (field == "owner") {
      let selectedVehicle = JSON.parse(JSON.stringify(vehiclesData[key]));
      selectedVehicle.workDays = selectedVehicle.workDays || 0;
      selectedVehicle.vehicleDayNorm = selectedVehicle.vehicleDayNorm || 0;
      selectedVehicle.fuelPrice = selectedVehicle.fuelPrice || 0;
      selectedVehicle.totalPrice =
        selectedVehicle.workDays *
        selectedVehicle.fuelPrice *
        selectedVehicle.vehicleDayNorm;
      tData.vehicles.push(selectedVehicle);
    }

    await calcTotal(tData.vehicles);
    setData(tData);
  };

  const calcTotal = async (assets) => {
    let totalAmount = 0;
    assets.map((item) => {
      totalAmount += item.totalPrice;
    });

    setTotalBudgetAmount(totalAmount);
  };

  const createMethod = async (payload) => {
    try {
      props.setTempData({
        ...payload,
        type: props.type,
        userId: userRole.userid,
        totalBudgetAmount: totalBudgetAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSave = async () => {
    setIsDisabled(true);
    await createMethod(data);
    props.onDismiss();
    setIsDisabled(false);
  };

  const onDelete = async (pIndex) => {
    let tData = { ...data };
    tData.vehicles.splice(pIndex, 1);

    await calcTotal(tData.vehicles);

    setData(tData);
  };

  useEffect(async () => {
    await getVehicles();
    if (props?.data) {
      setData({ ...props.data, ...data });
      setTotalBudgetAmount(
        parseFloat(props.data.totalBudgetAmount.replace(/,/g, ""))
      );
    }

    if (userRole.role == "DEPARTMENT" && !data.branchSchool) {
      let tData = { ...props?.data, ...data };
      tData.branchSchool = userRole.companyname;
      tData.department = userRole.department;
      setData(tData);
    }
    setDisableSchool(true);
  }, [props.isOpen]);

  let vPanel = null;
  let showDepartment;
  if (data.branchSchool == "ALL" || !data.branchSchool) {
    showDepartment = null;
  } else {
    showDepartment = (
      <FieldDropdown
        required
        onChanged={fieldOnChanged}
        title="Байгууллагын нэгж"
        keyfield="department"
        defaultValue={data.department}
        checkreq={checkRule}
        data={DEPARTMENTS[data.branchSchool]}
        disable={disableSchool}
      />
    );
  }

  if (currentStep == 0) {
    vPanel = (
      <PanelBase
        headerText={props.name}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="large"
        warning={warning}
        buttons={
          <div style={{ display: "flex" }}>
            <div>
              <ButtonDefault text="Үргэлжлүүлэх" onClick={nextStep} />
            </div>
          </div>
        }
      >
        <div className="RegisterNormativtExp">
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={12}>
                <FieldDropdown
                  required
                  onChanged={fieldOnChanged}
                  title="Салбар сургууль"
                  keyfield="branchSchool"
                  defaultValue={data.branchSchool}
                  checkreq={checkRule}
                  data={BRANCHSCHOOLS}
                  disable={disableSchool}
                />
              </Col>
              <Col span={12}>{showDepartment}</Col>
            </Row>
          </div>
          <div style={{ marginTop: 17 }}>
            <h2>Тээврийн хэрэгслийн жагсаалт</h2>
          </div>
          <div style={{ marginTop: 12 }}>
            <Row gutter={[16, 16]}>
              <Col span={1}></Col>
              <Col span={4}>
                <div className="prods-col-name">Марк</div>
              </Col>
              <Col span={3}>
                <div className="prods-col-name">Улсын дугаар</div>
              </Col>
              <Col span={3}>
                <div className="prods-col-name">Эд хариуцагч</div>
              </Col>
              <Col span={3}>
                <div className="prods-col-name">Ажлын хоног</div>
              </Col>
              <Col span={5}>
                <div className="prods-col-name">Өдрийн шатахууны норм</div>
              </Col>
              <Col span={5}>
                <div className="prods-col-name">Нийт үнэ (₮)</div>
              </Col>
            </Row>
            {data.vehicles?.map((r, index) => {
              return (
                <Row gutter={[16, 16]}>
                  <Col span={1}>
                    <div>
                      <IconButton
                        style={{
                          fontSize: 8,
                          paddingTop: 8,
                          ootHovered: { backgroundColor: "transparent" },
                        }}
                        onClick={() => onDelete(index)}
                        iconProps={{ iconName: "Delete" }}
                      />
                    </div>
                  </Col>
                  <Col span={4}>
                    <FieldTextBorderless
                      keyfield="vehicleMark"
                      defaultValue={r.vehicleMark}
                      placeholder={"Марк"}
                      disable={true}
                    />
                  </Col>
                  <Col span={3}>
                    <FieldTextBorderless
                      keyfield="vehicleNumber"
                      placeholder={"Улсын дугаар"}
                      disable={true}
                      defaultValue={r.vehicleNumber}
                    />
                  </Col>
                  <Col span={3}>
                    <FieldTextBorderless
                      keyfield="owner"
                      placeholder={"Эд хариуцагч"}
                      disable={true}
                      defaultValue={r.owner}
                    />
                  </Col>
                  <Col span={3}>
                    <FieldTextBorderless
                      onChanged={(value) =>
                        itemFieldOnChanged(r, index, "workDays", value)
                      }
                      keyfield="workDays"
                      placeholder={"Ажлын хоног"}
                      defaultValue={r.workDays}
                    />
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="vehicleDayNorm"
                      placeholder={"Өдрийн шатахууны норм (л)"}
                      defaultValue={r.vehicleDayNorm}
                      disable={true}
                    />
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="totalPrice"
                      placeholder={"Нйит үнийн дүн"}
                      disable={true}
                      defaultValue={
                        mntFormatter.format(r.totalPrice || 0) + "₮"
                      }
                    />
                  </Col>
                </Row>
              );
            })}
            <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={1}></Col>
              <Col span={4}>
                <FieldDropdownBorderless
                  onChanged={itemSelectOnChanged}
                  keyfield="vehicleMark"
                  placeholder={"Марк"}
                  checkreq={checkRule}
                  data={vehicles}
                />
              </Col>
              <Col span={3}>
                <FieldDropdownBorderless
                  onChanged={itemSelectOnChanged}
                  keyfield="vehicleNumber"
                  placeholder={"Улсын дугаар"}
                  checkreq={checkRule}
                  data={vehicleNumbers}
                />
              </Col>
              <Col span={3}>
                <FieldDropdownBorderless
                  onChanged={itemSelectOnChanged}
                  keyfield="owner"
                  placeholder={"Эд хариуцагч"}
                  checkreq={checkRule}
                  data={vehicleOwners}
                />
              </Col>
              <Col span={3}>
                <FieldTextBorderless
                  keyfield="workDays"
                  placeholder={"Ажлын хоног"}
                  disable={true}
                />
              </Col>
              <Col span={5}>
                <FieldTextBorderless
                  keyfield="vehicleDayNorm"
                  placeholder={"Өдрийн шатахууны норм"}
                  disable={true}
                />
              </Col>
              <Col span={5}>
                <FieldTextBorderless
                  keyfield="totalPrice"
                  placeholder={"Нийт үнийн дүн"}
                  disable={true}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col offset={19} span={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    // justifyContent: "end",
                    marginTop: 16,
                    fontWeight: 600,
                    fontSize: 12,
                  }}
                >
                  <h3>
                    Нийт дүн (₮):
                    {" " + mntFormatter.format(totalBudgetAmount || 0) + "₮"}
                  </h3>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ marginTop: 13 }}>
                  <FieldTextArea
                    onChanged={fieldOnChanged}
                    title="Тайлбар"
                    keyfield="description"
                    defaultValue={data.description}
                  />
                </div>
              </Col>
            </Row>
            <Col span={5} style={{ marginTop: 20 }}>
              <FieldUpload
                filetype="pdf"
                onChanged={fieldOnChanged}
                title="Файл хавсаргах (Зөвхөн PDF файл)"
                keyfield="normativfile"
                defaultValue={data.normativefile}
              />
            </Col>
          </div>
        </div>
      </PanelBase>
    );
  } else {
    vPanel = (
      <PanelBase
        headerText={props.name}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        buttons={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 16 }}>
              <ButtonSecondary onClick={prevStep} text="Буцах" />
            </div>
            <div>
              <ButtonDefault
                onClick={onSave}
                text={props?.calculateMethodId ? "Засах" : "Хадгалах"}
                disable={isDisabled}
              />
            </div>
          </div>
        }
      >
        <div className="RegisterNormativtExp">
          <Col span={12}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Төсвийн хуваарилалт"
              keyfield="distribution"
              defaultValue={data.distribution}
              checkreq={checkRule}
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </Col>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="1-р сар"
                keyfield="month1"
                defaultValue={data.month1}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="7-р сар"
                keyfield="month7"
                defaultValue={data.month7}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="2-р сар"
                keyfield="month2"
                defaultValue={data.month2}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="8-р сар"
                keyfield="month8"
                defaultValue={data.month8}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="3-р сар"
                keyfield="month3"
                defaultValue={data.month3}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="9-р сар"
                keyfield="month9"
                defaultValue={data.month9}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="4-р сар"
                keyfield="month4"
                defaultValue={data.month4}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="10-р сар"
                keyfield="month10"
                defaultValue={data.month10}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="5-р сар"
                keyfield="month5"
                defaultValue={data.month5}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="11-р сар"
                keyfield="month11"
                defaultValue={data.month11}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="6-р сар"
                keyfield="month6"
                defaultValue={data.month6}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="12-р сар"
                keyfield="month12"
                defaultValue={data.month12}
              />
            </Col>
          </Row>
        </div>
      </PanelBase>
    );
  }

  return vPanel;
};

export default FuelNormPanel;
