export const FOREIGN = [
    {
        key: 0,
        text: "Дотоод"
    },
    {
        key: 1,
        text: "Гадаад"
    }
];

export const SEMESTER = [
    {
        key: 1,
        text: "Хавар"
    },
    {
        key: 2,
        text: "Намар"
    }
];

export const TRAININGTYPE = [
    {
        key: 1,
        text: "Бэлтгэл"
    },
    {
        key: 2,
        text: "Өдрийн сургалт"
    },
    {
        key: 3,
        text: "Оройгоор"
    },
    {
        key: 4,
        text: "Эчнээ"
    }
];

export const LESSONTYPE = [
    {
        key: 1,
        text: "Ерөнхий суурь хичээл"
    },
    {
        key: 2,
        text: "Мэргэжлийн-мэргэшүүлэх хичээл"
    },
    {
        key: 3,
        text: "Судлах бүх төрлийн хичээл, эрдэм шинжилгээ, магистрын ажил"
    },
    {
        key: 4,
        text: "Судлах бүх төрлийн хичээлүүд, эрдэм шинжилгээ"
    },
    {
        key: 5,
        text: "Докторын диссертаци"
    },
    {
        key: 6,
        text: "Хэлний бэлтгэл"
    }
];

export const SCHOOLLIST = [
    {
        key: 1,
        text: "Бизнесийн сургууль"
    },
    {
        key: 2,
        text: "Олон улсын харилцаа, нийтийн удирдлагын сургууль"
    },
    {
        key: 3,
        text: "Хууль зүйн сургууль"
    },
    {
        key: 4,
        text: "Хэрэглээний шинжлэх ухаан, инженерчлэлийн сургууль"
    },
    {
        key: 5,
        text: "Шинжлэх ухааны сургууль"
    },
    {
        key: 6,
        text: "Завхан сургууль"
    },
    {
        key: 7,
        text: "Эрдэнэт сургууль"
    }
];