import React, { useState, useEffect } from "react";
import {
    ButtonDefault,
    PanelBase,
    LoadSpin,
    FieldNumberFormat,
    FieldText
} from "dw-components";
import { API } from '../../../API/API';
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling } from "devextreme-react/data-grid";

const SuggestionGridPanel = (props) => {

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const onSave = async () => {
        if (props?.id) {
            let tDataEdit = dataEdit?.filter(r => r.edit);
            if (tDataEdit?.length > 0) {
                let tDataEditDep = tDataEdit.filter(r => r.bdepid > 0);
                if (tDataEditDep?.length > 0) {
                    setLoadingSave(true);
                    await API.post("/api/budget/updBudgetDep?type_=3", tDataEditDep).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            message.success("Амжилттай");
                            setEdit(false);
                        }
                    }).catch(() => {
                        setLoadingSave(false);
                    });
                    setLoadingSave(false);
                }

                let tDataEditSchl = tDataEdit.filter(r => r.budgetschoolid > 0);

                if (tDataEditSchl?.length > 0) {
                    setLoadingSave(true);
                    tDataEditSchl?.map(r => {
                        r.price = parseFloat(r.universityprice);
                        r.note = r.universitynote;
                    });

                    await API.post("/api/budget/updBudgetSchool?type_=2", tDataEditSchl).then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            message.success("Амжилттай");
                            setEdit(false);
                        }

                    }).catch(() => {
                        setLoadingSave(false);
                    });
                    setLoadingSave(false);
                }

                props.onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    }

    const getData = async () => {
        setLoading(true);
        let tData = [];
        if (props?.id) {
            await API.get("/api/budget/suggestionSchoolEdit?budgetid=" + props?.id + "&ecocatid=" + props?.ecocatid + "&schoolid=" + props?.schoolid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
        }

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        setData(tData);
        setDataEdit(tData);
        setLoading(false);
    };

    useEffect(() => {
        getData(props?.id);
    }, [props?.ecocatid]);

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    return (
        <PanelBase
            headerText="Санал"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={!props.disable && (<div> {loadingSave ? (<LoadSpin />) : (<ButtonDefault text="Хадгалах" onClick={onSave} />)}</div>)}
        >
            {loading ? (
                <LoadSpin />
            ) : (
                <DataGrid
                    dataSource={data}
                    columnAutoWidth={true}
                    wordWrapEnabled={false}
                    showRowLines
                    showBorders
                >
                    <Scrolling columnRenderingMode="standard" mode="standard" useNative={true}/>
                    <Column
                        minWidth={200}
                        dataField="fullname"
                        caption="Нэгж" />
                    <Column caption="Нэгжийн санал">
                        <Column
                            caption="Дүн"
                            dataField="price"
                            format="fixedPoint"
                            width={150}
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={item.data?.price} />
                                );
                            }} />
                        <Column
                            caption="Хувь"
                            dataField="percent"
                            format="fixedPoint"
                            width={60}
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={((item?.data?.price || 0) * 100) / (props?.total || 1)} />
                                );
                            }} />
                        <Column
                            width={150}
                            dataField="note"
                            caption="Тайлбар"
                            cellRender={(item) => {
                                return (
                                    <FieldText disable={true} defaultValue={item.data?.note} />
                                );
                            }} />
                    </Column>
                    <Column caption="Сургуулийн санал">
                        <Column
                            caption="Дүн"
                            dataField="schoolprice"
                            format="fixedPoint"
                            width={150}
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={item.data?.schoolprice} />
                                );
                            }} />
                        <Column
                            caption="Хувь"
                            dataField="schoolpercent"
                            format="fixedPoint"
                            width={60}
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={((item?.data?.schoolprice || 0) * 100) / (props?.total || 1)} />
                                );
                            }} />
                        <Column
                            width={150}
                            dataField="schoolnote"
                            caption="Тайлбар"
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={item.data?.schoolnote} />
                                );
                            }} />
                    </Column>
                    <Column caption="Нэгтгэл төсөв">
                        <Column
                            caption="Дүн"
                            dataField="universityprice"
                            format="fixedPoint"
                            width={150}
                            cellRender={(item) => {
                                return (
                                    <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "universityprice")} defaultValue={item.data?.universityprice} />
                                );
                            }} />
                        <Column
                            caption="Хувь"
                            dataField="universitypercent"
                            format="fixedPoint"
                            width={60}
                            cellRender={item => {
                                return (
                                    <FieldNumberFormat disable={true} defaultValue={((item?.data?.universityprice || 0) * 100) / (props?.total || 1)} />
                                );
                            }} />
                        <Column
                            width={150}
                            dataField="universitynote"
                            caption="Тайлбар"
                            cellRender={(item) => {
                                return (
                                    <FieldText onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "universitynote")} defaultValue={item.data?.universitynote} />
                                );
                            }} />
                    </Column>
                </DataGrid>
            )}
        </PanelBase >
    );
}

export default SuggestionGridPanel;