import React, { useState, useEffect } from "react";
import { Layout, Menu, Typography, Drawer } from "antd";
import { NavLink, useLocation } from "react-router-dom";

const SideMenu = (props) => {
  const [visible, setVisible] = useState(props?.isOpen || false);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState([]);

  useEffect(() => {
    setSelectedKey([location.pathname]);
    if (props?.isOpen) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props?.isOpen, location]);

  const onClose = () => {
    setVisible(false);
    if (props.onCloseConfig) {
      props.onCloseConfig();
    }
  }

  return (
    <Drawer
      placement={'left'}
      closable={false}
      onClose={onClose}
      visible={visible}
      style={{
        display: !visible ? 'none' : '',
        marginLeft: '106px',
        marginTop: '48px',
        zIndex: 10
      }}
      bodyStyle={{
        padding: 0,
        paddingBottom: '50px'
      }}
      width={270}
    >
      <Menu
        mode="inline"
        //defaultSelectedKeys={["/budget/config/economycategory"]}
        selectedKeys={selectedKey}
      >
        {/* style={{ item.key == selectedKey ? color:"#605E5C" ! color: "dedault" }} */}
        <Menu.Item>
          <span style={{ fontSize: 18, fontWeight: 600 }}>Суурь бүртгэл</span>
        </Menu.Item>
        <Menu.Item key="/budget/config/economycategory" onClick={onClose}>
          <NavLink to="/budget/config/economycategory">
            <span style={{ color: "" }}>Эдийн засгийн ангилал</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/generalindicator" onClick={onClose}>
          <NavLink to="/budget/config/generalindicator">
            <span style={{ color: "" }}>Ерөнхий үзүүлэлт</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/schooldep" onClick={onClose}>
          <NavLink to="/budget/config/schooldep">
            <span>Сургууль, нэгж</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="/budget/config/hrPlan" style={{ paddingLeft: 24 }}>
          <NavLink to="/budget/config/hrPlan">
            <span>Хүний нөөцийн мэдээлэл</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="/budget/config/branch/hrPlan" style={{ paddingLeft: 24 }}>
          <NavLink to="/budget/config/branch/hrPlan">
            <span>Хүний нөөцийн мэдээлэл</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="/budget/config/objects" onClick={onClose}>
          <NavLink to="/budget/config/objects">
            <span>Анги танхим, өрөө тасалгаа</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/positions" onClick={onClose}>
          <NavLink to="/budget/config/positions">
            <span>Ажлын байр</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/tuitionfee" onClick={onClose}>
          <NavLink to="/budget/config/tuitionfee">
            <span>Сургалтын төлбөр</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/rentsquare" onClick={onClose}>
          <NavLink to="/budget/config/rentsquare">
            <span>Талбайн түрээс</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/products" onClick={onClose}>
          <NavLink to="/budget/config/products">
            <span>Нормативт бараа материал</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/repaircost" onClick={onClose}>
          <NavLink to="/budget/config/repaircost">
            <span>Эд хогшил</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/businessTrip" onClick={onClose}>
          <NavLink to="/budget/config/businessTrip">
            <span>Томилолтын мэдээ</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="/budget/config/assets">
          <NavLink to="/budget/config/assets">
            <span>Хөрөнгийн худалдан авалт</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="/budget/config/vehicle" onClick={onClose}>
          <NavLink to="/budget/config/vehicle">
            <span>Тээврийн хэрэгсэл</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/vehicletaxconfig" onClick={onClose}>
          <NavLink to="/budget/config/vehicletaxconfig">
            <span>Авто машины татвар</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/fuelNorm" onClick={onClose}>
          <NavLink to="/budget/config/fuelNorm">
            <span>Шатахууны норм</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/securityobjectschool" onClick={onClose}>
          <NavLink to="/budget/config/securityobjectschool">
            <span>Харуул, хамгаалалт</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/salaryconfig" onClick={onClose}>
          <NavLink to="/budget/config/salaryconfig">
            <span>Цалингийн тохиргоо</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/overtimewage" onClick={onClose}>
          <NavLink to="/budget/config/overtimewage">
            <span>Цагийн хөлс, илүү цаг</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/bonusconfig" onClick={onClose}>
          <NavLink to="/budget/config/bonusconfig">
            <span>Урамшуулал тохиргоо</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/purchaseitem" onClick={onClose}>
          <NavLink to="/budget/config/purchaseitem">
            <span>Хөрөнгийн нэр, төрөл</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/user" onClick={onClose}>
          <NavLink to="/budget/config/user">
            <span>Хэрэглэгч</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/pension" onClick={onClose}>
          <NavLink to="/budget/config/pension">
            <span>Тэтгэвэр, тэтгэмж</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink to="/budget/config/documentcategory" onClick={onClose}>
            <span>Баримт бичгийн ангилал</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/basecost" onClick={onClose}>
          <NavLink to="/budget/config/basecost">
            <span>Хичээл үйлдвэрлэлийн дадлага</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/buildingschoolcost" onClick={onClose}>
          <NavLink to="/budget/config/buildingschoolcost">
            <span>Байр ашиглалт /сургуулиар/</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/usercode" onClick={onClose}>
          <NavLink to="/budget/config/usercode">
            <span>Хэрэглэгчийн код бүртгэл</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/meter" onClick={onClose}>
          <NavLink to="/budget/config/meter">
            <span>Тоолуурын бүртгэл</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/economycategoryformula" onClick={onClose}>
          <NavLink to="/budget/config/economycategoryformula">
            <span>Томьёо оруулах</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/betweencost" onClick={onClose}>
          <NavLink to="/budget/config/betweencost">
            <span>Хоорондын тооцоо хийх сургууль</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/editindicator" onClick={onClose}>
          <NavLink to="/budget/config/editindicator">
            <span>Үзүүлэлт засах тохиргоо</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/kpi" onClick={onClose}>
          <NavLink to="/budget/config/kpi">
            <span>Гүйцэтгэлийн түлхүүр үзүүлэлт</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/budget/config/strategyplan" onClick={onClose}>
          <NavLink to="/budget/config/strategyplan">
            <span>Стратегийн төлөвлөгөө хэмжих</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default SideMenu;
