import React, { useContext, useEffect } from "react";
import Header from "../Components/Header/Header";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";

import Login from "../Pages/Login/Login";
import CallbackPage from "../Pages/Login/CallBackPage";
import Budget from "../Pages/Budget/Index";
import Reports from "../Pages/Reports/Index";
import ReportDetails from "../Pages/Reports/Details";
import BudgetIndicators from "../Pages/Budget/Indicators";
import DBudget from "../Pages/Department/Budget/Index";
import DPurchaseSuggestion from "../Pages/Department/PurchaseInvestment/Suggestion";

import BBudget from "../Pages/Branch/Budget/Index";
import BPurchaseSuggestion from "../Pages/Branch/Budget/PurchaseaInvestment/Suggestion";
import PurchaseSuggestion from "../Pages/Budget/PurchaseaInvestment/Suggestion";

import { UserContext } from "../Context/UserContext";

const Navigations = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo, setUserInfo } = useContext(UserContext);

    useEffect(() => {
        if (userInfo?.token) {
            if (isTokenExpired()) {
                localStorage.removeItem("userInfo");
                setUserInfo({});
                navigate("/login");
            }  
        }
      }, [navigate]);

    const isTokenExpired = () => {
        if (!userInfo?.expires_in) {
            return true;
        }

        const currentTime = new Date().getTime() / 1000; // Convert to seconds
    
        return currentTime > parseInt(userInfo?.expires_in, 10);
    };

    const logout = () => {
        localStorage.removeItem("userInfo");
        setUserInfo({});
        navigate("/login");
    };

    if (userInfo?.token) {

        const compHeader = (<Header
            companyname={userInfo?.schoolname}
            logOut={logout}
            location={location}
            navigate={navigate}
            sideMenuData={[]}
            menuData={null}
            showFooter={userInfo?.schooltypeid == 1 ? true : false}
        />);

        if (userInfo?.schooltypeid == 1) {
            return (
                <>
                    {compHeader}
                    <Routes>
                        {
                            userInfo?.menuroleids?.includes("1") &&
                            <Route
                                path="*"
                                element={<Navigate to={"/budget/dashboard"} />}
                            />
                        }
                        {
                            userInfo?.menuroleids?.includes("2") &&
                            <Route
                                path="/budget/*"
                                element={<Budget />}
                            />
                        }
                        {
                            userInfo?.menuroleids?.includes("3") &&
                            <Route
                                path="/purchaseinvestment/suggestion/:type/:id"
                                element={<PurchaseSuggestion />}
                            />
                        }
                        {
                            userInfo?.menuroleids?.includes("2") &&
                            <Route
                                path="/reports"
                                element={<Reports />}
                            />
                        }
                        {
                            userInfo?.menuroleids?.includes("2") &&
                            <Route
                                path="/report/details"
                                element={<ReportDetails />}
                            />
                        }
                        {
                            userInfo?.menuroleids?.includes("2") &&
                            <Route
                                path="/budget/indicators"
                                element={<BudgetIndicators />}
                            />
                        }
                        {
                            (userInfo?.menuroleids?.includes("5") ||
                                userInfo?.menuroleids?.includes("6") ||
                                userInfo?.menuroleids?.includes("7") ||
                                userInfo?.menuroleids?.includes("8")
                            ) &&
                            <Route
                                path="/budget/*"
                                element={<Budget />}
                            />
                        }
                    </Routes>
                </>
            );
        } else if ((userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 3 || userInfo?.schooltypeid == 5 || userInfo?.schooltypeid == 6) && userInfo?.menuroleids?.includes("2")) {
            return (
                <>
                    {compHeader}
                    <Routes>
                        <Route
                            path="/budget/branch/*"
                            element={<BBudget />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={"/budget/branch"} />} />
                        <Route
                            path="/purchaseinvestment/branch/suggestion/:type/:id"
                            element={<BPurchaseSuggestion />} />
                    </Routes>
                </>
            );
        } else if (userInfo?.menuroleids != "") {
            return (
                <>
                    {compHeader}
                    <Routes>
                        <Route
                            path="/budget/department/*"
                            element={<DBudget />}
                        />
                        <Route
                            path="/purchaseinvestment/department/suggestion/:type/:id"
                            element={<DPurchaseSuggestion />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={"/budget/department"} />} />
                    </Routes>
                </>
            );
        }

    } else {
        return (
            <Routes>
                <Route path="*" element={<Login />} />
                <Route path="/callback" element={<CallbackPage />} />
            </Routes>
        );
    }
};

export default Navigations;
