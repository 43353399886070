import React from 'react';

const IndicatorTitle = (props) => {
    return (
        <div style={{ marginBottom: 16, fontWeight: 600 }}>
            {props?.value}
        </div>
    );
}

export default IndicatorTitle;