import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, FieldText, ButtonSecondary } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { Icon } from '@fluentui/react/lib/Icon';
import { UserType } from "../../Const/UserType";

const BTripGridPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dBTravel, setDBTravel] = useState([]);
    const [dDist, setDDist] = useState([]);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const [splan, setSplan] = useState([]);
    const [kpis, setKpis] = useState([]);

    const [editIndicator, setEditIndicator] = useState(false);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            setLoadingSave(true);

            let vSuccess = false;

            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.iD = tR.id;

                tR.sCHOOLNAME = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                }
                tR.sCHOOLID = parseInt(currentschoolid);

                tR.bUDGETID = parseInt(props?.id);
                tR.qTY = tR.qty;
                tR.dAY = tR.day;
                tR.tRAVELCOST = tR.travelcost;
                tR.hOUSINGCOST = tR.housingcost;
                tR.tRAVELDAY = tR.travelday;
                tR.yEAR = tR.year;
                tR.dIVISIONNAME = tR.divisionname;
                tR.dIVISIONID = tR.divisionid;
                tR.dISTRICTID = tR.districtid;
                tR.dISTRICTNAME = tR.districtname;
                tR.nOTE = tR.note;
                tR.bUSINESSTRIPID = tR.businesstripid;
                tR.oUTPUT = tR.output;
                tR.oUTCOME = tR.outcome;
                tR.iMPACT = tR.impact;
                tR.sPTOPPRID = tR.sptopprid;
                tR.sPTOPPRNAME = tR.sptopprname;
                tR.sPGOALID = tR.spgoalid;
                tR.sPGOALNAME = tR.spgoalname;
                tR.sPSTRID = tR.spstrid;
                tR.sPSTRNAME = tR.spstrname;
                tR.sPINDID = tR.spindid;
                tR.sPINDNAME = tR.spindname;
                tR.kPIID = tR.kpiid;
                tR.kPINAME = tR.kpiname;

                delete tR.id;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.schoolname;
                delete tR.qty;
                delete tR.day;
                delete tR.travelcost;
                delete tR.housingcost;
                delete tR.travelday;
                delete tR.year;
                delete tR.divisionname;
                delete tR.divisionid;
                delete tR.districtid;
                delete tR.districtname;
                delete tR.note;
                delete tR.businesstripid;
                delete tR.totaltravelcost;
                delete tR.totalhousingcost;
                delete tR.totaltravelday;
                delete tR.totalcost;
                delete tR.countryid;
                delete tR.countryname;
                delete tR.created;
                delete tR.createdby;
                delete tR.perday;
                delete tR.schoolcode;
                delete tR.depcode;
                delete tR.year;
                delete tR.yeaR1;

                delete tR.output;
                delete tR.outcome;
                delete tR.impact;
                delete tR.sptopprid;
                delete tR.sptopprname;
                delete tR.spgoalid;
                delete tR.spgoalname;
                delete tR.spstrid;
                delete tR.spstrname;
                delete tR.spindid;
                delete tR.spindname;
                delete tR.kpiid;
                delete tR.kpiname;

                if (!isNaN(parseFloat(tR.qTY)) && !isNaN(parseFloat(tR.dAY))) {
                    if (!isNaN(parseFloat(tR.tRAVELCOST))) {
                        tR.tOTALTRAVELCOST = parseFloat((parseFloat(tR.qTY) * parseFloat(tR.tRAVELCOST)).toFixed(2));
                    } else {
                        tR.tOTALTRAVELCOST = 0;
                    }

                    if (!isNaN(parseFloat(tR.hOUSINGCOST))) {
                        tR.tOTALHOUSINGCOST = parseFloat((parseFloat(tR.qTY) * parseFloat(tR.dAY) * parseFloat(tR.hOUSINGCOST)).toFixed(2));
                    } else {
                        tR.tOTALHOUSINGCOST = 0;
                    }

                    if (!isNaN(parseFloat(tR.tRAVELDAY))) {
                        tR.tOTALTRAVELDAY = parseFloat((parseFloat(tR.qTY) * parseFloat(tR.dAY) * parseFloat(tR.tRAVELDAY)).toFixed(2));
                    } else {
                        tR.tOTALTRAVELDAY = 0;
                    }

                    tR.tOTALCOST = tR.tOTALTRAVELCOST + tR.tOTALHOUSINGCOST + tR.tOTALTRAVELDAY;
                } else {
                    tR.tOTALTRAVELCOST = 0;
                    tR.tOTALHOUSINGCOST = 0
                    tR.tOTALTRAVELDAY = 0;
                    tR.tOTALCOST = 0;
                }

                delete tR.arrayIndex;
                delete tR.edit;
                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;

                tDataEdit[i] = tR;
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetLocalBusinessTripInputType]) {
            //         budgetMutation {
            //           addBudgetLocalBusinessTrip(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetLocalBusinessTrip?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBTrip?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            //vSuccess = await calcfunc("calcBTrip", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        await API.get("/api/budget/budgetLocalBusinessTripTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Томилолт дотоод" + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getSPlan = async () => {
        let tSplans = [];
        let tKpis = [];
        await APIGraphQL.post("", {
            query: `query {
                sharedQuery {
                    getKeyPerfIndList {
                        key: iD
                        text: nAME
                    }
                    getSPlanList {
                        key: iD
                        text: nAME
                        typeid: sPLANTYPEID
                        parentid: pARENTID
                    }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getKeyPerfIndList?.length > 0) {
                tKpis = tResp?.sharedQuery?.getKeyPerfIndList;
            }
            if (tResp?.sharedQuery?.getSPlanList?.length > 0) {
                tSplans = tResp?.sharedQuery?.getSPlanList;
            }
        }).catch(() => {
            setLoading(false);
        });
        setSplan(tSplans);
        setKpis(tKpis);
        return tSplans;
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDBTravel = [];
        let alldep = isalldep;

        let tEditIndicator = false;

        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        setLoading(true);
        await API.get("/api/budget/budgetLclBTrip?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `query ($budgetid: Int) {
                sharedQuery {
                    getLclBusinessTripListByYear(budgetid: $budgetid){
                        key: dIVISIONID
                        text: dIVISIONNAME
                    }
                }
                }`,
            variables: {
                "budgetid": parseInt(props?.id)
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getLclBusinessTripListByYear?.length > 0) {
                tDBTravel = tResp?.sharedQuery?.getLclBusinessTripListByYear;
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `query ($schoolid: Int, $indid: Int) {
                sharedQuery {
                  getEditIndicatorBySchool(schoolid: $schoolid, indid: $indid) {
                    iD
                  }
                }
              }`,
            variables: {
                "schoolid": parseInt(userInfo?.schoolid),
                "indid": 60
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getEditIndicatorBySchool?.iD) {
                tEditIndicator = true;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setDBTravel(tDBTravel);
        setEditIndicator(tEditIndicator);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);
        getSPlan();

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "divisionid") {
            let tDDist = [];

            await APIGraphQL.post("", {
                query: `query ($budgetid: Int, $divisionid: Int) {
                    sharedQuery {
                      getBDistByDiv(budgetid: $budgetid, divisionid: $divisionid) {
                        key: dISTRICTID
                        text: dISTRICTNAME
                      }
                    }
                  }`,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "divisionid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getBDistByDiv;
                if (tResp) {
                    if (tResp?.length > 0) {
                        tDDist = tResp;
                        tDataEdit[pIndex]["districtid"] = 0;
                    }
                }
            }).catch(() => {
                setLoading(false);
            });

            setDDist(tDDist);
        } else if (pField == "districtid") {
            await APIGraphQL.post("", {
                query: `query ($budgetid: Int, $districtid: Int) {
                    sharedQuery {
                      getBTripByDistrict(districtid: $districtid, budgetid: $budgetid) {
                        travelcost: tRAVELCOST
                        housingcost: hOUSINGCOST
                        travelday: tRAVELDAY
                        year: yEAR
                        businesstripid: iD
                      }
                    }
                  }`,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "districtid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getBTripByDistrict;
                if (tResp) {
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                    setData(tDataEdit);
                    tDataEdit[pIndex]["totaltravelcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["travelcost"] || 0);
                    tDataEdit[pIndex]["totalhousingcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["housingcost"] || 0);
                    tDataEdit[pIndex]["totaltravelday"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["travelday"] || 0);
                    tDataEdit[pIndex]["totalcost"] = tDataEdit[pIndex]["totaltravelcost"] + (tDataEdit[pIndex]["totalhousingcost"] || 0) + (tDataEdit[pIndex]["totaltravelday"] || 0);
                }
            }).catch(() => {
                setLoading(false);
            });
        } else if (pField == "qty") {
            tDataEdit[pIndex]["totaltravelcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["travelcost"] || 0);
            tDataEdit[pIndex]["totalhousingcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["housingcost"] || 0);
            tDataEdit[pIndex]["totaltravelday"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["travelday"] || 0);
            tDataEdit[pIndex]["totalcost"] = tDataEdit[pIndex]["totaltravelcost"] + (tDataEdit[pIndex]["totalhousingcost"] || 0) + (tDataEdit[pIndex]["totaltravelday"] || 0);
        } else if (pField == "day") {
            tDataEdit[pIndex]["totaltravelcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["travelcost"] || 0);
            tDataEdit[pIndex]["totalhousingcost"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["housingcost"] || 0);
            tDataEdit[pIndex]["totaltravelday"] = (tDataEdit[pIndex]["qty"] || 0) * (tDataEdit[pIndex]["day"] || 0) * (tDataEdit[pIndex]["travelday"] || 0);
            tDataEdit[pIndex]["totalcost"] = tDataEdit[pIndex]["totaltravelcost"] + (tDataEdit[pIndex]["totalhousingcost"] || 0) + (tDataEdit[pIndex]["totaltravelday"] || 0);
        }

        if (pField == "sptopprid") {
            tDataEdit[pIndex]["spgoalid"] = null;
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spgoalid") {
            tDataEdit[pIndex]["spstrid"] = null;
            tDataEdit[pIndex]["spindid"] = null;
        }

        if (pField == "spstrid") {
            tDataEdit[pIndex]["spindid"] = null;
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }


    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBLocalBusinessTrip(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => {
                if (response?.data?.data?.budgetMutation?.delBLocalBusinessTrip == 1) {
                    vSuccess = true;
                }
            });

            if (vSuccess) {
                await API.post("api/budget/calcBTrip?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            }
                //vSuccess = await calcfunc("calcBTrip", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)

        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Дотоод томилолт"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={!props.disable && (
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                    <div>
                        <Row justify="space-between">
                            <Col>
                                {(!disable &&
                                    <Row justify="start" >
                                        <Col style={{ marginTop: 24 }}>
                                            <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Row style={{ marginTop: 30 }}>
                                <Col justify="end">
                                    /мян.төгрөг/
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                dataField="divisionname"
                                caption="Аймаг/ Хот"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "divisionid", pNameValue, "divisionname")} defaultValue={item.data?.divisionid} data={dBTravel} />
                                    );
                                }} />
                            <Column
                                dataField="districtname"
                                caption="Сум/ Дүүрэг"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "districtid", pNameValue, "districtname")} defaultValue={item.data?.districtid} data={(item.data?.districtid || item.data?.districtid > 0) ? [{ key: item.data?.districtid, text: item.data?.districtname }] : dDist} />
                                    );
                                }} />
                            <Column
                                dataField="note"
                                caption="Томилолтын зорилго"
                                alignment='center'
                                minwidth={140}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "note")} defaultValue={item.data?.note} />
                                    );
                                }} />
                            <Column
                                dataField="qty"
                                caption="Хүний тоо"
                                alignment='center'
                                width={80}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "qty")} defaultValue={item.data?.qty} />
                                    );
                                }} />
                            <Column
                                dataField="day"
                                caption="Томилолтын хоног"
                                alignment='center'
                                width={90}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "day")} defaultValue={item.data?.day} />
                                    );
                                }} />
                            <Column caption="Нэгж" alignment='center'>
                                <Column
                                    dataField="travelcost"
                                    caption="Замын зардал"
                                    width={150}
                                    alignment='center'
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "travelcost")} defaultValue={item.data?.travelcost} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="housingcost"
                                    caption="Байрны зардал"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "housingcost")} defaultValue={item.data?.housingcost} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="travelday"
                                    caption="Зам хоног"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "travelday")} defaultValue={item.data?.travelday} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column caption="Нийт" alignment='center'>
                                <Column
                                    dataField="totaltravelcost"
                                    caption="Замын зардал"
                                    alignment='center'
                                    format="fixedPoint"
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totaltravelcost")} defaultValue={item.data?.totaltravelcost} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="totalhousingcost"
                                    caption="Байрны зардал"
                                    alignment='center'
                                    format="fixedPoint"
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalhousingcost")} defaultValue={item.data?.totalhousingcost} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="totaltravelday"
                                    caption="Зам хоног"
                                    alignment='center'
                                    format="fixedPoint"
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={(disable == false && editIndicator == true) ? false : true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totaltravelday")} defaultValue={item.data?.totaltravelday} fixedDecimalScale={true} />
                                        );
                                    }} />

                                <Column
                                    dataField="totalcost"
                                    caption="Дүн"
                                    alignment='center'
                                    width={150}
                                    format="fixedPoint"
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.totalcost} fixedDecimalScale={true} />
                                        );
                                    }} />
                                 <Column
                                dataField="output"
                                caption="Хүрэх үр дүн /outputs/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "output")} defaultValue={item.data?.output} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.output} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="outcome"
                                caption="Хүрэх үр дагавар /outcome/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "outcome")} defaultValue={item.data?.outcome} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.outcome} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="impact"
                                caption="Үр нөлөө /Impact/"
                                width={400}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "impact")} defaultValue={item.data?.impact} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.impact} </div>
                                        );
                                    }
                                }} />

                            <Column
                                dataField="sptopprname"
                                caption="Тэргүүлэх чиглэл"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "sptopprid", pNameValue, "sptopprname")}
                                                defaultValue={item.data?.sptopprid}
                                                data={splan.filter(x => x.typeid == 2)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.sptopprname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spgoalname"
                                caption="Зорилт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spgoalid", pNameValue, "spgoalname")}
                                                defaultValue={item.data?.spgoalid}
                                                data={splan.filter(x => x.typeid == 3 && x.parentid == item.data?.sptopprid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spgoalname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spstrname"
                                caption="Стратеги"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spstrid", pNameValue, "spstrname")}
                                                defaultValue={item.data?.spstrid}
                                                data={splan.filter(x => x.typeid == 4 && x.parentid == item.data?.spgoalid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spstrname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="spindname"
                                caption="Хэмжих үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown
                                                disable={disable}
                                                onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "spindid", pNameValue, "spindname")}
                                                defaultValue={item.data?.spindid}
                                                data={splan.filter(x => x.typeid == 5 && x.parentid == item.data?.spstrid)}
                                            />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.spindname} </div>
                                        );
                                    }
                                }} />
                            <Column
                                dataField="kpiname"
                                caption="Гүйцэтгэлийн түлхүүр үзүүлэлт"
                                alignment='center'
                                width={300}
                                cellRender={(item) => {
                                    if (!disable) {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "kpiid", pNameValue, "kpiname")} defaultValue={item.data?.kpiid} data={kpis} />
                                        );
                                    }
                                    else {
                                        return (
                                            <div>{item.data?.kpiname} </div>
                                        );
                                    }
                                }} /> 
                                ({!disable &&
                                    <Column
                                        dataField="action"
                                        caption=""
                                        width={30}
                                        cellRender={(item) => {
                                            return (
                                                <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                                    <Icon iconName="Delete" />
                                                </div>
                                            );
                                        }} />
                                })
                            </Column>
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalcost"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={depid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(depid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BTripGridPanel;