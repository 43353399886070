import { DropdownMenuItemType } from "@fluentui/react";

const CALCULATEMETHODS = [
  { key: 1, text: "Орлогын үзүүлэлт", itemType: DropdownMenuItemType.Header },
  { key: 2, text: "Гараас утга оруулах" },
  { key: 3, text: "Оюутны тооноос" },
  { key: 4, text: "Оюутны байраас" },
  { key: 5, text: "Гүйцэтгэлээс тооцох" },
  { key: 6, text: "БЭЛАС" },
  { key: 7, text: "Түрээс" },
  { key: 8, text: "Судалгааны орлого" },
  { key: 9, text: "Мэргэжлийн үйлчилгээний орлого" },
  { key: 10, text: "Сургууль хоорондын тооцоо-Импорт" },
  { key: 11, text: "Хос хавсрах хөтөлбөрийн дотоод тооцооны орлого" },
  { key: 49, text: "-", itemType: DropdownMenuItemType.Divider, },
  { key: 50, text: "Зарлагын үзүүлэлт", itemType: DropdownMenuItemType.Header, },
  { key: 51, text: "Гараас утга оруулах" },
  { key: 52, text: "Нормативт зардал" },
  { key: 53, text: "Сургалтын төлбөр" },
  { key: 54, text: "ЭЗА-с хувиар тооцох" },
  { key: 55, text: "Гүйцэтгэлээс тооцох" },
  { key: 56, text: "Орон тооноос хамааралтай" },
  { key: 57, text: "Орон тооноос тогтмол" },
  { key: 58, text: "Хөрөнгийн худалдан авалт" },
  { key: 59, text: "Судалгааны суурь зардал" },
  { key: 60, text: "Томилолт" },
  { key: 61, text: "Шатахуун" },
  { key: 63, text: "Цалин" },
  { key: 64, text: "Объектоос хамаарсан" },
  { key: 65, text: "Дотуур байрнаас хамаарсан" },
  { key: 66, text: "Утасны зардал" },
  { key: 67, text: "Авто машины татвар хураамж" },
  { key: 68, text: "Хэвлэл мэдээлэл, маркетинг" },
  { key: 69, text: "Гишүүнчлэл, гаалийн татвар" },
  { key: 70, text: "Аудит баталгаажуулалт, зэрэглэл тогтоох" },
  { key: 71, text: "Бусад төлбөр хураамж" },
  { key: 72, text: "Мэдээлэл технологийн үйлчилгээ" },
  { key: 73, text: "Харуул хамгаалалт" },
  { key: 74, text: "Гэрээт ажлын хөлс" },
  { key: 75, text: "Тэтгэвэр, тэтгэмж" },
  { key: 76, text: "Оюутны үйлчилгээний зардал" },
  { key: 77, text: "Эрдэм шинжилгээ, судалгаа инновацийн зардал" },
  { key: 78, text: "Сургалтын үйлчилгээний зардал" },
  { key: 79, text: "Төсөл гэрээт ажилтай холбоотой зардал" },
  { key: 80, text: "Эд хогшилын зардал" },
  { key: 81, text: "Зөвлөх үйлчилгээний зардал" },
  { key: 82, text: "Урсгал засварын зардал" },
  { key: 83, text: "Нэг удаагийн тэтгэмж, шагнал" },
  { key: 84, text: "Дотоод үйл ажиллагаа" },
  { key: 85, text: "Хангамж бараа материалын зардал" },
  { key: 86, text: "Зөвлөх үйлчилгээний зардал" },
  { key: 87, text: "Ашиглалтын зардал" },
  { key: 88, text: "Урамшуулал" },
  { key: 90, text: "Сургууль хоорондын тооцоо-Экспорт" },
  { key: 91, text: "Интернэт" },
  { key: 92, text: "Хос, хавсарга хөтөлбөрийн дотоод тооцооны зардал"},
  { key: 93, text: "Бичиг хэргийн зардал /Захиргаа/"},
  { key: 94, text: "Бичиг хэргийн зардал /Сургалт/"},
  { key: 95, text: "Багш, сургалтын ажилтны дэмжлэг"},
  { key: 96, text: "Мөнгөн хөрөнгийн эцсийн үлдэгдэл"}
];

export default CALCULATEMETHODS