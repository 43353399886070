import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Drawer, Menu } from "antd";
import List from "./List";
import PurchaseInvestmentList from "../PurchaseInvestment/List"
import SideBudgetIcon from "../../../svg/sideMenuIcons/SideBudgetIcon";
import SideInvestmentIcon from "../../../svg/sideMenuIcons/SideInvestmentIcon";
import SideDocumentIcon from '../../../svg/sideMenuIcons/SideDocumentIcon';
import { useNavigate, Routes, Route, NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
import { APIGraphQL } from "../../../API/APIGraphQL";
import moment from "moment";

import { ReactComponent as AppListIcon } from "../../../svg/budgetIcons/AppListIcon.svg"
import { ReactComponent as AutoSumIcon } from "../../../svg/budgetIcons/AutoSumIcon.svg";
import { ReactComponent as CommentIcon } from "../../../svg/budgetIcons/CommentIcon.svg";
import { ReactComponent as HistoryIcon } from "../../../svg/budgetIcons/HistoryIcon.svg";
import { ReactComponent as MoneyDismissIcon } from "../../../svg/budgetIcons/MoneyDismissIcon.svg";
import { ReactComponent as MoneyHandIcon } from "../../../svg/budgetIcons/MoneyHandIcon.svg";

import DBudgetIndicators from "../../Department/Budget/Indicators";
import DOtherIndicators from "../../Department//Others/List";
import DepartmentIndicators from "../../Branch/Budget/DepartmentIndicators";
import BudgetNewPanel from "../../Budget/Panel/BudgetNewPanel";
import Document from "../../Document/Document";
import { ReactComponent as ReportIcon } from "../../../svg/budgetIcons/ReportIcon.svg"

const Budget = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [data, setData] = useState({});
  const [days, setDays] = useState();
  const { userInfo } = useContext(UserContext);
  const [showNew, setShowNew] = useState(false);

  const onClickSideMenu = (value) => {
    setCurrent(value);
    if (value === 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }

  useEffect(() => {
    getData();
  }, [location]);

  const getData = async () => {
    let tData = [];
    let latestYear = 0;
    await APIGraphQL.post("", {
      query: `query ($schoolid: Int) {
            budgetQuery {
              budgetsOpen(schoolid: $schoolid) {
                iD
                yEAR
                eNDDATE
                sTATUSNAME
                cASENAME
                budgetSchoolStatus(schoolid: $schoolid) {
                  sTATUSID
                  sTATUSNAME
                }
              }
            }
          }`,
      variables: {
        "schoolid": parseInt(userInfo?.schoolid)
      }
    }).then(response => {
      if (response?.data?.data?.budgetQuery?.budgetsOpen?.length > 0) {
        tData = response?.data?.data?.budgetQuery?.budgetsOpen;
        latestYear = Math.max(...tData.map(item => item.iD));
        tData = tData.filter(item => item.iD == latestYear)[0];
        if (tData) {
          setData(tData);
          setDays(moment(tData?.eNDDATE)?.diff(moment(), 'days'));
        }
      }
    });
  }

  const onNavigate = (p_type) => {
    setVisible(false);
    navigate("/budget/department/indicators/" + data?.iD + "/" + p_type);
  }

  const onClose = () => {
    setVisible(false);
  };

  const onBudgetNew = () => {
    setVisible(false);
    setShowNew(true);
  }

  const onDismiss = () => {
    setShowNew(false);
  }

  return (
    <div style={{ backgroundColor: "#F7F7F7", minHeight: "95vh" }}>
      <Row wrap={false}>
        <Col flex="none" style={{ position: 'fixed' }}>
          <div style={{
            backgroundColor: "#305095", minHeight: "95vh",
            boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.15)'
          }}>
            <div style={{ minHeight: "90vh" }}>
              {userInfo?.menuroleids.includes("2") &&
                <Row justify="center" align="middle" style={{ backgroundColor: current == 0 ? "#2C4A89" : "#305095" }}>
                  <Col span={2} offset={7} style={{ marginTop: 24, marginRight: 64 }}>
                    <div style={{ height: "90px", cursor: "pointer" }} onClick={() => { onClickSideMenu(0) }}>
                      <NavLink to="/budget/department/list">
                        <SideBudgetIcon active={current == 0 ? true : false} />
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              }
              {userInfo?.menuroleids.includes("3") &&
                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
              {userInfo?.menuroleids.includes("3") &&
                <Row justify="center" align="middle" style={{ backgroundColor: current == 1 ? "#2C4A89" : "#305095" }}>
                  <Col span={8} offset={1} style={{ marginTop: 24, marginRight: 27 }}>
                    <div style={{ height: "90px", cursor: "pointer" }} onClick={() => { onClickSideMenu(1) }}>
                      <NavLink to="/budget/department/investment">
                        <SideInvestmentIcon active={current == 1 ? true : false} />
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              }
              {userInfo?.menuroleids.includes("4") &&
                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
              {userInfo?.menuroleids.includes("4") &&
                <Row justify="center" align="middle" style={{ backgroundColor: current == 2 ? "#2C4A89" : "#305095" }}>
                  <Col style={{ marginTop: 24 }}>
                    <div style={{ height: "90px", cursor: "pointer" }} onClick={() => { onClickSideMenu(2) }}>
                      <NavLink to="/budget/department/document">
                        <SideDocumentIcon active={current == 2 ? true : false} />
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              }
              <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />
            </div>
          </div>
        </Col>
        <Col style={{ marginRight: "106px" }} />
        <Col flex="auto">
          <div style={{ backgroundColor: "#ffffff", marginLeft: 10 }}>
            <Routes>
              {userInfo?.menuroleids.includes("2") &&
                <Route
                  path="/list"
                  element={<List />}
                />}
              {userInfo?.menuroleids.includes("3") &&
                <Route
                  path="/investment"
                  element={<PurchaseInvestmentList />}
                />}
              {userInfo?.menuroleids.includes("4") &&
                <Route
                  path="/document"
                  element={<Document />}
                />}
              {userInfo?.menuroleids.includes("2") &&
                <Route
                  path="/indicators/:id"
                  element={<DBudgetIndicators />}
                />}
              {userInfo?.menuroleids.includes("2") &&
                <Route
                  path="/indicators/:id/:type"
                  element={<DBudgetIndicators />}
                />}
              {userInfo?.menuroleids.includes("2") &&
                <Route
                  path="/config/department/others"
                  element={<DOtherIndicators />}
                />}
              {userInfo?.menuroleids.includes("7") &&
                <Route
                  path="/indicators/config"
                  element={<DepartmentIndicators />}
                />}
            </Routes>
          </div>
        </Col>
      </Row>
      {visible && userInfo?.menuroleids.includes("2") && (
        <Drawer
          placement={'left'}
          closable={false}
          onClose={onClose}
          visible={visible}
          style={{
            display: !visible ? 'none' : '',
            marginLeft: '106px',
            marginTop: '48px',
            zIndex: 10
          }}
          width={235}
        >
          <div style={{
            background: days >= 0 ? '#F4BF00' : '#CE563C',
            width: '186px',
            height: '44px',
            borderRadius: '4px',
          }}
          >
            <p style={{ textAlign: 'center', fontWeight: '600', paddingTop: '12px', color: '#fff' }}>
              {days >= 0 ? days + ' хоног үлдсэн' : days * -1 + ' хоног хоцорсон'}
            </p>
            <h5>Нээлттэй төсөв: {data?.yEAR}</h5>
            <p style={{ color: '#6A6A6A', fontSize: '12px' }}>{data?.budgetSchoolStatus?.sTATUSNAME + " - " + data?.cASENAME}</p>
          </div>
          <Menu
            mode="inline"
            style={{
              height: '500px',
              borderRight: 0,
              marginTop: '60px',
              overFlow: 'hidden',
              lineHeight: 'normal',
              textAlign: 'left'
            }}
          >
            <Menu.Item icon={<AppListIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(0)}>
              Үзүүлэлт
            </Menu.Item>
            <Menu.Item icon={<MoneyHandIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(1)}>
              Төсвийн орлого
            </Menu.Item>
            <Menu.Item icon={<MoneyDismissIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(2)}>
              Төсвийн зарлага
            </Menu.Item>
            <Menu.Item icon={<AutoSumIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(3)}>
              Төсөв
            </Menu.Item>
            <Menu.Item icon={<CommentIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(4)}>
              Төсвийн санал
            </Menu.Item>
            <Menu.Item icon={<ReportIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(5)}>
              Тайлан
            </Menu.Item>
            <h5>Төсөв</h5>
            <Menu.Item icon={<HistoryIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => { setVisible(false); navigate("/budget/department/list"); }}>
              Төсвийн түүх
            </Menu.Item>
          </Menu>
        </Drawer>
      )}
      <BudgetNewPanel id={data?.iD} isOpen={showNew} onDismiss={onDismiss}></BudgetNewPanel>
    </div>
  );
};

export default Budget;
