import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldText, PanelBase, FieldNumberFormat, LoadSpin } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { VehicleCategory } from "../../../../Const/VehicleCategory";
import { VehicleFuelType } from "../../../../Const/VehicleFuelType";
import { UserContext } from "../../../../Context/UserContext";

const VehiclePanel = (props) => {
  const [data, setData] = useState({});
  const [dVType, setDVType] = useState([]);

  const { userInfo } = useContext(UserContext);
  const [checkRule, setCheckRule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = async () => {
    setLoading(true);

    let tDVType = [];

    await APIGraphQL.post("", {
      query: `{
        sharedQuery {
          getVehicleTypeList {
            key: iD
            text: nAME
          }
        }
      }`
    }).then(response => {

      let tResp = response?.data?.data?.sharedQuery?.getVehicleTypeList;

      if (tResp?.length > 0) {
        tDVType = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });
    setDVType(tDVType);


    let tData = {};

    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getVehicle(id: $id) {
              iD
              cATEGORYID
              cATEGORYNAME
              tYPEID
              tYPENAME
              mARK
              nUMBER
              fUELTYPEID
              fUELTYPENAME
              eNGINENUMBER
              cYLINDER
              cARRYINGCAPACITY
              sEAT
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getVehicle;
        if (tResp?.iD) {
          tData = tResp;
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.cATEGORYID ||
      !data?.tYPEID ||
      !data?.mARK ||
      !data?.nUMBER ||
      !data?.fUELTYPEID ||
      !data?.eNGINENUMBER ||
      !data?.cYLINDER ||
      !data?.cARRYINGCAPACITY ||
      !data?.sEAT) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
        tData.iSACTIVE = 1;
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: VehicleInputType) {
          sharedMutation {
            addVehicle(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addVehicle?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteVehicle($id: Int) {
        sharedMutation {
          deleteVehicle(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteVehicle == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}

            />
          </div>
        </div>
      }
    >
      <div className="add-product">
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Тээврийн хэрэгслийн ангилал" keyfield="cATEGORYID" keyfieldname="cATEGORYNAME" defaultValue={data.cATEGORYID} data={VehicleCategory} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={dVType} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Марк" keyfield="mARK" defaultValue={data.mARK} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Улсын дугаар" keyfield="nUMBER" defaultValue={data.nUMBER} checkreq={checkRule} />
          <div style={{ marginTop: 13 }}>
            <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Түлш" keyfield="fUELTYPEID" keyfieldname="fUELTYPENAME" defaultValue={data.fUELTYPEID} data={VehicleFuelType} checkreq={checkRule} />
          </div>
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldText required loading={loading} onChanged={fieldOnChanged} title="Хөдөлгүүрийн багтаамж" keyfield="eNGINENUMBER" defaultValue={data.eNGINENUMBER} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Цилиндр" keyfield="cYLINDER" defaultValue={data.cYLINDER} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Даац /тн/" keyfield="cARRYINGCAPACITY" defaultValue={data.cARRYINGCAPACITY} checkreq={checkRule} />
        </div>
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat required loading={loading} onChanged={fieldOnChanged} title="Суудлын тоо" keyfield="sEAT" defaultValue={data.sEAT} checkreq={checkRule} />
        </div>
      </div>
    </PanelBase>
  );
};

export default VehiclePanel;
