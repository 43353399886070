import React, { useEffect, useState, useContext } from 'react';
import { API } from "../../API/API";
import { message } from 'antd';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
const CallbackPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const navigate = useNavigate();
  const authState = localStorage.getItem("authState");
  const { setUserInfo } = useContext(UserContext);
  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    try {
      if (code != null && state === authState) {
        let token = "";
        let expires_in = 0;
        let expirationTime = 0;
        let nummail = "";
        await API.get("/api/auth/gettoken?code=" + code + "&state=" + state + "&authState=" + authState).then(response => {
          if (response?.status == 200 && response?.data?.retData?.length > 0) {
            
            token = response.data.retData[0].access_token;
            expires_in = response.data.retData[0].expires_in;
            expirationTime = new Date().getTime() / 1000 + expires_in; // Convert expiresIn to seconds
            const nummailObject = response.data.retData[1].find(item => item.type === "nummail");
            nummail = nummailObject.value
          }
        });
        let tExists = [];

        await API.get("/api/budget/auth?username=" + nummail).then(response => {
          if (response?.status == 200 && response?.data?.retData?.length > 0) {
            tExists = response?.data?.retData;
          }
        });
        if (tExists?.length > 0) {

          setUserInfo({ ...tExists[0], token: token, expires_in: expirationTime });
          localStorage.setItem("userInfo", JSON.stringify({ ...tExists[0], token: token, expires_in: expirationTime }));

          if (tExists[0].schooltypeid == 1) {
            navigate("/budget");
          } else if (tExists[0].schooltypeid == 2 || tExists[0].schooltypeid == 3  || tExists[0].schooltypeid == 6) {
            navigate("/budget/branch");
          }
          else if (tExists[0].schooltypeid == 4 || tExists[0].schooltypeid == 7) {
            navigate("/budget/department");
          }
        } else {
          message.warning("Системд нэвтрэх эрхгүй хэрэглэгч байна.");
        }
      }

    } catch (error) {
      message.error("Амжилтгүй " + error?.message);
      navigate("/login");
    }

  }
  return (
    <div>
      <h1>Түр хүлээнэ үү...</h1>
    </div>
  );
};

export default CallbackPage;
