import React, { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  getDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  ButtonDefault,
  ButtonSecondary,
  FieldDropdown,
  PanelBase,
  FieldNumberFormat,
  FieldTextArea,
  Row,
  Col,
  FieldComboBox,
} from "dw-components";
import FieldUpload from "../../../Components/FieldUpload/FieldUpload";
import { message } from "antd";
import { db } from "../../../Firebase/firebase-config";
import { MessageConst } from "../../../Const/MessageConst";
import { DEPARTMENTS } from "../../../Const/Departments";
import { BRANCHSCHOOLS } from "../../../Const/BranchSchools";

// Төсвийн дүнгээс хувиар
const BudgetByPercentPanel = (props) => {
  const userRole = {};

  const [data, setData] = useState({
    calculateMethodType: "budgetByPercent",
    indicatorId: props.indicatorData.indicatorId,
    category: props.indicatorData.category,
    distribution: "month",
  });

  const [checkRule, setCheckRule] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const [calculateMethodData, setCalculateMethodData] = useState();

  const nextStep = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.indicatorName) {
      isValid = false;
      setWarning("Эдийн засгийн ангилал" + MessageConst.fieldRequired);
    } else if (data.branchSchool != "ALL" && !data.department) {
      isValid = false;
      setWarning("Байгууллага нэгж" + MessageConst.fieldRequired);
    }

    if (isValid) {
      setCurrentStep((r) => r + 1);
      await changeDistribution(data.distribution);
    }
  };

  const prevStep = () => {
    setCurrentStep((r) => r - 1);
  };

  const changeDistribution = async (key) => {
    let tempData;
    switch (key) {
      case "month":
        tempData = { ...data };
        let perMonth =
          parseFloat(tempData.totalBudgetAmount.replace(/,/g, "")) / 12;
        setData({
          ...tempData,
          distribution: key,
          month1: perMonth,
          month2: perMonth,
          month3: perMonth,
          month4: perMonth,
          month5: perMonth,
          month6: perMonth,
          month7: perMonth,
          month8: perMonth,
          month9: perMonth,
          month10: perMonth,
          month11: perMonth,
          month12: perMonth,
        });
        break;
      case "season":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month10;
        delete tempData.month11;

        let perSeason =
          parseFloat(tempData.totalBudgetAmount.replace(/,/g, "")) / 4;

        setData({
          ...tempData,
          distribution: key,
          month3: perSeason,
          month6: perSeason,
          month9: perSeason,
          month12: perSeason,
        });
        break;
      case "year":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month3;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month6;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month9;
        delete tempData.month10;
        delete tempData.month11;
        delete tempData.month12;
        setData({
          ...tempData,
          distribution: key,
        });
        break;
      default:
        break;
    }
  };

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;

      let calculatePercent = tData.calculatePercent || 0;
      let duration = 0;
      let totalBudgetAmount = tData.budgetAmount || "0";

      if (field == "indicatorName") {
        let methodData;
        if (props.type == "DEPARTMENT") {
          methodData = calculateMethodData[key];
        } else {
          methodData = calculateMethodData[key][tData.department];
        }

        if (methodData) {
          totalBudgetAmount = methodData.totalBudgetAmount;
          tData = { ...tData, budgetAmount: totalBudgetAmount };
        } else {
          tData.budgetAmount = null;
        }

      } else if (field == "calculatePercent") {
        calculatePercent = tData.calculatePercent;
      }

      switch (tData.duration) {
        case "month":
          duration = 12;
          break;
        case "year":
          duration = 1;
          break;
        case "season":
          duration = 4;
          break;
        default:
          duration = 0;
          break;
      }

      tData.totalBudgetAmount = (
        (parseFloat(totalBudgetAmount.replace(/[^\d\.\-]/g, "")) *
          calculatePercent) /
        100 +
        parseFloat(totalBudgetAmount.replace(/[^\d\.\-]/g, "")) * duration
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setData(tData);
    }
  };

  // Writing data to firebase
  const createMethod = async (payload) => {
    try {
      props.setTempData({
        ...payload,
        type: props.type,
        userId: userRole.userid,
      });
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSave = async () => {
    setIsDisabled(true);
    await createMethod(data);
    props.onDismiss();
    setIsDisabled(false);
  };

  const onEditSubmit = async () => {
    // await updateMethod(data);
    props.onDismiss();
    props.forceUpdate();
  };

  useEffect(async () => {
    const IndicatorCollectionRef = collection(db, "budgetIndicators");
    // get department methods
    const Query = query(
      IndicatorCollectionRef,
      where("budgetId", "==", props?.budgetId)
    );

    const indicatorDocs = await getDocs(Query);
    setIndicators(
      indicatorDocs.docs.map((doc) => ({
        key: doc.id,
        text: doc.data().indicatorName,
      }))
    );

    if (props?.data) {
      setData({ ...props.data, ...data });
    }

    if (props?.calculateMethodData) {
      setCalculateMethodData(props?.calculateMethodData);
    }

    if (userRole.role == "DEPARTMENT" && !data.branchSchool) {
      let tData = { ...props?.data, ...data };
      tData.branchSchool = userRole.companyname;
      tData.department = userRole.department;
      setData(tData);
    }
    setDisableSchool(true);
  }, [props.isOpen]);

  let vPanel = null;
  let showDepartment = null;

  if (data.branchSchool == "ALL" || !data.branchSchool) {
    showDepartment = null;
  } else {
    showDepartment = (
      <div style={{ marginTop: 13 }}>
        <FieldDropdown
          required
          onChanged={fieldOnChanged}
          title="Байгууллагын нэгж"
          keyfield="department"
          defaultValue={data.department}
          checkreq={checkRule}
          data={DEPARTMENTS[data.branchSchool]}
          disable={disableSchool}
        />
      </div>
    );
  }

  if (currentStep == 0) {
    vPanel = (
      <PanelBase
        headerText="Нэмэх 1/2"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        buttons={
          <div style={{ display: "flex" }}>
            <div>
              <ButtonDefault text="Үргэлжлүүлэх" onClick={nextStep} />
            </div>
          </div>
        }
      >
        <div className="registerBudgetPercent">
          <div style={{ marginTop: 25 }}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Салбар сургууль"
              keyfield="branchSchool"
              defaultValue={data.branchSchool}
              checkreq={checkRule}
              data={BRANCHSCHOOLS}
              disable={disableSchool}
            />
          </div>
          {showDepartment}
          <div style={{ marginTop: 13 }}>
            <FieldComboBox
              required
              onChanged={fieldOnChanged}
              title="Эдийн засгийн ангилал"
              keyfield="indicatorName"
              defaultValue={data.indicatorName}
              checkreq={checkRule}
              data={indicators}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Төсөвлөсөн дүн"
              keyfield="budgetAmount"
              defaultValue={data.budgetAmount}
              checkreq={checkRule}
              disable={true}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Тооцох хувь"
              keyfield="calculatePercent"
              defaultValue={data.calculatePercent}
              checkreq={checkRule}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Хугацааны давтамж"
              keyfield="duration"
              defaultValue={data.duration}
              checkreq={checkRule}
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldNumberFormat
              required
              onChanged={fieldOnChanged}
              title="Нийт"
              keyfield="totalBudgetAmount"
              defaultValue={data.totalBudgetAmount}
              checkreq={checkRule}
              disable={true}
              data={[{ key: "1100", text: "1100" }]}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldTextArea
              onChanged={fieldOnChanged}
              title="Тайлбар"
              keyfield="description"
              defaultValue={data.description}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldUpload
              filetype="pdf"
              onChanged={fieldOnChanged}
              title="Файл хавсаргах (Зөвхөн PDF файл)"
              keyfield="budgetfile"
              defaultValue={data.budgetfile}
            />
          </div>
        </div>
      </PanelBase>
    );
  } else {
    vPanel = (
      <PanelBase
        headerText="Нэмэх 2/2"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        buttons={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 16 }}>
              <ButtonDefault
                onClick={() => onSave(false)}
                text="Хадгалаад шинэ"
              />
            </div>
            <ButtonSecondary onClick={prevStep} text="Буцах" />

            <div style={{ marginLeft: 16 }}>
              <ButtonDefault
                onClick={props?.calculateMethodId ? onEditSubmit : onSave}
                text={props?.calculateMethodId ? "Засах" : "Хадгалах"}
              />
            </div>
          </div>
        }
      >
        <div className="registerPerformance">
          <Col span={12}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Төсвийн хуваарилалт"
              keyfield="distribution"
              defaultValue={data.distribution}
              checkreq={checkRule}
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </Col>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="1-р сар"
                keyfield="month1"
                defaultValue={data.month1}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="7-р сар"
                keyfield="month7"
                defaultValue={data.month7}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="2-р сар"
                keyfield="month2"
                defaultValue={data.month2}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="8-р сар"
                keyfield="month8"
                defaultValue={data.month8}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="3-р сар"
                keyfield="month3"
                defaultValue={data.month3}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="9-р сар"
                keyfield="month9"
                defaultValue={data.month9}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="4-р сар"
                keyfield="month4"
                defaultValue={data.month4}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="10-р сар"
                keyfield="month10"
                defaultValue={data.month10}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="5-р сар"
                keyfield="month5"
                defaultValue={data.month5}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="11-р сар"
                keyfield="month11"
                defaultValue={data.month11}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="6-р сар"
                keyfield="month6"
                defaultValue={data.month6}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="12-р сар"
                keyfield="month12"
                defaultValue={data.month12}
              />
            </Col>
          </Row>
        </div>
      </PanelBase>
    );
  }

  return vPanel;
};

export default BudgetByPercentPanel;
