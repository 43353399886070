import React, { useEffect, useState } from "react";
import {
  getDoc,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import {
  ButtonDefault,
  FieldDropdown,
  FieldNumberFormat,
  FieldText,
  PanelBase,
} from "dw-components";
import { db } from "../../../../Firebase/firebase-config";
import { message } from "antd";
import { MessageConst } from "../../../../Const/MessageConst";
import ASSETCATEGORIES from "../../../../Const/AssetCategories";

const AssetsPanel = (props) => {
  const [data, setData] = useState({
    categoryType: "parent",
  });
  const [checkRule, setCheckRule] = useState(false);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [assetCats, setAssetCats] = useState([]);

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      setData(tData);
    }
  };

  // Writing data to firebase
  const createAsset = async (payload) => {
    try {
      const AssetsCollectionRef = collection(db, "assets");
      await addDoc(AssetsCollectionRef, payload);
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const getAsset = async (id) => {
    try {
      const AssetDoc = doc(db, "assets", id);
      const response = await getDoc(AssetDoc);

      setData(response.data());
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const updateAsset = async (payload, id) => {
    try {
      const AssetDoc = doc(db, "assets", id);
      await updateDoc(AssetDoc, payload);
      message.success("Амжилттай засагдлаа");
      setData({});
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSubmit = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    // if (!data.categoryName) {
    //   isValid = false;
    //   setWarning("Хөрөнгийн ангиллын нэр" + MessageConst.fieldRequired);
    // } else if (!data.marketPrice) {
    //   isValid = false;
    //   setWarning("Зах зээлийн үнэ" + MessageConst.fieldRequired);
    // }

    if (isValid) {
      setIsDisabled(true);
      await createAsset(data);
      props.onDismiss();
      props.forceUpdate();
      setIsDisabled(false);
      setCheckRule(false);
    }
  };

  const onEditSubmit = async () => {
    await updateAsset(data, props?.itemId);
    props.onDismiss();
    props.forceUpdate();
  };

  const getAllAssets = async () => {
    const AssetsRef = collection(db, "assets");
    const categoryQuery = query(
      AssetsRef,
      where("categoryType", "==", "parent")
    );

    const assetDocs = await getDocs(AssetsRef);

    const assets = assetDocs.docs.map((doc) => ({
      key: doc.id,
      text: doc.data().categoryName,
    }));

    return assets;
  };

  useEffect(async () => {
    if (props.itemId) {
      getAsset(props.itemId);
    }

    const categories = await getAllAssets();
    setAssetCats(categories);
  }, [props.isOpen]);

  let showFields = null;

  switch (data.categoryType) {
    case "parent":
      showFields = (
        <>
          <div style={{ marginTop: 13 }}>
            <FieldDropdown
              onChanged={fieldOnChanged}
              title="Хөрөнгийн үндсэн ангилал"
              keyfield="mainCategory"
              checkreq={checkRule}
              defaultValue={data.mainCategory}
              data={ASSETCATEGORIES}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldText
              required
              onChanged={fieldOnChanged}
              title="Хөрөнгийн дэд ангилллын нэр"
              keyfield="categoryName"
              checkreq={checkRule}
              defaultValue={data.categoryName}
            />
          </div>
        </>
      );
      break;
    case "child":
      showFields = (
        <>
          <div style={{ marginTop: 13 }}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Хөрөнгийн үндсэн ангилал"
              keyfield="parentCategory"
              checkreq={checkRule}
              defaultValue={data.parentCategory}
              data={assetCats}
            />
          </div>
          <div style={{ marginTop: 13 }}>
            <FieldText
              required
              onChanged={fieldOnChanged}
              title="Хөрөнгийн дэд ангиллын нэр"
              keyfield="categoryName"
              checkreq={checkRule}
              defaultValue={data.categoryName}
            />
          </div>
        </>
      );
      break;
  }

  return (
    <PanelBase
      headerText={props?.itemId ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      warning={warning}
      PanelType="medium"
      buttons={
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.itemId ? "Засах" : "Хадгалах"}
              onClick={props?.itemId ? onEditSubmit : onSubmit}
              disable={isDisabled}
            />
          </div>
        </div>
      }
    >
      <div className="add-asset">
        <div style={{ marginTop: 25 }}>
          <FieldDropdown
            required
            onChanged={fieldOnChanged}
            title="Ангиллын төрөл"
            keyfield="categoryType"
            checkreq={checkRule}
            defaultValue={data.categoryType}
            data={[
              { key: "parent", text: "Үндсэн ангилал" },
              { key: "child", text: "Дэд ангилал" },
            ]}
          />
        </div>
        {showFields}
        <div style={{ marginTop: 13 }}>
          <FieldNumberFormat
            required
            onChanged={fieldOnChanged}
            title="Зах зээлийн үнэ"
            keyfield="marketPrice"
            checkreq={checkRule}
            defaultValue={data.marketPrice}
          />
        </div>
      </div>
    </PanelBase>
  );
};

export default AssetsPanel;
