import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, ButtonSecondary, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { UserContext } from "../../Context/UserContext";
import { GENDER } from "../../Const/Gender";
import { APIGraphQL } from "../../API/APIGraphQL";
import { API } from "../../API/API";
import moment from "moment";
import { Icon } from '@fluentui/react/lib/Icon';
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";

const PensionPanel = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [dPosition, setDPosition] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [attachData, setAttachData] = useState([]);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);
        let vSuccess = false;

        setLoadingSave(true);
        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {

            let nDataEdit = [];
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                let currentschoolid = schoolid;
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                } 
                nDataEdit.push({
                    iD: parseInt(tR.id ?? 0),
                    sCHOOLID: parseInt(currentschoolid),
                    bUDGETID: parseInt(props?.id),
                    lASTNAME: tR?.lastname,
                    fIRSTNAME: tR?.firstname,
                    pOSITIONID: parseInt(tR?.positionid ?? 0),
                    pOSITIONNAME: tR?.positionname,
                    gENDERID: parseInt(tR?.genderid ?? 0),
                    gENDERNAME: tR?.gendername,
                    rEGNO: props?.regno,
                    pENSIONID: parseInt(tR?.pensionid ?? 0),
                    pENSIONNAME: tR?.pensionname,
                    pAYMONTH: parseFloat(tR?.paymonth ?? 0),
                    sENIORITY: parseFloat(tR?.seniority ?? 0),
                    bASESALARY: parseFloat(tR?.basesalary ?? 0),
                    tOTALAMOUNT: parseFloat((tR?.paymonth || 0) * (tR?.basesalary || 0)),
                    cREATED: tR?.cREATED ?? moment().format("YYYY.MM.DD HH:mm:SS"),
                    cREATEDBY: tR?.cREATEDBY ?? userInfo?.username
                });
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetPensionInputType]) {
            //         budgetMutation {
            //           addBudgetPension(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": nDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetPension?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // })

            await API.post("api/budget/calcBudgetPension?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,nDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
            //vSuccess = await calcfunc("calcBudgetPension", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
            setLoadingSave(false);
        }

        await props.afterSave();
        props.onDismiss();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDPosition = [];
        
        let alldep = isalldep;
        if(alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if(userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid) 
            pSchoolId = 0;

        setLoading(true);

        await API.get("/api/budget/getPensions?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;   
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });
        await APIGraphQL.post("", {
            query: ` {
                sharedQuery {
                  getPositionsfiltered(isuseuser: 0) {
                    key: iD
                    text: nAME
                  }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getPositionsfiltered?.length > 0) {
                tDPosition = tResp?.sharedQuery?.getPositionsfiltered;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setData(tData);
        setDataEdit(tData);
        setDPosition(tDPosition);
    }

    const setSchools = async(pSchoolId) => 
    {
        const schools = await getSchools(pSchoolId);
        if(userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);            
        }
        else if(userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6){
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;    
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
        }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }
        if (pField == "seniority") {
            tDataEdit[pIndex]["pensionname"] = "";
            await APIGraphQL.post("", {
                query: `query ($seniority: Int) {
                sharedQuery {
                    getPensionBySeniority(seniority: $seniority) {
                     pensionid: iD
                     pensionname: pENSIONNAME
                     paymonth: pAYMONTH
                  }
                }
              }`,
                variables: {
                    "seniority": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getPensionBySeniority;
                if (tResp.pensionname) {
                    tDataEdit[pIndex]["pensionname"] = tResp.pensionname;
                }
                if(tResp.pensionid){
                    tDataEdit[pIndex]["pensionid"] = tResp.pensionid;
                    tDataEdit[pIndex]["paymonth"] = tResp.paymonth;
                }
            }).catch(() => {
                setLoading(false);
            });

            tDataEdit[pIndex]["totalamount"] = (tDataEdit[pIndex]["paymonth"] || 0) * (tDataEdit[pIndex]["basesalary"] || 0) 
        }
        
        if (pField == "basesalary") {
            tDataEdit[pIndex]["totalamount"] = (tDataEdit[pIndex]["paymonth"] || 0) * (tDataEdit[pIndex]["basesalary"] || 0) 
        } else if (pField == "paymonth") {
            tDataEdit[pIndex]["totalamount"] = (tDataEdit[pIndex]["paymonth"] || 0) * (tDataEdit[pIndex]["basesalary"] || 0) 
        } 

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                        delBudgetPension(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetPension == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBudgetPension?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
            }
            // vSuccess = await calcfunc("calcBudgetPension", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
          }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
        query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
          variables: {
            "typeid": parseInt(typeid)
        }
        }).then(response => {       
        let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
            setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);  
        setDepId(pSchoolId);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Тэтгэвэр, тэтгэмж оруулах"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={(!props.disable &&
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                </div>
            )}
        >
            {props?.showSchool && (
               <div style={{ marginTop: 13 }}>
                   <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
               </div>
            )}
            {props?.showSchool && (
               <div style={{ marginTop: 24 }}>
                   <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
               </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (<LoadSpin />) : (
               <div>
               <div>
             <Row justify="space-between">
              <Col> 
                   {(!disable &&
                  <Row justify="start" > 
                      <Col style={{ marginTop: 24 }}>
                            <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                      </Col>
                  </Row>
                   )}
              </Col>
            <Row style={{ marginTop: 30 }}>
                <Col justify="end">
                             /мян.төгрөг/
                </Col>
            </Row>
          </Row>
         </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                dataField="schoolcode"
                                caption="Сургууль"
                                width={150}
                                alignment='right'
                                groupIndex={0}/>
                            )}
                            {disable && props?.showDep && (
                              <Column
                              dataField="depcode"
                              caption="Тэнхим"
                              width={150}
                              alignment='right'
                              groupIndex={1} />
                          )}
                            <Column
                                width={150}
                                dataField="lastname"
                                caption="Овог"
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "lastname")} defaultValue={item.data?.lastname} />
                                    );
                                }}
                            />
                            <Column
                                dataField="firstname"
                                caption="Нэр"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "firstname")} defaultValue={item.data?.firstname} />
                                    );
                                }} />
                            <Column
                                dataField="positionid"
                                caption="Албан тушаал"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "positionid", pNameValue, "positionname")} defaultValue={item.data?.positionid} data={dPosition} />
                                    );
                                }} />
                            <Column
                                dataField="genderid"
                                caption="Хүйс"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "genderid", pNameValue, "gendername")} defaultValue={item.data?.genderid} data={GENDER} />
                                    );
                                }} />
                            <Column
                                dataField="regno"
                                caption="Регистр"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "regno")} defaultValue={item.data?.regno} />
                                    );
                                }} />
                            <Column
                                dataField="seniority"
                                caption="МУИС-д ажилласан жил"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "seniority")} defaultValue={item.data?.seniority} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="pensionname"
                                caption="Тэтгэмж бодох интервал"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "pensionname")} defaultValue={item.data?.pensionname}/>
                                    );
                                }} />
                            <Column
                                dataField="paymonth"
                                caption="Тэтгэмж олгох сар"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "paymonth")} defaultValue={item.data?.paymonth} fixedDecimalScale={true} />
                                    );
                                }} />

                            <Column
                                dataField="basesalary"
                                caption="Үндсэн цалин"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "basesalary")} defaultValue={item.data?.basesalary} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="totalamount"
                                caption="Нийт"
                                format="fixedPoint"
                                alignment='center'
                                minwidth={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => {}} defaultValue={item.data?.totalamount} fixedDecimalScale={true} />
                                    );
                                }} 
                                />

                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalamount"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />

                            </Summary>
                        </DataGrid>
                        <div style={{ marginTop: 24 }}>
                            <FieldUploadRest id={props?.indId} schoolid={userInfo?.schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(userInfo?.schoolid); props?.afterAttach(); }} />
                        </div>
                    </div>
                </div>
            )}
        </PanelBase >
    );
}


export default PensionPanel;