import React, { useState, useEffect } from "react";
import { Color, ConstCss } from "../../Const/Const";
import { ReactComponent as Divider } from "../../svg/verticalDividerGray.svg";
import SearchIcon from "../../svg/IconClass/SearchIcon";
import ExitIcon from "../../svg/IconClass/ExitIcon";
import moment from "moment";

import { TextField, CommandBarButton, FontSizes } from "@fluentui/react";
import { debounce } from "lodash";

import { PageFilter, GridFilter, IconExcel, IconFilter, ButtonIcon } from "dw-components";

const PageHeaderOptions = (props) => {
  const [vsysdate, setVSysdate] = useState(
    moment().format("YYYY.MM.DD hh:mm:ss").toString()
  );
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(props.columns ? props.columns : []);
  const [filterInit, setFilterInit] = useState([]);

  const onSearch = debounce((p_value) => {
    let filteredData = [...data];
    if (p_value?.target?.value && data?.length > 0) {
      filteredData = data?.filter((r) => {
        let tValue = Object.keys(r)
          .map((key) => {
            return r[key];
          })
          ?.join("")
          ?.toLowerCase();

        if (tValue?.indexOf(p_value?.target?.value?.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (props.onSearch) {
      props.onSearch(filteredData);
    }
  }, 1000);

  const onChangeFilter = () => {
    let filteredData = [...data];
    if (props.onSearch) {
      props.onSearch(filteredData);
    }
    setShowFilter((r) => !r);
  };

  useEffect(() => {
    let tData = [];
    if (props.data) {
      tData = props.data;
    }
    setData(tData);

    let tFilterCol = props.columns?.filter((r) => r.filter == true);
    if (tFilterCol?.length > 0) {
      let tFilterInit = [];

      setFilterInit(tFilterInit);
    }
  }, [props.data]);

  return (
    <div>
      <div
        style={{
          height: 50,
          width: "100%",
          backgroundColor: Color.grayBackground,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div style={{ ...ConstCss.flexCenter }}>
          {!props.showLeftFilter && (
            <div style={{ marginRight: 16, marginLeft: 8 }}>
              <CommandBarButton
                text={props.title}
                menuProps={props.menuProps}
                style={{
                  fontSize: 20
                }}
              />
            </div>
          )}
          {props.showLeftFilter && (
            <div style={{ marginRight: 16 }}>
              <PageFilter
                onChange={props.changeFilter}
                value={props.filterValue}
                data={props.filterData}
                dataCount={props.dataCount}
              />
            </div>
          )}
          <div style={{ height: 28 }}>
            <Divider />
          </div>
          <div style={{ marginLeft: 16 }}>
            {props.buttonTitle && (
              <ButtonIcon
                text={props.buttonTitle}
                iconName="Add"
                onClick={props.onButtonClick}
              />
            )}
          </div>
          {props.addButton && (
            <div style={{ marginLeft: 16 }}>{props.addButton}</div>
          )}
        </div>
        <div style={{ ...ConstCss.flexCenter, paddingRight: 16 }}>
          {props.addRightButton ? props.addRightButton : null}
          {props.data ? (
            <>
              <div onClick={onChangeFilter} style={{ marginLeft: 8 }}>
                <IconFilter />
              </div>
              <div style={{ marginLeft: 16 }}>
                <IconExcel />
              </div>
            </>
          ) : null}
        </div>
      </div>
      {showFilter ? (
        <div
          style={{
            height: 50,
            width: "100%",
            backgroundColor: Color.grayHeaderBack,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchIcon />
            <TextField
              onChange={onSearch}
              style={{ backgroundColor: Color.grayHeaderBack, minWidth: 400 }}
              borderless
              placeholder="Хайх..."
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={onChangeFilter}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ExitIcon />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default PageHeaderOptions;
