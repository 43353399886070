import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, FieldNumberFormat, FieldDropdownMulti, ButtonSecondary
} from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { APIGraphQL } from "../../API/APIGraphQL";
import { UserContext } from "../../Context/UserContext";
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import moment from "moment";

const LightCostGridPanel = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [attachData, setAttachData] = useState([]);
    const [dCurrentYear, setCurrentYearData] = useState([]);
    const [currentyears, setCurrentYears] = useState([]);
    const [selectedmonths, setSelectedMonths] = useState([]);
    const [currentyear, setCurrentYear] = useState([]);
    const [nextyear, setNextYear] = useState([]);
    const [disable, setDisable] = useState(false);

    const onSave = async () => {

        let tDataEdit = dataEdit;
        if (tDataEdit?.length > 0 && props?.id) {
            setLoadingSave(true);
            let vSuccess = false;
            let tBSecDtl = [];
            let k = 0;
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i]};
                for(let j = 0; j < currentyears.length; j++)
                {
                    let tRNew = {};
                    tRNew.iD = tR[("'" + currentyears[j].text.toString() + "'" + "_ID")];
                    tRNew.bUDGETID = parseInt(props?.id);
                    tRNew.sCHOOLID = userInfo?.schoolid;
                    tRNew.bUILDINGID = tR.buildingid;
                    tRNew.bUILDINGNAME = tR.buildingname;
                    tRNew.cALCSCHOOLID = tR.calcschoolid;
                    tRNew.cALCSCHOOLNAME = tR.calcschoolname;
                    tRNew.uSERCODEID = tR.usercodeid;
                    tRNew.uSERCODE = tR.usercode;
                    tRNew.mETERID = tR.meterid;
                    tRNew.mETERNO = tR.meterno;
                    tRNew.lIGHTCOSTID = tR["'" + currentyears[j].text.toString() + "'" + "_LIGHTCOSTID"];
                    tRNew.cOSTDATE = currentyears[j].text.toString();
                    tRNew.sCHOOLAMOUNT = tR[("'" + currentyears[j].text.toString() + "'")];
                    tRNew.iD = 0;
                    tRNew.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                    tRNew.cREATEDBY = userInfo?.username;

                    tBSecDtl[k] = tRNew;
                    k += 1;
                }
            }
            await APIGraphQL.post("", {
                query: `mutation ($data: [BudgetLightCostInputType]) {
                    budgetMutation {
                        addBudgetLightCost(data: $data) {
                            iD
                        }
                    }
                }`,
                variables: {
                    "data": tBSecDtl
                }
            }).then(response => {
                if (response?.data?.data?.budgetMutation?.addBudgetLightCost?.length > 0) {
                    API.post("api/budget/calcBUseCost?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&typeid=3").then(response => {
                        if (response?.status == 200 && response?.data?.retType == 0) {
                            vSuccess = true;
                            message.success("Амжилттай");
                        }
                        }).catch(() => {
                            setLoading(false);
                        });
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }

        } else {
            props.onDismiss();
        }
        await props.afterSave();
    }

    const onGenerate = async () => {
        let yearsandmonth = [];
        
        selectedmonths.forEach((years) => {
            let tIndex = dCurrentYear?.map(r => r.key).indexOf(years);
            if (tIndex >= 0) {
                yearsandmonth.push(dCurrentYear[tIndex].text);
            }
        });
        let tData = [];
        await API.get("/api/budget/getBudgetLightCostCalcList?months=" + yearsandmonth.toString()).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setData(tData);
        setDataEdit(tData);
        let tMonths = [];
        selectedmonths.forEach((years) => {
            let tIndex = dCurrentYear?.map(r => r.key).indexOf(years);
            if (tIndex >= 0) {
                tMonths.push(dCurrentYear[tIndex]);
            }
        });
        setCurrentYears(tMonths);
        setCurrentYear(tMonths[0]?.year);
        setNextYear(tMonths[tMonths.length - 1]?.year);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        await API.get("/api/budget/getBudgetLightHeatCostListTemp?budgetid=" + props?.id + "&typeid=3" + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Гэрэл цахилгааны тооцоо." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
    }

    const getData = async () => {
        let tData = [];
        let tMonths = [];
        let tSavedmonths = [];
        setLoading(true);
        await API.get("/api/budget/getBudgetLightHeatCostList?budgetid=" + props?.id + "&typeid=3")
        .then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.data.length > 0) {
                    tData = response?.data?.retData?.data;
                }
                if (response?.data?.retData?.months.length > 0) {
                    tMonths = response?.data?.retData?.months;
                }
                if (response?.data?.retData?.savedmonths.length > 0) {
                    tSavedmonths = response?.data?.retData?.savedmonths;
                }
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        // await getAttachData();
        setLoading(false);
        setCurrentYearData(tMonths);
        setCurrentYears(tSavedmonths);
        setCurrentYear(tSavedmonths[0]?.year);
        setNextYear(tSavedmonths[tSavedmonths.length - 1]?.year);
        setData(tData);
        setDataEdit(tData);
    }

    useEffect(() => {
        getData();
    }, [props?.id, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const fieldPrevOnChanged = (key, text, field, fieldname) => {
        setSelectedMonths(key);
    }

    const onDelete = async () => {
        let tSuccess = false;
        await APIGraphQL.post("", {
            query: `mutation deleteBudgetLightCost($budgetid: Int) {
                    sharedMutation {
                        deleteBudgetLightCost(budgetid: $budgetid)
                    }
                  }`,
              variables: {
                "budgetid": parseInt(props?.id)
            },
        }).then((response) => {
            if (response?.data?.data?.sharedMutation?.deleteBudgetLightCost == 1) {
                tSuccess = true;
                if (props.afterSave) {
                    props.afterSave();
                }
            }
            if (tSuccess) {
                message.success("Амжилттай");
                props.onDismiss();
            }
        });
    };

    return (
        <PanelBase
            headerText="Гэрэл цахилгаан"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            onDelete={onDelete}
            PanelType="large"
            delete={props?.id > 0 ? true : false}
            buttons={(
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
    
    <div style={{ marginTop: 13, display: "flex"}}>
        <div style={{ width:"20%"}}>
            <FieldDropdownMulti required loading={loading} onChanged={fieldPrevOnChanged} title="Жил" keyfield="currentyear" defaultValue={currentyears.key} data={dCurrentYear}/>
        </div>
        <div style={{ marginLeft:"2%", width:"20%", float:"right", marginTop: 25}}>
            <ButtonSecondary text="Тооцох" onClick={onGenerate}/>
        </div>
    </div>
    <div style={{ display: "flex", justifyContent: "flex-end", marginRight: 16, marginTop: 16 }}>
                    /мян.төгрөг/
         </div>
    {loading ? (<LoadSpin />) : (
    <div style={{ marginTop: 24 }}>
        <DataGrid
            dataSource={data}
            columnAutoWidth={true}
            wordWrapEnabled={true}
            showRowLines
            showBorders
        >
        <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
        <Column Width={50} caption="№" alignment='center'
            cellRender={(item) => {
                return (
                    <div> {item.key?.arrayIndex + 1 + "."} </div>
                );
            }}
        />
        <Column
            dataField="buildingname"
            width={250}
            caption="Байр"
        />
        <Column
            width={250}
            dataField="calcschoolname"
            caption="Харьяа нэгж"
        />
        <Column
            dataField="usercode"
            caption="Хэрэглэгчийн код"
            width={80}
            format="fixedPoint" />
    <Column caption={"ГҮЙЦЭТГЭЛ ("+ currentyear +" оны сарууд)"} alignment='center'>
        {currentyears.filter(r=>r.year == currentyear)?.map(r => {
                return (
                    <Column
                        caption={r.key}
                        dataField={"'" + r.text?.toString() + "'" + "current"}
                        width={100}
                        format={"fixedPoint"}
                        cellRender={(item) => {
                            return (
                                <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, ("'" + r.text?.toString() + "'"))} defaultValue={item?.data?.[("'" + r.text + "'")]} fixedDecimalScale={true} />
                            );
                        }} />
                );
            })}            
    </Column>
    {nextyear !== currentyear && (
        <Column caption={"ГҮЙЦЭТГЭЛ ("+ nextyear +" оны сарууд)"} alignment='center'>
            {currentyears.filter(r=>r.year == nextyear)?.map(r => {
                return (
                    <Column
                        caption={r.key}
                        dataField={"'" + r?.text.toString() + "'"}
                        width={100}
                        format={"fixedPoint"}
                        cellRender={(item) => {
                            return (
                                <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, ("'" + r.text?.toString() + "'"))} defaultValue={item?.data?.[("'" + r.text + "'")]} fixedDecimalScale={true} />
                            );
                        }} />
                );
            })}
        </Column>
    )}
                <Column
                    dataField="total"
                    caption="Бүгд дүн"
                    alignment='center'
                    minwidth={150}
                    format="fixedPoint"
                    cellRender={(item) => {
                        return (
                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.total} fixedDecimalScale={true} />
                        );
                    }} />
                <Summary>
                    <TotalItem
                        column="№"
                        displayFormat="Нийт:"
                    />
                    <TotalItem
                        column="total"
                        summaryType="sum"
                        valueFormat="#,##0.00"
                        alignment="right"
                        displayFormat="{0}₮"
                    />
                </Summary>
            </DataGrid>
            <div style={{ marginTop: 24 }}>
                <FieldUploadRest id={props?.indId} schoolid={userInfo?.schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(userInfo?.schoolid); props?.afterAttach(); }} />
            </div>
        </div>
    )}
        </PanelBase >
    );
}

export default LightCostGridPanel;