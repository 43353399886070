import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { Table, Form, Input, message } from "antd";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { categoryTree, groupListData, getOthersDeparmentData } from "../../../Utils";

import OTHERINDICATORS from "../../../Const/OtherIndicators";

const EditableContext = createContext(null);

const OtherIndicators = (props) => {
    const userRole = {};
    const IndicatorCollectionRef = collection(db, "otherIndicators");
    const IndicatorGroupsCollectionRef = collection(db, "otherGroups");

    const [data, setData] = useState([]);

    const columns = [
        {
            title: "Үзүүлэлтийн нэр",
            dataIndex: "name",
            key: "name",
            width: "25%",
        },
        {
            title: "Дүн",
            dataIndex: "value",
            key: "value",
            width: "15%",
            editable: true,
        },
        {
            title: "",
            dataIndex: "",
            key: "x",
            width: 100,
            editable: true,
        },
    ];

    // Get list data from firebase
    useEffect(async () => {
        const indicatorDocs = await getDocs(IndicatorCollectionRef);
        const IndicatorGroupDocs = await getDocs(IndicatorGroupsCollectionRef);

        const indicators = indicatorDocs.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
            ...doc.data(),
        }));

        const categories = await categoryTree(indicatorGroups, "groupParent", "groupName");

        const groupedIndicators = await groupListData(indicators);
        const listData = await getOthersDeparmentData(categories, groupedIndicators, userRole.companyname, userRole.department);

        setData(listData);
        // setData(OTHERINDICATORS);
    }, []);

    const handleSave = (row) => {
        const tempData = [...data];
        tempData.map((item) => {
            item.children.map(async (r) => {
                if (r.code == row.code) {
                    r.value = row.value;
                    const IndicatorDoc = doc(db, "otherIndicators", row.indicatorId);

                    if (!row.values) row.values = {};
                    if (row.values[userRole.companyname]) {
                        await updateDoc(IndicatorDoc, {
                            values: { ...row.values, [userRole.companyname]: { ...row?.values[userRole.companyname], [userRole.department]: row.value } },
                        });
                    } else {
                        row.values[userRole.companyname] = {};
                        await updateDoc(IndicatorDoc, {
                            values: { ...row.values, [userRole.companyname]: { ...row?.values[userRole.companyname], [userRole.department]: row.value } },
                        });
                    }
                }
            });
            return item;
        });

        setData(tempData);
    };

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const customColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => {
                if (record.indicatorId) {
                    return {
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave: handleSave,
                    };
                }
            },
        };
    });

    return (
        <div style={{ margin: 16 }}>
            <Table
                components={components}
                rowClassName={() => "editable-row"}
                columns={customColumns}
                dataSource={data}
                childrenColumnName="children"
                scroll={{ x: 1500 }}
                size="small"
                bordered
                pagination={false}
            />
        </div>
    );
};

export default OtherIndicators;
