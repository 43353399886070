import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, LoadScreen, FieldDropdown, ButtonSecondary, FieldText } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import FileDownload from 'js-file-download';
import { SIGNATURETYPE } from "../../Const/BudgetSignatureConst";
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { Icon } from '@fluentui/react/lib/Icon';
import { setDisableIndicators, setAllDep, calcfunc } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";

const BudgetFuelFee = (props) => {

    const { userInfo } = useContext(UserContext);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [dTNumber, setdtNumber] = useState([]);
    const [dTSignature, setdTSignature] = useState([]);
    const [dTSignatureEdit, setdTSignatureEdit] = useState([]);
    const [dTSignatureType, setdTSignatureType] = useState(SIGNATURETYPE);
    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const onSave = async () => {
        let tDataEdit = dataEdit?.filter(r => r.edit);
        let vSuccess = false;
        if ((tDataEdit?.length > 0) && props?.id && (depid || schoolid)) {
            setLoadingSave(true);
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.sCHOOLNAME = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                }
                tR.sCHOOLID = parseInt(currentschoolid);
                tR.iD = tR.id;
                tR.bUDGETID = parseInt(props?.id);
                tR.vEHICLEID = tR.vehicleid;
                tR.mARK = tR.mark;
                tR.nUMBER = tR.number;
                tR.fUELTYPEID = tR.fueltypeid;
                tR.fUELTYPENAME = tR.fueltypename;
                tR.wORKDAY = tR.workday;
                tR.fUELNORM = tR.fuelnorm;
                tR.kMDAYNORM = tR.kmdaynorm;
                tR.fUELDAYNORM = tR.fueldaynorm;
                tR.oILQTY = tR.oilqty;
                tR.fUELPRICE = tR.fuelprice;
                tR.oILPRICE = tR.oilprice;
                tR.tOTALFUELPRICE = tR.totalfuelprice;
                tR.tOTALOILPRICE = tR.totaloilprice;
                tR.tOTALPRICE = tR.totalprice;
                tR.vEHICLEFUELNORMID = tR.vehiclefuelnormid;
                delete tR.id;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.vehiclefuelnormid;
                delete tR.schoolname;
                delete tR.vehicleid;
                delete tR.mark;
                delete tR.number;
                delete tR.fuletypeid;
                delete tR.fueltypename;
                delete tR.workday;
                delete tR.fuelnorm;
                delete tR.kmdaynorm;
                delete tR.fueldaynorm;
                delete tR.oilqty;
                delete tR.fuelprice;
                delete tR.oilprice;
                delete tR.totalfuelprice;
                delete tR.totaloilprice;
                delete tR.totalprice;
                delete tR.fueltypeid;
                delete tR.ownername;
                delete tR.schoolcode;
                delete tR.depcode;
                delete tR.year;
                delete tR.arrayIndex;
                delete tR.edit;
                delete tR.created;
                delete tR.createdby;
                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;

                tDataEdit[i] = tR;
            }
            // await APIGraphQL.post("", {
            //     query: `              
            //     mutation ($data: [BudgetVehicleFuelNormInputType]) {
            //       budgetMutation {
            //         addBudgetVehicleFuelNorm(data: $data) {
            //           iD
            //         }
            //         }
            //     }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetVehicleFuelNorm?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // });
            await API.post("api/budget/calcBFuel?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            //vSuccess = await calcfunc("calcBFuel", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
            setLoadingSave(false);

            if (vSuccess) {
                props?.onDismiss();
            }
        } else {
            props.onDismiss();
        }

        let tDataSign = dTSignatureEdit?.filter(r => r.edit);
        if (tDataSign?.length > 0) {
            for (let i = 0; i < tDataSign.length; i++) {
                let tR = { ...tDataSign[i] };
                tR.sOURCEID = 61;
                tR.sOURCENAME = "BUDGETFUELFEE";
                tR.sCHOOLID = 0;
                tR.sCHOOLNAME = "";
                delete tR.arrayIndex;
                delete tR.edit;
                tDataSign[i] = tR;
            }
            await APIGraphQL.post("", {
                query: `              
                 mutation ($dataSign: [BudgetSignatureInputType]) {
                    sharedMutation {
                        addBudgetSignature(data: $dataSign) {
                            iD
                        }
                    }
                }`,
                variables: {
                    "dataSign": tDataSign
                }
            }).then(response => {
                if (response?.data?.data?.sharedMutation?.addBudgetSignature?.length > 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            }).catch(() => {
                setLoadingSave(false);
            });

            if (vSuccess) {
                props?.onDismiss();
            }

        } else {
            props.onDismiss();
        }

        await props.afterSave();
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDTNumber = [];
        let tDTSignature = [];

        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        setLoading(true);
        await API.get("/api/budget/getbudgetFuelfee?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }
                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `query ($sourceid: Int) {
                sharedQuery {
                  getVehicleList {
                    key: iD
                    text: nUMBER
                  }
                  getBudgetSignature(sourceid: $sourceid) {
                    iD
                    aCTIONID
                    aCTIONNAME
                    jOBTITLE
                    eMPLOYEENAME
                    sOURCEID
                    sOURCENAME
                    sCHOOLID
                    sCHOOLNAME
                  }
                }
              }
              `,
            variables: {
                "sourceid": 61
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getVehicleList?.length > 0) {
                tDTNumber = tResp?.sharedQuery?.getVehicleList;
            }
            if (tResp?.sharedQuery?.getBudgetSignature?.length > 0) {
                tDTSignature = tResp?.sharedQuery?.getBudgetSignature;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        for (let i = 0; i < tDTSignature?.length; i++) {
            tDTSignature[i].arrayIndex = i;
        }

        await getAttachData(pSchoolId);
        setLoading(false);
        setdtNumber(tDTNumber);
        setData(tData);
        setDataEdit(tData);
        setdTSignature(tDTSignature);
        setdTSignatureEdit(tDTSignature);
    }

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд" });
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "vehicleid") {
            await APIGraphQL.post("", {
                query: `query ($vehicleid: Int) {
                    sharedQuery {
                        getVehicleFuelNormByVehicleID(vehicleid: $vehicleid) {
                            vehiclefuelnormid: iD
                            mark: mARK
                            fueltypeid: fUELTYPEID
                            fueltypename: fUELTYPENAME
                            fuelnorm: fUELNORM
                            fueldaynorm: fUELDAYNORM
                            fuelprice: fUELPRICE
                            oilqty: oILNORM
                            oilprice: oILPRICE
                            }
                        }
                    }
                    `,
                variables: {
                    "vehicleid": parseInt(pValue)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getVehicleFuelNormByVehicleID;
                if (tResp) {
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                    setData(tDataEdit);
                }
            }).catch(() => {
                setLoading(false);
            });
        } else if (pField == "workday") {
            tDataEdit[pIndex]["totaloilprice"] = (tDataEdit[pIndex]["oilprice"] || 0) * (tDataEdit[pIndex]["oilqty"] || 0);
            tDataEdit[pIndex]["totalfuelprice"] = (tDataEdit[pIndex]["fuelprice"] || 0) * (tDataEdit[pIndex]["fueldaynorm"] || 0) * pValue / 1000;
            tDataEdit[pIndex]["totalprice"] = (tDataEdit[pIndex]["totaloilprice"] || 0) + (tDataEdit[pIndex]["totalfuelprice"] || 0);
        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const fieldOnChangedSignature = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dTSignatureEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        tDataEdit[pIndex].edit = true;
        setdTSignatureEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });

        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onNewRowSign = () => {
        let tSign = [...dTSignatureEdit];
        tSign.push({
            iD: 0,
            arrayIndex: tSign.length
        });
        setdTSignatureEdit(tSign);
        setdTSignature(tSign);
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation delBudgetVehicleFuelNorm($id: Int) {
                    budgetMutation {
                      delBudgetVehicleFuelNorm(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetVehicleFuelNorm == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess) {
                await API.post("api/budget/calcBFuel?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) +
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid, tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data?.retType == 0) {
                        vSuccess = true;
                        message.success("Амжилттай");
                    }
                }).catch(() => {
                    setLoadingSave(false);
                });
            }
             //vSuccess = await calcfunc("calcBFuel", props?.id, (!schoolid ? userInfo?.schoolid : schoolid), (!depid ? userInfo?.schoolid : depid), userInfo?.schoolid)
        }
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDeleteSign = async (pIndex, pId) => {
        if (pId > 0) {
            await APIGraphQL.post("", {
                query: `mutation deleteBudgetSignature($id: Int) {
                    sharedMutation {
                      deleteBudgetSignature(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { });
            message.success("Амжилттай");
        }
        let tSign = [...dTSignatureEdit];
        tSign.splice(pIndex, 1);
        setdTSignatureEdit(tSign);
        setdTSignature(tSign);
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);

        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        await API.get("/api/budget/budgetFuelFeeTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, "Шатахууны зардал." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss();
        }
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд" });
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    return (
        <PanelBase
            headerText="Шатахууны зардлын тооцоолол"
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="large"
            buttons={(
                <div style={{ display: "flex" }}>
                    {!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    }
                    <div style={{ float: "left" }}>
                        <ButtonReport title="Татах" onClick={onExportDoc} />
                    </div>
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (
                <LoadSpin />
            ) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөгөөр/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                dataField="number"
                                caption="Машины дугаар"
                                width={150}
                                alignment='center'
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data.id, pValue, "vehicleid", pNameValue, "number")} defaultValue={item.data?.vehicleid} data={dTNumber} />
                                    );
                                }} />
                            <Column
                                width={150}
                                dataField="mark"
                                caption="Машины марк"
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.mark} />
                                    );
                                }}
                            />
                            <Column
                                dataField="fueltypename"
                                caption="Түлш"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={true} onChanged={() => { }} defaultValue={item.data?.fueltypename} />
                                    );
                                }} />
                            <Column
                                dataField="workday"
                                caption="Ажиллах хоног"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "workday")} defaultValue={item.data?.workday} />
                                    );
                                }} />
                            <Column
                                dataField="fuelnorm"
                                caption="Норм"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.fuelnorm} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column caption="Өдрийн норм" alignment='center'>
                                <Column
                                    dataField="kmdaynorm"
                                    caption="Км"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.fueldaynorm * 100 / item.data?.fuelnorm} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="fueldaynorm"
                                    caption="Түлш"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.fueldaynorm} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                dataField="oilqty"
                                caption="Тос"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.oilqty} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column caption="Нэгж үнэ" alignment='center'>
                                <Column
                                    dataField="fuelprice"
                                    caption="Түлш"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.fuelprice} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="oilprice"
                                    caption="Тос"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.oilprice} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column caption="Нийт үнэ" alignment='center'>
                                <Column
                                    dataField="totalfuelprice"
                                    caption="Түлш"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={(item?.data?.fuelprice || 0) * (item.data?.fueldaynorm || 0) * (item.data?.workday || 0) / 1000} fixedDecimalScale={true} />
                                        );
                                    }} />
                                <Column
                                    dataField="totaloilprice"
                                    caption="Тос"
                                    format="fixedPoint"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={(item?.data?.totaloilprice)} fixedDecimalScale={true} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                dataField="totalprice"
                                caption="Дүн"
                                format="fixedPoint"
                                alignment='center'
                                minwidth={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={item.data?.totalprice} fixedDecimalScale={true} />
                                    );
                                }} />
                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="totalprice"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                    {!disable &&
                        <div style={{ marginTop: 24 }}>
                            <ButtonSecondary onClick={onNewRowSign} text="Шинэ мөр нэмэх" />
                        </div>
                    }
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={dTSignature}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column
                                dataField="aCTIONNAME"
                                caption="Үйлдэл"
                                width={150}
                                format="fixedPoint"
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChangedSignature(item?.data?.arrayIndex, item?.data.iD, pValue, "aCTIONID", pNameValue, "aCTIONNAME")} defaultValue={item.data?.aCTIONID} data={dTSignatureType} />
                                    );
                                }} />
                            <Column
                                dataField="jOBTITLE"
                                caption="Албан тушаал"
                                width={150}
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable}
                                            onChanged={(pValue) => fieldOnChangedSignature(item?.data?.arrayIndex, item?.data?.iD, pValue, "jOBTITLE")}
                                            defaultValue={item.data?.jOBTITLE} />
                                    );
                                }} />
                            <Column
                                dataField="eMPLOYEENAME"
                                caption="Овог, нэр"
                                width={150}
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldText disable={disable}
                                            onChanged={(pValue) => fieldOnChangedSignature(item?.data?.arrayIndex, item?.data?.iD, pValue, "eMPLOYEENAME")}
                                            defaultValue={item.data?.eMPLOYEENAME} />
                                    );
                                }} />
                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDeleteSign(item?.data?.arrayIndex, item?.data?.iD)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                </div>
            )}
        </PanelBase >
    );
}

export default BudgetFuelFee;