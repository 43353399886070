export const MATHACTIONCONST = [
 { key: 1, text: "(" },
 { key: 2, text: "+" },
 { key: 3, text: "-" },
 { key: 4, text: "*" },
 { key: 5, text: "/" },
 { key: 6, text: "%" },
 { key: 7, text: ")" },
];


export const INDICATORTYPECONST = [
 {
     key: 1,
     text: "Эдийн засгийн ангилал"
 }, {
     key: 2,
     text: "Бусад үзүүлэлт"
 }
];


export const OTHERCALCMETHODS = [
 {
     key: 100,
     text: "Ажилчдын тоо"
 }, {
     key: 101,
     text: "Оюутны тоо, багц цаг"
 }, {
     key: 102,
     text: "Бусад үзүүлэлт оруулах"
 }
];

