import React, { useState, useEffect } from "react";
import { PanelBase, ButtonDefault, FieldDropdown, LoadSpin } from "dw-components";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { Col, message, Row } from "antd";
import { VehicleTaxColumns } from "../../../../Const/VehicleTaxColumns";
import { Icon } from '@fluentui/react/lib/Icon';

const VehicleTaxPanel = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [dVehicleTaxColumn] = useState(VehicleTaxColumns);

  const getData = async () => {
    setLoading(true);

    let tData = [];
    await APIGraphQL.post("", {
      query: `query ($economycategoryid: Int) {
        sharedQuery {
          ecoCatVehicleTax(economycategoryid: $economycategoryid) {
            iD
            cOLCODE
            cOLNAME
          }
        }
      }`,
      variables: {
        "economycategoryid": parseInt(props?.id)
      }
    }).then(response => {
      let tResp = response?.data?.data?.sharedQuery?.ecoCatVehicleTax;
      if (tResp?.length > 0) {
        tData = tResp;
      }
    }).catch(() => {
      setLoading(false);
    });

    setData(tData);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const onDismiss = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onSave = async () => {

    let tData = [...data];

    if (tData.length > 0) {
      setLoadingSave(true);
      let tSuccess = false;

      tData?.map(r => {
        r.eCONOMYCATEGORYID = parseInt(props?.id);
      });

      await APIGraphQL.post("", {
        query: `mutation ($data: [EconomyCategoryVehicleTaxInputType]) {
          sharedMutation {
            addEcoCatVehicleTax(data: $data) {
              iD
            }
          }
        }
        `,
        variables: {
          "data": tData
        }
      }).then(response => {
        if (response?.data?.data?.sharedMutation?.addEcoCatVehicleTax?.length > 0) {
          message.success("Амжилттай");
          tSuccess = true;
        }
      }).catch(() => {
        setLoadingSave(false);
      });
      setLoadingSave(false);

      if (tSuccess) {
        onDismiss();
      }
    }
  }

  const fieldOnChanged = async (pIndex, key, text, field, fieldName) => {
    let tData = [...data];
    if (pIndex == -1) {

      let tDataRow = {
        iD: 0,
        [field]: key
      }

      if (fieldName) {
        tDataRow = {
          ...tDataRow,
          [fieldName]: text
        }
      }
      tData.push({ ...tDataRow });

    } else {

      tData[pIndex] = {
        ...tData[pIndex],
        [field]: key
      };

      if (fieldName) {
        tData[pIndex] = {
          ...tData[pIndex],
          [fieldName]: text
        };
      }
    }

    setData(tData);
  }

  const onDelete = async (pIndex, pId) => {
    if (pId > 0) {
      await APIGraphQL.post("", {
        query: `mutation ($id: Int) {
          sharedMutation {
            delEcoCatVehicleTax(id: $id)
          }
        }`,
        variables: {
          "id": parseInt(pId)
        }
      }).then(response => { });
    }

    let tData = [...data];
    tData.splice(pIndex, 1);
    setData(tData);
  }

  return (
    <PanelBase
      headerText="Багана"
      isOpen={props.isOpen}
      PanelType="medium"
      onDismiss={onDismiss}
      buttons={loadingSave ? <LoadSpin /> : <ButtonDefault text="Хадгалах" onClick={onSave} />}
    >
    <div style={{ marginTop: 24 }}>
        {data?.map((r, index) => {
          return (
            <Row key={index} gutter={[16, 16]} style={{ marginTop: 8 }}>
              <Col span={1}>
                <div onClick={() => onDelete(index, r.iD)}>
                  <Icon style={{ marginTop: 13 }} iconName="Delete" />
                </div>
              </Col>
              <Col span={23}>
                <FieldDropdown onChanged={(key, text, field, fieldName) => fieldOnChanged(index, key, text, field, fieldName)} keyfield="cOLCODE" keyfieldname="cOLNAME" defaultValue={r.cOLCODE} data={dVehicleTaxColumn} />
              </Col>
            </Row>
          )
        })}

        <Row gutter={[16, 16]} key={data?.length} style={{ marginTop: 8 }}>
          <Col span={24}>
            <FieldDropdown placeholder="..." loading={loading} onChanged={(key, text, field, fieldName) => fieldOnChanged(-1, key, text, field, fieldName)} keyfield="cOLCODE" keyfieldname="cOLNAME" defaultValue={null} data={dVehicleTaxColumn} />
          </Col>
        </Row>
      </div>
    </PanelBase>
  );
};

export default VehicleTaxPanel;