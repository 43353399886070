import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FieldNumberFormat, LoadScreen, Number } from 'dw-components';
import { API } from '../../../../API/API';
import { TreeList } from 'devextreme-react';
import { Button, Row, Col, Space } from 'antd';
import { Column, ColumnFixing, Scrolling, Selection } from 'devextreme-react/tree-list';
import { UserContext } from '../../../../Context/UserContext';
import FileDownload from 'js-file-download';
import { useParams } from "react-router-dom";
import ButtonReport from '../../../../Components/ButtonReport/ButtonReport';

const BudgetDtl = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [shorten, setShorten] = useState(1);

    const [expanded, setExpanded] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [infoData, setInfoData] = useState({});
    const params = useParams();

    const getData = async () => {
        setLoading(true);
        let tColumns = [];
        let tData = [];
        let apiname = userInfo?.schooltypeid == 3 ? "availableschools?parentid=" : "availabledepartments?schoolid=";
        let tInfoData = {};

        let budgetid = 0;
        if (props?.id) {
            budgetid = props?.id;
        }
        else if(params?.id) {
            budgetid = params?.id;
        }

        await API.get("/api/budget/" + apiname + userInfo?.schoolid + "&budgetid=" + budgetid).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tColumns = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });

        if(budgetid > 0) {
            apiname = userInfo?.schooltypeid == 3 ? "budgetDtlSchoolSub" : "budgetDtlSchool";
            await API.get("/api/budget/" + apiname + "?budgetid=" + budgetid + "&schoolid=" + userInfo?.schoolid + "&datatype=" + props?.type + "&userid=" + userInfo?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });


            let income = 0;
            let issue = 0;
            for (let i = 0; i < tData?.length; i++) {
                tData[i].arrayIndex = i;
                if(tData[i]?.typeid == 1) {
                    income += tData[i]?.total;
                }
                else if(tData[i]?.typeid == 2) {
                    issue += tData[i]?.total;
                }
            }

            tInfoData.incomeprice = income;
            tInfoData.issueprice = issue;
        }

        setColumns(tColumns);
        setData(tData);
        setDataEdit(tData);
        setLoading(false);
        setInfoData(tInfoData);
    }

    useEffect(() => {
        getData();
    }, [props?.id, params?.id]);

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        tDataEdit[pIndex].note = "";
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
        if (props?.onEdit) {
            props.onEdit(tDataEdit);
        }
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let apiname = "/api/budget/budgetReportTemp?budgetid=" + props?.id + "&typeid=" + props?.type + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;
        if(props?.type == 0)
             apiname = "/api/budget/budgetReportTempFull?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;

        await API.get(apiname, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, (props?.type == 1 ? "Орлогын төсөв." : props?.type == 2 ? "Зарлагын төсөв." : "Нийт төсөв.") + pData?.filetype);
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const onExportDocDetail = async (pData) => {
        setLoadingDownload(true);
        let apiname = "/api/budget/budgetReportDetailTemp?budgetid=" + props?.id + "&typeid=" + props?.type + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;
        if(props?.type == 0)
             apiname = "/api/budget/budgetReportDetailTempFull?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid + "&userid=" + userInfo?.id + "&filetype=" + pData?.filetype;

        await API.get(apiname, {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, (props?.type == 1 ? "Орлогын төсөв дэлгэрэнгүй." : props?.type == 2 ? "Зарлагын төсөв дэлгэрэнгүй." : "Нийт төсөв дэлгэрэнгүй.") + pData?.filetype);
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const onCalcEndingBalance = async (pData) => {
        let apiname = "/api/budget/calcbudgetendingbalance?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&schooltypeid=" + userInfo?.schooltypeid;
       await API.get(apiname).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                getData();
            }
        }).catch(response => {
        });
    }

    const onOptionChanged = useCallback((e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    }, []);

    const getChildSummary = (p_node, p_col) => {
        let tSummary = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tSummary += getChildSummary(p_node.children[i], p_col);
            }
        } else {
            if (!isNaN(parseFloat(p_node?.data?.[p_col]))) {
                return parseFloat(p_node?.data?.[p_col]);
            } else {
                return 0;
            }
        }
        return tSummary;
    }


    const getChildCount = (p_node) => {
        let tCount = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tCount += getChildCount(p_node.children[i]);
            }
        } else {
            if (p_node?.data?.ischild == 1) {
                return 1;
            } else if (p_node?.data?.ischild == 0 && p_node?.data?.coverageid == 2
                && (p_node?.data?.calctypeid == 2 || p_node?.data?.calctypeid == 51)) {
                return 1;
            } else {
                return 0;
            }
        }
        return tCount;
    }

    const onNodesInitialized = useCallback((e) => {
        e.component.forEachNode((node) => {
            if (node?.data?.ischild == 0) {
                let tValue = getChildSummary(node, "total");
                node.data["total"] = parseFloat(tValue);

                Object.keys(node?.data).map(key => {
                    if (!isNaN(parseFloat(key))) {
                        let tValue = getChildSummary(node, key);
                        node.data[key] = parseFloat(tValue);
                    }
                });

                let childCount = getChildCount(node);
                if(childCount == 0 && !node?.data?.calctypeid){
                    node.visible = false;
                }
            }
        });
    }, []);

    if (loading) {
        return <LoadScreen />
    }

    let showTotalSum = [];
    if (props?.type == 1) {
        showTotalSum = [
            <Space offset={4}>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 68 }}>Нийт Орлого:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice} />
            </Space>]
    } else if (props?.type == 2) {
        showTotalSum = [
            <Space>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 77 }}>Нийт Зарлага:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.issueprice} />
            </Space>]
    } else if (props?.type == 0) {
        showTotalSum = [
            <Space>
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Нийт Орлого:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice}  fixedDecimalScale={true} />
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Нийт Зарлага:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.issueprice}  fixedDecimalScale={true} />
                <div style={{ color: '#605E5C', fontWeight: 550, fontSize: 16, marginLeft: 12 }}>Зөрүү:</div>
                <FieldNumberFormat disable={true} defaultValue={infoData?.incomeprice - infoData?.issueprice} fixedDecimalScale={true}  />
                <Button style={{ marginLeft: 8 }} type={expanded ? null : "primary"}
                        onClick={onCalcEndingBalance} size="small">{"Мөнгөн хөрөнгийн үлдэгдэл тооцох"}</Button>
            </Space>]
    }

    return (
        <div style={{ marginTop: 16, marginRight: 12 }} className='defaultTreeList'>
            <div style={{ marginRight: 8, marginLeft: 8 }}>
                <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Row>
                        <Col>
                            <Button
                                style={{ marginLeft: 8 }}
                                type={expanded ? null : "primary"}
                                onClick={() => {
                                    setExpanded(prevExpanded => !prevExpanded)
                                    setExpandedRowKeys([]);
                                }}
                                size="small"
                            >{expanded ? 'Бүгдийг хаах' : 'Бүгдийг нээх'}</Button>
                        </Col>
                        <Col style={{ marginLeft: 8 }}>
                            <ButtonReport size="small" title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                        </Col>
                        <Col style={{ marginLeft: 8 }}>
                            <ButtonReport size="small" title="Дэлгэрэнгүй татах" style={{ marginRight: 16 }} onClick={onExportDocDetail} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {showTotalSum}
                        </Col>
                    </Row>
                </Row>
            </div>
            <TreeList
                id="id"
                dataSource={data}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                keyExpr="ecocatid"
                parentIdExpr="ecocatparentid"
                showRowLines
                showBorders
                rowAlternationEnabled={true}

                autoExpandAll={expanded}
                expandedRowKeys={expandedRowKeys}
                onOptionChanged={onOptionChanged}
                onNodesInitialized={onNodesInitialized}
                height={"calc(100vh - " + (props.type == "0" ? "210" : "180") + "px)"}
            >
                <ColumnFixing enabled={true} />
                <Scrolling columnRenderingMode="standard" mode="standard" />
                <Selection mode="single" />
                <Column
                    minWidth={500}
                    dataField="fullname"
                    caption="Эдийн засгийн ангилал"
                    fixed={true} />
                <Column caption="Нэгтгэл төсөв">
                    <Column
                        dataField="total"
                        caption="Дүн"
                        minWidth={120}
                        cellRender={item => {
                            if ((item?.data?.coverageid == 2
                                || userInfo?.schooltypeid == 5)
                                && (item?.data?.calctypeid == 2 || item?.data?.calctypeid == 51)) {
                                return <FieldNumberFormat disable={props?.disable ? true : false} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.budgetschoolid, pValue, "price")} defaultValue={item.data?.total}  fixedDecimalScale={true} />
                            } else {
                                return <Number value={parseFloat(item.value).toFixed(2)} />;
                            }
                        }}
                    />
                </Column>
                {columns?.map(r => {
                    return (
                        <Column key={r.id} caption={r.code}>
                            <Column
                                caption="Дүн"
                                dataField={r.id?.toString()}
                                format="fixedPoint" 
                                cellRender={item => {
                                        return <Number value={parseFloat(item.value).toFixed(2)} />;
                                }}
                            />
                            <Column
                                caption="Хувь"
                                dataField={r.id?.toString() + "percent"}
                                format="fixedPoint"
                                width={60}
                                cellRender={item => {
                                    if (item?.data?.coverageid == 2) {
                                        return null;
                                    } else {
                                        return <Number value={((item?.data?.[r.id] || 0) * 100) / (data?.filter(q=>q.ischild == 1).reduce((a,v) =>  a = a + v[r.id] , 0 ) || 1)} />;
                                    }
                                }}
                            />
                        </Column>
                    );
                })}
            </TreeList>
        </div>
    );
}

export default BudgetDtl;