import React, { useContext, useEffect, useState, useCallback } from 'react';
import { LoadScreen, Number } from 'dw-components';
import { API } from '../../../API/API';
import { TreeList } from 'devextreme-react';
import { Button } from 'antd';
import { Column, ColumnFixing, Scrolling, Selection } from 'devextreme-react/tree-list';
import { UserContext } from '../../../Context/UserContext';
import AmountShortenButton from '../../../Components/AmountShortenButton/AmountShortenButton';

const Suggestion = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shorten, setShorten] = useState(1);

    const [expanded, setExpanded] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        if (props?.id) {
            await API.get("/api/budget/suggestionDepartment?budgetid=" + props?.id + "&departmentid=" + userInfo?.schoolid + "&userid=" + userInfo?.id).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
        }
        setData(tData);
        setLoading(false);
    }

    const customFormat = useCallback(
        (value) => {
            if (shorten != 1) {
                return (value * shorten).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
        },
        [shorten]
    );

    const onOptionChanged = useCallback((e) => {
        if (e.name === 'expandedRowKeys') {
            setExpandedRowKeys(e.value);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [props?.id]);

    const getChildSummary = (p_node, p_col) => {
        let tSummary = 0;

        if (p_node.children?.length > 0) {
            for (var i = 0; i < p_node?.children?.length; i++) {
                tSummary += getChildSummary(p_node.children[i], p_col);
            }
        } else {
            if (!isNaN(parseFloat(p_node?.data?.[p_col]))) {
                return parseFloat(p_node?.data?.[p_col]);
            } else {
                return 0;
            }
        }
        return tSummary;
    }

    const onNodesInitialized = useCallback((e) => {
        e.component.forEachNode((node) => {
            if (node?.data?.ischild == 0) {
                let tValue = getChildSummary(node, "price");
                node.data["price"] = parseFloat(tValue);

                tValue = getChildSummary(node, "schoolprice");
                node.data["schoolprice"] = parseFloat(tValue);

                tValue = getChildSummary(node, "universityprice");
                node.data["universityprice"] = parseFloat(tValue);
            }
        });
    }, []);

    if (loading) {
        return <LoadScreen />
    }

    return (
        <div style={{ marginTop: 16 }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <AmountShortenButton setShorten={setShorten} />
                <Button
                    style={{ marginLeft: 16 }}
                    type={expanded ? null : "primary"}
                    onClick={() => {
                        setExpanded(prevExpanded => !prevExpanded)
                        setExpandedRowKeys([]);
                    }}
                    size="small"
                >{expanded ? 'Бүгдийг хаах' : 'Бүгдийг нээх'}</Button>
            </div>
            <TreeList
                id="id"
                dataSource={data}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                keyExpr="ecocatid"
                parentIdExpr="ecocatparentid"
                showRowLines
                showBorders
                rowAlternationEnabled={true}
                autoExpandAll={expanded}
                expandedRowKeys={expandedRowKeys}
                onOptionChanged={onOptionChanged}
                onNodesInitialized={onNodesInitialized}
                height={"calc(100vh - 180px)"}>
                <Scrolling columnRenderingMode="standard" mode="standard" />
                <Selection mode="single" />
                <Column
                    minWidth={500}
                    dataField="fullname"
                    caption="Эдийн засгийн ангилал"
                />
                <Column caption="Нэгжийн санал">
                    <Column
                        caption="Дүн"
                        dataField="price"
                        format="#,##0.00"
                        width={100} />
                    <Column
                        caption="Хувь"
                        dataField="percent"
                        format="#,##0.00"
                        cellRender={item => {
                            return <Number value={((item?.data?.price || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="note"
                        caption="Тайлбар"
                    />
                </Column>
                <Column caption="Сургуулийн санал">
                    <Column
                        caption="Дүн"
                        dataField="schoolprice"
                        format="#,##0.00"
                        width={100} />
                    <Column
                        caption="Хувь"
                        dataField="schoolpercent"
                        format="#,##0.00"
                        width={60}
                        cellRender={item => {
                            return <Number value={((item?.data?.schoolprice || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="schoolnote"
                        caption="Тайлбар"
                    />
                </Column>
                <Column caption="Нэгтгэл төсөв">
                    <Column
                        caption="Дүн"
                        dataField="universityprice"
                        format="#,##0.00"
                        width={100} />
                    <Column
                        caption="Хувь"
                        dataField="universitypercent"
                        format="#,##0.00"
                        width={60}
                        cellRender={item => {
                            return <Number value={((item?.data?.universityprice || 0) * 100) / (props?.total || 1)} />;
                        }} />
                    <Column
                        width={150}
                        dataField="universitynote"
                        caption="Тайлбар"
                    />
                </Column>
            </TreeList>
        </div>
    );
}

export default Suggestion;