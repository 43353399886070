export const SIGNATURETYPE = [
 {
   'key': 2,
   'text': 'Төсөвлөсөн'
 },
 {
   'key': 3,
   'text': 'Боловсруулсан'
 },
 {
   'key': 4,
   'text': 'Хянасан'
 },
 {
   'key': 5,
   'text': 'Танилцсан'
 },
 {
   'key': 6,
   'text': 'Батлав/Захирал'
 }
];
