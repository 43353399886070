import React, { useState, useEffect } from "react";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import {
  ButtonDefault,
  FieldDropdown,
  FieldText,
  FieldTextArea,
  PanelBase,
  Row,
  Col,
  ButtonSecondary,
  FieldDate,
} from "dw-components";
import { CollapseAllVisibility, IconButton } from "@fluentui/react";
import FieldUpload from "../../../Components/FieldUpload/FieldUpload";
import FieldNumberFormat from "../../../Components/FieldNumberFormat/FieldNumberFormat";
import FieldTextBorderless from "../../../Components/FieldTextBorderless/FieldTextBorderless";
import FieldDropdownBorderless from "../../../Components/FieldDropdownBorderless/FieldDropdownBorderless";
import { BRANCHSCHOOLS } from "../../../Const/BranchSchools";
import { DEPARTMENTS } from "../../../Const/Departments";

import { message } from "antd";
import { MessageConst } from "../../../Const/MessageConst";

import BuildingPanel from "./PurchaseAssetPanels/BuildingPanel";

// Нормативт зардал
const PurchaseAssetPanel = (props) => {
  const userRole = {};
  const [data, setData] = useState({
    calculateMethodType: "purchaseAsset",
    indicatorId: props.indicatorData.indicatorId,
    category: props.indicatorData.category,
    distribution: "month",
  });

  const [assets, setAssets] = useState([]);
  const [assetsData, setAssetsData] = useState({});
  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);

  const [checkRule, setCheckRule] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [warning, setWarning] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSchool, setDisableSchool] = useState(false);

  const [showBuilding, setShowBuilding] = useState(false);

  const onShowNew = () => {
    setShowBuilding(true);
  }

  const onClose = () => {
    setShowBuilding(false)
  }

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const nextStep = async () => {
    setWarning(null);
    setCheckRule(true);

    let isValid = true;

    if (!data.branchSchool) {
      isValid = false;
      setWarning("Салбар сургууль" + MessageConst.fieldRequired);
    } else if (data.branchSchool != "ALL" && !data.department) {
      isValid = false;
      setWarning("Байгууллага нэгж" + MessageConst.fieldRequired);
    } else if (!data.assetCategory) {
      isValid = false;
      setWarning("Хөрөнгийн ангилал" + MessageConst.fieldRequired);
    } else if (!data.assets || data.assets.length == 0) {
      isValid = false;
      setWarning("Хөрөнгө сонгоогүй байна");
    } else if (!data.origin) {
      isValid = false;
      setWarning("Эх үүсвэр" + MessageConst.fieldRequired);
    } else if (!data.purchaseDate) {
      isValid = false;
      setWarning("Худалдан авах огноо" + MessageConst.fieldRequired);
    }

    if (isValid) {
      setCurrentStep((r) => r + 1);
      await changeDistribution(data.distribution);
    }
  };

  const prevStep = () => {
    setCurrentStep((r) => r - 1);
  };

  const changeDistribution = async (key) => {
    let tempData;
    switch (key) {
      case "month":
        tempData = { ...data };
        let perMonth = totalBudgetAmount / 12;
        setData({
          ...tempData,
          distribution: key,
          month1: perMonth,
          month2: perMonth,
          month3: perMonth,
          month4: perMonth,
          month5: perMonth,
          month6: perMonth,
          month7: perMonth,
          month8: perMonth,
          month9: perMonth,
          month10: perMonth,
          month11: perMonth,
          month12: perMonth,
        });
        break;
      case "season":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month10;
        delete tempData.month11;

        let perSeason = totalBudgetAmount / 4;

        setData({
          ...tempData,
          distribution: key,
          month3: perSeason,
          month6: perSeason,
          month9: perSeason,
          month12: perSeason,
        });
        break;
      case "year":
        tempData = { ...data };
        delete tempData.month1;
        delete tempData.month2;
        delete tempData.month3;
        delete tempData.month4;
        delete tempData.month5;
        delete tempData.month6;
        delete tempData.month7;
        delete tempData.month8;
        delete tempData.month9;
        delete tempData.month10;
        delete tempData.month11;
        delete tempData.month12;
        setData({
          ...tempData,
          distribution: key,
        });
        break;
      default:
        break;
    }
  };

  const AssetsRef = collection(db, "assets");

  const getAssets = async (assetCat, branchSchool) => {
    const Query = query(
      AssetsRef,
      where("assetCategory", "==", assetCat),
      where("branchSchool", "==", branchSchool)
    );
    const response = await getDocs(Query);

    if (!response.empty) {
      let availableAssets = response.docs.map((doc) => ({
        text: doc.data().assetName,
        key: doc.id,
      }));

      let tAssets = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const assetsData = tAssets.reduce(
        (obj, current) => ({ ...obj, [current.id]: current }),
        {}
      );

      setAssetsData(assetsData);
      return availableAssets;
    } else {
      return [];
    }
  };

  const fieldOnChanged = async (key, text, field) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;

      if (field == "branchSchool") {
        tData = {};
        tData[field] = key;
      }

      if (field == "assetCategory") {
        tData.assets = [];
        let assets = await getAssets(key, tData.branchSchool);
        setTotalBudgetAmount(0);
        setAssets(assets);
      }

      if (field == "distribution") {
        await changeDistribution(key);
      }

      setData(tData);
    }
  };

  const itemFieldOnChanged = async (pRecord, pIndex, pField, pValue) => {
    let tData = { ...data };

    const tValue = parseInt(pValue) || 0;
    const tUnitPrice = tData["assets"][pIndex]["marketPrice"] || 0;

    tData["assets"][pIndex][pField] = tValue;
    tData["assets"][pIndex]["totalPrice"] = tValue * tUnitPrice;

    await calcTotal(tData.assets);

    setData(tData);
  };

  const itemSelectOnChanged = async (key, text, field) => {
    let tData = { ...data };
    if (!tData.assets) {
      tData.assets = [];
    }
    let selectedAsset = assetsData[key];
    selectedAsset.count = selectedAsset.count || 0;
    selectedAsset.unitPrice = selectedAsset.marketPrice || 0;
    selectedAsset.totalPrice = selectedAsset.count * selectedAsset.marketPrice;
    tData.assets.push(selectedAsset);

    await calcTotal(tData.assets);
    setData(tData);
  };

  const calcTotal = async (assets) => {
    let totalAmount = 0;
    assets.map((item) => {
      totalAmount += item.totalPrice;
    });

    setTotalBudgetAmount(totalAmount);
  };

  const createMethod = async (payload) => {
    try {
      props.setTempData({
        ...payload,
        type: props.type,
        userId: userRole.userid,
        totalBudgetAmount: totalBudgetAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      message.error("Амжилтгүй");
    }
  };

  const onSave = async () => {
    setIsDisabled(true);
    await createMethod(data);
    props.onDismiss();
    setIsDisabled(false);
  };

  const onDelete = async (pIndex) => {
    let tData = { ...data };
    tData.assets.splice(pIndex, 1);

    await calcTotal(tData.assets);

    setData(tData);
  };

  useEffect(async () => {
    if (props?.data) {
      setData({ ...props.data, ...data });
      setTotalBudgetAmount(
        parseFloat(props.data.totalBudgetAmount.replace(/,/g, ""))
      );

      let assets = await getAssets(
        props.data.assetCategory,
        props.data.branchSchool
      );
      setAssets(assets);
    }

    if (userRole.role == "DEPARTMENT" && !data.branchSchool) {
      let tData = { ...props?.data, ...data };
      tData.branchSchool = userRole.companyname;
      tData.department = userRole.department;
      setData(tData);
    }
    setDisableSchool(true);
  }, [props.isOpen]);

  let vPanel = null;
  let showDepartment;
  if (data.branchSchool == "ALL" || !data.branchSchool) {
    showDepartment = null;
  } else {
    showDepartment = (
      <FieldDropdown
        required
        onChanged={fieldOnChanged}
        title="Байгууллагын нэгж"
        keyfield="department"
        defaultValue={data.department}
        checkreq={checkRule}
        data={DEPARTMENTS[data.branchSchool]}
        disable={disableSchool}
      />
    );
  }

  if (currentStep == 0) {
    vPanel = (
      <PanelBase
        headerText={props.name}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="large"
        warning={warning}
        buttons={
          <div style={{ display: "flex" }}>
            <div>
              <ButtonDefault text="Үргэлжлүүлэх" onClick={nextStep} />
              <ButtonDefault text="Test" onClick={onShowNew} />
            </div>
          </div>
        }
      >
        <div className="RegisterNormativtExp">
          <div style={{ marginTop: 20 }}>
            <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={12}>
                <FieldDropdown
                  required
                  onChanged={fieldOnChanged}
                  title="Салбар сургууль"
                  keyfield="branchSchool"
                  defaultValue={data.branchSchool}
                  checkreq={checkRule}
                  data={BRANCHSCHOOLS}
                  disable={disableSchool}
                />
              </Col>
              <Col span={12}>{showDepartment}</Col>
            </Row>
            {/* <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={12}>
                <FieldDropdown
                  required
                  onChanged={fieldOnChanged}
                  title="Хөрөнгийн ангилал"
                  keyfield="assetCategory"
                  defaultValue={data.assetCategory}
                  checkreq={checkRule}
                  data={ASSETCATEGORIES}
                />
              </Col>
              <Col span={12}>
                <FieldDropdown
                  required
                  onChanged={fieldOnChanged}
                  title="Эх үүсвэр"
                  keyfield="origin"
                  defaultValue={data.origin}
                  checkreq={checkRule}
                  data={[
                    { key: "transfer", text: "Он дамжуулах" },
                    { key: "new", text: "Шинээр худалдан авах" },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={12}>
                <FieldDate
                  required
                  onChanged={fieldOnChanged}
                  title="Худалдан авах огноо"
                  keyfield="purchaseDate"
                  defaultValue={data.purchaseDate}
                  checkreq={checkRule}
                />
              </Col>
            </Row> */}
          </div>
          <div style={{ marginTop: 17 }}>
            <h2>Хөрөнгийн жагсаалт</h2>
          </div>
          <div style={{ marginTop: 12 }}>
            <Row gutter={[16, 16]}>
              <Col span={1}></Col>
              <Col span={5}>
                <div className="prods-col-name">Хөрөнгийн ангилал</div>
              </Col>
              <Col span={5}>
                <div className="prods-col-name">Эх үүсвэр</div>
              </Col>
              <Col span={3}>
                <div className="prods-col-name">Хөрөнгийн нэр</div>
              </Col>
              <Col span={5}>
                <div className="prods-col-name">Нийт дүн (₮)</div>
              </Col>
              {/* <Col span={5}>
                <div className="prods-col-name">Нийт үнэ (₮)</div>
              </Col> */}
            </Row>
            {data.assets?.map((r, index) => {
              return (
                <Row gutter={[16, 16]}>
                  <Col span={1}>
                    <div>
                      <IconButton
                        style={{
                          fontSize: 8,
                          paddingTop: 8,
                          ootHovered: { backgroundColor: "transparent" },
                        }}
                        onClick={() => onDelete(index)}
                        iconProps={{ iconName: "Delete" }}
                      />
                    </div>
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="assetName"
                      defaultValue={r.assetName}
                      placeholder={"Хөрөнгийн нэр"}
                      disable={true}
                    />
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="assetIndicator"
                      placeholder={"Хөрөнгийн үзүүлэлт"}
                      disable={true}
                      defaultValue={r.assetIndicator}
                    />
                  </Col>
                  <Col span={3}>
                    <FieldTextBorderless
                      onChanged={(value) =>
                        itemFieldOnChanged(r, index, "count", value)
                      }
                      keyfield="count"
                      placeholder={"Тоо ширхэг"}
                      defaultValue={r.count}
                    />
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="marketPrice"
                      placeholder={"Үнийн дүн"}
                      defaultValue={
                        " " + mntFormatter.format(r.marketPrice || 0) + "₮"
                      }
                      disable={true}
                    />
                  </Col>
                  <Col span={5}>
                    <FieldTextBorderless
                      keyfield="totalPrice"
                      placeholder={"Нйит үнийн дүн"}
                      disable={true}
                      defaultValue={
                        " " + mntFormatter.format(r.totalPrice || 0) + "₮"
                      }
                    />
                  </Col>
                </Row>
              );
            })}
            <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
              <Col span={1}></Col>
              <Col span={5}>
                <FieldDropdownBorderless
                  onChanged={itemSelectOnChanged}
                  keyfield="name"
                  placeholder={"Хөрөнгө сонгох"}
                  checkreq={checkRule}
                  data={assets}
                />
              </Col>
              <Col span={5}>
                <FieldTextBorderless
                  keyfield="norm"
                  placeholder={"Хөрөнгийн үзүүлэлт"}
                  disable={true}
                />
              </Col>
              <Col span={3}>
                <FieldTextBorderless
                  keyfield="count"
                  placeholder={"Тоо ширхэг"}
                  disable={true}
                />
              </Col>
              <Col span={5}>
                <FieldTextBorderless
                  keyfield="marketPrice"
                  placeholder={"Үнийн дүн"}
                  disable={true}
                />
              </Col>
              <Col span={5}>
                <FieldTextBorderless
                  keyfield="totalPrice"
                  placeholder={"Нийт үнийн дүн"}
                  disable={true}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col offset={19} span={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    // justifyContent: "end",
                    marginTop: 16,
                    fontWeight: 600,
                    fontSize: 12,
                  }}
                >
                  <h3>
                    Нийт дүн (₮):
                    {" " + mntFormatter.format(totalBudgetAmount || 0) + "₮"}
                  </h3>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ marginTop: 13 }}>
                  <FieldTextArea
                    onChanged={fieldOnChanged}
                    title="Тайлбар"
                    keyfield="description"
                    defaultValue={data.description}
                  />
                </div>
              </Col>
            </Row>
            <Col span={5} style={{ marginTop: 20 }}>
              <FieldUpload
                filetype="pdf"
                onChanged={fieldOnChanged}
                title="Файл хавсаргах (Зөвхөн PDF файл)"
                keyfield="normativfile"
                defaultValue={data.normativefile}
              />
            </Col>
          </div>
        </div>
      </PanelBase>
    );
  } else {
    vPanel = (
      <PanelBase
        headerText={props.name}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        PanelType="medium"
        buttons={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 16 }}>
              <ButtonSecondary onClick={prevStep} text="Буцах" />
            </div>
            <div>
              <ButtonDefault
                onClick={onSave}
                text={props?.calculateMethodId ? "Засах" : "Хадгалах"}
                disable={isDisabled}
              />
            </div>
          </div>
        }
      >
        <div className="RegisterNormativtExp">
          <Col span={12}>
            <FieldDropdown
              required
              onChanged={fieldOnChanged}
              title="Төсвийн хуваарилалт"
              keyfield="distribution"
              defaultValue={data.distribution}
              checkreq={checkRule}
              data={[
                { key: "month", text: "Сар" },
                { key: "year", text: "Жил" },
                { key: "season", text: "Улирал" },
              ]}
            />
          </Col>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="1-р сар"
                keyfield="month1"
                defaultValue={data.month1}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="7-р сар"
                keyfield="month7"
                defaultValue={data.month7}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="2-р сар"
                keyfield="month2"
                defaultValue={data.month2}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="8-р сар"
                keyfield="month8"
                defaultValue={data.month8}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="3-р сар"
                keyfield="month3"
                defaultValue={data.month3}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="9-р сар"
                keyfield="month9"
                defaultValue={data.month9}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="4-р сар"
                keyfield="month4"
                defaultValue={data.month4}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="10-р сар"
                keyfield="month10"
                defaultValue={data.month10}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="5-р сар"
                keyfield="month5"
                defaultValue={data.month5}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="11-р сар"
                keyfield="month11"
                defaultValue={data.month11}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 13 }}>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="6-р сар"
                keyfield="month6"
                defaultValue={data.month6}
              />
            </Col>
            <Col span={12}>
              <FieldNumberFormat
                onChanged={fieldOnChanged}
                title="12-р сар"
                keyfield="month12"
                defaultValue={data.month12}
              />
            </Col>
          </Row>
        </div>
      </PanelBase>
    );
  }

  return (
    <>
      {vPanel}
      {/* <BuildingPanel
        // itemId={assetId}
        isOpen={showBuilding}
        onClose={onClose}
        // forceUpdate={forceUpdate}
      ></BuildingPanel> */}
    </>
  );
};

export default PurchaseAssetPanel;
