import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Drawer, Menu } from "antd";
import List from "./List";
import PurchaseInvestmentList from "./PurchaseaInvestment/List"
import Dashboard from "./Dashboard/Dashboard";
import SideDashboardIcon from '../../svg/sideMenuIcons/SideDashboardIcon';
import SideBudgetIcon from '../../svg/sideMenuIcons/SideBudgetIcon';
import SideOperationIcon from '../../svg/sideMenuIcons/SideOperationIcon';
import SideAllocationIcon from '../../svg/sideMenuIcons/SideAllocationIcon';
import SideInvestmentIcon from '../../svg/sideMenuIcons/SideInvestmentIcon';
import SideConfigIcon from '../../svg/sideMenuIcons/SideConfigIcon';
import SideFundIcon from '../../svg/sideMenuIcons/SideFundIcon';
import SideBuildInvestIcon from '../../svg/sideMenuIcons/SideBuildInvestIcon';
import SideDocumentIcon from '../../svg/sideMenuIcons/SideDocumentIcon';
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from 'moment';

import { ReactComponent as AppListIcon } from "../../svg/budgetIcons/AppListIcon.svg"
import { ReactComponent as AutoSumIcon } from "../../svg/budgetIcons/AutoSumIcon.svg";
import { ReactComponent as CommentIcon } from "../../svg/budgetIcons/CommentIcon.svg";
import { ReactComponent as HistoryIcon } from "../../svg/budgetIcons/HistoryIcon.svg";
import { ReactComponent as MoneyDismissIcon } from "../../svg/budgetIcons/MoneyDismissIcon.svg";
import { ReactComponent as MoneyHandIcon } from "../../svg/budgetIcons/MoneyHandIcon.svg";
import { ReactComponent as AddIcon } from "../../svg/add.svg";
import { ReactComponent as ReportIcon } from "../../svg/budgetIcons/ReportIcon.svg"

import { Routes, Route, useNavigate, useLocation, Navigate, NavLink } from "react-router-dom";
import BudgetDetails from "../Budget/Details";
import BudgetConfirmation from "../Budget/Confirmation";
import BudgetConfirm from "../Budget/Confirm";
import BranchConfirm from "../Budget/BranchConfirm.js.js";
import BusinessTrip from "../Config/BusinessTripInit/List/BusinessTripInit";
import Products from "../Config/Products/List";
import FuelNorm from "../Config/FuelNorm/List";
import Assets from "../Config/Assets/List";
import Objects from "../Config/Objects/List";
import HrPlan from "../Config/HrPlan/List";
import Positions from "../Config/Positions/Positions";
import RentSquare from "../Config/RentSquare/RentSquare";
import TuitionFee from "../Config/TuitionFee/List";
import OtherIndicators from "../Config/Others/List";
import Vehicle from "../Config/Vehicle/List";
import EconomyCategory from "../Config/EconomyCategory/EconomyCategory";
import SchoolDep from "../Config/SchoolDep/SchoolDep";
import SalaryConfig from "../Config/SalaryConfig/List/SalaryConfig";
import VehicleTaxConfig from "../Config/VehicleTaxConfig/List";
import BonusConfig from "../Config/BonusConfig/List/BonusConfig";
import SecurityObjectSchool from "../Config/SecurityObjectSchool/List";
import OvertimeWage from "../Config/OvertimeWageConfig/OvertimeWage";
import PurchaseItem from "../Config/PurchaseItem/List"
import User from "../Config/User/User";
import BudgetNewPanel from "./Panel/BudgetNewPanel";
import SideMenu from "../Sidemenu/Sidemenu";
import Pension from "../Config/Pension/Pension";
import DocumentCategory from "../Config/DocumentCategory/DocumentCategory";
import BaseCost from "../Config/BaseCost/BaseCost";
import RepairCost from "../Config/RepairCost/RepairCost";
import Document from "../Document/Document";
import Operations from "./Operations/Operations";
import BuildingInvestments from "./BuildingInvestments/BuildingInvestments";
import Fund from "./Fund/Fund";
import FundDetail from "./Fund/Panel/FundDetail";
import Allocations from "./Allocations/Allocations";
import Meter from "../Config/Meter/Meter";
import EconomyCategoryFormula from "../Config/EconomyCategoryFormula/EconomyCategoryFormula";
import GeneralIndicators from "../Config/GeneralIndicatorConfig/GeneralIndicators";
import BuildingSchoolCost from "../Config/BuildingSchoolCost/BuildingSchoolCost";
import UserCode from "../Config/UserCode/UserCode";
import AllocationNewPanel from "./Allocations/Panel/AllocationNewPanel";
import { UserContext } from "../../Context/UserContext";
import BudgetDtlDep from "./BudgetDtl/BudgetDtlDep";
import BetweenCost from "../Config/BetweenCost/BetweenCost";
import EditIndicator from "../Config/EditIndicator/EditIndicator";
import KPI from "../Config/KPI/KPI.js";
import StrategyPlan from "../Config/StrategyPlan/StrategyPlan.js";

const Budget = (props) => {
    const { userInfo } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(null);
    const [visible, setVisible] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [data, setData] = useState({});
    const [days, setDays] = useState();
    const [isConfigOpen, setIsConfigOpen] = useState(false);

    const onClickSideMenu = (value) => {
        setCurrent(value);
        if (value === 1) {
            setVisible(true);
        } else {
            setVisible(false);
        }
        if (value === 4) {
            setIsConfigOpen(true);
        } else {
            setIsConfigOpen(false);
        }
    }

    const onClose = () => {
        setVisible(false);
    };

    const onBudgetNew = () => {
        setVisible(false);
        setShowNew(true);
    }

    const onDismiss = () => {
        setShowNew(false);
    }

    useEffect(() => {
        getData();
    }, [location]);

    const getData = async () => {
        let tData = [];
        let latestYear = 0;
        await APIGraphQL.post("", {
            query: `query ($rowstate: String) {
                budgetQuery {
                  budgets(rowstate: $rowstate) {
                    iD
                    yEAR
                    eNDDATE
                    sTATUSNAME
                  }
                }
              }
              `,
            variables: {
                "rowstate": 'open'
            }
        }).then(response => {
            if (response?.data?.data?.budgetQuery?.budgets?.length > 0) {
                tData = response?.data?.data?.budgetQuery?.budgets;
                latestYear = Math.max(...tData.map(item => item.iD));
                tData = tData.filter(item => item.iD == latestYear)[0];
                if (tData) {
                    setData(tData);
                    setDays(moment(tData?.eNDDATE)?.diff(moment(), 'days'));
                }
            }
        });
    }

    const onNavigate = (p_type) => {
        setVisible(false);
        navigate("/budget/confirm/" + data?.iD + "/" + p_type);
    }

    const onCloseConfig = () => {
        setIsConfigOpen(false);
    }

    return (
        <div style={{ backgroundColor: "#F7F7F7", minHeight: "100vh", zIndex: 999 }}>
            <Row wrap={false}>
                <Col flex="none" style={{ position: 'fixed' }}>
                    <div style={{
                        backgroundColor: "#305095", minHeight: "100vh",
                        boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.15)'
                    }}>
                        <div style={{ minHeight: "90vh", backgroundColor: '#305095' }} >
                            {userInfo?.menuroleids?.includes("1") &&
                                <Row justify="center" align="middle" style={{ backgroundColor: current == 0 ? "#2C4A89" : "#305095" }}>
                                    <Col span={8} offset={1} style={{ marginTop: 24, marginRight: 36 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(0)}>
                                            <NavLink to="/budget/dashboard">
                                                <SideDashboardIcon active={current == 0 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Row justify="center" align="center" style={{ backgroundColor: current == 1 ? "#2C4A89" : "#305095" }}>
                                    <Col span={8} offset={6} style={{ marginTop: 24, marginRight: 34 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(1)}>
                                            <NavLink to="/budget/list">
                                                <SideBudgetIcon active={current == 1 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("3") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("3") &&
                                <Row justify="center" align="middle" style={{ backgroundColor: current == 2 ? "#2C4A89" : "#305095" }}>
                                    <Col span={8} offset={2} style={{ marginTop: 24, marginRight: 34 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(2)}>
                                            <NavLink to="/budget/pinvestmentlist">
                                                <SideInvestmentIcon active={current == 2 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("4") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("4") &&
                                <Row justify="center" align="middle" style={{ backgroundColor: current == 3 ? "#2C4A89" : "#305095" }}>
                                    <Col style={{ marginTop: 24 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(3)}>
                                            <NavLink to="/budget/document">
                                                <SideDocumentIcon active={current == 3 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("5") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("5") &&
                                <Row justify="center" align="center" style={{ backgroundColor: current == 3 ? "#2C4A89" : "#305095" }}>
                                    <Col style={{ marginTop: 24 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(3)}>
                                            <NavLink to="/budget/operation">
                                                <SideOperationIcon active={current == 3 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("6") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("6") &&
                                <Row justify="center" align="center" style={{ backgroundColor: current == 3 ? "#2C4A89" : "#305095" }}>
                                    <Col style={{ marginTop: 24 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(3)}>
                                            <NavLink to="/budget/allocation">
                                                <SideAllocationIcon active={current == 3 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("8") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("8") &&
                                <Row justify="center" align="center" style={{ backgroundColor: current == 3 ? "#2C4A89" : "#305095" }}>
                                    <Col style={{ marginTop: 24 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(3)}>
                                            <NavLink to="/budget/buildinginvestments">
                                                <SideBuildInvestIcon active={current == 3 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("9") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("9") &&
                                <Row justify="center" align="center" style={{ backgroundColor: current == 3 ? "#2C4A89" : "#305095" }}>
                                    <Col style={{ marginTop: 24 }}>
                                        <div style={{ height: "90px", cursor: "pointer" }} onClick={() => onClickSideMenu(3)}>
                                            <NavLink to="/budget/fund">
                                                <SideFundIcon active={current == 3 ? true : false} />
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {userInfo?.menuroleids?.includes("7") &&
                                <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />}
                            {userInfo?.menuroleids?.includes("7") &&
                                <Row justify="center" align="middle" style={{ backgroundColor: current == 4 ? "#2C4A89" : "#305095" }}>
                                    <Col span={8} offset={2} style={{ marginTop: 24, marginRight: 32 }}>
                                        <div onClick={() => {
                                            onClickSideMenu(4);
                                        }}
                                            style={{ height: "90px", cursor: "pointer" }}>
                                            <SideConfigIcon />
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <div style={{ borderTop: "1px solid rgba(239, 239, 239, 0.1)" }} />
                        </div>
                    </div>
                </Col>
                <Col style={{ marginRight: "106px" }} />
                <Col flex="auto">
                    <div style={{ backgroundColor: "#ffffff", marginLeft: "10px" }}>
                        <Routes>
                            {userInfo?.menuroleids?.includes("1") &&
                                <Route
                                    path="/"
                                    element={<Dashboard />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("1") &&
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/list"
                                    element={<List />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("3") &&
                                <Route
                                    path="/pinvestmentlist"
                                    element={<PurchaseInvestmentList />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("4") &&
                                <Route
                                    path="/document"
                                    element={<Document />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("5") &&
                                <Route
                                    path="/operation"
                                    element={<Operations />}
                                />
                            }
                            <Route
                                path="/buildinginvestments"
                                element={<BuildingInvestments />}
                            />
                            {userInfo?.menuroleids?.includes("9") &&
                                <Route
                                    path="/fund"
                                    element={<Fund />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("9") &&
                                <Route
                                    path="/fund/:id"
                                    element={<FundDetail />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("6") &&
                                <Route
                                    path="/allocation"
                                    element={<Allocations />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("6") &&
                                <Route
                                    path="/allocation/:id"
                                    element={<AllocationNewPanel />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/budgetdtldep/:id/:type/:schoolid"
                                    element={<BudgetDtlDep />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/details"
                                    element={<BudgetDetails />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/confirmation"
                                    element={<BudgetConfirmation />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/confirm/:id"
                                    element={<BudgetConfirm />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/confirm/:id/:type"
                                    element={<BudgetConfirm />}
                                />
                            }
                            {userInfo?.menuroleids?.includes("2") &&
                                <Route
                                    path="/branch/confirm"
                                    element={<BranchConfirm />}
                                />
                            }
                        </Routes>
                        {userInfo?.menuroleids?.includes("7") &&
                            <Routes>
                                <Route
                                    path="/config/businessTrip"
                                    element={<BusinessTrip />}
                                />
                                <Route
                                    path="/config/tuitionfee"
                                    element={<TuitionFee />}
                                />
                                <Route
                                    path="/config/others"
                                    element={<OtherIndicators />}
                                />
                                <Route
                                    path="/config"
                                    element={<Navigate to="/config/economycategory" />}
                                />
                                <Route
                                    path="/config/economycategory"
                                    element={<EconomyCategory />}
                                />
                                <Route
                                    path="/config/generalindicator"
                                    element={<GeneralIndicators />}
                                />
                                <Route
                                    path="/config/schooldep"
                                    element={<SchoolDep />}
                                />
                                <Route
                                    path="/config/products"
                                    element={<Products />}
                                />
                                <Route
                                    path="/config/fuelNorm"
                                    element={<FuelNorm />}
                                />
                                <Route
                                    path="/config/vehicle"
                                    element={<Vehicle />}
                                />
                                <Route
                                    path="/config/assets"
                                    element={<Assets />}
                                />
                                <Route
                                    path="/config/objects"
                                    element={<Objects />}
                                />
                                <Route
                                    path="/config/hrPlan"
                                    element={<HrPlan />}
                                />
                                <Route
                                    path="/config/positions"
                                    element={<Positions />}
                                />
                                <Route
                                    path="/config/rentsquare"
                                    element={<RentSquare />}
                                />
                                <Route
                                    path="/config/salaryconfig"
                                    element={<SalaryConfig />}
                                />
                                <Route
                                    path="/config/overtimewage"
                                    element={<OvertimeWage />}
                                />
                                <Route
                                    path="/config/vehicletaxconfig"
                                    element={<VehicleTaxConfig />}
                                />
                                <Route
                                    path="/config/bonusconfig"
                                    element={<BonusConfig />}
                                />
                                <Route
                                    path="/config/securityobjectschool"
                                    element={<SecurityObjectSchool />}
                                />
                                <Route
                                    path="/config/purchaseitem"
                                    element={<PurchaseItem />}
                                />
                                <Route
                                    path="/config/user"
                                    element={<User />}
                                />
                                <Route
                                    path="/config/pension"
                                    element={<Pension />}
                                />
                                <Route
                                    path="/config/documentcategory"
                                    element={<DocumentCategory />}
                                />
                                <Route
                                    path="/config/basecost"
                                    element={<BaseCost />}
                                />
                                <Route
                                    path="/config/repaircost"
                                    element={<RepairCost />}
                                />
                                <Route
                                    path="/config/buildingschoolcost"
                                    element={<BuildingSchoolCost />}
                                />
                                <Route
                                    path="/config/usercode"
                                    element={<UserCode />}
                                />
                                <Route
                                    path="/config/meter"
                                    element={<Meter />}
                                />
                                <Route
                                    path="/config/economycategoryformula"
                                    element={<EconomyCategoryFormula />}
                                />
                                <Route
                                    path="/config/betweencost"
                                    element={<BetweenCost />}
                                />
                                <Route
                                    path="/config/editindicator"
                                    element={<EditIndicator />}
                                />
                                 <Route
                                    path="/config/kpi"
                                    element={<KPI />}
                                />
                                <Route
                                    path="/config/strategyplan"
                                    element={<StrategyPlan />}
                                />
                            </Routes>
                        }
                    </div>
                </Col>
            </Row>
            {visible && userInfo?.menuroleids.includes("2") && (
                <Drawer
                    placement={'left'}
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    style={{
                        display: !visible ? 'none' : '',
                        marginLeft: '106px',
                        marginTop: '48px',
                        zIndex: 10
                    }}
                    width={235}
                >
                    <div style={{
                        background: days >= 0 ? '#F4BF00' : '#CE563C',
                        width: '186px',
                        height: '44px',
                        borderRadius: '4px',
                    }}
                    >
                        <p style={{ textAlign: 'center', fontWeight: '600', paddingTop: '12px', color: '#fff' }}>
                            {days >= 0 ? days + ' хоног үлдсэн' : days * -1 + ' хоног хоцорсон'}
                        </p>
                        <h5>Нээлттэй төсөв: {data?.yEAR}</h5>
                        <p style={{ color: '#6A6A6A', fontSize: '12px' }}>{data?.sTATUSNAME}</p>
                    </div>
                    <Menu
                        mode="inline"
                        style={{
                            height: '500px',
                            borderRight: 0,
                            marginTop: '60px',
                            overFlow: 'hidden',
                            lineHeight: 'normal',
                            textAlign: 'left'
                        }}
                    >
                        <Menu.Item icon={<AppListIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(0)}>
                            Үзүүлэлт
                        </Menu.Item>
                        <Menu.Item icon={<MoneyHandIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(1)}>
                            Төсвийн орлого
                        </Menu.Item>
                        <Menu.Item icon={<MoneyDismissIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(2)}>
                            Төсвийн зарлага
                        </Menu.Item>
                        <Menu.Item icon={<AutoSumIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(3)}>
                            Төсөв
                        </Menu.Item>
                        <Menu.Item icon={<CommentIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(4)}>
                            Төсвийн санал
                        </Menu.Item>
                        <Menu.Item icon={<ReportIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => onNavigate(5)}>
                            Тайлан
                        </Menu.Item>
                        <h5>Төсөв</h5>
                        <Menu.Item icon={<AddIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => { onBudgetNew() }}>
                            Шинэ төсөв үүсгэх
                        </Menu.Item>
                        <Menu.Item icon={<HistoryIcon style={{ width: '20px' }} />} style={{ paddingLeft: 0 }} onClick={() => { setVisible(false); navigate("/budget/list"); }}>
                            Төсвийн түүх
                        </Menu.Item>
                    </Menu>
                </Drawer>
            )}
            <BudgetNewPanel id={data?.iD} isOpen={showNew} onDismiss={onDismiss}></BudgetNewPanel>
            <SideMenu isOpen={isConfigOpen} onCloseConfig={onCloseConfig} />
        </div>
    );
};

export default Budget;
