import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import { PageHeader, GridDefaultShimmer } from "dw-components";
import PositionPanel from "./Panel/PositionPanel";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import FileDownload from 'js-file-download';
import { API } from '../../../API/API';

const Positions = () => {
  const [positionId, setPositionId] = useState();
  const [gridFilter, setGridFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [showNew, setShowNew] = useState(false);
  const [data, setData] = useState({});
  const [oData, setOData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns] = useState([
    {
      name: "№",
      minWidth: "20px",
      onRender: (row, index) => {
        return <div> {index + 1 + "."} </div>
      },
      maxWidth: 25,
      minWidth: 25
    },
    {
      name: "Ажлын байрны код",
      fieldName: "code",
      minColSize: "mid",
    },
    {
      name: "Ажлын байр",
      fieldName: "name",
      minColSize: "mid",
    },
    {
      name: "Ангилал",
      fieldName: "categoryname",
      minColSize: "mid",
    },
    {
      name: "Төлөв",
      fieldName: "isactive",
      onRender: (record) => {
        return (record.isactive == 0 ? "Идэвхгүй" : "Идэвхтэй")
      },
      minColSize: "mid",
    },
    {
      fieldName: "action",
      minColSize: "mid",
      onRender: (item) => {
        return (
          <div>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit"
              onClick={() => onEditClick(item.id)}
            />
          </div>
        );
      },
    },
  ]);

  const getData = async (p_filter) => {
    setLoading(true);
    let tGridFilter = [];
    let tFitler = [];
    if (p_filter?.length > 0) {
      tFitler = p_filter;
    }

    let tData = [];
    await API.post("/api/budget/positionConfigList", tFitler).then(response => {
      if (response?.status == 200 && response?.data?.retType == 0) {
        if (response?.data?.retData?.data?.length > 0) {
          tData = response?.data?.retData.data;
        }
        if (response?.data?.retData?.category?.length > 0) {
          tGridFilter.push({
            key: "position.categoryid",
            text: "Ангилал",
            data: response?.data?.retData?.category
          });
        }
      }
    }).catch(() => {
      setLoading(false);
    });

    setLoading(false);
    setData(tData);
    setOData(tData);
    setGridFilter(tGridFilter);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSearch = (data) => {
    setData(data);
  };

  const onNew = () => {
    setShowNew(true);
  };

  const onDismiss = () => {
    setPositionId();
    setShowNew(false);
  };

  const onEditClick = (id) => {
    setPositionId(id);
    setShowNew(true);
  };

  const downloadExcel = async () => {
    let tFitler = [];
    if (filteredData?.length > 0) {
      tFitler = filteredData;
    }

    await API.post("/api/budget/positionConfigListTemp?filetype=xlsx", tFitler, {
      'responseType': 'blob'
    }).then(response => {
      FileDownload(response.data, "Ажлын байр.xlsx");

    }).catch(response => {
        setLoadingDownload(false);
    });
    setLoadingDownload(false);
  }

  const onGridFilterChange = async (p_gridfiltervalue) => {
    setFilteredData(p_gridfiltervalue);
    getData(p_gridfiltervalue);
  }
  

return (
  <div>
    <Row style={{ minHeight: "95vh" }} wrap={false}>
      <Col flex="none">
        
      </Col>
      <Col flex="auto" style={{ backgroundColor: "#f0f2f5" }}>
        <PageHeader
          title={"Нийт"}
          dataCount={data?.length}
          buttonTitle="Ажлын байр"
          onButtonClick={onNew}
          columns={columns}
          onSearch={onSearch}
          data={oData}
          loading={loading} 
          addRightButton={(
            <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                <Space size={16} >
                    <div onClick={downloadExcel}>
                        <ExcelIcon />
                    </div>
                </Space>
            </div>
        )}
        gridFilter={gridFilter}
        onGridFilterChange={onGridFilterChange}
        />
        <div style={{ margin: 16 }}>
          <GridDefaultShimmer columns={columns} data={data} loading={loading} />
          <PositionPanel id={positionId} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} />
        </div>
      </Col>
    </Row>
  </div>
);
};

export default Positions;
