import React, { useContext, useState, useEffect } from "react";
import {
    ButtonDefault,
    PanelBase,
    LoadSpin,
    FieldNumberFormat,
    FieldText
} from "dw-components";
import { API } from '../../../API/API';
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, ColumnFixing, Scrolling, Selection } from "devextreme-react/data-grid";
import { UserContext } from '../../../Context/UserContext';

const SuggestionSchoolGridPanel = (props) => {
    const { userInfo } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSave = async () => {
        if (props?.id) {
            await API.post("/api/budget/updBudgetDep?type_=2", dataEdit).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    message.success("Амжилттай");
                    setEdit(false);
                }

            }).catch(() => {
                setLoading(false);
            })       
         props.onDismiss();
         if (props.afterSave) {
             await props.afterSave();
         }
        }
    }

    const getData = async () => {
        setLoading(true);
        let tData = [];
        if (props?.id) {
            await API.get("/api/budget/suggestionDepartmentEdit?budgetid=" + props?.id + "&schoolid=" + userInfo?.schoolid + "&ecocatid=" + props?.ecocatid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
        }

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        setData(tData);
        setDataEdit(tData);
        setLoading(false);
    };

    useEffect(() => {
        getData(props?.id);
    }, [props?.ecocatid]);

    const fieldOnChanged = (pIndex, pId, pValue, pField) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
        if (props?.onEdit) {
            onSave(tDataEdit);
        }
    }

    const onDismiss = () => {
      setData({});
      setCheckRule(false);
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    return (
        <PanelBase
            headerText="Санал"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={!props.disable && (<div> {loading ? (<LoadSpin />) : (<ButtonDefault text="Хадгалах" onClick={onSave} />)}</div>)}
        >
            <DataGrid
                dataSource={data}
                columnAutoWidth={true}
                wordWrapEnabled={false}
                showRowLines
                showBorders
            >
                <Scrolling columnRenderingMode="standard" mode="standard" />
                <Column
                    minWidth={200}
                    dataField="fullname"
                    caption="Нэгж"
                     />
                <Column caption="Нэгжийн санал">
                    <Column
                        caption="Дүн"
                        dataField="price"
                        format="fixedPoint" 
                        width={150}
                        cellRender={(item) => {
                         return (
                             <FieldNumberFormat disable={true} defaultValue={item.data?.price} />
                         );
                     }} />
                    <Column
                        caption="Хувь"
                        dataField="percent"
                        format="fixedPoint"
                        width={60}
                        cellRender={item => {
                            return (
                              <FieldNumberFormat disable={true} defaultValue={((item?.data?.price || 0) * 100) / (props?.total || 1)} />
                            );
                        }} />
                    <Column
                        width={150}
                        dataField="note"
                        caption="Тайлбар"
                         
                        cellRender={(item) => {
                         return (
                             <FieldText disable={true} defaultValue={item.data?.note} />
                         );
                     }} />
                </Column>
                <Column caption="Сургуулийн санал">
                <Column
                        caption="Дүн"
                        dataField="schoolprice"
                        format="fixedPoint" 
                        width={150}
                        cellRender={(item) => {
                         return (
                             <FieldNumberFormat onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "schoolprice")} defaultValue={item.data?.schoolprice} />
                         );
                     }} />
                    <Column
                        caption="Хувь"
                        dataField="schoolpercent"
                        format="fixedPoint"
                        width={60} 
                        cellRender={item => {
                            return (
                              <FieldNumberFormat disable={true} defaultValue={((item?.data?.schoolprice || 0) * 100) / (props?.total || 1)} />
                            );
                        }} />
                    <Column
                        width={150}
                        dataField="schoolnote"
                        caption="Тайлбар"
                          
                        cellRender={(item) => {
                         return (
                             <FieldText onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.bdepid, pValue, "schoolnote")} defaultValue={item.data?.schoolnote} />
                         );
                     }} />
                     </Column>
            </DataGrid>
        </PanelBase >
    );
}

export default SuggestionSchoolGridPanel;