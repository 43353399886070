import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, message, Typography } from "antd";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { ButtonDefault } from "dw-components";
import {
  doc,
  addDoc,
  updateDoc,
  collection,
  getDoc,
} from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { BUDGETSTATUS } from "../../Const/BudgetStatus";

import BranchIncomeIndicators from "./PivotItems/BranchIncomeIndicators";
import BranchOutcomeIndicators from "./PivotItems/BranchOutcomeIndicators";
import BranchTotalIndicators from "./PivotItems/BranchTotalIndicators"
import { BRANCHSCHOOLSKEYS } from "../../Const/BranchSchools";

const { Text } = Typography;

const BranchConfirm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [budgetDetail, setBudgetDetail] = useState({});
  const [indicatorTotals, setIndicatorTotals] = useState({});
  const [totalIncome, setTotalIncome] = useState();
  const [totalOutcome, setTotalOutcome] = useState();
  const [prevInitBalance, setPrevInitBalance] = useState();
  const [prevLastBalance, setPrevLastBalance] = useState();

  const [calculateMethodsData, setCalculateMethodsData] = useState({});
  const [budgetReq, setBudgetReq] = useState({});

  const methodCollectionRef = collection(db, "budgetMethods");

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  useEffect(async () => {
    const queryParams = new URLSearchParams(search);
    const budgetRequestId = queryParams.get("requestId");

    // get current branch request detail
    const BudgetReqDoc = doc(db, "budgetRequests", budgetRequestId);
    const bRequest = await getDoc(BudgetReqDoc);
    const budgetRequest = { ...bRequest.data(), id: bRequest.id };

    // get budget detail
    const BudgetDoc = doc(db, "budgets", budgetRequest?.budgetId);
    const bBudget = await getDoc(BudgetDoc);
    const budget = { ...bBudget.data(), id: bBudget.id };

    setBudgetDetail(budget);
    setBudgetReq(budgetRequest);
  }, []);

  const updateBudget = async () => {
    try {
      const BudgetReqDoc = doc(db, "budgetRequests", budgetReq.id);
      await updateDoc(BudgetReqDoc, {
        indicatorTotals: indicatorTotals,
      });

      if (Object.entries(calculateMethodsData).length > 0) {
        let item;
        for (let i in calculateMethodsData) {
          for (let j in calculateMethodsData[i]) {
            item = calculateMethodsData[i][j];
            if (item.budgetId) {
              const MethodCollectionDoc = doc(db, "budgetMethods", item.id);
              await updateDoc(MethodCollectionDoc, {
                ...item,
                budgetId: budgetDetail.id,
                budgetRequestId: budgetReq.id,
              });
            } else {
              await addDoc(methodCollectionRef, {
                ...item,
                budgetId: budgetDetail.id,
                budgetRequestId: budgetReq.id,
              });
            }
          }
        }
      }

      message.success("Амжилттай засагдлаа");
    } catch (error) {
      console.log(error);
      message.error("Амжилтгүй");
    }
  };

  const onEditSubmit = async () => {
    await updateBudget();
    navigate({
      pathname: "/budget/confirm",
      search: `?budgetId=${budgetDetail.id}`
    })
  };

  const setMethodsData = async (data) => {
    let temp = { ...calculateMethodsData };
    temp = { ...data, ...temp };
    setCalculateMethodsData(temp);
  };

  const setTotals = async (data) => {
    let temp = { ...indicatorTotals };
    temp = { ...data, ...temp };
    setIndicatorTotals(temp);
  };

  return (
    <div>
      <div style={{ margin: "16px 20px 16px" }}>
        <Row className="budget-header">
          <Col span={5}>
            <h2 className="budget-title">{BRANCHSCHOOLSKEYS[budgetReq.branchSchool]}</h2>
            <Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Нээсэн огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetDetail.openedDate}
                  </h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Хаах огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetDetail.closeDate}
                  </h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">
                    Албан тушаалтны нэр: &nbsp;
                  </h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetDetail.employeeName}
                  </h4>
                </Stack.Item>
              </Stack>
            </Stack>
          </Col>
          <Col span={2} style={{ marginTop: 36 }}>
            <h4>Төлөв</h4>
            <h4 className="budget-status">
              {BUDGETSTATUS[budgetDetail.status]}
            </h4>
          </Col>
          <Col span={3} style={{ marginTop: 36 }}>
            <h4>Нийт орлого</h4>
            <h4 className="budget-income">
              {mntFormatter.format(totalIncome || 0) + "₮"}
            </h4>
          </Col>
          <Col span={3} style={{ marginTop: 36 }}>
            <h4>Нийт зардал</h4>
            <h4 className="budget-outcome">
              {mntFormatter.format(totalOutcome || 0) + "₮"}
            </h4>
          </Col>
          <Col span={4} style={{ marginTop: 36 }}>
            <h4>Мөнгөн хөрөнгийн эхний үлдэгдэл</h4>
            <h4 className="budget-balance">
              {mntFormatter.format(prevInitBalance || 0) + "₮"}
            </h4>
          </Col>
          <Col span={4} style={{ marginTop: 36 }}>
            <h4>Мөнгөн хөрөнгийн эцсийн үлдэгдэл</h4>
            <h4 className="budget-balance">
              {mntFormatter.format(prevLastBalance || 0) + "₮"}
            </h4>
          </Col>
          <Col span={3} style={{ display: "flex", justifyContent: "end" }}>
            <ButtonDefault text={"Хадгалах"} onClick={onEditSubmit} />
          </Col>
        </Row>
      </div>
      <Pivot
        defaultSelectedKey="0"
        styles={{
          linkIsSelected: {
            "&::before": {
              backgroundColor: "#0358A7",
              height: 3,
            },
          },
          link: {
            height: 48,
          },
          root: {
            height: 48,
            float: "left",
            paddingLeft: 16,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            textAlignLast: "justify",
          },
        }}
      >
        <PivotItem headerText="Нийт үзүүлэлт" alwaysRender={true}>
          <BranchTotalIndicators
            setTotalIncome={setTotalIncome}
            setTotalOutcome={setTotalOutcome}
            setMethodsData={setMethodsData}
            setTotals={setTotals}
            budgetId={budgetDetail.id}
            budgetRequest={budgetReq}
          ></BranchTotalIndicators>
        </PivotItem>
        {/* <PivotItem headerText="Зарлагын үзүүлэлт" alwaysRender={true}> */}
          {/* <BranchOutcomeIndicators
            setTotalOutcome={setTotalOutcome}
            setMethodsData={setMethodsData}
            setTotals={setTotals}
            budgetId={budgetDetail.id}
            budgetRequest={budgetReq}
          ></BranchOutcomeIndicators> */}
        {/* </PivotItem> */}
      </Pivot>
    </div>
  );
};

export default BranchConfirm;
