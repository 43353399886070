import React, { useContext, useEffect, useState } from 'react';
import { LoadSpin, PanelBase } from 'dw-components';
import FieldUploadRest from '../../../../Components/FieldUploadRest/FieldUploadRest';
import { API } from '../../../../API/API';
import { UserContext } from '../../../../Context/UserContext';
import { PropertiesPanel } from 'devextreme-react/diagram';

const FileAttach = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        let tData = [];
        if (props?.id) {
            setLoading(true);
            await API.get("/api/budget/getAttachFile?type=" + (props?.type ? props?.type : 'budgetdtl') + "&id=" + props?.id + "&schoolid=" + (!props?.schoolid ? userInfo?.schoolid : props?.schoolid) + "&budgetid=" + props?.budgetid).then(response => {
                if (response?.status == 200 && response?.data?.retType == 0) {
                    tData = response?.data?.retData;
                }
            }).catch(() => {
                setLoading(false);
            });
            setLoading(false);
        }
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const afterSave = async () => {
        await getData();
        if (props?.afterSave) {
            await props?.afterSave();
        }
    }

    return (
        <PanelBase
            isOpen={props.isOpen}
            onDismiss={props?.onDismiss}
            headerText={props?.name}
        >
            {loading ? <LoadSpin /> : (
                <div style={{ marginTop: 24 }}>
                    <FieldUploadRest disable={props?.disable} id={props?.id} schoolid={userInfo?.schoolid} budgetid={props?.budgetid} title="Файл хавсаргах" keyfield={props?.type ? props?.type : 'budgetdtl'} defaultValue={data} afterSave={afterSave} />
                </div>
            )}
        </PanelBase>
    );
}

export default FileAttach;