import { message } from 'antd';
import { ButtonDefault, FieldDropdown, FieldNumber, FieldText, PanelBase, LoadSpin, FieldCheck } from 'dw-components';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { API } from '../../../API/API';
import { UserContext } from '../../../Context/UserContext';
import { SCHOOLTYPE } from '../../../Const/SchoolType';
import { UserType } from '../../../Const/UserType';
import FieldComboBoxMultiSelectAll from "../../../Components/FieldComboBoxMultiSelectAll/FieldComboBoxMultiSelectAll";

const NewSchoolDep = (props) => {

    const [initData] = useState({
        //iSACTIVE: true
    });
    const [data, setData] = useState({ ...initData });
    const { userInfo } = useContext(UserContext);
    const [checkRule, setCheckRule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dSchool, setDSchool] = useState([]);
    const [dSubSchool, setDSubSchool] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const getData = async () => {
        setLoadingData(true);

        let tData = { ...initData };
        if (props?.id) {
            await APIGraphQL.post("", {
                query: `query ($id: Int) {
                    sharedQuery {
                      schooldep(id: $id) {
                        iD
                        pARENTID
                        cODE
                        nAME
                        sCHOOLTYPEID
                        sCHOOLTYPENAME
                        tYPEID
                        tYPENAME
                        oRDERBY
                        eXTERNALCODE
                        lOCATIONNAME
                        iSACTIVE
                        cREATED
                        cREATEDBY
                        budgetschoolamendment {
                          iD
                          sCHOOLIDS
                        }
                      }
                    }
                  }`,
                variables: {
                    "id": parseInt(props?.id)
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.schooldep;
                if (tResp?.iD) {
                    tData = tResp;
                    tData.budgetschoolamendment.sCHOOLIDS = tData?.budgetschoolamendment?.sCHOOLIDS?.split(',').map(Number);
                }
            }).catch(response => {
                setLoadingData(false);
            });
        }

        let tDSchool = [];
        let tSubSchools = [];
        await APIGraphQL.post("", {
            query: `query($schoolid: Int){
                sharedQuery {
                  parentschooldep {
                    key: iD
                    text: nAME
                  }
                  schoolAllByParentId(schoolid: $schoolid) {
                      key: iD
                      text: nAME
                  }
                }
              }`,
              variables: {
                  "schoolid": tData?.pARENTID ?? -1
              }
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.parentschooldep;
            if (tResp?.length > 0) {
                tDSchool = tResp;
            }
            tResp = response?.data?.data?.sharedQuery?.schoolAllByParentId;
            if (tResp?.length > 0) {
                tSubSchools = tResp;
            }
        }).catch(response => {
            setLoadingData(false);
        });
        setDSchool(tDSchool);
        setDSubSchool(tSubSchools);

        setData(tData);
        setLoadingData(false);
    }

    useEffect(() => {
        getData();
    }, [props?.id]);

    const onDismiss = () => {
        setData({ ...initData });
        setCheckRule(false);
        if (props.onDismiss) {
            props.onDismiss();
        }
    }

    const fieldOnChanged = (key, text, field, fieldname) => {
        if (field) {
            if(field == "pARENTID")
            {
                let tSubSchools = [];
                APIGraphQL.post("", {
                    query: `query($schoolid: Int){
                        sharedQuery {
                            schoolAllByParentId(schoolid: $schoolid) {
                                key: iD
                                text: nAME
                            }
                        }
                    }`,
                    variables: {
                        "schoolid": key ?? -1
                    }
                }).then(response => {
                    let tResp = response?.data?.data?.sharedQuery?.schoolAllByParentId;
                    if (tResp?.length > 0) {
                        tSubSchools = tResp;
                    }
                    setDSubSchool(tSubSchools);
                }).catch(response => {
                    setLoadingData(false);
                });
            }

            let tData = { ...data };
            tData[field] = key;
            if (fieldname) {
                tData[fieldname] = text;
            }
            setData(tData);
        }
    }
   
    const onSave = async () => {
        let vRule = true;
        setCheckRule(true);

        let tData = { ...data };

        if (!tData?.pARENTID ||
            !tData?.cODE ||
            !tData?.nAME ||
            !tData?.sCHOOLTYPEID ||
            !tData?.sCHOOLTYPENAME ||
            !tData?.eXTERNALCODE)
            vRule = false;


        if (vRule) {

            tData.iSACTIVE = tData?.iSACTIVE == true ? 1 : 0;
            let tSuccess = false;
            if (!props.id) {
                tData.iD = 0;
                tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
                tData.cREATEDBY = userInfo?.username;
            }

            tData.sCHOOLIDS = tData.sCHOOLIDS?.filter(e => e !== 'selectAll').toString();

            setLoading(true);
            await API.post("/api/budget/addSchool", tData
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    tSuccess = true;
                } else {
                    message.error(response?.data?.retMsg);
                    setLoading(false);
                }

            }).catch((exception) => {
                setLoading(false);
            });

            if (tSuccess) {
                message.success("Амжилттай.");
                onDismiss();
                if (props.afterSave) {
                    await props.afterSave();
                }
            }
        }
    }

    return (
        <PanelBase
            headerText={"Сургууль, нэгж"}
            isOpen={props.isOpen}
            onDismiss={onDismiss}
            PanelType="medium"
            buttons={loading
                ? <LoadSpin />
                : <ButtonDefault
                    text={"Хадгалах"}
                    onClick={onSave}
                />
            }
        >
            <div>
                <div style={{ marginTop: 16 }}>
                    <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Сургууль" keyfield="pARENTID" defaultValue={data.pARENTID} checkreq={checkRule} data={dSchool} />
                </div>
                <div style={{ marginTop: 16 }}> <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Ангилал" keyfield="sCHOOLTYPEID" keyfieldname="sCHOOLTYPENAME" defaultValue={data.sCHOOLTYPEID} data={SCHOOLTYPE} checkreq={checkRule} /></div>
                <div style={{ marginTop: 16 }}> <FieldDropdown loading={loadingData} required onChanged={fieldOnChanged} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} checkreq={checkRule} /></div>
                <div style={{ marginTop: 16 }}>
                    <FieldText loading={loadingData} required onChanged={fieldOnChanged} title="Товчилсон нэр" keyfield="cODE" defaultValue={data.cODE} checkreq={checkRule} /> </div>
                <div style={{ marginTop: 16 }}>
                    <FieldText loading={loadingData} required onChanged={fieldOnChanged} title="Нэр" keyfield="nAME" defaultValue={data.nAME} checkreq={checkRule} /> </div>
                <div style={{ marginTop: 16 }}>
                    <FieldCheck loading={loadingData} required onChanged={fieldOnChanged} title="Идэвхитэй эсэх" keyfield="iSACTIVE" defaultValue={data.iSACTIVE} checkreq={checkRule} /> </div>
                {data.iSACTIVE == 0 && <div style={{ color:'red' }}>Сургуулийг идэвхгүй болгосноор тухайн нэгжийн төсвийг хамгийн сүүлийн төсвөөс устгах ба ДАХИН СЭРГЭЭХ БОЛОМЖГҮЙ-г анхаарна уу!</div>}
                <div style={{ marginTop: 16 }}>
                    <FieldNumber loading={loadingData} required onChanged={fieldOnChanged} title="Код" keyfield="eXTERNALCODE" defaultValue={data.eXTERNALCODE} checkreq={checkRule} /> </div>
                </div>
                <div style={{ marginTop: 13 }}>
                    <FieldText title="Харуулах эрэмбэ" keyfield="lOCATIONNAME" defaultValue={data.lOCATIONNAME} onChanged={fieldOnChanged} required checkreq={checkRule} loading={loading} />
                </div>
                {data?.sCHOOLTYPEID == 7 &&
                    <div style={{ marginTop: 13 }}>
                        <FieldComboBoxMultiSelectAll loading={loading} onChanged={fieldOnChanged} title="Төсөв татах газар хэлтэс" keyfield="sCHOOLIDS" defaultValue={data?.budgetschoolamendment?.sCHOOLIDS} data={dSubSchool} />
                    </div>
                }
        </PanelBase>
    );
}

export default NewSchoolDep;