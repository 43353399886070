import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Table, message, Tooltip, Typography } from "antd";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { ButtonDefault, ColEdit } from "dw-components";
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { methodDataToList } from "../../Utils";
import { BUDGETSTATUS } from "../../Const/BudgetStatus";
import {
  categoryTree,
  groupListData,
  convertToTableTree,
  getParentCategories,
} from "../../Utils";
//calculate method panels
import ByInputIncomePanel from "../CalculateMethods/Income/ByInputIncomePanel";
import ByPerformanceIncomePanel from "../CalculateMethods/Income/ByPerformanceIncomePanel";

import ByInputOutcomePanel from "../CalculateMethods/Outcome/ByInputOutcomePanel";
import ByPerformanceOutcomePanel from "../CalculateMethods/Outcome/ByPerformanceOutcomePanel";

import BelasPanel from "../CalculateMethods/Income/BelasPanel";
import BudgetByPercentPanel from "../CalculateMethods/Outcome/BudgetByPercentPanel";
import NormativeExpPanel from "../CalculateMethods/Outcome/NormativeExpPanel";
import PurchaseAssetPanel from "../CalculateMethods/Outcome/PurchaseAssetPanel";
import FuelNormPanel from "../CalculateMethods/Outcome/FuelNormPanel";

const { Text } = Typography;

const BudgetIndicators = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { budgetData, budgetId, budgetRequestId } = state;
  const [name, setName] = useState();

  const [data, setData] = useState([]);

  const [totalIncome, setTotalIncome] = useState();
  const [totalOutcome, setTotalOutcome] = useState();
  const [prevInitBalance, setPrevInitBalance] = useState();
  const [prevLastBalance, setPrevLastBalance] = useState();

  const [selectedCalculateMethod, setSelectedCalculateMethod] = useState("");
  const [selectedCalculateMethodData, setSelectedCalculateMethodData] =
    useState();
  const [selectedBudgetId, setSelectedBudgetId] = useState("");
  const [selectedIndicatorData, setSelectedIndicatorData] = useState("");

  const [showCalculateMethod, setShowCalculateMethod] = useState(false);
  const [calculateMethodData, setCalculateMethodData] = useState({});
  const [bCalculateMethodData, setBCalculateMethodData] = useState({});
  const [hCalculateMethodData, setHCalculateMethodData] = useState({});
  const [budgetReq, setBudgetReq] = useState({});

  const [departmentTotal, setDepartmentTotal] = useState(0);
  const [branchTotal, setBranchTotal] = useState(0);
  const [headTotal, setHeadTotal] = useState(0);

  const [parentCategories, setParentCategories] = useState([]);

  const methodCollectionRef = collection(db, "budgetMethods");
  const BudgetIndicatorsRef = collection(db, "budgetIndicators");
  const BudgetRequestsRef = collection(db, "budgetRequests");
  const IndicatorGroupsRef = collection(db, "indicatorGroups");

  useEffect(async () => {
    const BudgetReqDoc = doc(db, "budgetRequests", budgetRequestId);
    const budgetRequest = await getDoc(BudgetReqDoc);
    const budgetRequestData = { ...budgetRequest.data(), id: budgetRequest.id };

    // department method data
    const depQuery = query(
      methodCollectionRef,
      where("budgetId", "==", budgetId),
      where("branchSchool", "==", budgetRequestData.branchSchool),
      where("type", "==", "DEPARTMENT")
    );

    const DepMethodsDocs = await getDocs(depQuery);
    const depMethods = DepMethodsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const depMethodsObj = depMethods.reduce(
      (obj, current) => ({
        ...obj,
        [current.indicatorId]: {
          ...obj[current.indicatorId],
          [current.department]: current,
        },
      }),
      {}
    );

    // branch method data
    const branchQuery = query(
      methodCollectionRef,
      where("budgetRequestId", "==", budgetRequestId),
      where("type", "==", "BRANCH")
    );

    const BranchMethodsDocs = await getDocs(branchQuery);
    const branchMethods = BranchMethodsDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const branchMethodsObj = branchMethods.reduce(
      (obj, current) => ({
        ...obj,
        [current.indicatorId]: {
          ...obj[current.indicatorId],
          [current.department]: current,
        },
      }),
      {}
    );

    // head method data
    let hMethodsObj;
    if (["CHECKING", "APPROVED"].includes(budgetRequestData.status)) {
      const hMethodQuery = query(
        methodCollectionRef,
        where("budgetRequestId", "==", budgetRequestData.id),
        where("type", "==", "HEAD")
      );

      const hMethodsDocs = await getDocs(hMethodQuery);
      const hMethods = hMethodsDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      hMethodsObj = hMethods.reduce(
        (obj, current) => ({
          ...obj,
          [current.indicatorId]: {
            ...obj[current.indicatorId],
            [current.department]: current,
          },
        }),
        {}
      );

      await calcTotals(hMethodsObj);
    } else {
      hMethodsObj = JSON.parse(JSON.stringify(branchMethodsObj));
      for (let key in hMethodsObj) {
        for (let item in hMethodsObj[key]) {
          hMethodsObj[key][item].type = "HEAD";
          hMethodsObj[key][item].description = "";
        }
      }

      await calcTotals(hMethodsObj);
    }

    // indicator groups
    const IndicatorGroupDocs = await getDocs(IndicatorGroupsRef);
    const indicatorGroups = IndicatorGroupDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // indicators of current budget
    const indicatorQuery = query(
      BudgetIndicatorsRef,
      where("budgetId", "==", budgetId)
    );
    const indicatorDocs = await getDocs(indicatorQuery);
    const indicators = indicatorDocs.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const categories = await categoryTree(
      indicatorGroups,
      "groupParent",
      "groupName"
    );

    const parentCats = await getParentCategories(categories);
    const groupedIndicators = await groupListData(indicators);
    const listData = await convertToTableTree(categories, groupedIndicators);
    const methodList = await methodDataToList(listData, branchMethodsObj);

    setParentCategories(parentCats);
    setHCalculateMethodData(hMethodsObj);
    setBCalculateMethodData(branchMethodsObj);
    setCalculateMethodData(depMethodsObj);
    setBudgetReq(budgetRequestData);
    setData(methodList);
  }, []);

  const mntFormatter = Intl.NumberFormat("mn-MN", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  let branchRowTotal = 0;
  let departmentRowTotal = 0;
  let headRowTotal = 0;

  const columns = [
    {
      title: "Үзүүлэлт",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text, record) => {
        if (record.methodId) {
          return record.name;
        } else {
          return record.code + " - " + record.name;
        }
      },
    },
    {
      title: "Нэгжийн санал",
      width: "5%",
      align: "left",
    },
    {
      title: "Сургуулийн санал",
      width: "5%",
      align: "left",
      children: [
        {
          title: "Тоо хэмжээ",
          dataIndex: "bCount",
          key: "bCount",
          width: "8%",
          align: "right",
          render: (text, record) => {
            let index = 0;
            if (record.methodId) {
              ++index;
              return <div>{index}</div>;
            }
          },
        },
        {
          title: "Дүн",
          dataIndex: "currentBudget",
          key: "currentBudget",
          width: "8%",
          align: "right",
          render: (text, record) => {
            if (record.methodId) {
              let rowData =
                bCalculateMethodData[record.indicatorId][record.name];
              if (rowData) {
                return (
                  <div>
                    <Tooltip placement="bottom" title={rowData?.description}>
                      {mntFormatter.format(
                        rowData?.totalBudgetAmount.replace(/,/g, "")
                      ) + "₮"}
                    </Tooltip>
                  </div>
                );
              }
            } else {
              let totalAmount = 0;
              const calcTotalAmount = (record) => {
                if (record.children && record.children.length > 0) {
                  record.children.map((row) => {
                    if (row.methodId) {
                      let amount = 0;
                      let methodData =
                        bCalculateMethodData[row.indicatorId][row.name];
                      if (methodData) {
                        amount = parseFloat(
                          methodData?.totalBudgetAmount.replace(/,/g, "")
                        );
                      }
                      totalAmount += amount;
                    } else {
                      calcTotalAmount(row);
                    }
                  });
                }

                return totalAmount;
              };

              let total = calcTotalAmount(record);

              if (parentCategories.includes(record.code)) {
                switch (record.category) {
                  case "income":
                    branchRowTotal += total;
                    break;
                  case "outcome":
                    branchRowTotal -= total;
                    break;
                }

                setTotals(branchRowTotal, "BRANCH");
              }

              return (
                <div className="parent-total">
                  {mntFormatter.format(total) + "₮"}
                </div>
              );
            }
          },
        },
      ],
    },
    {
      title: "Нэгтгэл төсөв",
      width: "5%",
      align: "left",
      children: [
        {
          title: "Тоо хэмжээ",
          dataIndex: "hCount",
          key: "hCount",
          width: "8%",
          align: "right",
          render: (text, record) => {
            let index = 0;
            if (record.methodId) {
              ++index;
              return <div>{index}</div>;
            }
          },
        },
        {
          title: "Дүн",
          dataIndex: "currentBudget",
          key: "currentBudget",
          width: "8%",
          align: "right",
          render: (text, record) => {
            if (record.methodId) {
              let methodData =
                hCalculateMethodData[record.indicatorId][record.name];
              if (methodData) {
                return (
                  <div>
                    <ColEdit
                      onClick={() => {
                        onCalculateMethodNew({
                          indicatorId: record.indicatorId,
                          calculateMethod: record.indicatorData.calculateMethod,
                          category: record.indicatorData.category,
                          name: record.name,
                          department: methodData.department,
                        });
                      }}
                    >
                      {mntFormatter.format(
                        methodData.totalBudgetAmount.replace(/,/g, "")
                      ) + "₮"}
                    </ColEdit>
                  </div>
                );
              }
            } else {
              let totalAmount = 0;
              const calcTotalAmount = (record) => {
                if (record.children && record.children.length > 0) {
                  record.children.map((row) => {
                    if (row.methodId) {
                      let amount = 0;
                      let methodData =
                        hCalculateMethodData[row.indicatorId][row.name];
                      if (methodData) {
                        amount = parseFloat(
                          methodData?.totalBudgetAmount.replace(/,/g, "")
                        );
                      }
                      totalAmount += amount;
                    } else {
                      calcTotalAmount(row);
                    }
                  });
                }

                return totalAmount;
              };

              let total = calcTotalAmount(record);

              if (parentCategories.includes(record.code)) {
                switch (record.category) {
                  case "income":
                    headRowTotal += total;
                    break;
                  case "outcome":
                    headRowTotal -= total;
                    break;
                }

                setTotals(headRowTotal, "HEAD");
              }

              return (
                <div className="parent-total">
                  {mntFormatter.format(total) + "₮"}
                </div>
              );
            }
          },
        },
        {
          title: "Тайлбар",
          dataIndex: "description",
          key: "description",
          width: "30%",
          render: (text, record) => {
            if (record.methodId) {
              return (
                <div>
                  {
                    hCalculateMethodData[record.indicatorId][record.name]
                      ?.description
                  }
                </div>
              );
            }
          },
        },
      ],
    },
  ];

  const calcTotals = async (data) => {
    let tIncome = 0;
    let tOutcome = 0;

    for (let i in data) {
      for (let j in data[i]) {
        let value = data[i][j];
        let amount = parseFloat(value.totalBudgetAmount.replace(/,/g, ""));
        if (value.category == "income") {
          tIncome += amount;
        } else if (value.category == "outcome") {
          tOutcome += amount;
        }
      }
    }
    setTotalIncome(tIncome);
    setTotalOutcome(tOutcome);
  };

  const setTotals = async (amount, type) => {
    switch (type) {
      case "BRANCH":
        setBranchTotal(amount);
        break;
      case "DEPARTMENT":
        setDepartmentTotal(amount);
        break;
      case "HEAD":
        setHeadTotal(amount);
        break;
    }
  };

  // Writing data to firebase
  const createBudget = async (status) => {
    try {
      let payload = {
        status: status,
      };
      if (status == "ARPPROVE") {
        payload.sentDate = Date.now();
      }

      // update branch request status to APPROVE
      const BranchBudgetReqDoc = doc(db, "budgetRequests", budgetReq.id);
      await updateDoc(BranchBudgetReqDoc, payload);

      if (Object.entries(hCalculateMethodData).length > 0) {
        let item;
        for (let i in hCalculateMethodData) {
          for (let j in hCalculateMethodData[i]) {
            item = hCalculateMethodData[i][j];
            await addDoc(methodCollectionRef, {
              ...item,
              budgetId: budgetId,
              budgetRequestId: budgetReq.id,
              type: "HEAD",
            });
          }
        }
      }
      message.success("Амжилттай нэмэгдлээ");
    } catch (error) {
      console.error(error);
      message.error("Амжилтгүй");
    }
  };

  const updateBudget = async (status) => {
    try {
      if (status == "APPROVED") {
        const BudgetReqDoc = doc(db, "budgetRequests", budgetReq.id);
        await updateDoc(BudgetReqDoc, {
          status: status,
          sentDate: Date.now(),
        });
      }
      if (Object.entries(hCalculateMethodData).length > 0) {
        let item;
        for (let i in hCalculateMethodData) {
          for (let j in hCalculateMethodData[i]) {
            item = hCalculateMethodData[i][j];
            if (item.budgetId) {
              const MethodCollectionDoc = doc(db, "budgetMethods", item.id);
              await updateDoc(MethodCollectionDoc, {
                ...item,
                budgetId: budgetId,
                budgetRequestId: budgetReq.id,
              });
            } else {
              await addDoc(methodCollectionRef, {
                ...item,
                budgetId: budgetId,
                budgetRequestId: budgetReq.id,
              });
            }
          }
        }
      }

      message.success("Амжилттай засагдлаа");
    } catch (error) {
      console.error(error);
      message.error("Амжилтгүй");
    }
  };

  const onSubmit = async () => {
    await createBudget("CHECKING");
    navigate("/budget");
  };

  const onEditSubmit = async () => {
    await updateBudget();
    navigate("/budget");
  };

  const onApprove = async () => {
    if (budgetReq) {
      await updateBudget("APPROVED");
    } else {
      await createBudget("APPROVED");
    }

    navigate("/budget");
  };

  const setTempCalculateMethodData = async (data) => {
    let temp = { ...hCalculateMethodData };
    temp[data.indicatorId][data.department] = { ...data };

    await calcTotals(temp);

    setHCalculateMethodData(temp);
  };

  const onCalculateMethodNew = (data) => {
    setName(data.name);
    setSelectedCalculateMethod(data.calculateMethod);
    setSelectedIndicatorData({
      indicatorId: data.indicatorId,
      category: data.category,
    });
    setShowCalculateMethod(true);

    if (budgetId) {
      setSelectedBudgetId(budgetId);
      setSelectedCalculateMethodData(
        hCalculateMethodData[data.indicatorId][data.department]
      );
    }
  };

  const onCalculateMethodDismiss = () => {
    setSelectedCalculateMethod("");
    setSelectedIndicatorData({});
    setShowCalculateMethod(false);
    setSelectedBudgetId("");
    setSelectedCalculateMethodData({});
  };

  let vCalculateMethodPanel = null;
  switch (selectedCalculateMethod) {
    // income
    case "byInputIncome":
      vCalculateMethodPanel = (
        <ByInputIncomePanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByInputIncomePanel>
      );
      break;
    case "byPerformanceIncome":
      vCalculateMethodPanel = (
        <ByPerformanceIncomePanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByPerformanceIncomePanel>
      );
      break;

    case "БЭЛАС":
      vCalculateMethodPanel = (
        <BelasPanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></BelasPanel>
      );
      break;

    // outcome
    case "byInputOutcome":
      vCalculateMethodPanel = (
        <ByInputOutcomePanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByInputOutcomePanel>
      );
      break;
    case "byPerformanceOutcome":
      vCalculateMethodPanel = (
        <ByPerformanceOutcomePanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></ByPerformanceOutcomePanel>
      );
      break;

    case "Төсвийн дүнгээс хувиар":
      vCalculateMethodPanel = (
        <BudgetByPercentPanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          calculateMethodData={bCalculateMethodData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></BudgetByPercentPanel>
      );
      break;
    case "Нормативт зардал":
      vCalculateMethodPanel = (
        <NormativeExpPanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          calculateMethodData={calculateMethodData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></NormativeExpPanel>
      );
      break;
    case "Хөрөнгийн худалдан авалт":
      vCalculateMethodPanel = (
        <PurchaseAssetPanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          calculateMethodData={calculateMethodData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></PurchaseAssetPanel>
      );
      break;
    case "Шатахуун":
      vCalculateMethodPanel = (
        <FuelNormPanel
          name={name}
          type={"HEAD"}
          budgetId={selectedBudgetId}
          data={selectedCalculateMethodData}
          indicatorData={selectedIndicatorData}
          calculateMethodData={calculateMethodData}
          isOpen={showCalculateMethod}
          onDismiss={onCalculateMethodDismiss}
          setTempData={setTempCalculateMethodData}
        ></FuelNormPanel>
      );
      break;
    default:
      break;
  }
  return (
    <div>
      <div style={{ margin: "16px 20px 16px" }}>
        <Row className="budget-header">
          <Col span={5}>
            <h2 className="budget-title">{budgetData.budgetName}</h2>
            <Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Нээсэн огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">{budgetData.openedDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">Хаах огноо: &nbsp;</h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value"> {budgetData.closeDate}</h4>
                </Stack.Item>
              </Stack>
              <Stack horizontal>
                <Stack.Item>
                  <h4 className="budget-info-title">
                    Албан тушаалтны нэр: &nbsp;
                  </h4>
                </Stack.Item>
                <Stack.Item>
                  <h4 className="budget-info-value">
                    {budgetData.employeeName}
                  </h4>
                </Stack.Item>
              </Stack>
            </Stack>
          </Col>
          <Col span={2} style={{ marginTop: 36 }}>
            <h4>Төлөв</h4>
            <h4 className="budget-status">{BUDGETSTATUS[budgetData.status]}</h4>
          </Col>
          <Col span={3} style={{ marginTop: 36 }}>
            <h4>Нийт орлого</h4>
            <h4 className="budget-income">
              {mntFormatter.format(totalIncome || 0) + "₮"}
            </h4>
          </Col>
          <Col span={3} style={{ marginTop: 36 }}>
            <h4>Нийт зардал</h4>
            <h4 className="budget-outcome">
              {mntFormatter.format(totalOutcome || 0) + "₮"}
            </h4>
          </Col>
          <Col span={4} style={{ marginTop: 36 }}>
            <h4>Мөнгөн хөрөнгийн эхний үлдэгдэл</h4>
            <h4 className="budget-balance">
              {mntFormatter.format(prevInitBalance || 0) + "₮"}
            </h4>
          </Col>
          <Col span={4} style={{ marginTop: 36 }}>
            <h4>Мөнгөн хөрөнгийн эцсийн үлдэгдэл</h4>
            <h4 className="budget-balance">
              {mntFormatter.format(prevLastBalance || 0) + "₮"}
            </h4>
          </Col>
          <Col
            span={3}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ marginLeft: budgetReq?.status == "SAVED" ? 55 : 35 }}>
              <ButtonDefault
                text={budgetReq?.status == "SAVED" ? "Засах" : "Хадгалах"}
                onClick={budgetReq?.status == "SAVED" ? onEditSubmit : onSubmit}
                disable={budgetReq?.status == "APPROVED" ? true : false}
              />
            </div>
            <ButtonDefault
              text="Батлах"
              onClick={onApprove}
              disable={budgetReq?.status == "APPROVED" ? true : false}
            />
          </Col>
        </Row>
      </div>
      <Pivot
        defaultSelectedKey="1"
        styles={{
          linkIsSelected: {
            "&::before": {
              backgroundColor: "#0358A7",
              height: 3,
            },
          },
          link: {
            height: 48,
          },
          root: {
            height: 48,
            float: "left",
            paddingLeft: 16,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            textAlignLast: "justify",
          },
        }}
      >
        <PivotItem headerText="Үзүүлэлт">
          <div style={{ padding: "65px 20px 30px" }}>
            <h2 className="indicator-total">Байгууллага нэгжийн сонголт</h2>
            <Table
              size="small"
              columns={columns}
              dataSource={data}
              childrenColumnName="children"
              pagination={false}
              summary={() => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2} align="right">
                        <b>Нийт төсөв:</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} colSpan={1} align="right">
                        <Text type="danger">
                          {mntFormatter.format(departmentTotal) + "₮"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} colSpan={1} align="right">
                        <b>Нийт төсөв:</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={1} align="right">
                        <Text type="danger">
                          {mntFormatter.format(branchTotal) + "₮"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1} align="right">
                        <b>Нийт төсөв:</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} colSpan={1} align="right">
                        <Text type="danger">
                          {mntFormatter.format(headTotal) + "₮"}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
            {vCalculateMethodPanel}
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default BudgetIndicators;
