import React, { useState } from "react";
import { Form, Col, Row, message } from "antd";
import { LoadSpin } from "dw-components";
import { v4 as uuidv4 } from 'uuid';
import { getRedirectUrl } from "../../API/APIConfig";

const Login = (props) => {

  const [loading, setLoading] = useState(false);
  const onLogin = async (values) => {
    try {
      const clientId = 'bb2d695917774018a70f151c2b4169e7';
      const redirectUri = getRedirectUrl();
      const state = uuidv4();
      localStorage.setItem("authState", state)

      const authUrl = `https://auth.num.edu.mn/oauth2/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&response_type=code`;

      window.location.href = authUrl;

    } catch (error) {
      setLoading(false);
      message.error("Амжилтгүй " + error?.message);
    }
  }

  return (
    <div>
      <Row
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
        }}
      >
        <Col span={12}>
          <div className="login-screen"></div>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh"
            }}
          >
            <div className="LoginForm">
              <h1>Нэвтрэх</h1>
              <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={onLogin} autoComplete="off">
                {/* <Form.Item style={{ paddingRight: 17 }} label="Нэвтрэх нэр" name="username" rules={[{ required: true, message: "Нэвтрэх нэрээ оруулна уу!" }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Нууц үг" name="password" rules={[{ required: true, message: "Нууц үгээ оруулна уу!" }]}>
                  <Input.Password />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Нэвтрэх нэр сануулах</Checkbox>
                </Form.Item> */}
                <Form.Item style={{ paddingRight: 17 }}>
                  {loading ? <LoadSpin /> : (
                    <button type="primary" block htmltype="submit">
                      Нэгдсэн хандалтын системээр нэвтрэх
                    </button>
                  )}
                </Form.Item>
                {/* <div style={{ paddingRight: 17 }}>
                  <Warning value={warning} />
                </div> */}
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
