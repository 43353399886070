import React, { useEffect, useState, useContext } from "react";
import { ButtonDefault, FieldDropdown, FieldNumber, FieldNumberFormat, FieldText, LoadSpin, PanelBase } from "dw-components";
import { message } from "antd";
import moment from "moment";
import { APIGraphQL } from "../../../../API/APIGraphQL";
import { UserContext } from "../../../../Context/UserContext";

const BaseCostPanel = (props) => {
  const [data, setData] = useState({});
  const [dECat, setDECat] = useState([]);

  const [checkRule, setCheckRule] = useState(false);
  
  const { userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const ECatType = [
    {
      key: 76,
      text: "Оюутны үйлчилгээний зардал"
    }, {
      key: 78,
      text: "Сургалтын үйлчилгээний зардал"
    }, {
      key: 83,
      text: "Нэг удаагийн тэтгэмж, шагнал"
    }, {
      key: 84,
      text: "Дотоод үйл ажиллагаа"
    }, {
      key: 85,
      text: "Хангамж бараа материалын зардал"
    }
  ]

  const getData = async () => {
    setLoading(true);

    let tData = {};
    let tDECat = [];
    if (props?.id) {
      await APIGraphQL.post("", {
        query: `query ($id: Int) {
          sharedQuery {
            getBaseCost(id: $id) {
              iD
              nAME
              eCONOMYCATEGORYID
              eCONOMYCATEGORYNAME
              iNDICATORID
              iNDICATORNAME
            }
          }
        }`,
        variables: {
          "id": parseInt(props?.id)
        }
      }).then(response => {
        let tResp = response?.data?.data?.sharedQuery?.getBaseCost;
        if (tResp?.iD) {
          tData = tResp;
          tDECat.push({ key: tResp?.eCONOMYCATEGORYID, text: tResp?.eCONOMYCATEGORYNAME });
        }
      }).catch(() => {
        setLoading(false);
      });
    }
    setData(tData);
    setDECat(tDECat);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props?.id]);

  const fieldOnChanged = async (key, text, field, fieldname) => {
    if (field) {
      let tData = { ...data };
      tData[field] = key;
      if (fieldname) {
        tData[fieldname] = text;
      }
      if (field == "iNDICATORID" && key) {
        let tdECat = [];
        if (tData.iNDICATORID) {
          await APIGraphQL.post("", {
            query: `query ($indicatorid: Int) {
            sharedQuery {
              getBaseCostECatList(indicatorid: $indicatorid) {
                key: iD
                text: nAME
              }
            }
          }`,
            variables: {
              "indicatorid": parseInt(key)
            }
          }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getBaseCostECatList;
            if (tResp) {
              if (tResp?.length > 0) {
                tdECat = tResp;
              }
            }
          }).catch(() => {
            setLoading(false);
          });
        }
        setDECat(tdECat);
      }
      setData(tData);
    }
  }

  const onSave = async () => {
    setCheckRule(true);

    let vRule = true;

    let tData = { ...data };

    if (!data?.eCONOMYCATEGORYID ||
      !data?.iNDICATORID ||
      !data?.nAME) {
      vRule = false;
    }

    if (vRule) {
      let tSuccess = false;
      if (!props.id) {
        tData.iD = 0;
        tData.cREATED = moment().format("YYYY.MM.DD HH:mm:ss");
        tData.cREATEDBY = userInfo?.username;
      }
      setLoadingSave(true);
      await APIGraphQL.post("", {
        query: `mutation ($data: BaseCostInputType) {
          sharedMutation {
            addBaseCost(data: $data) {
              iD
            }
          }
        }`,
        variables: {
          "data": tData
        }
      }).then(response => {
        setLoadingSave(false);
        if (response?.data?.data?.sharedMutation?.addBaseCost?.iD) {
          tSuccess = true;
        }

      }).catch(() => {
        setLoadingSave(false);
      });

      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
        if (props.afterSave) {
          await props.afterSave();
        }
      }
    }
  }

  const onDismiss = () => {
    setData({});
    setCheckRule(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const onDelete = async () => {
    let tSuccess = false;
    await APIGraphQL.post("", {
      query: `mutation deleteBaseCost($id: Int) {
        sharedMutation {
          deleteBaseCost(id: $id)
        }
      }`,
      variables: {
        "id": parseInt(props.id)
      }
    }).then(response => {
      if (response?.data?.data?.sharedMutation?.deleteBaseCost == 1) {
        tSuccess = true;
        if (props.afterSave) {
          props.afterSave();
        }
      }
      if (tSuccess) {
        message.success("Амжилттай.");
        onDismiss();
      }
    });
  }

  return (
    <PanelBase
      headerText={props?.id ? "Засах" : "Нэмэх"}
      isOpen={props.isOpen}
      delete={props.id > 0 ? true : false}
      onDelete={onDelete}
      onDismiss={() => {
        props.onDismiss();
        setData({});
      }}
      PanelType="medium"
      buttons={loadingSave ? <LoadSpin /> :
        <div style={{ display: "flex" }}>
          <div>
            <ButtonDefault
              text={props?.id ? "Засах" : "Хадгалах"}
              onClick={onSave}
            />
          </div>
        </div>
      }
    >
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Дэд маягтын төрөл" keyfield="iNDICATORID" keyfieldname="iNDICATORNAME" checkreq={checkRule} defaultValue={data.iNDICATORID} data={ECatType} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldDropdown required loading={loading} onChanged={fieldOnChanged} title="Эдийн засгийн ангилал" keyfield="eCONOMYCATEGORYID" keyfieldname="eCONOMYCATEGORYNAME" checkreq={checkRule} defaultValue={data.eCONOMYCATEGORYID} data={dECat} />
      </div>
      <div style={{ marginTop: 13 }}>
        <FieldText required loading={loading} onChanged={fieldOnChanged} title="Зардлын ангиллын нэр" keyfield="nAME" checkreq={checkRule} defaultValue={data.nAME} />
      </div>

    </PanelBase>
  );
};

export default BaseCostPanel;
