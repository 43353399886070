const RATINGHIERARCHY = [
  { key: "ТҮ-5 I", text: "ТҮ-5 I" },
  { key: "ТҮ-5 II", text: "ТҮ-5 II" },
  { key: "ТҮ-5 III", text: "ТҮ-5 III" },
  { key: "ТҮ-5 IV", text: "ТҮ-5 IV" },
  { key: "ТҮ-5 V", text: "ТҮ-5 V" },

  { key: "ТҮ-7 I", text: "ТҮ-7 I" },
  { key: "ТҮ-7 II", text: "ТҮ-7 II" },
  { key: "ТҮ-7 III", text: "ТҮ-7 III" },
  { key: "ТҮ-7 IV", text: "ТҮ-7 IV" },
  { key: "ТҮ-7 V", text: "ТҮ-7 V" },

  { key: "ТҮ-8 I", text: "ТҮ-8 I" },
  { key: "ТҮ-8 II", text: "ТҮ-8 II" },
  { key: "ТҮ-8 III", text: "ТҮ-8 III" },
  { key: "ТҮ-8 IV", text: "ТҮ-8 IV" },
  { key: "ТҮ-8 V", text: "ТҮ-8 V" },

  { key: "ТҮМБ-4 I", text: "ТҮМБ-4 I" },
  { key: "ТҮМБ-4 II", text: "ТҮМБ-4 II" },
  { key: "ТҮМБ-4 III", text: "ТҮМБ-4 III" },
  { key: "ТҮМБ-4 IV", text: "ТҮМБ-4 IV" },
  { key: "ТҮМБ-4 V", text: "ТҮМБ-4 V" },

  { key: "ТҮМБ-5 I", text: "ТҮМБ-5 I" },
  { key: "ТҮМБ-5 II", text: "ТҮМБ-5 II" },
  { key: "ТҮМБ-5 III", text: "ТҮМБ-5 III" },
  { key: "ТҮМБ-5 IV", text: "ТҮМБ-5 IV" },
  { key: "ТҮМБ-5 V", text: "ТҮМБ-5 V" },
  
  { key: "ТҮМБ-6 I", text: "ТҮМБ-6 I" },
  { key: "ТҮМБ-6 II", text: "ТҮМБ-6 II" },
  { key: "ТҮМБ-6 III", text: "ТҮМБ-6 III" },
  { key: "ТҮМБ-6 IV", text: "ТҮМБ-6 IV" },
  { key: "ТҮМБ-6 V", text: "ТҮМБ-6 V" },

  { key: "ТҮМБ-7 I", text: "ТҮМБ-7 I" },
  { key: "ТҮМБ-7 II", text: "ТҮМБ-7 II" },
  { key: "ТҮМБ-7 III", text: "ТҮМБ-7 III" },
  { key: "ТҮМБ-7 IV", text: "ТҮМБ-7 IV" },
  { key: "ТҮМБ-7 V", text: "ТҮМБ-7 V" },
  
  { key: "ТҮМБ-8 I", text: "ТҮМБ-8 I" },
  { key: "ТҮМБ-8 II", text: "ТҮМБ-8 II" },
  { key: "ТҮМБ-8 III", text: "ТҮМБ-8 III" },
  { key: "ТҮМБ-8 IV", text: "ТҮМБ-8 IV" },
  { key: "ТҮМБ-8 V", text: "ТҮМБ-8 V" },
];

export { RATINGHIERARCHY };
