import { Layout, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { TreeList, Column, Selection, Scrolling } from 'devextreme-react/tree-list';
import { ButtonSecondary, ColEdit, LoadSpin, ButtonIcon } from 'dw-components';
import { ReactComponent as AddIcon } from '../../../svg/add.svg';
import StrategyPlanPanel from './StrategyPlanPanel';
import { APIGraphQL } from '../../../API/APIGraphQL';
import { IconButton } from '@fluentui/react';

const StrategyPlan = (props) => {

    const [data, setData] = useState([]);

    const [showNewSPlan, setShowNewSPlan] = useState(false);
    const [sPlanId, setSPlanId] = useState();
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);
        let tData = [];
        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                    getSPlanList {
                    iD
                    pARENTID
                    cODE
                    nAME
                    sPLANTYPEID
                    sPLANTYPENAME
                  }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data?.sharedQuery?.getSPlanList;
            if (tResp?.length > 0) {
                tData = tResp;
            }
        }).catch(response => {
            setLoading(false);
        });
        setLoading(false);
        setData(tData);
    }

    useEffect(() => {
        getData();
    }, []);

    const onEdit = (pId) => {
        setSPlanId(pId);
        setShowNewSPlan(true);
    }

    return (
        <Layout style={{ minHeight: "95vh" }}>
            <Layout>
                {loading ? (<LoadSpin />) : (
                    <div style={{ margin: 16 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Space>
                                <ButtonSecondary
                                    icon={<AddIcon />}
                                    text="Стратегийн төлөвлөгөөний хэмжих үзүүлэлт"
                                    onClick={() => {
                                        setSPlanId();
                                        setShowNewSPlan(true);
                                    }}
                                />
                            </Space>
                        </div>
                        <TreeList
                            id="iD"
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            keyExpr="iD"
                            parentIdExpr="pARENTID"
                            autoExpandAll={true}
                            showRowLines
                            showBorders
                        >
                            <Scrolling columnRenderingMode="standard" mode="standard" />
                            <Selection mode="single" />
                            <Column
                                minWidth={250}
                                dataField="nAME"
                                caption="Нэр"
                                cellRender={cellRender => {
                                    return (
                                        <ColEdit onClick={() => onEdit(cellRender?.data?.iD)}>{cellRender?.displayValue}</ColEdit>
                                    )
                                }
                                } />
                                <Column
                                
                                dataField="cODE"
                                caption="Код"
                                cellRender={cellRender => {
                                    return (
                                        <ColEdit onClick={() => onEdit(cellRender?.data?.iD)}>{cellRender?.displayValue}</ColEdit>
                                    )
                                }
                                } />

                                <Column
                                dataField="sPLANTYPENAME"
                                caption="Төрөл"
                                cellRender={cellRender => {
                                    return (
                                        <ColEdit onClick={() => onEdit(cellRender?.data?.iD)}>{cellRender?.displayValue}</ColEdit>
                                    )
                                }
                                } />
                             <Column
                                width={60}
                                dataField="iSACTIVE"
                                caption=" "
                                cellRender={cellRender => {
                                    return (
                                        <div className='smallIcon'>
                                           {cellRender.data?.iD > 1 ? <IconButton
                                                iconProps={{
                                                    iconName: "Edit"
                                                }}
                                                style={{
                                                    width: 14,
                                                    height: 14
                                                }}
                                                onClick={() => onEdit(cellRender?.data?.iD)}
                                            />: null}
                                        </div>
                                    );
                                }} /> 
                        </TreeList>
                        <StrategyPlanPanel
                            id={sPlanId}
                            isOpen={showNewSPlan}
                            onDismiss={() => {
                                setSPlanId();
                                setShowNewSPlan(false);
                            }}
                            afterSave={getData}
                        />
                    </div>
                )}
            </Layout>
        </Layout>
    );
}

export default StrategyPlan;