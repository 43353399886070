import React, { useState, useEffect, useContext } from "react";
import { ButtonDefault, PanelBase, LoadSpin, Col, Row, FieldNumberFormat, FieldDropdown, ButtonSecondary } from "dw-components";
import { message } from "antd";
import { DataGrid } from "devextreme-react";
import { Column, Scrolling, Summary, TotalItem, FilterRow, FilterPanel } from "devextreme-react/data-grid";
import { API } from "../../API/API";
import { UserContext } from "../../Context/UserContext";
import { FOREIGN, SEMESTER, TRAININGTYPE, LESSONTYPE } from "../../Const/BudgetTuitionFeeConst";
import { APIGraphQL } from "../../API/APIGraphQL";
import moment from "moment";
import { Icon } from '@fluentui/react/lib/Icon';
import FileDownload from 'js-file-download';
import FieldUploadRest from "../../Components/FieldUploadRest/FieldUploadRest";
import ButtonReport from "../../Components/ButtonReport/ButtonReport";
import { setDisableIndicators, setAllDep } from "../../Utils/SharedFunc";
import { UserType } from "../../Const/UserType";
import ChangeRatePanel from "../BudgetDtlIndicator/ChangeRatePanel/ChangeRatePanel";

const BudgetTuitionFee = (props) => {

    const { userInfo } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [attachData, setAttachData] = useState([]);

    const [disable, setDisable] = useState(false);
    const [showChangeRate, setShowChangeRate] = useState(false);
    const [dSchools, setDschools] = useState([]);
    const [dDeps, setDdeps] = useState([]);
    const [schoolid, setSchoolId] = useState(userInfo?.schoolid);
    const [depid, setDepId] = useState(userInfo?.schoolid);
    const [isalldep, setisalldep] = useState(0);

    const [dTLevel, setDTLevel] = useState([]);

    const onSave = async () => {

        let tDataEdit = dataEdit?.filter(r => r.edit);
        let vSuccess = false;

        setLoadingSave(true);
        if (tDataEdit?.length > 0 && props?.id && (depid || schoolid)) {
            for (let i = 0; i < tDataEdit.length; i++) {
                let tR = { ...tDataEdit[i] };
                tR.bUDGETID = parseInt(props?.id);
                tR.sCHOOLNAME = "";
                let currentschoolid = schoolid;
                let tIndex = dSchools?.map(r => r.key).indexOf(currentschoolid);
                if (tIndex >= 0) {
                    tR.sCHOOLNAME = dSchools[tIndex].text;
                }
                if (props?.levelid == 1) {
                    currentschoolid = depid;
                    let tIndex = dDeps?.map(r => r.key).indexOf(currentschoolid);
                    if (tIndex >= 0) {
                        tR.sCHOOLNAME = dDeps[tIndex].text;
                    }
                }
                tR.sCHOOLID = parseInt(currentschoolid);

                tR.iD = tR.id;
                tR.iSFOREIGN = tR.isforeign;
                tR.sEMESTERID = tR.semesterid;
                tR.sEMESTERNAME = tR.semestername;
                tR.tRAININGLEVELID = tR.traininglevelid;
                tR.tRAININGLEVELNAME = tR.traininglevelname;
                tR.sTUDENTLEVELID = 0;
                tR.sTUDENTLEVELNAME = "";
                tR.tRAININGTYPEID = tR.trainingtypeid;
                tR.tRAININGTYPENAME = tR.trainingtypename;
                tR.lESSONTYPEID = tR.lessontypeid;
                tR.lESSONTYPENAME = tR.lessontypename;
                tR.bATCHHOURLYFEE = tR.batchhourlyfee;
                tR.sTUDENTQTY = tR.studentqty;
                tR.tOTALBATCHHOUR = tR.totalbatchhour;
                tR.tOTALFEE = tR.totalfee;
                tR.iNDID = parseInt(props?.indId);
                delete tR.arrayIndex;
                delete tR.edit;
                delete tR.budgetid;
                delete tR.schoolid;
                delete tR.id;
                delete tR.isforeign;
                delete tR.semesterid;
                delete tR.semestername;
                delete tR.studentlevelid;
                delete tR.studentlevelname;
                delete tR.semestername;
                delete tR.traininglevelid;
                delete tR.traininglevelname;
                delete tR.trainingtypeid;
                delete tR.trainingtypename;
                delete tR.lessontypeid;
                delete tR.lessontypename;
                delete tR.batchhourlyfee;
                delete tR.studentqty;
                delete tR.totalbatchhour;
                delete tR.totalfee;
                delete tR.schoolname;
                delete tR.schoolcode;
                delete tR.created;
                delete tR.createdby;
                delete tR.note;
                delete tR.year;
                delete tR.depcode;
                delete tR.indid;

                if (tR.iD == 0) {
                    tR.cREATED = moment().format("YYYY.MM.DD HH:mm:SS");
                }
                tR.cREATEDBY = userInfo?.username;
                tDataEdit[i] = tR;
            }

            // await APIGraphQL.post("", {
            //     query: `mutation ($data: [BudgetTuitionFeeInputType]) {
            //         budgetMutation {
            //           addBudgetTuitionFee(data: $data) {
            //             iD
            //           }
            //         }
            //       }`,
            //     variables: {
            //         "data": tDataEdit
            //     }
            // }).then(response => {
            //     if (response?.data?.data?.budgetMutation?.addBudgetTuitionFee?.length > 0) {
            //         vSuccess = true;
            //         message.success("Амжилттай");
            //     }
            // }).catch(() => {
            //     setLoadingSave(false);
            // })
            await API.post("api/budget/calcBudgetTuitionFee?iscreate=1&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
            "&depid=" + (!depid ? userInfo?.schoolid : depid) +
            "&userschoolid=" + userInfo?.schoolid + "&indid=" + props?.indId,tDataEdit).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    vSuccess = true;
                    message.success("Амжилттай");
                }
            });
        }

        if (vSuccess) {
            await props.afterSave();
        }
        setLoadingSave(false);
        props.onDismiss();
    }

    const onExportDoc = async (pData) => {
        setLoadingDownload(true);
        let pSchoolId = props?.levelid == 1 ? depid : schoolid;
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;
        await API.get("/api/budget/budgetTuitionFeeTemp?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid + "&filetype=" + pData?.filetype + "&indid=" + props?.indId + "&indname=" + props?.indName , {
            'responseType': 'blob'
        }).then(response => {
            FileDownload(response.data, props?.indName + "." + pData?.filetype);

        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
    }

    const getData = async (pSchoolId, isalldep = 0) => {
        let tData = [];
        let tDTLevel = [];
        let alldep = isalldep;
        if (alldep == 0) {
            alldep = setAllDep(props?.showDep || props?.showSchool, pSchoolId, userInfo?.schoolid, schoolid, props?.levelid)
        }
        if (userInfo?.schooltypeid == 1 && pSchoolId == userInfo?.schoolid)
            pSchoolId = 0;

        setLoading(true);
        await API.get("/api/budget/getbudgetTuitionFee?budgetid=" + props?.id + "&schoolid=" + pSchoolId + "&alldep=" + alldep + "&userschoolid=" + userInfo?.schoolid  + "&indid=" + props?.indId).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tData = response?.data?.retData.data;
                let tSchoolTypeID = -1;
                let tIndex = dSchools?.map(r => r.key).indexOf(pSchoolId);
                if (tIndex >= 0) {
                    tSchoolTypeID = dSchools[tIndex].sCHOOLTYPEID;
                }

                let tDisable = setDisableIndicators(props?.coverageid, userInfo?.schooltypeid, alldep, tData?.length, tSchoolTypeID);
                setDisable(props?.disable || tDisable);
            }
        }).catch(() => {
            setLoading(false);
        });

        await APIGraphQL.post("", {
            query: `{
                sharedQuery {
                  getTrainingLevelList {
                    key: iD
                    text: nAME
                  }
                }
              }`
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.getTrainingLevelList?.length > 0) {
                tDTLevel = tResp?.sharedQuery?.getTrainingLevelList;
            }
        }).catch(() => {
            setLoading(false);
        });

        for (let i = 0; i < tData?.length; i++) {
            tData[i].arrayIndex = i;
        }
        await getAttachData(pSchoolId);

        setLoading(false);
        setDTLevel(tDTLevel);
        setData(tData);
        setDataEdit(tData);
    }

    const setSchools = async (pSchoolId) => {
        const schools = await getSchools(pSchoolId);
        if (userInfo?.schooltypeid == 1 || userInfo?.schooltypeid == 3) {
            setDschools(schools);
            setSchoolId(pSchoolId);
        }
        else if (userInfo?.schooltypeid == 2 || userInfo?.schooltypeid == 6) {
            setDdeps(schools);
            setDepId(pSchoolId);
        }
    }

    const getSchools = async (pSchoolId) => {
        let tSchools = [];
        await APIGraphQL.post("", {
            query: `query ($schoolid: Int) {
                sharedQuery {
                    schoolByParentId(schoolid: $schoolid) {
                        key: iD
                        text: nAME
                        sCHOOLTYPEID
                    }
                }
              }`,
            variables: {
                "schoolid": pSchoolId
            }
        }).then(response => {
            let tResp = response?.data?.data;
            if (tResp?.sharedQuery?.schoolByParentId?.length > 0) {
                tSchools = tResp?.sharedQuery?.schoolByParentId;
                tSchools.push({ key: pSchoolId, text: "Бүгд",  sCHOOLTYPEID: -1});
            }
        }).catch(() => {
            setLoading(false);
        });
        return tSchools;
    }

    useEffect(() => {
        let vSchoolId = userInfo?.schoolid;
        setSchools(vSchoolId);
        setisalldep(0);
        getData(vSchoolId);

    }, [props?.id, props?.showDep, props?.showSchool, props?.disable]);

    const getAttachData = async (pSchoolId) => {
        let tAttachData = [];
        await API.get("/api/budget/getAttachFile?type=budgetindicator&id=" + props?.indId + "&schoolid=" + pSchoolId + "&budgetid=" + props?.id).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                tAttachData = response?.data?.retData;
            }
        }).catch(() => {
            setLoading(false);
        });
        setAttachData(tAttachData);
    }

    const fieldOnChanged = async (pIndex, pId, pValue, pField, pNameValue, pFieldName) => {
        let tDataEdit = [...dataEdit];
        tDataEdit[pIndex][pField] = pValue;
        if (pFieldName) {
            tDataEdit[pIndex][pFieldName] = pNameValue;
        }

        if (pField == "totalbatchhour") {
            tDataEdit[pIndex]["totalfee"] = (tDataEdit[pIndex]["totalbatchhour"] || 0) * (tDataEdit[pIndex]["batchhourlyfee"] || 0);
        } else if (pField == "batchhourlyfee") {
            tDataEdit[pIndex]["totalfee"] = (tDataEdit[pIndex]["totalbatchhour"] || 0) * (tDataEdit[pIndex]["batchhourlyfee"] || 0);
        }

        if (["isforeign", "traininglevelid", "lessontypeid"].includes(pField) &&
            tDataEdit[pIndex]["traininglevelid"] &&
            tDataEdit[pIndex]["lessontypeid"]) {

            await APIGraphQL.post("", {
                query: `query ($budgetid: Int, $traininglevelid: Int, $lessontypeid: Int, $isforeign: Int, $schoolid: Int, $indid: Int) {
                    sharedQuery {
                      getTuitionBatchHourFee(budgetid: $budgetid, traininglevelid: $traininglevelid, lessontypeid: $lessontypeid, isforeign: $isforeign, schoolids: $schoolid, indid: $indid) {
                        batchhourlyfee: bATCHHOURLYFEE
                      }
                    }
                  }`,
                variables: {
                    "budgetid": parseInt(props?.id),
                    "traininglevelid": parseInt(tDataEdit[pIndex]["traininglevelid"]),
                    "lessontypeid": parseInt(tDataEdit[pIndex]["lessontypeid"]),
                    "isforeign": parseInt(tDataEdit[pIndex]["isforeign"]),
                    "schoolid": schoolid,
                    "indid": parseInt(props?.indId),
                }
            }).then(response => {
                let tResp = response?.data?.data?.sharedQuery?.getTuitionBatchHourFee;
                if (tResp)
                    tDataEdit[pIndex] = { ...tDataEdit[pIndex], ...tResp };
                else
                    tDataEdit[pIndex]["batchhourlyfee"] = 0;
                    tDataEdit[pIndex]["indid"] = 0;                    

                setData(tDataEdit);
            }).catch(() => {
                setLoading(false);
            });

        }

        tDataEdit[pIndex].edit = true;
        setDataEdit(tDataEdit);
    }

    const onNewRow = () => {
        let tDataEdit = [...dataEdit];
        tDataEdit.push({
            id: 0,
            arrayIndex: tDataEdit.length
        });
        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }

    const onDelete = async (pIndex, pId) => {
        let tDataEdit = [...dataEdit];
        tDataEdit.splice(pIndex, 1);
        for (let i = 0; i < tDataEdit?.length; i++) {
            tDataEdit[i].arrayIndex = i;
        }
        if (pId > 0) {
            let vSuccess = false;
            await APIGraphQL.post("", {
                query: `mutation ($id: Int) {
                    budgetMutation {
                      delBudgetTuitionFee(id: $id)
                    }
                  }`,
                variables: {
                    "id": parseInt(pId)
                }
            }).then(response => { 
                if (response?.data?.data?.budgetMutation?.delBudgetTuitionFee == 1) {
                    vSuccess = true;
                }
            });

            if(vSuccess)
            {
                await API.post("api/budget/calcBudgetTuitionFee?iscreate=0&budgetid=" + props?.id + "&schoolid=" + (!schoolid ? userInfo?.schoolid : schoolid) + 
                "&depid=" + (!depid ? userInfo?.schoolid : depid) +
                "&userschoolid=" + userInfo?.schoolid + "&indid=" + props?.indId,tDataEdit).then(response => {
                    if (response?.status == 200 && response?.data.retType == 0) {
                        vSuccess = true;
                    }
                });
            }
         }

        setDataEdit(tDataEdit);
        setData(tDataEdit);
    }
    const onChangeType = async (typeid) => {
        let tSchool = [];
        await APIGraphQL.post("", {
            query: `query ($typeid: Int) {
            sharedQuery {
              getSchoolsbyTypes(typeid: $typeid){
                key: iD
                text: nAME
                sCHOOLTYPEID
              }
            }
          }`,
            variables: {
                "typeid": parseInt(typeid)
            }
        }).then(response => {
            let tSResp = response?.data?.data?.sharedQuery?.getSchoolsbyTypes;
            if (tSResp?.length > 0) {
                tSchool = tSResp;
                tSchool.push({ key: userInfo?.schoolid, text: "Бүгд", sCHOOLTYPEID: -1});
            }
        })
        setDschools(tSchool);
    }
    const onChangeSchool = async (pSchoolId) => {
        let tddep = await getSchools(pSchoolId);
        setSchoolId(pSchoolId);
        setDdeps(tddep);
        setDepId(pSchoolId);
        setisalldep(props?.levelid == 2 ? 0 : 1);
        await getData(pSchoolId, props?.levelid == 2 ? 0 : 1);
    }

    const onChangeDep = async (pSchoolId) => {
        setDepId(pSchoolId);
        setisalldep(0);
        await getData(pSchoolId);
    }

    const onShowChangeRate = async () => {
        setShowChangeRate(true);
    }

    const onDismiss = async () => {
        setShowChangeRate(false);
    }

    const onChangeRate = async (rateData) => {
        setLoadingSave(true);
        let vSuccess = false;
        if(rateData?.sCHOOLIDS) {
            await API.post("/api/budget/changeBudgetRateTuitionFee?userschoolid=" + userInfo?.schoolid,
                rateData
            ).then(response => {
                if (response?.status == 200 && response?.data.retType == 0) {
                    message.success("Амжилттай");
                    vSuccess = true;
                }
                else if (response?.data.retType == 2) {
                    message.warning("Хувь оруулна уу!");
                }
            }).catch(() => {
                setLoadingSave(false);
            });
        }

        if (vSuccess) {
            await props.afterSave();
        }
        setLoadingSave(false);
        props.onDismiss();
    }

    return (
        <PanelBase
            headerText={props?.indName + " тооцоолох" }
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            PanelType="large"
            buttons={(!props.disable &&
                <div style={{ display: "flex" }}>
                    {(!disable &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onSave} text="Хадгалах" />)}
                        </div>
                    )}
                    {disable && userInfo?.schooltypeid == 1 &&
                        <div style={{ marginRight: 16 }}>
                            {loadingSave ? (<LoadSpin />) :
                                (<ButtonDefault onClick={onShowChangeRate} text="Хувиар өөрчлөх" />)}
                        </div>
                    }
                    <ButtonReport title="Татах" style={{ marginRight: 16 }} onClick={onExportDoc} />
                </div>
            )}
        >
            {props?.showSchool && (
                <div style={{ marginTop: 13 }}>
                    <FieldDropdown onChanged={onChangeType} title="Төрөл" keyfield="tYPEID" keyfieldname="tYPENAME" defaultValue={data.tYPEID} data={UserType} />
                </div>
            )}
            {props?.showSchool && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeSchool(key)} title={data.tYPEID == 5 ? "Бие даасан нэгж" : data.tYPEID == 1 ? "Тэнхим, газар хэлтэсүүд" : "Сургууль"} keyfield="schoolid" keyfieldname="schoolname" defaultValue={schoolid} data={dSchools} />
                </div>
            )}
            {props?.showDep && (
                <div style={{ marginTop: 24 }}>
                    <FieldDropdown onChanged={(key, text, field, fieldName) => onChangeDep(key)} title="Тэнхим" keyfield="schoolid" keyfieldname="schoolname" defaultValue={depid} data={dDeps} />
                </div>
            )}
            {loading ? (<LoadSpin />) : (
                <div>
                <div>
              <Row justify="space-between">
               <Col> 
                    {(!disable &&
                   <Row justify="start" > 
                       <Col style={{ marginTop: 24 }}>
                             <ButtonSecondary onClick={onNewRow} text="Шинэ мөр нэмэх" />
                       </Col>
                   </Row>
                    )}
               </Col>
             <Row style={{ marginTop: 30 }}>
                 <Col justify="end">
                              /мян.төгрөг/
                 </Col>
             </Row>
           </Row>
          </div>
                    <div style={{ marginTop: 24 }}>
                        <DataGrid
                            dataSource={data}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}
                            showRowLines
                            showBorders
                        >
                            <FilterRow visible={true} />
                            <FilterPanel visible={true} />
                            <Scrolling columnRenderingMode="standard" mode="standard" useNative={true} />
                            <Column width={50} caption="№" alignment='center' allowResizing={true}
                                cellRender={(item) => {
                                    return (
                                        <div style={{ marginTop: 8 }}> {item.key?.arrayIndex + 1 + "."} </div>
                                    );
                                }}
                            ></Column>
                            {disable && props?.showSchool && (
                                <Column
                                    dataField="schoolcode"
                                    caption="Сургууль"
                                    width={150}
                                    alignment='right'
                                    groupIndex={0} />
                            )}
                            {disable && props?.showDep && (
                                <Column
                                    dataField="depcode"
                                    caption="Тэнхим"
                                    width={150}
                                    alignment='right'
                                    groupIndex={1} />
                            )}
                            <Column
                                width={150}
                                dataField="isforeign"
                                caption="Гадаад эсэх"
                                alignment='center'
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "isforeign")} defaultValue={item.data?.isforeign} data={FOREIGN} />
                                    );
                                }}
                            />
                            <Column
                                dataField="semestername"
                                caption="Улирал"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "semesterid", pNameValue, "semestername")} defaultValue={item.data?.semesterid} data={SEMESTER} />
                                    );
                                }} />
                            <Column
                                dataField="lessontypename"
                                caption="Хичээлийн хэлбэр"
                                alignment='center'
                                width={250}
                                cellRender={(item) => {
                                    return (
                                        <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "lessontypeid", pNameValue, "lessontypename")} defaultValue={item.data?.lessontypeid} data={LESSONTYPE} />
                                    );
                                }} />
                            <Column caption="Сургалтын" alignment='center'>
                                <Column
                                    dataField="traininglevelname"
                                    caption="Түвшин"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "traininglevelid", pNameValue, "traininglevelname")} defaultValue={item.data?.traininglevelid} data={dTLevel} />
                                        );
                                    }} />
                                <Column
                                    dataField="trainingtypename"
                                    caption="Хэлбэр"
                                    alignment='center'
                                    width={150}
                                    cellRender={(item) => {
                                        return (
                                            <FieldDropdown disable={disable} onChanged={(pValue, pNameValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "trainingtypeid", pNameValue, "trainingtypename")} defaultValue={item.data?.trainingtypeid} data={TRAININGTYPE} />
                                        );
                                    }} />
                            </Column>
                            <Column
                                dataField="batchhourlyfee"
                                caption="Багц цагийн төлбөр"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "batchhourlyfee")} defaultValue={item.data?.batchhourlyfee} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="studentqty"
                                caption="Хичээл сонгосон суралцагчийн тоо"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "studentqty")} defaultValue={item.data?.studentqty} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="totalbatchhour"
                                caption="Нийт сонгосон багц цаг"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={disable} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalbatchhour")} defaultValue={item.data?.totalbatchhour} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="totalfee"
                                caption="Нийт төлбөл зохих дүн"
                                format="fixedPoint"
                                alignment='center'
                                width={150}
                                cellRender={(item) => {
                                    return (
                                        <FieldNumberFormat disable={true} onChanged={(pValue) => fieldOnChanged(item?.data?.arrayIndex, item?.data?.id, pValue, "totalfee")} defaultValue={item.data?.totalfee} fixedDecimalScale={true} />
                                    );
                                }} />
                            <Column
                                dataField="average"
                                caption="Дундаж Кредит"
                                format="fixedPoint"
                                alignment='center'
                                minwidth={150}
                                cellRender={(item) => {
                                    let vAverage = 0;
                                    if (!isNaN(item?.data?.totalbatchhour) && !isNaN(item?.data?.studentqty)) {
                                        vAverage = parseFloat(item?.data?.totalbatchhour) / parseFloat(item?.data?.studentqty);
                                    }

                                    return (
                                        <FieldNumberFormat disable={true} onChanged={() => { }} defaultValue={vAverage} fixedDecimalScale={true} />
                                    );
                                }} />
                            ({!disable && <Column
                                dataField="action"
                                caption=""
                                width={30}
                                cellRender={(item) => {
                                    return (
                                        <div onClick={() => onDelete(item?.data?.arrayIndex, item?.data?.id)} style={{ marginTop: 12, cursor: 'pointer' }}>
                                            <Icon iconName="Delete" />
                                        </div>
                                    );
                                }} />
                            })
                            <Summary>
                                <TotalItem
                                    column="№"
                                    displayFormat="Нийт:"
                                />
                                <TotalItem
                                    column="studentqty"
                                    summaryType="sum"
                                    valueFormat="fixedPoint"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                                <TotalItem
                                    column="totalbatchhour"
                                    summaryType="sum"
                                    valueFormat="fixedPoint"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />
                                <TotalItem
                                    column="totalfee"
                                    summaryType="sum"
                                    valueFormat="#,##0.00"
                                    alignment="right"
                                    displayFormat="{0}₮"
                                />

                            </Summary>
                        </DataGrid>
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <FieldUploadRest id={props?.indId} schoolid={schoolid} budgetid={props?.id} title="Файл хавсаргах" keyfield='budgetindicator' defaultValue={attachData} afterSave={() => { getAttachData(schoolid); props?.afterAttach(); }} />
                    </div>
                    <ChangeRatePanel isOpen={showChangeRate} budgetid={props?.id} indid={props?.indId} 
                        onDismiss={onDismiss}
                        title={props?.indName}
                        showRate={true}
                        showCategory={true}
                        onChangeRate={onChangeRate}/>
                </div>
            )}
        </PanelBase >
    );
}

export default BudgetTuitionFee;
