import React, { useEffect, useState } from "react";
import { Row, Col, Space } from "antd";
import { IconButton } from "@fluentui/react";
import RentSquarePanel from "./Panel/RentSquarePanel";
import { ReactComponent as ExcelIcon } from '../../../svg/excel.svg';
import FileDownload from 'js-file-download';
import { API } from '../../../API/API';
import { GridDefaultShimmer, PageHeader } from "dw-components";

const RentSquare = () => {

    const [rentSquareId, setRentSquareId] = useState();

    const [showNew, setShowNew] = useState(false);
    const [data, setData] = useState({});
    const [oData, setOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gridFilter, setGridFilter] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const [columns] = useState([
        {
            name: "№",
            maxWidth: 25,
            minWidth: 25,
            onRender: (row, index) => {
              return <div> {index + 1 + "."} </div>
            }
        },
        {
            name: "Түрээслүүлэх талбай /хаяг/",
            fieldName: "name",
            minColSize: "mid"
        },
        {
            name: "Эдийн засгийн ангилал",
            fieldName: "economycategoryname",
            minColSize: "mid",
        },
        {
            name: "Талбайн хэмжээ",
            fieldName: "squaresize",
            maxWidth: 150,
            minWidth: 120,
            summary: true,
            number: true,
        },
        {
            name: "Хэмжих нэгж",
            fieldName: "unitname",
            minColSize: "mid",
        },
        {
            name: "Төлөв",
            fieldName: "isactive",
            minColSize: "mid",
            onRender: (record) => {
                return (record.isactive == 0 ? "Идэвхгүй" : "Идэвхтэй")
            }
        },
        {
            fieldName: "action",
            minColSize: "mid",
            onRender: (item) => {
                return (
                    <div>
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            title="Edit"
                            ariaLabel="Edit"
                            onClick={() => onEditClick(item.id)}
                        />
                    </div>
                );
            },
        },
    ]);

    const downloadExcel = async () => {
        let tFitler = [];
        if (filteredData?.length > 0) {
          tFitler = filteredData;
        }
    
        await API.post("/api/budget/rentSquareConfigListTemp?filetype=xlsx", tFitler, {
          'responseType': 'blob'
        }).then(response => {
          FileDownload(response.data, "Талбайн түрээс.xlsx");
    
        }).catch(response => {
            setLoadingDownload(false);
        });
        setLoadingDownload(false);
      }

    const getData = async (p_filter) => {
        setLoading(true);
        let tGridFilter = [];
        let tFitler = [];
        if (p_filter?.length > 0) {
            tFitler = p_filter;
        }

        let tData = [];
        await API.post("/api/budget/rentSquareConfigList", tFitler).then(response => {
            if (response?.status == 200 && response?.data?.retType == 0) {
                if (response?.data?.retData?.length > 0) {
                    tData = response?.data?.retData;
                }
            }
        }).catch(() => {
         setLoading(false);
        });

        setLoading(false);
        setData(tData);
        setOData(tData);
        setGridFilter(tGridFilter);
    };

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (data) => {
        setData(data);
    };

    const onNew = () => {
        setShowNew(true);
    };

    const onDismiss = () => {
        setRentSquareId();
        setShowNew(false);
    };

    const onEditClick = (id) => {
        setRentSquareId(id);
        setShowNew(true);
    };

    const onGridFilterChange = async (p_gridfiltervalue) => {
      setFilteredData(p_gridfiltervalue);
      getData(p_gridfiltervalue);
    }

    return (
        <div>
            <Row style={{minHeight: "95vh" }} wrap={false}>
                <Col flex="none">
                    
                </Col>
                <Col flex="auto" style={{ backgroundColor:"#f0f2f5" }}>
                    <PageHeader
                        title={"Нийт"}
                        dataCount={data?.length}
                        buttonTitle="Талбайн түрээс"
                        onButtonClick={onNew}
                        columns={columns}
                        onSearch={onSearch}
                        data={oData}
                        loading={loading}
                        filteredData={data}
                        addRightButton={(
                            <div style={{ marginRight: 6, marginTop: 2, cursor: 'pointer' }}>
                                <Space size={16} >
                                    <div onClick={downloadExcel}>
                                        <ExcelIcon />
                                    </div>
                                </Space>
                            </div>
                        )}
                        gridFilter={gridFilter}
                        onGridFilterChange={onGridFilterChange}
                        />
                    <div style={{ padding: 16 }}>
                        <GridDefaultShimmer columns={columns} data={data} loading={loading} summary/>
                        <RentSquarePanel id={rentSquareId} isOpen={showNew} onDismiss={onDismiss} afterSave={getData} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default RentSquare;
