import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Menu, Space } from "antd";
import { IconButton, Panel, PanelType, Persona, PersonaSize } from "@fluentui/react";
import { Color, ConstCss } from "../../Const/Const";
import { ReactComponent as MenuLogo } from "../../svg/menu.svg";
import { ReactComponent as Divider } from "../../svg/verticalDivider.svg";
import { ReactComponent as DropdownIcon } from "../../svg/dropdown.svg";
import { ReactComponent as NotificationIcon } from "../../svg/notification.svg";
import { ReactComponent as QuestionIcon } from "../../svg/question.svg";
import { ReactComponent as MenuBlackIcon } from "../../svg/menuBlackIcon.svg";
import { ReactComponent as MenuBack } from "../../svg/menuBack.svg";
import { ReactComponent as MenuUserIcon } from "../../svg/menuUserIcon.svg";
import { UserContext } from "../../Context/UserContext";
import AccountChange from '../../Pages/Header/AccountChange/AccountChange';

const Header = (props) => {

    const { userInfo } = useContext(UserContext);

    const [showMenu, setShowMenu] = useState(false);
    const [menuKey, setMenuKey] = useState();
    const [menuText, setMenuText] = useState();
    const [backPath, setBackPath] = useState();
    const menuData = props.menuData ? props.menuData : {};
    const sideMenuDate = props.sideMenuData ? props.sideMenuData : {};
    const userMenu = props.userMenu ? props.userMenu : [];
    const showFooter = props.showFooter == true ? true : false;
    const [showAccountChange, setShowAccountChange] = useState(false);

    useEffect(() => {
        let tMenuKey = "";
        let tMenuText = "";
        let tMenuKeyParent = "";
        let tBackPath = "";

        if (props.location?.pathname) {
            let tPath = props.location?.pathname?.split("/");
            if (tPath.length >= 1) {
                tMenuKey = tPath[1];
                if (tPath.length > (userInfo?.schooltypeid == 1 ? 2 : 3)) {
                    tPath.pop();
                    tBackPath = tPath.join("/");
                }
            }
        }

        if (tMenuKey) {
            let tPath;
            Object.keys(menuData).map((key) => {
                menuData[key]?.map((r) => {
                    tPath = r.path.split("/");
                    if (tPath[0] == tMenuKey) {
                        tMenuKeyParent = key;
                    }
                });
            });

            let tMenuData = menuData[tMenuKeyParent];
            let tIndex = tMenuData?.map((r) => r.path.split("/")[0]).indexOf(tMenuKey);
            if (tIndex >= 0) {
                tMenuText = tMenuData[tIndex].text;
            }
        }

        setMenuKey(tMenuKeyParent);
        setMenuText(tMenuText);
        setBackPath(tBackPath);
    }, [props.location?.pathname]);

    const onChangeMenu = (value) => {
        if (props.navigate && value.key !== "logout") {
            props.navigate("/" + value.key);
        }
    };

    let menuComp = (
        <Menu onClick={onChangeMenu}>
            {menuKey && menuData[menuKey]
                ? menuData[menuKey].map((r) => {
                    return <Menu.Item key={r.path}>{r.text}</Menu.Item>;
                })
                : null}
        </Menu>
    );

    const logOut = () => {
        if (props.logOut) {
            props.logOut();
        }
    };

    let userComp = (
        <Menu onClick={onChangeMenu}>
            {userMenu &&
                userMenu?.map((r) => {
                    return <Menu.Item key={r.path}>{r.text}</Menu.Item>;
                })}
            <Menu.Item key="logout" onClick={logOut}>
                Гарах
            </Menu.Item>
        </Menu>
    );

    const onPressMenu = () => {
        setShowMenu((r) => !r);
    };

    const onPressQuestion = () => {
        if (props.questionDocUrl) {
            window.open(`${props.questionDocUrl}`, "_blank");
        }
    };

    const onPressBack = () => {
        if (props.navigate) {
            if (props.location?.state?.backurl) {
                props.navigate(backPath);
            } else {
                props.navigate(-1);
            }
        }
    };

    const onPressAccountChange = () => {
        setShowAccountChange(true);
    }
    
    const onAccountChangeDismiss = () => {
        setShowAccountChange(false);
    }

    return (
        <div>
            <div
                style={{
                    width: "100%",
                    height: "48px",
                    backgroundColor: "#26427E",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "14px",
                    paddingRight: "16px",
                    position: "fixed",
                    top: 0,
                    zIndex: 100
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>

                    {backPath && (
                        <div onClick={onPressBack} style={{ cursor: "pointer", marginRight: 16, ...ConstCss.flexCenter }}>
                            <MenuBack />
                        </div>
                    )}

                    <div onClick={onPressMenu} style={{ cursor: "pointer", ...ConstCss.flexCenter }}>
                        <MenuLogo />
                    </div>
                    <div style={{ marginLeft: 22, ...ConstCss.flexCenter, fontSize: 14, fontWeight: "bold", color: Color.white, cursor: 'pointer' }}
                        onClick={() => {
                            if (userInfo.schooltypeid == 1) {
                                props.navigate("/budget");
                            } else if (userInfo.schooltypeid == 2 || userInfo.schooltypeid == 3 || userinfo?.schooltypeid == 6) {
                                props.navigate("/budget/branch");
                            } else if (userInfo.schooltypeid == 4 || userInfo.schooltypeid == 7) {
                                props.navigate("/budget/department");
                            }
                        }}>
                        {props?.companyname?.substring(0, 60)}
                        {props?.companyname?.length > 60 && "..."}
                    </div>
                    <div style={{ marginLeft: 17, marginRight: 17, ...ConstCss.flexCenter }}>
                        <Divider />
                    </div>
                    {/* <div>
                        <Dropdown overlay={menuComp} trigger={["click"]}>
                            <div style={{ ...ConstCss.flexCenter, cursor: "pointer" }}>
                                <div
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: Color.white,
                                    }}
                                >
                                    {menuText}
                                </div>
                                <div style={{ paddingLeft: 12, marginTop: -6 }}>
                                    <DropdownIcon />
                                </div>
                            </div>
                        </Dropdown>
                    </div> */}
                </div>
                <div style={{ ...ConstCss.flexCenter, color: Color.white,}}>
                    {/* <div style={{ cursor: "pointer", marginLeft: 30, ...ConstCss.flexCenter }}>
                        <NotificationIcon />
                    </div> */}
                    {/* <div onClick={onPressQuestion} style={{ cursor: "pointer", marginLeft: 30, ...ConstCss.flexCenter }}> */}
                    <div style={{ paddingRight: 30 }}>
                        <a onClick={onPressAccountChange} href="#" style={{ color: Color.white, textTransform: "uppercase" }}>
                            Эрх хооронд шилжих
                        </a>
                    </div>
                    {userInfo?.username}
                    {/* </div> */}
                    <div style={{ cursor: "pointer", marginLeft: 30, ...ConstCss.flexCenter }}>
                        <Dropdown overlay={userComp} trigger={["click"]}>
                            <Persona hidePersonaDetails text={props.userid ? props.userid : ""} size={PersonaSize.size32} />
                        </Dropdown>
                    </div>
                </div>
            </div>
            <Panel
                headerText={props.title}
                isOpen={showMenu}
                onDismiss={onPressMenu}
                hasCloseButton={false}
                isLightDismiss={true}
                type={PanelType.smallFixedNear}
                isFooterAtBottom={true}
                onRenderHeader={() => {
                    return (
                        <div onClick={onPressMenu} style={{ display: "flex", justifyContent: "flex-start", marginBottom: 36, justifyItems: "center", cursor: "pointer" }}>
                            <div style={{ marginLeft: 24 }}>
                                <MenuBlackIcon />
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: 14, marginLeft: 10 }}>{props.title}</div>
                        </div>
                    );
                }}
                // onRenderFooter={() => {
                //     return (
                //         <div>
                //             {showFooter ? (
                //                 <div
                //                     onClick={() => {
                //                         setShowMenu(false);
                //                         props.navigate("/config");
                //                     }}
                //                     style={{ display: "flex", cursor: "pointer", marginBottom: 16, borderTop: "1px solid rgb(237, 235, 233)" }}
                //                 >
                //                     <div style={{ marginLeft: 24, marginTop: 16 }}>
                //                         <MenuUserIcon />
                //                     </div>
                //                     <div style={{ marginLeft: 10, marginTop: 16 }}>{props.settingsTitle ? props.settingsTitle : "Тохиргоо"}</div>
                //                 </div>
                //             ) : null}
                //         </div>
                //     );
                // }}
            >
                <div
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setShowMenu(false);
                        if (userInfo.schooltypeid == 1) {
                            props.navigate("/budget");
                        } else if (userInfo.schooltypeid == 2 || userInfo.schooltypeid == 3 || userinfo?.schooltypeid == 6) {
                            props.navigate("/budget/branch");
                        } else if (userInfo.schooltypeid == 4 || userinfo?.schooltypeid == 7) {
                            props.navigate("/budget/department");
                        }
                    }}>
                    <Space>
                        <IconButton
                            iconProps={{ iconName: "Home" }}
                        />
                        <div>
                            МУИС ТӨСВИЙН СИСТЕМ
                        </div>
                    </Space>
                </div>
            </Panel>
            <div style={{marginBottom: "48px"}}></div>
            <AccountChange isOpen={showAccountChange} onDismiss={onAccountChangeDismiss} />
        </div>
    );
};

export default Header;
